var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds-nc-card-course-shop" }, [
        _c(
          "div",
          {
            staticClass: "cover",
            style: { paddingBorrom: _vm.imgHeight + "px" },
          },
          [
            _c("dsf-image"),
            _c("div", { staticClass: "time-end" }, [
              _vm._v("倒计时：2天12时24分6秒"),
            ]),
          ],
          1
        ),
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "shop-buy-items" }, [
          _c("div", { staticClass: "ms" }, [
            _c("span", { staticClass: "prefix" }, [_vm._v("秒杀")]),
            _c(
              "span",
              { staticClass: "c-sale" },
              [_c("dsf-icon", { attrs: { name: "jinbi" } }), _vm._v(" 8500 ")],
              1
            ),
            _c("span", { staticClass: "o-sale" }, [_vm._v("1200")]),
          ]),
          _c("div", { staticClass: "ms" }, [
            _c("span", { staticClass: "prefix present" }, [_vm._v("赠")]),
            _c(
              "span",
              { staticClass: "jf" },
              [_c("dsf-icon", { attrs: { name: "jinbi" } }), _vm._v(" 10 ")],
              1
            ),
            _c(
              "span",
              { staticClass: "dyq" },
              [_c("dsf-icon", { attrs: { name: "quan" } }), _vm._v(" 20 ")],
              1
            ),
          ]),
        ]),
      ])
    : _c(
        "div",
        {
          staticClass: "ds-nc-card-course-shop",
          class: { "none-cursor": _vm.$route.query.isview == 1 },
          style: { width: _vm.imgWidth },
          attrs: { title: _vm.data["name"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "cover",
              style: {
                width: _vm.imgWidth,
                paddingBorrom: _vm.imgHeight + "px",
              },
            },
            [
              _c("dsf-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title ellipsis-2" }, [
              _c("span", [_vm._v(_vm._s(_vm.data["name"]))]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c(
              "span",
              {
                staticClass: "ellipsis",
                attrs: { title: _vm.data["teacherText"] },
              },
              [
                _vm._v(
                  "老师: " +
                    _vm._s(
                      _vm.data["teacherText"]
                        ? _vm.data["teacherText"].replace(/\^/g, ",")
                        : ""
                    )
                ),
              ]
            ),
            _c("span", [_vm._v("销量：" + _vm._s(_vm.data["indentNum"] || 0))]),
          ]),
          _c("div", { staticClass: "shop-buy-items" }, [
            _c("div", { staticClass: "ms" }, [
              _vm.prefixText && _vm.getPrefix("prefixText")
                ? _c("span", { staticClass: "prefix" }, [
                    _vm._v(
                      _vm._s(_vm.prefixText ? _vm.getPrefix("prefixText") : "")
                    ),
                  ])
                : _vm._e(),
              _c(
                "span",
                { staticClass: "c-sale" },
                [
                  _vm.data["price"]
                    ? _c("dsf-icon", { attrs: { name: "jinbi" } })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.data["price"] || "免费") + " "),
                ],
                1
              ),
              _vm.data["old_price"]
                ? _c("span", { staticClass: "o-sale" }, [
                    _vm._v(_vm._s(_vm.data["old_price"])),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "ms" }, [
              _vm.data["giftPayment"]
                ? _c("span", { staticClass: "prefix present" }, [_vm._v("赠")])
                : _vm._e(),
              _vm.data["giftPayment"]
                ? _c(
                    "span",
                    { staticClass: "jf" },
                    [
                      _c("dsf-icon", { attrs: { name: "jinbi" } }),
                      _vm._v(" " + _vm._s(_vm.data["giftPayment"]) + " "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data["giftCoupon"]
                ? _c(
                    "span",
                    { staticClass: "dyq" },
                    [
                      _c("dsf-icon", { attrs: { name: "quan" } }),
                      _vm._v(" " + _vm._s(_vm.data["giftCoupon"]) + " "),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title ellipsis-2" }, [_c("span")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("span", [_vm._v("老师: 张三")]),
      _c("span", [_vm._v("销量: 99")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }