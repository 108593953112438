<template>
  <div class="ds-nc-shopping-car">
    <div class="shopping-head">
      <div class="shopping-head-inner">
        <h3 class="name">购物车</h3>
        <div class="search-wrap">
          <el-input placeholder="请输入关键字" class="search-input" v-model="searchWord" @change="searchShopcar"></el-input>
          <span class="his-name" @click="toHistoryOrder">历史订单</span>
          <dsf-icon name="sousuo car-search" />
        </div>
      </div>
    </div>
    <div class="ds-nc-shopping-container" :style="{ minHeight: minHeight + 'px' }">
      <div class="ds-content-pannel" :style="{ minHeight: minHeight + 'px' }">
        <div class="header">
          <span class="name active">全部商品</span>
        </div>
        <div class="content">
          <div class="content-item">
            <dsfShoppingCarItem :list="shopCarList" @createOrder="createOrderSuccess" :orderUrl="orderUrl" @handleSuccess="handleSuccess" />
          </div>
        </div>
      </div>
      <!-- 
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部商品" name="first">
          <dsfShoppingCarItem :list="shopCarList" @createOrder="createOrderSuccess" :orderUrl="orderUrl" @handleSuccess="handleSuccess" />
        </el-tab-pane>
        <el-tab-pane label="限时秒杀" name="second">限时秒杀</el-tab-pane>
        <el-tab-pane label="满减商品" name="third">满减商品</el-tab-pane>
        <el-tab-pane label="团购商品" name="fourth">团购商品</el-tab-pane>
      </el-tabs> -->
    </div>
  </div>
</template>

<script>
const SHOPCARLIST = "/nc/shop/shopcart/myCart";
import dsfShoppingCarItem from "../../base/shop/shoppingCarItem.vue";
export default dsf.component({
  name: "DsfNcShoppingCar",
  ctrlCaption: "商城购物车",
  mixins: [$mixins.card],
  props: {
    imgWidth: {
      type: String,
      default: "",
    },
    orderUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shoporders?id=",
    },
    sourcePath: {
      type: String,
      default: "/ncIndex.html#/pc/nc/pagepersonalCenter/index?active=order",
    },
  },
  components: {
    dsfShoppingCarItem,
  },
  computed: {
    minHeight() {
      let _wh = window.innerHeight;
      return _wh - 170;
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      searchWord: "",
      activeName: "first",
      shopCarList: [],
    };
  },
  mounted() {
    this.loadShopCarList();
  },
  methods: {
    loadShopCarList() {
      const loading = dsf.layer.loading();
      let params = {
        type: "all",
        goodName: this.searchWord,
      };
      this.dsf.http
        .get(SHOPCARLIST, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            _.each(data, item => {
              item.checked = false;
            });
            this.shopCarList = data || [];
          } else {
            dsf.layer.message(message || "获取购物车列表异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取购物车列表异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    searchShopcar() {
      this.loadShopCarList();
    },
    createOrderSuccess() {
      this.loadShopCarList();
    },
    toHistoryOrder() {
      dsf.layer.openWindow(this.sourcePath);
    },
    handleSuccess() {
      this.loadShopCarList();
    },
  },
});
</script>
