var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header_model header_model_type2 margint20" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c("dsf-image", {
                key: _vm.logoImg[0] && _vm.logoImg[0].relativePath,
                attrs: { src: _vm.logoImg[0] && _vm.logoImg[0].relativePath },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "nav" }, [
            _c("div", { staticClass: "dsf-college-home-header-menu" }, [
              _c("div", {
                staticClass: "dsf-college-home-header-menu-bar",
                style: _vm.barStyle,
              }),
              _c(
                "div",
                { staticClass: "dsf-college-home-header-menu-list" },
                [
                  _vm._l(_vm.activeMenuList, function (menu, index) {
                    return [
                      index < 4 || _vm.activeMenuList.length == 5
                        ? _c(
                            "div",
                            {
                              key: menu["ncSaasSettingMenuId"] + "-" + index,
                              ref: "menu",
                              refInFor: true,
                              staticClass: "dsf-college-home-header-menu-item",
                              class: { active: _vm.activeMenuIndex === index },
                              on: {
                                click: function ($event) {
                                  return _vm._clickBefore(
                                    _vm.menuItemClick,
                                    menu,
                                    index
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(menu["title"]) + " ")]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm.activeMenuList.length > 5
                    ? _c(
                        "div",
                        {
                          staticClass: "morenav",
                          class: { active: _vm.showMore },
                          on: {
                            click: function ($event) {
                              return _vm._clickBefore(_vm.menuShowMore)
                            },
                          },
                        },
                        [
                          _vm._v(" 更多 "),
                          _c("dsf-icon", {
                            attrs: { name: _vm.showMore ? "shang1" : "xia1" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "dsf-college-home-header-menu-more",
                              class: { active: _vm.showMore },
                            },
                            [
                              _vm._l(
                                _vm.activeMenuList,
                                function (menu, index) {
                                  return [
                                    index >= 4
                                      ? _c(
                                          "div",
                                          {
                                            key:
                                              menu["ncSaasSettingMenuId"] +
                                              "-" +
                                              (index + 4),
                                            staticClass: "more-list",
                                            class: {
                                              active:
                                                _vm.activeMenuIndex === index,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm._clickBefore(
                                                  _vm.menuItemClick,
                                                  menu,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(menu["title"]) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "login_search" },
            [
              _c("el-input", {
                staticClass: "search",
                attrs: {
                  placeholder: "请输入关键字进行搜索",
                  "suffix-icon": "el-icon-search",
                },
              }),
              _vm._v(" 登录 "),
            ],
            1
          ),
        ]),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }