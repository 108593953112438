<template>
  <DsfSection
    mainWidth="1200px"
    :margin="[0, 0, 0, 0]"
    :padding="[10, 10, 10, 10]"
    :backgroundConfig="{
      color: '#f9f9f9',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
    :foregroundConfig="{
      color: 'rgba(255, 255, 255, 0)',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
  >
    <!--nctitle-->
    <DsfNcTitle
      v-if="showTitle"
      :showMore="showMore"
      :title="title"
      caption="DsfNcTitle"
      :showBottomLine="true"
      titleSize="big"
      :titleBold="false"
      :clickConfig="clickConfigTitle"
      padding="80px 0 60px 0"
    ></DsfNcTitle>
    <div class="dsf_nc_tuwendaohang">
      <div class="images-wrap">
        <div class="image-item" :class="count == 4 ? 'count4' : ''" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <DsfImage :src="item.image && item.image.relativePath" :error-src="dsf.config.setting_public_card_default_img"></DsfImage>
        </div>
      </div>
    </div>
  </DsfSection>
</template>

<script>
export default dsf.component({
  name: "DsfNcSaasHomeTuwendaohang",
  ctrlCaption: "图文导航",
  mixins: [$mixins.control],
  props: {
    moreText: {
      type: String,
      default: "查看更多",
    },

    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      clickConfigTitle: {
        url: "/ncIndex.html#/pc/nc/page/pc/news/newsListModelTwo",
        state: 2,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
    };
  },
  computed: {
    list() {
      let arr = [];
      // if (this.data.bindAttr["image1"]) {
      // }
      arr.push({
        image: this.data.bindAttr["image1"],
        link: this.data.bindAttr["link1"],
      });
      // if (this.data.bindAttr["image2"]) {

      // }
      arr.push({
        image: this.data.bindAttr["image2"],
        link: this.data.bindAttr["link2"],
      });
      // if (this.data.bindAttr["image3"]) {

      // }
      arr.push({
        image: this.data.bindAttr["image3"],
        link: this.data.bindAttr["link3"],
      });

      if (this.data.bindAttr["count"] == 4) {
        arr.push({
          image: this.data.bindAttr["image4"],
          link: this.data.bindAttr["link4"],
        });
      }
      return arr;
    },
    count() {
      return this.data.bindAttr.count;
    },
    showTitle() {
      return this.data["bindAttr"]["showTitle"] == 1 ? true : false;
    },
    title() {
      return this.data["bindAttr"]["title"];
    },
    showMore() {
      return this.data["bindAttr"]["showMore"] == 1 ? true : false;
    },
  },
  created() {
    console.log(this.data);
  },
  mounted() {},
  methods: {
    goMore() {
      this.$open({
        url: "/ncIndex.html#/pc/nc/page/pc/news/newsListModelTwo",
        state: 2,
      });
    },
    goDetail(item) {
      this.$open({
        url: item.link.indexOf("http") == -1 ? "http://" + item.link : item.link,
        state: 2,
      });
    },
  },
  watch: {
    "data.bindAttr": {
      handler(n) {
        console.log(n);
        this.list = n;
      },
      deep: true,
    },
  },
});
</script>
