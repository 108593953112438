<template>
  <div class="dsf-nc-spacil-detail-header" :style="{ width: cssWidth, padding: padding }">
    <!-- 面包屑 -->
    <div class="crumbs">
      <div class="wrap">
        <span class="fister-text" @click="back">{{ fisterText }} >&nbsp;</span>
        <span class="second-text">{{ secondText }}</span>
      </div>
      <div>
        <img v-if="isback" @click="back" src="../../../assets/imgs/fanhui.png" alt="返回" />
      </div>
    </div>
    <div class="spacil-info" v-if="!isCrumbs">
      <div class="nameandtags">
        <div class="name">{{ detail["name"] }}</div>
        <div class="tabs-wrap">
          <div class="tags-item" v-for="(item, index) in label" :key="index">{{ item }}</div>
        </div>
      </div>
      <div class="desc">
        <div class="desc-item">报名日期：{{ dsf.date.format(detail["bmsjSdate"], "yyyy-mm-dd") }} 至 {{ dsf.date.format(detail["bmsjEdate"], "yyyy-mm-dd") }}</div>
        <div class="desc-item">培训日期：{{ dsf.date.format(detail["pxsjSdate"], "yyyy-mm-dd") }} 至 {{ dsf.date.format(detail["pxsjEdate"], "yyyy-mm-dd") }}</div>
        <div class="desc-item">结业条件：必修 {{ detail["compulsory"] }} 选修 {{ detail["electives"] }} 学时 {{ detail["period"] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSpecialDetailHeader",
  ctrlCaption: "专题班详情顶部卡片",
  mixins: [$mixins.card],
  data() {
    return {
      detail: null,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "name", to: "name", type: "text", desc: "课题" },
          { from: "pxsj_sdate", to: "sDate", type: "text", desc: "开始时间" },
          { from: "pxsj_edate", to: "eDate", type: "text", desc: "结束时间" },
          { from: "needPeriod", to: "needPeriod", type: "text|number", desc: "必修学时", prefix: "必修学时：" },
          { from: "noNeedPeriod", to: "noNeedPeriod", type: "text|number", desc: "选修学时", prefix: "选修学时：" },
          { from: "stateValue", to: "stateValue", type: "text|number", desc: "状态值" },
          { from: "stateText", to: "stateText", type: "text", desc: "状态文字" },
          { from: "indentNum", to: "indentNum", type: "text|number", desc: "购买量" },
          { from: "show_description", to: "show_description", type: "text|number", desc: "是否显示倒计时" },
          { from: "timeDuring", to: "timeDuring", type: "text|number", desc: "倒计时描述" },
          { from: "price", to: "price", type: "text|number", desc: "兑换总积分" },
          { from: "old_price", to: "old_price", type: "text|number", desc: "兑换积分" },
          { from: "giftPayment", to: "giveIntegral", type: "text|number", desc: "赠送积分" },
          { from: "giftCoupon", to: "giftCoupon", type: "text|number", desc: "代金券" },
          { from: "id", to: "id", type: "text|number", desc: "id" },
          { from: "activitySuffix", to: "activitySuffix", type: "text", desc: "前缀文字", prefix: "秒杀价" },
        ];
      },
    },
    isback: {
      type: Boolean,
      default: true,
    },
    cssWidth: {
      type: String,
      default: "1300px",
    },
    padding: {
      type: String,
      default: "0 0 0 0",
    },
    fisterText: {
      type: String,
      default: "教辅中心",
    },
    secondText: {
      type: String,
      default: "专题班详情",
    },
    isCrumbs: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    label() {
      if (this.detail.labelsText) return this.detail.labelsText.split("^");
      return [];
    },
  },
  created() {
    this.getData();
  },
  methods: {
    back() {
      this.$router.go(-2);
    },
    getData() {
      // /inc/nc/class/info?id=a55db06328294f978f93e959397321a5
      var _this = this;
      var loading = dsf.layer.loading();
      //xxx为请求地址，key为参数名，value参数值
      this.$http
        .get("/inc/nc/class/info", {
          id: this.$route.query.id,
        })
        .done(res => {
          if (res.success) {
            //查询成功
            this.detail = res.data;
          } else {
            dsf.layer.message(res.message || "请求异常", false);
          }
        })
        .error(function (err) {
          //错误
          dsf.layer.message((err && err.message) || "请求异常", false);
        })
        .always(function () {
          //不管成功失败都会执行（请求，比如404，200）
          dsf.layer.closeLoading(loading);
        });
    },
  },
});
</script>
