<template>
  <div class="dsf_nc_poylvplayer">
    <div class="poylvplayer-inner">
      <div id="poylvplayer" ref="coursePlayer" class="poylvplayer"></div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "dsfNcPoylvPlayer",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    moreUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: "",
      audio: null,
      winH: window.innerHeight - 88,
      vid: "88083abbf5bcf1356e05d39666be527a_8",
    };
  },
  computed: {
    ...mapGetters(["getSeektime"]),
    videoSrc() {
      return function () {
        let url = this.videoSource;
        if (!url) return {};
        let arr = url.split(".");
        let extName = arr[arr.length - 1];
        let type = "";
        switch (extName) {
          case "mp3":
            type = "audio/mp3";
            break;
          case "m3u8":
            type = "video/m3u8";
            break;
          case "mp4":
            type = "video/mp4";
            break;
          default:
            type = "";
            break;
        }
        return {
          type: type,
          src: url,
        };
      };
    },
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  created() {},
  mounted() {
    // let that = this;
    // if (window.$$serverConfig) {
    //   let { setting_nc_player_is_auto, setting_nc_player_is_speed, setting_nc_player_is_drag } = window.$$serverConfig;
    // } else {
    // }
    this.loadPlayerScript(this.loadPlayer);
  },
  methods: {
    loadPlayerScript(callback) {
      if (window.polyvPlayer) {
        callback?.();
      } else {
        this.$http
          .importFiles([[dsf.url.getWebPath("$/js/libs/poylvplayer/poylvplayer.js"), ".js"]])
          .then(() => {
            callback?.();
          })
          .catch(() => {
            dsf.layer.message("加载polyvPlayer文件报错", false);
          });
      }
    },
    initAudio() {
      let that = this;
    },
    loadPlayer() {
      let that = this;
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#poylvplayer",
        width: "100%",
        height: "100%",
        vid: this.vid,
        screenshot: true,
        autoplay: false,
        watchStartTime: this.formatSeek("00:00:30"),
        // lang:en, // 播放器语言，可选 zh_CN （中文）、 en （英文）
        // ban_skin_progress:true, // 是否隐藏视频播放进度条
        // showHd: false, // 是否显示清晰度切换的按钮，默认为 true
        speed: true, // 是否显示倍速切换的按钮
        // allowFullscreen: false, // 是否允许全屏播放。为false时会隐藏全屏按钮（全屏的API依然可用）。
        history_video_duration: 2, //默认时长超过5分钟的视频才会开启续播功能，可通过此参数修改，单位：分钟。
        // ban_history_time: 'off', //当值为’on‘时会禁用续播功能。
        // ban_seek: isDrag ? "off" : "on", //是否禁止拖拽进度条
        ban_seek: "on", //是否禁止拖拽进度条
        // ban_seek_by_limit_time:'on', //是否禁止拖拽进度至视频未播放到的位置，为on时只可在已播放过的进度范围内拖拽（向前拖拽）
        //封面图
        // loading_bg_img: 'https://img.videocc.net/uimage/8/88083abbf5/first_image/2bb92156-e3ac-4781-bdba-483c3213ccd2_b.png', // 封面图URL
        cover_display: "scaleAspectFit", //封面图显示方式
        cover_opacity: 70, //封面图蒙层不透明度，取值范围：[0,100]。
        // vid: "873c41fa75291cfa095450cf4d3316cd_8",
        // vid: curKc.bfinfo.vid,
        // playsafe: polyvToken
      });
      this.player.on("s2j_onVideoPause", function (e) {
        console.log("播放暂停" + e);
        that.$emit("playerHasPause");
      });
      this.player.on("s2j_onVideoPlay", function (e) {
        console.log("播放播放" + e);
        that.$emit("playerHasPlay");
      });
      this.player.on("s2j_onVideoSeek", function (e) {
        console.log(e);
      });
      this.player.on("s2j_onPlayOver", function (e) {
        console.log("播放结束:" + e);
        that.$emit("playHasEnd");
      });
      this.player.on("s2j_onPlayerError", function (e) {
        dsf.layer.message("播放出现错误", false);
      });
    },
    changeVideo() {
      let { src, type } = this.videoSrc();
      let player = this.player;
    },
    formatSeek(t) {
      if (!t) {
        t = "00:00:00";
      }
      t = t.split(":");
      var s = t[0] * 60 * 60 + t[1] * 60 + t[2] * 1;
      return s;
    },
    currentPlayTime() {
      if (this.player) {
        let value = this.player.j2s_getCurrentTime();
        let data = dsf.date.secondsToTime(value);
        let time = data.hours + ":" + data.minutes + ":" + data.seconds;
        return time;
      }
    },
    ...mapActions(["uploadScreenshot"], ["playerPlaySaveProgress"]),
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },
  watch: {
    videoSource(n, o) {
      if (n) {
        this.videoSource = n;
        this.changeVideo();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dsf_nc_poylvplayer {
  width: 100%;
  height: 100%;
  transition: all 0.8s;
  position: relative;
  .poylvplayer-inner {
    height: 100%;
    .poylvplayer {
      height: 100%;
    }
  }
  .audio {
    width: 100%;
    position: absolute;
    bottom: 0;
    audio {
      width: 100%;
    }
  }
}
</style>
