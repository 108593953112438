var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_nc_zxqy_banner" }, [
        _c("ul", { staticClass: "left_course_wrap" }, [
          _c("li", { staticClass: "course_items active" }, [
            _vm._m(0),
            _c("div", { staticClass: "course_item_desc" }, [
              _vm._m(1),
              _c("ul", { staticClass: "course_inner_wrap" }, [
                _c("li", { staticClass: "course_inner_items" }, [
                  _c(
                    "div",
                    { staticClass: "image" },
                    [_c("dsf-nc-image", { attrs: { src: "" } })],
                    1
                  ),
                  _vm._m(2),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "banner_inner" },
          [
            _c(
              "el-carousel",
              { attrs: { height: "440px", autoplay: false } },
              _vm._l(4, function (item) {
                return _c(
                  "el-carousel-item",
                  { key: item },
                  [
                    _c("dsf-nc-image", { attrs: { src: "" } }),
                    _c("div", { staticClass: "footer" }, [
                      _c("div", { staticClass: "title ell" }, [
                        _vm._v(
                          "2021上海国际生物医药产业周开幕，加快打造具有全球影响力的生物医药上海高地！"
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "dsf_nc_zxqy_banner" }, [
        _c(
          "ul",
          { staticClass: "left_course_wrap" },
          _vm._l(_vm.courseData, function (item, index) {
            return _c(
              "li",
              {
                key: item._id,
                class:
                  "course_items" +
                  (_vm.hoverCourses === index ? " active" : ""),
                on: {
                  mouseover: function ($event) {
                    _vm.hoverCourses = index
                  },
                  mouseleave: function ($event) {
                    _vm.hoverCourses = null
                  },
                },
              },
              [
                _c("div", { staticClass: "course_item_lists" }, [
                  _c("span", { staticClass: "font" }, [
                    _vm._v(
                      _vm._s(item.first_name || "") +
                        " / " +
                        _vm._s(item.second_name || "")
                    ),
                  ]),
                  _c("span", { staticClass: "icon iconfont icon-gengduo2" }),
                ]),
                _vm.hoverCourses === index
                  ? _c("div", { staticClass: "course_item_desc" }, [
                      _c("div", { staticClass: "course_title_wrap" }, [
                        _c("div", { staticClass: "first_title" }, [
                          _vm._v(_vm._s(item.first_name || "")),
                        ]),
                        _c("div", { staticClass: "second_title" }, [
                          _vm._v(_vm._s(item.second_name || "")),
                        ]),
                      ]),
                      item.lists && item.lists.length > 0
                        ? _c(
                            "ul",
                            { staticClass: "course_inner_wrap" },
                            _vm._l(item.lists, function (it, idx) {
                              return _c(
                                "li",
                                {
                                  key: it._id,
                                  staticClass: "course_inner_items",
                                  on: {
                                    click: function ($event) {
                                      return _vm._clickBefore(
                                        _vm.clickCourseDetail,
                                        it
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "image" },
                                    [
                                      _c("dsf-nc-image", {
                                        attrs: {
                                          src: _vm._f("imgFormat")(
                                            it[
                                              "nc_courses_page_listsource.cover"
                                            ]
                                          ),
                                          "error-src": _vm.defaultSrc,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "desc" }, [
                                    _c("div", { staticClass: "item_title" }, [
                                      _vm._v(
                                        _vm._s(
                                          it[
                                            "nc_courses_page_listsource._name"
                                          ] || ""
                                        )
                                      ),
                                    ]),
                                    _c("div", { staticClass: "footer" }, [
                                      _c("div", { staticClass: "teacher" }, [
                                        _vm._v(
                                          _vm._s(
                                            it[
                                              "nc_courses_page_listsource.teacher_text"
                                            ] || ""
                                          )
                                        ),
                                      ]),
                                      _c("div", { staticClass: "count" }, [
                                        _c("i", {
                                          staticClass:
                                            "icon iconfont icon-yanjing1",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              it[
                                                "nc_courses_page_listsource.view_totle"
                                              ] || ""
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "banner_inner" },
          [
            _c(
              "el-carousel",
              {
                attrs: {
                  height: "440px",
                  loop: _vm.loop,
                  interval: _vm.interval,
                  autoplay: _vm.autoplay,
                  arrow: "never",
                },
              },
              _vm._l(_vm.bannerList, function (item, index) {
                return _c("el-carousel-item", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "banner_box",
                      on: {
                        click: function ($event) {
                          return _vm._clickBefore(_vm.clickBanner, item)
                        },
                      },
                    },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(item.cover),
                          fit: "fill",
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                      _c("div", { staticClass: "footer" }, [
                        _c("div", { staticClass: "title ell" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "course_item_lists" }, [
      _c("span", { staticClass: "font" }, [_vm._v("数字经济/形式分析")]),
      _c("span", { staticClass: "icon iconfont icon-gengduo2" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "course_title_wrap" }, [
      _c("div", { staticClass: "first_title" }, [_vm._v("数字经济")]),
      _c("div", { staticClass: "second_title" }, [_vm._v("形式分析")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("div", { staticClass: "item_title" }, [_vm._v("创企风采展示")]),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "teacher" }, [_vm._v("朱爽")]),
        _c("div", { staticClass: "count" }, [
          _c("i", { staticClass: "icon iconfont icon-yanjing1" }),
          _vm._v(" 4527 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }