var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_ncpc_class_photo" }, [
    _c("div", { staticClass: "photo_title" }, [_vm._v(_vm._s(_vm.titleName))]),
    _c("div", { staticClass: "photo_content" }, [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "count_box" }, [
          _c("span", [
            _vm._v(
              "照片总数：" + _vm._s(_vm.alibumData.photoCount || 0) + "张"
            ),
          ]),
          _c("span", [
            _vm._v("上传：" + _vm._s(_vm.alibumData.userCount || 0) + "人"),
          ]),
        ]),
        _c(
          "div",
          {
            class: [
              "upload_btn",
              _vm.classUnApply && !_vm.isManaged ? "gray" : "",
            ],
          },
          [
            _c(
              "el-upload",
              {
                class: [
                  "avatar_uploader",
                  _vm.classUnApply && !_vm.isManaged ? "gray" : "",
                ],
                attrs: {
                  action: _vm.actionUrl,
                  "show-file-list": false,
                  "on-success": _vm.handleAvatarSuccess,
                  "before-upload": _vm.beforeAvatarUpload,
                  disabled: _vm.classUnApply && !_vm.isManaged,
                },
              },
              [_vm._v(" 上传照片 ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "photo_wrap" }, [
        _vm.photoLists && _vm.photoLists.length
          ? _c(
              "div",
              { staticClass: "photo_wrap_box" },
              _vm._l(_vm.photoLists, function (item, index) {
                return _c(
                  "div",
                  { key: item._id + index, staticClass: "photo_items" },
                  [
                    _c(
                      "div",
                      { staticClass: "photo_item" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item["ipb_album_albumListData.mine"] ||
                                  _vm.isManaged,
                                expression:
                                  "item['ipb_album_albumListData.mine'] || isManaged",
                              },
                            ],
                            staticClass: "detel",
                            on: {
                              click: function ($event) {
                                !_vm.classUnApply && _vm.clickDetele(item)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _c("dsf-nc-image", {
                          attrs: {
                            src: _vm._f("imgFormat")(
                              item["ipb_album_albumListData.img"]
                            ),
                            width: "530px",
                            height: "280px",
                            "error-src":
                              _vm.dsf.config.setting_nc_image_defadivt_img,
                          },
                        }),
                        _c("div", { staticClass: "footer" }, [
                          _c("div", { staticClass: "name ell" }, [
                            _vm._v(
                              _vm._s(
                                item[
                                  "ipb_album_albumListData.ds_create_user_name"
                                ]
                              )
                            ),
                          ]),
                          _c("div", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(
                                _vm.dsf.date.format(
                                  item[
                                    "ipb_album_albumListData.ds_create_time"
                                  ],
                                  "yyyy-mm-dd"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _c("div", {
              staticClass: "ds-empty-data",
              domProps: { textContent: _vm._s("暂无数据") },
            }),
        _c(
          "div",
          {
            staticClass: "classphoto_page",
            style: { "text-align": _vm.paginationPosition },
          },
          [
            _c("el-pagination", {
              ref: "pagination",
              class: [_vm.paginationStyle],
              attrs: {
                "hide-on-single-page": false,
                total: _vm.pageCount,
                "page-size": _vm.pageSize,
                "page-sizes": _vm.limits,
                "current-page": _vm.currentPage,
                layout: "slot," + _vm.pagerLayout,
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function ($event) {
                  _vm.currentPage = $event
                },
                "size-change": _vm.pageSizeChange,
                "current-change": _vm.pageSizeChange,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }