var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_nc_footer",
      style: { width: _vm.itemWidth, margin: _vm.margin },
    },
    [
      _c("div", { staticClass: "nc-footer-inner" }, [
        _c("div", { staticClass: "footer-top" }, [
          _c("div", { staticClass: "footer-top-left" }, [
            _c(
              "dl",
              _vm._l(_vm.faceInfo, function (item) {
                return _c("dd", { key: item.name }, [
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("b", { staticClass: "total" }, [
                    _vm._v(_vm._s(item.total)),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "footer-top-right" }, [
            _c("div", { staticClass: "footer-top-right-text" }, [
              _c("span", [_vm._v(_vm._s(_vm.phone))]),
              _c("span", [_vm._v(_vm._s(_vm.technology))]),
              _c("span", [_vm._v(_vm._s(_vm.address))]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "footer-bottom" },
          [
            _vm._l(_vm.copyList, function (item) {
              return [
                item.title && item.title != ""
                  ? _c(
                      "span",
                      {
                        key: item.title,
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.toPage(item)
                          },
                        },
                      },
                      [
                        item.icon
                          ? _c("i", { staticClass: "polic-icon" })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }