<!--  -->
<template>
  <div class="dsf_liveTitle" :style="{ padding: titlePadding }" v-if="visible">
    <div class="txt" :style="{ fontSize: titleFont, width: titleWidth }">
      <img :src="src" alt="" v-if="src" class="title-img" />
      <span>{{ title }}</span>
      <span v-if="titleEg" class="en" :style="{ fontSize: titleFont }">{{ titleEg }}</span>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcLiveTitle",
  ctrlCaption: "直播标题",
  mixins: [$mixins.control],
  props: {
    visible: {
      default: true,
      type: Boolean,
    },
    title: {
      default: "近期直播",
      type: String,
    },
    titleEg: {
      default: "",
      type: String,
    },
    src: {
      type: String,
      default: "",
    },
    titleWidth: {
      type: String,
      default: "1200px",
    },
    titleFont: {
      type: String,
      default: "36px",
    },
    titlePadding: {
      type: String,
      default: "50px 0 40px 0",
    },
  },
  data() {
    return {};
  },
  components: {},
  created() {
    if (!this.src) {
      if (this.title === "近期直播") {
        this.src = require("../../../assets/imgs/pcimg/live/icon_zjzb.png");
      } else if (this.title === "直播历史") {
        this.src = require("../../../assets/imgs/pcimg/live/icon_zbls.png");
      }
    }
  },
});
</script>
<style lang="scss" scoped></style>
