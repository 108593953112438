import live from "./live";
import liveTitle from "./liveTitle";
import recentLive from "./recentLive";
import liveDetail from "./liveDetail";
import cardItemByLive from "./cardItemByLive";
import cardItemByWonderfulLive from "./cardItemByWonderfulLive";
import livesignin from "./livesignin";
import liveInformation from "./liveInformation";
import liveShopCard from "./liveShopCard";
import liveTeacherCard from "./liveTeacherCard";
import liveTeacherCardMain from "./liveTeacherCardMain";

export default [livesignin, live, recentLive, liveTitle, liveDetail, cardItemByLive, cardItemByWonderfulLive, liveInformation, liveShopCard, liveTeacherCard, liveTeacherCardMain];
