<template>
  <div v-if="isDesign" class="dsf-nc-spacil-shop-card">
    <div class="cover dsf-nc-image">
      <dsf-nc-image :error-src="defaultSrc" />
      <div class="info">
        <div class="card-state" style="background-color: #0080ff">报名</div>
        <div class="card-info">
          <!-- <span>2019.07.24 ～ 2019.08.27</span> -->
          <span>报名截止还剩 4天08:09:21</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">网络学院卡片组件</div>
    </div>
    <div class="footer">
      <span>
        <i class="iconfont icon-icon-bi"></i>
        <b>10</b>
        学时
      </span>
      <span>
        <i class="iconfont icon-icon-xuan"></i>
        <b>5</b>
        学时
      </span>
      <span>
        <i class="iconfont icon-xiaoliang"></i>
        <b>25</b>
      </span>
    </div>
    <div class="shop-buy-items no-padding">
      <div class="ms">
        <span class="prefix">秒杀</span>
        <span class="c-sale">
          <dsf-icon name="jinbi" />
          8500
        </span>
        <span class="o-sale">1200</span>
      </div>
      <div class="ms">
        <span class="prefix present">赠</span>
        <span class="jf">
          <dsf-icon name="jinbi" />
          10
        </span>
        <span class="dyq">
          <dsf-icon name="quan" />
          20
        </span>
      </div>
    </div>
  </div>
  <div v-else class="dsf-nc-spacil-shop-card" :title="data['name']" @click="_clickBefore(click)">
    <div class="cover dsf-nc-image">
      <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
      <div class="info">
        <div class="card-state" :style="`background-color: ${showBgColor(data['stateText'])}`">{{ data["stateText"] }}</div>
        <div class="card-info" v-if="data['timeDuring']">
          <!-- <span v-if="data['show_description'] == 1">报名截止还剩 {{ data["timeDuring"] }}</span>
          <span v-else>{{ data["sDate"] }} 至 {{ data["eDate"] }}</span> -->
          <span>报名截止还剩 {{ data["timeDuring"] }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title ellipsis-2">{{ data["name"] }}</div>
    </div>
    <div class="footer">
      <span>
        <i class="iconfont icon-icon-bi"></i>
        <b>{{ data["needPeriod"] || 0 }}学时</b>
      </span>
      <span>
        <i class="iconfont icon-icon-xuan"></i>
        <b>{{ data["noNeedPeriod"] || 0 }}学时</b>
      </span>
      <span>
        <i class="iconfont icon-xiaoliang"></i>
        <b>{{ data["indentNum"] || 0 }}</b>
      </span>
    </div>

    <div class="shop-buy-items no-padding special-padding">
      <div class="ms">
        <span class="prefix" v-if="activitySuffix && getPrefix('activitySuffix')">{{ prefixText ? getPrefix("activitySuffix") : "" }}</span>
        <span class="c-sale">
          <dsf-icon name="jinbi" v-if="data['price']" />
          {{ data["price"] || "免费" }}
        </span>
        <span class="o-sale" v-if="data['old_price']">{{ data["old_price"] }}</span>
      </div>
      <div class="ms">
        <span class="prefix present" v-if="data['giftPayment']">赠</span>
        <span class="jf" v-if="data['giftPayment']">
          <dsf-icon name="jinbi" />
          {{ data["giftPayment"] }}
        </span>
        <span class="dyq" v-if="data['giftCoupon']">
          <dsf-icon name="quan" />
          {{ data["giftCoupon"] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcShopSpecial",
  ctrlCaption: "商城专题卡片",
  mixins: [$mixins.card],
  design: {
    isMask: false,
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "name", to: "name", type: "text", desc: "课题" },
          { from: "pxsj_sdate", to: "sDate", type: "text", desc: "开始时间" },
          { from: "pxsj_edate", to: "eDate", type: "text", desc: "结束时间" },
          { from: "needPeriod", to: "needPeriod", type: "text|number", desc: "必修学时", prefix: "必修学时：" },
          { from: "noNeedPeriod", to: "noNeedPeriod", type: "text|number", desc: "选修学时", prefix: "选修学时：" },
          { from: "stateValue", to: "stateValue", type: "text|number", desc: "状态值" },
          { from: "stateText", to: "stateText", type: "text", desc: "状态文字" },
          { from: "indentNum", to: "indentNum", type: "text|number", desc: "购买量" },
          { from: "show_description", to: "show_description", type: "text|number", desc: "是否显示倒计时" },
          { from: "timeDuring", to: "timeDuring", type: "text|number", desc: "倒计时描述" },
          { from: "price", to: "price", type: "text|number", desc: "兑换总积分" },
          { from: "old_price", to: "old_price", type: "text|number", desc: "兑换积分" },
          { from: "giftPayment", to: "giveIntegral", type: "text|number", desc: "赠送积分" },
          { from: "giftCoupon", to: "giftCoupon", type: "text|number", desc: "代金券" },
          { from: "id", to: "id", type: "text|number", desc: "id" },
          { from: "activitySuffix", to: "activitySuffix", type: "text", desc: "前缀文字", prefix: "秒杀价" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  created() {},
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
    showBgColor(text) {
      let c = "";
      switch (text) {
        case "报名中":
          c = "#0080FF";
          break;
        case "进行中":
          c = "#34C47C";
          break;
        case "未开始":
          c = "#F67039";
          break;
        case "已结束":
          c = "#808080";
          break;
        default:
          c = "#0080FF";
          break;
      }
      return c;
    },
  },
});
</script>
