<template>
  <div class="dsf_userAccount" v-if="visible" :style="{ width: width, height: height }">
    <userTitle :title="title" :moreUrl="moreUrl" :showMore="showMore" v-if="showHeader"></userTitle>
    <div class="userInfo-content">
      <p class="margin-bottom-20">
        <span class="padding-left-10">单位：</span>
        <span class="margin-left-10">{{ resData[keys.unit] }}</span>
      </p>
      <p class="margin-bottom-20">
        <!-- require -->
        <span class="padding-left-10">用户名：</span>
        <span class="margin-left-10">{{ resData[keys.userName] || "" }}</span>
      </p>
      <p class="margin-bottom-20 pwd">
        <span class="padding-left-10">密码：</span>
        <span class="margin-left-10">********</span>
        <span class="change" @click="changePwd">修改</span>
      </p>
    </div>
  </div>
</template>

<script>
import userTitle from "./userTitle.vue";
export default dsf.component({
  name: "DsfNcUserAccount",
  ctrlCaption: "账户信息",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    pageType: {
      type: String,
      default: "tacitly",
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "账户信息",
    },
    width: {
      type: String,
      default: "480px",
    },
    moreUrl: { type: String, default: "" },
    height: {
      type: String,
      default: "400px",
    },
    changePwdUrl: {
      type: String,
      default: "",
    },
    reqUrl: { type: String, default: "" },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "unit", to: "unit", type: "text", desc: "单位" },
          { from: "userName", to: "userName", type: "text", desc: "用户名" },
          { from: "pwd", to: "pwd", type: "text", desc: "密码" },
        ];
      },
    },
    resData: {
      tyep: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      keys: {},
    };
  },
  components: {
    userTitle,
  },
  created() {
    this.keysMap.forEach(item => {
      this.keys[item.to] = item.from;
    });
  },
  mounted() {
    if (this.isDesign) {
      this.resData = {
        unit: "成都",
        userName: "张三",
        pwd: "********",
      };
    } else {
      this.getData();
    }
  },
  methods: {
    formatterPwd(data) {},
    getData() {
      if (this.reqUrl) {
        const loading = dsf.layer.loading();
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              this.resData = res.data;
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      }
    },
    changePwd() {
      if (this.changePwdUrl) {
        if (this.pageType == "inland") {
          this.$open({
            url: this.changePwdUrl,
            state: 2,
          });
        } else {
          this.$open({
            url: this.changePwdUrl,
            state: 3,
            width: "600px",
            height: "400px",
            title: "修改密码",
          });
        }
      }
    },
  },
});
</script>
