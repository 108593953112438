var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_saas_page_design" }, [
    _c(
      "div",
      { staticClass: "page_top" },
      [
        _c("el-input", {
          attrs: { width: "250" },
          model: {
            value: _vm.pageName,
            callback: function ($$v) {
              _vm.pageName = $$v
            },
            expression: "pageName",
          },
        }),
        _c(
          "div",
          { staticClass: "header_btns" },
          [
            _c(
              "dsf-button",
              { attrs: { type: "plain" }, on: { click: _vm.closePage } },
              [_vm._v("退出")]
            ),
            _c(
              "dsf-button",
              { attrs: { type: "plain" }, on: { click: _vm.preview } },
              [_vm._v("预览")]
            ),
            _c(
              "dsf-button",
              { attrs: { type: "primary" }, on: { click: _vm.savePage } },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "page_content" }, [
      _c("div", { staticClass: "component_list" }, [
        _c(
          "ul",
          _vm._l(_vm.components, function (component, i) {
            return _c(
              "li",
              {
                key: i,
                on: {
                  click: function ($event) {
                    return _vm.createComponent(component)
                  },
                },
              },
              [_vm._v(_vm._s(component.name))]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "page_view" },
        _vm._l(_vm.page, function (component, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "design_content",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.checkComponent(index, $event)
                },
              },
            },
            [
              _c(
                "div",
                { style: { margin: "auto", width: "100%" } },
                [
                  _c(component.type, {
                    tag: "component",
                    attrs: { data: component },
                  }),
                ],
                1
              ),
              _vm.activeIndex == index
                ? _c("div", { staticClass: "design_option" }, [
                    _c("div", [
                      _c("img", {
                        attrs: { src: _vm.delIcon, alt: "", title: "移除" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.remove(index)
                          },
                        },
                      }),
                      _vm.activeIndex != 0
                        ? _c("img", {
                            attrs: {
                              src: _vm.moveupIcon,
                              alt: "",
                              title: "上移",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.moveUp(index)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.activeIndex != _vm.page.length - 1 &&
                      _vm.page.length > 1
                        ? _c("img", {
                            attrs: {
                              src: _vm.movedownIcon,
                              alt: "",
                              title: "下移",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.moveDown(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ])
                : _c("div", { staticClass: "design_mask" }),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "component_attr" },
        [
          _vm.page[_vm.activeIndex]
            ? [
                _vm.page[_vm.activeIndex].name
                  ? _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.page[_vm.activeIndex].name)),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "component_attr_content" },
                  _vm._l(_vm.page[_vm.activeIndex].attr, function (attr, i) {
                    return _c("div", { key: i, staticClass: "attr" }, [
                      _c("div", { staticClass: "attr_title" }, [
                        _vm._v(_vm._s(attr.title)),
                      ]),
                      attr.type == "radio"
                        ? _c(
                            "div",
                            _vm._l(attr.selection, function (se) {
                              return _c(
                                "el-radio",
                                {
                                  key: se.label,
                                  style: {
                                    width: 100 / attr.selection.length + "%",
                                  },
                                  attrs: { label: se.value },
                                  model: {
                                    value:
                                      _vm.page[_vm.activeIndex].bindAttr[
                                        attr["bind"]
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.page[_vm.activeIndex].bindAttr,
                                        attr["bind"],
                                        $$v
                                      )
                                    },
                                    expression:
                                      "page[activeIndex].bindAttr[attr['bind']]",
                                  },
                                },
                                [_vm._v(" " + _vm._s(se.label) + " ")]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      attr.type == "input"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.page[_vm.activeIndex].bindAttr[
                                      attr["bind"]
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.page[_vm.activeIndex].bindAttr,
                                      attr["bind"],
                                      $$v
                                    )
                                  },
                                  expression:
                                    "page[activeIndex].bindAttr[attr['bind']]",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      attr.type == "image"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    action: "/file/upload",
                                    "show-file-list": false,
                                    "on-success": function (response) {
                                      _vm.handleAvatarSuccess(
                                        response,
                                        attr["bind"]
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.page[_vm.activeIndex].bindAttr[
                                    attr["bind"]
                                  ] &&
                                  _vm.page[_vm.activeIndex].bindAttr[
                                    attr["bind"]
                                  ].relativePath
                                    ? _c("DsfImage", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src:
                                            _vm.page[_vm.activeIndex].bindAttr[
                                              attr["bind"]
                                            ].relativePath,
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      attr.type == "dialog"
                        ? _c(
                            "div",
                            [
                              _c(
                                "dsf-button",
                                {
                                  staticStyle: { display: "block" },
                                  attrs: { type: "plain" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog(
                                        attr["bind"],
                                        attr.url,
                                        attr.radio
                                      )
                                    },
                                  },
                                },
                                [_vm._v("选择分类")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }