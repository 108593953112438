<template>
  <div v-if="isDesign" class="ds-nc-teacher-card">
    <div class="avatar-wrap">
      <dsf-nc-image class="avatar" :error-src="defaultAvatar" />
    </div>
    <div class="info">
      <div class="name_box">
        <span class="name">达娴滢</span>
        <span class="position">教授、博士生导师</span>
      </div>
      <div class="unit ell">中央党校（国家行政学院）</div>
      <div class="course">18门精品课程 ></div>
      <div class="foot">
        <div class="score_box">
          <dsf-icon name="pingjia2" />
          <span>2.7</span>
        </div>
        <div class="subscribe">
          <dsf-icon name="iconfontzhizuobiaozhun13" />
          <span>7048</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="objData" class="ds-nc-teacher-card" @click.stop="_clickBefore(courseClick)">
    <div class="avatar-wrap">
      <dsf-nc-image class="avatar" :src="objData['nc_teacher_data_mainTeacherData.photo'] | imgFormat" :error-src="defaultAvatar" />
    </div>
    <div class="info">
      <div class="name_box">
        <span class="name" :title="objData['nc_teacher_data_mainTeacherData.name']">{{ objData["nc_teacher_data_mainTeacherData.name"] || "" }}</span>
        <span class="position" :title="objData['nc_teacher_data_mainTeacherData.position']">{{ objData["nc_teacher_data_mainTeacherData.position"] || "" }}</span>
      </div>
      <div class="unit ell" :title="objData['nc_teacher_data_mainTeacherData.dept_text']">{{ objData["nc_teacher_data_mainTeacherData.dept_text"] || "" }}</div>
      <div class="course">{{ objData["nc_teacher_data_mainTeacherData.course_count"] }}{{ objData["nc_teacher_data_mainTeacherData.course_count"] }} ></div>
      <div class="foot">
        <div class="score_box">
          <dsf-icon name="pingjia2" />
          <span>{{ objData["nc_teacher_data_mainTeacherData.score"] || 0 }}</span>
        </div>
        <div class="subscribe">
          <dsf-icon name="iconfontzhizuobiaozhun13" />
          <span>{{ objData["nc_teacher_data_mainTeacherData.subscribed_num"] || 0 }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ds-nc-teacher-card" @click.stop="_clickBefore(courseClick)">
    <div class="avatar-wrap">
      <dsf-nc-image class="avatar" :src="data['avatar'] | imgFormat" :error-src="defaultAvatar" />
    </div>
    <div class="info">
      <div class="name_box ellipsis-2">
        <span class="name" :title="data['name']">{{ data["name"] || "" }}</span>
        <span class="position" :title="data['position']">{{ data["position"] || "" }}</span>
      </div>
      <div class="unit ell" :title="data['unit']">{{ data["unit"] || "" }}</div>
      <div class="course">{{ data["courseNum"] }}{{ getSuffix("courseNum") }} ></div>
      <div class="foot">
        <div class="score_box">
          <dsf-icon name="pingjia2" />
          <span>{{ data["score"] || 0 }}</span>
        </div>
        <div class="subscribe">
          <dsf-icon name="iconfontzhizuobiaozhun13" />
          <span>{{ data["subscribed_num"] || 0 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcTeacherCard",
  ctrlCaption: "教师卡片",
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "nc_teacher_data_mainTeacherData.photo", to: "avatar", type: "text", desc: "头像" },
          { from: "nc_teacher_data_mainTeacherData.name", to: "name", type: "text", desc: "名称" },
          { from: "nc_teacher_data_mainTeacherData.dept_text", to: "unit", type: "text", desc: "任职单位" },
          { from: "nc_teacher_data_mainTeacherData.position", to: "position", type: "text", desc: "职位" },
          {
            from: "nc_teacher_data_mainTeacherData.course_count",
            to: "courseNum",
            type: "text|number",
            desc: "课程数量",
            suffix: "门精品课程",
          },
          { from: "nc_teacher_data_mainTeacherData.score", to: "score", type: "text", desc: "评分" },
          { from: "nc_teacher_data_mainTeacherData.subscribed_num", to: "subscribed_num", type: "text", desc: "订阅数" },
        ];
      },
    },
    objData: {
      type: Object,
      default: null,
    },
    // 点击课程跳转设置
    clickConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      defaultAvatar: dsf.config.setting_nc_image_teacher_header_img || dsf.config.setting_public_teacher_default_header || this.errorSrc,
    };
  },
  mounted() {
    console.log(this.objData);
  },

  methods: {
    courseClick() {
      if (this.$listeners.courseClick) {
        this.$dispatch("courseClick", this.local);
      } else {
        if (!this.clickConfig) return;
        let { url, state } = this.clickConfig;
        url = this.$replace(url, this.local);
        this.$open({ url, state });
      }
      this.clickAfter();
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
});
</script>
