var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_saas saas_setting_footer" },
    [
      _c("div", { staticClass: "title t1 margint20 marginb20" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                _vm.settingModel = false
              },
            },
          },
          [_vm._v("页尾设置")]
        ),
        _vm._v(" " + _vm._s(_vm.settingModel ? "> 设置信息" : "") + " "),
      ]),
      !_vm.settingModel
        ? [
            _c("settingFooterTitle", {
              attrs: {
                title: "模板一",
                index: "1",
                radioValue: _vm.radioValue,
              },
              on: { change: _vm.change, setBtn: _vm.setBtn },
            }),
            _c("footerOne", {
              attrs: {
                itemWidth: "100%",
                margin: "9px 0 50px 0",
                data: _vm.dataModel1,
              },
            }),
            _c("settingFooterTitle", {
              attrs: {
                title: "模板二",
                index: "2",
                radioValue: _vm.radioValue,
              },
              on: { change: _vm.change, setBtn: _vm.setBtn },
            }),
            _c("footerTwo", {
              attrs: {
                itemWidth: "100%",
                margin: "9px 0 50px 0",
                data: _vm.dataModel2,
              },
            }),
            _c("settingFooterTitle", {
              attrs: {
                title: "模板三",
                index: "3",
                radioValue: _vm.radioValue,
              },
              on: { change: _vm.change, setBtn: _vm.setBtn },
            }),
            _c("footerThree", {
              attrs: {
                itemWidth: "100%",
                margin: "9px 0 0px 0",
                data: _vm.dataModel3,
              },
            }),
          ]
        : [
            _vm.settingModel == "1"
              ? _c("settingfootermodel1", {
                  attrs: { data: _vm.dataModel1 },
                  on: {
                    back: function ($event) {
                      _vm.settingModel = false
                    },
                    saveModel: _vm.saveModel,
                  },
                })
              : _vm._e(),
            _vm.settingModel == "2"
              ? _c("settingfootermodel2", {
                  attrs: { data: _vm.dataModel2 },
                  on: {
                    back: function ($event) {
                      _vm.settingModel = false
                    },
                    saveModel: _vm.saveModel,
                  },
                })
              : _vm._e(),
            _vm.settingModel == "3"
              ? _c("settingfootermodel3", {
                  attrs: { data: _vm.dataModel3 },
                  on: {
                    back: function ($event) {
                      _vm.settingModel = false
                    },
                    saveModel: _vm.saveModel,
                  },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }