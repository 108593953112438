<template>
  <div v-if="isDesign" class="ds-nc-shop-active-time">
    <div class="cover">
      <dsf-image />
    </div>
    <div class="active-time">活动时间: 11-11 00:00 · 12-11 08:00</div>
  </div>
  <div v-else class="ds-nc-shop-active-time">
    <div class="cover">
      <dsf-image />
    </div>
    <div class="active-time">
      <span>活动时间: {{ data["time"] || "11-11 00:00 · 12-11 08:00" }}</span>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcShopActiveTime",
  ctrlCaption: "商城活动时间",
  mixins: [$mixins.card],
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "time", to: "time", type: "text", desc: "时间" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
    imgWidth: {
      type: String,
      default: "",
    },
    imgHeight: {
      type: String,
      default: "",
    },
  },
  methods: {},
});
</script>
