var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "dsf_userInte",
          style: { width: _vm.width, height: _vm.height },
        },
        [
          _vm.showHeader
            ? _c("userTitle", { attrs: { title: _vm.title, showMore: false } })
            : _vm._e(),
          _c("div", { staticClass: "userInte-content" }, [
            _c("p", { staticClass: "margin-bottom-20" }, [
              _c("span", { staticClass: "padding-left-10" }, [
                _vm._v("积分等级："),
              ]),
              _c("span", { staticClass: "margin-left-10" }, [
                _vm._v(_vm._s(_vm.resData[_vm.keys.inteLevel])),
              ]),
            ]),
            _c("p", { staticClass: "margin-bottom-20" }, [
              _c("span", { staticClass: "padding-left-10" }, [
                _vm._v("当前可用积分："),
              ]),
              _c("span", { staticClass: "margin-left-10" }, [
                _vm._v(_vm._s(_vm.resData[_vm.keys.inteNum] || "")),
              ]),
            ]),
            _c("p", { staticClass: "margin-bottom-20 pwd" }, [
              _c("span", { staticClass: "padding-left-10" }, [
                _vm._v("历史总积分："),
              ]),
              _c("span", { staticClass: "margin-left-10" }, [
                _vm._v(_vm._s(_vm.resData[_vm.keys.inteHistory] || "")),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }