var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_ncpc_students_bbs",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "intro_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.showMore && _vm.moreStyle == "tb"
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm.showMore && _vm.moreStyle == "wz"
            ? _c(
                "span",
                {
                  staticClass: "more_font",
                  on: {
                    click: function ($event) {
                      !!_vm.showMore && _vm.clickMore()
                    },
                  },
                },
                [_vm._v("更多")]
              )
            : _vm._e(),
        ]),
        _vm.showLine ? _c("span", { staticClass: "line" }) : _vm._e(),
      ]),
      _vm.commentLists.length
        ? _c(
            "ul",
            { staticClass: "students_bbs_wrap" },
            _vm._l(_vm.commentLists, function (item, index) {
              return _c(
                "li",
                { key: index, staticClass: "students_bbs_items" },
                [
                  _c(
                    "div",
                    { staticClass: "user_info" },
                    [
                      _c(
                        "el-avatar",
                        {
                          attrs: {
                            src:
                              "/oua2/getUserPhoto?type=relativePath&userId=" +
                              item.dsCreateUserId,
                          },
                          on: { error: _vm.errorHandler },
                        },
                        [
                          _c("dsf-nc-image", {
                            attrs: {
                              src: "",
                              "error-src":
                                _vm.dsf.config
                                  .setting_nc_image_teacher_header_img ||
                                _vm.dsf.config
                                  .setting_public_teacher_default_header,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "user_name ell" }, [
                      _vm._v(_vm._s(item.dsCreateUserName || "")),
                    ]),
                    _c("div", {
                      staticClass: "plnr",
                      domProps: { innerHTML: _vm._s(item.plnr) },
                    }),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.showTime || "") + "发布"),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("div", {
            staticClass: "ds-empty-data",
            domProps: { textContent: _vm._s("暂无数据") },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }