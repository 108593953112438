<template>
  <DsfSection
    mainWidth="1200px"
    :margin="[0, 0, 0, 0]"
    :padding="[10, 10, 10, 10]"
    :backgroundConfig="{
      color: '#f9f9f9',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
    :foregroundConfig="{
      color: 'rgba(255, 255, 255, 0)',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
  >
    <!--nctitle43-->
    <DsfNcTitle
      v-if="showTitle"
      :showMore="showMore"
      :title="title"
      caption="DsfNcTitle"
      :showBottomLine="true"
      titleSize="big"
      :titleBold="false"
      :clickConfig="clickConfigTitle"
      padding="80px 0 60px 0"
    ></DsfNcTitle>
    <!--nclearningzone71-->
    <DsfNcLearningZone :detailUrl="detailUrl" :sourceUrl="sourceUrl" :loopNumber="1"></DsfNcLearningZone>
  </DsfSection>
</template>

<script>
export default dsf.component({
  name: "DsfNcSaasHomeRemenpindao",
  ctrlCaption: "热门频道",
  mixins: [$mixins.control],
  props: {
    moreText: {
      type: String,
      default: "查看更多",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      clickConfig: {
        url: "#/pc/nc/pageteacher/teachersDetail?id=",
        state: 2,
      },
      clickConfigTitle: {
        url: "/ncIndex.html#/pc/nc/pagechannel/channelList",
        state: 2,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
      detailUrl: "page.html#/pc/nc/pagechannel/channelDetail?id=",
      sourceUrl: "/inc/meta/list/data?pageNum=1&pageSize=15&query=%7B%22searchValue%22%3A%22%22%7D&order=%5B%5D&filter=%5B%5D&namespace=nc.channel.page&pageName=main",
    };
  },
  computed: {
    title() {
      return this.data["bindAttr"]["title"];
    },
    showTitle() {
      return this.data["bindAttr"]["showTitle"] == 1 ? true : false;
    },
    showMore() {
      return this.data["bindAttr"]["showMore"] == 1 ? true : false;
    },
  },
  created() {},
  mounted() {},
  methods: {},
});
</script>
