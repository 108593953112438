<template>
  <div class="dsf-register-control">
    <!-- 顶部步骤导航 -->
    <div class="dsf-register-step">
      <div class="dsf-register-setp" :class="{ 'current-step': step >= 1 }">
        <div class="step-cricle">
          <div class="cricle normal">
            <dsf-icon name="icon_gou" v-if="step > 1"></dsf-icon>
            <span v-else>1</span>
          </div>
          <p>注册</p>
        </div>
        <div class="right-line"></div>
      </div>
      <div class="dsf-register-setp" :class="{ 'current-step': step >= 2 }">
        <div class="left-line"></div>
        <div class="step-cricle">
          <div class="cricle">
            <dsf-icon name="icon_gou" v-if="step > 2"></dsf-icon>
            <span v-else>2</span>
          </div>
          <p>手机验证</p>
        </div>
        <div class="right-line"></div>
      </div>
      <div class="dsf-register-setp" :class="{ 'current-step': step >= 3 }">
        <div class="left-line"></div>
        <div class="step-cricle">
          <div class="cricle">3</div>
          <p>注册成功</p>
        </div>
      </div>
    </div>
    <!-- 内容部分 -->
    <div class="dsf-register-content">
      <!-- 第一步 -->
      <div v-if="step == 1">
        <div class="hint">请务必填写真实姓名和身份证号，设置并妥善保管密码。注册完成之后，身份证号将无法更改并做为登录名使用。</div>
        <el-form :model="userInfo" :rules="rules" ref="ruleForm" label-width="100px" class="userInfo-ruleForm">
          <el-form-item label="姓名" prop="name">
            <el-input v-model.trim="userInfo.name" placeholder="请输入姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="IDCode">
            <el-input v-model.trim="userInfo.IDCode" placeholder="请输入身份证号" clearable></el-input>
          </el-form-item>
          <el-form-item label="设置密码" prop="password">
            <el-input v-model.trim="userInfo.password" type="password" placeholder="请输入密码" clearable maxlength="15"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model.trim="userInfo.confirmPassword" type="password" placeholder="请再次输入密码" clearable maxlength="15"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="dsf-register-next-btn"
              @click="submitForm('ruleForm')"
              :class="{ disabled: disabled }"
              :disabled="disabled"
            >
              下一步
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 第二步 -->
      <div v-if="step === 2">
        <input type="text" v-model.trim="phone" class="dsf-register-input" placeholder="请输入手机号" />
        <!-- <p class="error-msg" v-if="phoneResult">
          <i class="iconfont icon-tixingshixin" />
          {{ phoneResult }}
        </p> -->
        <div class="dsf-register-btn" @click="showDialog">请点击进行验证</div>
        <el-button
          @click="handleClickNext"
          type="primary"
          class="dsf-register-next-btn"
          :class="{ disabled: !phone || !dsf.validate('isMobile', this.phone) }"
          :disabled="!phone || !dsf.validate('isMobile', this.phone)"
        >
          下一步
        </el-button>
      </div>
      <!-- 第三步 -->
      <div class="step-three" v-if="step === 3">
        <div class="step-succss">
          <i class="el-icon-check" />
        </div>
        <p class="success-tip">注册成功 !</p>
        <p class="mind">请牢记您的用户名和密码：</p>
        <div class="showUser">
          <div class="item">
            <span class="item-name">用户名</span>
            <span class="item-content">{{ phone }}</span>
          </div>
          <div class="item">
            <span class="item-name">密&nbsp;&nbsp;&nbsp;&nbsp;码</span>
            <span class="item-content">{{ userInfo.password }}</span>
          </div>
        </div>
        <el-button @click="reLogin" type="primary" class="dsf-register-success-btn">去 登 录</el-button>
      </div>
    </div>
    <el-dialog :modal-append-to-body="false" :visible.sync="dialogVisible" width="480px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dsf-register-dialog">
        <div class="title">手机短信验证码</div>
        <div class="phone">当前手机号：{{ hiddenPhone }}</div>
        <div class="dsf-register-input-2">
          <input type="text" v-model="code" placeholder="请输入验证码" />
          <el-button type="primary" :class="{ disabled: interval }" :disabled="Boolean(interval)" @click="getCode" class="dsf-register-next-btn">
            {{ timeMsg }}
          </el-button>
        </div>
        <p class="error-msg" v-if="codeAuthResult && code">
          <i class="iconfont icon-tixingshixin" />
          {{ codeAuthResult }}
        </p>
        <el-button
          type="primary"
          :class="{ disabled: !code || !isShowCodeBtn }"
          @click="authCode(2)"
          :disabled="!code || !isShowCodeBtn"
          class="dsf-register-dialog-next-btn"
        >
          下一步
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNCRegister",
  ctrlCaption: "注册",
  mixins: [$mixins.control],
  data() {
    return {
      // 步骤
      step: 1,
      //手机号码
      phone: "",
      disabled: true,
      // 弹框显示
      dialogVisible: false,
      // 倒计时时间
      time: this.countdownTimes,
      //倒计时时间
      countdownTimes: 0,
      // 控制密码框是否显示
      type: "password",
      notice: "",
      // 短信倒计时定时器
      interval: null,
      isShowCodeBtn: false,
      // 短信验证码
      code: "",
      // 是否已发送验证码
      isCodeSend: false,
      //验证码验证结果
      codeAuthResult: "",
      //验证码token
      token: "",
      //手机号为空的
      phoneResult: "",
      userInfo: {
        name: "",
        IDCode: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        IDCode: [
          {
            required: false,
            validator: (rule, value, callback) => {
              // if (!value) {
              //   return callback(new Error("请输入身份证号"));
              // }
              if (value && !dsf.validate("isIDCard", value)) {
                return callback(new Error("身份证号格式不正确"));
              }
              callback();
            },
            trigger: ["blur"],
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "长度最小6个字符", trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              console.log(this.userInfo.password);
              if (!value) {
                return callback(new Error("请再次输入密码"));
              }
              if (value.length < 6) {
                return callback(new Error("长度最小6个字符"));
              }
              if (value != this.userInfo.password) {
                return callback(new Error("两次密码不一致"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    title: {
      type: String,
      default: "忘记密码",
    },

    getCodeUrl: {
      type: String,
      default: "nc/org/user/sendNcVerifCode",
    },
    authCodeUrl: {
      type: String,
      default: "nc/org/user/verifyNcCode",
    },
    registeUrl: {
      type: String,
      default: "nc/org/user/register",
    },
  },
  computed: {
    timeMsg() {
      return this.interval ? this.time + " s" : this.isCodeSend ? "重新获取" : "获取短信验证码";
    },
    hiddenPhone() {
      return this.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
  },
  watch: {
    code() {
      this.codeAuthResult = "";
    },
    step(val) {
      if (val === 2) {
        this.dialogVisible = false;
      }
    },
    dialogVisible(val) {
      if (!val) {
        this.interval && clearInterval(this.interval);
        this.time = this.countdownTimes;
        this.interval = null;
      }
    },
    userInfo: {
      deep: true, //深度监听设置为 true
      handler: function (newV, oldV) {
        if (newV.name && newV.password && newV.confirmPassword) {
          this.disabled = false;
        }
      },
    },
  },
  mounted() {},
  beforeDestroy() {
    this.interval && clearInterval(this.interval);
    this.time = this.countdownTimes;
    this.interval = null;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // alert('submit!');
          this.step = 2;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showDialog() {
      if (this.phone === "") {
        dsf.layer.message("手机号不能为空", false);
        // this.phoneResult = "手机号不能为空";
        return false;
      }
      if (dsf.validate("isMobile", this.phone)) {
        this.codeAuthResult = "";
        this.code = "";
        this.dialogVisible = true;
        // this.phoneResult = "";
      } else {
        dsf.layer.message("手机格式不正确", false);
        // this.phoneResult = "手机格式不正确";
      }
    },
    changeIcon() {
      if (this.type === "text") {
        this.type = "password";
      } else {
        this.type = "text";
      }
    },

    // async nextStep(step) {
    //   if (step === 2) {
    //     // 验证验证码值
    //     let { err, token } = await this.authCode();
    //     if (err) {
    //       this.codeAuthResult = err;
    //       return false;
    //     }
    //     this.token = token;
    //     this.step = 3;
    //   }
    // },
    // 获取验证码
    getCode() {
      //   this.countdownTimes = 60;
      //   this.time = 60;
      //   this.notice = 1;
      this.isShowCodeBtn = true;
      this.$http
        .get(this.getCodeUrl, { phone: this.phone }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.isCodeSend = true;
            this.countdownTimes = 59;
            this.time = 59;
            dsf.layer.message("发送成功");
            this.interval = setInterval(() => {
              this.time--;
              if (this.time <= 0) {
                clearInterval(this.interval);
                this.interval = null;
                this.time = this.countdownTimes;
              }
            }, 1000);
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error(err => {
          dsf.error(err);
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(() => {});
    },
    // 验证验证码
    authCode() {
      // return new Promise(resolve => {
      this.$http
        .get(this.authCodeUrl, { phone: this.phone, code: this.code }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.registerUser();
            this.dialogVisible = false;
            // resolve({ err: null, token: data });
          } else {
            // resolve({ err: data || "请求异常", token: null });
            this.codeAuthResult = message;
            // dsf.layer.message(message || "请求异常", false);
          }
        })
        .error(err => {
          // resolve({ err: err?.message || "请求异常", token: null });
          dsf.layer.message(err?.message || "请求异常" || "请求异常", false);
        })
        .always(() => {});
      // });
    },
    // 注册
    registerUser() {
      // return new Promise(resolve => {
      this.$http
        .post(
          this.registeUrl,
          { phone: this.phone, token: this.token, password: this.userInfo.password, name: this.userInfo.name, IDCode: this.userInfo.IDCode },
          $$webRoot.nc
        )
        .done(res => {
          // if (success) {
          //   resolve({ success });
          // } else {
          //   dsf.layer.message(message || "请求异常", false);
          // }
          if (res.state === 20000) {
            this.step = 3;
          } else {
            dsf.layer.message(res.message || "请求异常", false);
            this.step = 1;
          }
        })
        .error(err => {
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(() => {});
      // });
    },
    handleClickNext() {
      dsf.layer.message("请点击进行验证", false);
    },
    reLogin() {
      if (this.$listeners["complete"]) {
        this.$dispatch("complete", { userName: this.phone, pwd: this.userInfo.password });
        // this.$router.go(-1);
      } else {
        this.$open({ url: dsf.config.setting_public_pcindex_url, state: 2 });
      }
    },
  },
});
</script>
