<template>
  <div class="dsf_nc_learning_zone">
    <div class="learning-inner">
      <div class="swiper-container channel-banner" :style="{ visibility: hasLoad ? 'inherit' : 'hidden' }">
        <div class="swiper-wrapper">
          <div :class="['swiper-slide', { 'swiper-no-swiping': bannerList.length <= 1 }]" v-for="item in bannerList" :key="item._id">
            <div :style="`background:url(${setImage(item['nc_channel_page_mainData.cover'])}) no-repeat;background-size:100%;`" @click="toChannelDetail(item)">
              <dsf-nc-image :src="setImage(item['nc_channel_page_mainData.cover'])" @load="iconLoadSuccess" @error="iconLoadError" v-show="showIcon" />
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-prev prev">
        <dsf-icon name="arrow-left2" />
      </div>
      <div class="swiper-button-next next">
        <dsf-icon name="arrow-right2" />
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcLearningZone",
  ctrlCaption: "学习专区",
  mixins: [$mixins.control, $mixins.card],
  components: {
    // Swiper,
    // SwiperSlide
  },
  props: {
    setHeight: {
      type: String,
      default: "256px",
      desc: "轮播图高度",
    },
    loopNumber: {
      type: Number,
      default: 1,
    },
    sourceUrl: {
      type: String,
      default: "meta/list/data?pageNum=1&pageSize=15&query=%7B%22searchValue%22%3A%22%22%7D&order=%5B%5D&filter=%5B%5D&namespace=nc.channel.page&pageName=main",
    },
    detailUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ncSwiper: null,
      hasLoad: false,
      bannerList: [],
      showIcon: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (window.Swiper === undefined) {
      this.loadScript(() => {
        this.initLearningZone();
      });
    } else {
      this.initLearningZone();
    }
  },
  methods: {
    iconLoadError() {
      this.showIcon = true;
    },
    iconLoadSuccess() {
      this.showIcon = false;
    },
    setImage(v) {
      if (dsf.isUnDef(v) || v == "") {
        return dsf.config.setting_public_card_default_img;
      }
      let obj = v;
      if (dsf.type(obj) == "string") {
        if (obj.indexOf("relativePath") == -1 || obj.indexOf("absolutePath") == -1) {
          return v;
        }
        return dsf.getFilePath(obj);
      }
      return dsf.config.setting_public_card_default_img;
    },
    toChannelDetail(item) {
      let { detailUrl } = this;
      if (detailUrl) {
        return this.$checkLogin()
          .then(res => {
            res && dsf.layer.openWindow(detailUrl + item._id);
          })
          .catch(() => {});
      }
    },
    loadScript(callback) {
      this.$http
        .importFiles([
          [dsf.url.getWebPath("$/js/libs/swiper/css/swiper.min.css"), ".css"],
          [dsf.url.getWebPath("$/js/libs/swiper/js/swiper.min.js"), ".js"],
        ])
        .then(() => {
          callback?.();
        })
        .catch(() => {
          dsf.layer.message("加载swiper文件报错", false);
          reject();
        });
    },
    initSwiper() {
      this.$nextTick(() => {
        let { bannerList } = this;
        this.ncSwiper = new Swiper(".learning-inner .channel-banner", {
          watchSlidesProgress: true,
          loopedSlides: 5,
          slidesPerView: "auto",
          centeredSlides: true,
          loop: bannerList.length <= this.loopNumber ? false : true,
          autoplay: {
            delay: 500000,
            disableOnInteraction: false,
          },
          autoplayDisableOnInteraction: false,
          allowSlideNext: true,
          allowSlidePrev: true,
          preventLinksPropagation: true,
          navigation: {
            nextEl: ".swiper-button-prev",
            prevEl: ".swiper-button-next",
          },
          on: {
            progress: function (progress) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                var modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                }
                var translate = slideProgress * modify * 191 + "px"; // 宽度÷2.4
                var scale = 1 - Math.abs(slideProgress) / 6;
                var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform("translateX(" + translate + ") scale(" + scale + ")");
                slide.css("zIndex", zIndex);
                slide.css("opacity", 1);
                if (Math.abs(slideProgress) > 3) {
                  slide.css("opacity", 0);
                }
              }
            },
            setTransition: function (transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                slide.transition(transition);
              }
            },
            click: function (swiper, event) {},
          },
        });
        this.hasLoad = true;
      });
    },
    initLearningZone() {
      const loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(this.sourceUrl, {})
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            this.bannerList = res.data;
            this.initSwiper();
          }
        })
        .error(err => {
          error(err);
          console.log(err);
        })
        .always(() => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
  },
});
</script>
