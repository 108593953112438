<template>
  <div class="dsf_nc_homework_summary">
    <div class="briefsummary_writting" v-if="!showRead">
      <el-form ref="form" :model="formModel" label-width="80px" :rules="rules" :class="readyOnly || homeworkState == 1 ? 'readyonly' : ''">
        <div class="homework_desc">作业描述：{{ homeWorkDesc.requirements || "暂无数据" }}</div>
        <el-form-item class="dsf_form_item">
          <div class="conditions_content">
            <el-form-item label="标题" class="dsf_item_form" prop="title">
              <el-input :disabled="readyOnly || homeworkState == 1" class="dsf_input" v-model="formModel.title" placeholder="请输入" maxlength="50" show-word-limit />
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item class="dsf_form_item">
          <div class="conditions_content">
            <el-form-item label="内容" class="dsf_item_form" prop="content">
              <el-input :disabled="readyOnly || homeworkState == 1" type="textarea" class="dsf_input" :rows="10" :resize="'none'" v-model="formModel.content" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item class="dsf_btns">
          <el-button calss="close" @click="onClose">关闭</el-button>
          <el-button type="primary" class="submit" @click="onSubmit('form')" v-if="homeworkState != 1 && !readyOnly">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="nc_pc_check_briefsummary_dialog" v-else>
      <div class="content_wrapper">
        <div class="teach" v-if="showgrade">
          <div :class="['score', checkForm.state_value == '2' ? 'pass' : checkForm.state_value == '-1' ? 'nopass' : '']">
            <div class="left">
              <span class="label">分数:&nbsp;</span>
              <span class="font" v-if="checkForm.score_type_value == 2">{{ checkForm.score || "0" }}分</span>
              <span class="font" v-else>{{ checkForm.score || "暂无" }}</span>
            </div>
            <div class="right"></div>
          </div>
          <div class="py">
            <span class="label">评语:&nbsp;</span>
            <span class="font">{{ checkForm.reviews || "暂无" }}</span>
          </div>
        </div>
        <div class="briefsummayr">
          <div class="xiaojieTitle">{{ checkForm.title }}</div>
          <div class="xiaojie_content">{{ checkForm.content }}</div>
        </div>
        <div class="dsf_btns">
          <el-button class="close" @click="onClose">关闭</el-button>
          <el-button type="primary" class="submit" @click="onSubmit('resubmit')" v-if="homeworkState == -1 && !readyOnly">重新提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcHomeworkSummary",
  ctrlCaption: "作业",
  mixins: [$mixins.control, $mixins.buttonBar],
  props: {
    homeworkId: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "/nc/homework/assessment/writing",
    },
    state: {
      type: String || Number,
      default: "",
    },
    readyonly: {
      type: Boolean || String || Number,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    studentId: {
      type: String,
      default: "",
    },
    showgrade: {
      type: String || Number,
      default: 1,
    },
  },
  data() {
    return {
      showRead: false, // 显示可填区域
      formModel: {
        homeworkId: "",
        title: "",
        content: "",
      },
      rules: {
        title: [{ required: this.required, trigger: "blur", validator: this.validator }],
        content: [{ required: this.required, message: "请填写内容", trigger: "blur" }],
      },
      checkForm: {},
      homeWorkDesc: {},
      readyOnly: false,
    };
  },
  mounted() {
    if (!this.isDesign) {
      this.formModel.homeworkId = this.homeworkId || this.queryString.homeworkId || "";
      this.homeworkState = this.state || this.queryString.state || "";
      this.homeworkStudentId = this.studentId || this.queryString.studentId || "";
      this.readyOnly = this.readyonly || this.queryString.readyonly;

      this.showgrade = (this.showgrade || this.queryString.showgrade) == 0 ? false : true;

      /*homeworkState: 1 待批阅； -1 退回； 2 成功*/
      // console.log(this.homeworkState);

      if (this.homeworkState == 2) {
        // 成功、待批阅 只读
        this.checkData();
        this.showRead = true;
        this.buttons = this.buttons.filter(item => {
          return item.actionName == "close";
        });
      } else if (this.homeworkState == 1) {
        // 待批阅 只读 显示撰写区域
        this.checkData();
        this.showRead = false;
        this.buttons = this.buttons.filter(item => {
          return item.actionName == "close";
        });
      } else if (this.homeworkState == -1) {
        // 退回 只读 显示评语
        this.checkData();
        this.showRead = true;
        this.buttons = this.buttons.filter(item => {
          if (item.actionName == "save") {
            item.text = "重新提交";
          }
          return item;
        });
      } else {
        this.showRead = false;
      }

      this.initHomeWorkDesc();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(
          "nc/homework/student/details",
          {
            homeworkId: this.formModel.homeworkId,
          },
          $$webRoot.nc
        )
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.formModel = {
              title: data.title, //作业
              content: data.content,
              homeworkId: data.nc_homework_id,
            };
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    checkData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(`nc/homework/assessment/wait-reading/${this.homeworkStudentId}`, {}, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.checkForm = data.info || {};
            this.formModel = {
              title: this.checkForm.title, //作业
              content: this.checkForm.content,
              homeworkId: this.checkForm.nc_homework_id,
            };
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    initHomeWorkDesc() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(`nc/homework/info/${this.formModel.homeworkId}`, {}, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.homeWorkDesc = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    onReSubmit() {
      this.$parent._props.title = "撰写小结";
      // console.log("onReSubmit===>", this.$parent._props.title);
      // return;
      this.showRead = false;
    },
    validator(rule, value, callback) {
      if (value.length > 50) {
        callback(new Error("标题长度不能超过50字"));
      } else if (value == null || value == "") {
        callback(new Error("请填写标题"));
      } else {
        callback();
      }
    },
    onSubmit(formName) {
      if (formName == "resubmit") {
        this.onReSubmit();
        return;
      }
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          let loading = dsf.layer.pc.loading();
          let headers = {
            "Content-Type": "application/json",
          };
          dsf.http
            .post(
              this.url,
              JSON.stringify(this.formModel),
              {
                headers,
              },
              $$webRoot.nc
            )
            .done(res => {
              let { success } = res;
              if (!success) {
                this.error(res);
              } else {
                dsf.layer.pc.message(`保存成功`, true);
                this.onClose();
                this.$dialog.$parent.params.cb("保存成功");
                this.$emit("submit");
              }
            })
            .error(err => {
              this.error(err);
            })
            .always(res => {
              dsf.layer.pc.closeLoading(loading);
            });
        } else {
          return false;
        }
      });
    },
    onClose() {
      if (this.$dialog) {
        this.$dialog.$parent.close();
      }
      this.$emit("close");
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
