var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-productBanner ds-no-padding",
      style: {
        "padding-bottom": _vm.rootHeight,
        "padding-top": "calc(100% - " + _vm.h + ")",
      },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        {
          staticClass: "el-carousel-box",
          style: { width: _vm.vertical ? "calc(100% - 300px)" : "100%" },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "swipe",
              attrs: {
                arrow: _vm.showButton,
                "indicator-position": _vm.showIndicators,
                direction: _vm.vertical ? "vertical" : "horizontal",
                autoplay: _vm.isAutoPlay,
                interval: _vm.autoPlay,
                trigger: _vm.indicatorTrigger,
                type: _vm.type,
                height: _vm.h,
              },
              on: {
                change: function (index) {
                  _vm.activeIndex = index
                },
              },
            },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "el-carousel-item",
                {
                  key: "ds-banner-item-" + index,
                  attrs: { label: _vm.indicatorNumber ? index + 1 : "" },
                },
                [
                  _c(
                    "div",
                    {
                      class: ["ds-banner-item", "" + index],
                      on: {
                        click: function (ev) {
                          return _vm._clickBefore(
                            _vm.onSwipeClick,
                            ev,
                            item,
                            index
                          )
                        },
                      },
                    },
                    [
                      _vm._t(
                        "default",
                        function () {
                          return [
                            _c("DsfNcDefaultHeader", {
                              staticClass: "el-carousel-item-image",
                              style: { "border-radius": _vm.borderRadius },
                              attrs: {
                                src: item.image,
                                round: false,
                                defaultHeaderUrl: _vm.noImgUrl,
                              },
                            }),
                            _vm._t("desc", null, null, {
                              row: item,
                              $index: index,
                            }),
                          ]
                        },
                        null,
                        { row: item, $index: index }
                      ),
                    ],
                    2
                  ),
                  _vm.showTitle
                    ? [
                        _c(
                          "p",
                          {
                            staticClass: "ds-banner-item-title ell",
                            style: _vm.getTitleStyle,
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.vertical
        ? _c(
            "div",
            { staticClass: "vertical_indicator" },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "vertical_indicator_item",
                  style: { height: 100 / _vm.images.length + "%" },
                  on: {
                    mouseenter: function ($event) {
                      return _vm.changeTab(index)
                    },
                  },
                },
                [
                  _c("dsf-nc-image", {
                    staticClass: "el-carousel-item-image",
                    attrs: {
                      src: _vm._f("imgFormat")(item["cover"]),
                      round: false,
                      "error-src": _vm.noImgUrl,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }