<template>
  <div class="dsf_comment_dialog">
    <div class="dialog_inner">
      <div class="name">课程评价</div>
      <el-rate v-model="rate" show-score allow-half :disabled="isComment"></el-rate>
      <button class="submit" @click="handleComment" v-if="!isComment">评价</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfNcCourseCommentDialog",
  ctrlCaption: "评价弹窗",
  mixins: [$mixins.control],
  props: {
    id: {
      type: String,
      default: "1",
    },
    type: {
      type: String,
      default: "",
    },
    isComment: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {},
  components: {},
  data() {
    return {
      rate: null,
    };
  },
  created() {
    if (this.item && this.item.assessScore) {
      this.rate = this.item.assessScore;
    }
  },
  mounted() {},
  methods: {
    commoeLoad(sourceUrl, params, succFn, failFun) {
      const loading = dsf.layer.pc.loading("提交中...");
      dsf.http
        .post(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          res.success && succFn && succFn(res);
          if (!res.success) error(res);
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        failFun && failFun(err);
        dsf.layer.pc.message(err.message, false);
      }
    },
    handleComment() {
      const sourceUrl = "nc/learn/assess/save";
      let { id, type, rate } = this;
      if (!rate) {
        return dsf.layer.pc.message("请先评价", false);
      }
      let params = {
        relationId: id,
        rTypeValue: type == "ztb" ? "class" : "course",
        score: rate,
      };
      this.commoeLoad(
        sourceUrl,
        params,
        res => {
          this.isComment = true;
          // this.$emit("update:showComment", false);
          this.$emit("commentSuccess", res.data);
          dsf.layer.pc.message(res.message, true);
        },
        err => {
          dsf.layer.pc.message(err.message, false);
        }
      );
    },
  },
  beforeDestroy() {},
};
</script>
