<template>
  <el-dialog class="ds-upload" width="800px" title="图片裁剪" :append-to-body="true" :visible.sync="editImg" :close-on-click-modal="false" :before-close="handleClose">
    <el-container style="height: 450px">
      <el-main style="padding: 0">
        <vue-picture-cut ref="pictureCut" :src="src" :msk-option="mskRatio" :menu-thickness="0">
          <vue-picture-cut-mask :width="mskRatio.width" :height="mskRatio.height" :is-round="false" :resize="resize" />
        </vue-picture-cut>
      </el-main>
      <el-aside class="edit-img" style="width: 130px">
        <el-button plain @click="rotate(-15)">顺时针转</el-button>
        <el-button plain @click="rotate(15)">逆时针转</el-button>
        <el-button plain @click="scaleH">水平镜像</el-button>
        <el-button plain @click="scaleV">垂直镜像</el-button>
        <el-button plain @click="zoom(0.96)">缩小</el-button>
        <el-button plain @click="zoom(1.04)">放大</el-button>
      </el-aside>
    </el-container>
    <div slot="footer" class="dialog-footer">
      <div style="position: absolute; font-size: 26px; left: 30%; color: red" v-show="showTipsText">{{ tipsText }}</div>
      <el-button @click="reset">重 置</el-button>
      <el-button type="primary" @click="saveFileImg">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { VuePictureCut, VuePictureCutMask, createUtils } from "vue-picture-cut";

export default {
  name: "dsfNcCropImage",
  components: {
    VuePictureCut,
    VuePictureCutMask,
  },
  props: {
    editImg: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: String,
      default: "",
    },
    editFileImg: {
      type: Object,
      default() {
        return {};
      },
    },
    requestUrl: {
      type: String,
      default: "",
    },
    showTipsText: {
      type: Boolean,
      default: false,
    },
    tipsText: {
      type: String,
      default: "",
    },
    resize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      src: "",
      mskRatio: {},
      utils: null,
    };
  },
  watch: {
    editImg: {
      handler(v) {
        if (v) {
          this.$nextTick(function () {
            this.loadData();
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadData() {
      this.utils = createUtils(this.$refs["pictureCut"]);
      this.src = dsf.url.getWebPath(this.editFileImg.relativePath);
      if (this.aspectRatio) {
        let ratio = this.aspectRatio.split("/");
        this.mskRatio = { width: Number(ratio[0]), height: Number(ratio[1]) };
      }
    },
    saveFileImg() {
      const res = this.utils.cut();
      if (res) {
        // this.blob = res.file;   // BLOB对象
        // this.base64 = res.src;  // base64字符串
        const formData = new FormData();
        formData.append("croppedImage", res.file, this.editFileImg.originalFileName);
        $.ajax({
          method: "post",
          url: this.requestUrl, //用于文件上传的服务器端请求地址
          data: formData,
          async: false,
          processData: false,
          contentType: false,
          beforeSend: xhr => {
            var headerParam = dsf.getCookie("XSRF-TOKEN") || "";
            xhr.setRequestHeader("X-XSRF-TOKEN", headerParam);
            xhr.setRequestHeader("authorization_token", dsf.getToken());
          },
          success: result => {
            if (typeof result === "string") {
              var result = JSON.parse(result);
            }
            if (result.success) {
              let files = result.data[0];
              // 重新上传 替换当前文件
              this.$emit("saveFile", files);
            } else {
              dsf.layer.message("裁剪失败" + result.message, false);
            }
          },
          error: () => {
            return false;
          },
          complete: () => {},
        });
      }
    },
    // 旋转
    rotate(current) {
      this.utils.rotate(current, true);
    },
    //缩放
    zoom(num) {
      this.utils.scale(num);
    },
    // 镜像
    scaleV() {
      this.utils.setFlipV(true);
    },
    scaleH() {
      this.utils.setFlipH(true);
    },
    // 重置
    reset() {
      this.utils.reset();
    },
    // 关闭裁剪
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>
