var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_wonderfullive" }, [
        _c("div", { staticClass: "content" }, [
          _vm.isDesign
            ? _c(
                "div",
                { staticClass: "item-wrap", style: { width: _vm.cardWidth } },
                [
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    [
                      _c("el-image", {
                        attrs: { src: _vm._f("imgFormat")("/"), fit: "cover" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "time-box" }, [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(_vm.formatterDate(11223232, "mm月dd日 hh:ii"))
                        ),
                      ]),
                      _c("span", { staticClass: "playType pointer" }, [
                        _vm._v("开启提醒"),
                      ]),
                    ]),
                    _c("p", { staticClass: "ell" }, [
                      _vm._v("上海全力打造全国新一轮全面开放新高地的新举措"),
                    ]),
                    _vm._m(0),
                  ]),
                  _c("div", { staticClass: "live-type" }, [_vm._v("未开始")]),
                ]
              )
            : _vm.objData
            ? _c(
                "div",
                {
                  staticClass: "item-wrap pointer",
                  style: { width: _vm.cardWidth },
                  on: { click: _vm.goDetail },
                },
                [
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.objData["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("p", { staticClass: "ell" }, [
                      _vm._v(_vm._s(_vm.objData["title"])),
                    ]),
                    _c("div", { staticClass: "time-box" }, [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatterDate(
                              _vm.objData["time_sdate"],
                              "mm月dd日 hh:ii"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail" }, [
                      _vm.objData["job"]
                        ? _c("span", { staticClass: "job" }, [
                            _vm._v(_vm._s(_vm.objData["job"])),
                          ])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.objData["teacher"]))]),
                    ]),
                  ]),
                  _vm.objData["state_text"]
                    ? _c(
                        "div",
                        {
                          staticClass: "live-type",
                          style:
                            "background-color: " +
                            _vm.showBgColor(_vm.objData["state_text"]),
                        },
                        [_vm._v(_vm._s(_vm.objData["state_text"]))]
                      )
                    : _vm._e(),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "item-wrap pointer",
                  style: { width: _vm.cardWidth },
                  on: { click: _vm.goDetail },
                },
                [
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.data["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "time-box" }, [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatterDate(
                              _vm.data["time"],
                              "mm月dd日 hh:ii"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "playType pointer",
                          on: { click: _vm.goDetail },
                        },
                        [_vm._v(_vm._s(_vm.getType(_vm.data)))]
                      ),
                    ]),
                    _c("p", { staticClass: "ell" }, [
                      _vm._v(_vm._s(_vm.data["title"])),
                    ]),
                    _c("div", { staticClass: "detail" }, [
                      _vm.data["job"]
                        ? _c("span", { staticClass: "job" }, [
                            _vm._v(_vm._s(_vm.data["job"])),
                          ])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.data["teacher"]))]),
                    ]),
                  ]),
                  _vm.data["liveTxt"]
                    ? _c(
                        "div",
                        {
                          staticClass: "live-type",
                          style:
                            "background-color: " +
                            _vm.showBgColor(_vm.data["liveTxt"]),
                        },
                        [_vm._v(_vm._s(_vm.data["liveTxt"]))]
                      )
                    : _vm._e(),
                ]
              ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail" }, [
      _c("span", { staticClass: "job" }, [_vm._v("job")]),
      _c("span", [_vm._v("张三")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }