<template>
  <div class="dsf_wonderfullive" v-if="visible">
    <div class="content">
      <div class="item-wrap" v-if="isDesign" :style="{ width: cardWidth }">
        <div class="img-box">
          <el-image :src="'/' | imgFormat" fit="cover"></el-image>
        </div>
        <div class="info">
          <div class="time-box">
            <span class="time">{{ formatterDate(11223232, "mm月dd日 hh:ii") }}</span>
            <span class="playType pointer">开启提醒</span>
          </div>
          <p class="ell">上海全力打造全国新一轮全面开放新高地的新举措</p>
          <div class="detail">
            <span class="job">job</span>
            <span>张三</span>
          </div>
        </div>
        <div class="live-type">未开始</div>
      </div>
      <div class="item-wrap pointer" v-else-if="objData" :style="{ width: cardWidth }" @click="goDetail">
        <div class="img-box">
          <!-- <el-image :src="data['cover'] | imgFormat" fit="cover"></el-image> -->
          <dsf-nc-image :src="objData['cover'] | imgFormat" :error-src="defaultSrc" />
        </div>
        <div class="info">
          <p class="ell">{{ objData["title"] }}</p>
          <div class="time-box">
            <span class="time">{{ formatterDate(objData["time_sdate"], "mm月dd日 hh:ii") }}</span>
            <!-- <span class="playType pointer" @click="goDetail">{{ getType(objData) }}</span> -->
          </div>

          <div class="detail">
            <span class="job" v-if="objData['job']">{{ objData["job"] }}</span>
            <span>{{ objData["teacher"] }}</span>
          </div>
        </div>
        <div class="live-type" v-if="objData['state_text']" :style="`background-color: ${showBgColor(objData['state_text'])}`">{{ objData["state_text"] }}</div>
      </div>
      <div class="item-wrap pointer" v-else :style="{ width: cardWidth }" @click="goDetail">
        <div class="img-box">
          <!-- <el-image :src="data['cover'] | imgFormat" fit="cover"></el-image> -->
          <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
        </div>
        <div class="info">
          <div class="time-box">
            <span class="time">{{ formatterDate(data["time"], "mm月dd日 hh:ii") }}</span>
            <span class="playType pointer" @click="goDetail">{{ getType(data) }}</span>
          </div>
          <p class="ell">{{ data["title"] }}</p>
          <div class="detail">
            <span class="job" v-if="data['job']">{{ data["job"] }}</span>
            <span>{{ data["teacher"] }}</span>
          </div>
        </div>
        <div class="live-type" v-if="data['liveTxt']" :style="`background-color: ${showBgColor(data['liveTxt'])}`">{{ data["liveTxt"] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCardItemByWonderfulLive",
  ctrlCaption: "精彩直播卡片",
  mixins: [$mixins.card],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    cardWidth: {
      type: String,
      default: "580px",
    },
    detailUrl: {
      type: String,
      default: "/page.html#/pc/nc/pagelive/liveDetail?id=",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "state_text", to: "liveTxt", type: "text", desc: "直播文字" },
          { from: "state_value", to: "type", type: "text|number", desc: "直播状态" },
          { from: "subscribe", to: "subscribe", type: "text", desc: "是否预约" },
          { from: "time_sdate", to: "time", type: "text|number", desc: "直播时间" },
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "speaker", to: "teacher", type: "text", desc: "授课教师" },
          { from: "job", to: "job", type: "text", desc: "职位" },
          { from: "nc_live_info_id", to: "id", type: "text", desc: "id" },
          { from: "login_limit_text", to: "login_limit_text", type: "text", desc: "登录校验文字" },
          { from: "login_limit_value", to: "login_limit_value", type: "text", desc: "登录校验值" },
        ];
      },
    },
    objData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  components: {},
  computed: {
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
  },
  methods: {
    showBgColor(text) {
      let c = "";
      switch (text) {
        case "直播中":
          c = "#34C47C";
          break;
        case "未开始":
          c = "#3C8DDB";
          break;
        case "已结束":
          c = "#999999";
          break;
        default:
          c = "#3C8DDB";
          break;
      }
      return c;
    },
    getType(data) {
      if (data["type_value"]) {
        if (data["type_value"] == 1) {
          return "进入直播";
        } else if (data["type_value"] == 2) {
          return "回放";
        } else if (data["type_value"] == 0) {
          return "进入直播";
          // if (!data["subscribe"]) {
          //   return "开启提醒";
          // } else {
          //   return "等待直播";
          // }
        }
      }
      if (data["type"]) {
        if (data["type"] == 1) {
          return "进入直播";
        } else if (data["type"] == 2) {
          return "回放";
        } else if (data["type"] == 0) {
          return "进入直播";
          // if (!data["subscribe"]) {
          //   return "开启提醒";
          // } else {
          //   return "等待直播";
          // }
        }
      }
    },
    goDetail() {
      // if (data["type"] == 0 && !data["subscribe"]) {
      //   this.liveAppointHandle(data);
      //   return;
      // }
      console.log(this.data);
      if (this.$listeners["click"]) {
        this.$dispatch("click", this.data);
      } else {
        if (this.detailUrl) {
          console.log(this.data);
          if (this.data["login_limit_value"] && this.data["login_limit_value"] == 1 && !this.isLogin) {
            this.$openDialog({
              title: "",
              width: "400px",
              height: "370px",
              noTitle: true,
              params: {
                showClose: false,
                path: dsf.config.setting_public_login_dialog,
                loginSuccess() {
                  console.log("登录成功");
                  dsf.layer.openWindow(this.detailUrl + this.data.id);
                },
                loginFail() {
                  console.log("登录失败");
                },
              },
              onClosed() {
                dsf.layer.closeWindow();
                console.log("关闭弹窗");
              },
            });
          } else {
            // let url = this.$replace(this.detailUrl, this.data.id);
            // this.$openWindow({ url: url });
            dsf.layer.openWindow(this.detailUrl + this.data.id);
          }
        }
      }
    },
    liveAppointHandle(data) {
      let params = {
        ywid: data.id,
        ywlxValue: "live",
        ywlxText: "直播",
      };
      let loading = dsf.layer.pc.loading();
      let appointUrl = "dsfa/subscribe/saveOrCancel";
      this.$http
        .post(appointUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            data.subscribe = res.data.isDo;
          }
          dsf.layer.pc.message(res.message, true);
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    formatterDate(time, format) {
      console.log(time);
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    getUrl(cover) {
      return cover ? dsf.url.getWebPath(JSON.parse(cover)[0].relativePath) : "";
    },
  },
});
</script>
<style lang="scss" scoped></style>
