<template>
  <div class="ds_newsNewsCard-pc" :style="controlStyle">
    <div class="ds_newsNewsCard-row" @click="_clickBefore(goDetail, data)" v-if="isDesign">
      <div class="title-box">
        <p class="title ell2">关于印发《生态环境部2021年度培训计划》的通知</p>
        <div class="detail">
          <p class="source">来源</p>
          <p class="time">2021/04/05 10:00:00</p>
        </div>
      </div>
      <div class="cover-img">
        <dsf-nc-image src="" :error-src="defaultSrc" />
      </div>
    </div>
    <div class="ds_newsNewsCard-row" @click="_clickBefore(goDetail, data)" v-else>
      <div class="title-box">
        <p class="title ell2" :class="{ isHeight: data['cover'] && data['cover'].length > 2 }">{{ data["title"] }}</p>
        <div class="detail">
          <p class="source" v-if="data['source']">{{ data["source"] }}</p>
          <p class="time">{{ data["time"] ? dsf.date.format(data["time"], "yyyy.mm.dd") : "" }}</p>
        </div>
      </div>
      <div v-if="data && data['cover'] && data['cover'].length > 2" class="cover-img">
        <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcNewsCard",
  ctrlCaption: "新闻卡片",
  mixins: [$mixins.card],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    margin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    padding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    detailUrl: {
      type: String,
      default: "",
    },

    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "title", to: "title", type: "text", desc: "新闻标题" },
          { from: "source", to: "source", type: "text", desc: "来源" },
          { from: "publishTime", to: "time", type: "text", desc: "时间" },
          { from: "cover", to: "cover", type: "text", desc: "封面" },
        ];
      },
    },
    defaultSrc: {
      type: String,
      default: dsf.config.setting_nc_image_default_img,
    },
  },
  watch: {},
  computed: {
    controlStyle() {
      let { margin, width, height, padding, isDesign, visible } = this;
      let style = {
        margin: margin.join("px ") + "px",
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
  },
  data() {
    return {};
  },
  mounted() {
    // if (this.isDesign) {
    //   this.data = {
    //     title: "关于印发《生态环境部2021年度培训计划》的通知",
    //     publishTime: "2021/04/05 10:00:00",
    //     source: "来源",
    //     cover: null,
    //   };
    // }
  },
  methods: {
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        if (this.$checkLogin) {
          this.$checkLogin().then(res => {
            if (res) next(...params);
          });
        } else {
          next(...params);
        }
      } catch (err) {
        console.log(err);
      }
    },
    goDetail(item) {
      if (this.$listeners["click"]) {
        this.$dispatch("click", item);
      } else {
        // if (this.detailUrl) {
        //   let url = this.$replace(this.detailUrl, item);
        //   url && dsf.layer.openWindow(url);
        // }
        if (item.type == 3) {
          item.externalLink && window.open(item.externalLink);
        } else {
          if (this.detailUrl) {
            let url = this.$replace(this.detailUrl, item);
            url && dsf.layer.openWindow(url);
          }
        }
      }
      //获取积分
      dsf.http
        .get("nc/cms/articles/queryArticlesById", { id: item.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            dsf.layer.message(res.message || "积分异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(res.message || "积分异常", false);
        })
        .always(res => {});
    },
  },
});
</script>
