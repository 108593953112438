var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_search_column" }, [
    _c(
      "div",
      {
        staticClass: "item_inner",
        on: {
          click: function ($event) {
            return _vm.toDetail(_vm.data)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "item-left-img" },
          [
            _c("dsf-image", {
              attrs: {
                src: _vm._f("imgFormat")(_vm.data["cover"]),
                "error-src": _vm.dsf.config.setting_nc_image_default_img,
              },
            }),
            _c("span", { staticClass: "item-type" }, [
              _vm._v(_vm._s(_vm.data.typename || "直播")),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "item-right-desc" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", { staticClass: "name ellipsis-2" }, [
              _vm._v(_vm._s(_vm.data.title)),
            ]),
          ]),
          _vm.pageType == "inland"
            ? _c("p", { staticClass: "ds-introduction" }, [
                _vm._v(
                  " 以习近平新时代中国特色社会主义思想为指导，深入学习贯彻党的十九大和十九届二中、三中、四中、五中、六中全会精神，持续开展党史学习教育，牢固树立“四个意识”，坚定“四个自信”，做到“两个维护”，准确把握全面从严治党工作要求和税务系统党的建设工作规范以及意识形态、政务公开、保密管理、舆情应对等工作的新形势和新要求，熟练掌握办公室（党委办公室）工作基本制度、基本流程，切实提升组织、沟通、协调、执行、写作等办文办事办会基本能力，更好履行办公室工作职责，服务学院教育培训事业高质量发展。 "
                ),
              ])
            : _vm._e(),
          _c("p", { staticClass: "item-exam-rule" }),
          _c("ul", { staticClass: "item-bottom-desc" }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }