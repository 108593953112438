<template>
  <div class="dsf_nc_mine ds-control" :style="{ width: itemWidth }">
    <div class="minecontent" v-if="it">
      <div class="yeartitle">
        <span>{{ it.learnYear || "" }}年度学时</span>
        <span class="people">超过{{ it.rankRate || 0 }}%同学</span>
      </div>
      <div class="speed">
        <div class="sleft">
          <span></span>
          <i>{{ it.hoursRate || 0 }}%</i>
        </div>
        <div class="sright">{{ it.learnedHours || 0 }}/{{ it.learnHours }}h</div>
      </div>
      <div class="speedbg"><span :style="{ width: it.hoursRate + '%' }"></span></div>
      <div class="choose">
        <p>
          <span>{{ courseText }}</span>
          <span>{{ it.courseNum || 0 }}门</span>
        </p>
        <p>
          <span>{{ specialText }}</span>
          <span>{{ it.classNum || 0 }}门</span>
        </p>
      </div>
    </div>
    <div v-if="isDesign">我的</div>
  </div>
</template>

<script>
import topTitle from "../../base/onStudy/topTitle";
export default dsf.component({
  name: "DsfNcMine",
  ctrlCaption: "我的",
  mixins: [$mixins.control],
  props: {
    itemWidth: {
      type: String,
      default: "390px",
    },
    courseText: {
      type: String,
      default: "课程:",
    },
    specialText: {
      type: String,
      default: "专题班:",
    },
  },
  components: {
    topTitle,
  },
  data() {
    return {
      num: 75,
      it: null,
    };
  },
  mounted() {
    if (!this.isDesign) this.loadMineInfo();
  },
  methods: {
    loadMineInfo() {
      const sourceUrl = "/nc/learn/year/task";
      const loading = dsf.layer.pc.loading();
      let params = {};
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            this.it = res.data;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
  },
});
</script>
