var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_cardItemByNote" }, [
        _c("div", { staticClass: "content" }, [
          _vm.isDesign
            ? _c("div", { staticClass: "item-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "img-box pointer",
                    style: {
                      width: _vm.imgWidth,
                      height: _vm.imgHeight,
                      margin: _vm.imgMargin,
                    },
                  },
                  [
                    _c("el-image", {
                      attrs: {
                        src: _vm._f("imgFormat")(_vm.src),
                        fit: "cover",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "info",
                    style: { width: "calc(96% - " + _vm.imgWidth + ")" },
                  },
                  [
                    _c("p", { staticClass: "title ell" }, [
                      _vm._v("笔记名称笔记名称笔记名称"),
                    ]),
                    _vm._m(0),
                    _c("div", { staticClass: "time" }, [
                      _c(
                        "span",
                        { staticClass: "video-time" },
                        [
                          _c("dsf-icon", { attrs: { name: "shijian2" } }),
                          _vm._v(" 00:01:38 "),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "video-date" }, [
                        _vm._v("2021-01-22 13:22:08"),
                      ]),
                    ]),
                  ]
                ),
              ])
            : _c(
                "div",
                {
                  staticClass: "item-wrap",
                  on: {
                    click: function ($event) {
                      return _vm._clickBefore(_vm.goDetail)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "img-box pointer",
                      style: {
                        width: _vm.imgWidth,
                        height: _vm.imgHeight,
                        margin: _vm.imgMargin,
                      },
                    },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.data["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info",
                      style: { width: "calc(96% - " + _vm.imgWidth + ")" },
                    },
                    [
                      _c("p", { staticClass: "title ell" }, [
                        _vm._v(_vm._s(_vm.data["title"] || "")),
                      ]),
                      _c("p", { staticClass: "from ell2" }, [
                        _vm._v(" 来源： "),
                        _c("span", [_vm._v(_vm._s(_vm.data["from"] || ""))]),
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _c(
                          "span",
                          { staticClass: "video-time" },
                          [
                            _c("dsf-icon", { attrs: { name: "shijian2" } }),
                            _vm._v(
                              " " + _vm._s(_vm.data["videoTime"] || "") + " "
                            ),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "video-date" }, [
                          _vm._v(_vm._s(_vm.data["time"] || 0)),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "from ell2" }, [
      _vm._v(" 来源： "),
      _c("span", [
        _vm._v(
          "数字经济时代云端一体“芯”机遇第四讲：把握机遇，携手创“芯”，共建未来"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }