<template>
  <div class="dsf_search_column" v-if="isDesign">
    <div class="item_inner">
      <div class="item-left-img">
        <el-image :src="'' | imgFormat"></el-image>
        <span class="item-type">考试</span>
        <span class="item-time">2012-10-15 ~ 2012-10-25</span>
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">标题</span>
          <span class="task">任务</span>
        </div>
        <p class="item-exam-rule">
          <span>考试总分: 100分</span>
          <span>及格总分: 60分</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <span>考试次数: 5次</span>
          </li>
          <li>
            <span>已考次数: 1次</span>
          </li>
          <li>
            <span>剩余次数: 4次</span>
          </li>
        </ul>
      </div>
      <div class="rate-wrap">
        <span class="rate">98分</span>
        <span class="rate-text">当前分数</span>
      </div>
    </div>
  </div>
  <div class="dsf_search_column" v-else>
    <div class="item_inner" @click="toDetail(data)">
      <div class="item-left-img">
        <dsf-image :src="data['cover'] | imgFormat" :error-src="dsf.config.setting_nc_image_default_img" />
        <span class="item-type">{{ data.typeText || "考试" }}</span>
        <span class="item-time">{{ data.start_time }} ~ {{ data.end_time }}</span>
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">{{ data.name }}</span>
          <span class="task" v-if="data.isTask">任务</span>
        </div>
        <p class="ds-introduction" v-if="pageType == 'inland'">
          以习近平新时代中国特色社会主义思想为指导，深入学习贯彻党的十九大和十九届二中、三中、四中、五中、六中全会精神，持续开展党史学习教育，牢固树立“四个意识”，坚定“四个自信”，做到“两个维护”，准确把握全面从严治党工作要求和税务系统党的建设工作规范以及意识形态、政务公开、保密管理、舆情应对等工作的新形势和新要求，熟练掌握办公室（党委办公室）工作基本制度、基本流程，切实提升组织、沟通、协调、执行、写作等办文办事办会基本能力，更好履行办公室工作职责，服务学院教育培训事业高质量发展。
        </p>
        <p class="item-exam-rule">
          <span>考试总分: {{ data.score }}分</span>
          <span>及格总分: {{ data.pass_score }}分</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <span>考试次数: {{ data.surplus_count }}次</span>
          </li>
          <li>
            <span>已考次数: {{ data.exam_count || 0 }}次</span>
          </li>
          <li>
            <span>剩余次数: {{ data.eRemainTimes || 0 }}次</span>
          </li>
        </ul>
      </div>
      <div class="rate-wrap">
        <span class="rate">{{ data.exam_score || 0 }}分</span>
        <span class="rate-text">当前分数</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dsfSearchExamColunm",
  ctrlCaption: "考试",
  props: {
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pageType: {
      type: String,
      default: "tacitly",
    },
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toDetail(item) {
      this.$emit("toDetail", { name: "exam", id: item.id });
    },
    formatPercent(it) {
      return it || "0";
    },
  },
};
</script>
