var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specialdetail_courselists",
      style: { display: _vm.visible ? "block" : "none" },
    },
    [
      _c("special-detail-course-item", {
        attrs: {
          lists: _vm.lists,
          "detail-data": _vm.detailData,
          isview: _vm.isview,
          openType: _vm.openType,
          socketUrl: _vm.socketUrl,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }