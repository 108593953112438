<template>
  <div class="user-invitation">
    <div class="user-invitation-inner">
      <div class="invitation-breadcrumb">
        <cite>用户邀请</cite>
      </div>
      <div class="invitation-info">
        <div class="invitation-image">
          <DsfNcImage :src="qrcodeurl"></DsfNcImage>
        </div>
        <div class="invitation-text-wrap">
          <p class="text-share">分享二维码邀请更多成员加入</p>
          <p class="text-share text-share-small">被邀用户扫码后会按照您设置的审核流程与注册信息进行注册</p>
          <div class="invitation-day-select">
            <span class="text">有效期 (天)：</span>
            <el-select v-model="form.validtime_value" clearable placeholder="请选择" @change="changeDate">
              <el-option v-for="item in daysOpts" :key="item.value" :label="item.text" :value="item.value"></el-option>
            </el-select>
          </div>
          <a class="invitation-downcode" @click="doencodeFn">
            <dsf-icon name="yunduanxiazai" />
            下载二维码
          </a>
        </div>
      </div>
      <div class="invitation-item">
        <h1 class="invitation-title">审核流程设置</h1>
        <div class="invitation-item-content">
          <div class="content-switch">
            <span>注册审核:</span>
            <el-switch v-model="form.isaudit"></el-switch>
          </div>
        </div>
      </div>
      <div class="invitation-item">
        <h1 class="invitation-title">注册信息设置</h1>
        <div class="invitation-item-content">
          <div class="content-register-setting">
            <userInvitationForm :btnsShow="false" :isRequest="false" @updateParams="updateParams" ref="formdata" :defaultList="defaultList"></userInvitationForm>
          </div>
        </div>
      </div>
      <div class="dsf_apply-btns">
        <el-button class="sumbit-btn" @click="sumbitForm">确 定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import userInvitationForm from "./userInvitationForm.vue";
export default dsf.component({
  name: "DsfNcUserInvitation",
  ctrlCaption: "注册邀请",
  mixins: [$mixins.control],
  data() {
    return {
      url: "nc/reg/updateUnitRelation",
      reqUrl: "nc/reg/getUnitRelation",
      daysOpts: [
        {
          value: "1",
          label: "1天",
        },
        {
          value: "2",
          label: "2天",
        },
      ],
      id: "",
      form: {
        validtime_value: "",
        isaudit: false,
      },
      defaultList: [
        { required: true, name: "姓名", text: "请输入姓名", base: true, rule: "name", type: "textbox" },
        { required: true, name: "手机号", text: "请输入参与人手机号", base: true, rule: "phone", type: "textbox" },
      ],
      qrcodeurl: "",
      reqParams: {
        items: [],
      },
    };
  },
  async created() {
    this.id = this.queryString["unitid"] || "";
    await this.initDays();
    this.getData();
  },
  components: { userInvitationForm },
  methods: {
    sumbitForm() {
      let params = {
        unitid: this.id || "",
      };
      // params = Object.assign(params, this.reqParams);
      params.validtime_value = this.form.validtime_value;
      this.changeDate(this.form.validtime_value);
      params.validtime_text = this.form.validtime_text;
      params.isaudit = this.form.isaudit ? "1" : "0";
      params.items = this.reqParams.items;
      // if (!params.validtime_text) return this.dsf.layer.message("请选择有效期", false);
      console.log(params);
      const loading = dsf.layer.loading();
      this.dsf.http
        .post(this.url, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.dsf.layer.message(res.message || "保存成功", true);
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    getData() {
      const loading = dsf.layer.loading();
      let params = {
        unitid: this.id || "",
      };
      this.dsf.http
        .get(this.reqUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.reqParams = res.data;
            this.qrcodeurl = this.reqParams.qrcodeurl;
            this.form.validtime_value = this.reqParams.validtimeValue;
            this.form.isaudit = this.reqParams.isauditValue == "0" ? false : true;
            this.$refs.formdata.formatterDataObj(res.data);
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    changeDate(val) {
      let arr = this.daysOpts.filter(item => {
        return item.value == val;
      });
      if (arr.length) {
        this.form.validtime_value = arr[0].value;
        this.form.validtime_text = arr[0].text;
      }
    },

    downloadQRCode() {},
    updateParams(val) {
      // console.log(val);
      this.reqParams.items = val.items;
    },
    initDays() {
      const sourceUrl = "dict/getListByClass?dictCode=nc.register.dict.validtime";
      this.$http
        .get(sourceUrl, {})
        .done(({ success, data, message }) => {
          if (success) {
            // console.log(data);
            this.daysOpts = data;
          } else {
            dsf.layer.pc.message(message, false);
          }
        })
        .error(error => {
          dsf.httpError(error);
        });
    },
    doencodeFn() {
      let it = this.reqParams.qrcodeurl;
      let title = this.reqParams.unitname + "邀请码" || "邀请码";
      if (it) {
        const ua = navigator.userAgent;
        if (ua.toLowerCase().indexOf("dingtalk") > 0) {
          dd.biz.util.previewImage({
            urls: [location.origin + it.extStr], //图片地址列表
            current: location.origin + it.extStr, //当前显示的图片链接
            onSuccess: function (result) {
              /**/
            },
            onFail: function (err) {},
          });
          return;
        }
        var filename; //图片名称
        var filetype; //图片类型
        // var path = it.extStr;
        var path = it;
        if (path) {
          var file = path.substring(path.lastIndexOf("/") + 1, path.length);
          var fileArr = file.toLowerCase().split(".");
          filename = it.title || title || fileArr[0];
          filetype = fileArr[1];
        }
        if (!filename) {
          filename = it.title || title;
        }
        if (!filetype) {
          filetype = "png";
        }
        var canvas = document.createElement("canvas");
        var img = document.createElement("img");
        img.onload = function (e) {
          canvas.width = img.width;
          canvas.height = img.height;
          var context = canvas.getContext("2d");
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob(blob => {
            var a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = filename + "." + filetype;
            a.click();
          }, `image/${filetype}`);
        };
        img.setAttribute("crossOrigin", "Anonymous");
        img.src = it;
      }
    },
  },
});
</script>
