<template>
  <div class="dsf_course_player" :style="{ width: itemWidth }">
    <div class="course-player-inner" :style="{ height: docHeight }">
      <div class="course-player-left">
        <div class="course-header-left">
          <div class="course-header-left-wrap">
            <span class="course-header-left-title ellipsis" :title="baseInfo.title || ''" v-if="baseInfo">{{ baseInfo.title || "" }}</span>
            <div class="course-header-rate" v-if="courseRate">
              <el-rate v-model="courseRate" :max="maxRate" disabled show-score text-color="#ff9900" allow-half></el-rate>
            </div>
            <div class="course-header-left-buttons" v-if="showBtnChange">
              <button :class="{ 'course-header-left-buttons-sel': playPattern }" @click="changePattern(true)">视频模式</button>
              <button :class="{ 'course-header-left-buttons-sel': !playPattern }" @click="changePattern(false)">讲义模式</button>
            </div>
            <div :class="['course-hear-or-see']">
              <button :class="['btn1', videoStatus ? '' : 'transparent']" @click="toggleStatus">
                <i :class="['iconfont', videoStatus ? 'icon-yanjing1' : 'icon-24gf-headphones']"></i>
                <span>{{ videoStatus ? "看课" : "听课" }}</span>
              </button>
            </div>
          </div>
          <div class="course-header-left-event" v-if="isView != 1">
            <span :class="{ 'active-item': isAssessed }" @click="handleComment()">
              <i class="iconfont icon-pingjia1"></i>
              评价
            </span>
            <span @click="handleLiked()" :class="{ 'active-item': hasLiked }" v-if="isShowLiked">
              <i class="iconfont icon-dianzan"></i>
              {{ hasLiked ? "已点赞" : "点赞" }}
            </span>
            <span @click="handleCollect()" :class="{ 'active-item': hasCollected }">
              <i class="iconfont icon-shoucang1"></i>
              {{ hasCollected ? "已收藏" : "收藏" }}
            </span>
            <span @click="joinStudy" :class="{ 'active-item': hasJoin }">
              <i class="iconfont icon-kecheng-jiarujihua"></i>
              {{ hasJoin ? "取消计划" : "加入计划" }}
            </span>
            <span @click="handleBack()">
              <i class="iconfont icon-kecheng-fankui"></i>
              反馈
            </span>
          </div>
        </div>
        <div class="course-player-pattern">
          <!-- 播放模式 -->
          <!-- @mousedown.stop="playerMousedown('dragItem', $event)"
            @mousemove.stop="playerMousemove('dragItem', $event)"
            @mouseup.stop="playerMouseup('dragItem', $event)" -->
          <div ref="dragItem" class="course-player-drag" :class="{ playPattern: !playPattern }" v-if="curPlayItem && showBtnChange">
            <dsf-nc-ckplayer
              ref="coursePlayer"
              v-if="curPlayItem"
              :videoSource="videoSrc"
              :cover="baseInfo && baseInfo.cover"
              :allowDrag="allowDrag"
              :is_allow_drag="true"
              :videoStatus="videoStatus"
              @playerHasPlay="playerHasPlay()"
              @playerHasPause="playerHasPause()"
              @playHasEnd="playEnd"
              @playerPlayingTime="playerPlayingTime"
              @playbackRateUpdate="playbackRateUpdate"
              @nextCourse="nextCourse"
            />
            <!-- <div class="course-cover" v-if="videoStatus">
              <img :src="baseInfo && getCover(baseInfo.cover)" alt="" />
            </div> -->
          </div>
          <!-- 讲义模式 -->
          <div class="course-document-pattern" v-if="showDocument">
            <iframe :src="docUrl" frameborder="0" scrolling="no" style="background: rgba(255, 255, 255, 1)"></iframe>
          </div>
        </div>
      </div>
      <div class="course-player-right" ref="rightBox">
        <div class="course-toggle-bar-wrap" @click="toggle()">
          <div class="toggle-bar">
            <i>||</i>
          </div>
        </div>
        <div class="right-box-inner">
          <div class="right-header-wrap">
            <div class="right-header" v-if="!dsf.isEmptyObject(baseInfo) && baseInfo.teacherList && baseInfo.teacherList.length > 0">
              <el-carousel height="140px" :arrow="baseInfo.teacherList.length > 1 ? 'hover' : 'never'" :indicator-position="baseInfo.teacherList.length > 1 ? '' : 'none'" :autoplay="false">
                <el-carousel-item v-for="item in baseInfo.teacherList" :key="item.id">
                  <div class="teacher" @click="toTeacherDetail(item)">
                    <div class="avatar">
                      <DsfNcDefaultHeader
                        class="default-avatar"
                        :src="item.photo"
                        :width="'90px'"
                        :height="'120px'"
                        :round="false"
                        :defaultHeaderUrl="dsf.config.setting_nc_image_teacher_header_img || dsf.config.setting_public_teacher_default_header"
                      />
                    </div>
                    <div class="desc">
                      <h3 class="name">{{ item.name }}</h3>
                      <div class="dept ellipsis-2">{{ item.unitJob }}</div>
                      <span class="total">{{ item.courseNum || 0 }}门精品课程</span>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="right-tab-items">
            <div class="tab-menus-wrap">
              <div class="tab-menus">
                <span :class="['tab-menus-name', getCurTabSelMenu($index)]" v-for="(item, $index) in tabList" :key="item.id" @click="tabMenuChange($index)" v-show="item.show">
                  {{ item.name }}
                </span>
              </div>
            </div>
            <div class="tab-content" :style="`height:${winH - 220}px`">
              <div class="content-item" v-if="tabIdx == 0">
                <dsf-nc-course-chapter :chapters="chapterInfo" @changeCurPlayCourse="changeCurPlayCourse" />
              </div>
              <div class="content-item" v-if="tabIdx == 1">
                <div class="introduce" v-if="baseInfo && baseInfo.content" v-html="baseInfo.content || ''"></div>
                <div class="desc-laybes" v-if="baseInfo && baseInfo.labels && baseInfo.labels.length">
                  <span>标签:</span>
                  <div class="tags">
                    <span v-for="item in baseInfo.labels" :key="item" class="tag">{{ item }}</span>
                  </div>
                </div>
                <div class="no-data" v-if="!baseInfo.content">
                  <i class="no-data-icon"></i>
                  <p>暂无简介</p>
                </div>
              </div>
              <div class="content-item" v-if="tabIdx == 2 && !isView">
                <dsf-nc-note-item :curItem="getCurPlayItem" :relationId="queryiId" :queryiType="queryiType" :item="baseInfo" />
              </div>
              <div class="content-item" v-if="tabIdx == 3 && isView">
                <dsf-nc-advice :relationId="queryiId" :queryiType="queryiType" :item="baseInfo" />
              </div>
              <div class="content-item" v-if="tabIdx == 4 && dsf.config.setting_nc_player_course_comment == '1'">
                <dsf-view-part path="/pc/nc/page/pc/course/coursecomment" class="comment" style="height: 100%"></dsf-view-part>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-comment" v-if="showComment">
      <div class="comment-mask" @click="showComment = !showComment"></div>
      <commentDialog :id="queryiId" :queryiType="queryiType" :item="initItem" :isComment="initItem.isAssessed" @commentSuccess="commentSuccess" />
    </div>

    <faceDialog v-if="isFace" :specialId="queryiId" @open="open" @shure="shure" :isShow="isShow"></faceDialog>
    <!-- <div class="masks" v-if="!isLogin || isLogin === ''" @click="toMainPage"></div> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import commentDialog from "./commentDialog";
import courseMxin from "../../mixin/courseExam";
import faceDialog from "../common/faceDialog.vue";
export default dsf.component({
  name: "DsfNcCoursePlayer",
  ctrlCaption: "课程播放",
  mixins: [$mixins.control, courseMxin],
  props: {
    moreUrl: {
      type: String,
      default: "/",
    },
    teacherDetailUrl: {
      type: String,
      default: "",
    },
    itemMargin: {
      type: String,
      default: "50px 0 58px 0",
    },
    showAdvice: {
      type: Boolean,
      default: false,
    },
    isShowLiked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getCurPlayItem", "getCurPlayTime"]),
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
    videoSrc() {
      let src = "";
      const data = this.curPlayItem;
      const type = data.rTypeValue;
      const fileUrl = this.filterFileUrl(data);
      switch (type) {
        case "link":
          src = data.linkPcUrl;
          break;
        case "document":
          src = "preview/file?url=" + fileUrl;
          break;
        case "audio":
          src = fileUrl;
          break;
        case "video":
          src = fileUrl;
          break;
        case "medium":
          src = fileUrl;
          break;
        default:
          break;
      }
      return type == "video" ? src : dsf.url.getWebPath(src);
    },
    videoType() {
      const data = this.curPlayItem;
      let type = data.rTypeValue;
      switch (type) {
        case "audio":
          type = "audio/mp3";
          break;
        case "video":
          type = "video/m3u8";
          break;
        case "mp4":
          type = "video/mp4";
          break;
        default:
          type = "video/m3u8";
          break;
      }
      return type;
    },
    isFace() {
      return localStorage.getItem("socketUrl") || null;
    },
    hasCollected() {
      let { isCollected } = this.initItem;
      return isCollected;
    },
    hasJoin() {
      let { isPlaned } = this.initItem;
      return isPlaned;
    },
    isAssessed() {
      let { isAssessed } = this.initItem;
      return isAssessed;
    },
    hasLiked() {
      let { isLiked = false } = this.initItem;
      return isLiked;
    },
    //是否显示视频模式讲义模式切换
    showBtnChange() {
      let { curItemType } = this;
      let state = true;
      if (curItemType == "document" || curItemType == "link" || curItemType == "medium") state = false;
      return state;
    },
    showDocument() {
      let { curPlayItem, curItemType, docUrl } = this;
      let state = false;
      if (curPlayItem && docUrl && curItemType !== "link") state = true;
      return state;
    },
    allowDrag() {
      const data = this.curPlayItem;
      const isDrag = data.finishedRate === 100 ? true : false;
      return isDrag;
    },
  },
  components: { commentDialog, faceDialog },
  data() {
    return {
      toggleFlag: false,
      videoStatus: false,
      tabIdx: 0,
      playPattern: true, //默认播放模式 只有在讲义模式下播放器才允许拖拽
      docHeight: window.innerHeight + "px", //讲义模式高度
      winH: window.innerHeight,
      chapterInfo: [], //目录信息
      curItemType: null, //当前播放类型
      curPlayItem: null, //当前播放课件
      baseInfo: {},
      isFold: false,
      playTimer: null,
      queryiId: this.$route.query.id,
      queryiType: this.$route.query.ztb || "course",
      isView: this.$route.query.isView || 0,
      initItem: {},
      playConfig: {}, //播放器配置
      docUrl: null,
      count: 0,
      preUrl: dsf.config.setting_public_file_host,
      coordinate: null, //拖拽坐标
      courseRate: null,
      maxRate: 5,
      showComment: false,
      playrate: 1,
      isPlayIng: false,
      examPlayerNode: 0,
      examAnserCount: 0,
      hasShowExamDialog: false,
      examShowDialog: false,
      examTimer: null,
      tabList: [
        {
          name: "目录",
          id: Math.random(),
          show: 1,
        },
        {
          name: "介绍",
          id: Math.random(),
          show: 1,
        },
        {
          name: "笔记",
          id: Math.random(),
          show: this.$route.query.isView ? 0 : 1,
        },
        {
          name: "意见建议",
          id: Math.random(),
          show: this.$route.query.isView ? 1 : 0,
        },
        {
          name: "评论",
          id: Math.random(),
          show: dsf.config.setting_nc_player_course_comment == 1 && !this.$route.query.isView ? 1 : 0,
        },
      ],
      throttleFormat: null,
    };
  },
  created() {
    let isOpenCourse = localStorage.getItem("isOpenCourse"); //1
    let reloadCourse = localStorage.getItem("reloadCourse"); //2
    this.timer2 = setTimeout(() => {
      localStorage.removeItem("reloadCourse"); //4
    }, 2000);
    let {
      setting_nc_player_one_player, //只允许打开一个窗口限制
    } = window.$$serverConfig;
    if (isOpenCourse == "open" && reloadCourse != "reloadCourse") {
      let open1 = localStorage.getItem("isOpenCourse"); //7
      let load1 = localStorage.getItem("reloadCourse");
      localStorage.removeItem("isOpenCourse");
      localStorage.removeItem("reloadCourse");
      this.timer3 = setTimeout(() => {
        console.log(open1, load1, setting_nc_player_one_player);
        if (open1 == "open" && load1 !== "reloadCourse" && setting_nc_player_one_player && !this.isDesign) {
          this.$alert("已有在学课程正在学习中，即将退出当前窗口！", {
            confirmButtonText: "确定",
            callback: action => {},
          });
          this.timer4 = setTimeout(() => {
            window.opener = null;
            location.href = "about:blank";
            window.close();
            localStorage.removeItem("reloadCourse");
            localStorage.removeItem("isOpenCourse"); //6
          }, 2000);
        } else {
          // history.go(0);
        }
      }, 1000);
    } else {
      setInterval(() => {
        localStorage.setItem("isOpenCourse", "open"); //5
      }, 1000);
    }
  },
  mounted() {
    if (!this.isDesign) {
      if (this.queryiId) {
        this.loadBaseInfo();
        this.loadCourses();
      } else {
        dsf.layer.pc.message("当前课程id为空", false);
      }
    }
    window.addEventListener("storage", () => {
      this.$root.isFace = JSON.parse(localStorage.getItem("isFace"));
      // 人脸识别
      if (JSON.parse(localStorage.getItem("isFace")) && this.$route.query.type == "ztb") {
        this.faceInteval();
      }
    });

    window.addEventListener("beforeunload", () => {
      let params = {
        courseId: this.queryiId,
        coursewareId: this.curPlayItem.id,
        classId: this.queryString.classid || "",
        watchPoint: this.getCurPlayTime,
        pulseRate: this.playrate,
        pulseTime: 10,
      };

      const formData = new FormData();
      Object.keys(params).forEach(key => {
        let value = params[key];
        if (typeof value !== "string") {
          // formData只能append string 或 Blob
          value = JSON.stringify(value);
        }
        formData.append(key, value);
      });
      navigator.sendBeacon("nc/course/play/pulseSaveRecord", formData);
      localStorage.setItem("reloadCourse", "reloadCourse");
    }); //3
    // this.throttleFormat = _.throttle(this.formatSeconds, 3000);
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.coursePlayer.playerPause();
        this.$refs.coursePlayer?.exitFull();
      });
    },
    commentUpdateProgress() {
      const sourceUrl = "nc/course/play/pulseSaveRecord";
      let params = {
        courseId: this.queryiId,
        coursewareId: this.curPlayItem.id,
        classId: this.queryString.classid || "",
        watchPoint: this.getCurPlayTime,
        pulseRate: this.playrate,
        pulseTime: 10,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadPost(
        sourceUrl,
        params,
        res => {},
        err => {
          dsf.layer.pc.message(err.message, false);
        }
      );
    },
    getCover(cover) {
      return JSON.parse(cover)[0]["relativePath"];
    },
    initExamTimer() {
      this.loadExamIds(this.openCourseExam);
    },
    openCourseExam() {
      let { useQuiz } = this.chapterInfo;
      /****
       * isCompleteContinue 1:必须做完才能继续课程 0:可以跳过不做
       * isIgnoreRight 1: 做题结果无论对错都可以继续学 0:做题结果必须全部正确方可继续学习  (isCompleteContinue === 1)
       * isIgnoreError 1:答错继续答题 0:答错返回上个节点   (isIgnoreRight === 0)
       * isAlwaysQuiz 1:每次都弹出题，0:只第一次弹出题目
       * quizInterval 每n分钟做题一次
       * quizNum 每次做n道题目
       * quizText 题库名称
       * quizValue 题库ID
       ****/
      let _this = this;
      if (useQuiz && !_this.hasShowExamDialog) {
        let count = 0;
        const { isCompleteContinue, isIgnoreRight, isIgnoreError, isAlwaysQuiz, quizInterval, quizNum, quizText, quizValue } = useQuiz;
        if ((isAlwaysQuiz == 0 && this.examShowDialog) || isAlwaysQuiz == 1) {
          _this.$refs.coursePlayer?.playerPause();
          _this.$refs.coursePlayer?.audioPause();
          _this.$refs.coursePlayer?.exitFull();
          if (_this.examIds.length) {
            let qid = _this.examIds[count];
            _this.hasShowExamDialog = true;
            _this.$openDialog({
              title: "随堂测试",
              width: "600px",
              height: "400px",
              params: {
                cb: (data, that) => {
                  //答题正确 正确数量加一
                  if (!data && !that) {
                    _this.examAnserCount = 0;
                    _this.hasShowExamDialog = false; //点击关闭按钮
                  }
                  if (data && data.result != "error") ++_this.examAnserCount;
                  ++count; //答题数量
                  if (isCompleteContinue == 1) {
                    if (isIgnoreRight == 1) {
                      //只要完成了对应的答题 无论正确错误都可以继续学习
                      if (count === quizNum) {
                        _this.examIds = null;
                        _this.hasShowExamDialog = false;
                        _this.examAnserCount = 0;
                        let timer = setTimeout(() => {
                          that.$dialog.close();
                          clearTimeout(timer);
                        }, 1000);
                      }
                    }
                    if (isIgnoreRight == 0) {
                      if (isIgnoreError == 1) {
                        //做题结果必须全部正确方可继续学习
                        //如果答题正确次数小于题目数量 重新请求题库id
                        if (_this.examAnserCount < quizNum && count > quizNum) {
                          _this.loadExamIds(() => {
                            //当前答题次数已经等于请求条数(题目已经答完)
                            if (count > quizNum) count = 0;
                            that.previewStatus = false;
                            _this.examIds && that.queryData(_this.examIds[count]);
                          });
                        }
                        if (_this.examAnserCount === quizNum) {
                          _this.hasShowExamDialog = false;
                          _this.examAnserCount = 0;
                          that.$dialog.close();
                        }
                      } else {
                        //答错关闭
                        if (data.result === "error") {
                          _this.examIds = null;
                          let node = _this.examPlayerNode - quizInterval * 60 || 0;
                          if (node < 0) node = 0.1;
                          dsf.layer.message("由于当前题目答错,即将返回上一节点", false);
                          _this.setCurrentSeektime(node);
                          _this.$refs.coursePlayer?.initCoursePlayer();
                          _this.hasShowExamDialog = false;
                          _this.examAnserCount = 0;
                          that.$dialog.close();
                        }
                        if (_this.examAnserCount === quizNum) {
                          _this.hasShowExamDialog = false;
                          _this.examAnserCount = 0;
                          that.$dialog.close();
                        }
                      }
                    }
                  }
                  if (isCompleteContinue == 0) {
                    if (count === quizNum) {
                      //已经答完规定题数
                      _this.examIds = null;
                      _this.hasShowExamDialog = false;
                      _this.examAnserCount = 0;
                      let timer = setTimeout(() => {
                        that.$dialog.close();
                        clearTimeout(timer);
                      }, 1000);
                    }
                  }
                  if (_this.examIds && data) {
                    if (count > quizNum) count = 0;
                    that.previewStatus = false;
                    let timer = setTimeout(() => {
                      _this.examIds && that.queryData(_this.examIds[count]);
                      clearTimeout(timer);
                    }, 1000);
                  }
                },
              },
              content: "DsfTestPaperComponent",
              dialogArgs: {
                qid,
                packageId: quizValue,
                showSave: true,
                showClose: isCompleteContinue == 0,
              },
              showClose: false,
              hasFooter: false,
              center: "center",
            });
          }
        }
      }
    },
    faceInteval() {
      this.$root.ws();
      if (this.$root.verifyTime2 >= -1) {
        clearInterval(this.faceTimer);
        this.faceTimer = setInterval(() => {
          this.$root.verifyTime2 = JSON.parse(JSON.parse(localStorage.getItem("verifyTime2")));
          this.$root.verifyTime2 = this.$root.verifyTime2 - 1;
          localStorage.setItem("verifyTime2", JSON.stringify(this.$root.verifyTime2));
          if (this.$root.verifyTime2 <= 0) {
            this.$root.verifyTime2 = Number(JSON.parse(JSON.parse(localStorage.getItem("verifyTime")))) * 60;
            localStorage.setItem("verifyTime2", Number(JSON.parse(localStorage.getItem("verifyTime"))) * 60);
            this.$root.isFace = true;
            this.faceTimeOut();
          }
        }, 1000);
      } else {
        clearInterval(this.faceTimer);
      }
    },
    faceTimeOut() {
      this.count = this.count + 1;
      this.faceInteval();
      // if (this.count == 0) {
      //   this.faceInteval();
      // } else {
      //   clearTimeout(this.timerface);
      //   this.timeface = setTimeout(() => {
      //     this.faceInteval();
      //     // localStorage.setItem("isFace", JSON.stringify(true));
      //   }, this.$root.verifyTime2 * 1000);
      // }
    },
    loadExamIds(callback) {
      const sourceUrl = "/quiz/extractQuestion";
      let { useQuiz } = this.chapterInfo;
      const { quizNum, quizValue } = useQuiz;
      let params = {
        packageId: quizValue,
        size: quizNum,
      };
      commoeLoadGet(sourceUrl, params, ({ success, data }) => {
        if (success) {
          this.examIds = data.ids;
          callback?.();
        }
      });
    },
    reloadData() {
      if (this.queryiId) {
        this.loadBaseInfo();
        this.loadCourses();
      }
    },
    loadBaseInfo() {
      const sourceUrl = "nc/course/play/getPlayBase";
      let params = {
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, res => {
        this.baseInfo = res.data;
      });
    },
    loadCurrentItem(it) {
      let that = this;
      const sourceUrl = "nc/course/play/getPlayRecord";
      let params = {
        coursewareId: it.id,
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, ({ data }) => {
        const seektime = this.formatSeekTime(data.lastWatchPoint);
        this.chapterInfo.children.forEach(cha => {
          if (data.id == cha.id && data.rTypeValue == "document") {
            cha.finishedRate = 100;
            data.finishedRate = 100;
          }
        });
        this.videoStatus = false;
        this.docUrl = null;
        this.playPattern = true;
        this.curItemType = data.rTypeValue;
        this.curPlayItem = data;
        this.$set(this.chapterInfo, "curPlayId", data.id);
        this.setCurrentProgress(data.finishedRate);
        this.setCurrentSeektime(seektime);
        this.setCurrentPlayItem(data);
        if (data.rTypeValue == "link") {
          this.toPageSourceUrl("您确定要跳转第三方链接吗？", data.linkPcUrl);
        }
        if (data.rTypeValue == "document") {
          if (data.fileAdditionUrl) {
            this.showDocumentPreview(data.fileAdditionUrl);
          } else {
            return dsf.layer.pc.message("文稿地址为空，请联系管理员上传相关文稿");
          }
        }
        if (data.rTypeValue == "medium") {
          this.toPageSourceUrl("您确定要跳转第三方链接的富媒体吗？", data.linkPcUrl);
        }
      });
    },
    loadCourses() {
      const sourceUrl = "nc/course/play/getPlayTrend";
      let params = {
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, res => {
        if (!dsf.isEmptyObject(res.data)) {
          const data = res.data;
          const { playTree, locationSite } = data;
          if (playTree) {
            if (!data.hasOwnProperty.call(data, "isLiked")) data.isLiked = false;
            this.initItem = data;
            this.courseRate = data.assessAverageScore;
            this.chapterInfo = playTree;
            if (playTree.useQuiz) {
              const { isAlwaysQuiz, quizInterval } = playTree.useQuiz;
              this.examTimerState = isAlwaysQuiz;
              this.examTimerTime = quizInterval || 5;
            }
            if (data.loginLimit == 1 && !this.isLogin) {
              this.$openDialog({
                title: "",
                width: "400px",
                height: "370px",
                noTitle: true,
                params: {
                  path: dsf.config.setting_public_login_dialog,
                  showClose: false,
                  loginSuccess() {
                    console.log("登录成功");
                    location.reload();
                  },
                  loginFail() {
                    console.log("登录失败");
                  },
                },
                onClosed() {
                  dsf.layer.closeWindow();
                  console.log("关闭弹窗");
                },
              });
            }
            this.$dialog?.close();
            const item = locationSite ? locationSite : playTree?.children?.length ? playTree.children[0] : null;
            this.loadCurrentItem(item);
          }
        }
      });
    },
    // 讲义模式切换
    changePattern(state) {
      if (this.playPattern == state) return;
      const type = this.curItemType;
      if (type === "link" || type === "document") {
        return dsf.layer.pc.message("当前课件暂无视频", false);
      } else {
        if (!state) {
          let { fileAdditionUrl } = this.curPlayItem;
          if (fileAdditionUrl) {
            this.showDocumentPreview(fileAdditionUrl);
            this.resetPlayerStyle(state);
            this.playPattern = false;
          } else {
            return dsf.layer.pc.message("当前课件暂无相关文档");
          }
        } else {
          this.docUrl = null;
          this.playPattern = true;
          this.resetPlayerStyle(state);
        }
      }
    },
    // 切换听课和看课状态
    toggleStatus() {
      if (this.toggleFlag) {
        return;
      }
      setTimeout(() => {
        this.toggleFlag = false;
      }, 2000);
      this.toggleFlag = true;
      let file = this.curPlayItem && JSON.parse(this.curPlayItem.fileUrl);
      const seektime = this.formatSeekTime(this.getCurPlayTime);
      if (seektime) {
        this.setCurrentSeektime(seektime);
        this.$refs.coursePlayer.playerParams.seek = seektime;
      }
      if (!this.videoStatus) {
        if (file.mp3) {
          this.curPlayItem.rTypeValue = "audio";
          this.setCurrentPlayItem(file.mp3);
          this.videoSrc = file.mp3;
          this.videoStatus = !this.videoStatus;
        }
      } else {
        this.curPlayItem.rTypeValue = "video";
        this.videoStatus = !this.videoStatus;
      }
      this.resetPlayerStyle(this.playPattern);
    },
    //文档类型课件预览
    showDocumentPreview(fileAdditionUrl) {
      let docUrl = `/preview/file?url=${this.preUrl}${JSON.parse(fileAdditionUrl)[0].relativePath}`;
      this.docUrl = docUrl;
      this.playPattern = false;
      // this.setSaveProgress();
    },
    getCurTabSelMenu(idx) {
      return this.tabIdx == idx ? "tab-menus-name-sel" : "";
    },
    tabMenuChange(idx) {
      this.$checkLogin().then(res => {
        if (res) {
          this.tabIdx = idx;
        }
      });
    },
    setImage(item) {
      const data = item;
      let src = "";
      if (dsf.isDef(data) && data[0]) {
        src = data[0].photo ? dsf.url.getWebPath(JSON.parse(data[0].photo)[0].relativePath) : "";
      }
      return src;
    },
    // 目录切换
    async changeCurPlayCourse(item) {
      let params2 = {
        courseId: this.queryiId,
        coursewareId: this.curPlayItem.id,
        watchPoint: this.getCurPlayTime,
        pulseRate: this.playrate,
        pulseTime: 10,
      };
      await this.updateProgress(params2);
      const sourceUrl = "nc/course/play/getPlayRecord";
      let params = {
        coursewareId: item.id,
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      this.curPlayItem = null;

      commoeLoadGet(sourceUrl, params, res => {
        // this.clearPlayInterval();
        this.loadCurrentItem(item);
        this.resetPlayerStyle(true);
        this.setPlayerScrrenshot(null);
      });
    },
    updateProgress(query) {
      const { courseId, coursewareId, watchPoint, pulseTime, pulseRate } = query;
      const sourceUrl = "nc/course/play/pulseSaveRecord";
      let params = {
        courseId,
        coursewareId,
        classId: this.queryString.classid || "",
        watchPoint,
        pulseTime,
        pulseRate,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadPost(
        sourceUrl,
        params,
        res => {
          if (res.success) {
            this.examShowDialog = res.data.isQuizFirst;
            this.setCurrentPlayProgress(res.data.finishedRate);
          } else {
            dsf.layer.pc.message("保存学习进度失败,请重新登录或联系管理员", false);
          }
          // setTimeout(() => {
          //   this.reloadData();
          // });
        },
        err => {
          dsf.layer.pc.message(err.message, false);
        }
      );
    },
    playbackRateUpdate(it) {
      this.playrate = it;
    },
    throttle(func, wait) {
      let previous = 0;
      return function () {
        let now = Date.now();
        let context = this;
        let args = arguments;
        if (now - previous > wait) {
          func.call(context, args);
          previous = now;
        }
      };
    },
    //当前视频播放时间
    playerPlayingTime(e, t, m) {
      //秒转换时间格式
      // this.throttleFormat(e);
      let finishedRate = Number((e / this.curPlayItem.sumDurationLong) * 100).toFixed(0);
      if (this.curPlayItem.sumDurationLong && this.curPlayItem.finishedRate != 100 && finishedRate > this.curPlayItem.finishedRate) {
        finishedRate < 0 ? (finishedRate = 0) : finishedRate;
        finishedRate > 100 ? (finishedRate = 100) : finishedRate;
        this.curPlayItem.finishedRate = finishedRate;

        this.chapterInfo.children.forEach(cha => {
          if (this.curPlayItem.id == cha.id) {
            cha.finishedRate = finishedRate;
            this.curPlayItem.finishedRate = finishedRate;
          }
        });
      }

      this.formatSeconds(e);
    },
    playNextCourse() {
      let { chapterInfo, curPlayItem } = this;
      let list = chapterInfo.children || [];
      let idx = -1;
      _.each(list, (item, index) => {
        if (item.id == curPlayItem.id) {
          idx = index;
        }
      });
      if (idx !== -1) {
        ++idx;
        if (idx == list.length) idx = 0;
        this.loadCurrentItem(list[idx]);
      }
    },
    nextCourse() {
      this.playNextCourse();
    },
    //当前视频播放结束
    async playEnd(state) {
      if (state == 2) {
        this.nextCourse();
      }
      let params = {
        courseId: this.queryiId,
        coursewareId: this.curPlayItem.id,
        watchPoint: this.getCurPlayTime,
        pulseRate: this.playrate,
        pulseTime: 10,
      };
      // await this.setSaveProgress();
      // this.loadCourses();
      await this.updateProgress(params);
      // this.clearPlayInterval();
    },
    //视频播放
    playerHasPlay(idx) {
      // this.clearPlayInterval();
      // this.setSaveProgress();

      if (!this.isPlayIng) this.isPlayIng = true;
    },
    saveProgressUntimer(source) {
      /**
       * 设置保存进度参数
       *
       * @param courseId 课程id
       * @param coursewareId 课件id
       * @param pulseTime 保存间隔时间
       * @param pulseRate 当前播放倍数
       * @param watchPoint 当前观看点节点 00:00:00
       *
       */
      let params = {
        courseId: this.queryiId,
        coursewareId: this.curPlayItem.id,
        watchPoint: this.getCurPlayTime,
        pulseRate: this.playrate,
        pulseTime: 10,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      this.updateProgress(params);
      if (source) dsf.layer.openWindow(source);
    },
    setSaveProgress() {
      let that = this;
      // this.playTimer = setInterval(function () {
      //   that.saveProgressUntimer();
      // }, 1000 * 10);
    },
    //视频暂停
    playerHasPause() {
      let params = {
        courseId: this.queryiId,
        coursewareId: this.curPlayItem.id,
        watchPoint: this.getCurPlayTime,
        pulseRate: this.playrate,
        pulseTime: 10,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      // this.clearPlayInterval();
      // this.updateProgress(params);
      if (this.isPlayIng) this.isPlayIng = false;
    },
    toPageSourceUrl(text, sourceUrl) {
      let that = this;
      dsf.layer
        .confirm({
          message: text,
        })
        .then(function () {
          that.saveProgressUntimer(sourceUrl);
        })
        .catch(function () {
          dsf.log("取消");
        });
    },
    handleCollect() {
      this.$checkLogin().then(res => {
        if (res) {
          const sourceUrl = "dsfa/collect/saveOrCancel";
          let params = {
            ywlxValue: "course",
            ywlxText: "课程",
            ywid: this.queryiId,
          };
          commoeLoadPost(
            sourceUrl,
            params,
            ({ message, data }) => {
              dsf.layer.pc.message(message, true);
              this.initItem.isCollected = data.isDo;
            },
            err => {
              dsf.layer.pc.message(err.message, false);
            }
          );
        }
      });
    },
    handleLiked() {
      this.$checkLogin().then(res => {
        if (res) {
          const sourceUrl = "dsfa/tags/saveOrCancel";
          let params = {
            ywlxValue: "course",
            ywlxText: "课程",
            ywid: this.queryiId,
          };
          commoeLoadPost(
            sourceUrl,
            params,
            ({ message, data }) => {
              dsf.layer.pc.message(message, true);
              this.initItem.isLiked = data.isDo;
            },
            err => {
              dsf.layer.pc.message(err.message, false);
            }
          );
        }
      });
    },

    handleBack() {
      if (this.$listeners["userBack"]) {
        this.$dispatch("userBack");
      } else {
        this.$checkLogin().then(res => {
          if (res) {
            this.$openDialog({
              title: "   ",
              width: "1000px",
              height: "70vh",
              params: {
                path: "#/pc/nc/operate/feedback/edit?resourceTitle=" + this.baseInfo.title + "&resourceId=" + this.baseInfo.id,
              },
              yes: result => {
                console.log(result);
              },
              showClose: false,
              hasFooter: false,
              center: "center",
            });
          }
        });
      }
    },
    laodCommentId() {
      const sourceUrl = "/nc/gather/assess/getIdByRelation?relaid=" + this.queryiId;
      return new Promise((resolve, reject) => {
        commoeLoadGet(sourceUrl, {}, ({ success, data }) => {
          if (success) {
            resolve(data);
          } else {
            reject(data);
          }
        });
      });
    },
    handleComment() {
      this.$checkLogin().then(res => {
        if (res) {
          this.laodCommentId().then(it => {
            let path = "#/pc/nc/assess/coursepc?relaId=" + this.queryiId;
            if (it && !dsf.isEmptyObject(it)) path += "&id=" + it;
            this.$openDialog({
              title: "评价",
              width: "1000px",
              height: "70vh",
              params: {
                path,
                cb: () => {
                  this.initItem.isAssessed = true;
                },
              },
              showClose: false,
              hasFooter: false,
              center: "center",
            });
          });
        }
      });
    },
    commentSuccess(it) {
      this.showComment = false;
      this.courseRate = it.averageScore;
    },
    toggle() {
      this.isFold = !this.isFold;
      this.$refs.rightBox.style.width = !this.isFold ? "480px" : 0;
      window.playerResize?.();
    },
    //加入学习计划
    joinStudy() {
      this.$checkLogin().then(res => {
        if (res) {
          const sourceUrl = "nc/learn/plan/saveOrCancel";
          const params = {
            id: this.queryiId,
          };
          commoeLoadPost(sourceUrl, params, ({ message, data }) => {
            dsf.layer.pc.message(message, true);
            this.initItem.isPlaned = data.isDo;
          });
        }
      });
    },
    //清楚定时器
    clearPlayInterval() {
      if (this.playTimer) clearInterval(this.playTimer);
    },
    //秒转换时间格式并保存到store
    formatSeconds(value) {
      let _a = this.examTimerTime * 60;
      value = Math.floor(value);
      let _t = parseInt(value + 1);
      let data = dsf.date.secondsToTime(value);
      let time = data.hours + ":" + data.minutes + ":" + data.seconds;
      this.examPlayerNode = parseInt(value);
      if (_t % _a === 0) {
        if (!this.examTimer) {
          this.examTimer = setTimeout(() => {
            this.initExamTimer();
            this.examTimer = null;
            clearTimeout(this.examTimer);
          }, 2000);
        }
      }
      if (value) {
        this.setCurrentPlayTime(time);
      }
    },
    formatSeekTime(t) {
      if (!t) t = "00:00:00";
      t = t.split(":");
      var s = t[0] * 60 * 60 + t[1] * 60 + t[2] * 1;
      return s;
    },
    getFileExt(filePath) {
      if (filePath != "" && filePath) {
        if (filePath.indexOf("?") > -1) {
          filePath = filePath.split("?")[0];
        }
        var pos = "." + filePath.replace(/.+\./, "");
        if (pos.toLowerCase() !== ".m3u8") {
          // dsf.layer.pc.message("当前课程切片出现问题，为保证播放不出现卡顿，请联系管理员");
          console.log("当前课程切片出现问题，为保证播放不出现卡顿，请联系管理员");
        }
        return pos.toLowerCase();
      }
      return "";
    },
    filterFileUrl(item) {
      let sourceUrl = "";
      if (item.rTypeValue == "document") {
        if (item.fileAdditionUrl) {
          sourceUrl = `/preview/file?url=${this.preUrl}${JSON.parse(item.fileAdditionUrl)[0].relativePath}`;
          this.setSaveProgress();
        }
      } else if (item.rTypeValue == "link") {
        sourceUrl = item.linkPcUrl;
      } else if (item.rTypeValue == "medium") {
        sourceUrl = item.linkPcUrl;
      } else if (item.rTypeValue == "audio") {
        if (JSON.parse(item.fileUrl)["mp3"] && JSON.parse(item.fileUrl)["mp3"]["relative"]) {
          sourceUrl = JSON.parse(item.fileUrl)["mp3"]["relative"];
        } else {
          sourceUrl = JSON.parse(item.fileUrl)["file"][0]["relativePath"];
        }
      } else {
        if (item && item.fileUrl) {
          const sourceItem = JSON.parse(item.fileUrl);
          if (sourceItem.file) {
            sourceUrl = sourceItem.file[0].relativePath;
          } else {
            if (dsf.isArray(sourceItem)) {
              sourceUrl = dsf.url.getWebPath(sourceItem[0].relativePath);
            } else {
              const urlArr = [
                [dsf.url.getWebPath(sourceItem.cd.relative) + "?type=nccd", "video/" + this.getFileExt(sourceItem.cd.relative).substring(1), "清晰", 0],
                [dsf.url.getWebPath(sourceItem.sd.relative) + "?type=ncsd", "video/" + this.getFileExt(sourceItem.sd.relative).substring(1), "标清", 0],
                [dsf.url.getWebPath(sourceItem.hd.relative) + "?type=nchd", "video/" + this.getFileExt(sourceItem.hd.relative).substring(1), "高清", 0],
              ];
              sourceUrl = urlArr;
            }
          }
        }
      }
      return sourceUrl;
    },
    toMainPage() {
      location.href = dsf.config.setting_public_pc_login_page;
    },
    async toTeacherDetail(item) {
      await this.$checkLogin().then(res => {
        if (res) {
          let sourceUrl = this.teacherDetailUrl;
          if (sourceUrl) {
            dsf.layer.openWindow(sourceUrl + item.id);
          }
        }
      });
    },
    resetPlayerStyle(state) {
      //讲义模式下 播放器Fixed
      if (this.$refs.dragItem) {
        const h = this.videoType == "audio/mp3" ? "60px" : "170px";
        let playerStyle = this.$refs.dragItem.style;
        playerStyle.zIndex = "23";
        playerStyle.position = !state ? "fixed" : "static";
        playerStyle.right = !state ? "20px" : 0;
        playerStyle.top = !state ? "0" : 0;
        playerStyle.width = !state ? "480px" : "100%";
        playerStyle.height = !state ? h : "100%";
        // playerStyle.cursor = !state ? "move" : "none";
        window.playerResize?.();
      }
    },
    playerMousedown(name, e) {
      if (!this.playPattern) {
        const { clientX, clientY } = e;
        const left = this.$refs[name].offsetLeft;
        const top = this.$refs[name].offsetTop;
        this.coordinate = {
          clientX,
          clientY,
          left,
          top,
        };
      }
    },
    playerMousemove(name, e) {
      if (this.coordinate && !this.playPattern) {
        let { clientX, clientY } = e;
        let winW = window.innerWidth;
        let winH = window.innerHeight;
        let x = this.coordinate.left + (clientX - this.coordinate.clientX);
        let y = this.coordinate.top + (clientY - this.coordinate.clientY);
        x = x <= 0 ? 0 : x >= winW - this.$refs[name].offsetWidth ? winW - this.$refs[name].offsetWidth : x;
        y = y <= 0 ? 0 : y >= winH - this.$refs[name].offsetHeight ? winH - this.$refs[name].offsetHeight : y;
        this.$refs[name].style.left = x + "px";
        this.$refs[name].style.top = y + "px";
      }
    },
    playerMouseup() {
      !this.playPattern && (this.coordinate = null);
    },
    // commentBtn(item) {
    //   console.log(item);
    //   this.$refs.courseComment?.comment(item.args);
    // },
    ...mapMutations({
      setCurrentPlayItem: "SET_CURRENT_PLAY_ITEM",
      setCurrentPlayTime: "SET_CURRENT_PLAY_TIME",
      setCurrentProgress: "SET_CURRENT_PLAY_PROGRESS",
      setCurrentSeektime: "SET_CURRENT_PLAY_SEEKTIME",
      setCurrentPlayProgress: "SET_CURRENT_PLAY_PROGRESS",
      setPlayerScrrenshot: "PLAYER_SET_SCRRENSHOT",
    }),
  },
});

function commoeLoadGet(sourceUrl, params = {}, succFn, failFun, method = "get") {
  // const loading = dsf.layer.pc.loading();
  dsf.http
    .get(sourceUrl, params, $$webRoot.nc)
    .done(res => {
      res.success && succFn && succFn(res);
      if (!res.success) error(res);
    })
    .error(err => {
      error(err);
    })
    .always(res => {
      // dsf.layer.pc.closeLoading(loading);
    });
  function error(err) {
    failFun && failFun(err);
    dsf.layer.pc.message(err.message, false);
  }
}

function commoeLoadPost(sourceUrl, params, succFn, failFun, method) {
  // const loading = dsf.layer.pc.loading();
  dsf.http
    .post(sourceUrl, params, $$webRoot.nc)
    .done(res => {
      res.success && succFn && succFn(res);
      if (!res.success) error(res);
    })
    .error(err => {
      error(err);
    })
    .always(res => {
      // dsf.layer.pc.closeLoading(loading);
    });
  function error(err) {
    failFun && failFun(err);
    dsf.layer.pc.message(err.message, false);
  }
}
</script>
