<template>
  <div class="dsf_nc_saas saas_setting_footer_model1">
    <div class="subtitle margint20 marginb20">效果预览</div>
    <footerTwo itemWidth="100%" margin="9px 0 50px 0" :data="data" />
    <div class="setting">
      <div>
        <div>
          <div class="subtitle margint20 marginb20">基本信息配置</div>
          <div class="line">
            <label>公司地址</label>
            <div>
              <el-input type="text" v-model="data.addressTwo" placeholder="请输入公司地址"></el-input>
            </div>
          </div>
          <div class="line">
            <label>邮箱</label>
            <div>
              <el-input type="text" v-model="data.email" placeholder="请输入邮箱"></el-input>
            </div>
          </div>
          <div class="line">
            <label>版权所有©</label>
            <div>
              <el-input type="text" v-model="data.copyright" placeholder="请输入版权所有©"></el-input>
            </div>
          </div>
          <div class="line">
            <label>网站备案号</label>
            <div>
              <el-input type="text" v-model="data.websiteInternet" placeholder="请输入网站备案号"></el-input>
            </div>
          </div>
          <div class="line">
            <label>咨询热线</label>
            <div>
              <el-input type="text" v-model="data.hotLine" placeholder="请输入咨询热线"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="subtitle margint20 marginb20">推广二维码</div>
        <div class="line">
          <label>二维码（一）</label>
          <div class="upload">
            <el-upload accept="image/jpg,image/jpeg,image/png" class="avatar-uploader" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess1">
              <DsfImage v-if="data.qrcodeOneImg" :src="JSON.parse(data.qrcodeOneImg || '{}').relativePath" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-upload accept="image/jpg,image/jpeg,image/png" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess1">
              <dsf-button size="small">上传</dsf-button>
            </el-upload>
            <dsf-button size="small" type="plain" @click="clearIcon1">删除</dsf-button>
            <el-input type="text" v-model="data.qrcodeOneName"></el-input>
          </div>
        </div>
        <div class="line">
          <label>二维码（二）</label>
          <div class="upload">
            <el-upload accept="image/jpg,image/jpeg,image/png" class="avatar-uploader" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess2">
              <DsfImage v-if="data.qrcodeTwoImg" :src="JSON.parse(data.qrcodeTwoImg || '{}').relativePath" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-upload accept="image/jpg,image/jpeg,image/png" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess2">
              <dsf-button size="small">上传</dsf-button>
            </el-upload>
            <dsf-button size="small" type="plain" @click="clearIcon2">删除</dsf-button>
            <el-input type="text" v-model="data.qrcodeTwoName"></el-input>
          </div>
        </div>
        <div class="line">
          <label>二维码（三）</label>
          <div class="upload">
            <el-upload accept="image/jpg,image/jpeg,image/png" class="avatar-uploader" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess3">
              <DsfImage v-if="data.qrcodeThreeImg" :src="JSON.parse(data.qrcodeThreeImg || '{}').relativePath" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-upload accept="image/jpg,image/jpeg,image/png" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess3">
              <dsf-button size="small">上传</dsf-button>
            </el-upload>
            <dsf-button size="small" type="plain" @click="clearIcon3">删除</dsf-button>
            <el-input type="text" v-model="data.qrcodeThreeName"></el-input>
            <dsf-nc-crop-image
              :show-tips-text="true"
              :resize="false"
              :edit-img="editImg"
              :edit-file-img="editFileImg"
              :aspect-ratio="aspectRatio"
              :request-url="setRequestUrl"
              @saveFile="editFile"
              @handleClose="handleClose"
            ></dsf-nc-crop-image>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <dsf-button type="plain" @click="backPage">取消</dsf-button>
      <dsf-button type="primary" @click="saveModel">保存</dsf-button>
    </div>
  </div>
</template>
<script>
import footerTwo from "../../base/saas/footerTwo.vue";
export default dsf.component({
  name: "DsfNcSaasSettingFooterModel2",
  ctrlCaption: "页尾模板设置2",
  mixins: [$mixins.control],
  props: {
    bindURL: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { footerTwo },
  data() {
    return {
      editImg: false,
      editFileImg: "",
      aspectRatio: "60/60",
      editImgType: "",
      radioValue: null,
    };
  },
  computed: {
    setRequestUrl() {
      return dsf.url.getWebPath("file/upload/");
    },
  },
  methods: {
    handleAvatarSuccess1(res) {
      this.editFileImg = res.data[0];
      this.editImgType = "qrcodeOneImg";
      this.editImg = true;
    },
    handleAvatarSuccess2(res) {
      this.editFileImg = res.data[0];
      this.editImgType = "qrcodeTwoImg";
      this.editImg = true;
    },
    handleAvatarSuccess3(res) {
      this.editFileImg = res.data[0];
      this.editImgType = "qrcodeThreeImg";
      this.editImg = true;
    },
    editFile(files) {
      this.editImg = false;
      this.data[this.editImgType] = JSON.stringify(files);
    },
    handleClose() {
      this.editImg = false;
    },
    clearIcon1() {
      this.data.qrcodeOneImg = "";
    },
    clearIcon2() {
      this.data.qrcodeTwoImg = "";
    },
    clearIcon3() {
      this.data.qrcodeThreeImg = "";
    },
    saveModel() {
      this.$emit("saveModel", this.data);
    },
    backPage() {
      this.$emit("back");
    },
  },
});
</script>
