var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds_mainNewsList", style: _vm.controlStyle },
    [
      _vm.news.length
        ? _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.news, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "news_row",
                  class: { active: index == _vm.active },
                  style: _vm.rowStyle,
                  on: {
                    mouseover: function ($event) {
                      return _vm.changeActive(index)
                    },
                    click: function ($event) {
                      return _vm._clickBefore(_vm.goDetail, item)
                    },
                  },
                },
                [
                  _c("p", { staticClass: "news_title" }, [
                    _vm._v(_vm._s(item[_vm.keyField.title] || "")),
                  ]),
                  index == _vm.active
                    ? _c("div", { staticClass: "news_content" }, [
                        _c("div", {
                          staticClass: "detail",
                          domProps: {
                            innerHTML: _vm._s(item[_vm.keyField.content]),
                          },
                        }),
                        _c("div", { staticClass: "bottom" }, [
                          _c(
                            "div",
                            { staticClass: "number" },
                            [
                              _c("dsf-icon", { attrs: { name: "yanjing1" } }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item[_vm.keyField.readingNumber] || 0)
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "time" }, [
                            _vm._v(
                              _vm._s(
                                item[_vm.keyField.time]
                                  ? _vm.dsf.date.format(
                                      item[_vm.keyField.time],
                                      "yyyy-mm-dd"
                                    )
                                  : ""
                              )
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "noDate" }, [_vm._v("暂无数据")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }