<template>
  <div class="dsf_specialdetail_social_rank" :style="{ width: itemWidth, display: visible ? 'block' : 'none', margin: itemSpace.replace(/,/g, ' ') }">
    <div class="social_title">
      <div class="desc">
        <span class="font">{{ title }}</span>
        <span class="more" v-if="showMore && moreStyle == 'tb'" @click="!!showMore && clickMore()"></span>
        <span class="more_font" v-else-if="showMore && moreStyle == 'wz'" @click="!!showMore && clickMore()">更多</span>
      </div>
      <span class="line" v-if="showLine"></span>
    </div>
    <ul class="rank_wrap">
      <li class="rank_items" v-for="(item, index) in lists" :key="index">
        <div class="rank_left">
          <div :class="['rank_count', index == 0 ? 'gold' : index == 1 ? 'silver' : index == 2 ? 'bronze' : '']" v-html="index > 2 ? index + 1 : ''"></div>
          <div class="heard">
            <dsf-nc-image :src="item.photo | imgFormat" :error-src="dsf.config.setting_public_user_default_card_header" />
          </div>
          <span class="name ell">{{ item.name || "无名" }}</span>
        </div>
        <div class="time">{{ item.time || 0 }}分钟</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSpecialDetailSocialRank",
  ctrlCaption: "专题班详情社区今日最佳",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "个人排行",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "/nc/class/student/rank",
    },
    moreUrl: {
      type: Object,
      default: null,
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    moreStyle: {
      type: String,
      default: "tb",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lists: [
        {
          name: "张三丰",
          time: "98",
          photo: "",
        },
        {
          name: "王大拿",
          time: "96",
          photo: "",
        },
        {
          name: "谢大脚",
          time: "87",
          photo: "",
        },
        {
          name: "小沈阳",
          time: "69",
          photo: "",
        },
        {
          name: "田欣欣",
          time: "62",
          photo: "",
        },
      ],
      params: {
        id: "",
        index: 1,
        size: 10,
      },
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "e1e9daabd8284af5b65bd8f3b1551745";
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            this.lists = res.data.list;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    getPath() {
      if (this.moreUrl.url) {
        let path2 = this.moreUrl.url;
        let urlInfo = dsf.url.parse(path2);
        let url = urlInfo.href.replace(urlInfo.search, "");
        let query = [];
        for (let k in urlInfo.searchQuery) {
          query.push(k + "=" + urlInfo.searchQuery[k]);
        }
        if (dsf.isUnDef(urlInfo.searchQuery.isview)) {
          if (this.readOnly) {
            query.push("isview=1");
          }
        }
        url += query.length > 0 ? "?" + query.join("&") : "";
        return this.$replace(url);
      }
      return "";
    },
    clickMore() {
      this.getPath();
      let { state } = this.moreUrl;
      let url = this.getPath();
      console.log(url, state);
      this.$open({ url, state });
    },
  },
});
</script>
