<template>
  <div class="dsf-shopping-car-item dsf-shopping-order-item">
    <template v-if="isDesign">
      <p class="name">商品信息</p>
      <div class="content">
        <div class="car-item-head">
          <dl class="head">
            <dd class="info">商品信息</dd>
            <dd class="state">商品类型</dd>
            <dd class="dept">购买方式</dd>
            <dd class="money">原价</dd>
            <dd class="money">优惠</dd>
            <dd class="handle center">实付</dd>
          </dl>
        </div>
        <div class="car-item">
          <dl class="head body">
            <dd class="info">
              <div class="item-card">
                <div class="item-card-img-wrap">
                  <dsf-image />
                </div>
                <div class="item-card-desc">
                  <h3 class="name ellipsis">供给侧结构性改革网络布局课</h3>
                  <p class="info">
                    <span>老师: 张三</span>
                    <span>销量: 1200</span>
                    <span>库存: 10</span>
                  </p>
                  <div class="give">
                    <span class="prefix">赠</span>
                    <dsf-icon name="quan" />
                    <span class="num">25</span>
                  </div>
                </div>
              </div>
            </dd>
            <dd class="state">课程</dd>
            <dd class="dept">个人购买</dd>
            <dd class="money">
              <dsf-icon name="jinbi" />
              850
            </dd>
            <dd class="money has-color">满减：-25</dd>
            <dd class="handle pay has-color">
              <dsf-icon name="jinbi" />
              25
            </dd>
          </dl>
        </div>
      </div>
      <div class="djq">
        <p class="name">可用代金券</p>
        <ul class="djq-list">
          <li>
            <div class="djq-left">
              <dsf-icon name="jinbi" />
              <span>850</span>
            </div>
            <div class="djq-right">
              <p>代金券</p>
              <p>有效期2021-12-21</p>
              <p>通用</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="bottom-check-out">
        <div class="inner-left">
          <div class="handle-item back-car">返回购物车</div>
        </div>
        <div class="inner-right">
          <div class="handle-item checkout-desc">
            <p>
              共5件商品，总计:
              <dsf-icon name="jinbi" />
              <b>850</b>
            </p>
            <p>
              我的余额:
              <dsf-icon name="jinbi" />
              269
            </p>
          </div>
          <div class="handle-item no-margin-right">
            <button class="btn-check-out some-checked">去结算</button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="name">商品信息</p>
      <div class="content">
        <div class="car-item-head">
          <dl class="head order-head">
            <dd class="info">商品信息</dd>
            <dd class="state">商品类型</dd>
            <dd class="dept">购买方式</dd>
            <dd class="money">原价</dd>
            <!-- <dd class="money">优惠</dd> -->
            <dd class="handle center">小计</dd>
          </dl>
        </div>
        <div class="car-item car-order-item" v-for="it in list" :key="it.goodId">
          <dl class="head body order-body">
            <dd class="info">
              <div class="item-card">
                <div class="item-card-img-wrap" @click="toDetaial(it)">
                  <dsf-image :src="it.cover | imgFormat" />
                </div>
                <div class="item-card-desc">
                  <h3 class="name ellipsis" @click="toDetaial(it)">{{ it.dGoodName }}</h3>
                  <div class="info order-desc">
                    <span class="teacher ell" :title="it.teachText ? it.teachText.replace(/\^/g, '、') : '-'">
                      老师: {{ it.teachText ? it.teachText.replace(/\^/g, "、") : "-" }}
                    </span>
                    <div class="bottom">
                      <span>销量: {{ it.sales || 0 }}</span>
                      <span>库存: {{ it.store || 0 }}</span>
                    </div>
                  </div>
                  <div class="give" v-if="it.giftCoupon">
                    <span class="prefix">赠</span>
                    <dsf-icon name="quan" />
                    <span class="num">25</span>
                  </div>
                </div>
              </div>
            </dd>
            <dd class="state">{{ it.dgoodTypeText }}</dd>
            <dd class="dept">{{ it.dshopTypeText }}</dd>
            <dd class="money">
              <dsf-icon name="jinbi" />
              {{ it.dOriginalPrice }}
            </dd>
            <!-- <dd class="money has-color">满减：0</dd> -->
            <dd class="handle pay has-color">
              <dsf-icon name="jinbi" />
              {{ it.dRealPrice }}
              <!-- {{ $route.query.isView == 1 ? it.dPayPrice : it.dRealPrice }} -->
            </dd>
          </dl>
        </div>
      </div>
      <!-- <div class="djq">
        <p class="name">可用代金券</p>
        <ul class="djq-list">
          <li>
            <div class="djq-left">
              <dsf-icon name="jinbi" />
              <span>850</span>
            </div>
            <div class="djq-right">
              <p>代金券</p>
              <p>有效期2021-12-21</p>
              <p>通用</p>
            </div>
          </li>
        </ul>
      </div> -->
      <div class="bottom-check-out" v-if="!readonly">
        <div class="inner-left">
          <div class="handle-item back-car" @click="backToShoppingCar">返回购物车</div>
          <div class="handle-item back-order" @click="backToShoppingOrder">我的订单</div>
        </div>
        <div class="inner-right">
          <div class="handle-item checkout-desc">
            <p>
              <span>共{{ orderInfo.len }}件商品，总计:</span>
              <dsf-icon name="jinbi" />
              <b>{{ orderInfo.price }}</b>
            </p>
            <p>
              <span>我的余额:</span>
              <dsf-icon name="jinbi" />
              {{ userCoins }}
            </p>
          </div>
          <div class="handle-item no-margin-right">
            <button class="btn-check-out some-checked" @click="toCheckOut">去结算</button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "dsfShoppingCarItem",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    shoppingCarUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shoppingcars",
    },
    sourcePath: {
      type: String,
      default: "/ncIndex.html#/pc/nc/pagepersonalCenter/index?active=order",
    },
    detailUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shopitemdetail?",
    },
    userCoins: {
      type: [Number, String],
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    orderInfo() {
      let { list } = this;
      let len = list.length;
      let price = 0;
      _.each(list, item => {
        price += Number(item.dRealPrice);
      });
      return {
        len,
        price,
      };
    },
  },
  data() {
    return {
      orderText: this.$route.query.isView == 1 ? "实付" : "应付",
    };
  },
  methods: {
    backToShoppingCar() {
      this.shoppingCarUrl && dsf.layer.openWindow(this.shoppingCarUrl);
    },
    backToShoppingOrder() {
      this.sourcePath && dsf.layer.openWindow(this.sourcePath);
    },
    toDetaial(it) {
      if (it) {
        const path = `${this.detailUrl}id=${it.goodId}&type=${it.dgoodTypeValue}`;
        dsf.layer.openWindow(path);
      }
    },
    toCheckOut() {
      this.$emit("toCheckOut");
    },
  },
};
</script>
