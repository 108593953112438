<template>
  <div class="dsf_nc_special_detail_course_item">
    <ul class="nc_detail_wrap" v-if="lists.length">
      <li :class="['nc_detail_item', { gjms: item.advancedMode }]" v-for="(item, index) in lists" :key="index">
        <div class="detail_top">
          <div class="title_box">
            <div class="item_title">{{ item.unitName }}</div>
            <span class="font">
              &nbsp;&nbsp;(选修: {{ item.electives || 0 }}门/{{ item.electivesPeriod || 0 }}学时&nbsp;&nbsp;必修: {{ item.compulsory || 0 }}门/{{ item.compulsoryPeriod || 0 }}学时)
            </span>
          </div>
          <div class="item_top_right" @click="clickFold(index)">
            <span v-if="!item.fold" class="font">展开</span>
            <span v-else class="font">收起</span>
            <span class="icon iconfont icon-xia1 sq" v-if="item.fold"></span>
            <span class="icon iconfont icon-shang1 sq" v-if="!item.fold"></span>
          </div>
        </div>
        <div class="detail_desc" v-if="item.fold && item.advancedMode">
          <div class="detail_desc_item" v-for="(items, idx) in item.subList" :key="items.subId">
            <div class="detail_desc_left">
              <span :class="['type_name', items.typeValue]">{{ items.typeText }}</span>
              &nbsp;
              <div class="type_category_box">
                <span class="type_category ell">【{{ items.categoryText }}】{{ items.name }}</span>
                <span class="type_category_hover" v-show="items.name.length > 15">{{ items.name }}</span>
              </div>
              <div class="date font" :style="`opacity:${items.timeSdate && items.timeEdate ? '1' : '0'}`">
                {{ dsf.date.format(items.timeSdate, "yyyy-mm-dd hh:ii") || "" }} 至 {{ dsf.date.format(items.timeEdate, "yyyy-mm-dd hh:ii") || "" }}
              </div>
              <div class="wcxs font">{{ items.period || "0" }}学时</div>
              <div class="teacher font ell">{{ items.teacher || "-" }}</div>
              <div class="progress">
                <span class="font">已学: {{ items.progress || 0 }}%</span>
              </div>
            </div>
            <div class="detail_desc_right">
              <div
                class="enter_btn"
                :style="`${itemStatus(items, item, idx, idx > 0 ? item.subList[idx - 1].progress : null) && items.playState && !isview ? '' : 'color: #aaa; cursor: default'}`"
                @click="showClick && items.playState && clickEnter(items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null)"
              >
                进入
              </div>
            </div>
          </div>
        </div>
        <div class="detail_desc" v-else-if="item.fold && !item.advancedMode">
          <div class="detail_desc_item" v-for="(items, idx) in item.subList" :key="items.subId">
            <div class="detail_desc_left">
              <span :class="['type_name', items.typeValue]">{{ items.typeText }}</span>
              &nbsp;
              <div class="type_category_box">
                <span class="type_category ell">{{ items.name }}</span>
                <span class="type_category_hover" v-show="items.name.length > 15">{{ items.name }}</span>
              </div>
              <div :class="['font', !!items.categoryValue && Number(items.categoryValue) ? 'bx' : 'xx']">{{ items.categoryText }}</div>
              <div class="duration_box">
                <div class="font duration ell">{{ setDuration(items) }}</div>
                <div :class="['duration_hover', { duration_none: setDuration(items) == '-' }]" v-if="items.typeValue == 'live' || items.typeValue == 'exam'">
                  {{ setDuration(items) }}
                </div>
              </div>
              <div class="wcxs font">{{ items.period || "0" }}学时</div>
              <div class="teacher font ell">{{ items.teacher || "-" }}</div>
              <div class="progress">
                <el-progress :percentage="items.progress || 0" stroke-width="10" :show-text="false"></el-progress>
                <span class="pivot-txt">{{ items.progress || 0 }}%</span>
              </div>
            </div>
            <div class="detail_desc_right">
              <div
                class="enter_btn"
                :style="`${itemStatus(items, item, idx, idx > 0 ? item.subList[idx - 1].progress : null) && items.playState && !isview ? '' : 'color: #aaa; cursor: default'}`"
                @click="showClick && items.playState && clickEnter(items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null)"
              >
                进入
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
    <div>
      <!-- showFace1 -->
      <el-dialog title="提示" class="face" :visible.sync="showFace1" :show-close="false" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" @beforeClose="beforeClose">
        <template>
          <div class="faceQrcode">
            <img :src="socketImg" alt="" />
          </div>
          <div class="desc">扫一扫人脸识别</div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "dsfNcSpecialDetailCourseItem",
  props: {
    lists: {
      type: Array,
      default: () => {
        return [];
      },
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isview: {
      type: Boolean,
      default: false,
    },
    openType: {
      type: String,
      default: "2",
    },
    socketUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      socketImg:"",
      showClick: false,
      isCanClick: false,
      // qrCodePath: "",
      showFace1: false,
    };
  },
  mounted() {},
  computed: {
    qrCodePath() {
      return this.$root.qrCodePath;
    },
  },
  methods: {
    beforeClose() {
      return;
    },
    clickFold(index) {
      this.lists[index]["fold"] = !this.lists[index]["fold"];
    },
    setDuration(item) {
      let durationHtml = "";
      switch (item.typeValue) {
        case "course": // 课程
          durationHtml = item.duration;
          break;
        case "live": // 直播
          durationHtml = item.duration ? "开播: " + item.duration : "-";
          break;
        case "exam": // 考试
          durationHtml = item.duration ? "开考: " + item.duration : "-";
          break;
        default:
          durationHtml = item.duration;
          break;
      }
      return durationHtml || "-";
    },
    itemStatus(item, parentItem, childIndex, lastProgress) {
      // 能点击 &&  当前单元没有设置排序
      if (this.showClick && parentItem.orderTypeValue == "0") return true;
      // 能点击 && 当前单元设置了排序 && 不是第一节课 && 上个进度为100
      if (this.showClick && parentItem.orderTypeValue == "1" && childIndex > 0 && lastProgress == 100) return true;
      //能点击 && 当前单元设置了排序 && 当前是第一节课
      if (this.showClick && parentItem.orderTypeValue == "1" && childIndex == 0) return true;
      // 能点击 &&  当前单元设置了排序 && 当前进度100
      if (this.showClick && parentItem.orderTypeValue == "1" && item.progress == 100) return true;
      //  状态为结业
      if (this.detailData.state == 3) return true;

      return false;
    },
    async clickEnter(item, specialId, parentItem, childIndex, lastProgress) {
      let loading = dsf.layer.loading();
      dsf.http
        .get(
          "nc/class/info",
          {
            id: this.$route.query.id,
          },
          $$webRoot.nc
        )
        .done(res => {
          if (res.success) {
            if (res.data["isFace"] == 1) {
              localStorage.setItem("socketUrl", this.socketUrl);
              localStorage.setItem("verifyTime2", JSON.stringify(Number(res.data["verifyTime"]) * 60));
              localStorage.setItem("verifyTime", JSON.stringify(Number(res.data["verifyTime"])));
              localStorage.setItem("isFace", JSON.stringify(res.data["isFace"] == 1 ? true : false));
              localStorage.setItem("isShowFace", res.data["isFace"]);
              localStorage.setItem("qrCodePath", JSON.stringify(res.data["qrCodePath"]));
              this.$root.isFace = JSON.parse(localStorage.getItem("isFace"));
              this.$root.verifyTime = JSON.parse(localStorage.getItem("verifyTime"));
              this.$root.qrCodePath = JSON.stringify(localStorage.getItem("qrCodePath"));
            } else {
              if (localStorage.getItem("socketUrl")) {
                localStorage.removeItem("socketUrl");
              }
            }
          }
        })
        .error(err => {})
        .always(async () => {
          if (this.$root.isFace != 1) {
            let statues = this.itemStatus(item, parentItem, childIndex, lastProgress);
            if (!statues || this.isview) return;
            // 点击进入
            let type = item.typeValue;
            let classid = specialId; // 专题班id
            let id = item.businessId; // 课程id;考试id
            let url = "";
            console.log(type, id, item);
            switch (type) {
              case "course": // 课程
                url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                break;
              case "video": // 视频
                url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                break;
              case "audio": // 音频
                url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                break;
              case "draft": // 文稿
                url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                break;
              case "media": // 富媒体
                url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                break;
              case "live": // 直播
                url = `/page.html#/pc/nc/pagelive/liveDetail?id=${id}`;
                break;
              case "exam": // 考试
                this.toExamDetail(item, specialId);
                break;
              case "asq": // 问卷
                let params = {
                  asqId: item.asqInfoId,
                  businessType: "1",
                  subId: item.subId,
                };
                this.dsf.http
                  .get("/nc/asq/api/getAsqUrl", params)
                  .done(res => {
                    if (res.success) {
                      url = res.data;
                    }
                    this.$open({ url, state: this.openType });
                  })
                  .error(err => {
                    dsf.layer.message(err.message || "获取考试异常", false);
                  })
                  .always(function () {});
                break;
              default:
                break;
            }
            this.$open({ url, state: this.openType });
          } else {
            // await this.$root.ws();
            console.log(localStorage.getItem("socketUrl") + "/" + dsf.getCookie("userId"));
            if (ws) {
              ws.close();
            }
            let ws = new WebSocket(`${localStorage.getItem("socketUrl")}/${dsf.getCookie("userId")}`);
            ws.onopen = () => {
              //当WebSocket创建成功时，触发onopen事件
              // localStorage.setItem("isFace", JSON.stringify(true));
              console.log("通道链接成功了");
              this.showFace1 = true;
              dsf.http.get("/face/getQrCode", {}, $$webRoot.nc)
              .done(res => {
                this.socketImg = res.data
              })
            };
            ws.onmessage = e => {
              // 接受到服务器传来的消息
              let data = JSON.parse(e.data);
              // data["_header"]["isPass"] = true;
              // console.log();
              if (data["_header"]["isPass"]) {
                localStorage.setItem("isFace", JSON.stringify(false));
                this.showFace1 = false;
                let statues = this.itemStatus(item, parentItem, childIndex, lastProgress);
                if (!statues) return;
                // 点击进入
                let type = item.typeValue;
                let classid = specialId; // 专题班id
                let id = item.businessId; // 课程id;考试id
                this.url = "";
                switch (type) {
                  case "course": // 课程
                    this.url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                    break;
                  case "video": // 视频
                    this.url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                    break;
                  case "audio": // 音频
                    this.url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                    break;
                  case "draft": // 文稿
                    this.url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                    break;
                  case "media": // 富媒体
                    this.url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
                    break;
                  case "live": // 直播
                    this.url = `/page.html#/pc/nc/pagelive/liveDetail?id=${id}`;
                    break;
                  case "exam": // 考试
                    this.toExamDetail(item, specialId);
                    break;
                  case "asq": // 问卷
                    let params = {
                      asqId: item.asqInfoId,
                      businessType: "1",
                      subId: item.subId,
                    };
                    this.dsf.http
                      .get("/nc/asq/api/getAsqUrl", params)
                      .done(res => {
                        if (res.success) {
                          url = res.data;
                        }
                        this.$open({ url, state: this.openType });
                      })
                      .error(err => {
                        dsf.layer.message(err.message || "获取考试异常", false);
                      })
                      .always(function () {});
                    break;
                  default:
                    break;
                }
                ws.close();
                this.$open({ url: this.url, state: 2 });
              } else {
                dsf.layer.message("人脸识别失败！", false);
                localStorage.setItem("isFace", JSON.stringify(false));
              }
            };
            ws.onclose = e => {
              //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
              console.log("连接关闭");
            };
          }
          dsf.layer.closeLoading(loading);
        });
    },
    toExamDetail(item) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: item["businessId"], //考试id
        businessId: item["subId"], //业务id
        businessType: 1,
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    setIcon(status) {
      let iconText = "";
      switch (status) {
        case "course" || "video" || "audio":
          iconText = "icon-shipin1";
          break;
        case "draft":
          iconText = "icon-wengao-1";
          break;
        case "live":
          iconText = "icon-a-zhibobofangshexiangjitianxianmianxing";
          break;
        case "exam":
          iconText = "icon-kaoshi";
          break;
        case "media":
          iconText = "icon-fumeitixiaoxi";
          break;
        default:
          iconText = "";
      }
      return iconText;
    },
  },
  watch: {
    lists: {
      handler(val) {
        val.forEach(element => {
          this.$set(element, "fold", true);
        });
      },
      immediate: true,
    },
    detailData: {
      handler(val) {
        // console.log(136136, val);
        if (val.state == 2 || val.state === 3) {
          this.showClick = true;
        } else {
          this.showClick = false;
        }
      },
      immediate: true,
    },
  },
};
</script>
