import { DataGridChildTest, DsfNcDataGridChildList } from "./list";
import baseComponents from "./base";
import banner from "./components/banner";
import user from "./components/mine";
import course from "./components/course";
import special from "./components/special";
import live from "./components/live";
import onStudy from "./components/onStudy";
import channel from "./components/channel";
import footer from "./components/footer";
import title from "./components/title";
import teacher from "./components/teacher";
import manage from "./components/manage";
import login from "./components/login";
import globalSearch from "./components/globalSearch";
import teachAssistant from "./components/teachAssistant";
import other from "./components/other";
import news from "./components/news";
import book from "./components/book";
import exam from "./components/exam";
import cardBox from "./components/cardBox";
import shop from "./components/shop";
import questions from "./components/questions";
import saas from "./components/saas";
import saasHomeComponents from "./base/saas/component";
import panel from "./components/panel";
import DsfNcMessageNotification from "./components/messagenotification";
import dsfNcCropImage from "./components/dsfNcCropImage";
const components = [
  ...DsfNcMessageNotification,
  ...panel,
  ...questions,
  ...banner,
  ...course,
  ...special,
  ...live,
  ...onStudy,
  ...channel,
  ...user, //基本信息
  ...manage, // 管理中心
  ...login,
  ...globalSearch,
  ...baseComponents,
  ...teachAssistant, // 教辅中心
  ...other,
  ...news,
  ...book,
  ...title, //门户标题
  ...footer,
  ...exam,
  ...teacher,
  ...cardBox,
  ...shop,
  ...saas,
  ...saasHomeComponents,
  ...dsfNcCropImage,
  DataGridChildTest,
  DsfNcDataGridChildList,
];

function install(Vue) {
  components.forEach(it => {
    Vue.component(it.name, it);

    if (!window.$components["nc"]) {
      window.$components["nc"] = {};
    }
    window.$components["nc"][it.name] = it;
  });
}

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export { install };

export default install;
