var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_recentLive" }, [
        _vm.isDesign
          ? _c(
              "div",
              { staticClass: "content", style: { width: _vm.conWidth } },
              [
                _c("div", { staticClass: "live-big-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "img-box pointer",
                      on: {
                        click: function ($event) {
                          return _vm.goDetail(_vm.liveObj)
                        },
                      },
                    },
                    [
                      _c("el-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.liveObj.cover),
                          fit: "cover",
                        },
                      }),
                      _c("div", { staticClass: "live-time" }, [
                        _vm._v("正在直播"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("p", { staticClass: "time" }, [
                      _vm._v("2021-07-16 00:00~2021-07-31 00:00"),
                    ]),
                    _c("div", { staticClass: "live-detail" }, [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "num-box" },
                        [
                          _c("dsf-icon", { staticClass: "icon-yanjing" }),
                          _c("span", { staticClass: "num" }, [_vm._v("50")]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "live-item" },
                  _vm._l(_vm.liveList, function (i, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        staticClass: "item-wrap pointer",
                        on: {
                          click: function ($event) {
                            return _vm.goDetail(i)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "img-box" }, [
                          _c("div", { staticClass: "live-time" }, [
                            _vm._v(_vm._s(_vm.formatterDate(i.time_sdate))),
                          ]),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("p", { staticClass: "ell2" }, [
                            _vm._v(_vm._s(i.title)),
                          ]),
                          _c("div", { staticClass: "detail" }, [
                            _c("span", [_vm._v(_vm._s(i.speaker || ""))]),
                            _c(
                              "span",
                              [
                                _c("dsf-icon", { staticClass: "icon-yanjing" }),
                                _c("span", { staticClass: "num" }, [
                                  _vm._v(_vm._s(i.views)),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _c("div", [
              _vm.liveArray.length
                ? _c(
                    "div",
                    { staticClass: "content", style: { width: _vm.conWidth } },
                    [
                      _c("div", { staticClass: "live-big-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "img-box pointer",
                            on: {
                              click: function ($event) {
                                return _vm.goDetail(_vm.liveObj)
                              },
                            },
                          },
                          [
                            _c("el-image", {
                              attrs: {
                                src: _vm._f("imgFormat")(_vm.liveObj.cover),
                                fit: "cover",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "live-time",
                                style:
                                  "background-color: " +
                                  _vm.showBgColor(_vm.liveObj["state_text"]),
                              },
                              [_vm._v(_vm._s(_vm.setTime(_vm.liveObj)))]
                            ),
                            _c("p", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.formatterDate(_vm.liveObj.time_sdate)
                                ) +
                                  "~" +
                                  _vm._s(
                                    _vm.formatterDate(_vm.liveObj.time_edate)
                                  )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "info" }, [
                          _c("p", { staticClass: "ell2 title" }, [
                            _vm._v(_vm._s(_vm.liveObj.title)),
                          ]),
                          _c("div", { staticClass: "live-detail" }, [
                            _c("p", { staticClass: "detail-p ell" }, [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(_vm.liveObj.speaker))]
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm.liveObj.job || "")),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "num-box" },
                              [
                                _c("dsf-icon", { staticClass: "icon-yanjing" }),
                                _c("span", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.liveObj.views || 0)),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "data-box",
                          style: { width: "calc(100% - 620px)" },
                        },
                        [
                          _vm.liveList.length
                            ? _c(
                                "div",
                                { staticClass: "live-item" },
                                _vm._l(_vm.liveList, function (i, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: idx,
                                      on: {
                                        click: function ($event) {
                                          return _vm.goDetail(i)
                                        },
                                      },
                                    },
                                    [
                                      idx < 3
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "item-wrap pointer",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "img-box" },
                                                [
                                                  _c("el-image", {
                                                    attrs: {
                                                      src: _vm._f("imgFormat")(
                                                        i.cover
                                                      ),
                                                      fit: "cover",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "seek-time",
                                                      style:
                                                        "background-color: " +
                                                        _vm.showBgColor(
                                                          i["state_text"]
                                                        ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.setTime(i))
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "live-time",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatterDate(
                                                            i.time_sdate
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "info" },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "ell2" },
                                                    [_vm._v(_vm._s(i.title))]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "detail" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "name ell",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                i.speaker || ""
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "job",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  i.job || ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "num-box",
                                                        },
                                                        [
                                                          _c("dsf-icon", {
                                                            staticClass:
                                                              "icon-yanjing",
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "num",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  i.views || 0
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm.liveList.length < 3
                            ? _c(
                                "div",
                                {
                                  staticClass: "noDate",
                                  class: { centerData: !_vm.liveList.length },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../../assets/imgs/pcimg/live/img_nomore.png"),
                                      alt: "",
                                    },
                                  }),
                                  _c("p", [_vm._v("没有更多近期直播了～")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _c("div", { staticClass: "noDate" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/imgs/pcimg/live/img_nomore.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("没有更多近期直播了～")]),
                  ]),
              _vm.isMore && _vm.liveList2.length
                ? _c(
                    "div",
                    {
                      staticClass: "moreTitle",
                      style: { width: _vm.conWidth },
                    },
                    [
                      _c("div", { staticClass: "border-box" }),
                      _vm._m(1),
                      _c("div", { staticClass: "border-box" }),
                    ]
                  )
                : _vm._e(),
              _vm.isMore && _vm.liveList2.length
                ? _c(
                    "div",
                    {
                      staticClass: "live-item live-item2",
                      style: { width: _vm.conWidth },
                    },
                    _vm._l(_vm.liveList2, function (i, idx) {
                      return _c(
                        "div",
                        {
                          key: idx,
                          staticClass: "item-wrap pointer margin-top",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(i)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "img-box" },
                            [
                              _c("dsf-nc-image", {
                                attrs: {
                                  src: _vm._f("imgFormat")(i["cover"]),
                                  "error-src": _vm.defaultSrc,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "seek-time",
                                  style:
                                    "background-color: " +
                                    _vm.showBgColor(i["state_text"]),
                                },
                                [_vm._v(_vm._s(_vm.setTime(i)))]
                              ),
                              _c("div", { staticClass: "live-time" }, [
                                _vm._v(_vm._s(_vm.formatterDate(i.time_sdate))),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "info" }, [
                            _c("p", { staticClass: "ell2" }, [
                              _vm._v(_vm._s(i.title)),
                            ]),
                            _c("div", { staticClass: "detail" }, [
                              _c("p", { staticClass: "name ell" }, [
                                _c("span", [_vm._v(_vm._s(i.speaker || ""))]),
                                _c("span", { staticClass: "job" }, [
                                  _vm._v(_vm._s(i.job || "")),
                                ]),
                              ]),
                              _c(
                                "span",
                                { staticClass: "num-box" },
                                [
                                  _c("dsf-icon", {
                                    staticClass: "icon-yanjing",
                                  }),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(i.views || 0)),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "detail-p" }, [
      _c("span", { staticStyle: { "margin-right": "10px" } }, [_vm._v("张三")]),
      _c("span", [_vm._v("教授")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "more" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/imgs/pcimg/live/more_left.png"),
          alt: "",
        },
      }),
      _c("span", [_vm._v("更多")]),
      _c("img", {
        attrs: {
          src: require("../../../assets/imgs/pcimg/live/more_right.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }