<template>
  <div class="ds-control ds-productBanner ds-no-padding" :id="id" :style="{ 'padding-bottom': rootHeight, 'padding-top': `calc(100% - ${h})` }">
    <div class="el-carousel-box" :style="{ width: vertical ? 'calc(100% - 300px)' : '100%' }">
      <el-carousel
        ref="swipe"
        :arrow="showButton"
        :indicator-position="showIndicators"
        :direction="vertical ? 'vertical' : 'horizontal'"
        :autoplay="isAutoPlay"
        :interval="autoPlay"
        :trigger="indicatorTrigger"
        :type="type"
        :height="h"
        @change="
          index => {
            activeIndex = index;
          }
        "
      >
        <el-carousel-item v-for="(item, index) in images" :key="`ds-banner-item-${index}`" :label="indicatorNumber ? index + 1 : ''">
          <div :class="['ds-banner-item', `${index}`]" @click="ev => _clickBefore(onSwipeClick, ev, item, index)">
            <slot v-bind="{ row: item, $index: index }">
              <!-- <dsf-image class="el-carousel-item-image" :src="item.image" :style="{ 'border-radius': borderRadius }" :error-src="errorImgSrc" /> -->
              <!--  -->
              <DsfNcDefaultHeader class="el-carousel-item-image" :src="item.image" :style="{ 'border-radius': borderRadius }" :round="false" :defaultHeaderUrl="noImgUrl"></DsfNcDefaultHeader>
              <slot name="desc" v-bind="{ row: item, $index: index }"></slot>
            </slot>
          </div>
          <template v-if="showTitle">
            <p class="ds-banner-item-title ell" :style="getTitleStyle">{{ item.title }}</p>
          </template>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="vertical" class="vertical_indicator">
      <div class="vertical_indicator_item" v-for="(item, index) in images" :key="index" :style="{ height: 100 / images.length + '%' }" @mouseenter="changeTab(index)">
        <dsf-nc-image class="el-carousel-item-image" :src="item['cover'] | imgFormat" :round="false" :error-src="noImgUrl" />
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcProductBanner",
  ctrlCaption: "产品轮播图",
  mixins: [$mixins.control],
  props: {
    ratio: {
      type: String,
      default: "200px",
    },
    showButton: {
      //切换箭头的显示时机 always/hover/never
      type: String,
      default: "hover",
    },
    showIndicators: {
      //指示器的位置  inside/none/outside
      type: String,
      default: "inside",
    },
    vertical: {
      //垂直滚动
      type: Boolean,
      default: false,
    },
    indicatorColor: {
      //指示灯颜色 为空默认使用皮肤辅色
      type: String,
      default: "#fff",
    },
    //为空默认为高亮颜色的透明度0.48
    indicatorDefaultColor: {
      type: String,
      default: "",
    },
    indicatorWidth: {
      type: Number,
      default: 30,
    },
    indicatorHeight: {
      type: Number,
      default: 2,
    },
    indicatorRadius: {
      type: [String, Number],
      default: "0",
    },
    //选中指示器宽度
    indicatorActiveWidth: {
      type: String,
      default: "",
    },
    //选中指示器圆角
    indicatorActiveRadius: {
      type: String,
      default: "0",
    },
    indicatorNumber: {
      type: Boolean,
      default: false,
    },
    indicatorMarginBottom: {
      type: String,
      default: "0",
    },
    autoPlay: {
      type: [String, Number],
      default: 3000,
    },
    isAutoPlay: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    borderRadius: {
      type: [String, Number],
      default: "0px",
    },
    indicatorTrigger: {
      type: String,
      default: "hover",
    },
    type: {
      type: String,
      default: "normal",
    },
    titlePosition: {
      type: String,
      default: "bottom",
    },
    list: {
      type: Array,
      default() {
        return [
          { image: dsf.config.setting_nc_image_default_img },
          { image: dsf.config.setting_nc_image_default_img },
          { image: dsf.config.setting_nc_image_default_img },
          { image: dsf.config.setting_nc_image_default_img },
        ];
      },
    },
    url: {
      type: String,
      default: "",
    },
    toUrl: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 0,
    },
    // 配置字段名
    imageKeyName: {
      type: String,
      default: "image",
    },
    titleKeyName: {
      type: String,
      default: "title",
    },
    showTitle: {
      type: Boolean,
      default: false,
      desc: "是否显示标题",
    },
    titleWidth: {
      type: String,
      default: "100%",
      desc: "标题宽度",
    },
    titleHeight: {
      type: String,
      default: "40px",
      desc: "标题高度",
    },
    titleFontSize: {
      type: String,
      default: "16px",
      desc: "标题字体大小",
    },
    titleLineHeight: {
      type: String,
      default: "40px",
      desc: "标题行高",
    },
    titleMargin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    titlePadding: {
      type: Array,
      default() {
        return [0, 0, 0, 20];
      },
    },
    titleColor: {
      type: String,
      default: "",
    },
    titleBgColor: {
      type: String,
      default: "",
    },
    descKeyName: {
      type: String,
      default: "desc",
    },
    jumpWayKeyName: {
      type: String,
      default: "jumpWay",
    },
    // 图片加载失败的替代图片
    errorImgSrc: {
      type: String,
      default: "",
    },
  },
  computed: {
    rootHeight() {
      let ratio = this.ratio;
      if (/^\d+\/\d+$/.test(ratio)) {
        ratio = ratio.split("/");
        ratio = (100 * ratio[1]) / ratio[0] + "%";
      }
      return ratio;
    },
    images() {
      let data = this.data;
      let max = this.max;
      if (max) {
        let $data = (data || []).slice(0, max);
        if (!$data.length) {
          $data = [{ image: this.noImgUrl }];
        }
        return $data;
      } else {
        return data.length ? data : [{ image: this.noImgUrl }];
      }
    },
    getTitleStyle() {
      let style = {};
      switch (this.titlePosition) {
        case "bottom":
          style = { bottom: 0, left: 0 };
          break;
        case "top":
          style = { top: 0, left: 0 };
          break;
        case "left":
          style = { left: 0, top: 0 };
          break;
        case "right":
          style = { right: 0, top: 0 };
          break;
      }
      if (this.showTitle) {
        style.width = this.titleWidth;
        style.height = this.titleHeight;
        style.fontSize = this.titleFontSize;
        style.lineHeight = this.titleLineHeight;
        style.margin = this.titleMargin.join("px ") + "px";
        style.padding = this.titlePadding.join("px ") + "px";
        style.color = this.titleColor;
        style.background = this.titleBgColor;
        if (this.titlePosition == "left" || this.titlePosition == "right") {
          style.writingMode = "tb-rl";
        }
      }
      return style;
    },
  },
  data() {
    return {
      id: "dsfBanner" + dsf.uuid(16),
      data: [],
      activeIndex: 0,
      h: "100%",
      titleStyle: {},
      noImgUrl: "",
    };
  },
  watch: {
    indicatorWidth() {
      this.updateStyleDom();
    },
    showIndicators() {
      this.setH();
    },
    titlePosition() {
      this.updateStyleDom();
    },
    indicatorHeight() {
      this.setH();
      this.updateStyleDom();
    },
    indicatorColor() {
      this.updateStyleDom();
    },
    indicatorDefaultColor() {
      this.updateStyleDom();
    },
    indicatorRadius() {
      this.updateStyleDom();
    },
    indicatorActiveWidth() {
      this.updateStyleDom();
    },
    indicatorActiveRadius() {
      this.updateStyleDom();
    },
    indicatorMarginBottom() {
      this.updateStyleDom();
    },
    list(v, ov) {
      if (!this.url) {
        this.data = dsf.mix(true, [], v);
      }
    },
  },
  created() {
    if (this.isDesign) {
      let data = [];
      if (!this.list.length) {
        data = [{ image: "" }, { image: "" }];
      } else {
        data = this.list;
      }
      this.data = dsf.mix(true, [], data);
    } else {
      if (!this.url) {
        this.data = dsf.mix(true, [], this.list);
      } else {
        this.loadData();
      }
    }
    // if (!this.errorImgSrc) {
    //   this.errorImgSrc = dsf.config.setting_nc_image_default_img || "";
    // }

    this.setH();
    let styleDom = document.createElement("style");
    document.body.append(styleDom);
    this.styleDom = styleDom;
    this.updateStyleDom();
    // this.noImageSrc ||
    this.noImgUrl = dsf.config.setting_nc_image_banner_img || dsf.config.setting_nc_image_default_img || "";
  },
  destroyed() {
    this.styleDom?.remove();
  },
  methods: {
    setH() {
      if (this.showIndicators == "outside" || this.type == "card") {
        this.h = `calc(100% - 24px - ${this.indicatorHeight}px)`;
      } else {
        this.h = "100%";
      }
    },

    updateStyleDom() {
      if (this.styleDom) {
        let bgColor = this.indicatorColor;
        let defaultColor = this.indicatorDefaultColor;
        //选中指示器宽度
        let indicatorActiveWidth = this.indicatorActiveWidth && this.indicatorActiveWidth != "0" ? this.indicatorActiveWidth : this.indicatorWidth;
        //选中指示器圆角
        let indicatorActiveRadius = this.indicatorActiveRadius && this.indicatorActiveRadius != "0" ? this.indicatorActiveRadius : this.indicatorRadius;

        let opacity = 1;
        if (bgColor === "theme") {
          bgColor = this.$root.themesConfig[this.$root.setting.theme].normal;
        }
        if (!defaultColor) {
          opacity = 0.48;
          defaultColor = bgColor;
        }

        this.styleDom.innerHTML = `
          #${this.id} .el-carousel__button{
            height: ${this.indicatorHeight}px;
            width: ${this.indicatorWidth}px;
            border-radius: ${this.indicatorRadius};
            background-color: ${defaultColor};
            opacity: ${opacity};
          }
          #${this.id} .el-carousel__indicator.is-active button{
            background-color: ${bgColor};
            opacity: 1;
            width:${indicatorActiveWidth}px;
            border-radius: ${indicatorActiveRadius};
          }
        `;
        this.styleDom.innerHTML += `
          #${this.id} .el-carousel__indicators{
            margin-bottom: ${this.indicatorMarginBottom}px
          }
        `;
        if (this.titlePosition === "bottom" && this.showTitle) {
          this.styleDom.innerHTML += `
              #${this.id} .el-carousel__indicators{
                left:initial;
                right:0;
                transform: translateX(-20%)
              }
            `;
        }
      }
    },
    formatApiData(data) {
      if (!Array.isArray(data)) {
        return [];
      }
      return data.map(it => {
        return {
          url: "",
          ...it,
          image: dsf.getFilePath(it[this.imageKeyName]),
          title: it[this.titleKeyName],
          desc: it[this.descKeyName],
          jumpWay: it[this.jumpWayKeyName],
        };
      });
    },
    loadData() {
      let url = this.$replace(this.url, this);
      this.$http.get(url).then(({ data }) => {
        this.data = this.formatApiData(data.data);
      });
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
      } catch (err) {
        console.log(err);
      }
    },
    onSwipeClick(ev, item, index) {
      if (this.type === "normal" || index == this.activeIndex) {
        if (this.$listeners["click"]) {
          this.$dispatch("click", item);
        } else {
          let toUrl = "";
          if (item.url) {
            toUrl = item.url;
          } else if (this.toUrl) {
            toUrl = this.$replace(this.toUrl, item);
          }
          this.$open({
            url: toUrl + "",
            state: item.jumpWay,
          });
        }
      }
    },
    validateApiFiled(data) {
      if (!Array.isArray(data.data)) {
        console.error("数据源必须为数组");
      } else {
        const flag = data.data.every(it => {
          return Object.prototype.toString.call(it) === "[object Object]" && Object.prototype.hasOwnProperty.call(it, "image");
        });
        if (!flag) {
          console.error(`
            接口数据格式请参考: \n
            [{\n
              image: '',\n
              title: '',\n
              desc: ''\n
            }]
          `);
        }
      }
    },
    changeTab(index) {
      this.$refs.swipe.setActiveItem(index);
    },
  },
});
</script>
