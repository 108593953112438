var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_assessment", class: _vm.getCss },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.formModel, "label-width": "80px" } },
        [
          _vm.conditions
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("考核条件")]),
                    _c("div", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.conditionsLabel)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "conditions_content" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_item_form",
                        attrs: { label: "满足学时" },
                      },
                      [
                        _vm._v(" 选修 "),
                        _c("el-input", {
                          attrs: {
                            onkeyup:
                              "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.eletivesChange(
                                _vm.formModel.electivesPeriod,
                                _vm.periodData.electivesPeriod
                              )
                            },
                          },
                          model: {
                            value: _vm.formModel.electivesPeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "electivesPeriod", $$v)
                            },
                            expression: "formModel.electivesPeriod",
                          },
                        }),
                        _c("span", [_vm._v("学时")]),
                        _c("span", [
                          _vm._v(
                            "/" +
                              _vm._s(_vm.periodData.electivesPeriod || 0) +
                              "学时    "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            "    必修 " +
                              _vm._s(_vm.periodData.compulsoryPeriod || 0) +
                              "/" +
                              _vm._s(_vm.periodData.compulsoryPeriod || 0) +
                              "学时"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.exam
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("考试")]),
                    _c("div", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.examTitleLabel)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "conditions_content" },
                  [
                    _c("DsfDataChoice", {
                      attrs: {
                        autoComplete: _vm.examAutoComplete,
                        metaDataType: _vm.examMetaDataType,
                        chooseMetaData: _vm.examChooseMetaData,
                        source: _vm.examSource,
                        maxItemsNumber: _vm.examMaxItemsNumber,
                        label: _vm.examLabel,
                        showLabel: _vm.examShowLabel,
                        textKey: _vm.examTextKey,
                        valueKey: _vm.examValueKey,
                        dialogUrl: _vm.examDialogUrl,
                        dialogTextKey: _vm.examDialogTextKey,
                        dialogValueKey: _vm.examDialogValueKey,
                        dialogTitle: _vm.examDialogSetting.split(",")[2],
                        dialogWidth: _vm.examDialogSetting.split(",")[0],
                        dialogHeight: _vm.examDialogSetting.split(",")[1],
                      },
                      on: { change: _vm.changeExamDataChoice },
                      model: {
                        value: _vm.examlists,
                        callback: function ($$v) {
                          _vm.examlists = $$v
                        },
                        expression: "examlists",
                      },
                    }),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_item_form",
                        attrs: { label: "奖励学时:" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            onkeyup:
                              "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangevalue(
                                _vm.formModel.examHurs,
                                "examHurs",
                                "formModel"
                              )
                            },
                          },
                          model: {
                            value: _vm.formModel.examHurs,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "examHurs", $$v)
                            },
                            expression: "formModel.examHurs",
                          },
                        }),
                        _vm._v(" 学时 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.questionnaire
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("问卷")]),
                    _c("div", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.questTitleLabel)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "conditions_content" },
                  [
                    _c("DsfDataChoice", {
                      attrs: {
                        autoComplete: _vm.questAutoComplete,
                        metaDataType: _vm.questMetaDataType,
                        chooseMetaData: _vm.questChooseMetaData,
                        source: _vm.questSource,
                        maxItemsNumber: _vm.questMaxItemsNumber,
                        label: _vm.questLabel,
                        showLabel: _vm.questShowLabel,
                        textKey: _vm.questTextKey,
                        valueKey: _vm.questValueKey,
                        dialogUrl: _vm.questDialogUrl,
                        dialogTextKey: _vm.questDialogTextKey,
                        dialogValueKey: _vm.questDialogValueKey,
                        dialogTitle: _vm.questDialogSetting.split(",")[2],
                        dialogWidth: _vm.questDialogSetting.split(",")[0],
                        dialogHeight: _vm.questDialogSetting.split(",")[1],
                      },
                      on: { change: _vm.changeQuestDataChoice },
                      model: {
                        value: _vm.questlists,
                        callback: function ($$v) {
                          _vm.questlists = $$v
                        },
                        expression: "questlists",
                      },
                    }),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_item_form",
                        attrs: { label: "奖励学时" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            onkeyup:
                              "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangevalue(
                                _vm.formModel.evaluatePeriod,
                                "evaluatePeriod",
                                "formModel"
                              )
                            },
                          },
                          model: {
                            value: _vm.formModel.evaluatePeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "evaluatePeriod", $$v)
                            },
                            expression: "formModel.evaluatePeriod",
                          },
                        }),
                        _vm._v(" 学时 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.homework
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("作业")]),
                    _c("div", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.homeworkTitleLabel)),
                    ]),
                    _c("el-switch", {
                      model: {
                        value: _vm.switchStatus,
                        callback: function ($$v) {
                          _vm.switchStatus = $$v
                        },
                        expression: "switchStatus",
                      },
                    }),
                  ],
                  1
                ),
                _vm.switchStatus
                  ? _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "dsf_item_form",
                            attrs: { label: "作业描述" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "dsf_input",
                              model: {
                                value: _vm.homeworkForm.homeworkName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.homeworkForm,
                                    "homeworkName",
                                    $$v
                                  )
                                },
                                expression: "homeworkForm.homeworkName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "dsf_item_form",
                            attrs: { label: "作业说明" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "dsf_input",
                              attrs: {
                                type: "textarea",
                                rows: 5,
                                resize: "none",
                              },
                              model: {
                                value: _vm.homeworkForm.homeworkRequirements,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.homeworkForm,
                                    "homeworkRequirements",
                                    $$v
                                  )
                                },
                                expression: "homeworkForm.homeworkRequirements",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "dsf_item_form",
                            attrs: { label: "成绩类型" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "dsf_input",
                                attrs: {
                                  placeholder: "下拉框，等级制、分数制",
                                },
                                model: {
                                  value: _vm.homeworkForm.homeworkScoreTypeText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.homeworkForm,
                                      "homeworkScoreTypeText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "homeworkForm.homeworkScoreTypeText",
                                },
                              },
                              _vm._l(_vm.scoreType, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.text,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "dsf_item_form",
                            attrs: { label: "奖励学时" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                onkeyup:
                                  "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangevalue(
                                    _vm.homeworkForm.homeworkPeriod,
                                    "homeworkPeriod",
                                    "homeworkForm"
                                  )
                                },
                              },
                              model: {
                                value: _vm.homeworkForm.homeworkPeriod,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.homeworkForm,
                                    "homeworkPeriod",
                                    $$v
                                  )
                                },
                                expression: "homeworkForm.homeworkPeriod",
                              },
                            }),
                            _vm._v(" 学时 "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.exam
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("证书")]),
                    _c("div", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.certificateTitleLabel)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "conditions_content" },
                  [
                    _c("DsfDataChoice", {
                      attrs: {
                        autoComplete: _vm.certificateAutoComplete,
                        metaDataType: _vm.certificateMetaDataType,
                        chooseMetaData: _vm.certificateChooseMetaData,
                        source: _vm.certificateSource,
                        maxItemsNumber: _vm.certificateMaxItemsNumber,
                        label: _vm.certificateLabel,
                        showLabel: _vm.certificateShowLabel,
                        textKey: _vm.certificateTextKey,
                        valueKey: _vm.certificateValueKey,
                        dialogUrl: _vm.certificateDialogUrl,
                        dialogTextKey: _vm.certificateDialogTextKey,
                        dialogValueKey: _vm.certificateDialogValueKey,
                        dialogTitle: _vm.certificateDialogSetting.split(",")[2],
                        dialogWidth: _vm.certificateDialogSetting.split(",")[0],
                        dialogHeight: _vm.certificateDialogSetting.split(
                          ","
                        )[1],
                      },
                      on: { change: _vm.changeCertificateDataChoice },
                      model: {
                        value: _vm.certificateLists,
                        callback: function ($$v) {
                          _vm.certificateLists = $$v
                        },
                        expression: "certificateLists",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { staticClass: "dsf_btns" },
            [
              _c("DsfButtonBar", {
                attrs: { buttons: _vm.buttons, "max-number": _vm.maxNumber },
                on: { save: _vm.onSubmit, close: _vm.onClose },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }