<template>
  <div class="dsf_recentLive" v-if="visible">
    <!-- <liveTitle :title="title" :titleFont="titleFont" :titlePadding="titlePadding"></liveTitle> -->
    <div class="content" :style="{ width: conWidth }" v-if="isDesign">
      <div class="live-big-box">
        <div class="img-box pointer" @click="goDetail(liveObj)">
          <el-image :src="liveObj.cover | imgFormat" fit="cover"></el-image>
          <div class="live-time">正在直播</div>
        </div>
        <div class="info">
          <p class="time">2021-07-16 00:00~2021-07-31 00:00</p>
          <div class="live-detail">
            <p class="detail-p">
              <span style="margin-right: 10px">张三</span>
              <span>教授</span>
            </p>
            <div class="num-box">
              <dsf-icon class="icon-yanjing"></dsf-icon>
              <span class="num">50</span>
            </div>
          </div>
        </div>
      </div>
      <div class="live-item">
        <div class="item-wrap pointer" v-for="(i, idx) in liveList" :key="idx" @click="goDetail(i)">
          <div class="img-box">
            <!-- <el-image :src="i.cover | imgFormat" fit="cover"></el-image> -->
            <div class="live-time">{{ formatterDate(i.time_sdate) }}</div>
          </div>
          <div class="info">
            <p class="ell2">{{ i.title }}</p>
            <div class="detail">
              <span>{{ i.speaker || "" }}</span>
              <span>
                <dsf-icon class="icon-yanjing"></dsf-icon>
                <span class="num">{{ i.views }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="content" :style="{ width: conWidth }" v-if="liveArray.length">
        <div class="live-big-box">
          <div class="img-box pointer" @click="goDetail(liveObj)">
            <el-image :src="liveObj.cover | imgFormat" fit="cover"></el-image>
            <div class="live-time" :style="`background-color: ${showBgColor(liveObj['state_text'])}`">{{ setTime(liveObj) }}</div>
            <p class="time">{{ formatterDate(liveObj.time_sdate) }}~{{ formatterDate(liveObj.time_edate) }}</p>
          </div>
          <div class="info">
            <p class="ell2 title">{{ liveObj.title }}</p>
            <div class="live-detail">
              <p class="detail-p ell">
                <span style="margin-right: 10px">{{ liveObj.speaker }}</span>
                <span>{{ liveObj.job || "" }}</span>
              </p>
              <div class="num-box">
                <dsf-icon class="icon-yanjing"></dsf-icon>
                <span class="num">{{ liveObj.views || 0 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="data-box" :style="{ width: `calc(100% - 620px)` }">
          <div class="live-item" v-if="liveList.length">
            <div v-for="(i, idx) in liveList" :key="idx" @click="goDetail(i)">
              <div v-if="idx < 3" class="item-wrap pointer">
                <div class="img-box">
                  <el-image :src="i.cover | imgFormat" fit="cover"></el-image>
                  <div class="seek-time" :style="`background-color: ${showBgColor(i['state_text'])}`">{{ setTime(i) }}</div>
                  <div class="live-time">{{ formatterDate(i.time_sdate) }}</div>
                </div>
                <div class="info">
                  <p class="ell2">{{ i.title }}</p>
                  <div class="detail">
                    <p class="name ell">
                      <span>{{ i.speaker || "" }}</span>
                      <span class="job">{{ i.job || "" }}</span>
                    </p>
                    <span class="num-box">
                      <dsf-icon class="icon-yanjing"></dsf-icon>
                      <span class="num">{{ i.views || 0 }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="noDate" v-if="liveList.length < 3" :class="{ centerData: !liveList.length }">
            <img src="../../../assets/imgs/pcimg/live/img_nomore.png" alt="" />
            <p>没有更多近期直播了～</p>
          </div>
        </div>
      </div>
      <div class="noDate" v-else>
        <img src="../../../assets/imgs/pcimg/live/img_nomore.png" alt="" />
        <p>没有更多近期直播了～</p>
      </div>

      <!-- 更多 -->
      <div class="moreTitle" :style="{ width: conWidth }" v-if="isMore && liveList2.length">
        <div class="border-box"></div>
        <div class="more">
          <img src="../../../assets/imgs/pcimg/live/more_left.png" alt="" />
          <span>更多</span>
          <img src="../../../assets/imgs/pcimg/live/more_right.png" alt="" />
        </div>
        <div class="border-box"></div>
      </div>
      <div class="live-item live-item2" :style="{ width: conWidth }" v-if="isMore && liveList2.length">
        <div class="item-wrap pointer margin-top" v-for="(i, idx) in liveList2" :key="idx" @click="goDetail(i)">
          <div class="img-box">
            <!-- <el-image :src="i.cover | imgFormat" fit="cover"></el-image> -->
            <dsf-nc-image :src="i['cover'] | imgFormat" :error-src="defaultSrc" />
            <div class="seek-time" :style="`background-color: ${showBgColor(i['state_text'])}`">{{ setTime(i) }}</div>
            <div class="live-time">{{ formatterDate(i.time_sdate) }}</div>
          </div>
          <div class="info">
            <p class="ell2">{{ i.title }}</p>
            <div class="detail">
              <p class="name ell">
                <span>{{ i.speaker || "" }}</span>
                <span class="job">{{ i.job || "" }}</span>
              </p>
              <span class="num-box">
                <dsf-icon class="icon-yanjing"></dsf-icon>
                <span class="num">{{ i.views || 0 }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { liveTitle } from "./index";
export default dsf.component({
  name: "DsfNcRecentLive",
  ctrlCaption: "近期直播",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "近期直播",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    conWidth: {
      type: String,
      default: "1200px",
    },
    reqUrl: {
      type: String,
      default: "nc/Meta/tree/dbSource/id/?rmId=9e8395330cf947f1b317fabc6f592e48",
    },
    detailUrl: {
      type: String,
      default: "/pc/nc/pagelive/liveDetail?id=",
    },
    detailUrlTxt: {
      type: String,
      default: "nc_live_info_id",
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      src: require("../../../assets/imgs/SC1.png"),
      liveObj: {},
      liveArray: [],
      liveList: [],
      liveList2: [],
    };
  },
  components: {
    // liveTitle,
  },
  mounted() {
    if (!this.isDesign) {
      this.getList();
    }
  },
  computed: {
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
  },
  methods: {
    showBgColor(text) {
      let c = "";
      switch (text) {
        case "直播中":
          c = "#34C47C";
          break;
        case "未开始":
          c = "#3C8DDB";
          break;
        case "已结束":
          c = "#999999";
          break;
        default:
          c = "#3C8DDB";
          break;
      }
      return c;
    },
    getList() {
      let params = {};
      const loading = dsf.layer.pc.loading();
      this.$http
        .get(this.reqUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.liveObj = res.data[0] || {};
            this.liveArray = res.data;
            this.liveList = JSON.parse(JSON.stringify(res.data));
            if (this.liveList.length) {
              this.liveList.splice(0, 1);
            }
            this.liveList2 = JSON.parse(JSON.stringify(res.data));
            if (this.liveList2.length) {
              this.liveList2.splice(0, 4);
            }
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    goDetail(item) {
      console.log(item);
      if (item["login_limit_value"] && item["login_limit_value"] == 1 && !this.isLogin) {
        this.$openDialog({
          title: "",
          width: "400px",
          height: "370px",
          noTitle: true,
          params: {
            showClose: false,
            path: dsf.config.setting_public_login_dialog,
            loginSuccess() {
              console.log("登录成功");
              dsf.layer.openWindow(this.detailUrl + item[this.detailUrlTxt]);
            },
            loginFail() {
              console.log("登录失败");
            },
          },
          onClosed() {
            dsf.layer.closeWindow();
            console.log("关闭弹窗");
          },
        });
      } else {
        dsf.layer.openWindow(this.detailUrl + item[this.detailUrlTxt]);
      }
      // dsf.layer.openWindow(this.detailUrl + item[this.detailUrlTxt]);
    },
    setTime(i) {
      console.log(i.tdoa);
      return i.tdoa < 0 || i.tdoa == 0 ? "直播中" : this.getDuration(i.tdoa * 1000, "dd天hh小时mm分");
    },
    getDuration(my_time) {
      let days = my_time / 1000 / 60 / 60 / 24;
      let daysRound = Math.floor(days);
      let hours = my_time / 1000 / 60 / 60 - 24 * daysRound;
      let hoursRound = Math.floor(hours);
      let minutes = my_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      let minutesRound = Math.floor(minutes);
      let seconds = my_time / 1000 - 24 * 60 * 60 * daysRound - 60 * 60 * hoursRound - 60 * minutesRound;
      // console.log("转换时间:", daysRound + "天", hoursRound + "时", minutesRound + "分", seconds + "秒");
      if (daysRound && daysRound < 10) {
        daysRound = "0" + daysRound;
      }
      if (hoursRound && hoursRound < 10) {
        hoursRound = "0" + hoursRound;
      }
      if (minutesRound && minutesRound < 10) {
        minutesRound = "0" + minutesRound;
      }
      let time = daysRound + "天" + hoursRound + "时" + minutesRound + "分";
      return time;
    },
    formatterDate(time, format) {
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    getUrl(cover) {
      return cover ? dsf.url.getWebPath(JSON.parse(cover)[0].relativePath) : "";
    },
  },
});
</script>
<style lang="scss" scoped></style>
