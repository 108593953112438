var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds-nc-card-course" }, [
        _c(
          "div",
          { staticClass: "cover" },
          [
            _c("dsf-image"),
            _c("span", { staticClass: "state" }, [_vm._v("课程")]),
            _vm._m(0),
          ],
          1
        ),
        _vm._m(1),
        _vm._m(2),
      ])
    : _vm.objData
    ? _c(
        "div",
        {
          staticClass: "ds-nc-card-course",
          style: { width: _vm.imgWidth },
          attrs: { title: _vm.objData["nc_courses_page_homesource._name"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "cover",
              style: { width: _vm.imgWidth, height: _vm.imgHeight },
            },
            [
              _c("dsf-image", {
                attrs: {
                  src: _vm._f("imgFormat")(
                    _vm.objData["nc_courses_page_homesource.cover"]
                  ),
                  "error-src": _vm.defaultSrc,
                },
              }),
              _vm.objData["state"]
                ? _c("span", { staticClass: "state" }, [
                    _vm._v(_vm._s(_vm.objData["state"])),
                  ])
                : _vm._e(),
              _vm.objData["hours"] || _vm.objData["durtion"]
                ? _c("div", { staticClass: "bottom" }, [
                    _c("div", [
                      _c("i", { staticClass: "iconfont icon-xueshi" }),
                      _vm._v(" " + _vm._s(_vm.objData["hours"]) + "学时 "),
                    ]),
                    _c("div", [
                      _c("i", { staticClass: "iconfont icon-shijian" }),
                      _vm._v(" " + _vm._s(_vm.objData["durtion"]) + " "),
                      _c("span", { staticClass: "static_time" }, [
                        _vm._v("00:22:15"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title ellipsis-2" }, [
              _vm.objData["nc_courses_page_homesource.is_new"]
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#ff3600",
                        "font-weight": "bold",
                        "margin-right": "5px",
                      },
                    },
                    [_vm._v("NEW")]
                  )
                : _vm._e(),
              _c("span", [
                _vm._v(_vm._s(_vm.objData["nc_courses_page_homesource._name"])),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c(
              "span",
              {
                staticClass: "ellipsis",
                attrs: {
                  title: _vm.objData["nc_courses_page_homesource.teacher_text"]
                    ? _vm.objData[
                        "nc_courses_page_homesource.teacher_text"
                      ].replace(/\^/g, "、")
                    : "",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.objData["nc_courses_page_homesource.teacher_text"]
                        ? _vm.objData[
                            "nc_courses_page_homesource.teacher_text"
                          ].replace(/\^/g, "、")
                        : ""
                    ) +
                    " "
                ),
              ]
            ),
            _c("span", [
              _c("i", {
                staticClass: "iconfont icon-guankanbeifen4",
                staticStyle: { "font-size": "0.9em" },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.objData["nc_courses_page_homesource.view_totle"] || 0
                  ) +
                  " "
              ),
            ]),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "ds-nc-card-course",
          style: { width: _vm.imgWidth },
          attrs: { title: _vm.data["title"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "cover",
              style: { width: _vm.imgWidth, height: _vm.imgHeight },
            },
            [
              _c("dsf-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
              _vm.data["state"]
                ? _c("span", { staticClass: "state" }, [
                    _vm._v(_vm._s(_vm.data["state"])),
                  ])
                : _vm._e(),
              _vm.data["hours"] || _vm.data["durtion"]
                ? _c("div", { staticClass: "bottom" }, [
                    _c("div", [
                      _c("i", { staticClass: "iconfont icon-xueshi" }),
                      _vm._v(" " + _vm._s(_vm.data["hours"]) + "学时 "),
                    ]),
                    _c("div", [
                      _c("i", { staticClass: "iconfont icon-shijian" }),
                      _vm._v(" " + _vm._s(_vm.data["durtion"]) + " "),
                      _c("span", { staticClass: "static_time" }, [
                        _vm._v("00:22:15"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title ellipsis-2" }, [
              _vm.data["isNew"]
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#ff3600",
                        "font-weight": "bold",
                        "margin-right": "5px",
                      },
                    },
                    [_vm._v("NEW")]
                  )
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.data["title"]))]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c(
              "span",
              {
                staticClass: "ellipsis",
                attrs: {
                  title: _vm.data["teacher"]
                    ? _vm.data["teacher"].replace(/\^/g, "、")
                    : "",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.data["teacher"]
                        ? _vm.data["teacher"].replace(/\^/g, "、")
                        : ""
                    ) +
                    " "
                ),
              ]
            ),
            _c("span", [
              _c("i", {
                staticClass: "iconfont icon-guankanbeifen4",
                staticStyle: { "font-size": "0.9em" },
              }),
              _vm._v(" " + _vm._s(_vm.data["views"] || 0) + " "),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom" }, [
      _c("div", [
        _c("i", { staticClass: "iconfont icon-xueshi" }),
        _vm._v(" 0.2学时 "),
      ]),
      _c("div", [
        _c("i", { staticClass: "iconfont icon-shijian" }),
        _vm._v(" 00:22:15 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title ellipsis-2" }, [
        _c(
          "span",
          {
            staticStyle: {
              color: "#ff3600",
              "font-weight": "bold",
              "margin-right": "5px",
            },
          },
          [_vm._v("NEW")]
        ),
        _c("span", [
          _vm._v(
            "课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("span", [_vm._v("张三")]),
      _c("span", [
        _c("i", {
          staticClass: "iconfont icon-guankanbeifen4",
          staticStyle: { "font-size": "0.9em" },
        }),
        _vm._v(" 1234 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }