<template>
  <div class="dsf_registerFormation_information_wrap">
    <div class="dsf_registerFormation_information">
      <div class="_infor_box">
        <!-- <div class="title">请填写个人信息</div> -->
        <el-form ref="form" :rules="rules" :model="form" :label-width="labelWidth">
          <el-form-item :label="item.name" :prop="item.rule" v-for="(item, index) in fromItems" :key="index">
            <div v-if="item.type == 'textbox' && item.rule != 'age'">
              <el-input v-model="form[item.rule]" :placeholder="item.text"></el-input>
            </div>
            <div v-if="item.type == 'ouaselect'">
              <!-- <DsfOuaSelect :showLabel="false" :dialogUrl="dialogUrl" /> -->
              <el-input v-model="form[item.rule]" :placeholder="item.text"></el-input>
            </div>
            <div v-if="item.type == 'areaselect'">
              <!-- <DsfAreaSelect v-if="item.rule == 'address'" :showLabel="false" v-model="form[item.rule]" /> -->
              <el-input v-if="item.rule == 'address'" v-model="form[item.rule]" :placeholder="item.text"></el-input>
              <DsfAreaSelect v-if="item.rule == 'city'" :range="['0', '2']" :showLabel="false" v-model="areaselectList" />
            </div>
            <div v-if="item.type == 'uploadphoto'">
              <DsfUploadPhoto :showLabel="false" v-model="form[item.rule]" />
            </div>
            <div v-if="item.type == 'uploadimg'">
              <DsfUploadImg v-if="item.rule == 'image1'" :showLabel="false" v-model="image1" />
              <DsfUploadImg v-if="item.rule == 'image2'" :showLabel="false" v-model="image2" />
              <DsfUploadImg v-if="item.rule == 'image3'" :showLabel="false" v-model="image3" />
              <DsfUploadImg v-if="item.rule == 'image4'" :showLabel="false" v-model="image4" />
            </div>
            <div v-if="item.rule == 'sex'">
              <el-radio v-model="form[item.rule]" label="1">男</el-radio>
              <el-radio v-model="form[item.rule]" label="2">女</el-radio>
            </div>
            <div v-if="item.rule == 'age'">
              <el-input-number v-model="form[item.rule]" :placeholder="item.text" :min="1" :max="200" :step="1" step-strictly></el-input-number>
            </div>
            <div v-if="item.type == 'checkbox'">
              <div class="checbox" v-if="item.rule == 'checkbox1'">
                <el-checkbox-group v-model="checkbox1" v-for="(it, idx) in item.values" :key="idx + 'checkbox'">
                  <el-checkbox :label="it.value" :checked="it.check">{{ it.text }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="checbox" v-if="item.rule == 'checkbox2'">
                <el-checkbox-group v-model="checkbox2" v-for="(it, idx) in item.values" :key="idx + 'checkbox'">
                  <el-checkbox :label="it.value" :checked="it.check">{{ it.text }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="checbox" v-if="item.rule == 'checkbox3'">
                <el-checkbox-group v-model="checkbox3" v-for="(it, idx) in item.values" :key="idx + 'checkbox'">
                  <el-checkbox :label="it.value" :checked="it.check">{{ it.text }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="checbox" v-if="item.rule == 'checkbox4'">
                <el-checkbox-group v-model="checkbox4" v-for="(it, idx) in item.values" :key="idx + 'checkbox'">
                  <el-checkbox :label="it.value" :checked="it.check">{{ it.text }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div v-if="item.type == 'radiobox'" class="radiobox">
              <el-radio-group v-model="form[item.rule]" v-for="(it, idx) in item.values" :key="idx + 'radiobox'">
                <el-radio :label="it.value">{{ it.text }}</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <!-- <el-form-item class="submit_btn" v-if="hasSubmitBtn">
            <el-button class="btns" type="primary" @click="onSubmit('form')">提交</el-button>
            <el-button class="btns" @click="cancel('form')">取消</el-button>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcRegisterFormation",
  ctrlCaption: "专题班个人信息弹框",
  mixins: [$mixins.control, $mixins.formControl],
  props: {
    // dialogUrl: {
    //   type: String,
    //   default: "dsfa/oua/select",
    // },
    // inforPostSuccess: {
    //   type: Function,
    //   required: false,
    // },
    // show: {
    //   type: Boolean,
    //   default: false,
    // },
    // url: {
    //   default: "/nc/class/apply/info",
    //   type: String,
    // },
    // hasSubmitBtn: {
    //   default: true,
    //   type: Boolean,
    // },
    // hasEmit: {
    //   default: false,
    //   type: Boolean,
    // },
    fromValues: {
      default: () => {
        return [];
      },
      type: Array,
    },
    labelWidth: {
      type: String,
      default: "67px",
    },
  },
  data() {
    return {
      params: {
        id: "",
      },
      form: {
        // name: "",
      },
      checkbox1: [], // 多选1
      checkbox2: [], // 多选2
      checkbox3: [], // 多选3
      checkbox4: [], // 多选4
      image1: [], // 图片1
      image2: [], // 图片2
      image3: [], // 图片3
      image4: [], // 图片4
      areaselectList: {}, // 省份城市选择
      fromItems: [],
      rules: {
        name: [{ required: false, message: "请输入参与人名称", trigger: "blur" }],
        phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入手机号码"));
              }
              if (!dsf.validate("isMobile", value)) {
                return callback(new Error("手机号码格式不正确"));
              }
              callback();
            },
            trigger: ["blur"],
          },
          {},
        ],
        company: [{ required: false, message: "请输入参与人公司", trigger: "blur" }],
        post: [{ required: false, message: "请输入参与人职位", trigger: "blur" }],
        department: [{ required: false, message: "请输入参与人部门", trigger: "blur" }],
        address: [{ required: false, message: "请输入参与人住址", trigger: "blur" }],
        idCard: [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (!value && !rule.required) {
                return callback();
              }
              if (!value) {
                return callback(new Error("请输入身份证"));
              }
              if (!dsf.validate("isIDCard", value)) {
                return callback(new Error("身份证号格式不正确"));
              }
              callback();
            },
            trigger: ["blur"],
          },
        ],
        sex: [{ required: false, message: "请输入参与人性别", trigger: "blur" }],
        age: [{ required: false, message: "请输入参与人年龄", trigger: "blur" }],
        userName: [{ required: false, message: "请输入参与人真实姓名", trigger: "blur" }],
        photo: [{ required: false, message: "请输入参与人个人照片", trigger: "blur" }],
        city: [{ required: false, message: "请输入参与人省份城市", trigger: "blur" }],
        email: [{ required: false, message: "请输入参与人邮箱", trigger: "blur" }],
        radio1: [{ required: false, message: "请输入参与人单选", trigger: "blur" }],
        radio2: [{ required: false, message: "请输入参与人单选", trigger: "blur" }],
        radio3: [{ required: false, message: "请输入参与人单选", trigger: "blur" }],
        radio4: [{ required: false, message: "请输入参与人单选", trigger: "blur" }],
        checkbox1: [{ required: false, message: "请输入参与人多选", trigger: "blur" }],
        checkbox2: [{ required: false, message: "请输入参与人多选", trigger: "blur" }],
        checkbox3: [{ required: false, message: "请输入参与人多选", trigger: "blur" }],
        checkbox4: [{ required: false, message: "请输入参与人多选", trigger: "blur" }],
        image1: [{ required: false, message: "请上传参与人图片", trigger: "blur" }],
        image2: [{ required: false, message: "请上传参与人图片", trigger: "blur" }],
        image3: [{ required: false, message: "请上传参与人图片", trigger: "blur" }],
        image4: [{ required: false, message: "请上传参与人图片", trigger: "blur" }],
        text1: [{ required: false, message: "请输入参与人自定义文本", trigger: "blur" }],
        text2: [{ required: false, message: "请输入参与人自定义文本", trigger: "blur" }],
        text3: [{ required: false, message: "请输入参与人自定义文本", trigger: "blur" }],
        text4: [{ required: false, message: "请输入参与人自定义文本", trigger: "blur" }],
      },
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "";
  },
  mounted() {},
  watch: {
    form: {
      handler(val) {
        this.$emit("onSubmit", this.form);
      },
      immediate: true,
      deep: true,
    },
    fromValues: {
      handler(val) {
        this.initData(val);
      },
      immediate: true,
      deep: true,
    },
    image1: {
      handler(val) {
        if (!dsf.isEmptyObject(val) && val != "[]") {
          this.targetData("image1", val);
        } else {
          this.targetData("image1", "");
        }
      },
      immediate: true,
    },
    image2: {
      handler(val) {
        if (!dsf.isEmptyObject(val) && val != "[]") {
          this.targetData("image2", val);
        } else {
          this.targetData("image2", "");
        }
      },
      immediate: true,
    },
    image3: {
      handler(val) {
        if (!dsf.isEmptyObject(val) && val != "[]") {
          this.targetData("image3", val);
        } else {
          this.targetData("image3", "");
        }
      },
      immediate: true,
    },
    image4: {
      handler(val) {
        if (!dsf.isEmptyObject(val) && val != "[]") {
          this.targetData("image4", val);
        } else {
          this.targetData("image4", "");
        }
      },
      immediate: true,
    },
    checkbox1: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("checkbox1", JSON.stringify(val));
        } else {
          this.targetData("checkbox1", "");
        }
      },
      immediate: true,
    },
    checkbox2: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("checkbox2", JSON.stringify(val));
        } else {
          this.targetData("checkbox2", "");
        }
      },
      immediate: true,
    },
    checkbox3: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("checkbox3", JSON.stringify(val));
        } else {
          this.targetData("checkbox3", "");
        }
      },
      immediate: true,
    },
    checkbox4: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("checkbox4", JSON.stringify(val));
        } else {
          this.targetData("checkbox4", "");
        }
      },
      immediate: true,
    },
    text1: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("text1", val);
        } else {
          this.targetData("text1", "");
        }
      },
      immediate: true,
    },
    text2: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("text2", val);
        } else {
          this.targetData("text2", "");
        }
      },
      immediate: true,
    },
    text3: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("text3", val);
        } else {
          this.targetData("text3", "");
        }
      },
      immediate: true,
    },
    text4: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("text4", val);
        } else {
          this.targetData("text4", "");
        }
      },
      immediate: true,
    },
    areaselectList: {
      handler(val) {
        if (!dsf.isEmptyObject(val)) {
          this.targetData("city", JSON.stringify(val));
        } else {
          this.targetData("city", "");
        }
      },
      immediate: true,
    },
  },
  methods: {
    targetData(target, value) {
      for (const targetKey in this.form) {
        if (Object.hasOwnProperty.call(this.form, targetKey)) {
          if (targetKey === target) {
            this.form[targetKey] = value;
          }
        }
      }
      // console.log(target, 2525252, value, this.form);
    },

    initData(data) {
      this.fromItems = JSON.parse(data || "[]");
      console.log(this.fromItems);
      this.fromItems.map(item => {
        for (const key in this.rules) {
          if (key == item.rule) {
            this.rules[key][0].required = item.required || false;
            this.rules[key][0].message = item.text;
          }
        }

        if (item.rule?.indexOf("radio") > -1) {
          // 根据数据返回单选框赋值
          item.values.forEach((value, index) => {
            if (value.check) {
              this.$set(this.form, item.rule, index);
            }
          });
        } else {
          // 其他赋默认值空数组
          this.$set(this.form, item.rule, "");
        }
      });

      // this.renderData()
    },
    renderData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get("/nc/org/user/base/info", {}, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            // console.log("点击==", this.form);
            this.form.name = data.name || "";
            this.form.phone = data.phone || "";
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // sessionStorage.setItem("specialSubmit", JSON.stringify(this.form));
          // dsf.layer.closeWindow(this);
          this.$emit("personalInforSubmit", JSON.stringify(this.form));
        } else {
          // console.log("error submit!!");
          return false;
        }
      });

      // console.log("submit===>", this.form);
    },
    cancel() {
      // dsf.layer.closeWindow(this);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
