var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_search_column" },
    _vm._l(_vm.data, function (item) {
      return _c(
        "div",
        { key: "item" + item.name, staticClass: "column-inner" },
        [
          item.typename == "新闻"
            ? _c(
                "newsColumn",
                _vm._g(
                  {
                    key: "newsColumn" + item.id,
                    attrs: { data: item, pageType: _vm.pageType },
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
          item.typename == "课程"
            ? _c(
                "courseColumn",
                _vm._g(
                  {
                    key: "courseColumn" + item.id,
                    attrs: { data: item, pageType: _vm.pageType },
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
          item.typename == "专题班"
            ? _c(
                "specialColumn",
                _vm._g(
                  {
                    key: "specialColumn" + item.id,
                    attrs: { data: item, pageType: _vm.pageType },
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
          item.typename == "频道"
            ? _c(
                "channelColumn",
                _vm._g(
                  {
                    key: "channelColumn" + item.id,
                    attrs: { data: item, pageType: _vm.pageType },
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
          item.typename == "名师"
            ? _c(
                "teacherColumn",
                _vm._g(
                  {
                    key: "teacherColumn" + item.id,
                    attrs: { data: item, pageType: _vm.pageType },
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
          item.typename == "考试"
            ? _c(
                "examColumn",
                _vm._g(
                  {
                    key: "examColumn" + item.id,
                    attrs: { data: item, pageType: _vm.pageType },
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
          item.typename == "直播"
            ? _c(
                "liveColumn",
                _vm._g(
                  {
                    key: "liveColumn" + item.id,
                    attrs: { data: item, pageType: _vm.pageType },
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }