var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_course_chapter" }, [
    _c(
      "div",
      { staticClass: "cousrse-chapter-inner" },
      [
        !_vm.dsf.isEmptyObject(_vm.chapters)
          ? _c(
              "el-collapse",
              {
                model: {
                  value: _vm.activeNames,
                  callback: function ($$v) {
                    _vm.activeNames = $$v
                  },
                  expression: "activeNames",
                },
              },
              _vm._l(_vm.resetChapters, function (item, index) {
                return _c(
                  "el-collapse-item",
                  {
                    key: item.name + index,
                    class: { "no-active": !item.children },
                    attrs: { name: index },
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("div", { staticClass: "cousrse-item-head" }, [
                        _c(
                          "div",
                          { staticClass: "icon-wrap" },
                          [
                            _c("dsf-icon", {
                              attrs: {
                                name: _vm.getTitleIcon(item.rTypeValue),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "title-wrap" }, [
                          _c("div", { staticClass: "title-inner" }, [
                            _c(
                              "h3",
                              {
                                staticClass: "title ellipsis",
                                attrs: { title: item.title || "" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.title) +
                                    " " +
                                    _vm._s(item.ext) +
                                    " "
                                ),
                              ]
                            ),
                            item.isRequired != null
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "item-state",
                                    style:
                                      "width:40px;color:#FFF;background-color:" +
                                      (item.isRequired == 1
                                        ? "#C90C0A"
                                        : "#69C33B") +
                                      " ",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.isRequiredItemText(item)) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.showState(item)
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "item-state",
                                    class: {
                                      "item-finish": item.isFinished == 1,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.showText(item)) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          item.period
                            ? _c("p", { staticClass: "study-hour" }, [
                                _vm._v(_vm._s(item.period) + "学时"),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.showHandle(item) && !_vm.isView
                          ? _c(
                              "span",
                              {
                                staticClass: "to-handle",
                                on: {
                                  click: function ($event) {
                                    return _vm.handle(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.isFinished ? "查看" : "提交")
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "chapter-content-wrap" }, [
                      _c(
                        "div",
                        {
                          ref: "chapterList",
                          refInFor: true,
                          staticClass: "chapter-content",
                        },
                        _vm._l(item.children, function (it, index) {
                          return _c(
                            "div",
                            {
                              key: it.id,
                              class: [
                                "tab-content-desc",
                                {
                                  "desc-item-sel":
                                    _vm.chapters.curPlayId == it.id ||
                                    _vm.idx == index,
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  item.rTypeValue === "course" &&
                                    _vm.itemFn(it, index)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "wrap" },
                                [
                                  item.rTypeValue === "course"
                                    ? _c("el-progress", {
                                        staticClass: "progress-circle",
                                        attrs: {
                                          width: "40",
                                          "stroke-width": "3",
                                          color: _vm.curColor,
                                          type: "circle",
                                          percentage: it.finishedRate,
                                          format: _vm.formatPercent,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "item-icon",
                                      class: {
                                        "item-means":
                                          item.rTypeValue === "file",
                                        "nc-icon": item.rTypeValue === "course",
                                      },
                                    },
                                    [
                                      _c("dsf-icon", {
                                        attrs: {
                                          name:
                                            item.rTypeValue === "course"
                                              ? _vm.getCourseIcon(it)
                                              : _vm.getIcon(
                                                  it,
                                                  item.rTypeValue
                                                ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "con-title ellipsis",
                                      class: {
                                        "no-pointer":
                                          item.rTypeValue === "file",
                                      },
                                      attrs: { title: it.title },
                                    },
                                    [_vm._v(_vm._s(it.title))]
                                  ),
                                  item.rTypeValue === "file"
                                    ? _c(
                                        "span",
                                        { staticClass: "item-handle" },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "preview",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.fileHandle(it, 0)
                                                },
                                              },
                                            },
                                            [_vm._v("预览")]
                                          ),
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: it.extValue == 1,
                                                  expression:
                                                    "it.extValue == 1",
                                                },
                                              ],
                                              staticClass: "down",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.fileHandle(it, 1)
                                                },
                                              },
                                            },
                                            [_vm._v("下载")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.chapters.curPlayId == it.id ||
                              _vm.idx == index
                                ? _c("dsf-icon", { attrs: { name: "bofang1" } })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  2
                )
              }),
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.homeworkParams.show
      ? _c(
          "div",
          [
            _c("DsfNcCheckBriefSummary", {
              attrs: {
                show: _vm.homeworkParams.show,
                homeworkId: _vm.homeworkParams.homeworkId,
                homeworkState: _vm.homeworkParams.homeworkState,
                titleName: _vm.homeworkParams.title,
                "show-title": _vm.homeworkParams.showTitle,
                readyonly: _vm.homeworkParams.readyonly,
                required: _vm.homeworkParams.required,
                "dialog-width": "520px",
              },
              on: {
                "update:show": function ($event) {
                  return _vm.$set(_vm.homeworkParams, "show", $event)
                },
                breisfsSummarySubmit: _vm.homeworkSuccess,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.showCertificate
      ? _c(
          "div",
          [
            _c("DsfNcCertificate", {
              attrs: { show: _vm.showCertificate, imgUrl: _vm.certificateUrl },
              on: {
                "update:show": function ($event) {
                  _vm.showCertificate = $event
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }