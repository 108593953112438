<template>
  <div class="ds-control ds-navtab ds-side-navtab ds_nc_ali_sidebar" :class="{ 'ds-no-padding': !showPadding }" :style="{ height: isDesign ? '100%' : defaultHeight }">
    <el-tabs
      v-model="activeName"
      :tab-position="'left'"
      :type="tabStyle == 'customer-tab-pad' ? '' : tabStyle"
      :closable="enableDelete"
      @tab-click="_clickBefore(tabClick)"
      @tab-remove="tabRemove"
    >
      <slot></slot>
      <el-tab-pane
        v-for="d in slots.filter(tab => {
          return isHide(tab) !== true;
        })"
        :key="d.name"
        :disabled="isdisable(d)"
        :label="d.title"
        :name="d.name"
        :slot-name="d.name"
        :lazy="isLazy(d)"
        :style="{ padding: $padding }"
      >
        <span slot="label" class="label_font">
          <i :class="`ali_sidebar_icon${activeName == d.name ? ' active' : ''}`"></i>
          {{ d.title }}
          <span v-if="d.badge !== null" class="el-badge" v-html="d.badge"></span>
        </span>
        <slot :name="d.name" v-if="isDesign"></slot>
        <div v-if="activeName == d.name && !isDesign" :class="['insert', { 'insert-wrap': !isDesign }]">
          <slot :name="d.name"></slot>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcAliSideBar",
  ctrlCaption: "数智学堂侧边导航",
  mixins: [$mixins.tabControl],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    showPadding: {
      type: Boolean,
      default: false,
    },
    moduleWidth: {
      type: String,
      default: "220px",
    },
    moduleHeight: {
      type: String,
      default: "840px",
    },
    active: {
      type: String,
      default: "",
    },
    defaultHeight: {
      type: String,
      default: window.innerHeight - 180 - 70 - 20 + "px",
    },
  },
  data() {
    return {
      activeName: this.$route.query.active || "",
    };
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.$checkLogin();
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
  },
});
</script>
