var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf-nc-live-shop-card" }, [
        _c("div", { staticClass: "content" }, [
          _vm.isDesign
            ? _c(
                "div",
                { staticClass: "item-wrap", style: { width: _vm.cardWidth } },
                [
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    [
                      _c("el-image", {
                        attrs: { src: _vm._f("imgFormat")("/"), fit: "cover" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "time-box" }, [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(_vm.formatterDate(11223232, "mm月dd日 hh:ii"))
                        ),
                      ]),
                      _c("span", { staticClass: "playType pointer" }, [
                        _vm._v("开启提醒"),
                      ]),
                    ]),
                    _c("p", { staticClass: "ell" }, [
                      _vm._v("上海全力打造全国新一轮全面开放新高地的新举措"),
                    ]),
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "shop-buy-items no-padding posi-bottom" },
                      [
                        _c("div", { staticClass: "ms" }, [
                          _c("span", { staticClass: "prefix" }, [
                            _vm._v("秒杀价"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "c-sale" },
                            [_c("dsf-icon"), _vm._v(" 8500 ")],
                            1
                          ),
                          _c("span", { staticClass: "o-sale" }, [
                            _vm._v("1200"),
                          ]),
                        ]),
                        _c("div", { staticClass: "ms" }, [
                          _c("span", { staticClass: "prefix present" }, [
                            _vm._v("赠"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "jf" },
                            [_c("dsf-icon"), _vm._v(" 10 ")],
                            1
                          ),
                          _c("span", { staticClass: "dyq" }, [_vm._v("20")]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "live-type" }, [_vm._v("未开始")]),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "item-wrap pointer",
                  style: { width: _vm.cardWidth },
                  on: {
                    click: function ($event) {
                      return _vm._clickBefore(_vm.goDetail)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.data["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "time-box" }, [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(_vm.data["timeYMD"]) +
                            " " +
                            _vm._s(_vm.data["timeHMS"])
                        ),
                      ]),
                      _c("span", { staticClass: "playType pointer" }, [
                        _vm._v(_vm._s(_vm.getType(_vm.data))),
                      ]),
                    ]),
                    _c("p", { staticClass: "ell" }, [
                      _vm._v(_vm._s(_vm.data["title"])),
                    ]),
                    _c("div", { staticClass: "detail" }, [
                      _c("div", { staticClass: "teacher" }, [
                        _vm.data["teacherJobName"]
                          ? _c("span", { staticClass: "job" }, [
                              _vm._v(_vm._s(_vm.data["teacherJobName"])),
                            ])
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(_vm.data["teacher"]))]),
                      ]),
                      _c("div", { staticClass: "sales" }, [
                        _vm._v("销量：" + _vm._s(_vm.data["indentNum"] || 0)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "shop-buy-items no-padding posi-bottom" },
                      [
                        _c("div", { staticClass: "ms" }, [
                          _vm.prefixText && _vm.getPrefix("prefixText")
                            ? _c("span", { staticClass: "prefix" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.prefixText
                                      ? _vm.getPrefix("prefixText")
                                      : ""
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            { staticClass: "c-sale" },
                            [
                              _c("dsf-icon", { attrs: { name: "jinbi" } }),
                              _vm._v(
                                " " + _vm._s(_vm.data["price"] || "免费") + " "
                              ),
                            ],
                            1
                          ),
                          _vm.data["old_price"]
                            ? _c("span", { staticClass: "o-sale" }, [
                                _vm._v(_vm._s(_vm.data["old_price"])),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.data["giftPayment"]
                          ? _c("div", { staticClass: "ms" }, [
                              _c("span", { staticClass: "prefix present" }, [
                                _vm._v("赠"),
                              ]),
                              _c(
                                "span",
                                { staticClass: "jf" },
                                [
                                  _c("dsf-icon"),
                                  _vm._v(
                                    " " + _vm._s(_vm.data["giftPayment"]) + " "
                                  ),
                                ],
                                1
                              ),
                              _vm.data["giftCoupon"]
                                ? _c("span", { staticClass: "dyq" }, [
                                    _vm._v(_vm._s(_vm.data["giftCoupon"])),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm.data["stateText"]
                    ? _c("div", { staticClass: "live-type" }, [
                        _vm._v(_vm._s(_vm.data["stateText"])),
                      ])
                    : _vm._e(),
                ]
              ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail" }, [
      _c("div", { staticClass: "teacher" }, [
        _c("span", { staticClass: "job" }, [_vm._v("网院")]),
        _c("span", [_vm._v("测试")]),
      ]),
      _c("div", { staticClass: "sales" }, [_vm._v("销量：230")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }