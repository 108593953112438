<template>
  <div class="dsf_nc_ckplayer" v-if="!isDesign">
    <div class="ckplayer-inner" id="ckplayerInner" :style="{ width: width }" v-if="videoSource">
      <div class="audio" v-if="!showVideoPlayer && videoSrc().type == 'audio/mp3'">
        <audio controls :src="videoSrc().type === 'audio/mp3' ? videoSrc().src : ''" id="playAudio" controlsList="nodownload">
          <p>
            Your browser does not support the
            <code>audio</code>
            element.
          </p>
        </audio>
        <div>
          <div v-if="!allowDrag" class="hidetimeline"></div>
        </div>
      </div>
      <div style="height: 100%" v-else>
        <div id="dsfNcCkplayer" ref="ckPlayer" :style="`height: 100%;`"></div>
      </div>
    </div>
    <div class="audio-cover-wrap" v-if="videoSrc().type === 'audio/mp3'" :class="audioPlay ? 'audio-play' : 'audio-paused'">
      <dsf-nc-image :src="itemCover" v-if="itemCover" class="audio-cover" />
    </div>
  </div>
  <div class="dsf_nc_ckplayer" v-else>
    <div class="ckplayer-inner" :style="{ width: width }">
      <div style="height: 100%">
        <div id="dsfNcCkplayer" v-if="!itemCover" ref="ckPlayer" style="height: 100%"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default dsf.component({
  name: "DsfNcCkplayer",
  ctrlCaption: "ckplayer播放器",
  mixins: [$mixins.control],
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: window.innerHeight - 93 + "px",
    },
    videoSource: {
      type: [String, Array],
      default: "",
    },
    showBackrate: {
      type: [String, Number],
      default: 1,
      desc: "是否显示倍数",
    },
    is_auto: {
      type: Boolean,
      default: true,
      desc: "是否自动播放",
    },
    is_speed: {
      type: Boolean,
      default: true,
      desc: "是否显示倍数播放",
    },
    is_hd: {
      type: Boolean,
      default: false,
      desc: "是否显示清晰度切换",
    },
    is_allow_drag: {
      type: Boolean,
      default: false,
      desc: "课件完成度100%允许拖拽",
    },
    is_drag: {
      type: Boolean,
      default: true,
      desc: "是否允许拖拽",
    },
    allowDrag: {
      type: Boolean,
      default: false,
      desc: "是否允许拖拽,组件传入状态",
    },
    ctrl_tab_stop: {
      type: Boolean,
      default: false,
      desc: "是否允许切换窗播放",
    },
    end_action: {
      type: String,
      default: "1",
      desc: "播放结束是否循环",
    },
    hint: {
      type: Boolean,
      default: true,
      desc: "是否允许分钟提示暂停",
    },
    cover: {
      type: [String, Object],
      default() {
        return {};
      },
      desc: "封面图片",
    },
    videoStatus: {
      type: Boolean,
      default: true,
    },
    playerParams: {
      type: Object,
      default: () => {
        return {
          playerID: "ncCourseCkplayer",
          container: "#dsfNcCkplayer",
          variable: "player",
          html5m3u8: true,
          loaded: "playerLoaded",
          // loop: true,
          autoplay: true,
          sortPlay: false,
          next: "nextCourse",
          defaultPoster: "", //封面图片
          playbackrate: 1,
          drag: "start",
          debug: false,
          isloaded: false
        };
      },
    },
  },
  data() {
    return {
      ckPlayer: null,
      audio: null,
      watermark: null,
      hasShowRemind: false,
      hasInit: false,
      playIng: false,
      audioPlay: false,
      remindTimer: null,
    };
  },
  computed: {
    ...mapGetters(["getSeektime"]),
    videoSrc() {
      return function () {
        let url = this.videoSource;
        let type = "video/m3u8";
        if (!url) return {};
        if (Object.prototype.toString.call(url) !== "[object Array]") {
          if (!url.indexOf("mp3") != -1) {
            let arr = url.split(".");
            let extName = arr[arr.length - 1];
            switch (extName) {
              case "mp3":
                type = "audio/mp3";
                break;
              case "m3u8":
                type = "video/m3u8";
                break;
              case "mp4":
                type = "video/mp4";
                break;
              default:
                if (url.indexOf("mp3") != -1) type = "audio/mp3";
                break;
            }
          }
        }
        return {
          type: type,
          src: url,
        };
      };
    },
    curColor() {
      return this.$root?.currentTheme?.normal || "#fc7001";
    },
    itemCover() {
      let cover = dsf.config.setting_nc_image_default_img;
      if (this.cover) {
        let v = this.cover;
        if (dsf.isUnDef(v) || v == "") {
          return "";
        }
        let obj = v;
        if (dsf.type(obj) == "string") {
          if (obj.indexOf("relativePath") == -1 || obj.indexOf("absolutePath") == -1) {
            return v;
          }
          return dsf.getFilePath(obj);
        }
        return "";
      }
      return cover;
    },
    showVideoPlayer() {
      let url = this.videoSource;
      if (!url) return false;
      if (Object.prototype.toString.call(url) === "[object Array]") return true;
      let arr = url.split(".");
      let extName = arr[arr.length - 1];
      return extName === "mp4" || extName === "m3u8";
    },
  },
  mounted() {
    this.playerInit();
  },
  methods: {
    playerInit() {
      if (window.$$serverConfig) {
        let {
          setting_nc_player_is_auto, //是否自动播放
          setting_nc_player_is_speed, //是否显示倍数
          setting_nc_player_is_drag, //是否允许拖拽
          setting_nc_player_ctrl_tab_stop, //切换窗口是否暂停视频
          setting_nc_player_end_action, //播放结束是否循环
          setting_nc_player_is_hd, //是否显示清晰度切换
        } = window.$$serverConfig;

        this.is_auto = Boolean(Number(setting_nc_player_is_auto));
        this.is_speed = Boolean(Number(setting_nc_player_is_speed));
        // this.is_drag = Boolean(Number(setting_nc_player_is_drag)); //当前播放视频节点已经完成的视频限制??
        this.is_drag = Number(setting_nc_player_is_drag); //当前播放视频节点已经完成的视频限制??
        this.is_hd = Boolean(Number(setting_nc_player_is_hd));
        this.ctrl_tab_stop = Boolean(Number(setting_nc_player_ctrl_tab_stop));
        this.end_action = setting_nc_player_end_action;
      }

      this.$nextTick(() => {
        this.loadScript(this.initCoursePlayer);
      });
    },
    loadScript(callback) {
      if (window.ckplayer) {
        callback?.();
      } else {
        this.$http
          .importFiles([
            [dsf.url.getWebPath("$/js/libs/ckplayer/ckPlayer.js"), ".js"],
            [dsf.url.getWebPath("$/js/libs/ckplayer/hls/hls.min.js"), ".js"],
          ])
          .then(() => {
            callback?.();
          })
          .catch(() => {
            dsf.layer.message("加载ckPlayer文件报错", false);
          });
      }
    },
    initAudio() {
      let that = this;
      this.$nextTick(() => {
        this.audio = document.querySelector("#playAudio");
        if (this.audio) {
          const audio = this.audio;
          let timer = null;
          audio.currentTime = this.getSeektime || 0;
          this.end_action == 1 && audio.setAttribute("loop", "loop");
          this.is_auto && audio.setAttribute("autoplay", "autoplay");
          audio.addEventListener("play", function (e) {
            that.audioPlay = true;
            that.$emit("playerHasPlay");
            timer = setInterval(() => {
              let t = audio.currentTime;
              that.$emit("playerPlayingTime", t);
            }, 950);
          });
          audio.addEventListener("pause", function (e) {
            that.audioPlay = false;
            that.$emit("playerHasPause");
            if (timer) clearInterval(timer);
          });
          audio.addEventListener("ended", function (e) {
            that.audioPlay = false;
            that.$emit("playHasEnd");
            if (timer) clearInterval(timer);
          });
          audio.addEventListener("ratechange", function (e) {
            that.$emit("playbackRateUpdate", audio.playbackRate || 1);
          });
          this.initWaterMark().then(res => {
            if (res) {
              const ckplayerInner = document.querySelector("#ckplayerInner");
              let waterMark = new Image();
              waterMark.src = res;
              waterMark.className = "water_mark_audio";
              ckplayerInner.appendChild(waterMark);
            }
          });
        }
      });
    },
    /***
     *  ckplayer: config 是否允许拖拽
     *  0:不能拖拽
     *  1:可以拖拽
     *  2:只能向右拖拽
     *  3:只能后退
     *  4:能回到第一次拖动时的位置
     *  5:看过的地方可以随意拖动
     */
    initCoursePlayer() {
      let that = this;
      if (this.videoSource) {
        this.hasInit = true;
        let { src, type } = this.videoSrc();
        if (type == "audio/mp3") {
          this.initAudio();
        } else {
          // this.playerParams.video = this.is_hd ? src : typeof src == "string" ? src : src[0][0];
          // this.playerParams.video = typeof src === "string" ? src : src[0][0];
          // this.playerParams.seek = this.playerParams.seek ? this.playerParams.seek : this.getSeektime || 0;
          // this.playerParams.themeColor = this.curColor;
          this.initWaterMark()
            .then(res => {
              that.playerParams.logo = res || null;
              let params = {
                seek: this.playerParams.seek ? this.playerParams.seek : this.getSeektime || 0,
                video: typeof src === "string" ? src : src[0][0],
                themeColor: this.curColor,
                autoplay: this.is_auto,
                playbackrate: this.is_speed ? 1 : false,
                loop: this.end_action == 1,
              };
              let player = new ckplayer(Object.assign(this.playerParams, params));
              that.ckPlayer = player;
              this.ctrl_tab_stop && this.initPlayerTabStop();
              window.playerLoaded = function () {
                that.ckPlayer.addListener("error", that.playerLoadError); //监听视频加载出错
                that.ckPlayer.addListener("duration", that.durationHandler); //监听当前播放总时长
                that.ckPlayer.addListener("buffer", that.bufferHandler); //监听缓冲状态
                that.ckPlayer.addListener("time", that.playerTimes); //监听播放时间
                that.ckPlayer.addListener("play", that.playHasPlay); //监听视频播放
                that.ckPlayer.addListener("pause", that.playHasPause); //监听视频暂停
                that.ckPlayer.addListener("ended", that.playHasEnd); //监听播放结束
                // that.ckPlayer.addListener("playauto", that.playHasAuto); //监听自动播放切换
                that.ckPlayer.addListener("screenshot", that.playerScreenshot); //监听截图功能
                that.ckPlayer.addListener("playbackRate", that.playbackRateUpdate); //监听播放倍速
                // this.ckPlayer.addListener("definitionChange", definitionChangeHandler); //监听清晰度切换事件
                that.ckPlayer.addListener("loadTime", that.loadTimeHandler); //监听加载速度
                if (that.allowDrag) {
                  that.ckPlayer.changeConfig("config", "timeScheduleAdjust", 1);
                } else {
                  that.ckPlayer.changeConfig("config", "timeScheduleAdjust", Number(that.is_drag));
                }
                if (that.hint) {
                  that.remindPopup();
                }
              };
              window.nextCourse = function () {
                that.$emit("nextCourse");
              };
            })
            .catch(() => {
              console.log("err");
            });
        }
      } else {
        if (this.isDesign) {
          this.playerParams.video = "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4";
          this.ckPlayer = new ckplayer(this.playerParams);
        } else {
          console.log("播放地址为空");
        }
      }
    },
    changeVideo() {
      let { src, type } = this.videoSrc();
      let player = this.ckPlayer;
      if (player) {
        const newParam = {
          container: "#dsfNcCkplayer",
          variable: "player",
          autoplay: this.is_auto,
          seek: this.getSeektime || 0,
          playbackrate: this.is_speed ? 1 : false,
          next: "nextCourse",
          video: src,
        };
        if (player.playerType == "html5video") {
          if (type == "video/m3u8") {
            player.removeChild();
            player = null;
            player = new ckplayer();
            player.embed(newParam);
          } else {
            player.newVideo(newParam);
          }
        }
      }
    },
    durationHandler(e) {
      this.$emit("playerDurationHandler", e);
    },
    bufferHandler(e) {
      this.$emit("playerBufferHandler", e);
    },
    loadTimeHandler(e) {
      this.$emit("playerLoadTimeHandler", e);
    },
    audioPause() {
      this.audioPlay && this.audio.pause();
    },
    playerTimes(e) {
      // this.setCurrentPlayTime(e);
      this.$emit("playerPlayingTime", e);
    },
    playHasPlay() {
      this.playIng = true;
      this.$emit("playerHasPlay");
    },
    playHasPause() {
      this.playIng = false;
      this.$emit("playerHasPause");
    },
    playHasEnd() {
      let endAction = this.end_action; //1 当前视频重播 2视频列表循环 3暂停
      if (endAction == 3) {
        this.playerPause();
      }
      this.$emit("playHasEnd", endAction);
    },
    playerPause() {
      this.ckPlayer && this.ckPlayer.videoPause();
    },
    playerPlay() {
      this.ckPlayer && this.ckPlayer.videoPlay();
    },
    exitFull() {
      this.ckPlayer && this.ckPlayer.quitFullScreen();
    },
    playbackRateUpdate(it) {
      this.$emit("playbackRateUpdate", it[0]);
    },
    playerScreenshot(d) {
      if (d) {
        if (this.uploadScreenshot) {
          this.uploadScreenshot(d.base64);
        } else {
          this.$emit("playerScreenshot", d);
        }
      }
    },
    playerEmitScrrenshot() {
      this.ckPlayer && this.ckPlayer.screenshot("video", true, "视频截图");
    },
    playerLoadError() {
      if (!this.videoSource) {
        dsf.layer.pc.message("当前播放地址为空", false);
      }
    },
    remindPopup() {
      let that = this,
        nowDate = 0,
        time = window.$$serverConfig?.setting_nc_player_remind_time || 5,
        hintTime = typeof Number(time) === "number" && time > 0 ? Number(time) : 5;
      document.onmousemove = function () {
        nowDate = new Date().getTime();
      };
      function setSeePlayerRemind() {
        nowDate = new Date().getTime();
        clearInterval(that.remindTimer);
        that.remindTimer = setInterval(function () {
          if (new Date().getTime() - nowDate > hintTime * 60 * 1000 && !that.hasShowRemind && that.playIng) {
            that.playHasPause();
            dsf.layer
              .confirm({
                message: "您已学习" + hintTime + "分钟了,是否继续学习？",
              })
              .then(function () {
                that.playerPlay();
                that.hasShowRemind = false;
                setSeePlayerRemind();
              })
              .catch(function () {
                dsf.layer.closeWindow();
              });
            that.playerPause();
            that.hasShowRemind = true;
            clearInterval(that.remindTimer);
          }
        }, 1000);
      }
      if (time && time > 0) {
        setSeePlayerRemind();
      }
    },
    initWaterMark() {
      let it = new Promise((resolve, reject) => {
        if (window.$$serverConfig && dsf.getToken()) {
          const { setting_nc_watermark_video_text_open: text_open, setting_nc_watermark_video_text, setting_public_system_name, setting_nc_watermark_video_img: water_img } = window.$$serverConfig;

          let base64 = "";
          const text = setting_nc_watermark_video_text
            ? setting_nc_watermark_video_text
            : setting_public_system_name
            ? `${setting_public_system_name}-${dsf.getCookie("user_name")}`
            : dsf.getCookie("user_name");
          if (text_open == 1) {
            let _c = document.createElement("canvas");
            if (water_img) {
              _c.width = text.length * 20 + 53; //图片宽度+左边距
              _c.height = 48;
              var ctx = _c.getContext("2d");
              // ctx.rect(0, 0, _c.width, _c.height);
              // ctx.fill();
              let _wk = new Image();
              _wk.src = water_img;
              _wk.setAttribute("crossOrigin", "Anonymous");
              _wk.style.width = "48px";
              _wk.style.height = "48px";
              _wk.onload = () => {
                ctx.drawImage(_wk, 0, 0, 48, 48);
                ctx.font = "normal bold 20px sans-serif";
                ctx.fillStyle = "rgba(255,255,255,.5)";
                ctx.fillText(text, 53, 32);
                base64 = _c.toDataURL("image/png");
                resolve(base64);
              };
              _wk.onerror = () => {
                resolve("");
              };
            } else {
              _c.width = text.length * 20;
              _c.height = 48;
              var ctx = _c.getContext("2d");
              ctx.fillStyle = "rgba(255,255,255,.2)";
              ctx.font = "normal bold 20px sans-serif";
              ctx.fillText(text, 0, 32);
              base64 = _c.toDataURL("image/png");
              resolve(base64);
            }
          } else {
            if (water_img) {
              resolve(water_img);
            } else {
              resolve("");
            }
          }
        } else {
          resolve("");
        }
      });
      return it;
    },
    initPlayerTabStop() {
      let that = this;
      let hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      function handleVisibilityChange() {
        if (document[hidden]) {
          that.ckPlayer && that.ckPlayer.videoPause();
          that.audioPlay && that.audio.pause();
        }
      }
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    },
    getCookie(name) {
      var strcookie = document.cookie;
      var arrcookie = strcookie.split("; ");
      for (var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split("=");
        if (arr[0] == name) {
          var val = decodeURI(arr[1]);
          return val;
        }
      }
      return "";
    },
    ...mapActions(["uploadScreenshot"]),
  },
  beforeDestroy() {
    if (this.remindTimer) clearInterval(this.remindTimer);
    if (this.ckPlayer) this.ckPlayer.V = null;
  },
  watch: {
    videoSource(n, o) {
      if (n) {
        this.$nextTick(()=>{
          this.videoSource = n;
          this.ckPlayer = null;
          this.initCoursePlayer();
        })
      }
    },
    videoStatus(n, o) {
      if (n) {
        this.initAudio();
        this.audioPlay = true;
        this.is_auto = true;
      }
    },
  },
});
</script>
