var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "dsf_specialdetail_intro_common",
          style: { width: _vm.itemWidth, margin: _vm.controlStyle },
        },
        [
          _vm.showTitle
            ? _c("div", { staticClass: "intro_title" }, [
                _c("div", { staticClass: "desc" }, [
                  _c(
                    "span",
                    {
                      staticClass: "font",
                      style: "font-weight: " + (_vm.bolder ? "bold" : "normal"),
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm.showMore && _vm.moreStyle == "tb"
                    ? _c("span", {
                        staticClass: "more",
                        on: {
                          click: function ($event) {
                            !!_vm.showMore && _vm.clickMore()
                          },
                        },
                      })
                    : _vm.showMore && _vm.moreStyle == "wz"
                    ? _c(
                        "span",
                        {
                          staticClass: "more_font",
                          on: {
                            click: function ($event) {
                              !!_vm.showMore && _vm.clickMore()
                            },
                          },
                        },
                        [_vm._v("更多")]
                      )
                    : _vm._e(),
                ]),
                _vm.showLine ? _c("span", { staticClass: "line" }) : _vm._e(),
              ])
            : _vm._e(),
          _vm.dataSource == "json" || _vm.isDesign
            ? _c(
                "ul",
                { staticClass: "common_wrap" },
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "common_items",
                      style: _vm.itemStyle,
                      on: {
                        click: function ($event) {
                          return _vm.clickItem(item)
                        },
                      },
                    },
                    [
                      _c("i", { class: "icon iconfont " + item.icon }),
                      _c("span", {
                        staticClass: "picture",
                        style: "background-image: url(" + item.picture + ")",
                      }),
                      _c("div", { staticClass: "font" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.dataSource == "http"
            ? _c(
                "ul",
                { staticClass: "common_wrap" },
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "common_items",
                      style: _vm.itemStyle,
                      on: {
                        click: function ($event) {
                          return _vm.clickItem(item)
                        },
                      },
                    },
                    [
                      _c("i", { class: "icon iconfont " + item.icon }),
                      _c("div", { staticClass: "font" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }