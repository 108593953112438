var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds_hotlist", style: _vm.controlStyle }, [
    _c("div", { staticClass: "ds_nc_deftitle" }, [
      _c(
        "div",
        { staticClass: "def-title" },
        [
          _c("div", { staticClass: "leftcon" }, [
            _c("i"),
            _c("span", [_vm._v(_vm._s(_vm.title))]),
          ]),
          _vm.moreurl
            ? [
                _c(
                  "div",
                  { staticClass: "rightbtn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.morebtn },
                      },
                      [
                        _vm._v(" 更多 "),
                        _c("i", {
                          staticClass: "el-icon-arrow-right el-icon--right",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm.hotList.length
      ? _c(
          "div",
          { staticClass: "content" },
          _vm._l(_vm.hotList, function (v, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "hotlist-content course-left",
                style: _vm.rowStyle,
                on: {
                  click: function ($event) {
                    return _vm.goDetail(_vm.item)
                  },
                },
              },
              [
                _c("span", { staticClass: "elps ell" }, [
                  _c("span", { class: "num " + "num" + (index + 1) }, [
                    _vm._v(_vm._s(index + 1)),
                  ]),
                  _vm._v(" " + _vm._s(v[_vm.keyField.title] || "") + " "),
                ]),
                _c("span", [_vm._v(_vm._s(v[_vm.keyField.num] || 0))]),
                _c("p", { class: "bg " + "bg" + (index + 1) }),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "noDate" }, [_vm._v("暂无数据")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }