var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_userInfoPage" }, [
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("userInfo", {
              attrs: {
                keysMap: _vm.keysMap,
                showHeader: _vm.showHeader,
                moreUrl: _vm.infoMoreUrl,
                resData: _vm.resData,
                width: _vm.width,
                height: _vm.height,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("userHeader", {
              attrs: {
                imageUrl: _vm.imageUrl && _vm.resData[_vm.imageUrl],
                showHeader: _vm.showHeader,
                headerMoreUrl: _vm.headerMoreUrl,
                width: _vm.width,
                height: _vm.height,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("userAccount", {
              attrs: {
                keysMap: _vm.keysMap2,
                showHeader: _vm.showHeader,
                moreUrl: _vm.accountMoreUrl,
                changePwdUrl: _vm.changePwdUrl,
                resData: _vm.resData,
                width: _vm.width,
                height: _vm.height,
                pageType: _vm.pageType,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("userInte", {
              attrs: {
                keysMap: _vm.keysMap4,
                showHeader: _vm.showHeader,
                resData: _vm.resData,
                width: _vm.width,
                height: _vm.height,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }