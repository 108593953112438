var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_saas saas_setting_footer_model1" },
    [
      _c("div", { staticClass: "subtitle margint20 marginb20" }, [
        _vm._v("效果预览"),
      ]),
      _c("footerOne", {
        attrs: { itemWidth: "100%", margin: "9px 0 50px 0", data: _vm.data },
      }),
      _c("div", { staticClass: "setting" }, [
        _c("div", [
          _c("div", [
            _c("div", { staticClass: "subtitle margint20 marginb20" }, [
              _vm._v("基本信息配置"),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("label", [_vm._v("座机")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入座机" },
                    model: {
                      value: _vm.data.companyPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "companyPhone", $$v)
                      },
                      expression: "data.companyPhone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("label", [_vm._v("公司地址")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入公司地址" },
                    model: {
                      value: _vm.data.addressOne,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "addressOne", $$v)
                      },
                      expression: "data.addressOne",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c("div", { staticClass: "subtitle margint20 marginb20" }, [
                _vm._v("数据配置"),
              ]),
              _vm._l(_vm.sourceList, function (source, i) {
                return _c("div", { key: i, staticClass: "line" }, [
                  _c("label", [_vm._v(_vm._s(source.name))]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "请选择显示数据",
                          },
                          model: {
                            value: _vm.data.dataSource[i],
                            callback: function ($$v) {
                              _vm.$set(_vm.data.dataSource, i, $$v)
                            },
                            expression: "data.dataSource[i]",
                          },
                        },
                        _vm._l(source.selection, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
        ]),
        _c("div", [
          _c("div", { staticClass: "subtitle margint20 marginb20" }, [
            _vm._v("底部信息配置"),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("备案号")]),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "请输入备案号" },
                  model: {
                    value: _vm.data.internetNumOne,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "internetNumOne", $$v)
                    },
                    expression: "data.internetNumOne",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("公安备案图标")]),
            _c(
              "div",
              { staticClass: "upload" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                      "before-upload": _vm.beforeAvatarUpload,
                    },
                  },
                  [
                    _vm.data.policeInternetImgOne
                      ? _c("DsfImage", {
                          staticClass: "avatar",
                          attrs: {
                            src: JSON.parse(
                              _vm.data.policeInternetImgOne || "{}"
                            ).relativePath,
                          },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ],
                  1
                ),
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                      "before-upload": _vm.beforeAvatarUpload,
                    },
                  },
                  [
                    _c("dsf-button", { attrs: { size: "small" } }, [
                      _vm._v("上传"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "dsf-button",
                  {
                    attrs: { size: "small", type: "plain" },
                    on: { click: _vm.clearIcon },
                  },
                  [_vm._v("删除")]
                ),
                _c("dsf-nc-crop-image", {
                  attrs: {
                    "show-tips-text": true,
                    resize: false,
                    "edit-img": _vm.editImg,
                    "edit-file-img": _vm.editFileImg,
                    "aspect-ratio": _vm.aspectRatio,
                    "request-url": _vm.setRequestUrl,
                  },
                  on: { saveFile: _vm.editFile, handleClose: _vm.handleClose },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("公安备案号")]),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "请输入公安备案号" },
                  model: {
                    value: _vm.data.policeInternetNumOne,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "policeInternetNumOne", $$v)
                    },
                    expression: "data.policeInternetNumOne",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("等级备案号")]),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "请输入等级备案号" },
                  model: {
                    value: _vm.data.levelInternetNumOne,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "levelInternetNumOne", $$v)
                    },
                    expression: "data.levelInternetNumOne",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "footer_btn" },
        [
          _c(
            "dsf-button",
            { attrs: { type: "plain" }, on: { click: _vm.backPage } },
            [_vm._v("取消")]
          ),
          _c(
            "dsf-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveModel } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }