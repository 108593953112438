<template>
  <div class="text-login">
    <div class="login-box-info margin-top">
      <dsf-icon name="icon_zhanghao" />
      <input type="text" :placeholder="nameHolder" v-model="loginForm.username" autocomplete="new-password" />
    </div>
    <div class="login-box-info">
      <dsf-icon name="suozi" />
      <input type="password" :placeholder="pwdHolder" v-model="loginForm.password" autocomplete="new-password" v-if="!showPwd" />
      <input type="text" :placeholder="pwdHolder" v-model="loginForm.password" autocomplete="new-password" v-else />
      <dsf-icon name="biyan" class="show-pwd" @click="changePwdType" v-if="!showPwd" />
      <dsf-icon name="yanjing1" class="show-pwd" @click="changePwdType" v-else />
    </div>
    <div class="login-box-info login-code">
      <div class="login-code-wrap">
        <dsf-icon name="yanzhengma1" />
        <input type="text" :placeholder="codeHolder" v-model="loginForm.code" />
      </div>
      <img v-if="imgCode" :src="imgCode" alt="" class="img-code" @click="getImgCode()" />
    </div>
    <div class="login-box-handle">
      <div>
        <el-checkbox v-model="loginForm.checked" @change="setRemindState">记住密码</el-checkbox>
      </div>
      <div @click="toChangePwd">忘记密码</div>
      <div @click="toRegister" v-if="dsf.config.setting_nc_reg_is_register == 1">
        <div class="hint">
          没有账号？
          <span class="pointer">去注册</span>
        </div>
      </div>
    </div>
    <div class="login-box-button">
      <el-button :loading="loading" type="info" native-type="submit" size="small" @click="loginHandle">登 录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sourceUrl: {
      type: String,
      default: "/nc/login/login",
    },
    nameHolder: {
      type: String,
      default: "请输入账号",
    },
    pwdHolder: {
      type: String,
      default: "请输入密码",
    },
    codeHolder: {
      type: String,
      default: "动态验证码",
    },
    toUrl: {
      type: String,
      default: "",
    },
    loginSuccess: {
      type: Function,
      required: false,
    },
    loginFail: {
      type: Function,
      required: false,
    },
    isreloadCurrentPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        code: "",
        checked: Boolean(localStorage.getItem("isRemind")) || false,
      },
      tabPosition: "top",
      rules: {
        username: [{ required: true, message: "请输入您的账号", trigger: "blur" }],
        password: [{ required: true, message: "密码长度必须大于6位", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      loading: false,
      imgCode: null,
      userMsg: localStorage.getItem("remindUserMsg") ? JSON.parse(localStorage.getItem("remindUserMsg")) : null,
      checkPwd: true,
      showPwd: false,
      loginHandle: null,
    };
  },
  components: {},
  computed: {},
  created() {
    if (this.userMsg && this.loginForm.checked) {
      const { userName, password } = this.userMsg;
      this.loginForm.username = userName;
      this.loginForm.password = password;
      this.checkPwd = false;
    }
  },
  mounted() {
    this.getImgCode();
    this.loginHandle = _.throttle(this.submitTextLogin, 1000);
  },
  methods: {
    changePwdType() {
      this.showPwd = !this.showPwd;
    },
    getImgCode() {
      this.imgCode = dsf.url.getWebPath("code/image?t=" + new Date().getTime());
    },
    submit() {
      this.loginHandle();
    },
    submitTextLogin() {
      if (this.loading) return;
      const { username, password, code, checked } = this.loginForm;
      if (username === "") {
        dsf.layer.message("账号不能为空！", false);
        return;
      }
      if (password === "") {
        dsf.layer.message("密码不能为空！", false);
        return;
      }
      if (code === "") {
        this.getImgCode();
        dsf.layer.message("验证码不能为空！", false);
        return;
      }
      if (this.loading) return;
      this.loading = true;
      let params = {
        userName: username,
        password: dsf.md5(dsf.md5(password)),
        code: code,
        typeValue: 1,
      };
      dsf.http
        .post(this.sourceUrl, params, $$webRoot.nc)
        .done(({ message, data, success }) => {
          if (success) {
            const toPath = data.index_url;
            const curPath = location.hash;
            dsf.layer.pc.message(message, true);
            dsf.cookies.set("isLogin", 1);
            this.loginSuccess?.();
            localStorage.setItem("remindUserMsg", checked ? JSON.stringify({ userName: username, password: password }) : "");
            let timer = setTimeout(() => {
              if (this.isreloadCurrentPage) {
                window.location.reload();
              } else if (toPath && !toPath.includes(curPath)) {
                window.location.href = toPath;
              } else {
                window.location.reload();
              }
              clearTimeout(timer);
              this.loading = false;
            }, 1000);
          } else {
            this.getImgCode();
            this.loading = false;
            dsf.layer.pc.message(message, false);
          }
        })
        .error(err => {
          this.loginFail?.();
          this.getImgCode();
          this.loading = false;

          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {});
      return false;
    },
    setRemindState() {
      localStorage.setItem("isRemind", this.loginForm.checked);
    },
    toChangePwd() {
      this.$emit("changePassword");
    },
    toRegister() {
      this.$emit("toRegister");
    },
  },
};
</script>
