<template>
  <div class="dsf_nc_ckplayer" v-if="!isDesign">
    <div class="ckplayer-inner" id="_inner" :style="{ width: width, height: v_height }">
      <div style="height: 100%">
        <div id="_player" ref="ckplayer" style="height: 100%"></div>
      </div>
      <!-- <div class="audio" v-else>
        <audio controls :src="videoSrc().src" id="_playerAudio" controlsList="nodownload">
          <p>
            Your browser does not support the
            <code>audio</code>
            element.
          </p>
        </audio>
      </div> -->
    </div>
    <!-- <div class="audio-cover-wrap" v-if="videoSrc().type === 'audio/mp3'" :class="audioPlay ? 'audio-play' : 'audio-paused'">
      <dsf-nc-image :src="itemCover" v-if="itemCover" class="audio-cover" />
    </div> -->
  </div>
  <div class="dsf_nc_ckplayer" v-else>
    <div class="ckplayer-inner" :style="{ width: width, height: v_width }">
      <div style="height: 100%">
        <div id="_player" ref="ckplayer" style="height: 100%"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcCoursewarePlayer",
  ctrlCaption: "课件播放器",
  mixins: [$mixins.control],
  props: {
    v_width: {
      type: String,
      default: "100%",
    },
    v_height: {
      type: String,
      default: window.innerHeight + "px",
    },
    videoSource: {
      type: [String, Array],
      default: "",
    },
    showBackrate: {
      type: [String, Number],
      default: 1,
      desc: "是否显示倍数",
    },
    is_auto: {
      type: Boolean,
      default: false,
      desc: "是否自动播放",
    },
    is_speed: {
      type: Boolean,
      default: true,
      desc: "是否显示倍数播放",
    },
    is_hd: {
      type: Boolean,
      default: false,
      desc: "是否显示清晰度切换",
    },
    is_allow_drag: {
      type: Boolean,
      default: false,
      desc: "课件完成度100%允许拖拽",
    },
    /***
     *  ckplayer: config 是否允许拖拽
     *  0:不能拖拽
     *  1:可以拖拽
     *  2:只能向右拖拽
     *  3:只能后退
     *  4:能回到第一次拖动时的位置
     *  5:看过的地方可以随意拖动
     */
    is_drag: {
      type: [String, Number],
      default: 0,
    },
    allowDrag: {
      type: Boolean,
      default: false,
      desc: "是否允许拖拽,组件传入状态",
    },
    ctrl_tab_stop: {
      type: Boolean,
      default: false,
      desc: "是否允许切换窗播放",
    },
    end_action: {
      type: String,
      default: "1",
      desc: "播放结束是否循环",
    },
    hint: {
      type: Boolean,
      default: true,
      desc: "是否允许分钟提示暂停",
    },
    hintMins: {
      type: Number,
      default: 5,
    },
    saveTime: {
      type: Number,
      default: 10,
    },
    cover: {
      type: [String, Object],
      default() {
        return {};
      },
      desc: "封面图片",
    },
    sourceUrl: {
      type: String,
      default: "nc/course/play/getPlayRecord",
    },
    saveSourceUrl: {
      type: String,
      default: "nc/course/play/pulseSaveRecord",
    },
    playerParams: {
      type: Object,
      default: () => {
        return {
          playerID: "_ncplayer",
          container: "#_player",
          variable: "player",
          html5m3u8: true,
          loaded: "playerLoaded",
          // loop: true,
          autoplay: true,
          sortPlay: false,
          next: "nextCourse",
          defaultPoster: "", //封面图片
          playbackrate: 1,
          drag: "start",
          debug: false,
          hideautoplayBtn: true,
        };
      },
    },
  },
  data() {
    return {
      ckplayer: null,
      hasShowRemind: false,
      playIng: false,

      videoInfo: null,
      showPlayer: false,
      playRate: 1, //播放倍数
      playTime: 0,
      saveTimer: null,
      id: this.$route.query.id,
    };
  },
  computed: {
    curColor() {
      return this.$root?.currentTheme?.normal || "#fc7001";
    },
    itemCover() {
      let cover = dsf.config.setting_nc_image_default_img;
      if (this.cover) {
        let v = this.cover;
        if (dsf.isUnDef(v) || v == "") {
          return "";
        }
        let obj = v;
        if (dsf.type(obj) == "string") {
          if (obj.indexOf("relativePath") == -1 || obj.indexOf("absolutePath") == -1) {
            return v;
          }
          return dsf.getFilePath(obj);
        }
        return "";
      }
      return cover;
    },
  },
  mounted() {
    if (this.isDesign) {
      this.init(this.initDefaultPlayer);
    } else {
      this.init(this.initPlayer);
    }
  },
  methods: {
    init(callback) {
      // if (window.$$serverConfig) {
      //   let {
      //     setting_nc_player_is_auto, //是否自动播放
      //     setting_nc_player_is_speed, //是否显示倍数
      //     setting_nc_player_is_drag, //是否允许拖拽
      //     setting_nc_player_ctrl_tab_stop, //切换窗口是否暂停视频
      //     setting_nc_player_end_action, //播放结束是否循环
      //     setting_nc_player_is_hd, //是否显示清晰度切换
      //   } = window.$$serverConfig;
      //   this.is_auto = Boolean(setting_nc_player_is_auto);
      //   this.is_speed = Boolean(setting_nc_player_is_speed);
      //   this.is_drag = Boolean(setting_nc_player_is_drag); //当前播放视频节点已经完成的视频限制??
      //   this.is_hd = Boolean(setting_nc_player_is_hd);
      //   this.ctrl_tab_stop = Boolean(setting_nc_player_ctrl_tab_stop);
      //   this.end_action = setting_nc_player_end_action;
      // }
      // this.end_action == 1 && (this.playerParams.loop = true);
      // this.playerParams.playbackrate = this.is_speed ? 1 : false;
      // this.playerParams.autoplay = this.is_auto;
      // this.ctrl_tab_stop && this.initPlayerTabStop();
      // if (this.is_allow_drag) this.is_drag = this.allowDrag;
      if (window.ckplayer) {
        callback?.();
      } else {
        this.$http
          .importFiles([
            [dsf.url.getWebPath("$/js/libs/ckplayer/ckPlayer.js"), ".js"],
            [dsf.url.getWebPath("$/js/libs/ckplayer/hls/hls.min.js"), ".js"],
          ])
          .then(() => {
            callback?.();
          })
          .catch(() => {
            dsf.layer.message("加载ckplayer文件报错", false);
          });
      }
    },
    loadPlayInfo(id) {
      return new Promise((resolve, reject) => {
        const loading = dsf.layer.pc.loading();
        let params = {
          coursewareId: id,
        };
        dsf.http
          .get(this.sourceUrl, params, $$webRoot.nc)
          .done(({ data, success, message }) => {
            if (success) {
              resolve(data);
              this.$emit("videoInfo", data);
            } else {
              dsf.layer.message(message || "请求异常", false);
              reject(data);
            }
          })
          .error(err => {
            error(err);
          })
          .always(res => {
            dsf.layer.pc.closeLoading(loading);
          });
        function error(err) {
          dsf.layer.message("请求异常", false);
          reject(err);
        }
      });
    },
    updateProgress(state) {
      let _this = this;
      const time = state ? _this.saveTime * 1000 : 50;
      if (_this.saveTimer) clearTimeout(_this.saveTimer);
      function setTimer() {
        _this.saveTimer = setTimeout(() => {
          let params = {
            coursewareId: _this.id,
            watchPoint: numberTotime(_this.playTime),
            pulseTime: _this.saveTime,
            pulseRate: _this.playRate,
            type: "courseware",
          };
          dsf.http
            .post(_this.saveSourceUrl, params, $$webRoot.nc)
            .done(({ data, success }) => {
              if (state && success) {
                _this.updateProgress(true);
              }
              _this.$emit("updateProgress", data);
            })
            .error(err => {
              error(err);
            })
            .always(res => {});
          function error(err) {
            dsf.layer.pc.message(err.message, false);
          }
        }, time);
      }

      setTimer();
    },
    async initPlayer() {
      let id = this.id;
      if (!id) return dsf.layer.pc.message("课件id不能为空", false);
      let videoInfo = await this.loadPlayInfo(id);
      if (videoInfo) {
        let { fileUrl, lastWatchPoint } = videoInfo;
        let params = {
          video: resetVideoUrl(fileUrl, this.is_hd),
          seek: resetSeektime(lastWatchPoint),
          themeColor: this.curColor,
          loop: this.end_action == 1,
          playbackrate: this.is_speed ? 1 : false,
          autoplay: this.is_auto,
        };
        const _vp = Object.assign(this.playerParams, params);
        this.showPlayer = true;
        this.ckplayer = new ckplayer(_vp);
        !this.ctrl_tab_stop && this.initPlayerTabStop();
        window.playerLoaded = () => {
          this.ckplayer.addListener("error", this.playerLoadError); //监听视频加载出错
          this.ckplayer.addListener("duration", this.durationHandler); //监听当前播放总时长
          this.ckplayer.addListener("buffer", this.bufferHandler); //监听缓冲状态
          this.ckplayer.addListener("time", this.playerTimes); //监听播放时间
          this.ckplayer.addListener("play", this.playHasPlay); //监听视频播放
          this.ckplayer.addListener("pause", this.playHasPause); //监听视频暂停
          this.ckplayer.addListener("ended", this.playHasEnd); //监听播放结束
          this.ckplayer.addListener("screenshot", this.playerScreenshot); //监听截图功能
          this.ckplayer.addListener("playbackRate", this.playbackRateUpdate); //监听播放倍速
          // this.ckplayer.addListener("definitionChange", definitionChangeHandler); //监听清晰度切换事件
          this.ckplayer.addListener("loadTime", this.loadTimeHandler); //监听加载速度
          this.ckplayer.changeConfig("config", "timeScheduleAdjust", Number(this.is_drag));
          if (this.hint) {
            this.remindPopup();
          }
        };
        window.nextCourse = () => {
          this.$emit("nextCourse");
        };
      } else {
        console.log("播放地址为空");
      }
    },
    initDefaultPlayer() {
      this.playerParams.video = "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4";
      this.ckplayer = new ckplayer(this.playerParams);
    },
    changeVideo(src) {
      let player = this.ckplayer;
      if (player) {
        const newParam = {
          container: "#_player",
          variable: "player",
          autoplay: this.is_auto,
          seek: 0,
          playbackrate: this.is_speed ? 1 : false,
          next: "nextCourse",
          video: src,
        };
        if (player.playerType == "html5video") {
          if (type == "video/m3u8") {
            player.removeChild();
            player = null;
            player = new ckplayer();
            player.embed(newParam);
          } else {
            player.newVideo(newParam);
          }
        }
      }
    },
    durationHandler(e) {
      this.$emit("playerDurationHandler", e);
    },
    bufferHandler(e) {
      this.$emit("playerBufferHandler", e);
    },
    loadTimeHandler(e) {
      this.$emit("playerLoadTimeHandler", e);
    },
    audioPause() {
      this.audioPlay && this.audio.pause();
    },
    playerTimes(e) {
      // this.setCurrentPlayTime(e);
      this.playTime = e;
      this.$emit("playerPlayingTime", e);
    },
    playHasPlay() {
      this.playIng = true;
      this.updateProgress(true);
      this.$emit("playerHasPlay");
    },
    playHasPause() {
      this.playIng = false;
      this.updateProgress(false);
      this.$emit("playerHasPause");
    },
    playHasEnd() {
      let endAction = this.end_action; //1 当前视频重播 2视频列表循环 3暂停
      if (endAction == 3) {
        this.playerPause();
      }
      this.$emit("playHasEnd", endAction);
      this.updateProgress(false);
    },
    playerPause() {
      this.ckplayer && this.ckplayer.videoPause();
    },
    playerPlay() {
      this.ckplayer && this.ckplayer.videoPlay();
    },
    playbackRateUpdate(it) {
      this.playRate = it[0];
      this.$emit("playbackRateUpdate", it[0]);
    },
    playerScreenshot(d) {
      if (d) {
        this.$emit("playerScreenshot", d);
      }
    },
    playerEmitScrrenshot() {
      this.ckplayer && this.ckplayer.screenshot("video", true, "视频截图");
    },
    playerLoadError() {
      if (!this.videoSource) {
        dsf.layer.pc.message("当前播放地址为空", false);
      }
    },
    remindPopup() {
      let that = this,
        nowDate = 0,
        remindTimer = null,
        hintTime = this.hintMins;
      document.onmousemove = function () {
        nowDate = new Date().getTime();
      };
      function setSeePlayerRemind() {
        nowDate = new Date().getTime();
        remindTimer = setInterval(function () {
          if (new Date().getTime() - nowDate > hintTime * 60 * 1000 && !that.hasShowRemind && that.playIng) {
            dsf.layer
              .confirm({
                message: "您已学习" + hintTime + "分钟了,是否继续学习？",
              })
              .then(function () {
                that.playerPlay();
                that.hasShowRemind = false;
                setSeePlayerRemind();
              })
              .catch(function () {
                dsf.layer.closeWindow();
              });
            that.playerPause();
            that.hasShowRemind = true;
            if (remindTimer) clearInterval(remindTimer);
          }
        }, 1000);
      }
      setSeePlayerRemind();
    },
    // initWaterMark() {
    //   let it = new Promise((resolve, reject) => {
    //     if (window.$$serverConfig && dsf.getToken()) {
    //       const {
    //         setting_nc_watermark_video_text_open: text_open,
    //         setting_nc_watermark_video_text,
    //         setting_public_system_name,
    //         setting_nc_watermark_video_img: water_img,
    //       } = window.$$serverConfig;

    //       let base64 = "";
    //       const text = setting_nc_watermark_video_text
    //         ? setting_nc_watermark_video_text
    //         : setting_public_system_name
    //         ? `${setting_public_system_name}-${dsf.getCookie("user_name")}`
    //         : dsf.getCookie("user_name");
    //       if (text_open == 1) {
    //         let _c = document.createElement("canvas");
    //         if (water_img) {
    //           _c.width = text.length * 20 + 53; //图片宽度+左边距
    //           _c.height = 48;
    //           var ctx = _c.getContext("2d");
    //           // ctx.rect(0, 0, _c.width, _c.height);
    //           // ctx.fill();
    //           let _wk = new Image();
    //           _wk.src = water_img;
    //           _wk.setAttribute("crossOrigin", "Anonymous");
    //           _wk.style.width = "48px";
    //           _wk.style.height = "48px";
    //           _wk.onload = () => {
    //             ctx.drawImage(_wk, 0, 0, 48, 48);
    //             ctx.font = "normal bold 20px sans-serif";
    //             ctx.fillStyle = "rgba(255,255,255,.5)";
    //             ctx.fillText(text, 53, 32);
    //             base64 = _c.toDataURL("image/png");
    //             resolve(base64);
    //           };
    //           _wk.onerror = () => {
    //             resolve("");
    //           };
    //         } else {
    //           _c.width = text.length * 20;
    //           _c.height = 48;
    //           var ctx = _c.getContext("2d");
    //           ctx.fillStyle = "rgba(255,255,255,.2)";
    //           ctx.font = "normal bold 20px sans-serif";
    //           ctx.fillText(text, 0, 32);
    //           base64 = _c.toDataURL("image/png");
    //           resolve(base64);
    //         }
    //       } else {
    //         if (water_img) {
    //           resolve(water_img);
    //         } else {
    //           resolve("");
    //         }
    //       }
    //     } else {
    //       resolve("");
    //     }
    //   });
    //   return it;
    // },
    initPlayerTabStop() {
      let that = this;
      let hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      function handleVisibilityChange() {
        if (document[hidden]) {
          that.ckplayer && that.ckplayer.videoPause();
          that.audioPlay && that.audio.pause();
        }
      }
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    },
  },
});

function resetVideoUrl(it, isHd) {
  let url = null;
  if (it) {
    const d = JSON.parse(it);
    if (isHd) {
      url = [
        [d.cd.relative, "video/m3u8", "清晰", 0],
        [d.hd.relative, "video/m3u8", "标清", 1],
        [d.sd.relative, "video/m3u8", "超清", 2],
      ];
    } else {
      url = d.origin.relative;
    }
  }
  return url;
}
function numberTotime(t) {
  let _t = parseInt(t),
    _d = dsf.date.secondsToTime(_t),
    time = _d.hours + ":" + _d.minutes + ":" + _d.seconds;
  return time;
}
function resetSeektime(t) {
  if (!t) t = "00:00:00";
  t = t.split(":");
  t = t[0] * 60 * 60 + t[1] * 60 + t[2] * 1;
  return t;
}
</script>
