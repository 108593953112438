var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_saas saas_setting_footer_model3" },
    [
      _c("div", { staticClass: "subtitle margint20 marginb20" }, [
        _vm._v("效果预览"),
      ]),
      _c("footerThree", {
        attrs: { itemWidth: "100%", margin: "9px 0 50px 0", data: _vm.data },
      }),
      _c("div", { staticClass: "subtitle margint20 marginb20" }, [
        _vm._v("底部链接"),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data.footUrls,
            "row-style": { height: "60px" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "名称",
              width: "200",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.active
                      ? _c("el-input", {
                          attrs: { type: "text" },
                          model: {
                            value: scope.row.name,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "name", $$v)
                            },
                            expression: "scope.row.name",
                          },
                        })
                      : _c("span", [_vm._v(_vm._s(scope.row.name))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "url", label: "链接", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.active
                      ? _c("el-input", {
                          attrs: { type: "text" },
                          model: {
                            value: scope.row.url,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "url", $$v)
                            },
                            expression: "scope.row.url",
                          },
                        })
                      : _c("span", [_vm._v(_vm._s(scope.row.url))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "tablebtns" },
                      [
                        _c("dsf-icon", {
                          attrs: { name: "tianxie" },
                          on: {
                            click: function ($event) {
                              return _vm.toEdit(scope)
                            },
                          },
                        }),
                        _c("dsf-icon", {
                          attrs: { name: "delete" },
                          on: {
                            click: function ($event) {
                              return _vm.toDetle(scope)
                            },
                          },
                        }),
                        _c("dsf-icon", {
                          attrs: { name: "shang" },
                          on: {
                            click: function ($event) {
                              return _vm.toUp(scope)
                            },
                          },
                        }),
                        _c("dsf-icon", {
                          attrs: { name: "xia" },
                          on: {
                            click: function ($event) {
                              return _vm.toDown(scope)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "add_nav", on: { click: _vm.addNav } },
        [
          _c("dsf-icon", { attrs: { name: "jia" } }),
          _vm._v(" 新增导航栏链接 "),
        ],
        1
      ),
      _c("div", { staticClass: "setting" }, [
        _c("div", [
          _c("div", { staticClass: "subtitle margint20 marginb20" }, [
            _vm._v("底部信息配置"),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("备案号")]),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "请输入备案号" },
                  model: {
                    value: _vm.data.internetNumTwo,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "internetNumTwo", $$v)
                    },
                    expression: "data.internetNumTwo",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("公安备案图标")]),
            _c(
              "div",
              { staticClass: "upload" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                    },
                  },
                  [
                    _vm.data.policeInternetImgTwo
                      ? _c("DsfImage", {
                          staticClass: "avatar",
                          attrs: {
                            src: JSON.parse(
                              _vm.data.policeInternetImgTwo || "{}"
                            ).relativePath,
                          },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ],
                  1
                ),
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                    },
                  },
                  [
                    _c("dsf-button", { attrs: { size: "small" } }, [
                      _vm._v("上传"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "dsf-button",
                  {
                    attrs: { size: "small", type: "plain" },
                    on: { click: _vm.clearIcon },
                  },
                  [_vm._v("删除")]
                ),
                _c("dsf-nc-crop-image", {
                  attrs: {
                    "show-tips-text": true,
                    resize: false,
                    "edit-img": _vm.editImg,
                    "edit-file-img": _vm.editFileImg,
                    "aspect-ratio": _vm.aspectRatio,
                    "request-url": _vm.setRequestUrl,
                  },
                  on: { saveFile: _vm.editFile, handleClose: _vm.handleClose },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("公安备案号")]),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "请输入公安备案号" },
                  model: {
                    value: _vm.data.policeInternetNumTwo,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "policeInternetNumTwo", $$v)
                    },
                    expression: "data.policeInternetNumTwo",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("等级备案号")]),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "请输入等级备案号" },
                  model: {
                    value: _vm.data.levelInternetNumTwo,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "levelInternetNumTwo", $$v)
                    },
                    expression: "data.levelInternetNumTwo",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "footer_btn" },
        [
          _c(
            "dsf-button",
            { attrs: { type: "plain" }, on: { click: _vm.backPage } },
            [_vm._v("取消")]
          ),
          _c(
            "dsf-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveModel } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }