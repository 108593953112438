<template>
  <div class="ds-change-phone">
    <section>
      <!--验证码-->
      <div class="ds-change-phone-box">
        <p>更换手机号后，下次登录可以使用新手机号登录。</p>
        <p>当前手机号：{{ userInfo.phone }}</p>
        <div class="tips"></div>
        <label class="input">
          <i class="beforeIcon iconfont icon-lianxidianhua"></i>
          <input v-model.trim="phoneNumber" maxlength="11" type="text" placeholder="请输入手机号码" @blur="blurInput" />
        </label>
        <div class="input">
          <i class="beforeIcon iconfont icon-yanzhengma1"></i>
          <div class="input-bt" :class="[codeBtType]" @click.stop="getCode">
            {{ codeBtType != "" ? `${countdownTime}s` : "获取验证码" }}
          </div>
          <input v-model.trim="phoneCode" maxlength="12" type="text" placeholder="请输入验证码" />
        </div>
        <div class="error-tips" v-if="codeAuthResult && phoneCode">
          <i class="iconfont icon-tixingshixin" />
          {{ codeAuthResult }}
        </div>
        <div class="btns">
          <el-button class="btn" :disabled="!isSendCode" :loading="isWaiting" block @click="nextStep">确 认</el-button>
        </div>
      </div>
    </section>
    <!-- 验证通过 -->
    <!-- <van-dialog v-model="showdialog" title="提示" show-cancel-button :showConfirmButton="false" :showCancelButton="false">
      <div class="dialog-content">
        <div>
          <p class="dialog-hint">“{{ phoneNumber }}”账号已注册使用，且有账户内容！</p>
          <p class="dialog-hint">请确认“{{ userInfo.phone }}”账号与“{{ phoneNumber }}”账号是否为同一用户？</p>
        </div>
        <div class="btns">
          <van-button type="info">是</van-button>
          <van-button plain type="primary">否</van-button>
        </div>
      </div>
    </van-dialog> -->
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcChangePhone",
  ctrlCaption: "忘记密码",
  mixins: [$mixins.control],
  props: {
    reqUrl: {
      type: String,
      default: "/nc/org/user/detail",
    },
    getCodeUrl: {
      type: String,
      default: "nc/org/user/sendNcVerifCode",
    },
    authCodeUrl: {
      type: String,
      default: "nc/org/user/updatePhone",
    },
  },
  data() {
    return {
      // 是否等待状态
      isWaiting: false,
      // 获取验证码按钮的状态。countdown：倒计时中
      codeBtType: "",
      // 验证码倒计时的时间
      countdownTime: 0,
      countdownTimes: 60,
      notice: "",
      // 手机号
      phoneNumber: "",
      // 短信验证码
      phoneCode: "",
      //验证码换取的token
      token: "",
      // 验证码结果
      codeAuthResult: "",
      userInfo: {},
      // showdialog: false,
      isCode: false,
      isSendCode: false,
    };
  },
  watch: {
    phoneCode() {
      this.codeAuthResult = "";
    },
  },
  mounted() {
    if (this.isDesign) {
      this.userInfo = {
        phone: "13********3",
      };
    } else {
      this.getData();
    }
    // 获取倒计时
    // this.getInterval();
  },
  methods: {
    blurInput() {
      // this.isCode = false;
      // if (!this.phoneNumber) return;
      // if (!dsf.validate("isMobileOrTelPhone", this.phoneNumber)) {
      //   dsf.layer.message("手机号输入有误", false);
      //   return;
      // }
      // const loading = dsf.layer.loading();
      // let params = {
      //   phone: this.phoneNumber,
      // };
      // this.dsf.http
      //   .get("nc/org/user/checkPhone", params, $$webRoot.nc)
      //   .done(res => {
      //     if (res.success) {
      //       this.isCode = true;
      //     } else {
      //       this.dsf.layer.message(res.message, false);
      //     }
      //   })
      //   .error(response => {
      //     this.dsf.layer.message(response.message, false);
      //   })
      //   .always(function () {
      //     dsf.layer.closeLoading(loading);
      //   });
    },
    getData() {
      if (this.reqUrl) {
        const loading = dsf.layer.loading();
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              this.userInfo = res.data;
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      }
    },
    // 获取验证码
    getCode() {
      if (this.codeBtType != "") return;
      // if (!this.isCode) return;
      if (this.phoneNumber === "") {
        dsf.layer.message("手机号不能为空！", false);
        return;
      }
      if (!dsf.validate("isMobileOrTelPhone", this.phoneNumber)) {
        dsf.layer.message("手机号输入有误", false);
        return;
      }
      this.isSendCode = false;
      let loading = dsf.layer.loading();
      // todo 获取验证码，这次setTimeout模拟了异步请
      this.$http
        .get(this.getCodeUrl, { phone: this.phoneNumber, codetype: "modifyPhone" })
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.message("发送成功");
            this.isSendCode = true;
            this.codeBtType = "countdown";
            this.doCountdown();
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error(err => {
          dsf.error(err);
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
    },
    // 执行倒计时
    doCountdown() {
      this.countdownTime = this.countdownTimes - 1;
      this.timeout = setInterval(() => {
        this.countdownTime--;
        if (this.countdownTime <= -1) {
          this.codeBtType = "";
          clearInterval(this.timeout);
        }
      }, 1000);
    },
    // 验证验证码
    authCode() {
      return new Promise(resolve => {
        let loading = dsf.layer.loading();
        this.$http
          .post(this.authCodeUrl, { phone: this.phoneNumber, code: this.phoneCode, codetype: "modifyPhone" })
          .done(({ success, data, message }) => {
            if (success) {
              dsf.layer.message(message || "成功");
              setTimeout(() => {
                window.location.reload();
              }, 500);
              this.isWaiting = false;
            } else {
              resolve({ err: message || "请求异常", token: null });
            }
          })
          .error(err => {
            resolve({ err: err?.message || "请求异常", token: null });
          })
          .always(() => {
            dsf.layer.closeLoading(loading);
          });
      });
    },
    // 下一步
    async nextStep() {
      // 验证验证码值
      this.isWaiting = true;
      let { err, token } = await this.authCode();
      this.isWaiting = false;
      if (err) {
        this.codeAuthResult = err;
        return false;
      }
      this.token = token;
      // this.showdialog = true;
    },
  },
});
</script>
