var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("ul", { staticClass: "ds-nc-card-course-two" }, [
        _c(
          "li",
          { staticClass: "card-course-items" },
          [
            _c("dsf-nc-image"),
            _c("span", { staticClass: "title" }, [
              _vm._v("健全多层次社会保障体系，让社会保障制度更公平（上）"),
            ]),
            _vm._m(0),
          ],
          1
        ),
      ])
    : _c(
        "ul",
        {
          staticClass: "ds-nc-card-course-two",
          attrs: { title: _vm.data["title"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "li",
            { staticClass: "card-course-items" },
            [
              _c("dsf-nc-image", {
                staticStyle: { width: "100%", height: "158px" },
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.data["title"])),
              ]),
              _c("div", { staticClass: "footer" }, [
                _c("span", [_vm._v("主讲人：" + _vm._s(_vm.data["teacher"]))]),
                _c("span", [_vm._v("选课人次：" + _vm._s(_vm.data["num"]))]),
              ]),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("span", [_vm._v("主讲人：杨宜勇")]),
      _c("span", [_vm._v("选课人次：284")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }