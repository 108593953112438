<template>
  <div class="dsf_credential">
    <div v-if="isDesign" class="dsf_credential-wrap">
      <div class="img-box">
        <el-image :src="isDesignSrc" fit="cover"></el-image>
      </div>
    </div>
    <div v-else class="dsf_credential-wrap">
      <div class="img-box">
        <el-image :src="src" fit="cover"></el-image>
        <!-- <dsf-nc-image :src="src" :error-src="defaultSrc" /> -->
      </div>
      <div class="dsf_credential-download" v-if="isDownLoad">
        <el-button @click="add(src)">下载证书</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCredential",
  ctrlCaption: "证书详情",
  mixins: [$mixins.control],
  props: {
    certificateSrc: {
      type: String,
      default: "",
    },
    isDownLoad: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      isDesignSrc: require("../../../assets/imgs/nocredential.png"),
      src: "",
      name: "",
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  mounted() {
    setTimeout(() => {
      this.src = this.$route.query.src
        ? dsf.url.getWebPath(this.$route.query.src)
        : this.queryString["src"]
        ? dsf.url.getWebPath(this.queryString["src"])
        : this.certificateSrc || "";
      this.name = this.$route.query.name || this.queryString["name"] || "证书";
    }, 100);
  },
  methods: {
    add(src) {
      var filename; //图片名称
      var filetype; //图片类型
      var path = src;
      if (path) {
        var file = path.substring(path.lastIndexOf("/") + 1, path.length);
        var fileArr = file.toLowerCase().split(".");
        filename = this.name || fileArr[0];
        filetype = fileArr[1];
      }
      if (!filename) {
        filename = this.name;
      }
      if (!filetype) {
        filetype = "png";
      }
      var canvas = document.createElement("canvas");
      var img = document.createElement("img");
      img.onload = function (e) {
        canvas.width = img.width;
        canvas.height = img.height;
        var context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(blob => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = filename + "." + filetype;
          a.click();
        }, `image/${filetype}`);
      };
      img.setAttribute("crossOrigin", "Anonymous");
      img.src = src;
    },
  },
});
</script>
<style lang="scss" scoped></style>
