var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c(
        "div",
        {
          staticClass: "dsf_nc_footer_two",
          style: { width: _vm.itemWidth, margin: _vm.margin },
        },
        [
          _c("div", { staticClass: "nc-footer-inner" }, [
            _c("div", { staticClass: "footer-top" }, [
              _vm._m(0),
              _vm._m(1),
              _c("div", { staticClass: "footer-top-right" }, [
                _c(
                  "div",
                  { staticClass: "item qrcode-one" },
                  [
                    _vm.data.qrcodeOneImg
                      ? _c("DsfImage", {
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeOneImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("dsf-icon", {
                          staticClass: "qrcode-icon",
                          attrs: { name: "ico" },
                        }),
                    _c("p", [_vm._v("APP下载")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item qrcode-one" },
                  [
                    _vm.data.qrcodeTwoImg
                      ? _c("DsfImage", {
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeTwoImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("dsf-icon", {
                          staticClass: "qrcode-icon",
                          attrs: { name: "ico" },
                        }),
                    _c("p", [_vm._v("微信公众号")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item qrcode-one" },
                  [
                    _vm.data.qrcodeThreeImg
                      ? _c("DsfImage", {
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeThreeImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("dsf-icon", {
                          staticClass: "qrcode-icon",
                          attrs: { name: "ico" },
                        }),
                    _c("p", [_vm._v("微博")]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "dsf_nc_footer_two",
          style: { width: _vm.itemWidth, margin: _vm.margin },
        },
        [
          _c("div", { staticClass: "nc-footer-inner" }, [
            _c("div", { staticClass: "footer-top" }, [
              _c("div", { staticClass: "footer-top-left" }, [
                _c("dl", [
                  _vm.data.addressTwo
                    ? _c("dd", { staticClass: "address" }, [
                        _vm._v("地址：" + _vm._s(_vm.data.addressTwo)),
                      ])
                    : _vm._e(),
                  _vm.data.email
                    ? _c("dd", { staticClass: "email" }, [
                        _vm._v("邮箱：" + _vm._s(_vm.data.email)),
                      ])
                    : _vm._e(),
                  _vm.data.copyright
                    ? _c("dd", { staticClass: "copyNumber" }, [
                        _vm._v("版权所有 © " + _vm._s(_vm.data.copyright)),
                      ])
                    : _vm._e(),
                  _vm.data.websiteInternet
                    ? _c("dd", { staticClass: "policeNumber" }, [
                        _vm._v(
                          "网站备案号：" + _vm._s(_vm.data.websiteInternet)
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "footer-top-center" }, [
                _vm.data.hotLine
                  ? _c("dl", [
                      _c("dd", { staticClass: "phone_text" }, [
                        _vm._v(_vm._s(_vm.phoneText)),
                      ]),
                      _c("dd", { staticClass: "phone" }, [
                        _vm._v(_vm._s(_vm.data.hotLine)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "footer-top-right" }, [
                _c(
                  "div",
                  { staticClass: "item qrcode-one" },
                  [
                    _vm.data.qrcodeOneImg
                      ? _c("DsfImage", {
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeOneImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("dsf-icon", {
                          staticClass: "qrcode-icon",
                          attrs: { name: "ico" },
                        }),
                    _c("p", [_vm._v(_vm._s(_vm.data.qrcodeOneName))]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item qrcode-one" },
                  [
                    _vm.data.qrcodeTwoImg
                      ? _c("DsfImage", {
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeTwoImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("dsf-icon", {
                          staticClass: "qrcode-icon",
                          attrs: { name: "ico" },
                        }),
                    _c("p", [_vm._v(_vm._s(_vm.data.qrcodeTwoName))]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item qrcode-one" },
                  [
                    _vm.data.qrcodeThreeImg
                      ? _c("DsfImage", {
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeThreeImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("dsf-icon", {
                          staticClass: "qrcode-icon",
                          attrs: { name: "ico" },
                        }),
                    _c("p", [_vm._v(_vm._s(_vm.data.qrcodeThreeName))]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-top-left" }, [
      _c("dl", [
        _c("dd", { staticClass: "address" }, [
          _vm._v("地址：上海市大木桥路108号6楼"),
        ]),
        _c("dd", { staticClass: "email" }, [_vm._v("邮箱：qyfwy@shaitc.com")]),
        _c("dd", { staticClass: "copyNumber" }, [
          _vm._v("版权所有 © 上海市中小型企业发展服务中心"),
        ]),
        _c("dd", { staticClass: "policeNumber" }, [
          _vm._v("网站备案号：沪ICP020577号"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-top-center" }, [
      _c("dl", [
        _c("dd", { staticClass: "phone_text" }, [_vm._v("服务云咨询热线")]),
        _c("dd", { staticClass: "phone" }, [_vm._v("400-820-3302")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }