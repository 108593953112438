<template>
  <div class="dsf_ncpc_rank_detail">
    <div class="rank_detail_top">
      <div class="title">学员排行榜</div>
      <ul class="rank_top_wrap" v-if="headerData && headerData.length > 0">
        <li :class="['rank_top_items', idx == 0 ? 'silver' : idx == 1 ? 'gold' : idx == 2 ? 'bronze' : '']" v-for="(it, idx) in headerData" :key="idx">
          <div class="hearder_img" v-if="it">
            <dsf-nc-image v-if="it.photo" :src="it.photo | imgFormat" :error-src="defaultSrc"></dsf-nc-image>
            <dsf-nc-image v-else :src="''" :error-src="defaultSrc"></dsf-nc-image>
          </div>
          <div class="ranking" v-if="it"></div>
          <div class="name" v-if="it">{{ it.name || "" }}</div>
          <div class="time" v-if="it">{{ it.time || 0 }}分钟</div>
          <div class="no_data" v-else>暂无数据</div>
        </li>
      </ul>
    </div>
    <div class="rank_detail_container">
      <div class="ower_rank">
        <div class="rankcount">{{ myselfData.rank }}</div>
        <div class="rank_name">
          <dsf-nc-image :src="myselfData.photo | imgFormat" :error-src="defaultSrc"></dsf-nc-image>
          <span class="name">{{ myselfData.name || "暂无" }}</span>
        </div>
        <div class="rank_time">{{ myselfData.time || "0" }}分钟</div>
      </div>
      <div class="rank_header">
        <div class="rankcount">排名</div>
        <div class="rank_name">学员</div>
        <div class="rank_time">学习时长</div>
      </div>
      <ul class="rank_wrap" v-if="tableData && tableData.length > 3">
        <li class="rank_items" v-for="(item, index) in tableData.slice(3)" :key="index">
          <div class="rankcount">{{ index + 4 }}</div>
          <div class="rank_name">
            <dsf-nc-image :src="item.photo | imgFormat" :error-src="defaultSrc"></dsf-nc-image>
            <span class="name">{{ item.name || "" }}</span>
          </div>
          <div class="rank_time">{{ item.time }}</div>
        </li>
      </ul>
      <div v-if="tableData && tableData.length > 3" class="more" @click="handlerClick">{{ moreText }}</div>
      <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcRankDetail",
  ctrlCaption: "榜单详情",
  mixins: [$mixins.control],
  props: {},
  data() {
    return {
      defaultSrc: dsf.config.setting_public_user_default_card_header,
      url: "/nc/class/student/rank",
      params: {
        id: "",
        size: 10,
        index: 1,
      },
      tableData: [
        { name: "1111", photo: "", time: "11111" },
        { name: "22222", photo: "", time: "222221" },
        { name: "33333333", photo: "", time: "333333331" },
        { name: "44444", photo: "", time: "444441" },
        { name: "5555555", photo: "", time: "55555551" },
        { name: "6666666", photo: "", time: "66666661" },
      ],
      moreText: "加载更多",
      headerData: [],
      myselfData: {},
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "e1e9daabd8284af5b65bd8f3b1551745";
  },
  mounted() {
    if (!this.isDesign) {
      this.tableData = [];
      this.initData();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            error(res);
          } else {
            this.getLengthData = data.list;
            this.tableData = this.tableData.concat(data.list);
            this.headerData = this.tableData.slice(0, 3);
            let [one, two, three] = this.headerData;
            this.headerData = [two, one, three];
            this.myselfData = data.myself;
            if (data.list.length < this.params.size) {
              this.moreText = "没有更多了";
            }

            this.$nextTick(() => {
              this.$page.resize();
            });
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    handlerClick() {
      if (this.moreText == "没有更多了") return;
      this.params.index += 1;
      this.initData();

      // console.log(1111, this.params);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
