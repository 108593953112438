var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_ncpc_special_announcement_notice" }, [
        _c("div", { staticClass: "announcement_notice_title" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.titleName))]),
        ]),
        _c(
          "div",
          { staticClass: "message_top" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "message_checkbox",
                attrs: { shape: "square" },
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [_vm._v("只看未读(2)")]
            ),
            _c("div", { staticClass: "font" }, [_vm._v("全部标为已读")]),
          ],
          1
        ),
        _vm._m(0),
      ])
    : _c("div", { staticClass: "dsf_ncpc_special_announcement_notice" }, [
        _c("div", { staticClass: "announcement_notice_title" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.titleName))]),
        ]),
        _c(
          "div",
          { staticClass: "message_top" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "message_checkbox",
                attrs: { shape: "square" },
                on: { change: _vm.clickCheckBox },
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [_vm._v("只看未读(" + _vm._s(_vm.noReadTotal || 0) + ")")]
            ),
            _c("div", { staticClass: "font", on: { click: _vm.markReadAll } }, [
              _vm._v("全部标为已读"),
            ]),
          ],
          1
        ),
        _vm.messageLists && _vm.messageLists.length
          ? _c("div", { staticClass: "message_content" }, [
              _c(
                "ul",
                { staticClass: "message_wrap" },
                _vm._l(_vm.messageLists, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: item._id,
                      staticClass: "message_items",
                      on: {
                        click: function ($event) {
                          return _vm.handleClick(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "title ell" }, [
                        _c("i", {
                          class: ["is_new", { active: item.status != 1 }],
                        }),
                        _c("span", { staticClass: "font ell" }, [
                          _vm._v(" " + _vm._s(item.title || "") + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(
                          _vm._s(
                            _vm.dsf.date.format(item.sendDateTime, "yyyy-mm-dd")
                          )
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "announcement_notice_page",
                  style: { "text-align": _vm.paginationPosition },
                },
                [
                  _c("el-pagination", {
                    ref: "pagination",
                    class: [_vm.paginationStyle],
                    attrs: {
                      "hide-on-single-page": false,
                      total: _vm.pageCount,
                      "page-size": _vm.pageSize,
                      "page-sizes": _vm.limits,
                      "current-page": _vm.currentPage,
                      layout: "slot," + _vm.pagerLayout,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.currentPage = $event
                      },
                      "size-change": _vm.pageSizeChange,
                      "current-change": _vm.pageSizeChange,
                    },
                  }),
                ],
                1
              ),
            ])
          : _c("div", {
              staticClass: "ds-empty-data",
              domProps: { textContent: _vm._s("暂无数据") },
            }),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message_content" }, [
      _c("ul", { staticClass: "message_wrap" }, [
        _c("li", { staticClass: "message_items" }, [
          _c("div", { staticClass: "title_box" }, [
            _c("span", { staticClass: "title ell" }, [
              _c("span", { staticClass: "font" }, [_vm._v("开班提醒")]),
              _c("i", { staticClass: "is_new" }),
            ]),
            _c("span", { staticClass: "date" }, [_vm._v("2014-05-20")]),
          ]),
          _c("div", { staticClass: "content ell" }, [
            _vm._v(
              "与其等待与能力匹配的机会，不如培养与机会匹配的能力与其等待与能力匹配的机会，不如培养与机会匹配的能力"
            ),
          ]),
        ]),
        _c("li", { staticClass: "message_items" }, [
          _c("div", { staticClass: "title_box" }, [
            _c("span", { staticClass: "title ell" }, [
              _c("span", { staticClass: "font ell" }, [_vm._v("结业提醒")]),
              _c("i", { staticClass: "is_new" }),
            ]),
            _c("span", { staticClass: "date" }, [_vm._v("2014-05-20")]),
          ]),
          _c("div", { staticClass: "content ell" }, [
            _vm._v("您已经成功结业啦！"),
          ]),
        ]),
        _c("li", { staticClass: "message_items" }, [
          _c("div", { staticClass: "title_box" }, [
            _c("span", { staticClass: "title ell" }, [
              _c("span", { staticClass: "font" }, [_vm._v("其他")]),
            ]),
            _c("span", { staticClass: "date" }, [_vm._v("2014-05-20")]),
          ]),
          _c("div", { staticClass: "content ell" }, [
            _vm._v(
              "与其等待与能力匹配的机会，不如培养与机会匹配的能力与其等待与能力匹配的机会，不如培养与机会匹配的能力"
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }