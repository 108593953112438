var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "dsf_userInfo",
          style: { width: _vm.width, height: _vm.height },
        },
        [
          _vm.showHeader
            ? _c("userTitle", {
                attrs: {
                  title: _vm.title,
                  moreUrl: _vm.moreUrl,
                  showMore: _vm.showMore,
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "userInfo-content" }, [
            _c("p", { staticClass: "margin-bottom-20" }, [
              _c("span", { staticClass: "padding-left-10" }, [
                _vm._v("姓名："),
              ]),
              _c("span", { staticClass: "margin-left-10" }, [
                _vm._v(_vm._s(_vm.resData[_vm.keys.name])),
              ]),
            ]),
            _c("p", { staticClass: "margin-bottom-20" }, [
              _c("span", { staticClass: "padding-left-10" }, [
                _vm._v("性别："),
              ]),
              _c("span", { staticClass: "margin-left-10" }, [
                _vm._v(_vm._s(_vm.resData[_vm.keys.sex] || "")),
              ]),
            ]),
            _c("p", { staticClass: "margin-bottom-20" }, [
              _c("span", { staticClass: "padding-left-10" }, [
                _vm._v("职务："),
              ]),
              _c("span", { staticClass: "margin-left-10" }, [
                _vm._v(_vm._s(_vm.resData[_vm.keys.job] || "")),
              ]),
            ]),
            _c("p", { staticClass: "margin-bottom-20" }, [
              _c("span", { staticClass: "padding-left-10" }, [
                _vm._v("手机号："),
              ]),
              _c("span", { staticClass: "margin-left-10" }, [
                _vm._v(_vm._s(_vm.resData[_vm.keys.phone || ""])),
              ]),
            ]),
            _c("div", { staticClass: "tags" }, [
              _c(
                "p",
                {
                  staticClass: "padding-left-10 table-cell tag-title",
                  staticStyle: { "padding-bottom": "20px" },
                },
                [_vm._v("兴趣爱好：")]
              ),
              _vm.resData[_vm.keys.hobbies]
                ? _c(
                    "p",
                    { staticClass: "margin-left-10 table-cell tag-box" },
                    _vm._l(
                      _vm.resData[_vm.keys.hobbies].split("^"),
                      function (item, index) {
                        return _c("span", { key: index, staticClass: "tag" }, [
                          _vm._v(_vm._s(item)),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "tags" }, [
              _c("p", { staticClass: "padding-left-10 table-cell tag-title" }, [
                _vm._v("个人标签："),
              ]),
              _vm.resData[_vm.keys.tags]
                ? _c(
                    "p",
                    { staticClass: "margin-left-10 table-cell tag-box" },
                    _vm._l(
                      _vm.resData[_vm.keys.tags].split("^"),
                      function (item, index) {
                        return _c("span", { key: index, staticClass: "tag" }, [
                          _vm._v(_vm._s(item)),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }