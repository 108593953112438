var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_nc_teacher_share ds-control" }, [
        _c("div", { staticClass: "title_font" }, [
          _vm._v("下载图片，进行分享"),
        ]),
        _c("div", { ref: "container", staticClass: "content_wrap" }, [
          _c("div", { staticClass: "content_innter" }, [
            _c("div", { staticClass: "content_top" }, [
              _c(
                "div",
                { staticClass: "header_img" },
                [
                  _c("DsfNcDefaultHeader", {
                    staticClass: "default-avatar",
                    attrs: {
                      src: "",
                      width: "124px",
                      height: "165px",
                      round: false,
                      defaultHeaderUrl:
                        _vm.dsf.config.setting_public_user_default_header,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "content_top_desc" }, [
                _c("div", { staticClass: "name" }, [_vm._v("谯婷")]),
                _c("div", { staticClass: "position font" }, [
                  _vm._v("上海梦创双杨"),
                ]),
                _c("div", { staticClass: "deptText font" }, [
                  _vm._v("教授、研究生导师"),
                ]),
                _vm.info.adeptText
                  ? _c(
                      "div",
                      { staticClass: "tags" },
                      _vm._l(_vm.info["adeptText"].split("^"), function (item) {
                        return _c("span", { key: item, staticClass: "tag" }, [
                          _vm._v(_vm._s(item)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._m(0),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("el-button", { staticClass: "btn close" }, [_vm._v("关闭")]),
            _c(
              "el-button",
              { staticClass: "btn down", attrs: { type: "primary" } },
              [_vm._v("下载图片")]
            ),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "dsf_nc_teacher_share" }, [
        _c("div", { staticClass: "title_font" }, [
          _vm._v("下载图片，进行分享"),
        ]),
        _c("div", { ref: "container", staticClass: "content_wrap" }, [
          _c("div", { staticClass: "content_innter" }, [
            _c("div", { staticClass: "content_top" }, [
              _c(
                "div",
                { staticClass: "header_img" },
                [
                  _c("DsfNcDefaultHeader", {
                    staticClass: "default-avatar",
                    attrs: {
                      src: _vm.info.photo,
                      width: "124px",
                      height: "165px",
                      round: false,
                      defaultHeaderUrl:
                        _vm.dsf.config.setting_nc_image_teacher_header_img ||
                        _vm.dsf.config.setting_public_teacher_default_header,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "content_top_desc" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.info.name)),
                ]),
                _c("div", { staticClass: "position font" }, [
                  _vm._v(_vm._s(_vm.info.position || "")),
                ]),
                _c("div", { staticClass: "deptText font" }, [
                  _vm._v(_vm._s(_vm.info.deptText || "")),
                ]),
                _vm.info.adeptText
                  ? _c(
                      "div",
                      { staticClass: "tags" },
                      _vm._l(_vm.info["adeptText"].split("^"), function (item) {
                        return _c("span", { key: item, staticClass: "tag" }, [
                          _vm._v(_vm._s(item)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "content_bottom" }, [
              _c("div", { staticClass: "qrCode" }, [
                _c("img", { attrs: { src: _vm.qrCodeInfo.qrCode, alt: "" } }),
              ]),
              _c("div", { staticClass: "des" }, [
                _c("div", { staticClass: "desc_font" }, [_vm._v("扫描二维码")]),
                _c(
                  "div",
                  {
                    staticClass: "desc_btn",
                    on: { click: _vm.clickTeacherDetail },
                  },
                  [_vm._v("查看老师详情")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              { staticClass: "btn close", on: { click: _vm.handlerClose } },
              [_vm._v("关闭")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn down",
                attrs: { type: "primary" },
                on: { click: _vm.handlerDownload },
              },
              [_vm._v("下载图片")]
            ),
          ],
          1
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content_bottom" }, [
      _c("div", { staticClass: "qrCode" }),
      _c("div", { staticClass: "des" }, [
        _c("div", { staticClass: "desc_font" }, [_vm._v("扫描二维码")]),
        _c("div", { staticClass: "desc_btn" }, [_vm._v("查看老师详情")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }