<template>
  <div class="ds-control ds-navtab ds-side-navtab" :class="{ 'ds-no-padding': !showPadding }" :style="{ height: isDesign ? '100%' : tabHeight }" id="tab-pane">
    <el-tabs
      v-model="activeName"
      :tab-position="'left'"
      :type="tabStyle == 'customer-tab-pad' ? '' : tabStyle"
      :closable="enableDelete"
      @tab-click="tabClick"
      @tab-remove="tabRemove"
    >
      <slot></slot>
      <el-tab-pane
        v-for="d in slots.filter(tab => {
          return isHide(tab) !== true;
        })"
        :key="d.name"
        :disabled="isdisable(d)"
        :label="d.title"
        :name="d.name"
        :slot-name="d.name"
        :lazy="isLazy(d)"
        :style="{ padding: $padding }"
      >
        <span slot="label">
          <i :class="`icon iconfont ${d.icon}`"></i>
          {{ d.title }}
          <span v-if="d.badge !== null" class="el-badge" v-html="d.badge"></span>
        </span>
        <slot :name="d.name" v-if="isDesign"></slot>
        <template v-if="activeName == d.name && !isDesign" class="slot-box">
          <slot :name="d.name"></slot>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSideBar",
  ctrlCaption: "侧边导航",
  mixins: [$mixins.tabControl],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    moduleWidth: {
      type: String,
      default: "220px",
    },
    moduleHeight: {
      type: String,
      default: "840px",
    },
    active: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeName: this.$route.query.active || "",
      styleDom: "",
    };
  },
  components: {},
  created() {},
  mounted() {
    let styleDom = document.createElement("style");
    document.body.append(styleDom);
    this.styleDom = styleDom;
    this.updateStyleDom();
  },
  methods: {
    updateStyleDom() {
      this.$nextTick(() => {
        let height = $("#tab-pane").height();
        if (height) {
          this.styleDom.innerHTML = `
            .ds-side-navtab .slot-box .ds-page-content,
            .ds-side-navtab .slot-box .ds-panel {
              min-height: ${height - 85}px;
            }
            .ds-side-navtab .slot-box .ds-empty-data{
              min-height: ${height - 268}px;
            }
          `;
        }
      });
    },
  },
});
</script>
