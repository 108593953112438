var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_userInvitationForm_apply",
      style: { width: _vm.itemWidth, padding: _vm.itemPadding },
    },
    [
      _c("div", { staticClass: "form-container" }, [
        _c(
          "div",
          { staticClass: "container-left" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("已设置选项")]),
            _vm._m(0),
            _vm._l(_vm.reqParams.items, function (item, index) {
              return [
                _c(
                  "div",
                  { key: index, staticClass: "input-row margin-bottom-10" },
                  [
                    _c(
                      "div",
                      { staticClass: "input-width-70 input-title text-center" },
                      [
                        _c("el-checkbox", {
                          attrs: { disabled: item.base || _vm.isview },
                          model: {
                            value: item.required,
                            callback: function ($$v) {
                              _vm.$set(item, "required", $$v)
                            },
                            expression: "item.required",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "input-width-100 input-title" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "input-width-all input-title" },
                      [
                        _c("el-input", {
                          staticClass: "item-input",
                          attrs: { disabled: _vm.isview },
                          model: {
                            value: item.text,
                            callback: function ($$v) {
                              _vm.$set(item, "text", $$v)
                            },
                            expression: "item.text",
                          },
                        }),
                      ],
                      1
                    ),
                    !item.base
                      ? _c("dsf-icon", {
                          staticClass: "deleteInput",
                          attrs: { name: "icon_wrong" },
                          on: {
                            click: function ($event) {
                              return _vm.hanldeDeleteInput(item, index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
        _c("div", { staticClass: "container-right" }, [
          _c("div", { staticClass: "title" }, [_vm._v("添加选项")]),
          _c("div", { staticClass: "checked-add" }, [
            _c("div", { staticClass: "common margin-bottom-10" }, [
              _vm._v("常用项"),
            ]),
            _c(
              "div",
              { staticClass: "checed-add-container margin-bottom-10" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    attrs: { disabled: _vm.isview, border: "" },
                    on: { change: _vm.handleChangeCommon },
                    model: {
                      value: _vm.commonCheckboxGroup,
                      callback: function ($$v) {
                        _vm.commonCheckboxGroup = $$v
                      },
                      expression: "commonCheckboxGroup",
                    },
                  },
                  _vm._l(_vm.common, function (item) {
                    return _c(
                      "el-checkbox-button",
                      {
                        key: item.type,
                        staticClass:
                          "margin-bottom-10 margin-5 checkbox-button",
                        attrs: {
                          disabled: _vm.isview,
                          border: "",
                          label: item.name,
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "common margin-bottom-10" }, [
              _vm._v("自定义项"),
            ]),
            _c(
              "div",
              { class: _vm.isview ? "disable" : "custom" },
              [
                _vm._l(_vm.custom, function (item) {
                  return [
                    _c(
                      "span",
                      {
                        key: item.type,
                        staticClass: "custom-btn margin-5",
                        on: {
                          click: function ($event) {
                            !_vm.isview && _vm.handleCustom(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.text))]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm.btnsShow
        ? _c(
            "div",
            { staticClass: "dsf_specila_apply-btns text-center" },
            [
              _vm.backGo
                ? _c("el-button", { on: { click: _vm.clickBackSetp } }, [
                    _vm._v("上一步"),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                { staticClass: "sumbit-btn", on: { click: _vm.sumbitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "自定义选项",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": "",
            "before-close": _vm.beforeClose,
            "custom-class": "NcSpecialApplyDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("label", { staticClass: "item-title" }, [_vm._v("报名选项")]),
              _c("el-input", {
                staticClass: "item-input",
                model: {
                  value: _vm.customItem.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.customItem, "name", $$v)
                  },
                  expression: "customItem.name",
                },
              }),
              _vm.customItem.type == "radiobox" ||
              _vm.customItem.type == "checkbox"
                ? _c(
                    "div",
                    { staticClass: "content-add" },
                    [
                      _c("p", { staticClass: "hint" }, [
                        _vm._v(
                          _vm._s(
                            _vm.customItem.type == "radiobox"
                              ? "单选框"
                              : "多选框"
                          ) + "选项列表"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "add-list" },
                        _vm._l(_vm.checkboxList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "add-input-box" },
                            [
                              _c("el-input", {
                                staticClass: "item-input require",
                                attrs: { required: "" },
                                model: {
                                  value: item.text,
                                  callback: function ($$v) {
                                    _vm.$set(item, "text", $$v)
                                  },
                                  expression: "item.text",
                                },
                              }),
                              !item.base
                                ? _c("dsf-icon", {
                                    staticClass: "deleteInput",
                                    attrs: { name: "icon_wrong" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCheckbox(item, index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "add_btn",
                          attrs: { size: "small" },
                          on: { click: _vm.addCheckbox },
                        },
                        [_vm._v("添 加")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.dialogSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-row margin-bottom-10" }, [
      _c("div", { staticClass: "input-width-70 input-title" }, [
        _vm._v("报名者必填"),
      ]),
      _c("div", { staticClass: "input-width-100 input-title ell" }, [
        _vm._v("报名选项"),
      ]),
      _c("div", { staticClass: "input-width-all input-title" }, [
        _vm._v("报名者提示语"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }