<template>
  <div class="dsf_cardItemByLive" v-if="visible">
    <div class="content">
      <div class="item-wrap pointer" v-if="isDesign">
        <div class="img-box">
          <el-image :src="src | imgFormat" fit="cover"></el-image>
        </div>
        <div class="info">
          <p class="ell2">上海全力打造全国新一轮全面开放新高地的新举措</p>
          <div class="detail">
            <span>张三</span>
            <span>
              <dsf-icon class="icon-yanjing"></dsf-icon>
              <span class="num">4527</span>
            </span>
          </div>
        </div>
      </div>
      <div class="item-wrap pointer" @click="_clickBefore(goDetail)" v-else :style="{ width: cardWidth }">
        <div class="img-box" :style="{ width: cardWidth }">
          <!-- <el-image :src="data['cover'] | imgFormat" fit="cover"></el-image> -->
          <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
        </div>
        <div class="info" :style="{ width: cardWidth }">
          <p class="ell2">{{ data["title"] }}</p>
          <div class="detail">
            <span>{{ data["teacher"] }}</span>
            <span class="views">
              <dsf-icon class="icon-yanjing"></dsf-icon>
              <span class="num">{{ data["views"] || 0 }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCardItemByLive",
  ctrlCaption: "直播卡片",
  mixins: [$mixins.card],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    cardWidth: {
      type: String,
      default: "220px",
    },
    detailUrl: {
      type: String,
      default: "/pc/nc/pagelive/liveDetail?id=",
    },

    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "nc_live_page_historyData.cover", to: "cover", type: "text", desc: "封面" },
          { from: "nc_live_page_historyData.title", to: "title", type: "text", desc: "标题" },
          { from: "nc_live_page_historyData.speaker", to: "teacher", type: "text", desc: "授课教师" },
          { from: "nc_live_page_historyData.views", to: "views", type: "text|number", desc: "浏览量" },
          { from: "nc_live_page_historyData.nc_live_info_id", to: "id", type: "text", desc: "id" },
          { from: "nc_live_page_historyData.login_limit_text", to: "login_limit_text", type: "text", desc: "是否登录校验文本" },
          { from: "nc_live_page_historyData.login_limit_value", to: "login_limit_value", type: "text", desc: "是否登录校验" },
        ];
      },
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  components: {},
  computed: {
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
  },
  methods: {
    goDetail() {
      console.log(this.data);
      if (this.$listeners["click"]) {
        this.$dispatch("click", this.data);
      } else {
        if (this.data["login_limit_value"] && this.data["login_limit_value"] == 1 && !this.isLogin) {
          this.$openDialog({
            title: "",
            width: "400px",
            height: "370px",
            noTitle: true,
            params: {
              showClose: false,
              path: dsf.config.setting_public_login_dialog,
              loginSuccess() {
                console.log("登录成功");
                dsf.layer.openWindow(this.detailUrl + this.data.id);
              },
              loginFail() {
                console.log("登录失败");
              },
            },
            onClosed() {
              dsf.layer.closeWindow();
              console.log("关闭弹窗");
            },
          });
        } else {
          dsf.layer.openWindow(this.detailUrl + this.data.id);
        }
        // if (this.detailUrl) {
        //   // let url = this.$replace(this.detailUrl, this.data);
        //   // this.$openWindow({ url: url });
        //   dsf.layer.openWindow(this.detailUrl + this.data.id);
        // }
      }
    },
  },
});
</script>
<style lang="scss" scoped></style>
