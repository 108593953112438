var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exam_card" }, [
    _c("ul", { staticClass: "exam_card_wrap" }, [
      _vm.isDesign
        ? _c("li", { staticClass: "exam_card_items" }, [
            _c("div", { staticClass: "title" }, [_vm._v("10.28考试-4 第二批")]),
            _vm._m(0),
            _c("div", { staticClass: "exam_footer" }, [
              true
                ? _c("div", { staticClass: "bmck normal btn" }, [
                    _vm._v("报名参考"),
                  ])
                : _vm._e(),
              false
                ? _c("div", { staticClass: "ksck normal btn" }, [
                    _vm._v("开始考试"),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "ksxz btn" }, [_vm._v("考生须知")]),
            ]),
          ])
        : _c("li", { staticClass: "exam_card_items" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.data["title"])),
            ]),
            _c("div", { staticClass: "exam_content" }, [
              _c("div", { staticClass: "exam_date" }, [
                _c("span", { staticClass: "lable" }, [_vm._v("考试时间：")]),
                _c("span", { staticClass: "font" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dsf.date.format(_vm.data["sdate"], "yyyy-mm-dd")
                    ) +
                      " 至 " +
                      _vm._s(
                        _vm.dsf.date.format(_vm.data["edate"], "yyyy-mm-dd")
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "exam_center" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "lable" }, [
                      _vm._v("考试时长："),
                    ]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.data["exam_duration"]) + "分钟"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "lable" }, [
                      _vm._v("考试总分："),
                    ]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.data["grossScore"]) + "分"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "lable" }, [
                      _vm._v("及格分数："),
                    ]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.data["passScore"]) + "分"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "lable" }, [
                      _vm._v("考试次数："),
                    ]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.data["exam_total_count"]) + "次"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "lable" }, [
                      _vm._v("已考次数："),
                    ]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.data["exam_count"]) + "次"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "lable" }, [
                      _vm._v("剩余次数："),
                    ]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.data["surplus_count"]) + "次"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "exam_footer" }, [
              true
                ? _c("div", { staticClass: "bmck normal btn" }, [
                    _vm._v("报名参考"),
                  ])
                : _vm._e(),
              false
                ? _c("div", { staticClass: "ksck normal btn" }, [
                    _vm._v("开始考试"),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "ksxz btn" }, [_vm._v("考生须知")]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "exam_content" }, [
      _c("div", { staticClass: "exam_date" }, [
        _c("span", { staticClass: "lable" }, [_vm._v("考试时间：")]),
        _c("span", { staticClass: "font" }, [
          _vm._v("2021-12-25 12:00 至 2022-01-01 12:00"),
        ]),
      ]),
      _c("div", { staticClass: "exam_center" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "lable" }, [_vm._v("考试时长：")]),
            _c("span", { staticClass: "font" }, [_vm._v("90分钟")]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "lable" }, [_vm._v("考试总分：")]),
            _c("span", { staticClass: "font" }, [_vm._v("100分")]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "lable" }, [_vm._v("及格分数：")]),
            _c("span", { staticClass: "font" }, [_vm._v("60分")]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "lable" }, [_vm._v("考试次数：")]),
            _c("span", { staticClass: "font" }, [_vm._v("5次")]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "lable" }, [_vm._v("已考次数：")]),
            _c("span", { staticClass: "font" }, [_vm._v("1次")]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "lable" }, [_vm._v("剩余次数：")]),
            _c("span", { staticClass: "font" }, [_vm._v("4次")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }