<template>
  <div class="dsf_userInte" v-if="visible" :style="{ width: width, height: height }">
    <userTitle :title="title" :showMore="false" v-if="showHeader"></userTitle>
    <div class="userInte-content">
      <p class="margin-bottom-20">
        <span class="padding-left-10">积分等级：</span>
        <span class="margin-left-10">{{ resData[keys.inteLevel] }}</span>
      </p>
      <p class="margin-bottom-20">
        <!-- require -->
        <span class="padding-left-10">当前可用积分：</span>
        <span class="margin-left-10">{{ resData[keys.inteNum] || "" }}</span>
      </p>
      <p class="margin-bottom-20 pwd">
        <span class="padding-left-10">历史总积分：</span>
        <span class="margin-left-10">{{ resData[keys.inteHistory] || "" }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import userTitle from "./userTitle.vue";
export default dsf.component({
  name: "DsfNcUserInte",
  ctrlCaption: "积分信息",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "积分信息",
    },
    width: {
      type: String,
      default: "480px",
    },
    height: {
      type: String,
      default: "400px",
    },
    reqUrl: { type: String, default: "" },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "inteLevel", to: "inteLevel", type: "text", desc: "积分等级" },
          { from: "inteNum", to: "inteNum", type: "text", desc: "当前可用积分" },
          { from: "inteHistory", to: "inteHistory", type: "text", desc: "历史总积分" },
        ];
      },
    },
    resData: {
      tyep: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      keys: {},
    };
  },
  computed: {
    getHeight() {
      return parseInt(this.height) - 41 + "px";
    },
  },
  components: {
    userTitle,
  },
  created() {
    this.keysMap.forEach(item => {
      this.keys[item.to] = item.from;
    });
  },
  mounted() {},
  methods: {},
});
</script>
<style lang="scss" scoped></style>
