var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_live_information-mobile",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "intro_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("h3", { staticClass: "course-tab-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm.showMore
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm._e(),
        ]),
      ]),
      _vm.lists.length
        ? _c(
            "ul",
            { staticClass: "relevant_information_wrap" },
            _vm._l(_vm.lists, function (item, index) {
              return _c("li", { key: index, staticClass: "infomation_items" }, [
                _c("i", { class: "icon " + item["nc_class_file.type"] }),
                _c("span", { staticClass: "name ell" }, [
                  _vm._v(_vm._s(item["nc_class_file.title"])),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "ylook",
                    on: {
                      click: function ($event) {
                        return _vm.handleClickLook(item)
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
                item["nc_class_file.download_free"] &&
                item["nc_class_file.download_free"].value == 1
                  ? _c("span", { staticClass: "segmentation" }, [_vm._v("|")])
                  : _vm._e(),
                item["nc_class_file.download_free"] &&
                item["nc_class_file.download_free"].value == 1
                  ? _c(
                      "span",
                      {
                        staticClass: "ydown",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickDown(item)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _c("div", { staticClass: "no_data" }, [_vm._v("暂无数据")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }