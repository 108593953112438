var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-shop-purchase-method" }, [
    _c(
      "div",
      { staticClass: "method-btns" },
      [
        _c("label", [_vm._v("选择购买方式：")]),
        _vm._l(_vm.items, function (it, index) {
          return _c(
            "el-radio",
            {
              key: it.value,
              attrs: { label: it.value },
              on: {
                change: function ($event) {
                  return _vm.buyMethod(index)
                },
              },
              model: {
                value: _vm.state,
                callback: function ($$v) {
                  _vm.state = $$v
                },
                expression: "state",
              },
            },
            [_vm._v(_vm._s(it.text))]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "bottom-btns" },
      [
        _c("DsfButtonBar", {
          attrs: { buttons: _vm.buttons },
          on: {
            save: function ($event) {
              return _vm.onReSubmit()
            },
            close: function ($event) {
              return _vm.onClose()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }