<template>
  <div class="dsf_nc_banner">
    <div class="banner-inner">
      <el-carousel :class="indicatorClass" trigger="click" :height="setHeight" :interval="interval" :loop="loop">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <el-image :src="item.url">
            <div slot="error" class="image-slot">
              <img :src="dsf.config.setting_error_img" alt="" />
            </div>
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcBanner",
  ctrlCaption: "轮播",
  mixins: [$mixins.control],
  components: {},
  computed: {
    indicatorClass() {
      return this.indicatorStyle === "方块" ? "block" : "normal";
    },
  },
  props: {
    setHeight: {
      type: String,
      default: "600px",
      desc: "轮播图高度",
    },
    interval: {
      type: Number,
      default: 3000000,
      desc: "自动切换的时间间隔，单位为毫秒",
    },
    loop: {
      type: Boolean,
      default: true,
    },
    sourceUrl: {
      type: String,
      default: "",
    },
    indicatorStyle: {
      type: [String, Number],
      default: "方块",
    },
    bannerList: {
      type: Array,
      default: () => {
        return [
          {
            name: "首页",
            title: "首页",
            url: "/",
          },
          {
            name: "课程",
            title: "课程",
            url: "/",
          },
        ];
      },
    },
  },
  data() {
    return {
      idx: 0,
    };
  },
  created() {
    console.log(this.indicatorStyle);
  },
  methods: {
    menuItemTo(idx) {
      this.idx = idx;
    },
  },
});
</script>
