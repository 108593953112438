<template>
  <ul v-if="isDesign" class="ds-nc-book-card">
    <li class="card-book-items">
      <dsf-nc-image />
      <span class="title">健全多层次社会保障体系，让社会保障制度更公平（上）</span>
    </li>
  </ul>
  <ul v-else class="ds-nc-book-card" :title="data['title']" @click="_clickBefore(click)">
    <li class="card-book-items">
      <dsf-nc-image :src="data['cover'] | imgFormat" style="width: 132px; height: 167px" :error-src="defaultSrc" />
      <span class="title ell2">{{ data["title"] }}</span>
    </li>
  </ul>
</template>

<script>
export default dsf.component({
  name: "DsfNcBookCard",
  ctrlCaption: "book卡片",
  mixins: [$mixins.card],
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "title", to: "title", type: "text", desc: "课题" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
  },
});
</script>
