<template>
  <div class="dsf_special_detail_intro" :style="{ display: visible ? 'block' : 'none' }">
    <div class="intro_wrap" v-if="detailData" v-html="detailData"></div>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSpecialDetailIntro",
  ctrlCaption: "专题班详情简介",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/describe",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    itemWidth: {
      type: String,
      default: "1200px",
    },
  },
  data() {
    return {
      detailData: "暂无数据",
      params: {
        id: "",
      },
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "";
  },
  mounted() {
    if (!this.isDesign) {
      this.initData();
    }
  },
  methods: {
    reData() {
      this.initData();
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            // this.error(res);
          } else {
            this.detailData = res.data;
          }
        })
        .error(err => {
          // this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
  },
});
</script>
