var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_exam_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            { staticClass: "item-left-img" },
            [
              _c("dsf-image", { attrs: { src: _vm._f("imgFormat")("") } }),
              _c("span", { staticClass: "item-type" }, [_vm._v("考试")]),
              _c("span", { staticClass: "item-time" }, [
                _vm._v("2012-10-15 ~ 2012-10-25"),
              ]),
            ],
            1
          ),
          _vm._m(0),
          _vm._m(1),
        ]),
      ])
    : _c("div", { staticClass: "dsf_exam_column" }, [
        _c(
          "div",
          {
            staticClass: "item_inner",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm._clickBefore(_vm.click)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "item-left-img" },
              [
                _c("dsf-image", {
                  attrs: {
                    src: _vm._f("imgFormat")(_vm.data.cover),
                    errorSrc: _vm.dsf.config.setting_nc_image_default_img,
                  },
                }),
                _c("span", { staticClass: "item-type" }, [
                  _vm._v(_vm._s(_vm.data.typeText || "考试")),
                ]),
                _c("span", { staticClass: "item-time" }, [
                  _vm._v(
                    _vm._s(_vm.data.start_time) +
                      " ~ " +
                      _vm._s(_vm.data.end_time)
                  ),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "item-right-desc" }, [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "name ellipsis-2" }, [
                  _vm._v(_vm._s(_vm.data.name)),
                ]),
                _vm.data.isTask
                  ? _c("span", { staticClass: "task" }, [_vm._v("任务")])
                  : _vm._e(),
              ]),
              _c("p", { staticClass: "item-exam-rule" }, [
                _c("span", [
                  _vm._v("考试总分: " + _vm._s(_vm.data.score) + "分"),
                ]),
                _c("span", [
                  _vm._v("及格总分: " + _vm._s(_vm.data.pass_score) + "分"),
                ]),
              ]),
              _c("ul", { staticClass: "item-bottom-desc" }, [
                _c("li", [
                  _c("span", [
                    _vm._v(
                      "考试次数: " + _vm._s(_vm.data.surplus_count) + "次"
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [
                    _vm._v(
                      "已考次数: " + _vm._s(_vm.data.exam_count || 0) + "次"
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [
                    _vm._v(
                      "剩余次数: " + _vm._s(_vm.data.eRemainTimes || 0) + "次"
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "rate-wrap" }, [
              _c("span", { staticClass: "rate" }, [
                _vm._v(_vm._s(_vm.data.exam_score || 0) + "分"),
              ]),
              _c("span", { staticClass: "rate-text" }, [_vm._v("最高成绩")]),
            ]),
          ]
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-right-desc" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "name ellipsis-2" }, [_vm._v("标题")]),
        _c("span", { staticClass: "task" }, [_vm._v("任务")]),
      ]),
      _c("p", { staticClass: "item-exam-rule" }, [
        _c("span", [_vm._v("考试总分: 100分")]),
        _c("span", [_vm._v("及格总分: 60分")]),
      ]),
      _c("ul", { staticClass: "item-bottom-desc" }, [
        _c("li", [_c("span", [_vm._v("考试次数: 5次")])]),
        _c("li", [_c("span", [_vm._v("已考次数: 1次")])]),
        _c("li", [_c("span", [_vm._v("剩余次数: 4次")])]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rate-wrap" }, [
      _c("span", { staticClass: "rate" }, [_vm._v("98分")]),
      _c("span", { staticClass: "rate-text" }, [_vm._v("最高成绩")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }