<template>
  <div v-if="isDesign" class="ds-nc-card-course-shop">
    <div class="cover" :style="{ paddingBorrom: imgHeight + 'px' }">
      <dsf-image />
      <div class="time-end">倒计时：2天12时24分6秒</div>
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <!-- <span style="color: #ff3600; font-weight: bold; margin-right: 5px">NEW</span> -->
        <span></span>
      </div>
    </div>
    <div class="footer">
      <span>老师: 张三</span>
      <span>销量: 99</span>
    </div>
    <div class="shop-buy-items">
      <div class="ms">
        <span class="prefix">秒杀</span>
        <span class="c-sale">
          <dsf-icon name="jinbi" />
          8500
        </span>
        <span class="o-sale">1200</span>
      </div>
      <div class="ms">
        <span class="prefix present">赠</span>
        <span class="jf">
          <dsf-icon name="jinbi" />
          10
        </span>
        <span class="dyq">
          <dsf-icon name="quan" />
          20
        </span>
      </div>
    </div>
  </div>
  <div v-else class="ds-nc-card-course-shop" :title="data['name']" @click="_clickBefore(click)" :style="{ width: imgWidth }" :class="{ 'none-cursor': $route.query.isview == 1 }">
    <div class="cover" :style="{ width: imgWidth, paddingBorrom: imgHeight + 'px' }">
      <dsf-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
      <!-- <div class="time-end">倒计时：2天12时24分6秒</div> -->
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <span>{{ data["name"] }}</span>
      </div>
    </div>
    <div class="footer">
      <span class="ellipsis" :title="data['teacherText']">老师: {{ data["teacherText"] ? data["teacherText"].replace(/\^/g, ",") : "" }}</span>
      <span>销量：{{ data["indentNum"] || 0 }}</span>
    </div>
    <div class="shop-buy-items">
      <div class="ms">
        <span class="prefix" v-if="prefixText && getPrefix('prefixText')">{{ prefixText ? getPrefix("prefixText") : "" }}</span>
        <span class="c-sale">
          <dsf-icon name="jinbi" v-if="data['price']" />
          {{ data["price"] || "免费" }}
        </span>
        <span class="o-sale" v-if="data['old_price']">{{ data["old_price"] }}</span>
      </div>
      <div class="ms">
        <span class="prefix present" v-if="data['giftPayment']">赠</span>
        <span class="jf" v-if="data['giftPayment']">
          <dsf-icon name="jinbi" />
          {{ data["giftPayment"] }}
        </span>
        <span class="dyq" v-if="data['giftCoupon']">
          <dsf-icon name="quan" />
          {{ data["giftCoupon"] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcShopCourse",
  ctrlCaption: "商城课程卡片",
  mixins: [$mixins.card],
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "name", to: "name", type: "text", desc: "标题" },
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          {
            from: "timeDuring",
            to: "timeDuring",
            type: "text",
            desc: "倒计时",
          },
          { from: "is_new", to: "isNew", type: "boolean", desc: "标签" },
          {
            from: "teacherText",
            to: "teacherText",
            type: "text",
            desc: "授课教师",
          },
          {
            from: "indentNum",
            to: "indentNum",
            type: "text|number",
            desc: "销量",
          },
          { from: "price", to: "price", type: "text|number", desc: "价格" },
          {
            from: "old_price",
            to: "old_price",
            type: "text|number",
            desc: "原价",
          },
          {
            from: "giftPayment",
            to: "giftPayment",
            type: "text|number",
            desc: "赠送积分",
          },
          {
            from: "giftCoupon",
            to: "giftCoupon",
            type: "text|number",
            desc: "代金券",
          },
          {
            from: "prefixText",
            to: "prefixText",
            type: "text",
            desc: "前缀文字",
            prefix: "秒杀价",
          },
          { from: "id", to: "id", type: "text", desc: "id" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
    imgWidth: {
      type: String,
      default: "",
    },
    imgHeight: {
      type: String,
      default: "",
    },
  },
  created() {
    // this.loadShopCarList();
  },
  methods: {
    click() {
      if (!this.clickConfig) return;
      if (this.$route.query.isview == 1) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
  },
});
</script>
