var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_cardItemByExam" }, [
        _c("div", { staticClass: "content" }, [
          _vm.isDesign
            ? _c("div", { staticClass: "item-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "img-box pointer",
                    style: {
                      width: _vm.imgWidth,
                      height: _vm.imgHeight,
                      margin: _vm.imgMargin,
                    },
                  },
                  [
                    _c("el-image", {
                      attrs: {
                        src: _vm._f("imgFormat")(_vm.src),
                        fit: "cover",
                      },
                    }),
                    _c("p", { staticClass: "examTxt" }, [_vm._v("考试")]),
                    _c("p", { staticClass: "learnTime" }, [
                      _vm._v(
                        _vm._s(_vm.formatterDate("2020.3.4", "yyyy.mm.dd")) +
                          "~" +
                          _vm._s(_vm.formatterDate("2020.3.15", "yyyy.mm.dd"))
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "info",
                    style: { width: "calc(96% - " + _vm.imgWidth + ")" },
                  },
                  [
                    _c("p", { staticClass: "title ell" }, [
                      _vm._v("关于《XXXX》学习的考试"),
                    ]),
                    _vm._m(0),
                  ]
                ),
                _vm._m(1),
              ])
            : _c(
                "div",
                {
                  staticClass: "item-wrap",
                  on: {
                    click: function ($event) {
                      return _vm._clickBefore(_vm.goDetail)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "img-box pointer",
                      style: {
                        width: _vm.imgWidth,
                        height: _vm.imgHeight,
                        margin: _vm.imgMargin,
                      },
                    },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.data["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                      _c("p", { staticClass: "examTxt" }, [_vm._v("考试")]),
                      _vm.data["startTime"]
                        ? _c("p", { staticClass: "learnTime" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatterDate(
                                  _vm.data["startTime"],
                                  "yyyy.mm.dd"
                                )
                              ) +
                                "~" +
                                _vm._s(
                                  _vm.formatterDate(
                                    _vm.data["endTime"],
                                    "yyyy.mm.dd"
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info",
                      style: { width: "calc(96% - " + _vm.imgWidth + ")" },
                    },
                    [
                      _c("p", { staticClass: "title ell pointer" }, [
                        _vm._v(
                          "关于《" +
                            _vm._s(_vm.data["title"] || "") +
                            "》学习的考试"
                        ),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _c("div", { staticClass: "detail-cell-1" }, [
                          _c("span", [
                            _vm._v(
                              "考试总分：" +
                                _vm._s(_vm.data["total"] || 0) +
                                "分"
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "及格分数：" +
                                _vm._s(_vm.data["pass"] || 0) +
                                "分"
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "detail-cell-2" }, [
                          _c("span", [
                            _vm._v(
                              "考试次数：" +
                                _vm._s(
                                  _vm.data["examNum"].includes("无限")
                                    ? _vm.data["examNum"]
                                    : (_vm.data["examNum"] || 0) + "次"
                                )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "已考次数：" +
                                _vm._s(_vm.data["already"] || 0) +
                                "次"
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "剩余次数：" +
                                _vm._s(
                                  _vm.data["residue"].includes("无限")
                                    ? _vm.data["residue"]
                                    : (_vm.data["residue"] || 0) + "次"
                                )
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.data["heightScore"]
                    ? _c("div", { staticClass: "score" }, [
                        _c("p", { staticClass: "score-num" }, [
                          _vm._v(_vm._s(_vm.data["heightScore"] || 0) + "分"),
                        ]),
                        _c("p", { staticClass: "txt" }, [_vm._v("最高成绩")]),
                      ])
                    : _vm._e(),
                ]
              ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail" }, [
      _c("div", { staticClass: "detail-cell-1" }, [
        _c("span", [_vm._v("考试总分：100分")]),
        _c("span", [_vm._v("及格分数：60分")]),
      ]),
      _c("div", { staticClass: "detail-cell-2" }, [
        _c("span", [_vm._v("考试次数：5次")]),
        _c("span", [_vm._v("已考次数：1次")]),
        _c("span", [_vm._v("剩余次数：4次")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "score" }, [
      _c("p", { staticClass: "score-num" }, [_vm._v("78分")]),
      _c("p", { staticClass: "txt" }, [_vm._v("最高成绩")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }