<template>
  <div class="dsf_search_column">
    <div class="column-inner" v-for="item in data" :key="'item' + item.name">
      <newsColumn :key="'newsColumn' + item.id" :data="item" v-if="item.typename == '新闻'" v-on="$listeners" :pageType="pageType" />
      <courseColumn :key="'courseColumn' + item.id" :data="item" v-if="item.typename == '课程'" v-on="$listeners" :pageType="pageType" />
      <specialColumn :key="'specialColumn' + item.id" :data="item" v-if="item.typename == '专题班'" v-on="$listeners" :pageType="pageType" />
      <channelColumn :key="'channelColumn' + item.id" :data="item" v-if="item.typename == '频道'" v-on="$listeners" :pageType="pageType" />
      <teacherColumn :key="'teacherColumn' + item.id" :data="item" v-if="item.typename == '名师'" v-on="$listeners" :pageType="pageType" />
      <examColumn :key="'examColumn' + item.id" :data="item" v-if="item.typename == '考试'" v-on="$listeners" :pageType="pageType" />
      <liveColumn :key="'liveColumn' + item.id" :data="item" v-if="item.typename == '直播'" v-on="$listeners" :pageType="pageType" />
    </div>
  </div>
</template>
<script>
import courseColumn from "./courseColumn.vue";
import specialColumn from "./specialColumn.vue";
import examColumn from "./examColumn.vue";
import newsColumn from "./newsColumn.vue";
import channelColumn from "./channelColumn.vue";
import teacherColumn from "./teacherColumn.vue";
import liveColumn from "./liveColumn.vue";
export default {
  name: "dsfAllSearchColunm",
  ctrlCaption: "检索列表",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageType: {
      type: String,
      default: "tacitly",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item.id);
    },
  },
  components: {
    courseColumn,
    specialColumn,
    examColumn,
    newsColumn,
    channelColumn,
    teacherColumn,
    liveColumn,
  },
};
</script>
