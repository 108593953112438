var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_search_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            { staticClass: "item-left-img" },
            [
              _c("el-image", { attrs: { src: _vm._f("imgFormat")("") } }),
              _c("span", { staticClass: "item-type" }, [_vm._v("专题班")]),
              _c("span", { staticClass: "item-time" }, [
                _vm._v("2012-10-20 ~ 2012-10-25"),
              ]),
            ],
            1
          ),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "circle-wrap" },
            [
              _c("el-progress", {
                staticClass: "progress-circle",
                attrs: {
                  width: "60",
                  "stroke-width": "5",
                  color: _vm.curColor,
                  type: "circle",
                  percentage: 33,
                },
              }),
              _c("p", [_vm._v("当前进度")]),
            ],
            1
          ),
        ]),
      ])
    : _c("div", { staticClass: "dsf_search_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            {
              staticClass: "item-left-img",
              on: {
                click: function ($event) {
                  return _vm.toDetail(_vm.data)
                },
              },
            },
            [
              _c("dsf-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.dsf.config.setting_nc_image_default_img,
                },
              }),
              _c("span", { staticClass: "item-type" }, [
                _vm._v(_vm._s(_vm.data.typeText || "专题班")),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "item-right-desc" }, [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "name ellipsis-2" }, [
                _vm._v(_vm._s(_vm.data.title)),
              ]),
              _vm.data.isTask
                ? _c("span", { staticClass: "task" }, [_vm._v("任务")])
                : _vm._e(),
            ]),
            _vm.pageType == "inland"
              ? _c("p", { staticClass: "ds-introduction" }, [
                  _vm._v(
                    " 以习近平新时代中国特色社会主义思想为指导，深入学习贯彻党的十九大和十九届二中、三中、四中、五中、六中全会精神，持续开展党史学习教育，牢固树立“四个意识”，坚定“四个自信”，做到“两个维护”，准确把握全面从严治党工作要求和税务系统党的建设工作规范以及意识形态、政务公开、保密管理、舆情应对等工作的新形势和新要求，熟练掌握办公室（党委办公室）工作基本制度、基本流程，切实提升组织、沟通、协调、执行、写作等办文办事办会基本能力，更好履行办公室工作职责，服务学院教育培训事业高质量发展。 "
                  ),
                ])
              : _vm._e(),
            _vm.data.pxrq_sdate
              ? _c("p", { staticClass: "item-teacher-name" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.data.pxrq_sdate
                          ? _vm.dsf.date.format(
                              _vm.data.pxrq_sdate,
                              "yyyy-mm-dd"
                            )
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" ~ "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.data.pxrq_edate
                          ? _vm.dsf.date.format(
                              _vm.data.pxrq_edate,
                              "yyyy-mm-dd"
                            )
                          : ""
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-right-desc" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "name ellipsis-2" }, [
          _vm._v("我是一个标题"),
        ]),
        _c("span", { staticClass: "task" }, [_vm._v("任务")]),
      ]),
      _c("p", { staticClass: "item-teacher-name" }, [
        _c("span", [_vm._v("张三")]),
        _c("span", [_vm._v("项目经理")]),
      ]),
      _c("ul", { staticClass: "item-bottom-desc" }, [
        _c("li", [
          _c("span", { staticClass: "desc-span" }, [_vm._v("必修")]),
          _vm._v(" 10门课程 "),
        ]),
        _c("li", [
          _c("span", { staticClass: "desc-span" }, [_vm._v("选修")]),
          _vm._v(" 5门课程 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }