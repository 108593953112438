var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_ncpc_subscribe_dynamic",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "intro_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.showMore && _vm.moreStyle == "tb"
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm.showMore && _vm.moreStyle == "wz"
            ? _c(
                "span",
                {
                  staticClass: "more_font",
                  on: {
                    click: function ($event) {
                      !!_vm.showMore && _vm.clickMore()
                    },
                  },
                },
                [_vm._v("更多")]
              )
            : _vm._e(),
        ]),
        _vm.showLine ? _c("span", { staticClass: "line" }) : _vm._e(),
      ]),
      _vm.lists.length
        ? _c(
            "ul",
            { staticClass: "subscribe_dynamic_wrap" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "li",
                { key: index, staticClass: "subscribe_dynamic_items" },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c(
                        "el-avatar",
                        {
                          staticClass: "photo",
                          attrs: {
                            src:
                              "/oua2/getUserPhoto?type=relativePath&userId=" +
                              item.userId,
                          },
                          on: { error: _vm.errorHandler },
                        },
                        [
                          _c("dsf-nc-image", {
                            staticClass: "avatar",
                            attrs: { src: "", "error-src": _vm.defaultAvatar },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.userName || "")),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "date" }, [
                    _vm._v(_vm._s(item.subscribeDateTime || "暂无") + "  订阅"),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("div", {
            staticClass: "ds-empty-data",
            domProps: { textContent: _vm._s("暂无数据") },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }