var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_mine ds-control", style: { width: _vm.itemWidth } },
    [
      _vm.it
        ? _c("div", { staticClass: "minecontent" }, [
            _c("div", { staticClass: "yeartitle" }, [
              _c("span", [_vm._v(_vm._s(_vm.it.learnYear || "") + "年度学时")]),
              _c("span", { staticClass: "people" }, [
                _vm._v("超过" + _vm._s(_vm.it.rankRate || 0) + "%同学"),
              ]),
            ]),
            _c("div", { staticClass: "speed" }, [
              _c("div", { staticClass: "sleft" }, [
                _c("span"),
                _c("i", [_vm._v(_vm._s(_vm.it.hoursRate || 0) + "%")]),
              ]),
              _c("div", { staticClass: "sright" }, [
                _vm._v(
                  _vm._s(_vm.it.learnedHours || 0) +
                    "/" +
                    _vm._s(_vm.it.learnHours) +
                    "h"
                ),
              ]),
            ]),
            _c("div", { staticClass: "speedbg" }, [
              _c("span", { style: { width: _vm.it.hoursRate + "%" } }),
            ]),
            _c("div", { staticClass: "choose" }, [
              _c("p", [
                _c("span", [_vm._v(_vm._s(_vm.courseText))]),
                _c("span", [_vm._v(_vm._s(_vm.it.courseNum || 0) + "门")]),
              ]),
              _c("p", [
                _c("span", [_vm._v(_vm._s(_vm.specialText))]),
                _c("span", [_vm._v(_vm._s(_vm.it.classNum || 0) + "门")]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.isDesign ? _c("div", [_vm._v("我的")]) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }