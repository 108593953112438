var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-invitation" }, [
    _c("div", { staticClass: "user-invitation-inner" }, [
      _vm._m(0),
      _c("div", { staticClass: "invitation-info" }, [
        _c(
          "div",
          { staticClass: "invitation-image" },
          [_c("DsfNcImage", { attrs: { src: _vm.qrcodeurl } })],
          1
        ),
        _c("div", { staticClass: "invitation-text-wrap" }, [
          _c("p", { staticClass: "text-share" }, [
            _vm._v("分享二维码邀请更多成员加入"),
          ]),
          _c("p", { staticClass: "text-share text-share-small" }, [
            _vm._v("被邀用户扫码后会按照您设置的审核流程与注册信息进行注册"),
          ]),
          _c(
            "div",
            { staticClass: "invitation-day-select" },
            [
              _c("span", { staticClass: "text" }, [_vm._v("有效期 (天)：")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  on: { change: _vm.changeDate },
                  model: {
                    value: _vm.form.validtime_value,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "validtime_value", $$v)
                    },
                    expression: "form.validtime_value",
                  },
                },
                _vm._l(_vm.daysOpts, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "invitation-downcode",
              on: { click: _vm.doencodeFn },
            },
            [
              _c("dsf-icon", { attrs: { name: "yunduanxiazai" } }),
              _vm._v(" 下载二维码 "),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "invitation-item" }, [
        _c("h1", { staticClass: "invitation-title" }, [_vm._v("审核流程设置")]),
        _c("div", { staticClass: "invitation-item-content" }, [
          _c(
            "div",
            { staticClass: "content-switch" },
            [
              _c("span", [_vm._v("注册审核:")]),
              _c("el-switch", {
                model: {
                  value: _vm.form.isaudit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isaudit", $$v)
                  },
                  expression: "form.isaudit",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "invitation-item" }, [
        _c("h1", { staticClass: "invitation-title" }, [_vm._v("注册信息设置")]),
        _c("div", { staticClass: "invitation-item-content" }, [
          _c(
            "div",
            { staticClass: "content-register-setting" },
            [
              _c("userInvitationForm", {
                ref: "formdata",
                attrs: {
                  btnsShow: false,
                  isRequest: false,
                  defaultList: _vm.defaultList,
                },
                on: { updateParams: _vm.updateParams },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "dsf_apply-btns" },
        [
          _c(
            "el-button",
            { staticClass: "sumbit-btn", on: { click: _vm.sumbitForm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "invitation-breadcrumb" }, [
      _c("cite", [_vm._v("用户邀请")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }