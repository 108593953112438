var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds_recommendNews", style: _vm.controlStyle },
    [
      _vm._m(0),
      _vm.news.length
        ? _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.news, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "ds_recommendNews-row ell",
                  style: _vm.rowStyle,
                  on: {
                    click: function ($event) {
                      return _vm._clickBefore(_vm.goDetail, item)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "number", class: { isRed: index < 3 } },
                    [_vm._v(_vm._s(index + 1))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "ell",
                      attrs: { title: item[_vm.keyField.title] },
                    },
                    [_vm._v(_vm._s(item[_vm.keyField.title]))]
                  ),
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "noDate" }, [_vm._v("暂无数据")]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module_header" }, [
      _c("h6", {}, [_vm._v("相关推荐")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }