var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specialdetail_social_todybest",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "social_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.showMore
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm._e(),
        ]),
        _c("span", { staticClass: "line" }),
      ]),
      _c(
        "div",
        { staticClass: "todybest_wrap" },
        [
          _c(
            "el-image",
            {
              staticClass: "head_img",
              attrs: {
                src: _vm.setImg(""),
                width: "80px",
                height: "80px",
                fit: "cover",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [
                  _c("img", {
                    staticStyle: { width: "80px", height: "80px" },
                    attrs: { src: _vm.dsf.config.setting_error_img, alt: "" },
                  }),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "name" }, [_vm._v("张三")]),
          _c("div", { staticClass: "phone" }, [_vm._v("13162123382")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }