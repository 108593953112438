<template>
  <div class="ds-nc-shopping-car ds-nc-shopping-order">
    <div class="shopping-head">
      <div class="shopping-head-inner">
        <h3 class="name">{{ orderText }}</h3>
        <h4 class="sub-text" v-if="!readonly">商品已提交至【我的订单】，状态为待付款，若想重新加购商品，请于商品详情处重新加购商品。</h4>
      </div>
    </div>
    <div class="ds-nc-shopping-container order-posi order-padding-bottom" :style="{ minHeight: minHeight + 'px' }">
      <shoppingOrderItem :list="orderList" @toCheckOut="toCheckOut" :shoppingCarUrl="shoppingCarUrl" :userCoins="coins" :readonly="readonly" />
    </div>
  </div>
</template>

<script>
const ORDERINFO = "/nc/shop/shopIndent/myIndentDetail",
  PAYORDERMONEY = "/nc/shop/shopPayment/commitPayment",
  USERCOINS = "/nc/shop/shopIndent/getMyCoins";
import shoppingOrderItem from "../../base/shop/shoppingOrderItem.vue";
export default dsf.component({
  name: "DsfNcshopOrders",
  ctrlCaption: "商城确认订单",
  mixins: [$mixins.card],
  components: {
    shoppingOrderItem,
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      searchWord: "",
      activeName: "first",
      orderList: [],
      id: this.$route.query.id,
      coins: 0,
      readonly: this.$route.query.isView == 1,
      orderText: this.$route.query.isView == 1 ? "订单详情" : "结算订单",
      allowSubmit: true,
    };
  },
  props: {
    imgWidth: {
      type: String,
      default: "",
    },
    imgHeight: {
      type: String,
      default: "",
    },
    shoppingCarUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shoppingcars",
    },
    historyOrderUrl: {
      type: String,
      default: "/ncIndex.html#/pc/nc/pagepersonalCenter/index?active=order",
    },
  },
  computed: {
    minHeight() {
      let _wh = window.innerHeight;
      return _wh - 170;
    },
  },
  mounted() {
    this.loadOrderInfo();
    this.loadUserCoins();
  },
  methods: {
    loadOrderInfo() {
      if (!this.id) return;
      const loading = dsf.layer.loading();
      let params = {
        indentId: this.id,
      };
      this.dsf.http
        .get(ORDERINFO, params, $$webRoot.nc)
        .done(({ success, data, state, message }) => {
          if (success) {
            this.orderList = data;
          } else {
            if (state == 65006) {
              dsf.layer.message("当前商品已在购物车", true);
            } else {
              dsf.layer.message(message || "订单信息异常", false);
            }
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "订单信息异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    loadUserCoins() {
      const loading = dsf.layer.loading();
      this.dsf.http
        .get(USERCOINS, {}, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.coins = data;
          } else {
            dsf.layer.message(message || "获取用户支付币失败", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取用户支付币异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    toCheckOut() {
      if (!this.id) return;
      if (this.allowSubmit) {
        const loading = dsf.layer.loading();
        let params = {
          shopIndentId: this.id,
        };
        this.allowSubmit = false;
        this.dsf.http
          .post(PAYORDERMONEY, params, $$webRoot.nc)
          .done(({ success, data, state, message }) => {
            if (success) {
              dsf.layer.message(message || "支付成功", true);
              let timer = setTimeout(() => {
                this.historyOrderUrl && dsf.layer.openWindow(this.historyOrderUrl);
                window.close();
                clearTimeout(timer);
              }, 1500);
            } else {
              dsf.layer.message(message || "支付异常", false);
              this.allowSubmit = true;
            }
          })
          .error(err => {
            this.allowSubmit = true;
            dsf.layer.message(err.message || "支付异常", false);
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      }
    },
  },
});
</script>
