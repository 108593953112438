var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_course_poylv_player", style: { width: _vm.itemWidth } },
    [
      _c(
        "div",
        {
          staticClass: "course-player-inner",
          style: { height: _vm.docHeight },
        },
        [
          _c("div", { staticClass: "course-player-left" }, [
            _c("div", { staticClass: "course-header-left" }, [
              _c("div", { staticClass: "course-header-left-wrap" }, [
                _vm.chapterInfo
                  ? _c(
                      "span",
                      {
                        staticClass: "course-header-left-title ellipsis",
                        attrs: { title: _vm.chapterInfo.title || "" },
                      },
                      [_vm._v(_vm._s(_vm.chapterInfo.title || ""))]
                    )
                  : _vm._e(),
                _vm.curItemType !== "document" && _vm.curItemType !== "link"
                  ? _c("div", { staticClass: "course-header-left-buttons" }, [
                      _c(
                        "button",
                        {
                          class: {
                            "course-header-left-buttons-sel": _vm.playPattern,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changePattern(true)
                            },
                          },
                        },
                        [_vm._v("视频模式")]
                      ),
                      _c(
                        "button",
                        {
                          class: {
                            "course-header-left-buttons-sel": !_vm.playPattern,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changePattern(false)
                            },
                          },
                        },
                        [_vm._v("讲义模式")]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "course-header-left-event" }, [
                _c(
                  "span",
                  {
                    class: [_vm.isAssessed ? "active-item" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.handleComment()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-pingjia2" }),
                    _vm._v(" 评价 "),
                  ]
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handleCollect()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-shoucang2" }),
                    _vm._v(
                      " " + _vm._s(_vm.hasCollected ? "已收藏" : "收藏") + " "
                    ),
                  ]
                ),
                _c("span", { on: { click: _vm.joinStudy } }, [
                  _c("i", { staticClass: "iconfont icon-add" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.hasJoin ? "取消学习计划" : "加入学习计划") +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "course-player-pattern" }, [
              _vm.curPlayItem &&
              _vm.curItemType !== "document" &&
              _vm.curItemType !== "link"
                ? _c(
                    "div",
                    {
                      ref: "dragItem",
                      staticClass: "course-player-drag",
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                          return _vm.playerMousedown("dragItem", $event)
                        },
                        mousemove: function ($event) {
                          $event.stopPropagation()
                          return _vm.playerMousemove("dragItem", $event)
                        },
                        mouseup: function ($event) {
                          $event.stopPropagation()
                          return _vm.playerMouseup("dragItem", $event)
                        },
                      },
                    },
                    [
                      _vm.curPlayItem
                        ? _c("dsfNcPoylvPlayer", {
                            ref: "coursePlayer",
                            attrs: { videoSource: _vm.videoSrc },
                            on: {
                              playerHasPlay: function ($event) {
                                return _vm.playerHasPlay()
                              },
                              playerHasPause: function ($event) {
                                return _vm.playerHasPause()
                              },
                              playHasEnd: _vm.playEnd,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.curPlayItem && _vm.docUrl && _vm.curItemType !== "link"
                ? _c("div", { staticClass: "course-document-pattern" }, [
                    _c("iframe", {
                      attrs: {
                        src: _vm.docUrl,
                        frameborder: "0",
                        scrolling: "no",
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { ref: "rightBox", staticClass: "course-player-right" }, [
            _c(
              "div",
              {
                staticClass: "course-toggle-bar-wrap",
                on: {
                  click: function ($event) {
                    return _vm.toggle()
                  },
                },
              },
              [_vm._m(0)]
            ),
            _c("div", { staticClass: "right-box-inner" }, [
              _c("div", { staticClass: "right-header-wrap" }, [
                !_vm.dsf.isEmptyObject(_vm.baseInfo) &&
                _vm.baseInfo.teacherList &&
                _vm.baseInfo.teacherList.length > 0
                  ? _c("div", { staticClass: "right-header" }, [
                      _c(
                        "div",
                        {
                          staticClass: "teacher",
                          on: {
                            click: function ($event) {
                              return _vm.toTeacherDetail(
                                _vm.baseInfo.teacherList[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar" },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: _vm._f("imgFormat")(
                                    _vm.baseInfo.teacherList[0].photo
                                  ),
                                  fit: "cover",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "desc" }, [
                            _c("h3", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.baseInfo.teacherList[0].name)),
                            ]),
                            _c("div", { staticClass: "dept ellipsis-2" }, [
                              _vm._v(
                                _vm._s(_vm.baseInfo.teacherList[0].unitJob)
                              ),
                            ]),
                            _c("span", { staticClass: "total" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.baseInfo.teacherList[0].courseNum || 0
                                ) + "门精品课程"
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "right-tab-items" }, [
                _c("div", { staticClass: "tab-menus-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "tab-menus" },
                    _vm._l(_vm.tabList, function (item, $index) {
                      return _c(
                        "span",
                        {
                          key: item.id,
                          class: [
                            "tab-menus-name",
                            _vm.getCurTabSelMenu($index),
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.tabMenuChange($index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "tab-content",
                    style: "height:" + (_vm.winH - 220) + "px",
                  },
                  [
                    _vm.tabIdx == 0
                      ? _c(
                          "div",
                          { staticClass: "content-item" },
                          [
                            _c("dsf-nc-course-chapter", {
                              attrs: { chapters: _vm.chapterInfo },
                              on: {
                                changeCurPlayCourse: _vm.changeCurPlayCourse,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.tabIdx == 1
                      ? _c("div", { staticClass: "content-item" }, [
                          _vm.baseInfo
                            ? _c("div", {
                                staticClass: "introduce",
                                domProps: {
                                  innerHTML: _vm._s(_vm.baseInfo.content || ""),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.tabIdx == 2
                      ? _c(
                          "div",
                          { staticClass: "content-item" },
                          [
                            _c("dsf-nc-note-item", {
                              attrs: {
                                curItem: _vm.getCurPlayItem,
                                relationId: _vm.queryiId,
                                queryiType: _vm.queryiType,
                                item: _vm.baseInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticStyle: { height: "200px" } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toggle-bar" }, [_c("i", [_vm._v("||")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }