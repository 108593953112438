<template>
  <div v-if="isDesign" class="dsf-nc-spacil-card">
    <div class="cover dsf-nc-image">
      <dsf-nc-image :error-src="defaultSrc" />
      <div class="info">
        <div>
          <div class="title ellipsis-2">深入学习贯彻习近平新时代中国特色社会主义思想</div>
          <div class="hours">
            <span>{{ getPrefix("compulsoryHours") }}20</span>
            <span>{{ getPrefix("electivesHours") }}58.5</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="date">2019.07.24 ～ 2019.08.27</div>
    </div>
    <div class="footer">
      <span style="color: #d6310b">报名中</span>
      <span>
        <i class="iconfont icon-wodexuanzhong"></i>
        <span>1234</span>
      </span>
    </div>
  </div>
  <div v-else-if="objData" class="dsf-nc-spacil-card" :title="objData['title']" @click="_clickBefore(click)">
    <div class="cover dsf-nc-image">
      <dsf-nc-image :src="objData['nc_class_page_mainData.cover'] | imgFormat" :error-src="defaultSrc" />
      <div class="info">
        <div>
          <div class="title ellipsis-2">
            {{ objData["nc_class_page_mainData.name"] }}
          </div>
          <div class="hours">
            <span>必修学时:{{ objData["nc_class_page_mainData.compulsoryPeriod"] }}</span>
            <span>选修学时:{{ objData["nc_class_page_mainData.electivesPeriod"] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="date">{{ objData["nc_class_page_mainData.pxsj_sdate"] }} ～ {{ objData["nc_class_page_mainData.pxsj_edate"] }}</div>
    </div>
    <div class="footer">
      <span v-if="objData['nc_class_page_mainData.current_state_value'] == 1" style="color: #d6310b">{{ objData["nc_class_page_mainData.current_state_text"] }}</span>
      <span v-else>{{ objData["nc_class_page_mainData.current_state_text"] }}</span>
      <span>
        <i class="iconfont icon-wodexuanzhong" style="font-size: 0.9em"></i>
        {{ objData["nc_class_page_mainData.studentcount"] }}
      </span>
    </div>
  </div>
  <div v-else class="dsf-nc-spacil-card" :title="data['title']" @click="_clickBefore(click)">
    <div class="cover dsf-nc-image">
      <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
      <div class="info">
        <div>
          <div class="title ellipsis-2">
            {{ data["title"] }}
          </div>
          <div class="hours">
            <span>{{ getPrefix("compulsoryHours") }}{{ data["compulsoryHours"] }}</span>
            <span>{{ getPrefix("electivesHours") }}{{ data["electivesHours"] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="date">{{ data["sDate"] }} ～ {{ data["eDate"] }}</div>
    </div>
    <div class="footer">
      <span v-if="data['stateValue'] == 1" style="color: #d6310b">{{ data["stateText"] }}</span>
      <span v-else>{{ data["stateText"] }}</span>
      <span>
        <i class="iconfont icon-wodexuanzhong" style="font-size: 0.9em"></i>
        {{ data["views"] }}
      </span>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSpecilaCard",
  ctrlCaption: "专题培训卡片",
  mixins: [$mixins.card],
  design: {
    isMask: false,
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "nc_class_page_mainData.cover", to: "cover", type: "text", desc: "封面" },
          { from: "nc_class_page_mainData.name", to: "title", type: "text", desc: "课题" },
          { from: "nc_class_page_mainData.pxsj_sdate", to: "sDate", type: "text", desc: "开始时间" },
          { from: "nc_class_page_mainData.pxsj_edate", to: "eDate", type: "text", desc: "结束时间" },
          { from: "nc_class_page_mainData.compulsoryperiod", to: "compulsoryHours", type: "text|number", desc: "必修学时", prefix: "必修学时：" },
          { from: "nc_class_page_mainData.electivesperiod", to: "electivesHours", type: "text|number", desc: "选修学时", prefix: "选修学时：" },
          { from: "nc_class_page_mainData.current_state_value", to: "stateValue", type: "text|number", desc: "状态值" },
          { from: "nc_class_page_mainData.current_state_text", to: "stateText", type: "text", desc: "状态文字" },
          { from: "nc_class_page_mainData.studentcount", to: "views", type: "text|number", desc: "浏览量" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
    objData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
  },
});
</script>
