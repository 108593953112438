(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vuex"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define("nc", ["vuex", "vue"], factory);
	else if(typeof exports === 'object')
		exports["nc"] = factory(require("vuex"), require("vue"));
	else
		root["nc"] = factory(root["Vuex"], root["Vue"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__599__) {
return 