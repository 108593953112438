var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds-nc-teacher-card" }, [
        _c(
          "div",
          { staticClass: "avatar-wrap" },
          [
            _c("dsf-nc-image", {
              staticClass: "avatar",
              attrs: { "error-src": _vm.defaultAvatar },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _vm._m(0),
          _c("div", { staticClass: "unit ell" }, [
            _vm._v("中央党校（国家行政学院）"),
          ]),
          _c("div", { staticClass: "course" }, [_vm._v("18门精品课程 >")]),
          _c("div", { staticClass: "foot" }, [
            _c(
              "div",
              { staticClass: "score_box" },
              [
                _c("dsf-icon", { attrs: { name: "pingjia2" } }),
                _c("span", [_vm._v("2.7")]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "subscribe" },
              [
                _c("dsf-icon", { attrs: { name: "iconfontzhizuobiaozhun13" } }),
                _c("span", [_vm._v("7048")]),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm.objData
    ? _c(
        "div",
        {
          staticClass: "ds-nc-teacher-card",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm._clickBefore(_vm.courseClick)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "avatar-wrap" },
            [
              _c("dsf-nc-image", {
                staticClass: "avatar",
                attrs: {
                  src: _vm._f("imgFormat")(
                    _vm.objData["nc_teacher_data_mainTeacherData.photo"]
                  ),
                  "error-src": _vm.defaultAvatar,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "name_box" }, [
              _c(
                "span",
                {
                  staticClass: "name",
                  attrs: {
                    title: _vm.objData["nc_teacher_data_mainTeacherData.name"],
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.objData["nc_teacher_data_mainTeacherData.name"] || ""
                    )
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "position",
                  attrs: {
                    title:
                      _vm.objData["nc_teacher_data_mainTeacherData.position"],
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.objData["nc_teacher_data_mainTeacherData.position"] ||
                        ""
                    )
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "unit ell",
                attrs: {
                  title:
                    _vm.objData["nc_teacher_data_mainTeacherData.dept_text"],
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.objData["nc_teacher_data_mainTeacherData.dept_text"] ||
                      ""
                  )
                ),
              ]
            ),
            _c("div", { staticClass: "course" }, [
              _vm._v(
                _vm._s(
                  _vm.objData["nc_teacher_data_mainTeacherData.course_count"]
                ) +
                  _vm._s(
                    _vm.objData["nc_teacher_data_mainTeacherData.course_count"]
                  ) +
                  " >"
              ),
            ]),
            _c("div", { staticClass: "foot" }, [
              _c(
                "div",
                { staticClass: "score_box" },
                [
                  _c("dsf-icon", { attrs: { name: "pingjia2" } }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.objData["nc_teacher_data_mainTeacherData.score"] ||
                          0
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "subscribe" },
                [
                  _c("dsf-icon", {
                    attrs: { name: "iconfontzhizuobiaozhun13" },
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.objData[
                          "nc_teacher_data_mainTeacherData.subscribed_num"
                        ] || 0
                      )
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "ds-nc-teacher-card",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm._clickBefore(_vm.courseClick)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "avatar-wrap" },
            [
              _c("dsf-nc-image", {
                staticClass: "avatar",
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["avatar"]),
                  "error-src": _vm.defaultAvatar,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "name_box ellipsis-2" }, [
              _c(
                "span",
                { staticClass: "name", attrs: { title: _vm.data["name"] } },
                [_vm._v(_vm._s(_vm.data["name"] || ""))]
              ),
              _c(
                "span",
                {
                  staticClass: "position",
                  attrs: { title: _vm.data["position"] },
                },
                [_vm._v(_vm._s(_vm.data["position"] || ""))]
              ),
            ]),
            _c(
              "div",
              { staticClass: "unit ell", attrs: { title: _vm.data["unit"] } },
              [_vm._v(_vm._s(_vm.data["unit"] || ""))]
            ),
            _c("div", { staticClass: "course" }, [
              _vm._v(
                _vm._s(_vm.data["courseNum"]) +
                  _vm._s(_vm.getSuffix("courseNum")) +
                  " >"
              ),
            ]),
            _c("div", { staticClass: "foot" }, [
              _c(
                "div",
                { staticClass: "score_box" },
                [
                  _c("dsf-icon", { attrs: { name: "pingjia2" } }),
                  _c("span", [_vm._v(_vm._s(_vm.data["score"] || 0))]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "subscribe" },
                [
                  _c("dsf-icon", {
                    attrs: { name: "iconfontzhizuobiaozhun13" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.data["subscribed_num"] || 0))]),
                ],
                1
              ),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "name_box" }, [
      _c("span", { staticClass: "name" }, [_vm._v("达娴滢")]),
      _c("span", { staticClass: "position" }, [_vm._v("教授、博士生导师")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }