var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_poylvplayer" }, [
    _c("div", { staticClass: "poylvplayer-inner" }, [
      _c("div", {
        ref: "coursePlayer",
        staticClass: "poylvplayer",
        attrs: { id: "poylvplayer" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }