var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_note_item" }, [
    _c(
      "div",
      { staticClass: "dsf_nc_note_item_inner" },
      [
        _c(
          "el-form",
          {
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "el-form-item",
              { staticClass: "note-text" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: _vm.setPlaceholder,
                    maxlength: "1000",
                    "show-word-limit": "",
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.handleScreenshot()
                    },
                    input: _vm.handleTextInput,
                  },
                  model: {
                    value: _vm.form.desc,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "desc", $$v)
                    },
                    expression: "form.desc",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "note-handle" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "note-handle-item",
                    attrs: { label: "公开", width: "40" },
                  },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.form.public,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "public", $$v)
                        },
                        expression: "form.public",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "note-save",
                    class: { "no-text": _vm.isDisabled },
                    style: { width: _vm.btnWidth },
                    attrs: { disabled: _vm.isDisabled },
                    on: { click: _vm.saveNote },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "note-list-wrap" }, [
          _c(
            "div",
            { ref: "noteList", staticClass: "note-list" },
            [
              _c("div", { staticClass: "note-head" }, [
                _c("span", [_vm._v("历史笔记")]),
                _c(
                  "div",
                  { staticClass: "note-list-class" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.filterNote },
                        model: {
                          value: _vm.form.showType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "showType", $$v)
                          },
                          expression: "form.showType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "我的" } }),
                        _c("el-radio", { attrs: { label: "全部" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.noteList.length > 0
                ? _vm._l(_vm.noteList, function (item, index) {
                    return _c(
                      "div",
                      { key: item._id, staticClass: "note-list-item" },
                      [
                        _c("p", { staticClass: "note-list-item-top" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getPrefixText(item)) +
                                ": " +
                                _vm._s(
                                  item[
                                    "nc_learn_note_resourcelistsource.node_time"
                                  ] || ""
                                )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item[
                                  "nc_learn_note_resourcelistsource.r_time"
                                ] || ""
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "note-list-item-desc" }, [
                          _c(
                            "div",
                            { staticClass: "note-img" },
                            [
                              _c("dsf-image", {
                                attrs: {
                                  src: _vm._f("imgFormat")(
                                    item[
                                      "nc_learn_note_resourcelistsource.node_picture"
                                    ]
                                  ),
                                  errorSrc:
                                    _vm.dsf.config.setting_nc_image_default_img,
                                  fit: "cover",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "note-info" },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  { style: { color: _vm.fontColor } },
                                  [
                                    _c("dsf-icon", {
                                      attrs: { name: "person" },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item[
                                            "nc_learn_note_resourcelistsource.user_name"
                                          ]
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", [
                                  item[
                                    "nc_learn_note_resourcelistsource.is_own"
                                  ] == 1
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateItem(index)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  item[
                                    "nc_learn_note_resourcelistsource.is_own"
                                  ] == 1
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.delNote(item)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              item.readOnly
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "node-text",
                                      style: { color: _vm.fontColor },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item[
                                              "nc_learn_note_resourcelistsource.node_words"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c("el-input", {
                                    ref: "noteText",
                                    refInFor: true,
                                    staticClass: "node-text node-text-area",
                                    attrs: { type: "textarea" },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.saveNote(index, item)
                                      },
                                    },
                                    model: {
                                      value: _vm.tempText,
                                      callback: function ($$v) {
                                        _vm.tempText = $$v
                                      },
                                      expression: "tempText",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  })
                : _c("div", { staticClass: "no-data no-data-note" }, [
                    _c("i", { staticClass: "no-data-icon" }),
                    _c("p", [_vm._v("暂无笔记")]),
                  ]),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }