<!--  -->
<template>
  <div class="dsf_ncpc_task">
    <div class="task_title">我的任务</div>
    <div class="search_box_container">
      <el-form class="search_box" ref="searchForm" :model="searchForm" label-width="42px">
        <el-form-item label="名称：" prop="taskName">
          <el-input class="search_input" v-model="searchForm.taskName"></el-input>
        </el-form-item>
        <el-form-item label="时间：" class="timer_box" prop="time">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="searchForm.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="类型：" prop="taskType">
          <el-select class="search_input" v-model="searchForm.taskType" placeholder="请选择">
            <el-option v-for="item in optionsType" :key="item.value" :label="item.text" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dsf_btns">
          <el-button class="submit_btn" @click="submitForm('searchForm')">查询</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="task_comtainer">
      <div class="title">待办</div>
      <ul class="task_wrap" v-if="taskTodolist.length">
        <li class="task_items" v-for="(item, index) in taskTodolist" :key="index" @click="handleClick(item)">
          <div class="task_left">
            <div class="task_name" v-html="setTaskType(item)"></div>
            <div class="task_title ell">任务：{{ item.task_name }}</div>
            <div class="task_date">周期：{{ setDate(item.task_cycle_sdate, item.task_cycle_edate, "至") }}</div>
            <div class="task_time" v-show="item.countdown">倒计时：{{ item.countdown }}</div>
          </div>
          <div class="task_right">
            <el-progress
              v-if="item.complete_rate > 0 || item.task_state_value == 2"
              type="circle"
              :percentage="item.complete_rate == 0 ? 0 : item.complete_rate"
              :width="84"
            ></el-progress>
            <div class="no_start" v-else>{{ item.task_state_text }}</div>
          </div>
        </li>
      </ul>
      <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
    </div>
    <div class="task_comtainer">
      <div class="title">已办</div>
      <ul class="task_wrap" v-if="taskDonelist.length">
        <li class="task_items" v-for="(item, index) in taskDonelist" :key="index" @click="handleClick(item)">
          <div class="task_left">
            <div class="task_name" v-html="setTaskType(item)"></div>
            <div class="task_title ell">任务：{{ item.task_name }}</div>
            <div class="task_date">周期：{{ setDate(item.task_cycle_sdate, item.task_cycle_edate, "至") }}</div>
            <div class="task_time" v-show="item.countdown">倒计时：{{ item.countdown }}</div>
          </div>
          <div class="task_right">
            <el-progress type="circle" :percentage="item.complete_rate == 0 ? 0 : item.complete_rate" :width="84"></el-progress>
          </div>
        </li>
      </ul>
      <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcTask",
  ctrlCaption: "我的任务",
  mixins: [$mixins.control],
  props: {},
  data() {
    return {
      searchForm: {
        taskName: "",
        time: "",
        taskType: "",
        strtDate: "",
        endDate: "",
        taskStatus: "", //1待办，2已办 不传查所有
      },
      taskData: {},
      taskTodolist: [
        {
          task_name: "1106学时任务请完成1学时", //任务名称
          complete_rate: "42.86", //进度
          task_type_text: "学时任务",
          task_type_value: "3",
          task_cycle_edate: "2021-11-13 23:59:59",
          task_state_text: "进行中",
          task_state_value: 2,
          task_cycle_sdate: "2021-11-05 00:00:00",
          countdown: "1天",
        },
        {
          task_name: "请完成23学时",
          complete_rate: "0",
          task_type_text: "学时任务",
          task_type_value: "3",
          task_cycle_edate: "2021-11-27 23:59:59",
          task_state_text: "进行中",
          task_state_value: 2,
          task_cycle_sdate: "2021-11-03 00:00:00",
        },
        {
          task_name: "请完成音频20210720001,其他20210720001,新增视频包含讲义20210720001,课程考核调试101课程",
          complete_rate: "0",
          task_type_text: "课程任务",
          task_type_value: "1",
          task_cycle_edate: "2021-11-13 23:59:59",
          task_state_text: "进行中",
          task_state_value: 2,
          task_cycle_sdate: "2021-11-05 00:00:00",
        },
      ],
      taskDonelist: [],
      optionsType: [
        {
          text: "学时",
          value: "3",
        },
        {
          text: "课程",
          value: "1",
        },
        {
          text: "考试",
          value: "2",
        },
      ],
    };
  },
  components: {},
  mounted() {
    if (!this.isDesign) {
      this.taskTodolist = [];
      this.renderData();
    }
  },
  methods: {
    renderData() {
      const loading = dsf.layer.loading();
      const sourceUrl = "/nc/studytask/getTaskListByUser";
      this.dsf.http
        .get(sourceUrl, this.searchForm, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.taskData = data;
            this.taskTodolist = data.todolist || [];
            this.taskDonelist = data.donelist || [];
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    setDate(sdate, edate, mark) {
      let start = (sdate && sdate.split(" ")[0]) || "";
      let end = (edate && edate.split(" ")[0]) || "";
      return `${start} ${mark} ${end}`;
    },
    setTaskType(item) {
      let type = "";
      switch (item.task_type_value) {
        case "3": // 学时任务
          type = "#学时";
          break;
        case "1": // 课时任务
          type = "#课程";
          break;
        case "2": // 课时任务
          type = "#考试";
          break;
      }

      return type;
    },
    handleClick(item) {
      //  调整至详情
      console.log("click===", item);
      let id = item._id;
      let url = `#/pc/nc/page/pc/personalCenter/taskDetail?taskId=${id}`;
      this.$open({ url, state: 2 });
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.searchForm.time && this.searchForm.time.length > 0) {
            this.searchForm.strtDate = this.searchForm.time[0];
            this.searchForm.endDate = this.searchForm.time[1];
          } else {
            this.searchForm.strtDate = null;
            this.searchForm.endDate = null;
          }
          this.renderData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.renderData();
    },
  },
});
</script>
