var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-platform-card-box ds-nc-card-box" },
    [
      _vm.checkbox && _vm.showCheckAll && _vm.maxCheckNum !== 1
        ? _c(
            "el-checkbox",
            {
              staticClass: "ds-platform-card-box-checkAll",
              class: [_vm.checkboxSize],
              attrs: {
                value: _vm.checkAll,
                indeterminate: _vm.isIndeterminate,
              },
              on: {
                change: function ($event) {
                  return _vm.handleCheckAllChange($event)
                },
              },
            },
            [_vm._v(" 全选 ")]
          )
        : _vm._e(),
      _vm.isDesign
        ? _c(
            "div",
            {
              staticClass: "ds-platform-card-list",
              class: [_vm.checkboxPosition],
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ds-platform-card-item no-margin-left no-margin-top",
                  style: _vm.itemStyle,
                  attrs: { "slot-name": "default" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ds-platform-card-item-content" },
                    [_vm._t("default", null, null, { row: {}, $index: 0 })],
                    2
                  ),
                  _vm.checkbox && _vm.checkboxStyle
                    ? _c("dsf-custom-checkbox", {
                        staticClass: "ds-platform-card-item-checkbox",
                        attrs: {
                          "style-type": _vm.checkboxStyle,
                          size: _vm.checkboxSize,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.columnNumber - 1, function ($index) {
                return [
                  _c(
                    "div",
                    {
                      key: $index,
                      staticClass: "ds-platform-card-item no-margin-top",
                      style: _vm.itemStyle,
                    },
                    [
                      _vm.checkbox && _vm.checkboxStyle
                        ? _c("dsf-custom-checkbox", {
                            staticClass: "ds-platform-card-item-checkbox",
                            attrs: {
                              "style-type": _vm.checkboxStyle,
                              size: _vm.checkboxSize,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      !_vm.realData.length
        ? _c(
            "div",
            {
              staticClass: "ds-platform-card-list",
              class: [_vm.checkboxPosition],
            },
            [
              _c("div", {
                staticClass: "ds-empty-data",
                staticStyle: { "max-height": "500px" },
                attrs: { text: "暂无数据" },
              }),
            ]
          )
        : _vm._e(),
      _vm.realData.length
        ? _c(
            "div",
            {
              staticClass: "ds-platform-card-list",
              class: [_vm.checkboxPosition],
            },
            [
              _vm.showBanner
                ? [
                    _c(
                      "el-carousel",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "indicator-position": "outside",
                          interval: _vm.interval,
                          loop: _vm.loop,
                          arrow: _vm.arrow,
                        },
                      },
                      _vm._l(_vm.list, function (it, $index) {
                        return _c(
                          "el-carousel-item",
                          { key: $index },
                          _vm._l(it, function (row, index) {
                            return _c(
                              "div",
                              {
                                key: row[_vm.rowKey],
                                staticClass: "ds-platform-card-item",
                                class: {
                                  "no-margin-left": !(index % _vm.columnNumber),
                                  "no-margin-top": index < _vm.columnNumber,
                                },
                                style: _vm.itemStyle,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ds-platform-card-item-content",
                                  },
                                  [
                                    _vm._t("default", null, null, {
                                      row: row,
                                      index: index,
                                    }),
                                  ],
                                  2
                                ),
                                _vm.checkbox && _vm.checkboxStyle
                                  ? _c("dsf-custom-checkbox", {
                                      staticClass:
                                        "ds-platform-card-item-checkbox",
                                      attrs: {
                                        value:
                                          _vm.checkIdList.indexOf(
                                            row[_vm.rowKey]
                                          ) > -1,
                                        "style-type": _vm.checkboxStyle,
                                        size: _vm.checkboxSize,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.checkChange(row, $event)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      }),
                      1
                    ),
                  ]
                : _vm._l(_vm.realData, function (row, index) {
                    return _c(
                      "div",
                      {
                        key: row[_vm.rowKey],
                        staticClass: "ds-platform-card-item",
                        class: {
                          "no-margin-left": !(index % _vm.columnNumber),
                          "no-margin-top": index < _vm.columnNumber,
                        },
                        style: _vm.itemStyle,
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ds-platform-card-item-content" },
                          [
                            _vm._t("default", null, null, {
                              row: row,
                              index: index,
                            }),
                          ],
                          2
                        ),
                        _vm.checkbox && _vm.checkboxStyle
                          ? _c("dsf-custom-checkbox", {
                              staticClass: "ds-platform-card-item-checkbox",
                              attrs: {
                                value:
                                  _vm.checkIdList.indexOf(row[_vm.rowKey]) > -1,
                                "style-type": _vm.checkboxStyle,
                                size: _vm.checkboxSize,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkChange(row, $event)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }