import dsfNcSaasHomeCarousel from "./dsfNcSaasHomeCarousel.vue";
import dsfNcSaasHomeXinwenzixun from "./dsfNcSaasHomeXinwenzixun.vue";
import dsfNcSaasHomeJingpinkecheng from "./dsfNcSaasHomeJingpinkecheng.vue";
import dsfNcSaasHomeZhuantipeixun from "./dsfNcSaasHomeZhuantipeixun.vue";
import dsfNcSaasHomeJingcaizhibo from "./dsfNcSaasHomeJingcaizhibo.vue";
import dsfNcSaasHomeRemenpindao from "./dsfNcSaasHomeRemenpindao.vue";
import dsfNcSaasHomeMingshifengcai from "./dsfNcSaasHomeMingshifengcai.vue";
import dsfNcSaasHomeTuwendaohang from "./dsfNcSaasHomeTuwendaohang.vue";
export default [
  dsfNcSaasHomeCarousel,
  dsfNcSaasHomeXinwenzixun,
  dsfNcSaasHomeJingpinkecheng,
  dsfNcSaasHomeZhuantipeixun,
  dsfNcSaasHomeJingcaizhibo,
  dsfNcSaasHomeRemenpindao,
  dsfNcSaasHomeMingshifengcai,
  dsfNcSaasHomeTuwendaohang,
];
