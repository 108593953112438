var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds-nc-shop-active-time" }, [
        _c("div", { staticClass: "cover" }, [_c("dsf-image")], 1),
        _c("div", { staticClass: "active-time" }, [
          _vm._v("活动时间: 11-11 00:00 · 12-11 08:00"),
        ]),
      ])
    : _c("div", { staticClass: "ds-nc-shop-active-time" }, [
        _c("div", { staticClass: "cover" }, [_c("dsf-image")], 1),
        _c("div", { staticClass: "active-time" }, [
          _c("span", [
            _vm._v(
              "活动时间: " +
                _vm._s(_vm.data["time"] || "11-11 00:00 · 12-11 08:00")
            ),
          ]),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }