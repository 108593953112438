<template>
  <DsfSection
    mainWidth="1200px"
    :margin="[0, 0, 0, 0]"
    :padding="[10, 10, 10, 10]"
    :backgroundConfig="{
      color: '#fff',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
    :foregroundConfig="{
      color: 'rgba(255, 255, 255, 0)',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
  >
    <div class="dsf_nc_xinwenzixun">
      <!--nctitle-->
      <DsfNcTitle
        v-if="showTitle"
        :showMore="showMore"
        :title="title"
        caption="DsfNcTitle"
        :showBottomLine="true"
        titleSize="big"
        :titleBold="false"
        :clickConfig="clickConfigTitle"
        padding="80px 0 60px 0"
      ></DsfNcTitle>
      <div class="wrap">
        <div class="item pointer" @click="_clickBefore(goDetail, item)" v-for="(item, index) in list" :key="item.id">{{ index + 1 }}、{{ item.title }}</div>
      </div>
    </div>
  </DsfSection>
</template>

<script>
export default dsf.component({
  name: "DsfNcSaasHomeXinwenzixun",
  ctrlCaption: "新闻资讯",
  mixins: [$mixins.control],
  props: {
    moreText: {
      type: String,
      default: "查看更多",
    },
    sourceUrl: {
      type: String,
      default: "/inc/cms/articles/queryArticlesByCatalogIds",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    url: {
      type: String,
      default: "/nc/banner/pclist?code=001&pageSize=6",
    },
  },
  components: {},
  data() {
    return {
      list: [],
      clickConfig: {
        url: "#/pc/nc.page.pc.news/newsDetailModelTwo?id=",
        state: 2,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
      clickConfigTitle: {
        url: "/ncIndex.html#/pc/nc/page/pc/news/newsListModelTwo",
        state: 4,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
    };
  },
  computed: {
    title() {
      return this.data["bindAttr"]["title"];
    },
    showTitle() {
      return this.data["bindAttr"]["showTitle"] == 1 ? true : false;
    },
    showMore() {
      return this.data["bindAttr"]["showMore"] == 1 ? true : false;
    },
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
      } catch (err) {
        console.log(err);
      }
    },
    goMore() {
      this.$open({
        url: "/ncIndex.html#/pc/nc/page/pc/news/newsListModelTwo",
        state: 2,
      });
    },
    goDetail(item) {
      console.log(item);
      this.$open({
        url: this.clickConfig.url + item["id"],
        state: this.clickConfig.state,
      });
    },
    getList() {
      let that = this;
      let params = {
        pageNumber: 1,
        pageSize: 5,
        catalogIds: JSON.stringify(["3c9f27b10d03453f85e21933346857a8"]),
        orderPara: JSON.stringify([
          { column: "publish_time", sort: "" },
          { column: "page_view", sort: "" },
        ]),
      };
      this.$http
        .get(this.sourceUrl, {})
        .done(function (res) {
          if (res.success) {
            that.list = res.data;
            console.log(res.data);
          } else {
            dsf.layer.message(res.message || "请求异常", false);
          }
        })
        .error(function (err) {
          //错误
          dsf.layer.message((err && err.message) || "请求异常", false);
        })
        .always(function () {
          //不管成功失败都会执行（请求，比如404，200）
        });
    },
  },
  watch: {
    "data.bindAttr": {
      handler(n) {
        // this.list = n;
        this.getList();
      },
      deep: true,
    },
  },
});
</script>
