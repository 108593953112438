var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specialdetail_intro_recommend",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "intro_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.showMore && _vm.moreStyle == "tb"
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !_vm.isview && !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm.showMore && _vm.moreStyle == "wz"
            ? _c(
                "span",
                {
                  staticClass: "more_font",
                  on: {
                    click: function ($event) {
                      !_vm.isview && !!_vm.showMore && _vm.clickMore()
                    },
                  },
                },
                [_vm._v("更多")]
              )
            : _vm._e(),
        ]),
        _vm.showLine ? _c("span", { staticClass: "line" }) : _vm._e(),
      ]),
      _vm.lists.length && _vm.type == "class"
        ? _c(
            "ul",
            { staticClass: "recommend_wrap" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "recommend_items",
                  on: {
                    click: function ($event) {
                      !_vm.isview && _vm.clickItem(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "head_img cover" },
                    [
                      _c("dsf-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(item.cover),
                          width: "140px",
                          height: "80px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "recommend_desc" }, [
                    _c("div", { staticClass: "desc_title ell" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("div", { staticClass: "desc_data" }, [
                      _vm._v(
                        _vm._s(
                          _vm.setDate(item.pxsjSdate, item.pxsjEdate, " ～ ")
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "foot" }, [
                      _c("span", { staticClass: "bx" }, [
                        _vm._v("必修 " + _vm._s(item.compulsoryPeriod || 0)),
                      ]),
                      _c("span", { staticClass: "xx" }, [
                        _vm._v("选修 " + _vm._s(item.electivesPeriod || 0)),
                      ]),
                      _c("span", { staticClass: "person" }, [
                        _c("i", {
                          staticClass: "icon iconfont icon-tab-mine-fill",
                        }),
                        _vm._v(" " + _vm._s(item.studentCount || 0) + " "),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm.lists.length && _vm.type == "channel"
        ? _c(
            "ul",
            { staticClass: "recommend_wrap channel_recommend" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "recommend_items",
                  on: {
                    click: function ($event) {
                      !_vm.isview && _vm.clickItem(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "head_img cover" },
                    [
                      _c("dsf-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(item.cover),
                          width: "140px",
                          height: "80px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "recommend_desc" }, [
                    _c("div", { staticClass: "desc_title ell" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "desc_data" }, [
                      _vm._v(
                        "资源数:" +
                          _vm._s(item.courseCount || 0) +
                          " | 学时: " +
                          _vm._s(item.period || 0)
                      ),
                    ]),
                    _c("div", { staticClass: "foot" }, [
                      _c("span", { staticClass: "subscribed" }, [
                        _vm._v("订阅数: " + _vm._s(item.subscription || 0)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("div", {
            staticClass: "ds-empty-data",
            domProps: { textContent: _vm._s("暂无数据") },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }