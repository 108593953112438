var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_homework_summary" }, [
    !_vm.showRead
      ? _c(
          "div",
          { staticClass: "briefsummary_writting" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                class:
                  _vm.readyOnly || _vm.homeworkState == 1 ? "readyonly" : "",
                attrs: {
                  model: _vm.formModel,
                  "label-width": "80px",
                  rules: _vm.rules,
                },
              },
              [
                _c("div", { staticClass: "homework_desc" }, [
                  _vm._v(
                    "作业描述：" +
                      _vm._s(_vm.homeWorkDesc.requirements || "暂无数据")
                  ),
                ]),
                _c("el-form-item", { staticClass: "dsf_form_item" }, [
                  _c(
                    "div",
                    { staticClass: "conditions_content" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dsf_item_form",
                          attrs: { label: "标题", prop: "title" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "dsf_input",
                            attrs: {
                              disabled: _vm.readyOnly || _vm.homeworkState == 1,
                              placeholder: "请输入",
                              maxlength: "50",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.formModel.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModel, "title", $$v)
                              },
                              expression: "formModel.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-form-item", { staticClass: "dsf_form_item" }, [
                  _c(
                    "div",
                    { staticClass: "conditions_content" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dsf_item_form",
                          attrs: { label: "内容", prop: "content" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "dsf_input",
                            attrs: {
                              disabled: _vm.readyOnly || _vm.homeworkState == 1,
                              type: "textarea",
                              rows: 10,
                              resize: "none",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.formModel.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModel, "content", $$v)
                              },
                              expression: "formModel.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form-item",
                  { staticClass: "dsf_btns" },
                  [
                    _c(
                      "el-button",
                      { attrs: { calss: "close" }, on: { click: _vm.onClose } },
                      [_vm._v("关闭")]
                    ),
                    _vm.homeworkState != 1 && !_vm.readyOnly
                      ? _c(
                          "el-button",
                          {
                            staticClass: "submit",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onSubmit("form")
                              },
                            },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", { staticClass: "nc_pc_check_briefsummary_dialog" }, [
          _c("div", { staticClass: "content_wrapper" }, [
            _vm.showgrade
              ? _c("div", { staticClass: "teach" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "score",
                        _vm.checkForm.state_value == "2"
                          ? "pass"
                          : _vm.checkForm.state_value == "-1"
                          ? "nopass"
                          : "",
                      ],
                    },
                    [
                      _c("div", { staticClass: "left" }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("分数: "),
                        ]),
                        _vm.checkForm.score_type_value == 2
                          ? _c("span", { staticClass: "font" }, [
                              _vm._v(_vm._s(_vm.checkForm.score || "0") + "分"),
                            ])
                          : _c("span", { staticClass: "font" }, [
                              _vm._v(_vm._s(_vm.checkForm.score || "暂无")),
                            ]),
                      ]),
                      _c("div", { staticClass: "right" }),
                    ]
                  ),
                  _c("div", { staticClass: "py" }, [
                    _c("span", { staticClass: "label" }, [_vm._v("评语: ")]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(_vm.checkForm.reviews || "暂无")),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "briefsummayr" }, [
              _c("div", { staticClass: "xiaojieTitle" }, [
                _vm._v(_vm._s(_vm.checkForm.title)),
              ]),
              _c("div", { staticClass: "xiaojie_content" }, [
                _vm._v(_vm._s(_vm.checkForm.content)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "dsf_btns" },
              [
                _c(
                  "el-button",
                  { staticClass: "close", on: { click: _vm.onClose } },
                  [_vm._v("关闭")]
                ),
                _vm.homeworkState == -1 && !_vm.readyOnly
                  ? _c(
                      "el-button",
                      {
                        staticClass: "submit",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onSubmit("resubmit")
                          },
                        },
                      },
                      [_vm._v("重新提交")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }