<template>
  <div v-if="isDesign" class="ds_channel_detail">
    <div class="ds_channel_detail_wrap">
      <div class="channel-detail-inner">
        <div class="cover">
          <el-image src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"></el-image>
        </div>
        <div class="desc">
          <h3 class="desc-name">
            <span class="name">2</span>
          </h3>
          <div class="info">
            <span>111发布: 2020-10-21</span>
          </div>
          <div class="info">
            <span>资源数：共 30门</span>
          </div>
          <div class="info info-bottom">
            <span>浏览 12221次</span>
          </div>
        </div>
        <div class="channel-button">
          <!-- <dsf-icon name="dianzanhong" /> -->
          <dsf-icon name="iconfontzhizuobiaozhun13" />
        </div>
      </div>
    </div>
    <div class="channel-content">
      <el-tabs v-model="activeName" @tab-click="changeTab">
        <el-tab-pane label="简介" name="first">简介</el-tab-pane>
        <el-tab-pane label="章节" name="second">章节</el-tab-pane>
      </el-tabs>
      <div class="intro_inner" v-if="activeName == 'first'">
        <div class="resouce" style="margin-bottom: 20px">
          <DsfNcspecialDetailRelevantInformation :showLine="showLine" :itemWidth="itemWidth" :page="page" />
        </div>
        <div class="recommend">
          <DsfNcSpecialDetailIntroRecommend
            :url="'/nc/pack/channel/recommendlist'"
            :type="'channel'"
            :showMore="true"
            :moreStyle="moreStyle"
            :title="title"
            :moreUrl="moreUrl"
            :itemConfig="itemConfig"
            :showLine="showLine"
            :itemWidth="itemWidth"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ds_channel_detail">
    <div class="ds_channel_detail_wrap" v-if="it">
      <div class="channel-detail-inner">
        <div class="cover">
          <dsf-image :src="it.cover | imgFormat" />
        </div>
        <div class="desc">
          <h3 class="desc-name">
            <span class="name">{{ it.title }}</span>
          </h3>
          <div class="info">
            <span>发布: {{ it.time }}</span>
          </div>
          <div class="info">
            <span>资源数: {{ it.courseCount || 0 }}门</span>
          </div>
          <div class="info info-bottom">
            <span>浏览: {{ it.hits || 0 }}次&nbsp;&nbsp;|&nbsp;&nbsp;订阅数: {{ it.subscription || 0 }}</span>
          </div>
          <div class="info info-bottom" v-if="it.labelText">
            <span v-for="item in it.labelText.split('^')" :key="item" class="tags">{{ item }}</span>
          </div>
        </div>
        <div class="channel-button">
          <button class="subcrible" @click="toSubscrible(it, 'mySubscribed')">{{ showSubText }}</button>
        </div>
      </div>
    </div>
    <div class="channel-content">
      <div :class="['channel_content_inner', { channel_content_inner_left: activeName == 'first' }]">
        <el-tabs v-model="activeName" @tab-click="changeTab">
          <el-tab-pane label="简介" name="first">
            <div class="channel-content-intro" v-if="it && it.describe">
              <p class="text" v-html="it.describe"></p>
            </div>
            <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
          </el-tab-pane>
          <el-tab-pane label="章节" name="second">
            <template v-if="list && list.length > 0">
              <div class="channel-chapter" :class="{ 'show-chapter': item.toggle }" v-for="(item, index) in list" :key="item.unitName">
                <div class="chapter-title" @click="changItemFold(it, index)">
                  <h3 class="title">
                    {{ item.unitName }}
                    <span>({{ item.totalPeriod || 0 }}学时)</span>
                  </h3>
                  <div class="toggle-name">
                    <span>{{ item.toggle ? "展开" : "收起" }}</span>
                    <!-- <i class="el-collapse-item__arrow el-icon-arrow-right"></i> -->
                    <i class="icon iconfont icon-xia1 sq" v-if="item.toggle"></i>
                    <i class="icon iconfont icon-shang1 sq" v-else></i>
                  </div>
                </div>
                <div class="chapter-body">
                  <!-- <dl class="body-head">
                  <dd class="name">名称</dd>
                  <dd class="duration">时长</dd>
                  <dd class="hours">学时</dd>
                  <dd class="progress">进度</dd>
                  <dd class="handle">操作</dd>
                </dl> -->
                  <dl class="body-content" v-for="(it, idx) in item.subList" :key="it.businessId">
                    <dd class="name">
                      <span class="state" :style="`background-color:${getBgcColor(it.typeValue)}`">{{ it.typeText }}</span>
                      <span class="title ellipsis" :title="it.name">{{ it.name }}</span>
                    </dd>
                    <dd class="duration" v-if="it.duration">{{ it.duration }}</dd>
                    <dd class="hours">{{ it.period }}学时</dd>
                    <dd class="progress"><el-progress :percentage="it.progress" :stroke-width="10"></el-progress></dd>
                    <dd :class="['handle', { gray: !isOrderTypeValue(it, idx, item) }]" @click="isOrderTypeValue(it, idx, item) && toDetail(it)">进入</dd>
                  </dl>
                </div>
              </div>
            </template>
            <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="intro_inner" v-if="activeName == 'first'">
        <div class="resouce" style="margin-bottom: 20px">
          <DsfNcspecialDetailRelevantInformation :showLine="showLine" :itemWidth="itemWidth" :page="page" :showMore="inforShowMore" :moreStyle="inforMoreStyle" />
        </div>
        <div class="recommend">
          <!-- :url="'/nc/pack/channel/recommendlist'" -->
          <DsfNcSpecialDetailIntroRecommend
            :url="recommendUrl"
            :type="'channel'"
            :showMore="showMore"
            :moreStyle="moreStyle"
            :title="title"
            :moreUrl="moreUrl"
            :itemConfig="itemConfig"
            :showLine="showLine"
            :itemWidth="itemWidth"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcChannelCardDetail",
  ctrlCaption: "学习专区卡片详情",
  mixins: [$mixins.control],
  props: {
    recommendUrl: {
      type: String,
      default: "/nc/pack/channel/recommendlist?pageNum=1&pageSize=3",
    },
    sourceUrl: {
      type: String,
      default: "nc/pack/channel/info",
    },
    coursePath: {
      type: String,
      default: "/pc/nc/pagecourse/coursePlayer?id=",
    },
    livePath: {
      type: String,
      default: "/page.html#/pc/nc/pagelive/liveDetail?id=",
    },
    /*相关资料*/
    page: {
      type: Number,
      default: 5,
    },
    inforMoreStyle: {
      type: String,
      default: "tb",
    },
    inforShowMore: {
      type: Boolean,
      default: true,
    },
    /*相关推荐*/
    title: {
      type: String,
      default: "相关推荐",
    },
    moreStyle: {
      type: String,
      default: "tb",
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    itemWidth: {
      type: String,
      default: "440px",
    },
  },
  computed: {
    showDzIcon() {
      let icon = "dianzanhong";
      if (this.it) {
        icon = this.it.myLike ? "dianzan1" : "dianzanhong";
      }
      return icon;
    },
    showSubText() {
      let text = "取消订阅";
      if (this.it) {
        text = this.it.mySubscribed ? "取消订阅" : "立即订阅";
      }
      return text;
    },

    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  data() {
    return {
      it: null,
      queryiId: this.$route.query.id,
      activeName: "first",
      state: false,
      list: [],
    };
  },

  mounted() {
    if (!this.isDesign) {
      this.reData();
    }
  },
  methods: {
    reData() {
      this.loadChannelCardDetail();
      this.loadChannelList();
    },
    changeTab(idx) {},
    changItemFold(it, idx) {
      this.list[idx]["toggle"] = !this.list[idx]["toggle"];
    },
    loadChannelCardDetail(type) {
      const { sourceUrl } = this;
      const params = {
        id: this.queryiId,
      };
      if (type) {
        params["type"] = 1;
      }
      const loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.it = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(err, false);
      }
    },
    loadChannelList() {
      const sourceUrl = "/nc/pack/channel/course/list";
      const params = {
        id: this.queryiId,
      };
      const loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            _.each(data, item => {
              item.toggle = true;
            });
            this.list = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(err, false);
      }
    },
    toSubscrible(it, type) {
      let that = this;
      this.$checkLogin().then(res => {
        if (res) {
          const sourceUrl = type === "mySubscribed" ? "/dsfa/subscribe/saveOrCancel" : "/dsfa/tags/saveOrCancel";
          const params = {
            ywlxValue: "channel",
            ywlxText: "频道",
            ywid: it.channelId,
          };
          const loading = dsf.layer.pc.loading();
          dsf.http
            .post(sourceUrl, params, $$webRoot.nc)
            .done(({ success, data, message }) => {
              if (success) {
                this.it[type] = data.isDo;
                dsf.layer.pc.message(message, true);
                this.loadChannelCardDetail("1");
                this.loadChannelList();
              } else {
                error();
              }
            })
            .error(err => {
              error();
            })
            .always(res => {
              dsf.layer.pc.closeLoading(loading);
            });
        }
      });
      function error() {
        dsf.layer.pc.message("操作失败", false);
      }
    },
    isOrderTypeValue(it, idx, parentItem) {
      //是否按序学习
      if (parentItem.orderTypeValue && Number(parentItem.orderTypeValue)) {
        //是否按序学习
        let lastItem = parentItem.subList.length ? parentItem.subList[idx - 1] : it;
        if (idx === 0 || lastItem.progress == 100) {
          // 第一条|| 上一条进度100%
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    toDetail(it) {
      this.$checkLogin().then(res => {
        if (res) {
          if (it.typeValue === "course") {
            this.coursePath && dsf.layer.openWindow(this.coursePath + it.businessId);
          } else if (it.typeValue === "channel") {
            this.queryiId = it.unitId;
            this.reloadData();
          } else if (it.typeValue === "live") {
            this.livePath && dsf.layer.openWindow(this.livePath + it.businessId);
          } else if (it.typeValue === "exam") {
            const params = {
              examId: it["businessId"],
              businessId: it["subId"],
              businessType: 1,
              deviceType: "pc",
            };
            this.$toExamPage(params);
          }
        }
      });
    },
    getBgcColor(it) {
      if (!it) it = "course";
      let config = {
        course: "#69C33B",
        live: "#FF9F50",
        exam: "#538CEE",
      };
      return config[it];
    },
  },
});
</script>
