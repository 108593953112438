<template>
  <div class="dsf-register-model-two-pc-control" :style="controlStyle">
    <div class="close-btn">
      <dsf-icon name="guanbi2" @click="$root.close()"></dsf-icon>
    </div>
    <div class="content">
      <div class="register_form">
        <div class="title">注册账号</div>
        <el-form ref="form" :model="form" class="form-box" :label-width="'67px'">
          <!-- <el-form-item label-width="0" v-if="!fromValues.length">
            <el-input v-model="form.name" placeholder="姓名">
              <dsf-icon name="icon_user" slot="prefix"></dsf-icon>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0" v-if="!fromValues.length">
            <el-input v-model="form.phone" placeholder="手机">
              <dsf-icon name="icon_phone" slot="prefix"></dsf-icon>
            </el-input>
          </el-form-item> -->
          <DsfNcPcRegisterFormation :columnsNum="3" :fromValues="fromValues" @onSubmit="updateFormValue" :labelWidth="'67px'"></DsfNcPcRegisterFormation>
          <el-form-item label="验证码" v-show="checkMsgCode">
            <div class="code-form">
              <el-input v-model="form.code" placeholder="验证码" class="code-input"></el-input>
              <div class="form-getCode" @click.stop="getCodeFun">{{ codeBtType != "" ? `${countdownTime}s` : "获取验证码" }}</div>
            </div>
          </el-form-item>
          <el-form-item label="密码" class="requiredFrom">
            <el-input :type="isShowPwd ? 'text' : 'password'" v-model="form.pwd" placeholder="密码">
              <dsf-icon :name="isShowPwd ? 'yanjing1' : 'biyan'" slot="suffix" class="pointer" @click="isShowPwd = !isShowPwd"></dsf-icon>
            </el-input>
          </el-form-item>

          <div class="messageTips">{{ messageTips }}</div>
          <div class="clause">
            <el-radio v-model="clause" label="1"></el-radio>
            我已阅读并同意
            <span class="span-color pointer" @click="handleClause">《注册条款》</span>
            和
            <span class="span-color pointer" @click="handlePolicy">《隐私政策》</span>
          </div>
          <el-form-item label-width="0" class="btn">
            <!-- :disabled="!isSendCode" -->
            <el-button @click="onSubmit" class="submit-btn">注册</el-button>
          </el-form-item>
        </el-form>
        <div class="hint">
          已有账号？
          <span class="pointer" @click="goLogin">去登录</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNCRegisterModelTwo",
  ctrlCaption: "注册模式二",
  mixins: [$mixins.control],
  props: {
    margin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    padding: {
      type: Array,
      default() {
        return [50, 40, 40, 40];
      },
    },
    formUrl: {
      type: String,
      default: "nc/reg/getRegConfig",
    },
    getCodeUrl: {
      type: String,
      default: "nc/org/user/sendNcVerifCode?codetype=reg",
    },
    checkMsgCode: {
      type: Boolean,
      default: true,
    },
    submitUrl: {
      type: String,
      default: "nc/reg/userRegister",
    },
    selectUrl: {
      type: String,
      default: "page.html#/pc/nc/page/pc/login/selectPlatform",
    },
    labelWidth: {
      type: String,
      default: "67px",
    },
  },
  data() {
    return {
      urlUnitid: "",
      unitid: "",
      unitname: "",
      checkCodeSuccess: false,
      form: {
        code: "",
        pwd: "",
      },
      itemForm: {},
      isShowPwd: false,
      timeout: null,
      messageTips: "",
      isSendCode: false,
      codeBtType: "",
      countdownTime: 0,
      countdownTimes: 60,
      getCodeFun: null,
      fromValues: JSON.stringify([
        { required: true, name: "姓名", text: "请输入姓名", base: true, rule: "name", type: "textbox" },
        { required: true, name: "手机号", text: "请输入参与人手机号", base: true, rule: "phone", type: "textbox" },
      ]),
      clause: "0",
      clauseValue: "",
      policyValue: "",
    };
  },
  components: {},
  computed: {
    controlStyle() {
      let { margin, width, height, padding, isDesign, visible } = this;
      let style = {
        margin: margin.join("px ") + "px",
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
  },
  watch: {},
  created() {
    this.urlUnitid = this.queryString["unitid"] || "";
  },
  async mounted() {
    if (this.isDesign) {
      this.getFromValues();
    } else {
      //绑定多次触发一下
      this.getCodeFun = _.debounce(this.getCode, 1000);
      //获取密码规则
      this.getFromValues();
      let res = await this.getiInterval();
      if (res.success) {
        this.messageTips = res.data.notice;
      }
    }
  },

  beforeDestroy() {},
  methods: {
    //获取表单数据
    getFromValues() {
      if (!this.formUrl) return;
      const loading = dsf.layer.loading();
      this.dsf.http
        .get(
          this.formUrl,
          {
            unitid: this.urlUnitid || "",
            routekey: dsf.config.setting_nc_tenement_web_route || "",
          },
          $$webRoot.nc
        )
        .done(res => {
          if (res.success) {
            this.fromValues =
              res.data.items ||
              JSON.stringify([
                { required: true, name: "姓名", text: "请输入姓名", base: true, rule: "name", type: "textbox" },
                { required: true, name: "手机号", text: "请输入参与人手机号", base: true, rule: "phone", type: "textbox" },
              ]);
            this.unitid = res.data.unitid;
            this.unitname = res.data.unitname;
            this.clauseValue = res.data.regTerms;
            this.policyValue = res.data.privacyPolicy;
          } else {
            this.dsf.layer.message(res.message || "服务器异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "服务器异常", false);
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
    },
    updateFormValue(value) {
      // this.form = Object.assign(this.form, value);
      this.itemForm = value;
      // console.log(this.itemForm);
    },
    async onSubmit() {
      console.log("submit!");
      if (!this.itemForm.name) return this.dsf.layer.message("请输入姓名", false);
      if (!this.itemForm.phone) return this.dsf.layer.message("请输入手机号", false);
      if (!this.form.code && this.checkMsgCode) return this.dsf.layer.message("请输入验证码", false);
      // if (!this.checkCodeSuccess) return !this.dsf.layer.message("验证码错误", false);
      if (!this.form.pwd) return this.dsf.layer.message("请输入密码", false);
      if (this.clause != "1") return this.dsf.layer.message("请同意注册条款和隐私政策", false);

      let params = {
        unitid: this.unitid,
        unitname: this.unitname,
        password: this.form.pwd,
        routekey: dsf.config.setting_nc_tenement_web_route || "",
        platformJson: "",
        applyreason: "",
      };
      await this.checkCode();
      await this.selfverify();
      params.password = dsf.md5(dsf.md5(params.password));
      let items = Object.assign(this.itemForm, { password: params.password });
      params.items = JSON.stringify(items);
      if (!this.submitUrl) return;
      //如果有unitid就注册没有就下一步
      if (this.urlUnitid) {
        const loading = dsf.layer.loading();
        this.dsf.http
          .post(this.submitUrl, params, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              if (res.state == 30000) {
                //待审核
                dsf.layer
                  .confirm({ message: res.data || "申请提交成功，待审核", title: "提示", confirmButtonText: "确定", showCancelButton: false })
                  .then(() => {
                    this.$root.close();
                  })
                  .catch(() => {
                    this.$root.close();
                    console.log("取消");
                  });
              } else {
                dsf.layer
                  .confirm({ message: "注册成功,去登录?", title: "提示", confirmButtonText: "是", cancelButtonText: "否" })
                  .then(() => {
                    this.reLogin();
                  })
                  .catch(() => {
                    this.$root.close();
                    console.log("取消重新登录");
                  });
              }
            } else {
              this.dsf.layer.message(res.message || "服务器异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "服务器异常", false);
          })
          .always(() => {
            dsf.layer.closeLoading(loading);
          });
      } else {
        //add 监听外部事件
        if (this.$listeners["handleSubmit"]) {
          this.$dispatch("handleSubmit", { params: params });
          return;
        }
        //下一步
        //选择平台
        dsf.layer
          .confirm({
            message: "注册成功，请选择平台！",
            title: "提示",
            confirmButtonText: "确定",
            showClose: false,
            showCancelButton: false,
            closeOnPressEscape: false,
            closeOnClickModal: false,
          })
          .then(() => {
            if (!this.selectUrl) return;
            let url = this.$replace(this.selectUrl, { id: this.urlUnitid });
            sessionStorage.setItem("register_form_data", JSON.stringify(params));
            this.$open({
              url,
              state: 2,
            });
            this.$root.close();
          })
          .catch(() => {
            this.$root.close();
          });
      }
    },
    getiInterval() {
      return new Promise(resolve => {
        this.dsf.http
          .get("user/password/getUpdatePwdRule", {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              resolve(res);
            } else {
              this.dsf.layer.message(res.message || "获取密码规则错误", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取密码规则错误", false);
          });
      });
    },
    selfverify() {
      return new Promise(resolve => {
        this.dsf.http
          .get("nc/reg/ncVerifyPwd", { pwd: dsf.base64.encode(this.form.pwd) }, $$webRoot.nc)
          .done(res => {
            if (res.data.success) {
              resolve(res.data.success);
            } else {
              this.dsf.layer.message(res.data.message || "验证密码失败", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "验证密码失败", false);
          });
      });
    },
    checkCode() {
      return new Promise(resolve => {
        // let loading = dsf.layer.loading();
        if (!this.checkMsgCode) this.form.code = 666666;
        this.$http
          .get("nc/org/user/verifyNcCode", { phone: this.itemForm.phone, code: this.form.code, codetype: "reg" })
          .done(({ success, data, message }) => {
            if (success) {
              this.checkCodeSuccess = true;
              resolve(success);
            } else {
              dsf.layer.message(message || "请求异常", false);
            }
          })
          .error(err => {
            dsf.error(err);
            dsf.layer.message(err?.message || "请求异常", false);
          })
          .always(() => {
            // dsf.layer.closeLoading(loading);
          });
      });
    },
    // 获取验证码
    getCode() {
      console.log(this.itemForm);
      if (this.codeBtType != "") return;
      if (!this.itemForm.phone) {
        dsf.layer.message("手机号不能为空！", false);
        return;
      }
      if (!dsf.validate("isMobileOrTelPhone", this.itemForm.phone)) {
        dsf.layer.message("手机号输入有误", false);
        return;
      }
      this.isSendCode = false;
      let loading = dsf.layer.loading();
      this.$http
        .get(this.getCodeUrl, { phone: this.itemForm.phone })
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.message("发送成功");
            this.isSendCode = true;
            this.codeBtType = "countdown";
            this.doCountdown();
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error(err => {
          dsf.error(err);
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
    },
    // 执行倒计时
    doCountdown() {
      this.countdownTime = this.countdownTimes - 1;
      this.timeout = setInterval(() => {
        this.countdownTime--;
        if (this.countdownTime <= -1) {
          this.codeBtType = "";
          clearInterval(this.timeout);
        }
      }, 1000);
    },
    goLogin() {
      if (this.$listeners["handleClosePage"]) {
        this.$dispatch("handleClosePage");
      } else {
        this.reLogin();
      }
    },
    reLogin() {
      dsf.storage.session.clear();
      dsf.cookies.remove("userId");
      dsf.cookies.set("isLogin", "");
      dsf.cookies.set("authorization_token", "");
      window.location.href = location.origin + dsf.url.getWebPath(dsf.config.setting_public_pcindex_url) + "?showLogin=1";
      window.location.reload();
      // this.$root.close();
      // this.$checkLogin();
    },
    //点击条款
    handleClause() {
      if (this.$listeners["clickClause"]) {
        this.$dispatch("clickClause", { clauseValue: this.clauseValue });
      } else {
        this.$openDialog({
          title: "服务条款",
          template: this.clauseValue,
          height: "60vh",
          width: "90vw",
          type: "dialog",
          confirmButtonText: "确定",
          showConfirmButton: true,
        });
      }
    },
    //点击政策
    handlePolicy() {
      if (this.$listeners["clickPolic"]) {
        this.$dispatch("clickPolic", { policyValue: this.policyValue });
      } else {
        this.$openDialog({
          title: "隐私政策",
          template: this.policyValue,
          height: "60vh",
          width: "90vw",
          type: "dialog",
          confirmButtonText: "确定",
          showConfirmButton: true,
        });
      }
    },
  },
});
</script>
