var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds_channel_card" }, [
        _c("div", { staticClass: "channel_card_wrap" }, [
          _c(
            "div",
            { staticClass: "cover" },
            [_c("dsf-nc-image", { attrs: { "error-src": _vm.defaultSrc } })],
            1
          ),
          _vm._m(0),
        ]),
      ])
    : _c("div", { staticClass: "ds_channel_card", on: { click: _vm.click } }, [
        _c("div", { staticClass: "channel_card_wrap" }, [
          _c(
            "div",
            { staticClass: "cover" },
            [
              _c("dsf-nc-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "desc" }, [
            _c("h3", { staticClass: "desc-title" }, [
              _c(
                "span",
                {
                  staticClass: "name ell",
                  attrs: { title: _vm.data.title || "" },
                },
                [_vm._v(_vm._s(_vm.data.title || ""))]
              ),
            ]),
            _c("div", { staticClass: "info" }, [
              _c("div", [
                _vm._v(
                  "资源数: " +
                    _vm._s(_vm.data.course_count || 0) +
                    " ｜ 学时: " +
                    _vm._s(_vm.data.period || 0)
                ),
              ]),
              _c("div", { staticClass: "subscrip" }, [
                _vm._v("订阅人数: " + _vm._s(_vm.data.subscribed_num || 0)),
              ]),
            ]),
          ]),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("h3", { staticClass: "desc-title" }, [
        _c("span", { staticClass: "name ell" }, [_vm._v("1")]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("div", [_vm._v("资源数: 8 ｜ 学时: 3")]),
        _c("div", { staticClass: "subscrip" }, [_vm._v("订阅人数: 1382")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }