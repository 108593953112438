import { render, staticRenderFns } from "./userPortrayal.vue?vue&type=template&id=09918cb8&scoped=true&"
import script from "./userPortrayal.vue?vue&type=script&lang=js&"
export * from "./userPortrayal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09918cb8",
  null
  
)

component.options.__file = "pc/components/mine/userPortrayal.vue"
export default component.exports