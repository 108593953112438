<template>
  <div class="ds_newsListModel ds-control" :style="controlStyle">
    <div class="left-tab">
      <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" unique-opened @select="selectMenu" :default-openeds="defaultOpeneds">
        <template v-for="item in menus">
          <el-submenu :index="item.id" v-if="item.children && item.children.length" :key="item.id">
            <template slot="title">
              <dsf-icon :name="item.icon"></dsf-icon>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <!-- <el-menu-item :index="i.id" v-for="i in item.children" :key="i.id">{{ i.name }}</el-menu-item> -->
              <el-menu-item :index="i.id" v-for="i in item.children" :key="i.id">{{ i.name }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item :index="item.id" v-else :key="item.id">
            <dsf-icon :name="item.icon"></dsf-icon>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
    <div class="content">
      <template v-if="newsList.length">
        <div class="ds_newsListModel-box">
          <div class="ds_newsListModel-row" v-for="(item, index) in newsList" :key="index" @click="_clickBefore(goDetail, item)" :style="rowStyle">
            <div class="title-box">
              <!-- :class="{ hasCover: item[keyField.cover] && item[keyField.cover].length > 2 }" -->
              <p class="title ell2">{{ item[keyField.title] }}</p>
              <div class="img-video">
                <!-- 多图新闻 -->
                <template v-if="item.type === '1'">
                  <div class="cover-img more-img-cover" v-for="(i, idx) in item[keyField.imgArray] || []" :key="idx">
                    <dsf-nc-image :src="i[keyField.imgMore] | imgFormat" :error-src="defaultSrc" />
                  </div>
                </template>
                <!-- 视频新闻 -->
                <template v-else-if="item.type === '2' && item.video">
                  <div class="cover-img">
                    <dsf-nc-image :src="item.cover | imgFormat" :error-src="defaultSrc" />
                    <dsf-icon name="icon-play" class="play"></dsf-icon>
                    <div class="cover-mask"></div>
                  </div>
                </template>
                <!-- 新闻封面 -->
                <div v-else-if="item && item[keyField.cover] && item[keyField.cover].length > 2" class="cover-img">
                  <dsf-nc-image :src="item.cover | imgFormat" :error-src="defaultSrc" />
                </div>
              </div>
              <div class="detail">
                <p class="source" v-if="item[keyField.source]">{{ item[keyField.source] }}</p>
                <p class="time">{{ item[keyField.time] ? dsf.date.format(item[keyField.time], "yyyy.mm.dd") : "" }}</p>
              </div>
            </div>
            <!-- <div v-if="item && item[keyField.cover] && item[keyField.cover].length > 2" class="cover-img">
          <dsf-nc-image :src="item.cover | imgFormat" :error-src="defaultSrc" />
        </div> -->
          </div>
        </div>
        <div class="ds-datagrid-pager">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper,sizes"
            :total="total"
            class="style3"
          ></el-pagination>
        </div>
      </template>
      <div v-else class="noDate">暂无数据</div>
    </div>
    <div class="hot">
      <div :class="`insert ${isDesign ? 'insert-wrap' : ''}`">
        <div slot-name="hot" :class="['insert-content', { 'insert-border': isDesign }]">
          <slot name="hot"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcNewsListModel",
  ctrlCaption: "新闻列表多模式",
  mixins: [$mixins.layout],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    margin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    padding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    rowPadding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    rowMargin: {
      type: Array,
      default() {
        return [0, 0, 28, 0];
      },
    },
    detailUrl: {
      type: String,
      default: "",
    },
    reqUrl: {
      type: String,
      default: "cms/articles/queryArticlesByCatalogIds",
    },
    //获取列表数量
    reqTotalUrl: {
      type: String,
      default: "cms/articles/queryArticlesTotalByCatalogIds",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "title", to: "title", type: "text", desc: "新闻标题" },
          { from: "source", to: "source", type: "text", desc: "来源" },
          { from: "publishTime", to: "time", type: "text", desc: "时间" },
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "articlesImagesVos", to: "imgArray", type: "text", desc: "多图" },
          { from: "imgMore", to: "imgMore", type: "text", desc: "多图图片字段" },
          // { from: "type", to: "type", type: "text", desc: "类型" },
        ];
      },
    },
    //获取分类
    classifyUrl: {
      type: String,
      default: "cms/catalog/getTreeCatalog",
    },
    defaultActive: {
      type: String,
      default: "",
    },
    news: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaultSrc: {
      type: String,
      default: dsf.config.setting_nc_image_default_img,
    },
    slots: {
      type: Array,
      default() {
        return [{ name: "hot", controls: [] }];
      },
    },
  },
  watch: {
    news: {
      handler(newValue, oldValue) {
        if (newValue.length) {
          this.newsList = newValue;
        }
      },
      deep: true,
    },
  },
  computed: {
    controlStyle() {
      let { margin, width, height, padding, isDesign, visible } = this;
      let style = {
        margin: margin.join("px ") + "px",
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    rowStyle() {
      let { rowPadding, rowMargin } = this;
      let style = {
        margin: rowMargin.join("px ") + "px",
        padding: rowPadding.join("px ") + "px",
      };
      return style;
    },
  },
  data() {
    return {
      keyField: {},
      id: "",
      newsList: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      classify: [],
      defaultOpeneds: [],
      menus: [],
    };
  },
  async mounted() {
    this.id = this.queryString["id"] || "";
    this.keysMap.map(item => {
      this.keyField[item.to] = item.from;
    });
    if (!this.isDesign) {
      await this.getClassify();
      //有ID默认选中tab
      if (this.id) {
        this.defaultActive = this.id;
        this.selectMenu(this.id);
        return;
      }
      this.getTotal();
      this.initData();
    } else {
      this.menus = [
        {
          id: "5069900a6c58403b8af4c0d7404d88ee",
          name: "党建宝",
          codes: "001",
          treeinfoPid: null,
          type: {
            text: "文件夹",
            value: "0",
          },
          children: [
            {
              id: "a5f3946e383e414ab0f9d4848a475bfd",
              name: "党建头条",
              codes: "001001",
              treeinfoPid: "5069900a6c58403b8af4c0d7404d88ee",
              type: {
                text: "频道",
                value: "1",
              },
              children: null,
            },
          ],
        },
      ];
      this.newsList = [
        {
          title: "关于印发《生态环境部2021年度培训计划》的通知",
          publishTime: "2021/04/05 10:00:00",
          source: "来源",
          cover: null,
        },
        {
          title: "关于印发《生态环境部2021年度培训计划》的通知关于印发《生态环境部2021年度培训计划》的通知",
          publishTime: "2021/04/05 10:00:00",
          source: "来源",
          cover: null,
        },
        {
          title: "关于印发《生态环境部2021年度培训计划》的通知",
          publishTime: "2021/04/05 10:00:00",
          source: "来源",
          cover: null,
        },
      ];
    }
  },
  methods: {
    selectMenu(id) {
      this.classify = [id];
      this.pageNumber = 1;
      this.initData();
      this.getTotal();
    },
    //获取分类
    getClassify() {
      if (!this.classifyUrl) return;
      return new Promise(resolve => {
        dsf.http
          .get(this.classifyUrl, {}, $$webRoot.nc)
          .done(res => {
            if (!res.success) {
              this.error(res);
            } else {
              let { data } = res;
              this.menus = data;
              this.defaultActive = this.menus[0].children && this.menus[0].children.length ? this.menus[0].children[0].id : this.menus[0].id;
              this.defaultOpeneds = [this.menus[0].id];
              this.classify = [this.defaultActive];
              resolve(true);
            }
          })
          .error(err => {
            this.error(err);
          })
          .always(res => {});
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.initData();
    },
    handleSizeChange(val) {
      this.pageNumber = 1;
      this.pageSize = val;
      this.initData();
    },
    getTotal() {
      if (!this.reqTotalUrl) return;
      dsf.http
        .get(this.reqTotalUrl, { catalogIds: JSON.stringify(this.classify) }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.total = res.data.total || 0;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {});
    },
    initData() {
      if (!this.reqUrl) return;
      let loading = dsf.layer.loading();
      dsf.http
        .get(
          this.reqUrl,
          {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            catalogIds: JSON.stringify(this.classify),
            orderPara: JSON.stringify([
              { column: "is_top_value", sort: "desc" },
              { column: "ds_order", sort: "asc" },
              { column: "publish_time", sort: "desc" },
              { column: "page_view", sort: "desc" },
            ]),
          },
          $$webRoot.nc
        )
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.newsList = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.message(err.message, false);
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        if (this.$checkLogin) {
          if (!dsf.getToken()) {
            if (this.$listeners["clickBefore"]) {
              this.$dispatch("clickBefore", { params: [...params] });
            } else {
              this.$checkLogin().then(res => {
                if (res) next(...params);
              });
            }
          } else {
            next(...params);
          }
        } else {
          next(...params);
        }
      } catch (err) {
        console.log(err);
      }
    },
    goDetail(item) {
      if (this.$listeners["click"]) {
        this.$dispatch("click", item);
      } else {
        if (item.type == 3) {
          item.externalLink && window.open(item.externalLink);
        } else {
          if (this.detailUrl) {
            let url = this.$replace(this.detailUrl, item);
            url && dsf.layer.openWindow(url);
          }
        }
      }
      //获取积分
      dsf.http
        .get("nc/cms/articles/queryArticlesById", { id: item.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            dsf.layer.message(res.message || "积分异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(res.message || "积分异常", false);
        })
        .always(res => {});
    },
  },
});
</script>
