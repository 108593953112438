<template>
  <div class="dsf_study_task ds-control" :style="{ width: itemWidth }">
    <div class="dsf_study_task_inner">
      <div class="content" v-if="!isDesign">
        <course-column :list="itemList" v-if="taskType == 'course'" @toDetail="toDetailPage" />
        <special-column :list="itemList" v-if="taskType == 'class'" @toDetail="toDetailPage" />
        <exam-column :list="itemList" v-if="taskType == 'exam'" @toDetail="toDetailPage" />
      </div>
      <div v-else>
        {{ sourceUrl + ":" + taskType }}
      </div>
    </div>
  </div>
</template>

<script>
import topTitle from "../../base/onStudy/topTitle";
import courseColumn from "../../base/onStudy/courseColumn";
import specialColumn from "../../base/onStudy/specialColumn";
import examColumn from "../../base/onStudy/examColumn";
export default dsf.component({
  name: "DsfNcStudyTask",
  ctrlCaption: "学习任务",
  mixins: [$mixins.control],
  props: {
    sourceUrl: {
      type: String,
      default: "nc/learn/year/underwayTask",
    },
    detailUrl: {
      type: String,
      default: "nc/learn/year/underwayTask",
    },
    itemWidth: {
      type: String,
      default: "790px",
    },
    taskType: {
      type: String,
      default: "course",
    },
  },
  components: {
    topTitle,
    courseColumn,
    specialColumn,
    examColumn,
  },
  data() {
    return {
      itemList: [],
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign) this.loadStudyTask();
  },
  methods: {
    loadStudyTask() {
      let { sourceUrl, taskType } = this;
      const loading = dsf.layer.pc.loading();
      let params = {
        type: taskType,
      };
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          console.log(res);
          if (!res.success) {
            error(res);
          } else {
            this.itemList = res.data;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    toDetailPage(id) {
      if (this.detailUrl) {
        dsf.layer.openWindow(this.detailUrl + id);
      }
    },
  },
});
</script>
<style lang="scss">
.dsf_study_task {
  width: 790px;
  .dsf_study_task_inner {
    padding: 0 10px;
  }
}
</style>
