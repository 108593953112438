<template>
  <div class="ds-control ds-navtab ds-nc-tabs" :class="{ 'ds-no-padding': !showPadding }" :style="{ height: isDesign ? '100%' : tabHeight }">
    <div class="ds-tab-title">
      <div
        v-for="(d, index) in slots.filter(tab => {
          return isHide(tab) !== true;
        })"
        :key="d.name"
        @click="tabChange(index)"
        :class="{ 'is-active': index === curIdx }"
      >
        {{ d.title }}
      </div>
    </div>
    <template
      v-for="(d, $index) in slots.filter(tab => {
        return isHide(tab) !== true;
      })"
    >
      <div :key="d.name" :label="d.title" :name="d.name" :slot-name="d.name" class="tab-pannel" v-if="$index === curIdx">
        <slot :name="d.name"></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcTitleFour",
  ctrlCaption: "选项卡",
  mixins: [$mixins.tabControl],
  props: {},
  data() {
    return {
      curIdx: 0,
    };
  },
  methods: {
    tabChange(idx) {
      console.log(1);
      this.curIdx = idx;
    },
  },
});
</script>
