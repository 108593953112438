var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_comment_dialog" }, [
    _c(
      "div",
      { staticClass: "dialog_inner" },
      [
        _c("div", { staticClass: "name" }, [_vm._v("课程评价")]),
        _c("el-rate", {
          attrs: {
            "show-score": "",
            "allow-half": "",
            disabled: _vm.isComment,
          },
          model: {
            value: _vm.rate,
            callback: function ($$v) {
              _vm.rate = $$v
            },
            expression: "rate",
          },
        }),
        !_vm.isComment
          ? _c(
              "button",
              { staticClass: "submit", on: { click: _vm.handleComment } },
              [_vm._v("评价")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }