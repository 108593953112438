var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-navtab ds-side-navtab",
      class: { "ds-no-padding": !_vm.showPadding },
      style: { height: _vm.isDesign ? "100%" : _vm.tabHeight },
      attrs: { id: "tab-pane" },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: {
            "tab-position": "left",
            type: _vm.tabStyle == "customer-tab-pad" ? "" : _vm.tabStyle,
            closable: _vm.enableDelete,
          },
          on: { "tab-click": _vm.tabClick, "tab-remove": _vm.tabRemove },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm._t("default"),
          _vm._l(
            _vm.slots.filter(function (tab) {
              return _vm.isHide(tab) !== true
            }),
            function (d) {
              return _c(
                "el-tab-pane",
                {
                  key: d.name,
                  style: { padding: _vm.$padding },
                  attrs: {
                    disabled: _vm.isdisable(d),
                    label: d.title,
                    name: d.name,
                    "slot-name": d.name,
                    lazy: _vm.isLazy(d),
                  },
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { class: "icon iconfont " + d.icon }),
                    _vm._v(" " + _vm._s(d.title) + " "),
                    d.badge !== null
                      ? _c("span", {
                          staticClass: "el-badge",
                          domProps: { innerHTML: _vm._s(d.badge) },
                        })
                      : _vm._e(),
                  ]),
                  _vm.isDesign ? _vm._t(d.name) : _vm._e(),
                  _vm.activeName == d.name && !_vm.isDesign
                    ? [_vm._t(d.name)]
                    : _vm._e(),
                ],
                2
              )
            }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }