var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_briefsummary", class: _vm.getCss },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            width: "50%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            "append-to-body": true,
            "custom-class": "nc_pc_briefsummary_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(_vm._s(_vm.titleName)),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formModel,
                "label-width": "80px",
                rules: _vm.rules,
                disabled: _vm.readyonly,
              },
            },
            [
              _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "div",
                  { staticClass: "conditions_content" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_item_form",
                        attrs: { label: "标题", prop: "title" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "dsf_input",
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formModel.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "title", $$v)
                            },
                            expression: "formModel.title",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "div",
                  { staticClass: "conditions_content" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_item_form",
                        attrs: { label: "内容", prop: "content" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "dsf_input",
                          attrs: {
                            type: "textarea",
                            rows: 10,
                            resize: "none",
                            placeholder: "请输入",
                          },
                          model: {
                            value: _vm.formModel.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "content", $$v)
                            },
                            expression: "formModel.content",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { staticClass: "dsf_btns" },
                [
                  _c("DsfButtonBar", {
                    attrs: {
                      buttons: _vm.buttons,
                      "max-number": _vm.maxNumber,
                    },
                    on: {
                      save: function ($event) {
                        return _vm.onSubmit("form")
                      },
                      close: _vm.onClose,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }