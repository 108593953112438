<template>
  <div class="dsf-shop-purchase-method">
    <div class="method-btns">
      <label>选择购买方式：</label>
      <el-radio v-for="(it, index) in items" :key="it.value" v-model="state" :label="it.value" @change="buyMethod(index)">{{ it.text }}</el-radio>
    </div>
    <div class="bottom-btns">
      <DsfButtonBar :buttons="buttons" @save="onReSubmit()" @close="onClose()" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfNcSpecialSourceControlItem",
  ctrlCaption: "商城购买方式",
  props: {
    items: {
      type: Array,
      default: () => {
        return [
          {
            text: "组织购买",
            value: "unit",
          },
          {
            text: "个人购买",
            value: "private",
          },
        ];
      },
    },
    buttons: {
      type: Array,
      default() {
        return [
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "取消",
            title: "",
            type: "plain",
          },
          {
            actionName: "save",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "确定",
            title: "",
          },
        ];
      },
    },
  },
  data() {
    return {
      idx: 0,
      curItem: this.items[0],
      state: "unit",
    };
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  mounted() {},
  methods: {
    buyMethod(idx) {
      this.curItem = this.items[idx];
    },
    onClose() {
      this.$dialog?.close();
    },
    onReSubmit() {
      this.$dialog?.options?.params?.cb(this.curItem);
      this.$dialog?.close();
    },
  },
};
</script>
