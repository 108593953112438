var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specialdetail_intro_teacher",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "intro_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.showMore
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !_vm.isview && !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm._e(),
        ]),
        _vm.showLine ? _c("span", { staticClass: "line" }) : _vm._e(),
      ]),
      _vm.lists.length
        ? _c(
            "ul",
            { staticClass: "teacher_wrap" },
            _vm._l(_vm.lists, function (item, index) {
              return _c("li", { key: index, staticClass: "teacher_items" }, [
                _c(
                  "div",
                  { staticClass: "head_img" },
                  [
                    _c("DsfNcImage", {
                      staticClass: "img_box",
                      attrs: {
                        src: _vm._f("imgFormat")(item.photo),
                        "error-src":
                          _vm.dsf.config
                            .setting_public_user_default_card_header,
                        width: "40px",
                        height: "40px",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "font name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                item.phone
                  ? _c(
                      "div",
                      { staticClass: "font phone" },
                      [
                        !item.chekPhone
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.getPhone(item.phone, 0))),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.getPhone(item.phone, 1))),
                            ]),
                        _vm._v("    "),
                        !item.chekPhone
                          ? _c("dsf-icon", {
                              attrs: { name: "biyan" },
                              on: {
                                click: function ($event) {
                                  !_vm.isview && _vm.checkPhone(index)
                                },
                              },
                            })
                          : _c("dsf-icon", {
                              attrs: { name: "yanjing1" },
                              on: {
                                click: function ($event) {
                                  return _vm.checkPhone(index)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _c("div", {
            staticClass: "ds-empty-data",
            domProps: { textContent: _vm._s("暂无数据") },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }