<template>
  <div class="ds-control ds_flexible_box_three" :style="boxStyle">
    <div :class="['insert', 'insert-left', { 'insert-wrap': isDesign }]" :style="{ width: leftBarStyle, margin: itemsStyle, border }">
      <div slot-name="flex-box-three-left" :class="['insert-content', { 'insert-border': isDesign }]">
        <slot name="flex-box-three-left"></slot>
      </div>
    </div>
    <div :class="['insert', 'insert-center', { 'insert-wrap': isDesign }]" :style="{ width: centerBarStyle, margin: itemsStyle, border }">
      <div slot-name="flex-box-three-center" :class="['insert-content', { 'insert-border': isDesign }]">
        <slot name="flex-box-three-center"></slot>
      </div>
    </div>
    <div :class="['insert', 'insert-right', { 'insert-wrap': isDesign }]" :style="{ width: rightBarStyle, margin: itemsStyle, border }">
      <div slot-name="flex-box-three-right" :class="['insert-content', { 'insert-border': isDesign }]">
        <slot name="flex-box-three-right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcFlexBoxThree",
  ctrlCaption: "三列",
  mixins: [$mixins.layout],
  props: {
    // 布局方式
    layout: {
      type: String,
      default: "row",
    },
    boxHeight: {
      type: String,
      default: "100%",
    },
    boxWidth: {
      type: String,
      default: "100%",
    },
    centerBarStyle: {
      type: String,
      default: "250px",
    },
    leftBarStyle: {
      type: String,
      default: "250px",
    },
    rightBarStyle: {
      type: String,
      default: "250px",
    },
    margin: {
      type: Array,
      default: () => [10, 10, 10, 10],
    },
    slots: {
      type: Array,
      default: function () {
        return [
          {
            name: "flex-box-three-left",
            controls: [],
          },
          {
            name: "flex-box-three-center",
            controls: [],
          },
          {
            name: "flex-box-three-right",
            controls: [],
          },
        ];
      },
    },
  },
  data() {
    return {
      border: this.isDesign ? " 1px dashed" : "none",
    };
  },
  computed: {
    itemsStyle() {
      let { margin } = this;
      let style = margin.join("px ") + "px";

      return style;
    },
    boxStyle() {
      let { isDesign } = this;
      let res = {
        "flex-direction": this.layout,
        height: this.boxHeight,
        width: this.boxWidth,
      };
      if (isDesign) {
        res.height = "500px";
      }
      // this.$dispatch("design-height-change", this.boxHeight);

      return res;
    },
  },
  created() {},
  methods: {},
});
</script>
