var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds_teacher_detail" }, [
        _c("div", { staticClass: "ds_teacher_detail_inner" }, [
          _c(
            "div",
            { staticClass: "cover" },
            [_c("el-image", { attrs: { src: _vm._f("imgFormat")(1) } })],
            1
          ),
          _vm._m(0),
          _c("button", { staticClass: "subcrible" }, [_vm._v("立即订阅")]),
        ]),
      ])
    : _c("div", { staticClass: "ds_teacher_detail" }, [
        _vm.it
          ? _c("div", { staticClass: "ds_teacher_detail_inner" }, [
              _c("div", { staticClass: "detail_inner" }, [
                _c(
                  "div",
                  { staticClass: "cover" },
                  [
                    _c("DsfNcDefaultHeader", {
                      staticClass: "default-avatar",
                      attrs: {
                        src: _vm.it.photo,
                        width: "180px",
                        height: "240px",
                        round: false,
                        defaultHeaderUrl: _vm.imgErrorSrc,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "desc" },
                  [
                    _c("div", { staticClass: "desc_top" }, [
                      _c("h3", { staticClass: "desc-name" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.it.name)),
                        ]),
                        _c("span", { staticClass: "position" }, [
                          _vm._v(_vm._s(_vm.it.position)),
                        ]),
                      ]),
                      _c("div", { staticClass: "dept ell" }, [
                        _c("span", [_vm._v(_vm._s(_vm.it.deptText))]),
                      ]),
                      _c("div", { staticClass: "abstracr ell4" }, [
                        _c("span", {
                          staticClass: "font",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.it.abstracts && _vm.it.abstracts.length > 220
                                ? _vm.it.abstracts.slice(0, 220) + "  ..."
                                : _vm.it.abstracts || "暂无数据"
                            ),
                          },
                        }),
                        _vm.it.abstracts && _vm.it.abstracts.length > 220
                          ? _c(
                              "span",
                              {
                                staticClass: "more",
                                on: {
                                  click: function ($event) {
                                    _vm.dialogTeacherVisible = true
                                  },
                                },
                              },
                              [_vm._v("查看更多")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "el-dialog",
                      {
                        staticClass: "teacher_dialog",
                        attrs: {
                          title: "师资介绍",
                          visible: _vm.dialogTeacherVisible,
                          width: "30%",
                          "append-to-body": "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogTeacherVisible = $event
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "dialog_font",
                          domProps: { innerHTML: _vm._s(_vm.it.abstracts) },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "info" }, [
                      _c("span", [
                        _c("b", [_vm._v(_vm._s(_vm.it.courseCount))]),
                        _vm._v(" 门课程 "),
                      ]),
                      _c("span", [
                        _c("b", [_vm._v(_vm._s(_vm.it.subscription))]),
                        _vm._v(" 人订阅 "),
                      ]),
                    ]),
                    _vm.it.adeptText
                      ? _c(
                          "div",
                          { staticClass: "tags" },
                          _vm._l(
                            _vm.it["adeptText"].split("^"),
                            function (item) {
                              return _c(
                                "span",
                                { key: item, staticClass: "tag" },
                                [_vm._v(_vm._s(item))]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "subcrible",
                    on: {
                      click: function ($event) {
                        return _vm.toSubscrible(_vm.it)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.it["mySubscribed"] ? "取消订阅" : "立即订阅")
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("h3", { staticClass: "desc-name" }, [
        _c("span", { staticClass: "name" }, [_vm._v("名称")]),
      ]),
      _c("div", { staticClass: "dept" }, [_c("span", [_vm._v("阿里巴巴")])]),
      _c("div", { staticClass: "info" }, [
        _c("span", [_c("b", [_vm._v("35")]), _vm._v(" 门课程 ")]),
        _c("span", [_c("b", [_vm._v("25")]), _vm._v(" 人订阅 ")]),
      ]),
      _c("div", { staticClass: "tags" }, [
        _c("span", { staticClass: "tag" }, [_vm._v("标签")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }