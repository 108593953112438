var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_statistical_summary" }, [
    _c(
      "ul",
      { staticClass: "card" },
      _vm._l(_vm.menus, function (item, idx) {
        return _c(
          "li",
          {
            key: idx,
            style: { width: item.width, background: item.bg },
            on: {
              click: function ($event) {
                return _vm.itemClick(item)
              },
            },
          },
          [
            _c("p", [_c("dsf-icon", { attrs: { name: item.icon } })], 1),
            _c("div", [
              _c("span", [_vm._v(_vm._s(_vm.resData[item.field] || 0))]),
              _c("label", { attrs: { for: "" } }, [_vm._v(_vm._s(item.name))]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }