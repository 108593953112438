import { render, staticRenderFns } from "./homePanel.vue?vue&type=template&id=600dfcc2&"
import script from "./homePanel.vue?vue&type=script&lang=js&"
export * from "./homePanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/components/panel/homePanel.vue"
export default component.exports