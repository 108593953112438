<template>
  <div class="dsf_nc_zxqy_footer" :style="{ width: itemWidth, margin: margin }">
    <div class="nc_footer_inner">
      <div class="one_wrap">
        <div class="img">
          <dsf-nc-image :src="sydwImg" />
        </div>
        <div class="img">
          <dsf-nc-image :src="zfwzImg" />
        </div>
      </div>
      <div class="two_wrap">
        <div class="address">{{ address }}</div>
        <div class="email">{{ email }}</div>
        <div class="copy">{{ copy }}</div>
        <div class="records">{{ records }}</div>
      </div>
      <div class="three_wrap">
        <div class="servephone">
          <p>{{ servephoneText }}</p>
          <p>{{ servephoneNo1 }}</p>
          <p>{{ servephoneNo2 }}</p>
        </div>
        <div class="card_serve_phone">
          <p>{{ cardServephoneTxt }}</p>
          <p>{{ cardServephoneNo1 }}</p>
        </div>
      </div>
      <div class="four_wrap">
        <div class="app qr_box">
          <span>{{ appValue[0] || "" }}</span>
          <dsf-nc-image :src="appValue[1]"></dsf-nc-image>
        </div>
        <div class="wechat qr_box">
          <span>{{ wechatValue[0] || "" }}</span>
          <dsf-nc-image :src="wechatValue[1]"></dsf-nc-image>
        </div>
        <div class="TikTok qr_box">
          <span>{{ tiktokValue[0] || "" }}</span>
          <dsf-nc-image :src="tiktokValue[1]"></dsf-nc-image>
        </div>
        <div class="video qr_box">
          <span>{{ videoValue[0] || "" }}</span>
          <dsf-nc-image :src="videoValue[1]"></dsf-nc-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcZxqyFooter",
  ctrlCaption: "中小企业门户页脚",
  mixins: [$mixins.control],
  props: {
    itemWidth: {
      type: String,
      default: "100%",
    },
    margin: {
      type: String,
      default: "20px 0 0 0",
    },
    sydwImg: {
      type: String,
      default: "static/img/product/footerico2.png",
    },
    zfwzImg: {
      type: String,
      default: "static/img/product/footerico1.png",
    },
    address: {
      type: String,
      default: "地址：上海市大木桥路108号6楼",
    },
    email: {
      type: String,
      default: "邮箱：qyfwy@sheitc.sh.gov.cn",
    },
    copy: {
      type: String,
      default: "版权所有 上海市中小企业发展服务中心保留所有权利",
    },
    records: {
      type: String,
      default: "网站备案号：沪ICP020577号",
    },
    servephone: {
      type: String,
      default: "服务云咨询热线：, 021-64225181, 021-64225003",
    },
    cardServephone: {
      type: String,
      default: "一网通办咨询热线：, 12345",
    },
    app: {
      type: String,
      default: "APP,static/img/product/footerQR_app.png",
    },
    wechat: {
      type: String,
      default: "微信,static/img/product/footerQR_weixin.png",
    },
    tiktok: {
      type: String,
      default: "抖音,static/img/product/footerQR_Tik_Tok.png",
    },
    video: {
      type: String,
      default: "视频号,static/img/product/footerQR_sph2021.png",
    },
  },
  computed: {
    appValue() {
      return this.app.split(",");
    },
    wechatValue() {
      return this.wechat.split(",");
    },
    tiktokValue() {
      return this.tiktok.split(",");
    },
    videoValue() {
      return this.video.split(",");
    },
    servephoneText() {
      let text = (this.servephone && this.servephone.split(",")) || "";
      text = this.servephone.split(",")[0] || "";
      return text;
    },
    servephoneNo1() {
      let text = (this.servephone && this.servephone.split(",") && this.servephone.split(",").length > 0) || "";
      text = this.servephone.split(",")[1];
      return text;
    },
    servephoneNo2() {
      let text = (this.servephone && this.servephone.split(",") && this.servephone.split(",").length > 1) || "";
      text = this.servephone.split(",")[2];
      return text;
    },
    cardServephoneTxt() {
      let text = (this.cardServephone && this.cardServephone.split(",")) || "";
      text = this.cardServephone.split(",")[0] || "";
      return text;
    },
    cardServephoneNo1() {
      let text = (this.cardServephone && this.cardServephone.split(",") && this.cardServephone.split(",").length > 0) || "";
      text = this.cardServephone.split(",")[1];
      return text;
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
});
</script>
