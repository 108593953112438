var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_saas saas_setting_page" },
    [
      _c("div", { staticClass: "title t1 margint20 marginb20" }, [
        _vm._v("页面功能设置"),
      ]),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("dsf-button", { on: { click: _vm.createPage } }, [
            _vm._v("新增页面"),
          ]),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入检索关键字" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getData.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.searchValue,
                    callback: function ($$v) {
                      _vm.searchValue = $$v
                    },
                    expression: "searchValue",
                  },
                },
                [
                  _c("dsf-icon", {
                    attrs: { slot: "append", name: "fangdajing" },
                    on: { click: _vm.getData },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.pageList, stripe: "", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "页面名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "p",
                      { staticClass: "table_name" },
                      [
                        scope.row.pageStatus == "1"
                          ? _c("dsf-icon", {
                              attrs: { name: "shoucang_shixin" },
                            })
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(scope.row.name))]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "dsUpdateTime", label: "修改时间", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "300px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "tablebtns" },
                      _vm._l(_vm.buttons, function (btn) {
                        return _c(
                          "el-button",
                          {
                            key: btn.action,
                            style: {
                              display:
                                scope.row.pageStatus == "1" &&
                                (btn.action == "set_home_page" ||
                                  btn.action == "row_delete")
                                  ? "none"
                                  : "unset",
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.clickoption(btn, scope)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(btn.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }