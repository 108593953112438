<template>
  <DsfSection
    mainWidth="1200px"
    :margin="[0, 0, 0, 0]"
    :padding="[10, 10, 10, 10]"
    :backgroundConfig="{ color: '#F9F9F9', image: '', position: 'center', repeat: 'no-repeat', size: 'cover' }"
    :foregroundConfig="{
      color: 'rgba(255, 255, 255, 0)',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
  >
    <!--nctitle-->
    <DsfNcTitle v-if="showTitle" :title="title" caption="DsfNcTitle" :showBottomLine="true" titleSize="big" :titleBold="false" :clickConfig="clickConfigTitle" padding="80px 0 60px 0"></DsfNcTitle>
    <!--platformcardbox-->
    <DsfPlatformCardBox
      :columnNumber="3"
      columnWidth="384px"
      marginTop="15px"
      rowKey="_id"
      url="/inc/meta/list/data?pageNum=1&pageSize=6&query=%7B%22searchValue%22%3A%22%22%7D&order=%5B%5D&filter=%5B%5D&namespace=nc.class.page&pageName=main&t=1623231933366"
      :checkbox="false"
    >
      <template v-slot:default="scope">
        <!--ncspecilacard-->
        <DsfNcSpecilaCard :local="scope.row" :keysMap="keysMap" :clickConfig="clickConfig" :click-before="checkLogin"></DsfNcSpecilaCard>
      </template>
    </DsfPlatformCardBox>
  </DsfSection>
</template>

<script>
export default dsf.component({
  name: "DsfNcSaasHomeZhuantipeixun",
  ctrlCaption: "专题培训",
  mixins: [$mixins.control],
  props: {
    moreText: {
      type: String,
      default: "查看更多",
    },
    sourceUrl: {
      type: String,
      default: "/inc/meta/list/data?pageNum=1&pageSize=6&query=%7B%22searchValue%22%3A%22%22%7D&order=%5B%5D&filter=%5B%5D&namespace=nc.class.page&pageName=main",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      clickConfig: {
        url: "/page.html#/pc/nc/pagespecial/specialDetail?id=@[_id]",
        state: 2,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
      clickConfigTitle: {
        url: "/ncIndex.html#/pc/nc/pagespecial/specialList",
        state: 4,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
      keysMap: [
        {
          from: "nc_class_page_mainData.cover",
          to: "cover",
          type: "text",
          desc: "封面",
        },
        {
          from: "nc_class_page_mainData.name",
          to: "title",
          type: "text",
          desc: "课题",
        },
        {
          from: "nc_class_page_mainData.pxsj_sdate",
          to: "sDate",
          type: "text",
          desc: "开始时间",
        },
        {
          from: "nc_class_page_mainData.pxsj_edate",
          to: "eDate",
          type: "text",
          desc: "结束时间",
        },
        {
          from: "nc_class_page_mainData.compulsoryperiod",
          to: "compulsoryHours",
          type: "text|number",
          desc: "必修学时",
          prefix: "必修学时：",
        },
        {
          from: "nc_class_page_mainData.electivesperiod",
          to: "electivesHours",
          type: "text|number",
          desc: "选修学时",
          prefix: "选修学时：",
        },
        {
          from: "nc_class_page_mainData.current_state_value",
          to: "stateValue",
          type: "text|number",
          desc: "状态值",
        },
        {
          from: "nc_class_page_mainData.current_state_text",
          to: "stateText",
          type: "text",
          desc: "状态文字",
        },
        {
          from: "nc_class_page_mainData.studentcount",
          to: "views",
          type: "text|number",
          desc: "浏览量",
        },
      ],
    };
  },
  computed: {
    title() {
      return this.data["bindAttr"]["title"];
    },
    showTitle() {
      return this.data["bindAttr"]["showTitle"] == 1 ? true : false;
    },
    showMore() {
      return this.data["bindAttr"]["showMore"] == 1 ? true : false;
    },
  },
  created() {},
  mounted() {},
  methods: {
    checkLogin: function () {
      return this.$checkLogin();
    },
  },
});
</script>
