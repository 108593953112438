<template>
  <div class="dsf_nc_alibanner">
    <div id="accordion" class="dsf_nc_alibanner_inner" v-if="isDesign">
      <div class="alibanner_container">
        <div class="alibanner_wrapper">
          <div class="alibanner_img_box" :class="eleindex == index ? (index == 0 ? 'banneractive isFirstaActive' : 'banneractive') : ''" v-for="(ele, index) in lists" :key="index">
            <dsf-nc-image :src="ele.cover | imgFormat" :error-src="defaultSrc" />
            <span class="alibanner_title">{{ ele.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div id="accordion" class="dsf_nc_alibanner_inner" v-if="!isDesign">
      <div class="alibanner_container">
        <div class="alibanner_wrapper">
          <div
            class="alibanner_img_box"
            :class="eleindex == index ? (index == 0 ? 'banneractive isFirstaActive' : 'banneractive') : ''"
            :style="eleindex == index ? { flex: activePercent } : ''"
            v-for="(ele, index) in lists"
            :key="index"
            @mouseenter="enter(index)"
            @mouseleave="out"
            @click="_clickBefore(handleClick, ele)"
          >
            <dsf-nc-image :src="ele.cover | imgFormat" :error-src="defaultSrc" />
            <div :style="index == 0 ? { background: `url(${setBgImage(ele.cover)})`, left: firstLeft } : { background: `url(${setBgImage(ele.cover)})` }" class="alibanner_title">
              <span class="title_font">{{ ele.title }}</span>
              <span class="mask"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcAliBanner",
  ctrlCaption: "阿里学堂轮播",
  mixins: [$mixins.control],
  props: {
    loopNumber: {
      type: Number,
      default: 3,
    },
    sourceUrl: {
      type: String,
      default: "/inc/nc/banner/pclist",
    },
    detailUrl: {
      type: Object,
      default: () => ({}),
    },
    titlesColor: {
      type: String,
      default: "#D19C00,#84000B,#CB5C00",
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      eleindex: 0,
      lists: [
        {
          cover:
            '[{"originalFileName":"轮播图3.jpg","name":"轮播图3","suffix":"jpg","size":86951,"contentType":"image/jpeg","relativePath":"http://39.103.137.108:89/dsf5/upload/files/20210922/a9ee76606ff24ed4ae461a401da70f4a.png","absolutePath":"http://39.103.137.108:89/dsf5/upload/files/20210922/a9ee76606ff24ed4ae461a401da70f4a.png","uploadDate":"2021-06-26 12:43:39","id":"b47a4aee9ccc47f9b401adb9ddaf5348"}]',
          imgUrl: "http://39.103.137.108:89/dsf5/upload/files/20210922/a9ee76606ff24ed4ae461a401da70f4a.png",
          opentype_text: "新页面",
          opentype_value: "2",
          title: "互联网大图1111",
          url: null,
          _id: "eddde46b22404772909420cddadfc891",
        },
        {
          cover:
            '[{"originalFileName":"轮播图3.jpg","name":"轮播图3","suffix":"jpg","size":86951,"contentType":"image/jpeg","relativePath":"/static/img/product/defaultCardImage.png","absolutePath":"/static/img/product/defaultCardImage.png","uploadDate":"2021-06-26 12:43:39","id":"b47a4aee9ccc47f9b401adb9ddaf5348"}]',
          imgUrl: "/static/img/product/defaultCardImage.png",
          opentype_text: "新页面",
          opentype_value: "2",
          title: "互联网大图2222",
          url: null,
          _id: "eddde46b22404772909420cddadfc891",
        },
        {
          cover:
            '[{"originalFileName":"轮播图3.jpg","name":"轮播图3","suffix":"jpg","size":86951,"contentType":"image/jpeg","relativePath":"http://39.103.137.108:89/dsf5/upload/files/20210831/6af8346fcddc478980fe9dbec1c598d1.jpeg","absolutePath":"http://39.103.137.108:89/dsf5/upload/files/20210831/6af8346fcddc478980fe9dbec1c598d1.jpeg","uploadDate":"2021-06-26 12:43:39","id":"b47a4aee9ccc47f9b401adb9ddaf5348"}]',
          imgUrl: "http://39.103.137.108:89/dsf5/upload/files/20210831/6af8346fcddc478980fe9dbec1c598d1.jpeg",
          opentype_text: "新页面",
          opentype_value: "2",
          title: "互联网大图3333",
          url: null,
          _id: "eddde46b22404772909420cddadfc891",
        },
      ],
      params: {
        code: "001",
        pageSize: this.loopNumber,
      },
      activePercent: "1 1 82%",
      firstLeft: 0,
      bannerLength: 3,
    };
  },
  mounted() {
    this.setActiveStyle(this.lists); // 设置选择样式
    if (!this.isDesign) {
      this.lists = [];
      this.initSwiper();
    }
  },
  methods: {
    initSwiper() {
      const loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.sourceUrl, this.params, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.lists = data;
            this.bannerLength = this.lists.length;

            if (!this.bannerLength) return;
            this.setActiveStyle(); // 设置选择样式
          }
        })
        .error(err => {
          this.error(err);
          console.log(err);
        })
        .always(() => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    setActiveStyle() {
      let length = this.bannerLength;
      let boxWidth = document.getElementById("accordion").clientWidth - 40;

      this.firstLeft = boxWidth - length * 60;
      let math = (this.firstLeft / boxWidth) * 100;
      this.activePercent = `1 1 ${Math.round(math) + 1 + "%"}`;
      this.firstLeft = this.firstLeft + length + "px";
    },
    setBgImage(v) {
      if (dsf.isUnDef(v) || v == "") {
        return dsf.config.setting_public_card_default_img;
      }
      let obj = v;
      if (dsf.type(obj) == "string") {
        if (obj.indexOf("relativePath") == -1 || obj.indexOf("absolutePath") == -1) {
          return v;
        }
        return dsf.getFilePath(obj);
      }
      return dsf.config.setting_public_card_default_img;
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    enter(index) {
      let length = this.bannerLength;
      let boxWidth = document.getElementById("accordion").clientWidth - 40;
      if (index) {
        this.firstLeft = "0px";
      } else {
        this.firstLeft = boxWidth - length * 60 + length + "px";
      }
      this.eleindex = index;
    },
    handleClick(item) {
      // if (!this.itemConfig) return;
      // let { url, state } = this.detailUrl;
      // url = this.$replace(url, item);
      // this.$open({ url, state });
      this.$open({ url: item.url, state: item.opentype_value });
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.$checkLogin();
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
    out() {
      console.log("out==>移出");
    },
  },
});
</script>
