<template>
  <div class="ds-nc-shopping-myorder-pc" :style="getStyle">
    <div class="ds-nc-shopping-myorder-top">
      <div class="status">
        <label>交易状态：</label>
        <el-select v-model="status" filterable placeholder="请选择" @change="handleSearch">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-input placeholder="请输入搜索内容" v-model="searchText" @keyup.enter.native="handleSearch">
          <el-button slot="append" @click="handleSearch">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="order-tabel">
      <td class="col-1">商品信息</td>
      <td class="col-2">商品类型</td>
      <td class="col-3">购买方式</td>
      <td class="col-4 margin-right-22">价格</td>
      <td class="col-5">小计</td>
      <td class="col-6">交易状态</td>
      <td class="col-7">操作</td>
    </div>
    <div class="content" v-if="list.length">
      <template v-for="item in list">
        <div class="order-list" :key="item.indentId">
          <div class="order-title">
            <div>
              <span class="time">{{ item.ds_create_time }}</span>
              <span class="order-number">订单号：{{ item.indentCode }}</span>
            </div>
            <div class="handle pointer" @click="deleteOrder(item)" v-if="item.status_value !== 'success'">删除</div>
          </div>
          <div class="order-row">
            <div class="left col-1" :class="{ rightBorder: item.indetDetail.length > 1 }">
              <template v-for="i in item.indetDetail">
                <div class="row-detail" :key="i.id" :class="{ bottomBorder: item.indetDetail.length > 1 }">
                  <div class="products col-1 pointer" @click="handleProductDetail(i)">
                    <div class="img-box">
                      <dsf-nc-image :src="i['cover'] | imgFormat" :error-src="defaultSrc" />
                    </div>
                    <div class="products-details">
                      <div class="title">{{ i.dGoodName }}</div>

                      <!-- 专题班 -->
                      <div class="center" v-if="i.dgoodTypeValue === 'class'">
                        <span>必修：{{ i.period || 0 }}学时</span>
                        <span>选修：{{ i.noNeedPeriod || 0 }}学时</span>
                      </div>
                      <!-- 直播 -->
                      <div class="center" v-if="i.dgoodTypeValue === 'live'">
                        <span>教师：{{ i.teachText || "" }}</span>
                        <span>职务：{{ i.teacherUnitName || "" }}</span>
                      </div>
                      <!-- 频道 -->
                      <div class="center" v-if="i.dgoodTypeValue === 'channel'">
                        <span>资源数：{{ i.subNum || 0 }}</span>
                        <span>学时：{{ i.period || 0 }}学时</span>
                        <span>销量：{{ i.sales }}</span>
                      </div>
                      <!-- 课程 -->
                      <div class="center" v-if="i.dgoodTypeValue === 'course'">
                        <span>教师：{{ i.teachText || "" }}</span>
                        <span>销量：{{ i.sales }}</span>
                      </div>

                      <div class="card">
                        <div class="card-text" v-if="i.giftPayment">赠</div>
                        <div v-if="i.giftCoupon">
                          <dsf-icon class="icon-quan"></dsf-icon>
                          <span>1</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 align-item">{{ i.dgoodTypeText }}</div>
                  <div class="col-3 align-item">{{ i.dshopTypeText }}</div>
                  <div class="col-4 align-item prices">
                    <p class="price">
                      <dsf-icon class="icon-jinbi"></dsf-icon>
                      <span class="status-text">{{ i.dRealPrice }}</span>
                    </p>
                    <!-- 优惠活动 -->
                    <!-- <p class="price-top">
                      <dsf-icon class="icon-jinbi"></dsf-icon>
                      <span class="status-text">{{ i.dOriginalPrice }}</span>
                    </p> -->
                  </div>
                </div>
              </template>
            </div>
            <div class="right">
              <div class="money align-item col-5">
                <dsf-icon class="icon-jinbi"></dsf-icon>
                <span>{{ item.realPrice }}</span>
              </div>
              <div class="status align-item col-6">
                <p>
                  <span class="status-text">{{ item.isOverTimeValue === "over" ? "已取消" : item.status_text }}</span>
                </p>
                <div class="countDown" v-if="item.isOverTimeValue !== 'over' && item.status_value === 'await'">
                  <p>
                    <span class="down">!</span>
                    <span>倒计时</span>
                  </p>
                  <span class="time" :time="interValObj['countDown' + item.indentId]" :id="'countDown' + item.indentId">
                    {{ interValObj["countDown" + item.indentId] }}
                  </span>
                </div>
              </div>
              <div class="handles align-item col-7">
                <p class="pointer pay" v-if="item.status_value === 'await' && item.isOverTimeValue === 'notOver'" @click="handleClickPay(item)">
                  立即支付
                </p>
                <p class="pointer viewdetail" @click="handleClickView(item)">查看详情</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="noData">
      暂无订单，快去
      <span class="goOrder pointer" @click="openShop">下单</span>
      吧！
    </div>
    <div class="ds-datagrid-pager">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper,sizes"
        :total="total"
        class="style3"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcShoppingMyOrder",
  ctrlCaption: "我的订单",
  mixins: [$mixins.control],
  components: {},
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "success",
          label: "已付款",
        },
        {
          value: "await",
          label: "待付款",
        },
        {
          value: "cancel",
          label: "已取消",
        },
        // {
        //   value: "refunding",
        //   label: "退款中",
        // },
        // {
        //   value: "refunded",
        //   label: "已退款",
        // },
      ],
      list: [],
      status: "",
      searchText: "",
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      interValObj: {},
    };
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    shopPath: {
      type: String,
      default: "/ncIndex.html#/pc/nc/page/pc/home/onlineshop",
    },
  },
  computed: {
    getStyle() {
      let style = {
        width: this.width,
      };
      return style;
    },
  },
  mounted() {
    if (!this.isDesign) {
      this.getData();
    }
  },
  methods: {
    getData() {
      const loading = dsf.layer.loading();
      let params = {
        pageNum: this.pageNumber,
        pageSize: this.pageSize,
        statusValue: this.status,
        goodName: this.searchText,
      };
      const header = {
        "Content-Type": "application/json",
      };
      this.interValObj = {};
      this.dsf.http
        .get("/nc/shop/shopIndent/myIndent", params, { header }, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.list = res.data.list;
            this.total = Number(res.data.total || 0);
            this.list.length &&
              this.list.forEach((item, index) => {
                if (item.isOverTimeValue !== "over" && item.status_value === "await") {
                  if (item.countDown) {
                    this.setIntervalFun(item);
                  }
                }
              });
          } else {
            this.dsf.layer.message(res.message || "获取订单异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取订单异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    deleteOrder(item) {
      //待审核
      dsf.layer
        .confirm({ message: "是否删除订单？", title: "提示", confirmButtonText: "确定", cancelButtonText: "取消" })
        .then(() => {
          const loading = dsf.layer.loading();
          let params = {
            indentId: item.indentId,
            type: "remove",
          };

          this.dsf.http
            .post("/nc/shop/shopIndent/dealIndent", params, $$webRoot.nc)
            .done(res => {
              if (res.success) {
                this.dsf.layer.message(res.message || "删除订单成功", true);
                this.getData();
              } else {
                this.dsf.layer.message(res.message || "删除异常", false);
              }
            })
            .error(response => {
              this.dsf.layer.message(response.message || "删除异常", false);
            })
            .always(function () {
              dsf.layer.closeLoading(loading);
            });
        })
        .catch(() => {
          this.$root.close();
          console.log("取消");
        });
    },
    setIntervalFun(item) {
      item.countDown = parseInt(item.countDown / 1000) * 1000;
      this.interValObj["timer" + item.indentId] = setInterval(() => {
        item.countDown = Number(item.countDown) - 1000;
        if (item.countDown <= 0) {
          item.countDown = 0;
          this.$set(this.interValObj, "countDown" + item.indentId, "00:00:00");
          this.$set(item, "isOverTimeValue", "over");
          clearInterval(this.interValObj["timer" + item.indentId]);
        } else {
          this.$set(this.interValObj, "countDown" + item.indentId, this.formatDuring(item.countDown));
        }
        // this.$set(this.interValObj, "countDown" + item.indentId, this.formatterDate(item.countDown, "hh:ii:ss"));
      }, 1000);
    },
    formatterDate(time, format) {
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    formatDuring(mss) {
      // let days = parseInt(mss / (1000 * 60 * 60 * 24));
      let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      if (hours < 10) {
        hours = "0" + hours;
      }
      let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let seconds = (mss % (1000 * 60)) / 1000;
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds + "";
    },

    handleSearch() {
      this.pageNumber = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageNumber = 1;
      this.pageSize = val;
      this.getData();
    },
    openShop() {
      let showShop = dsf.config.setting_nc_tenement_is_shop == 1 || false;
      if (showShop) {
        this.$open({ url: this.shopPath, state: 2 });
      } else {
        dsf.layer.message("该组织商城未开启", false);
      }
    },
    handleProductDetail(item) {
      this.$open({ url: "page.html#/pc/nc/page/pc/shop/shopitemdetail?type=" + item.dgoodTypeValue + "&id=" + item.goodId, state: 2 });
    },
    handleClickPay(item) {
      this.$open({ url: "page.html#/pc/nc/page/pc/shop/shoporders?id=" + item.indentId, state: 2 });
    },
    handleClickView(item) {
      this.$open({ url: "page.html#/pc/nc/page/pc/shop/shoporders?isView=1&id=" + item.indentId, state: 2 });
    },
  },
});
</script>
