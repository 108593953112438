<template>
  <div class="dsf_globalSearch">
    <div class="dsf_globalSearch_inner">
      <div class="search-header">
        <div class="search-box">
          <input type="text" placeholder="请输入检索内容" v-model="keywords" />
          <dsf-icon name="sousuo1" @click="loadSearchList" />
        </div>
        <p class="search-result">
          搜索结果 ：
          <strong v-html="resText" class="search-result-text"></strong>
        </p>
        <div class="search-result-menus" v-if="resList.length > 0">
          <dl :class="['search-result-menus-name', { 'search-result-menus-name-sel': tabIdx == index }]" v-for="(item, index) in resList" :key="item.name" @click="changeTabs(index, item)">
            {{ item.name }}
          </dl>
        </div>
      </div>
      <div class="search-result-content" v-for="(item, index) in resList" :key="'item' + item.name">
        <div class="search-result-content-item" v-if="tabIdx == index">
          <div class="content-item" v-if="item.name == '全部'">
            <allSearchColumn :data="item.data" @toDetail="toDetail" :pageType="pageType" />
          </div>
          <div class="content-item" v-if="item.name == '新闻'">
            <newsColumn v-for="d in item.data" :key="'newsColumn' + d.id" :data="d" @toDetail="toDetail" :pageType="pageType" />
          </div>
          <div class="content-item" v-else-if="item.name == '课程'">
            <courseColumn v-for="d in item.data" :key="'courseColumn' + d.id" :data="d" @toDetail="toDetail" :pageType="pageType" />
          </div>
          <div class="content-item" v-else-if="item.name == '专题班'">
            <specialColumn v-for="d in item.data" :key="'specialColumn' + d.id" :data="d" @toDetail="toDetail" :pageType="pageType" />
          </div>
          <div class="content-item" v-else-if="item.name == '频道'">
            <channelColumn v-for="d in item.data" :key="'channelColumn' + d.id" :data="d" @toDetail="toDetail" :pageType="pageType" />
          </div>
          <div class="content-item" v-else-if="item.name == '名师'">
            <teacherColumn v-for="d in item.data" :key="'teacherColumn' + d.id" :data="d" @toDetail="toDetail" :pageType="pageType" />
          </div>
          <div class="content-item" v-else-if="item.name == '考试'">
            <examColumn v-for="d in item.data" :key="'examColumn' + d.id" :data="d" @toDetail="toDetail" :pageType="pageType" />
          </div>
          <div class="content-item" v-else-if="item.name == '直播'">
            <liveColumn v-for="d in item.data" :key="'examColumn' + d.id" :data="d" @toDetail="toDetail" :pageType="pageType" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import allSearchColumn from "../../base/search/allSearchColumn.vue";
import courseColumn from "../../base/search/courseColumn.vue";
import specialColumn from "../../base/search/specialColumn.vue";
import examColumn from "../../base/search/examColumn.vue";
import newsColumn from "../../base/search/newsColumn.vue";
import channelColumn from "../../base/search/channelColumn.vue";
import teacherColumn from "../../base/search/teacherColumn.vue";
import liveColumn from "../../base/search/liveColumn.vue";
export default dsf.component({
  name: "DsfNcGlobalSearch",
  ctrlCaption: "全局搜索",
  mixins: [$mixins.control],
  props: {
    searchUrl: {
      type: String,
      default: "nc/search/searchDocumentGroup",
    },
    courseSourceUrl: {
      type: String,
      default: "/page.html#/pc/nc/pagecourse/coursePlayer?id=",
    },
    specialSourceUrl: {
      type: String,
      default: "/page.html#/pc/nc/pagespecial/specialDetail?id=",
    },
    channelSourceUrl: {
      type: String,
      default: "/page.html#/pc/nc/pagechannel/channelDetail?id=",
    },
    teacherSourceUrl: {
      type: String,
      default: "/page.html#/pc/nc/pageteacher/teachersDetail?id=",
    },
    liveSourceUrl: {
      type: String,
      default: "/page.html#/pc/nc/pagelive/liveDetail?id=",
    },
    // 页面类型
    pageType: {
      type: String,
      default: "tacitly",
    },
    newsSourceUrl: {
      type: String,
      default: "/page.html#pc/nc/page/pc/news/newsDetail?id=",
    },
  },
  data() {
    return {
      resList: [],
      resText: "暂无数据，您可以试试其他类型",
      keywords: this.$route.query.kw || "",
      tabIdx: 0,
      tabType: null,
      prefixText: {
        "00": "门", //课程
        "01": "个", //频道
        "05": "个", //专题班
        11: "条", //新闻
        12: "位", //名师
        14: "场", //直播
      },
      pageNum: 1,
      loading: false,
      loaded: false,
    };
  },
  components: {
    allSearchColumn,
    courseColumn,
    specialColumn,
    examColumn,
    newsColumn,
    channelColumn,
    teacherColumn,
    liveColumn,
  },
  created() {
    let that = this;
    if (!this.isDesign) {
      document.onkeydown = function (e) {
        let key = e.keyCode;
        if (key == 13) {
          that.loadSearchList();
        }
      };
    }
  },
  mounted() {
    this.loadSearchList();
    window.addEventListener("scroll", this.globalSearchScroll, true);
  },
  methods: {
    loadSearchList() {
      let that = this;
      this.resList = [];
      this.resText = "";
      const { prefixText } = this;
      const loading = dsf.layer.pc.loading();
      let params = {
        pageNum: 1,
        pageSize: 100,
        searchContent: this.keywords,
      };
      dsf.http
        .get(this.searchUrl, params, $$webRoot.nc)
        .done(res => {
          let { data, success, message } = res;
          if (success) {
            if (data.length > 0) {
              let text = "共";
              let allItem = [];
              let othItem = [];
              _.each(data, function (item, idx) {
                let temp = {
                  name: item.typename,
                  data: item.result,
                  type: item.typeid,
                };
                allItem.push(...item.result);
                text += `<span class="search-result-text"><a>${item.group_count}</a>${prefixText[item.typeid]}${item.typename}${idx == data.length - 1 ? "" : "、"}</span>`;
                othItem.push(temp);
              });
              this.resText = text;
              this.resList = [
                {
                  name: "全部",
                  data: allItem,
                },
                ...othItem,
              ];
              console.log(this.resList);
            } else {
              that.resText = "暂无数据，您可以试试其他类型";
            }
          } else {
            dsf.layer.pc.message(message, false);
          }
        })
        .error(({ message }) => {
          dsf.layer.pc.message(message, false);
        })
        .always(() => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    loadAllSearchList() {
      const loading = this.loading ? "" : dsf.layer.pc.loading();
      let params = {
        searchContent: this.keywords,
        serchType: this.tabType,
        pageNum: this.pageNum,
        pageSize: 20,
      };
      dsf.http
        .get("nc/search/searchAllByKeyWord", params, $$webRoot.nc)
        .done(res => {
          let { data, success, message } = res;
          if (success) {
            let resData = this.resList;
            let idx = resData.findIndex(it => it.type == this.tabType);
            let d = data.result;
            if (this.loading) {
              if (d.length > 0) {
                this.resList[idx]["data"] = [...this.resList[idx]["data"], ...d];
              } else {
                this.loaded = true;
              }
            } else {
              this.resList[idx]["data"] = d;
            }
            this.loading = false;
          } else {
            dsf.layer.pc.message(message, false);
          }
        })
        .error(({ message }) => {
          dsf.layer.pc.message(message, false);
        })
        .always(() => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    changeTabs(idx, item) {
      if (this.$listeners["tabChange"]) {
        this.$emit("tabChange", { idx, item });
      } else {
        let { type } = item;
        this.tabIdx = idx;
        this.tabType = type || null;
        this.loaded = false;
        // this.loading = false;
        this.pageNum = 1;
        type && this.loadAllSearchList();
      }
    },
    toDetail(it) {
      let ev = null;
      const { courseSourceUrl, specialSourceUrl, channelSourceUrl, teacherSourceUrl, liveSourceUrl, newsSourceUrl } = this;
      const config = {
        course: courseSourceUrl,
        special: specialSourceUrl,
        channel: channelSourceUrl,
        teacher: teacherSourceUrl,
        live: liveSourceUrl,
        news: newsSourceUrl,
      };
      const { name, id } = it;
      const path = config[name] + id;
      if (this.$listeners["detailClick"]) {
        this.$emit("detailClick", { it, path });
      } else {
        config[name] && dsf.layer.openWindow(path);
      }
    },
    globalSearchScroll() {
      this.$nextTick(() => {
        if (this.loading || this.loaded) return;
        const winH = window.innerHeight;
        const headH = document.querySelector(".search-header")?.offsetHeight;
        const docH = document.querySelector(".search-result-content")?.offsetHeight;
        let scrollTop = document.querySelector(".dsf_globalSearch")?.scrollTop;
        if (scrollTop + winH > docH + headH) {
          if (this.tabType) {
            this.loading = true;
            ++this.pageNum;
            this.tabType && this.loadAllSearchList();
          }
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.globalSearchScroll);
  },
});
</script>
