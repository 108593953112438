var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "DsfNcPcDataCenter", style: { width: _vm.width } },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "container-item" }, [
              _c("h3", { staticClass: "item-title" }, [_vm._v("学习汇总统计")]),
              _c("div", { staticClass: "item-content" }, [
                _c(
                  "ul",
                  { staticClass: "items-study" },
                  _vm._l(_vm.studySummary, function (item, index) {
                    return _c("li", { key: index }, [
                      _c("div", { staticClass: "items-study-item" }, [
                        _c("h4", { staticClass: "study-item-title" }, [
                          _vm._v(_vm._s(item.label)),
                        ]),
                        _c("p", { staticClass: "study-item-info" }, [
                          _c("b", { staticClass: "ell" }, [
                            _vm._v(_vm._s(_vm.studySummaryData[item.from])),
                          ]),
                          _c("span", [_vm._v(_vm._s(item.suffix))]),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "container-item container-item-margin jschoose" },
              [
                _c("h3", { staticClass: "item-title" }, [
                  _vm._v(" 登录学习统计 "),
                  _c("div", { staticClass: "choosebox" }, [
                    _c("div", { staticClass: "citem" }, [
                      _c("span", [_vm._v("设备:")]),
                      _c(
                        "ul",
                        { attrs: { type: "deviceType" } },
                        _vm._l(_vm.deviceTypeList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: { liveact: item.type == _vm.deviceType },
                              on: {
                                click: function ($event) {
                                  return _vm.loginLearn("deviceType", item.type)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "citem" }, [
                      _c("span", [_vm._v("时间:")]),
                      _c(
                        "ul",
                        { attrs: { type: "queryType" } },
                        _vm._l(_vm.queryTypeList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: { liveact: item.type == _vm.queryType },
                              on: {
                                click: function ($event) {
                                  return _vm.loginLearn("queryType", item.type)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "citem" }, [
                      _c(
                        "ul",
                        { attrs: { type: "granularity" } },
                        _vm._l(_vm.granularityList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: { liveact: item.type == _vm.granularity },
                              on: {
                                click: function ($event) {
                                  return _vm.loginLearn(
                                    "granularity",
                                    item.type
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _vm._m(0),
              ]
            ),
            _c("div", { staticClass: "container-item container-item-margin" }, [
              _c("h3", { staticClass: "item-title" }, [
                _vm._v(" 登录情况统计 "),
                _c(
                  "ul",
                  { staticClass: "item-tab", attrs: { type: "loginSth" } },
                  _vm._l(_vm.loginSthList, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: { liveact: item.type == _vm.loginSth },
                        on: {
                          click: function ($event) {
                            return _vm.changeLogin(item.type)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "item-con-box item-box-float-l" }, [
                  _c("h3", { staticClass: "item-eCharts-title" }, [
                    _vm._v("用户登录情况"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "login-situation jslogin-situation" },
                    [
                      _c("li", [
                        _vm._v(" 可用用户数 : "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.loginConditionData[
                                _vm.loginDescKeys[0].from
                              ] || 0
                            )
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _vm._v(" 已登用户数 : "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.loginConditionData[
                                _vm.loginDescKeys[1].from
                              ] || 0
                            )
                          ),
                        ]),
                      ]),
                      _c("li", { staticStyle: { display: "block" } }, [
                        _vm._v(" 活跃度 : "),
                        _c("span", { staticClass: "rate-num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.loginConditionData[
                                _vm.loginDescKeys[2].from
                              ] || 0
                            ) + "%"
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", {
                    staticStyle: { width: "100%", height: "200px" },
                    attrs: { id: "EChartsLoginDesc" },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "item-con-box item-box-float jsitem-box-float",
                  },
                  [
                    _c(
                      "h3",
                      { staticClass: "item-eCharts-title title-margin" },
                      [_vm._v("用户登录来源")]
                    ),
                    _c("p", { staticClass: "item-com item-com-1" }, [
                      _c("span", {
                        style: {
                          background: this.loginDescKeys[4].suffix || "#32c5ff",
                        },
                      }),
                      _vm._v(
                        " PC端登录:" +
                          _vm._s(
                            _vm.loginConditionData[_vm.loginDescKeys[4].from] ||
                              0
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "item-com item-com-2" }, [
                      _c("span", {
                        style: {
                          background: this.loginDescKeys[5].suffix || "#0079ff",
                        },
                      }),
                      _vm._v(
                        " 移动端登录:" +
                          _vm._s(
                            _vm.loginConditionData[_vm.loginDescKeys[5].from] ||
                              0
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "item-com item-com-3" }, [
                      _c("span", {
                        style: {
                          background: this.loginDescKeys[6].suffix || "#fa6400",
                        },
                      }),
                      _vm._v(
                        " PC&移动端登录:" +
                          _vm._s(
                            _vm.loginConditionData[_vm.loginDescKeys[6].from] ||
                              0
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "item-com-all" }, [
                      _c(
                        "span",
                        {
                          staticClass: "item-pc",
                          style: {
                            width: _vm.num1 + "%",
                            background:
                              this.loginDescKeys[4].suffix || "#32c5ff",
                          },
                        },
                        [_vm._v(_vm._s(_vm.num1) + "%")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "item-mobile",
                          style: {
                            width: _vm.num2 + "%",
                            background:
                              this.loginDescKeys[5].suffix || "#0079ff",
                          },
                        },
                        [_vm._v(_vm._s(_vm.num2) + "%")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "item-pc-mobile",
                          style: {
                            width: _vm.num3 + "%",
                            background:
                              this.loginDescKeys[6].suffix || "#fa6400",
                          },
                        },
                        [_vm._v(_vm._s(_vm.num3) + "%")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-content" }, [
      _c("div", { staticClass: "item-login-info" }, [
        _c("h3", { staticClass: "item-eCharts-title" }, [_vm._v("学习访问量")]),
        _c("div", {
          staticStyle: { width: "100%", height: "260px" },
          attrs: { id: "EChartsLogin" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }