var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_title_two", style: { padding: _vm.controlStyle } },
    [
      _c("div", { staticClass: "title-inner" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _c("i", { staticClass: "line" }),
        ]),
        _vm.showMore
          ? _c("div", { staticClass: "title-more" }, [
              _c(
                "span",
                {
                  attrs: { slot: "more" },
                  on: {
                    click: function ($event) {
                      return _vm._clickBefore(_vm.toUrl)
                    },
                  },
                  slot: "more",
                },
                [_vm._v(_vm._s(_vm.moreFont))]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }