import { render, staticRenderFns } from "./channelCard.vue?vue&type=template&id=23eae22c&"
import script from "./channelCard.vue?vue&type=script&lang=js&"
export * from "./channelCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/components/channel/channelCard.vue"
export default component.exports