var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("ul", { staticClass: "ds-nc-book-card" }, [
        _c(
          "li",
          { staticClass: "card-book-items" },
          [
            _c("dsf-nc-image"),
            _c("span", { staticClass: "title" }, [
              _vm._v("健全多层次社会保障体系，让社会保障制度更公平（上）"),
            ]),
          ],
          1
        ),
      ])
    : _c(
        "ul",
        {
          staticClass: "ds-nc-book-card",
          attrs: { title: _vm.data["title"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "li",
            { staticClass: "card-book-items" },
            [
              _c("dsf-nc-image", {
                staticStyle: { width: "132px", height: "167px" },
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
              _c("span", { staticClass: "title ell2" }, [
                _vm._v(_vm._s(_vm.data["title"])),
              ]),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }