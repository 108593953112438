var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_alibanner" }, [
    _vm.isDesign
      ? _c(
          "div",
          { staticClass: "dsf_nc_alibanner_inner", attrs: { id: "accordion" } },
          [
            _c("div", { staticClass: "alibanner_container" }, [
              _c(
                "div",
                { staticClass: "alibanner_wrapper" },
                _vm._l(_vm.lists, function (ele, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "alibanner_img_box",
                      class:
                        _vm.eleindex == index
                          ? index == 0
                            ? "banneractive isFirstaActive"
                            : "banneractive"
                          : "",
                    },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(ele.cover),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                      _c("span", { staticClass: "alibanner_title" }, [
                        _vm._v(_vm._s(ele.title)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]
        )
      : _vm._e(),
    !_vm.isDesign
      ? _c(
          "div",
          { staticClass: "dsf_nc_alibanner_inner", attrs: { id: "accordion" } },
          [
            _c("div", { staticClass: "alibanner_container" }, [
              _c(
                "div",
                { staticClass: "alibanner_wrapper" },
                _vm._l(_vm.lists, function (ele, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "alibanner_img_box",
                      class:
                        _vm.eleindex == index
                          ? index == 0
                            ? "banneractive isFirstaActive"
                            : "banneractive"
                          : "",
                      style:
                        _vm.eleindex == index
                          ? { flex: _vm.activePercent }
                          : "",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.enter(index)
                        },
                        mouseleave: _vm.out,
                        click: function ($event) {
                          return _vm._clickBefore(_vm.handleClick, ele)
                        },
                      },
                    },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(ele.cover),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "alibanner_title",
                          style:
                            index == 0
                              ? {
                                  background:
                                    "url(" + _vm.setBgImage(ele.cover) + ")",
                                  left: _vm.firstLeft,
                                }
                              : {
                                  background:
                                    "url(" + _vm.setBgImage(ele.cover) + ")",
                                },
                        },
                        [
                          _c("span", { staticClass: "title_font" }, [
                            _vm._v(_vm._s(ele.title)),
                          ]),
                          _c("span", { staticClass: "mask" }),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }