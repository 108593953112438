var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-login code-login" }, [
    _c(
      "div",
      { staticClass: "login-box-info margin-top" },
      [
        _c("dsf-icon", { attrs: { name: "icon_phone" } }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.loginForm.phone,
              expression: "loginForm.phone",
            },
          ],
          attrs: {
            type: "text",
            placeholder: _vm.nameHolder,
            autocomplete: "new-password",
          },
          domProps: { value: _vm.loginForm.phone },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.loginForm, "phone", $event.target.value)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "login-box-info login-code" },
      [
        _c(
          "div",
          { staticClass: "login-code-wrap" },
          [
            _c("dsf-icon", { attrs: { name: "yanzhengma1" } }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.loginForm.code,
                  expression: "loginForm.code",
                },
              ],
              attrs: { type: "text", placeholder: _vm.codeHolder },
              domProps: { value: _vm.loginForm.code },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.loginForm, "code", $event.target.value)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "btn-send-msg",
            attrs: { type: "info", size: "small" },
            on: { click: _vm.throttleLoadMsgCode },
          },
          [_vm._v(_vm._s(_vm.msgText))]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "login-box-handle" }),
    _c(
      "div",
      { staticClass: "login-box-button" },
      [
        _c(
          "el-button",
          {
            attrs: {
              loading: _vm.loading,
              type: "info",
              "native-type": "submit",
              size: "small",
            },
            on: { click: _vm.submitCodeLogin },
          },
          [_vm._v("登 录")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }