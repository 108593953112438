<template>
  <div v-show="isDesign || visible" :style="controlStyle" class="ds-control" :class="{ 'ds-no-padding': !isDesign }">
    <div :class="`ds-nc-home-panel ${styleType ? `ds-nc-home-panel-${styleType}` : ''}`" :is-design="isDesign" :showShadow="showShadow" :style="panelStyle">
      <div class="ds-nc-home-panel-header">
        <div class="ds-nc-home-panel-header-left">
          <slot name="header-title">
            <div v-if="titleIcon" class="ds-nc-home-panel-icon">
              <DsfIcon :name="titleIcon"></DsfIcon>
            </div>
            <div v-if="title" class="ds-nc-home-panel-title">{{ title }}</div>
          </slot>
          <slot name="header-right"></slot>
        </div>
        <div class="ds-nc-home-panel-header-center">
          <slot name="header-center"></slot>
        </div>
        <div class="ds-nc-home-panel-header-right">
          <slot name="header-right"></slot>
          <div v-if="moreIcon || moreText" class="ds-nc-home-panel-more" @click="more">
            <template v-if="moreText">
              <span class="ds-nc-home-panel-more-font">{{ moreText }}</span>
            </template>
            <DsfIcon :name="moreIcon"></DsfIcon>
          </div>
        </div>
      </div>
      <div slot-name="default" class="ds-nc-home-panel-content" :style="{ padding: padding.join('px ') + 'px' }">
        <template v-if="isDesign">
          <slot></slot>
        </template>
        <template v-else>
          <DsfVirtualScroll :isDesign="isDesign">
            <slot></slot>
          </DsfVirtualScroll>
        </template>
      </div>
    </div>
    <DsfNcPcAddSource :show.sync="showDialog" />
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcHomePanel",
  ctrlCaption: "nc基础面板",
  mixins: [$mixins.control],
  provide() {
    return {
      $homePanel: this,
    };
  },
  props: {
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      },
    },
    // 标题
    title: {
      type: String,
      default: "面板标题",
    },
    // 标题Icon
    titleIcon: {
      type: String,
      default: () => "qiapianmoshi_kuai",
    },
    // 显示阴影
    showShadow: {
      type: Boolean,
      default: true,
    },
    // 圆角
    borderRadius: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "100%",
    },
    // 更多图标 icon名称
    moreIcon: {
      type: String,
      default: () => "gengduo",
    },
    // 更多图标 名称
    moreText: {
      type: String,
      default: () => "",
    },
    // 内边框
    padding: {
      type: Array,
      default() {
        return [0, 20, 0, 20];
      },
    },
    // 外边框
    margin: {
      type: Array,
      default() {
        return [8, 8, 8, 8];
      },
    },
    // 风格
    styleType: {
      type: String,
      default: "one",
    },
    // 是否可见
    visible: {
      type: Boolean,
      default: true,
    },
    // 更多按钮跳转方式
    moreConfig: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      default: "#fff",
    },
  },
  computed: {
    controlStyle() {
      let { width, margin, height, isDesign, visible } = this;
      let style = {
        width,
        padding: margin.join("px ") + "px",
        height,
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    panelStyle() {
      let { height, borderRadius, isDesign, backgroundColor } = this;
      let style = {
        borderRadius: borderRadius.join("px ") + "px",
        height: "100%",
        backgroundColor,
      };
      let isBorderRadius = borderRadius.some(radius => radius);
      if (isBorderRadius) style.overflow = "hidden";
      if (isDesign) {
        if (height == "100%") {
          style.height = `calc(${height} - 10px)`;
        } else {
          style.height = `calc(${height} - 20px)`;
        }
      }
      return style;
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  watch: {
    height: {
      immediate: true,
      handler(height) {
        let { isDesign } = this;
        if (isDesign) {
          this.$dispatch("design-height-change", height);
        }
      },
    },
  },
  methods: {
    more() {
      if (this.moreText == "新建" && this.moreConfig.state == 3) {
        this.showDialog = true;
        return;
      }
      if (this.moreConfig && !this.moreConfig.title) {
        this.moreConfig.title = this.title;
      }
      if (this.$listeners["moreBtn"]) {
        return this.$emit("moreBtn", this);
      }
      this.$open({ ...this.moreConfig }).closed = () => {
        this.reloadData();
      };
    },
    reloadData() {
      this.$childrenReloadData();
    },
  },
  design: {
    isMask: false,
  },
});
</script>
