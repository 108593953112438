var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_assessment_new", class: _vm.getCss },
    [
      _c(
        "el-form",
        {
          ref: "formModel",
          attrs: {
            rules: _vm.rules,
            model: _vm.formModel,
            "label-width": "80px",
            disabled: _vm.isview,
          },
        },
        [
          _vm.showFace
            ? _c(
                "el-form-item",
                {
                  staticClass:
                    "dsf_form_item dsf_margin_bottom_30 dsf_form_item_renlian",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "dsf_label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("人脸验证")]),
                      _c("el-switch", {
                        staticClass: "label_switch",
                        on: {
                          change: function ($event) {
                            return _vm.handlerSwitch(
                              "showFaceSwitchValue",
                              "showFaceSwitchValue",
                              _vm.showFaceSwitchValue
                            )
                          },
                        },
                        model: {
                          value: _vm.showFaceSwitchValue,
                          callback: function ($$v) {
                            _vm.showFaceSwitchValue = $$v
                          },
                          expression: "showFaceSwitchValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showFaceSwitchValue
                    ? _c(
                        "div",
                        { staticClass: "conditions_content" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "dsf_item_form",
                              attrs: { label: "是否开启人脸验证：" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.hanlderRadio(
                                        "face",
                                        "faceValue",
                                        _vm.isFace
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.isFace,
                                    callback: function ($$v) {
                                      _vm.isFace = $$v
                                    },
                                    expression: "isFace",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "开启:1" } },
                                    [_vm._v(" 开启 ")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "关闭:0" } },
                                    [_vm._v("关闭")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "renlian_desc" }, [
                                _vm._v(
                                  "开启人脸验证后，该专题班将进行人脸验证，验证后通过后方可学习"
                                ),
                              ]),
                              _vm.formModel.face == "1"
                                ? _c("div", { staticClass: "set_renlian" }, [
                                    _c("span", [
                                      _vm._v("设置人脸验证事件：开始学习"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.formModel.verifyTime,
                                          expression: "formModel.verifyTime",
                                        },
                                      ],
                                      attrs: {
                                        type: "number",
                                        onKeypress:
                                          "return (/[\\d]/.test(String.fromCharCode(event.keyCode)))",
                                        onkeyup: "clearNoNum(this)",
                                      },
                                      domProps: {
                                        value: _vm.formModel.verifyTime,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.formModel,
                                            "verifyTime",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v("分钟后，需要进行一次人脸验证"),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.formModel.face == "1"
                                ? _c(
                                    "div",
                                    { staticClass: "set_renlian_desc" },
                                    [
                                      _vm._v(
                                        "进入专题班必须进行人脸验证，设置后按照设置的学习时间进行一次验证，验证不通过则不可进行学习。"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.conditions
            ? _c(
                "el-form-item",
                { staticClass: "dsf_form_item dsf_margin_bottom_30" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "dsf_label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("考核条件")]),
                      _vm.showConditionsSwitch
                        ? _c("el-switch", {
                            staticClass: "label_switch",
                            on: {
                              change: function ($event) {
                                return _vm.handlerSwitch(
                                  "periodEnableValue",
                                  "periodEnableText",
                                  _vm.periodEnableValue
                                )
                              },
                            },
                            model: {
                              value: _vm.periodEnableValue,
                              callback: function ($$v) {
                                _vm.periodEnableValue = $$v
                              },
                              expression: "periodEnableValue",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.periodEnableValue
                    ? _c(
                        "div",
                        { staticClass: "conditions_content" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "dsf_item_form",
                              staticStyle: { "margin-top": "15px" },
                              attrs: {
                                label: "满足学时:",
                                prop: "electivesPeriod",
                              },
                            },
                            [
                              _c("span", [_vm._v("    选修 ")]),
                              _c("el-input", {
                                staticClass: "dsf_margin_bottom_10",
                                attrs: {
                                  disabled: _vm.isview,
                                  onkeyup:
                                    "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.eletivesChange(
                                      _vm.formModel.electivesPeriod,
                                      _vm.periodData.electivesPeriod
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.formModel.electivesPeriod,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "electivesPeriod",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.electivesPeriod",
                                },
                              }),
                              _c("span", [_vm._v("学时")]),
                              _c("span", [
                                _vm._v(
                                  "/" +
                                    _vm._s(
                                      _vm.periodData.electivesPeriod || 0
                                    ) +
                                    "学时    "
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  "    必修 " +
                                    _vm._s(
                                      _vm.periodData.compulsoryPeriod || 0
                                    ) +
                                    "/" +
                                    _vm._s(
                                      _vm.periodData.compulsoryPeriod || 0
                                    ) +
                                    "学时"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.quiz
            ? _c(
                "el-form-item",
                { staticClass: "dsf_form_item dsf_margin_bottom_30" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "dsf_label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("随堂测试")]),
                      _vm.showQuizSwitch
                        ? _c("el-switch", {
                            staticClass: "label_switch",
                            on: {
                              change: function ($event) {
                                return _vm.handlerSwitch(
                                  "quizEnableValue",
                                  "quizEnableText",
                                  _vm.quizEnableValue
                                )
                              },
                            },
                            model: {
                              value: _vm.quizEnableValue,
                              callback: function ($$v) {
                                _vm.quizEnableValue = $$v
                              },
                              expression: "quizEnableValue",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.quizEnableValue,
                          expression: "quizEnableValue",
                        },
                      ],
                      staticClass: "quiz_content",
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dsf_margin_bottom_10",
                          attrs: { label: "答题规则：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.hanlderRadio(
                                    "quizCompleteValue",
                                    "quizCompleteText",
                                    _vm.quizCompleteValue
                                  )
                                },
                              },
                              model: {
                                value: _vm.quizCompleteValue,
                                callback: function ($$v) {
                                  _vm.quizCompleteValue = $$v
                                },
                                expression: "quizCompleteValue",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "必须做完才能继续课程:1" } },
                                [_vm._v("必须做完才能继续课程")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "可以跳过不做:0" } },
                                [_vm._v("可以跳过不做")]
                              ),
                            ],
                            1
                          ),
                          _vm.formModel.quizCompleteValue
                            ? _c(
                                "div",
                                { staticClass: "quiz_inner_box" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "答题结果：" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.hanlderRadio(
                                                "quizRightValue",
                                                "quizRightText",
                                                _vm.quizRightValue
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.quizRightValue,
                                            callback: function ($$v) {
                                              _vm.quizRightValue = $$v
                                            },
                                            expression: "quizRightValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label:
                                                  "做题结果无论对错都可以继续学习:1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "做题结果无论对错都可以继续学习"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label:
                                                  "做题结果必须全部正确方可继续学习:0",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "做题结果必须全部正确方可继续学习"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.quizRightValue ==
                                            "做题结果必须全部正确方可继续学习:0",
                                          expression:
                                            "quizRightValue == '做题结果必须全部正确方可继续学习:0'",
                                        },
                                      ],
                                      attrs: { label: "答错设置：" },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.hanlderRadio(
                                                "quizIgnoreErrorValue",
                                                "quizIgnoreErrorText",
                                                _vm.quizIgnoreErrorValue
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.quizIgnoreErrorValue,
                                            callback: function ($$v) {
                                              _vm.quizIgnoreErrorValue = $$v
                                            },
                                            expression: "quizIgnoreErrorValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: "答错继续答题:1",
                                              },
                                            },
                                            [_vm._v("答错继续答题")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: "答错返回上个节点:0",
                                              },
                                            },
                                            [_vm._v("答错返回上个节点")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass:
                            "quiz2 no_label_name dsf_margin_bottom_10",
                          attrs: { label: "答规则：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.hanlderRadio(
                                    "quizAlwaysValue",
                                    "quizAlwaysText",
                                    _vm.quizAlwaysValue
                                  )
                                },
                              },
                              model: {
                                value: _vm.quizAlwaysValue,
                                callback: function ($$v) {
                                  _vm.quizAlwaysValue = $$v
                                },
                                expression: "quizAlwaysValue",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "每次都弹出题目:1" } },
                                [_vm._v("每次都弹出题目")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "只第一次上课弹出题目:0" } },
                                [_vm._v("只第一次上课弹出题目")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ct_rules",
                          attrs: { label: "出题规则：", prop: "quizInterval" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "rules_inner dsf_line_box" },
                            [
                              _c("span", [_vm._v("每")]),
                              _c("el-input", {
                                staticClass: "dsf_margin_bottom_10",
                                attrs: {
                                  type: "input",
                                  disabled: _vm.isview,
                                  onkeyup:
                                    "value=value.replace(/[^\\d{1,}]/g,'')",
                                },
                                model: {
                                  value: _vm.formModel.quizInterval,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formModel, "quizInterval", $$v)
                                  },
                                  expression: "formModel.quizInterval",
                                },
                              }),
                              _c("span", [_vm._v("分钟做题一次，每次做")]),
                              _c("el-input", {
                                attrs: {
                                  type: "input",
                                  disabled: _vm.isview,
                                  onkeyup:
                                    "value=value.replace(/[^\\d{1,}]/g,'')",
                                },
                                model: {
                                  value: _vm.formModel.quizNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formModel, "quizNum", $$v)
                                  },
                                  expression: "formModel.quizNum",
                                },
                              }),
                              _c("span", [_vm._v("道题目。")]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass:
                            "dsf_remark no_label_name dsf_margin_bottom_10",
                          attrs: { label: "出题规则：" },
                        },
                        [
                          _c("div", { staticClass: "font" }, [
                            _vm._v("注：视频时长：" + _vm._s(_vm.duration)),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dsf_margin_bottom_10",
                          attrs: { label: "考试题目：", prop: "quizlists" },
                        },
                        [
                          _c("DsfDataChoice", {
                            staticClass:
                              "no_label_name no_show_label data_choice_margin dsf_margin_bottom_10",
                            attrs: {
                              disabled: _vm.isview,
                              autoComplete: _vm.quizAutoComplete,
                              metaDataType: _vm.quizMetaDataType,
                              chooseMetaData: _vm.quizChooseMetaData,
                              source: _vm.quizSource,
                              maxItemsNumber: _vm.quizMaxItemsNumber,
                              label: _vm.quizLabel,
                              showLabel: _vm.quizShowLabel,
                              textKey: _vm.quizTextKey,
                              valueKey: _vm.quizValueKey,
                              dialogUrl: _vm.quizDialogUrl,
                              dialogTextKey: _vm.quizDialogTextKey,
                              dialogValueKey: _vm.quizDialogValueKey,
                              dialogTitle: _vm.quizDialogSetting.split(",")[2],
                              dialogWidth: _vm.quizDialogSetting.split(",")[0],
                              dialogHeight: _vm.quizDialogSetting.split(",")[1],
                            },
                            on: { change: _vm.changeQuizDataChoice },
                            model: {
                              value: _vm.quizlists,
                              callback: function ($$v) {
                                _vm.quizlists = $$v
                              },
                              expression: "quizlists",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.exam
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("课后练习")]),
                    _vm.showExamSwitch
                      ? _c("el-switch", {
                          staticClass: "label_switch",
                          on: {
                            change: function ($event) {
                              return _vm.handlerSwitch(
                                "enableExam",
                                null,
                                _vm.enableExam
                              )
                            },
                          },
                          model: {
                            value: _vm.enableExam,
                            callback: function ($$v) {
                              _vm.enableExam = $$v
                            },
                            expression: "enableExam",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.enableExam,
                        expression: "enableExam",
                      },
                    ],
                    staticClass: "conditions_content",
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10",
                        attrs: { label: "练习类型：" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.hanlderRadio(
                                  "examNecessaryValue",
                                  "examNecessaryText",
                                  _vm.examNecessaryValue
                                )
                              },
                            },
                            model: {
                              value: _vm.examNecessaryValue,
                              callback: function ($$v) {
                                _vm.examNecessaryValue = $$v
                              },
                              expression: "examNecessaryValue",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "必填:1" } }, [
                              _vm._v(" 必填 "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "dsf_radio_remark icon iconfont icon-093info",
                                },
                                [
                                  _c("i", { staticClass: "remark_font" }, [
                                    _vm._v(
                                      "选择必考后，必须练习通过后才能获取学时得到证书"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("el-radio", { attrs: { label: "选填:0" } }, [
                              _vm._v("选填"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10",
                        attrs: { label: "练习名称：", prop: "examlists" },
                      },
                      [
                        _c("DsfDataChoice", {
                          staticClass:
                            "no_label_name no_show_label data_choice_margin dsf_margin_bottom_10",
                          attrs: {
                            disabled: _vm.isview,
                            autoComplete: _vm.examAutoComplete,
                            metaDataType: _vm.examMetaDataType,
                            chooseMetaData: _vm.examChooseMetaData,
                            source: _vm.examSource,
                            maxItemsNumber: _vm.examMaxItemsNumber,
                            label: _vm.examLabel,
                            showLabel: _vm.examShowLabel,
                            textKey: _vm.examTextKey,
                            valueKey: _vm.examValueKey,
                            dialogUrl: _vm.examDialogUrl,
                            dialogTextKey: _vm.examDialogTextKey,
                            dialogValueKey: _vm.examDialogValueKey,
                            dialogTitle: _vm.examDialogSetting.split(",")[2],
                            dialogWidth: _vm.examDialogSetting.split(",")[0],
                            dialogHeight: _vm.examDialogSetting.split(",")[1],
                          },
                          on: { change: _vm.changeExamDataChoice },
                          model: {
                            value: _vm.examlists,
                            callback: function ($$v) {
                              _vm.examlists = $$v
                            },
                            expression: "examlists",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10 reward_hurs",
                        attrs: { label: "奖励学时:", prop: "examHurs" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "dsf_margin_bottom_10",
                          attrs: {
                            disabled: _vm.isview,
                            onkeyup:
                              "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangevalue(
                                _vm.formModel.examHurs,
                                "examHurs"
                              )
                            },
                          },
                          model: {
                            value: _vm.formModel.examHurs,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "examHurs", $$v)
                            },
                            expression: "formModel.examHurs",
                          },
                        }),
                        _c("span", { staticClass: "xs" }, [_vm._v("学时")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.homework
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("课后作业")]),
                    _vm.showHomeworkSwitch
                      ? _c("el-switch", {
                          on: {
                            change: function ($event) {
                              return _vm.handlerSwitch(
                                "homeworkEnabled",
                                null,
                                _vm.homeworkEnabled
                              )
                            },
                          },
                          model: {
                            value: _vm.homeworkEnabled,
                            callback: function ($$v) {
                              _vm.homeworkEnabled = $$v
                            },
                            expression: "homeworkEnabled",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.homeworkEnabled,
                        expression: "homeworkEnabled",
                      },
                    ],
                    staticClass: "conditions_content",
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10",
                        attrs: { label: "作业类型：" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.hanlderRadio(
                                  "homeworkNecessaryValue",
                                  "homeworkNecessaryText",
                                  _vm.homeworkNecessaryValue
                                )
                              },
                            },
                            model: {
                              value: _vm.homeworkNecessaryValue,
                              callback: function ($$v) {
                                _vm.homeworkNecessaryValue = $$v
                              },
                              expression: "homeworkNecessaryValue",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "必填:1" } }, [
                              _vm._v(" 必填 "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "dsf_radio_remark icon iconfont icon-093info",
                                },
                                [
                                  _c("i", { staticClass: "remark_font" }, [
                                    _vm._v(
                                      "选择必填后，必须填写感悟后才能获取学时得到证书"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("el-radio", { attrs: { label: "选填:0" } }, [
                              _vm._v("选填"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10",
                        attrs: {
                          label: "作业描述：",
                          prop: "homeworkRequirements",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "dsf_textarea",
                          attrs: { type: "textarea", rows: 5, resize: "none" },
                          model: {
                            value: _vm.formModel.homeworkRequirements,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formModel,
                                "homeworkRequirements",
                                $$v
                              )
                            },
                            expression: "formModel.homeworkRequirements",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.homeworkTypeShow
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "dsf_margin_bottom_10",
                            attrs: {
                              label: "成绩类型：",
                              prop: "homeworkScoreTypeValue",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "dsf_select dsf_margin_bottom_10",
                                attrs: {
                                  placeholder: "下拉框，等级制、分数制",
                                },
                                on: { change: _vm.handlerChangeSelect },
                                model: {
                                  value: _vm.formModel.homeworkScoreTypeValue,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "homeworkScoreTypeValue",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formModel.homeworkScoreTypeValue",
                                },
                              },
                              _vm._l(_vm.scoreType, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.text,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10 reward_hurs",
                        attrs: { label: "奖励学时:", prop: "homeworkPeriod" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "dsf_margin_bottom_10",
                          attrs: {
                            disabled: _vm.isview,
                            onkeyup:
                              "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangevalue(
                                _vm.formModel.homeworkPeriod,
                                "homeworkPeriod"
                              )
                            },
                          },
                          model: {
                            value: _vm.formModel.homeworkPeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "homeworkPeriod", $$v)
                            },
                            expression: "formModel.homeworkPeriod",
                          },
                        }),
                        _c("span", { staticClass: "xs" }, [_vm._v("学时")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.evaluate
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("调查问卷")]),
                    _vm.showEvaluateSwitch
                      ? _c("el-switch", {
                          staticClass: "label_switch",
                          on: {
                            change: function ($event) {
                              return _vm.handlerSwitch(
                                "enableEvaluate",
                                null,
                                _vm.enableEvaluate
                              )
                            },
                          },
                          model: {
                            value: _vm.enableEvaluate,
                            callback: function ($$v) {
                              _vm.enableEvaluate = $$v
                            },
                            expression: "enableEvaluate",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.enableEvaluate,
                        expression: "enableEvaluate",
                      },
                    ],
                    staticClass: "conditions_content",
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10",
                        attrs: { label: "问卷类型：" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.hanlderRadio(
                                  "evaluateNecessaryValue",
                                  "evaluateNecessaryText",
                                  _vm.evaluateNecessaryValue
                                )
                              },
                            },
                            model: {
                              value: _vm.evaluateNecessaryValue,
                              callback: function ($$v) {
                                _vm.evaluateNecessaryValue = $$v
                              },
                              expression: "evaluateNecessaryValue",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "必填:1" } }, [
                              _vm._v(" 必填 "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "dsf_radio_remark icon iconfont icon-093info",
                                },
                                [
                                  _c("i", { staticClass: "remark_font" }, [
                                    _vm._v(
                                      "选择必答后，必须填写问卷后才能获取学时得到证书"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("el-radio", { attrs: { label: "选填:0" } }, [
                              _vm._v("选填"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10",
                        attrs: { label: "问卷模板：", prop: "evaluatelists" },
                      },
                      [
                        _c("DsfDataChoice", {
                          staticClass:
                            "no_label_name no_show_label data_choice_margin",
                          attrs: {
                            disabled: _vm.isview,
                            autoComplete: _vm.evaluateAutoComplete,
                            metaDataType: _vm.evaluateMetaDataType,
                            chooseMetaData: _vm.evaluateChooseMetaData,
                            source: _vm.evaluateSource,
                            maxItemsNumber: _vm.evaluateMaxItemsNumber,
                            label: _vm.evaluateLabel,
                            showLabel: _vm.evaluateShowLabel,
                            textKey: _vm.evaluateTextKey,
                            valueKey: _vm.evaluateValueKey,
                            dialogUrl: _vm.evaluateDialogUrl,
                            dialogTextKey: _vm.evaluateDialogTextKey,
                            dialogValueKey: _vm.evaluateDialogValueKey,
                            dialogTitle: _vm.evaluateDialogSetting.split(
                              ","
                            )[2],
                            dialogWidth: _vm.evaluateDialogSetting.split(
                              ","
                            )[0],
                            dialogHeight: _vm.evaluateDialogSetting.split(
                              ","
                            )[1],
                          },
                          on: { change: _vm.changeEvaluateDataChoice },
                          model: {
                            value: _vm.evaluatelists,
                            callback: function ($$v) {
                              _vm.evaluatelists = $$v
                            },
                            expression: "evaluatelists",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10 reward_hurs",
                        attrs: { label: "奖励学时:", prop: "evaluatePeriod" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "dsf_margin_bottom_10",
                          attrs: {
                            onkeyup:
                              "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangevalue(
                                _vm.formModel.evaluatePeriod,
                                "evaluatePeriod"
                              )
                            },
                          },
                          model: {
                            value: _vm.formModel.evaluatePeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "evaluatePeriod", $$v)
                            },
                            expression: "formModel.evaluatePeriod",
                          },
                        }),
                        _c("span", { staticClass: "xs" }, [_vm._v("学时")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.certificate
            ? _c("el-form-item", { staticClass: "dsf_form_item" }, [
                _c(
                  "span",
                  {
                    staticClass: "dsf_label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("证书")]),
                    _vm.showCertificateSwitch
                      ? _c("el-switch", {
                          staticClass: "label_switch",
                          on: {
                            change: function ($event) {
                              return _vm.handlerSwitch(
                                "enableCertificate",
                                null,
                                _vm.enableCertificate
                              )
                            },
                          },
                          model: {
                            value: _vm.enableCertificate,
                            callback: function ($$v) {
                              _vm.enableCertificate = $$v
                            },
                            expression: "enableCertificate",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.enableCertificate,
                        expression: "enableCertificate",
                      },
                    ],
                    staticClass: "conditions_content",
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dsf_margin_bottom_10",
                        attrs: {
                          label: "证书名称：",
                          prop: "certificateLists",
                        },
                      },
                      [
                        _c("DsfDataChoice", {
                          staticClass:
                            "no_label_name no_show_label data_choice_margin dsf_margin_bottom_10",
                          attrs: {
                            disabled: _vm.isview,
                            autoComplete: _vm.certificateAutoComplete,
                            metaDataType: _vm.certificateMetaDataType,
                            chooseMetaData: _vm.certificateChooseMetaData,
                            source: _vm.certificateSource,
                            maxItemsNumber: _vm.certificateMaxItemsNumber,
                            label: _vm.certificateLabel,
                            showLabel: _vm.certificateShowLabel,
                            textKey: _vm.certificateTextKey,
                            valueKey: _vm.certificateValueKey,
                            dialogUrl: _vm.certificateDialogUrl,
                            dialogTextKey: _vm.certificateDialogTextKey,
                            dialogValueKey: _vm.certificateDialogValueKey,
                            dialogTitle: _vm.certificateDialogSetting.split(
                              ","
                            )[2],
                            dialogWidth: _vm.certificateDialogSetting.split(
                              ","
                            )[0],
                            dialogHeight: _vm.certificateDialogSetting.split(
                              ","
                            )[1],
                          },
                          on: { change: _vm.changeCertificateDataChoice },
                          model: {
                            value: _vm.certificateLists,
                            callback: function ($$v) {
                              _vm.certificateLists = $$v
                            },
                            expression: "certificateLists",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { staticClass: "dsf_btns" },
            [
              _c("DsfButtonBar", {
                attrs: {
                  disabled: _vm.isview,
                  buttons: _vm.buttons,
                  "max-number": _vm.maxNumber,
                },
                on: {
                  save: function ($event) {
                    return _vm.onSubmit("formModel")
                  },
                  close: _vm.onClose,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }