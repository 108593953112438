<template>
  <div class="dsf_nc_saas_page_design">
    <div class="page_top">
      <el-input v-model="pageName" width="250"></el-input>
      <div class="header_btns">
        <dsf-button type="plain" @click="closePage">退出</dsf-button>
        <dsf-button type="plain" @click="preview">预览</dsf-button>
        <dsf-button type="primary" @click="savePage">保存</dsf-button>
      </div>
    </div>
    <div class="page_content">
      <div class="component_list">
        <ul>
          <li v-for="(component, i) in components" :key="i" @click="createComponent(component)">{{ component.name }}</li>
        </ul>
      </div>
      <div class="page_view">
        <div class="design_content" v-for="(component, index) in page" :key="index" @click.stop.prevent="checkComponent(index, $event)">
          <div :style="{ margin: 'auto', width: '100%' }">
            <component :is="component.type" :data="component"></component>
          </div>
          <div class="design_option" v-if="activeIndex == index">
            <div>
              <!-- <img :src="moveIcon" alt="" title="移动" @click.stop="move(index)" v-if="page.length > 1" /> -->
              <img :src="delIcon" alt="" title="移除" @click.stop="remove(index)" />
              <img :src="moveupIcon" alt="" title="上移" @click.stop="moveUp(index)" v-if="activeIndex != 0" />
              <img :src="movedownIcon" alt="" title="下移" @click.stop="moveDown(index)" v-if="activeIndex != page.length - 1 && page.length > 1" />
            </div>
          </div>
          <div v-else class="design_mask"></div>
        </div>
      </div>
      <div class="component_attr">
        <template v-if="page[activeIndex]">
          <div class="title" v-if="page[activeIndex].name">
            <span>{{ page[activeIndex].name }}</span>
          </div>
          <div class="component_attr_content">
            <div class="attr" v-for="(attr, i) in page[activeIndex].attr" :key="i">
              <div class="attr_title">{{ attr.title }}</div>
              <div v-if="attr.type == 'radio'">
                <el-radio :style="{ width: 100 / attr.selection.length + '%' }" v-model="page[activeIndex].bindAttr[attr['bind']]" v-for="se in attr.selection" :label="se.value" :key="se.label">
                  {{ se.label }}
                </el-radio>
              </div>
              <div v-if="attr.type == 'input'">
                <el-input v-model="page[activeIndex].bindAttr[attr['bind']]"></el-input>
              </div>
              <div v-if="attr.type == 'image'">
                <el-upload
                  class="avatar-uploader"
                  action="/file/upload"
                  :show-file-list="false"
                  :on-success="
                    response => {
                      handleAvatarSuccess(response, attr['bind']);
                    }
                  "
                >
                  <DsfImage
                    v-if="page[activeIndex].bindAttr[attr['bind']] && page[activeIndex].bindAttr[attr['bind']].relativePath"
                    :src="page[activeIndex].bindAttr[attr['bind']].relativePath"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div v-if="attr.type == 'dialog'">
                <dsf-button style="display: block" type="plain" @click="openDialog(attr['bind'], attr.url, attr.radio)">选择分类</dsf-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcSaasPageDesign",
  ctrlCaption: "页面设计",
  mixins: [$mixins.control],
  props: {
    bindURL: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pageName: "红色风格",
      components: [],
      page: [],
      activeIndex: "",
      moveIcon: require("../../../assets/imgs/move.png"),
      delIcon: require("../../../assets/imgs/del.png"),
      moveupIcon: require("../../../assets/imgs/moveup.png"),
      movedownIcon: require("../../../assets/imgs/movedown.png"),
    };
  },
  mounted() {
    this.getComponentsJson();
  },
  methods: {
    openDialog(bind, path, radio) {
      this.$openDialog({
        title: "弹框",
        width: "800px",
        height: "600px",
        footerAlign: "",
        params: {
          path: path,
          selectValue: this.page[this.activeIndex].bindAttr[bind] || [],
        },
        btns: [
          {
            text: "确定",
            handler: async res => {
              let data = await res.yes();
              if (dsf.isArray(data)) {
                if (radio && data.length != 1) {
                  dsf.layer.pc.message("请重新操作并选中一条数据！", false);
                } else {
                  this.page[this.activeIndex].bindAttr[bind] = data;
                }
              } else {
                this.page[this.activeIndex].bindAttr[bind] = data;
              }
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
    getComponentsJson() {
      const loading = dsf.layer.loading();
      dsf.http
        .get(
          "meta/list/data",
          {
            pageNum: 1,
            pageSize: 15,
            query: '{"searchValue":""}',
            order: "[]",
            filter: "[]",
            namespace: "nc.saas.setting.component",
            pageName: "list",
          },
          $$webRoot.nc
        )
        .done(({ success, data, message }) => {
          this.components = JSON.parse(data[0]["nc_saas_setting_component.component_json"]);
          console.log("----------");
          console.log(this.components);
          this.getPageData();
        })
        .error(() => {
          dsf.layer.pc.message("获取数据异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    //获取页面楼层信息
    getPageData() {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/getSaasMainBody";
      let params = { saasGroupPageId: this.$route.query.id };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          this.page = data.saasMainBodyDetailList;
          this.pageName = data.saasGroupPageName;
        })
        .error(() => {
          dsf.layer.pc.message("获取数据异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    //插入组件至屏幕
    createComponent(c) {
      let flag = true;
      this.page.forEach(item => {
        if (item.name != "图文导航" && item.name != "精品课程" && item.name == c.name) {
          flag = false;
        }
      });
      if (!flag) return dsf.layer.pc.message("此组件只能在同一页面存在一个，请检查！", false);
      var n = JSON.parse(JSON.stringify(c));
      n.bindAttr = {};
      n.attr.forEach(e => {
        n.bindAttr[e["bind"]] = e["default"] || "";
      });

      this.page.push(n);
      this.checkComponent(this.page.length - 1);
    },
    //选择组件切换属性
    checkComponent(i, e) {
      this.activeIndex = i;
    },
    handleAvatarSuccess(res, attr) {
      this.page[this.activeIndex].bindAttr[attr] = res.data[0];
    },
    //删除楼层
    remove(index) {
      this.page.splice(index, 1);
      this.activeIndex = "";
    },
    //上移
    moveUp(index) {
      let arr = this.swapArr(this.page, index, index - 1);
      this.$set(this, "page", arr);
      this.activeIndex = --index;
    },
    //下移
    moveDown(index) {
      let arr = this.swapArr(this.page, index + 1, index);
      this.$set(this, "page", arr);
      this.activeIndex = ++index;
    },
    swapArr(arr, i, j) {
      let newArr = JSON.parse(JSON.stringify(arr));
      [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
      return newArr;
    },
    //关闭
    closePage() {
      dsf.layer
        .confirm({ message: "退出将不保存当前修改内容，是否确认退出？", title: "提示", confirmButtonText: "退出", cancelButtonText: "取消" })
        .then(() => {
          window.close();
        })
        .catch(() => {});
    },
    //预览
    preview() {
      localStorage.setItem("previewDesign", JSON.stringify(this.page));
      window.open("/page.html#/pc/nc/saas/setting/pagepreview");
    },
    //保存页面配置
    savePage() {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/saveSaasMainBody";
      const header = {
        "Content-Type": "application/json",
      };
      this.page.forEach((p, i) => {
        p.mainBodySort = i;
      });
      let params = {
        saasGroupPageId: this.$route.query.id,
        saasGroupPageName: this.pageName,
        saasMainBodyDetailList: this.page,
      };
      dsf.http
        .post(sourceUrl, params, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.pc.message("保存成功！", success);
          } else {
            dsf.layer.pc.message("操作异常:" + message, success);
          }
        })
        .error(() => {
          dsf.layer.pc.message("操作异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
  },
  watch: {
    page: {
      handler(n) {
        console.log(n);
      },
      deep: true,
    },
  },
});
</script>
