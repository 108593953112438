<template>
  <!-- <div class="control" :style="controlStyle"> -->
  <DsfNcHomeTabPanel
    :borderRadius="borderRadius"
    v-bind.sync="daibanPanelConfig"
    :isDesign="isDesign"
    :visibleSlots="visibleSlots"
    :allSlots="allSlots"
    :workrecordSlots="workrecordSlots"
    :readSlots="readSlots"
    :announcementSlots="announcementSlots"
    @getStatus="getStatus"
    @beenRead="beenRead"
    @change="tabChange"
    :count="count"
  >
    <template v-for="(slot, index) in daibanPanelConfig.slots" :slot="slot.name">
      <DsfNcHomePendingProcess
        :key="index"
        v-bind.sync="daibanPanelContentConfig[slot.name]"
        v-on="$listeners"
        :toConfig="slot.rowClick ? slot.rowClick : daibanPanelContentToConfig"
        :nodeNameShow="daibanPanelConfig.nodeNameShow"
        :url="buildUrl(slot.url)"
        :isDesign="isDesign"
        :pageSize="pageSize"
        :status="status"
        :isBeenRead="isBeenRead"
        @count="getCount"
        :tabActive="tabActive"
      >
        <template slot="operating" slot-scope="scope">
          <slot name="operating" v-bind="scope"></slot>
        </template>
        <template slot="row" slot-scope="scope">
          <slot name="row" v-bind="scope"></slot>
        </template>
      </DsfNcHomePendingProcess>
    </template>
  </DsfNcHomeTabPanel>
  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";
import DsfNcHomePendingProcess from "./pendingProcess.vue";
import DsfHomeTabPanel from "../panel/tabPanel.vue";
export default dsf.component({
  name: "DsfNcMessageNotification",
  mixins: [$mixins.control],
  ctrlCaption: "消息待办新",
  components: { DsfNcHomePendingProcess, DsfHomeTabPanel },
  props: {
    visibleSlots: {
      type: Boolean,
      default: true,
    },
    allSlots: {
      type: Array,
      default() {
        return [];
      },
    },
    workrecordSlots: {
      type: Array,
      default() {
        return [];
      },
    },
    readSlots: {
      type: Array,
      default() {
        return [];
      },
    },
    announcementSlots: {
      type: Array,
      default() {
        return [];
      },
    },
    pageSize: {
      type: [Number, String],
      default: 100,
    },
    selectTabsVals: {
      default: null,
    },
    daibanPanelConfig: {
      default() {
        return {
          slots: [
            {
              title: "全部1",
              name: "all",
              hide: false,
              badge: null,
              lazy: false,
              url: "/message/info/getMessageList?pageNum=1",
              more: {},
            },
            {
              title: "待办",
              name: "workrecord",
              hide: false,
              badge: null,
              lazy: false,
              url: "/message/info/getMessageList?type=workrecord&pageNum=1",
              more: {
                url: "",
              },
            },
            {
              title: "待阅",
              name: "read",
              hide: false,
              badge: null,
              lazy: false,
              url: "/message/info/getMessageList?type=read&pageNum=1",
              more: {
                url: "",
              },
            },
            {
              title: "消息",
              name: "announcement",
              hide: false,
              badge: null,
              lazy: false,
              url: "/message/info/getMessageList?type=announcement&pageNum=1",
              more: {},
            },
          ],
          title: "",
          titleIcon: "",
          showShadow: true,
          width: "100%",
          height: "100%",
          moreIcon: "gengduo",
          moreText: "",
          moreShow: true,
          addIcon: "add",
          addText: "",
          addShow: false,
          settingIcon: "shezhi",
          settingText: "",
          settingShow: true,
          padding: [0, 0, 25, 0],
          margin: [10, 10, 10, 10],
          styleType: "one",
          active: "all",
          badgeUrl: "/message/info/queryMessageTotal?status=0&type=announcement,read,workrecord,all",
          openScroll: false,
          nodeNameShow: false,
        };
      },
    },
    daibanPanelContentConfig: {
      default() {
        return {
          all: {
            width: "100%",
            height: "100%",
            padding: [0, 0, 0, 0],
            list: [],
            url: "/message/info/getMessageList?pageNum=1",
            toConfig: {},
          },
          workrecord: {
            width: "100%",
            height: "100%",
            padding: [0, 0, 0, 0],
            list: [],
            url: "/message/info/getMessageList?type=workrecord&pageNum=1",
            toConfig: {},
          },
          read: {
            width: "100%",
            height: "100%",
            padding: [0, 0, 0, 0],
            list: [],
            url: "/message/info/getMessageList?type=read&pageNum=1",
            toConfig: {},
          },
          announcement: {
            width: "100%",
            height: "100%",
            padding: [0, 0, 0, 0],
            list: [],
            url: "/message/info/getMessageList?type=announcement&pageNum=1",
            toConfig: {},
          },
        };
      },
    },
    daibanPanelContentToConfig: {
      type: Object,
      default: () => ({}),
    },
    // 圆角
    borderRadius: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
  },
  data() {
    return {
      status: "0",
      isBeenRead: false,
      count: 0,
      tabActive: null,
    };
  },
  computed: {
    ...mapState("ws", ["subscript"]),
    controlStyle() {
      let { widthPanel, heightPanel } = this;
      return { width: widthPanel, height: heightPanel };
    },
  },
  watch: {
    "subscript.workrecord": {
      handler(to) {
        this.$set(this.daibanPanelConfig.slots[1], "badge", to || 0);
        console.log("this.daibanPanelConfig", this.daibanPanelConfig.slots);
      },
      immediate: true,
    },
  },
  created() {
    if (!this.isDesign) {
      let daibanPanelContentConfig = { ...this.daibanPanelContentConfig };
      this.$emit("update:daibanPanelContentConfig", daibanPanelContentConfig);
    } else {
      this.$dispatch("design-height-change", "100%");
    }
    let defaultConfig = dsf.mix(this.getPanelDefaultConfig(), this.daibanPanelConfig);
    dsf.mix(this.daibanPanelConfig, defaultConfig);
  },
  beforeDestroy() {
    this._socket?.$end();
  },
  methods: {
    tabChange(tab) {
      this.tabActive = tab["args"];
    },
    beenRead() {
      this.isBeenRead = !this.isBeenRead;
    },
    getStatus(status) {
      this.status = status;
    },
    getCount(count) {
      this.count = count;
      console.log("count", count);
    },
    getPanelDefaultConfig() {
      return {
        title: "",
        titleIcon: "",
        showShadow: true,
        width: "100%",
        height: "100%",
        moreIcon: "gengduo",
        moreText: "",
        moreShow: true,
        addIcon: "add",
        addText: "",
        addShow: false,
        settingIcon: "shezhi",
        settingText: "",
        settingShow: true,
        padding: [0, 0, 25, 0],
        margin: [10, 10, 10, 10],
        styleType: "one",
        active: "all",
        badgeUrl: "/message/info/queryMessageTotal?status=0&type=announcement,read,workrecord,all",
        openScroll: false,
        nodeNameShow: false,
      };
    },
    getRootCtrl() {
      let node = this;
      while (!node.caption && node.$parent !== node) {
        node = node.$parent;
      }
      if (node.caption) {
        return node;
      }
      return this;
    },
    settingClick(tab) {
      this.$openDialog({
        title: "个性化设置",
        showClose: true,
        content: "DsfTabPanelSetting",
        height: "500px",
        width: "800px",
        params: {
          currentData: this.daibanPanelConfig.slots || [],
          selectTabsVals: this.realSelectTabsVals || _.map(this.daibanPanelConfig.slots, "name") || [],
          noUrl: true,
        },
        btns: [
          {
            text: "重置",
            handler: res => {
              res?.reset();
            },
          },
          {
            text: "确定",
            handler: res => {
              let realSelectTabsVals = (res.yes && res.yes()) || [];
              let rootCtrl = this.getRootCtrl();
              if (!realSelectTabsVals.length) {
                realSelectTabsVals = _.map(this.slots, "name");
              }
              if (realSelectTabsVals.indexOf(this.tabActive) === -1) {
                this.tabActive = realSelectTabsVals[0] || "";
              }
              this.selectTabsValsCopy = realSelectTabsVals;
              if (rootCtrl.caption) {
                rootCtrl.$emit("update:selectTabsVals", realSelectTabsVals);
                rootCtrl.$addCustom(rootCtrl.caption, {
                  selectTabsVals: realSelectTabsVals,
                });
              }
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
    buildUrl(url) {
      if (url && /^\/message\/info\/getMessageList/.test(url)) {
        if (!/pageNum\b/.test(url)) {
          if (/\?/.test(url)) {
            url += "&";
          } else {
            url += "?";
          }
          url += "pageNum=1";
        }
      }

      return url;
    },
  },
});
</script>

<style></style>
