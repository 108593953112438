<template>
  <div class="dsf_nc_teacher_share ds-control" v-if="isDesign">
    <div class="title_font">下载图片，进行分享</div>
    <div class="content_wrap" ref="container">
      <div class="content_innter">
        <div class="content_top">
          <div class="header_img">
            <DsfNcDefaultHeader
              class="default-avatar"
              :src="''"
              :width="'124px'"
              :height="'165px'"
              :round="false"
              :defaultHeaderUrl="dsf.config.setting_public_user_default_header"
            />
          </div>
          <div class="content_top_desc">
            <div class="name">谯婷</div>
            <div class="position font">上海梦创双杨</div>
            <div class="deptText font">教授、研究生导师</div>
            <div class="tags" v-if="info.adeptText">
              <span class="tag" v-for="item in info['adeptText'].split('^')" :key="item">{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="content_bottom">
          <div class="qrCode"></div>
          <div class="des">
            <div class="desc_font">扫描二维码</div>
            <div class="desc_btn">查看老师详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button class="btn close">关闭</el-button>
      <el-button type="primary" class="btn down">下载图片</el-button>
    </div>
  </div>
  <div class="dsf_nc_teacher_share" v-else>
    <div class="title_font">下载图片，进行分享</div>
    <div class="content_wrap" ref="container">
      <div class="content_innter">
        <div class="content_top">
          <div class="header_img">
            <DsfNcDefaultHeader
              class="default-avatar"
              :src="info.photo"
              :width="'124px'"
              :height="'165px'"
              :round="false"
              :defaultHeaderUrl="dsf.config.setting_nc_image_teacher_header_img || dsf.config.setting_public_teacher_default_header"
            />
          </div>
          <div class="content_top_desc">
            <div class="name">{{ info.name }}</div>
            <div class="position font">{{ info.position || "" }}</div>
            <div class="deptText font">{{ info.deptText || "" }}</div>
            <div class="tags" v-if="info.adeptText">
              <span class="tag" v-for="item in info['adeptText'].split('^')" :key="item">{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="content_bottom">
          <div class="qrCode">
            <img :src="qrCodeInfo.qrCode" alt="" />
          </div>
          <div class="des">
            <div class="desc_font">扫描二维码</div>
            <div class="desc_btn" @click="clickTeacherDetail">查看老师详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button class="btn close" @click="handlerClose">关闭</el-button>
      <el-button type="primary" class="btn down" @click="handlerDownload">下载图片</el-button>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";

export default dsf.component({
  name: "DsfNcTeacherShare",
  ctrlCaption: "教师卡片",
  mixins: [$mixins.control],
  props: {
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    // 跳转方式
    clickConfig: {
      type: String,
      default: null,
    },
    // 页面地址
    pageUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ids: "",
      info: {
        adeptText: "数字经济^教育^电商^企业^教育^电商^企业^教育^电商^企业^教育^电商^企业",
      },
      qrCodeInfo: {},
    };
  },
  mounted() {
    if (!this.isDesign) {
      this.ids = this.queryString.id || this.$route.query.id || "";
      this.info.adeptText = "";
      this.initData();
      this.initQrcode();
    }
  },
  computed: {
    pageUrlReal() {
      let real = "";
      real = this.$replace(this.pageUrl, this.local);

      return real;
    },
  },
  methods: {
    initData() {
      const sourceUrl = "nc/teacher/info";
      const params = {
        id: this.ids,
      };
      const loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.info = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(message, false);
      }
    },
    initQrcode() {
      /*
        TYPE  ：
          class = 班级
          course = 课程
          live = 直播
          teacher = 教师
      */
      const sourceUrl = "/nc/share/qrcode";
      const params = {
        businessId: "",
        url: this.pageUrlReal || window.location.href,
        type: this.type || this.$route.query.type || "",
        width: 104,
        height: 104,
      };
      const loading = dsf.layer.pc.loading();
      dsf.http
        .post(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.qrCodeInfo = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(message, false);
      }
    },
    clickTeacherDetail() {
      if (dsf.isEmptyObject(this.clickConfig)) return;
      let url = this.$replace(this.clickConfig, this.info);
      this.$open({ url, state: 2 });
    },
    handlerClose() {
      this.$dispatch("close");
      this.$dialog.$parent.cancel();
    },
    handlerDownload() {
      this.$dispatch("downLoad");

      html2canvas(this.$refs.container, {
        useCORS: true,
        x: 0,
        y: 0,
        width: 415,
        height: 390,
      }).then(canvas => {
        let imgUrl = canvas.toDataURL("image/png", 1);
        let link = document.createElement("a");
        link.download = this.info.name + "师资分享.png";
        link.href = imgUrl;
        this.$nextTick(() => {
          link.click();
        });
      });
    },

    html2canvas() {
      return html2canvas(this.$refs.container, {
        useCORS: true,
        x: 0,
        y: 0,
        width: 415,
        height: 390,
      }).then(canvas => {
        var imgUrl = canvas.toDataURL("image/png", 1); // 此方法可以设置截图质量（0-1）
        return imgUrl;
      });
    },
  },
});
</script>
