<template>
  <!--section15-->
  <DsfSection mainWidth="100%" :margin="[0, 0, 0, 0]" :padding="[0, 0, 0, 0]" :backgroundConfig="null">
    <!--ncproductbanner77-->
    <DsfNcProductBanner
      ratio="19/6"
      :url="url"
      :showIndicators="data.bindAttr.model == 'vertical' ? 'none' : 'inside'"
      :vertical="data.bindAttr.model == 'vertical'"
      :indicatorWidth="16"
      :indicatorHeight="16"
      imageKeyName="imgUrl"
    ></DsfNcProductBanner>
  </DsfSection>
</template>
<script>
export default {
  ctrlCaption: "轮播图",
  name: "DsfNcSaasHomeCarousel",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    url: {
      type: String,
      default: "/nc/banner/pclist?code=001&pageSize=6",
    },
    // 配置字段名
    imageKeyName: {
      type: String,
      default: "image",
    },
    titleKeyName: {
      type: String,
      default: "title",
    },
    descKeyName: {
      type: String,
      default: "desc",
    },
    jumpWayKeyName: {
      type: String,
      default: "jumpWay",
    },
  },
  data() {
    return {
      noImgUrl: "",
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.noImgUrl = dsf.config.setting_nc_image_banner_img || dsf.config.setting_nc_image_default_img || "";
  },
  methods: {},
};
</script>
