var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_newsDetail", style: { width: _vm.width } },
    [
      _c("div", { staticClass: "news_wrap" }, [
        _c("h5", { staticClass: "news_title" }, [
          _vm._v(_vm._s(_vm.news[_vm.keyField.title] || "")),
        ]),
        _c("h3", { staticClass: "subtitle" }, [
          _vm._v(_vm._s(_vm.news[_vm.keyField.subhead] || "")),
        ]),
        _vm.news[_vm.keyField.brief]
          ? _c("p", { staticClass: "brief" }, [
              _vm._v("摘要：" + _vm._s(_vm.news[_vm.keyField.brief] || "")),
            ])
          : _vm._e(),
        _c("div", { staticClass: "news_info" }, [
          _c("span", [_vm._v(_vm._s(_vm.news[_vm.keyField.time] || ""))]),
          _c(
            "span",
            [
              _c("dsf-icon", { attrs: { name: "yanjing1" } }),
              _vm._v(
                " " + _vm._s(_vm.news[_vm.keyField.readingNumber] || "") + " "
              ),
            ],
            1
          ),
        ]),
        _vm.news[_vm.keyField.type] == "1" && _vm.news.articlesImagesVos.length
          ? _c(
              "div",
              { staticClass: "moreImg" },
              _vm._l(_vm.news.articlesImagesVos, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("dsf-nc-image", {
                      attrs: {
                        src: _vm._f("imgFormat")(item.imagePath),
                        "error-src": _vm.defaultSrc,
                      },
                    }),
                    _c("div", {
                      staticClass: "news_content",
                      domProps: { innerHTML: _vm._s(item.content) },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.news[_vm.keyField.type] == "2" && _vm.news[_vm.keyField.video]
          ? _c(
              "div",
              { staticClass: "news-video" },
              [
                _c("dsfNcPlayer", {
                  attrs: {
                    videoSource: _vm.getVideoPlay(_vm.news[_vm.keyField.video]),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.news[_vm.keyField.type] == "3"
          ? _c("div", { staticClass: "news-link" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.news[_vm.keyField.externalLink],
                    target: "_blank",
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.news[_vm.keyField.source] || "点击跳转")),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm.news[_vm.keyField.content]
          ? _c("div", {
              staticClass: "news_content",
              domProps: { innerHTML: _vm._s(_vm.news[_vm.keyField.content]) },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }