var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-special-source-controll ds-control" }, [
    _vm.showModel
      ? _c(
          "div",
          { staticClass: "dsf-special-source-model" },
          [
            _c("el-switch", {
              staticClass: "model",
              attrs: {
                "inactive-text": "高级模式",
                width: "20",
                disabled: _vm.readonly,
              },
              on: { change: _vm.modelChange },
              model: {
                value: _vm.modelLv,
                callback: function ($$v) {
                  _vm.modelLv = $$v
                },
                expression: "modelLv",
              },
            }),
            _vm.modelLv
              ? _c(
                  "div",
                  { staticClass: "items-wrap" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.readonly },
                        on: { change: _vm.radioChange },
                        model: {
                          value: _vm.modelText,
                          callback: function ($$v) {
                            _vm.modelText = $$v
                          },
                          expression: "modelText",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "无学习限制" } }),
                        _c("el-radio", { attrs: { label: "不允许提前学习" } }),
                        _c("el-radio", {
                          attrs: { label: "只能在规定时间段内学习" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.chapter.length > 0
      ? _c(
          "div",
          [
            _vm._l(_vm.chapter, function (item, index) {
              return [
                _c("specialSourceControllItem", {
                  key: item.name,
                  attrs: {
                    buttons: _vm.buttons,
                    list: item.list,
                    stateConfig: item.config,
                    name: item.name,
                    unitId: item.unitId,
                    desc: item.desc,
                    idx: index,
                    sort: item.sort,
                    sourceUrl: _vm.sourceUrl,
                    maxHours: _vm.maxHours,
                    showTime: _vm.modelLv,
                    showMustBe: _vm.showMustBe,
                    sDate: _vm.sDate,
                    eDate: _vm.eDate,
                  },
                  on: {
                    deleteChaterItem: _vm.deleteChaterItem,
                    updateChapterList: _vm.updateChapterList,
                    chapterToUp: _vm.chapterToUp,
                    chapterToDown: _vm.chapterToDown,
                    updateSaveList: _vm.updateSaveList,
                  },
                }),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "add-control-btn",
        class: { "item-readonly": _vm.readonly },
      },
      [
        _c(
          "dsf-button",
          {
            staticClass: "add-contol-item",
            attrs: { size: "medium" },
            on: { click: _vm.addChapter },
          },
          [_vm._v("添加章节")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }