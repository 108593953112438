<template>
  <div class="ds_newslist" :style="controlStyle">
    <div class="content" v-if="newsList.length">
      <div class="ds_newslist-row" v-for="(item, index) in newsList" :key="index" @click="_clickBefore(goDetail, item)" :style="rowStyle">
        <p class="time">{{ item[keyField.time] ? dsf.date.format(item[keyField.time], "yyyy.mm.dd") : "" }}</p>
        <p class="title ell">{{ item[keyField.title] }}</p>
      </div>
    </div>
    <div v-else class="noDate">暂无数据</div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcNewsList",
  ctrlCaption: "新闻列表",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    margin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    padding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    rowPadding: {
      type: Array,
      default() {
        return [12, 0, 12, 0];
      },
    },
    rowMargin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    detailUrl: {
      type: String,
      default: "",
    },
    reqUrl: {
      type: String,
      default: "",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "title", to: "title", type: "text", desc: "新闻标题" },
          { from: "time", to: "time", type: "text", desc: "时间" },
        ];
      },
    },
    news: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    news: {
      handler(newValue, oldValue) {
        // if (newValue.length) {
        this.newsList = newValue;
        // }
      },
      deep: true,
    },
  },
  computed: {
    controlStyle() {
      let { margin, width, height, padding, isDesign, visible } = this;
      let style = {
        margin: margin.join("px ") + "px",
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    rowStyle() {
      let { rowPadding, rowMargin } = this;
      let style = {
        margin: rowMargin.join("px ") + "px",
        padding: rowPadding.join("px ") + "px",
      };
      return style;
    },
  },
  data() {
    return {
      keyField: {},
      id: "",
      newsList: [],
    };
  },
  mounted() {
    this.id = this.queryString["id"] || "";
    this.keysMap.map(item => {
      this.keyField[item.to] = item.from;
    });
    if (!this.isDesign) {
      this.initData();
    } else {
      this.newsList = [
        {
          title: "关于印发《生态环境部2021年度培训计划》的通知",
          time: "2021/04/05 10:00:00",
        },
        {
          title: "关于印发《生态环境部2021年度培训计划》的通知关于印发《生态环境部2021年度培训计划》的通知",
          time: "2021/04/05 10:00:00",
        },
        {
          title: "关于印发《生态环境部2021年度培训计划》的通知",
          time: "2021/04/05 10:00:00",
        },
      ];
    }
  },
  methods: {
    initData() {
      if (!this.reqUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.reqUrl, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.newsList = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        if (this.$checkLogin) {
          this.$checkLogin().then(res => {
            if (res) next(...params);
          });
        } else {
          next(...params);
        }
      } catch (err) {
        console.log(err);
      }
    },
    goDetail(item) {
      if (this.$listeners["click"]) {
        this.$dispatch("click", item);
      } else {
        // if (this.detailUrl) {
        //   let url = this.$replace(this.detailUrl, item);
        //   url && dsf.layer.openWindow(url);
        // }
        if (item.type == 3) {
          item.externalLink && window.open(item.externalLink);
        } else {
          if (this.detailUrl) {
            let url = this.$replace(this.detailUrl, item);
            url && dsf.layer.openWindow(url);
          }
        }
      }
      //获取积分
      dsf.http
        .get("nc/cms/articles/queryArticlesById", { id: item.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            dsf.layer.message(res.message || "积分异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(res.message || "积分异常", false);
        })
        .always(res => {});
    },
  },
});
</script>
