var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_learning_zone" }, [
    _c("div", { staticClass: "learning-inner" }, [
      _c(
        "div",
        {
          staticClass: "swiper-container channel-banner",
          style: { visibility: _vm.hasLoad ? "inherit" : "hidden" },
        },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.bannerList, function (item) {
              return _c(
                "div",
                {
                  key: item._id,
                  class: [
                    "swiper-slide",
                    { "swiper-no-swiping": _vm.bannerList.length <= 1 },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      style:
                        "background:url(" +
                        _vm.setImage(item["nc_channel_page_mainData.cover"]) +
                        ") no-repeat;background-size:100%;",
                      on: {
                        click: function ($event) {
                          return _vm.toChannelDetail(item)
                        },
                      },
                    },
                    [
                      _c("dsf-nc-image", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showIcon,
                            expression: "showIcon",
                          },
                        ],
                        attrs: {
                          src: _vm.setImage(
                            item["nc_channel_page_mainData.cover"]
                          ),
                        },
                        on: {
                          load: _vm.iconLoadSuccess,
                          error: _vm.iconLoadError,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "swiper-button-prev prev" },
        [_c("dsf-icon", { attrs: { name: "arrow-left2" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "swiper-button-next next" },
        [_c("dsf-icon", { attrs: { name: "arrow-right2" } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }