<template>
  <div class="dsf_nc_footer_three" v-if="isDesign" :style="{ width: itemWidth, margin: margin }">
    <div class="nc-footer-inner">
      <div class="footer-top">
        <div class="wrap">
          <div class="regards" v-for="(nav, index) in data.footUrls" :key="index" @click="toPage(nav.url)" v-show="nav.name">{{ nav.name }}</div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="internet_provider">沪ICP备18014503号-5</div>
        <div class="internet_provider">
          <img class="police-icon-new" v-if="data.policeInternetImgTwo" :src="JSON.parse(data.policeInternetImgTwo).relativePath" />
          <i v-else class="polic-icon"></i>
          <span>沪公网安备 31010602006053</span>
        </div>
        <div class="internet_provider">等级备案号：21010643029-21001</div>
      </div>
    </div>
  </div>
  <div class="dsf_nc_footer_three" v-else :style="{ width: itemWidth, margin: margin }">
    <div class="nc-footer-inner">
      <div class="footer-top">
        <div class="wrap">
          <div class="regards" v-for="(nav, index) in data.footUrls" :key="index" @click="toPage(nav.url)" v-show="nav.name">{{ nav.name }}</div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="internet_provider" v-if="data.internetNumTwo" @click="toPage(copyNumberSourceUrl)">{{ data.internetNumTwo }}</div>
        <div class="internet_provider" v-if="data.policeInternetNumTwo" @click="toPage(policeNumberSourceUrl)">
          <img class="police-icon-new" v-if="data.policeInternetImgTwo" :src="JSON.parse(data.policeInternetImgTwo).relativePath" />
          <i v-else class="polic-icon"></i>
          <span>{{ data.policeInternetNumTwo }}</span>
        </div>
        <div class="internet_provider" v-if="data.levelInternetNumTwo" @click="toPage(levNumberSourceUrl)">等级备案号：{{ data.levelInternetNumTwo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcFooterThree",
  ctrlCaption: "门户页脚3",
  mixins: [$mixins.control],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    itemWidth: {
      type: String,
      default: "100%",
    },
    margin: {
      type: String,
      default: "20px 0 0 0",
    },
    sourceUrl: {
      type: String,
      default: "nc/saas/count/home",
    },
    copyNumberSourceUrl: {
      type: String,
      default: "http://icp.chinaz.com/home/info?host=xuexibang.cn",
    },
    policeNumberSourceUrl: {
      type: String,
      default: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006053",
    },
    levNumberSourceUrl: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    if (this.isDesign) {
      this.data.footUrls = [{ name: "关于我们" }, { name: "免责申明" }, { name: "合作伙伴" }, { name: "联系方式" }];
    }
  },
  methods: {
    toPage(it) {
      this.$open({
        url: it.indexOf("http") == -1 ? "http://" + it : it,
        state: 2,
      });
    },
  },
});
</script>
