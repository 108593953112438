var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_channel_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            { staticClass: "item-left-img" },
            [
              _c("dsf-image", {
                staticClass: "img",
                attrs: { src: _vm._f("imgFormat")("") },
              }),
              _vm._m(0),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "circle-wrap" },
            [
              _c("el-progress", {
                staticClass: "progress-circle",
                attrs: {
                  width: "60",
                  "stroke-width": "5",
                  color: _vm.curColor,
                  type: "circle",
                  percentage: 33,
                },
              }),
              _c("p", [_vm._v("当前进度")]),
            ],
            1
          ),
        ]),
      ])
    : _c("div", { staticClass: "dsf_channel_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            { staticClass: "item-left-img" },
            [
              _c("dsf-image", {
                staticClass: "img",
                attrs: { src: _vm._f("imgFormat")(_vm.data.cover) },
              }),
              _c("div", { staticClass: "item-right-desc" }, [
                _c("div", { staticClass: "title ell2" }, [
                  _vm._v(_vm._s(_vm.data.title || "")),
                ]),
                _c("div", { staticClass: "resource_period" }, [
                  _c("span", [
                    _vm._v("资源数: " + _vm._s(_vm.data.resource || 0)),
                  ]),
                  _vm._v("   |   "),
                  _c("span", [_vm._v("学时: " + _vm._s(_vm.data.period || 0))]),
                ]),
                _c("div", { staticClass: "subscribed" }, [
                  _vm._v("订阅人数: " + _vm._s(_vm.data.subscribed || 0)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "circle-wrap" },
            [
              _c("el-progress", {
                staticClass: "progress-circle",
                attrs: {
                  width: "60",
                  "stroke-width": "5",
                  color: _vm.curColor,
                  type: "circle",
                  percentage: _vm.data.finishedRateOrScore,
                },
              }),
              _c("p", [_vm._v("当前进度")]),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-right-desc" }, [
      _c("div", { staticClass: "title ell2" }, [_vm._v("我是一个标题1111")]),
      _c("div", { staticClass: "resource_period" }, [
        _c("span", [_vm._v("资源数: 8")]),
        _vm._v("   |   "),
        _c("span", [_vm._v("学时: 3")]),
      ]),
      _c("div", { staticClass: "subscribed" }, [_vm._v("订阅人数: 1382")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }