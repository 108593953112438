var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_special_detail_intro",
      style: { display: _vm.visible ? "block" : "none" },
    },
    [
      _vm.detailData
        ? _c("div", {
            staticClass: "intro_wrap",
            domProps: { innerHTML: _vm._s(_vm.detailData) },
          })
        : _c("div", {
            staticClass: "ds-empty-data",
            domProps: { textContent: _vm._s("暂无数据") },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }