<template>
  <div class="dsf_nc_note_item">
    <div class="dsf_nc_note_item_inner">
      <el-form @submit.native.prevent>
        <el-form-item class="note-text">
          <el-input type="textarea" :placeholder="setPlaceholder" v-model="form.desc" maxlength="1000" show-word-limit @focus="handleScreenshot()" @input="handleTextInput"></el-input>
        </el-form-item>
        <div class="note-handle">
          <el-form-item label="公开" class="note-handle-item" width="40">
            <el-switch v-model="form.public"></el-switch>
          </el-form-item>
          <button class="note-save" :class="{ 'no-text': isDisabled }" :disabled="isDisabled" @click="saveNote" :style="{ width: btnWidth }">保存</button>
        </div>
      </el-form>
      <div class="note-list-wrap">
        <div class="note-list" ref="noteList">
          <div class="note-head">
            <span>历史笔记</span>
            <div class="note-list-class">
              <el-radio-group v-model="form.showType" @change="filterNote">
                <el-radio label="我的"></el-radio>
                <el-radio label="全部"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <template v-if="noteList.length > 0">
            <div class="note-list-item" v-for="(item, index) in noteList" :key="item._id">
              <p class="note-list-item-top">
                <span>{{ getPrefixText(item) }}: {{ item["nc_learn_note_resourcelistsource.node_time"] || "" }}</span>
                <span>{{ item["nc_learn_note_resourcelistsource.r_time"] || "" }}</span>
              </p>
              <div class="note-list-item-desc">
                <div class="note-img">
                  <dsf-image :src="item['nc_learn_note_resourcelistsource.node_picture'] | imgFormat" :errorSrc="dsf.config.setting_nc_image_default_img" fit="cover" />
                </div>
                <div class="note-info">
                  <div>
                    <span :style="{ color: fontColor }">
                      <dsf-icon name="person" />
                      {{ item["nc_learn_note_resourcelistsource.user_name"] }}
                    </span>
                    <div>
                      <span @click="updateItem(index)" v-if="item['nc_learn_note_resourcelistsource.is_own'] == 1">编辑</span>
                      <span @click="delNote(item)" v-if="item['nc_learn_note_resourcelistsource.is_own'] == 1">删除</span>
                    </div>
                  </div>
                  <div class="node-text" v-if="item.readOnly" :style="{ color: fontColor }">
                    {{ item["nc_learn_note_resourcelistsource.node_words"] }}
                  </div>
                  <el-input type="textarea" ref="noteText" v-else class="node-text node-text-area" v-model="tempText" @blur="saveNote(index, item)"></el-input>
                </div>
              </div>
            </div>
          </template>
          <div class="no-data no-data-note" v-else>
            <i class="no-data-icon"></i>
            <p>暂无笔记</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "dsfNcNoteItem",
  props: {
    setPlaceholder: {
      type: String,
      default: "请输入笔记内容",
    },
    moreUrl: {
      type: String,
      default: "",
    },
    titlePadding: {
      type: String,
      default: "50px 0 58px 0",
    },
    relationTitle: {
      type: String,
      default: "",
    },
    relationId: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    liveCover: {
      type: String,
      default: "",
    },
    queryiType: {
      type: String,
      default: "ztb",
    },
    btnWidth: {
      type: String,
      default: "",
    },
    fontColor: {
      type: String,
      default: "",
    },
    curItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        desc: "",
        public: false,
        showType: "我的",
      },
      noteList: [],
      tempText: "",
      tempParams: {
        isOwn: 1,
      },
      hasLoaded: false,
      loadCount: 0,
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters(["getScreenshot", "getCurPlayTime"]),
    coverImage() {
      let cover = "";
      if (this.liveCover) cover = this.liveCover;
      if (this.getScreenshot) {
        cover = JSON.stringify(this.getScreenshot);
      } else {
        if (this.item?.cover) cover = this.item.cover;
      }
      return cover;
    },
    curCourseType() {
      let curItem = this.curItem;
      const value = curItem.rTypeValue;
      const text = curItem.rTypeText;
      return {
        text,
        value,
      };
    },
  },
  created() {},
  mounted() {
    this.loadNoteList();
  },
  methods: {
    loadNoteList(state) {
      const sourceUrl = "meta/list/data";
      const params = {
        pageNum: ++this.loadCount,
        pageSize: 20,
        query: "{searchValue:''}",
        order: "[]",
        namespace: "nc.learn.note",
        pageName: "resourcelist",
        relationId: this.relationId,
      };
      dsf.http
        .get(sourceUrl, Object.assign(params, this.tempParams), $$webRoot.nc)
        .done(({ success, data }) => {
          if (success) {
            if (data.length < params.pageSize) this.hasLoaded = true;
            _.each(data, item => {
              item.readOnly = true;
            });
            this.noteList = data;
            this.setNoteListHeight();
          }
        })
        .error(err => {
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {});
    },
    setNoteListHeight() {
      this.$nextTick(function () {
        const it = this.$refs.noteList;
        let winH = window.innerHeight;
        let itTop = it.offsetTop;
        let itH = it.offsetHeight;
        if (winH - itTop < itH) {
          this.$refs.noteList.style.height = winH - itTop - 30 + "px";
          this.$refs.noteList.style.overflowY = "scroll";
        }
      });
    },
    handleScreenshot() {
      this.$parent.$refs.coursePlayer && this.$parent.$refs.coursePlayer.playerEmitScrrenshot();
    },
    handleTextInput(e) {
      this.isDisabled = e ? false : true;
    },
    getPrefixText(item) {
      if (item) {
        const value = item["nc_learn_note_resourcelistsource.node_type_value"] || "video";
        const texts = {
          document: "文稿节点",
          video: "视频节点",
          audio: "音频节点",
          medium: "富媒体节点",
          link: "外链节点",
        };
        return texts[value];
      }
    },
    saveNote(idx, data) {
      // console.log(this.item);
      // console.log(this.curItem);
      this.$checkLogin().then(res => {
        if (res) {
          let item = this.form;
          if (item.desc == "" && this.tempText == "") return dsf.layer.pc.message("笔记内容不能为空", false);
          if (this.tempText.length > 1000) return dsf.layer.pc.message("笔记内容超长，请重新输入", false);
          const sourceUrl = "nc/learn/note/save";
          let params = {
            relationId: this.relationId,
            nodeTime: this.getCurPlayTime,
            nodePicture: this.coverImage,
            nodeWords: item.desc,
            isPublic: item.public ? 1 : 0,
            rTypeValue: this.isLive ? "live" : this.queryiType == "ztb" ? "class" : "course",
            relationTitle: this.relationTitle ? this.relationTitle : this.item.title,
            nodeTypeText: this.curCourseType.text,
            nodeTypeValue: this.curCourseType.value,
          };
          if (data) {
            //修改笔记 传入当前笔记id
            params = Object.assign(params, {
              id: this.noteList[idx]["_id"],
              nodeWords: this.tempText,
            });
          }
          dsf.http
            .post(sourceUrl, params, $$webRoot.nc)
            .done(res => {
              if (res.success) {
                this.loadCount = 0;
                this.loadNoteList();
                this.tempText = "";
                this.form.desc = "";
                this.isDisabled = true;
                dsf.layer.pc.message("保存成功", true);
              } else {
                dsf.layer.pc.message(res.message, false);
              }
            })
            .error(err => {
              dsf.layer.pc.message(err.message, false);
            })
            .always(res => {
              // dsf.layer.pc.closeLoading(loading);
            });
        }
      });
    },
    delNote(item) {
      const sourceUrl = "nc/learn/note/delete";
      let params = {
        ids: JSON.stringify(item._id),
      };
      dsf.http
        .post(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.loadCount = 0;
            this.loadNoteList();
            this.tempText = "";
            dsf.layer.pc.message("删除成功", true);
          } else {
            dsf.layer.pc.message("删除失败", false);
          }
        })
        .error(err => {
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {
          // dsf.layer.pc.closeLoading(loading);
        });
    },
    updateItem(idx) {
      this.noteList[idx].readOnly = false;
      this.tempText = this.noteList[idx]["nc_learn_note_resourcelistsource.node_words"];
    },
    setScrrenshot(item) {
      const data = item;
      let src = "";
      if (dsf.isDef(data) && data[0]) {
        src = data[0].photo ? dsf.url.getWebPath(JSON.parse(data[0].photo)[0].relativePath) : "";
      }
      return src;
    },
    // 我的笔记 全部笔记切换
    filterNote(text) {
      if (text == "我的") {
        this.tempParams = {
          isOwn: 1,
        };
      } else {
        this.tempParams = {
          isAll: 1,
        };
      }
      this.loadCount = 0;
      this.loadNoteList();
    },
  },
  beforeDestroy() {},
};
</script>
