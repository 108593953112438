var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds_channel_detail" }, [
        _c("div", { staticClass: "ds_channel_detail_wrap" }, [
          _c("div", { staticClass: "channel-detail-inner" }, [
            _c(
              "div",
              { staticClass: "cover" },
              [
                _c("el-image", {
                  attrs: {
                    src:
                      "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                  },
                }),
              ],
              1
            ),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "channel-button" },
              [_c("dsf-icon", { attrs: { name: "iconfontzhizuobiaozhun13" } })],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "channel-content" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.changeTab },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "简介", name: "first" } }, [
                  _vm._v("简介"),
                ]),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "章节", name: "second" } },
                  [_vm._v("章节")]
                ),
              ],
              1
            ),
            _vm.activeName == "first"
              ? _c("div", { staticClass: "intro_inner" }, [
                  _c(
                    "div",
                    {
                      staticClass: "resouce",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [
                      _c("DsfNcspecialDetailRelevantInformation", {
                        attrs: {
                          showLine: _vm.showLine,
                          itemWidth: _vm.itemWidth,
                          page: _vm.page,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "recommend" },
                    [
                      _c("DsfNcSpecialDetailIntroRecommend", {
                        attrs: {
                          url: "/nc/pack/channel/recommendlist",
                          type: "channel",
                          showMore: true,
                          moreStyle: _vm.moreStyle,
                          title: _vm.title,
                          moreUrl: _vm.moreUrl,
                          itemConfig: _vm.itemConfig,
                          showLine: _vm.showLine,
                          itemWidth: _vm.itemWidth,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "ds_channel_detail" }, [
        _vm.it
          ? _c("div", { staticClass: "ds_channel_detail_wrap" }, [
              _c("div", { staticClass: "channel-detail-inner" }, [
                _c(
                  "div",
                  { staticClass: "cover" },
                  [
                    _c("dsf-image", {
                      attrs: { src: _vm._f("imgFormat")(_vm.it.cover) },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "desc" }, [
                  _c("h3", { staticClass: "desc-name" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.it.title)),
                    ]),
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("span", [_vm._v("发布: " + _vm._s(_vm.it.time))]),
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("span", [
                      _vm._v(
                        "资源数: " + _vm._s(_vm.it.courseCount || 0) + "门"
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "info info-bottom" }, [
                    _c("span", [
                      _vm._v(
                        "浏览: " +
                          _vm._s(_vm.it.hits || 0) +
                          "次  |  订阅数: " +
                          _vm._s(_vm.it.subscription || 0)
                      ),
                    ]),
                  ]),
                  _vm.it.labelText
                    ? _c(
                        "div",
                        { staticClass: "info info-bottom" },
                        _vm._l(_vm.it.labelText.split("^"), function (item) {
                          return _c(
                            "span",
                            { key: item, staticClass: "tags" },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "channel-button" }, [
                  _c(
                    "button",
                    {
                      staticClass: "subcrible",
                      on: {
                        click: function ($event) {
                          return _vm.toSubscrible(_vm.it, "mySubscribed")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.showSubText))]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "channel-content" }, [
          _c(
            "div",
            {
              class: [
                "channel_content_inner",
                { channel_content_inner_left: _vm.activeName == "first" },
              ],
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.changeTab },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "简介", name: "first" } },
                    [
                      _vm.it && _vm.it.describe
                        ? _c("div", { staticClass: "channel-content-intro" }, [
                            _c("p", {
                              staticClass: "text",
                              domProps: { innerHTML: _vm._s(_vm.it.describe) },
                            }),
                          ])
                        : _c("div", {
                            staticClass: "ds-empty-data",
                            domProps: { textContent: _vm._s("暂无数据") },
                          }),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "章节", name: "second" } },
                    [
                      _vm.list && _vm.list.length > 0
                        ? _vm._l(_vm.list, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: item.unitName,
                                staticClass: "channel-chapter",
                                class: { "show-chapter": item.toggle },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chapter-title",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changItemFold(_vm.it, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("h3", { staticClass: "title" }, [
                                      _vm._v(" " + _vm._s(item.unitName) + " "),
                                      _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(item.totalPeriod || 0) +
                                            "学时)"
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "toggle-name" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.toggle ? "展开" : "收起")
                                        ),
                                      ]),
                                      item.toggle
                                        ? _c("i", {
                                            staticClass:
                                              "icon iconfont icon-xia1 sq",
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "icon iconfont icon-shang1 sq",
                                          }),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "chapter-body" },
                                  _vm._l(item.subList, function (it, idx) {
                                    return _c(
                                      "dl",
                                      {
                                        key: it.businessId,
                                        staticClass: "body-content",
                                      },
                                      [
                                        _c("dd", { staticClass: "name" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "state",
                                              style:
                                                "background-color:" +
                                                _vm.getBgcColor(it.typeValue),
                                            },
                                            [_vm._v(_vm._s(it.typeText))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "title ellipsis",
                                              attrs: { title: it.name },
                                            },
                                            [_vm._v(_vm._s(it.name))]
                                          ),
                                        ]),
                                        it.duration
                                          ? _c(
                                              "dd",
                                              { staticClass: "duration" },
                                              [_vm._v(_vm._s(it.duration))]
                                            )
                                          : _vm._e(),
                                        _c("dd", { staticClass: "hours" }, [
                                          _vm._v(_vm._s(it.period) + "学时"),
                                        ]),
                                        _c(
                                          "dd",
                                          { staticClass: "progress" },
                                          [
                                            _c("el-progress", {
                                              attrs: {
                                                percentage: it.progress,
                                                "stroke-width": 10,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "dd",
                                          {
                                            class: [
                                              "handle",
                                              {
                                                gray: !_vm.isOrderTypeValue(
                                                  it,
                                                  idx,
                                                  item
                                                ),
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                _vm.isOrderTypeValue(
                                                  it,
                                                  idx,
                                                  item
                                                ) && _vm.toDetail(it)
                                              },
                                            },
                                          },
                                          [_vm._v("进入")]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          })
                        : _c("div", {
                            staticClass: "ds-empty-data",
                            domProps: { textContent: _vm._s("暂无数据") },
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.activeName == "first"
            ? _c("div", { staticClass: "intro_inner" }, [
                _c(
                  "div",
                  {
                    staticClass: "resouce",
                    staticStyle: { "margin-bottom": "20px" },
                  },
                  [
                    _c("DsfNcspecialDetailRelevantInformation", {
                      attrs: {
                        showLine: _vm.showLine,
                        itemWidth: _vm.itemWidth,
                        page: _vm.page,
                        showMore: _vm.inforShowMore,
                        moreStyle: _vm.inforMoreStyle,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "recommend" },
                  [
                    _c("DsfNcSpecialDetailIntroRecommend", {
                      attrs: {
                        url: _vm.recommendUrl,
                        type: "channel",
                        showMore: _vm.showMore,
                        moreStyle: _vm.moreStyle,
                        title: _vm.title,
                        moreUrl: _vm.moreUrl,
                        itemConfig: _vm.itemConfig,
                        showLine: _vm.showLine,
                        itemWidth: _vm.itemWidth,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("h3", { staticClass: "desc-name" }, [
        _c("span", { staticClass: "name" }, [_vm._v("2")]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("span", [_vm._v("111发布: 2020-10-21")]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("span", [_vm._v("资源数：共 30门")]),
      ]),
      _c("div", { staticClass: "info info-bottom" }, [
        _c("span", [_vm._v("浏览 12221次")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }