var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_live_teacher_card" }, [
    _vm.isDesign
      ? _c("ul", { staticClass: "live_card_wrap" }, [
          _c("li", { staticClass: "live_card_item" }, [
            _c(
              "div",
              { staticClass: "img_box" },
              [
                _c("div", { staticClass: "state yjs" }, [_vm._v("已结束")]),
                _c("dsf-nc-image", {
                  attrs: {
                    src: _vm._f("imgFormat")(_vm.data["cover"]),
                    "error-src": _vm.defaultSrc,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "item_right_box" }, [
              _vm._m(0),
              _c("div", { staticClass: "name" }, [
                _vm._v("一带一路与中华文化海外传播"),
              ]),
              _c("div", { staticClass: "foot" }, [
                _c(
                  "div",
                  { staticClass: "foot_left" },
                  [
                    _c("dsf-icon", { attrs: { name: "icon_person1" } }),
                    _c("span", [_vm._v("北京行政学院主任 · 邓邓")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "views_count" },
                  [
                    _c("dsf-icon", { attrs: { name: "yanjing1" } }),
                    _c("span", [_vm._v("4527")]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ])
      : _c("ul", { staticClass: "live_card_wrap" }, [
          _c(
            "li",
            {
              staticClass: "live_card_item",
              on: {
                click: function ($event) {
                  return _vm.handlerClick(_vm.data)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "img_box" },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "state",
                        { wks: _vm.data.current_state_text == "未开始" },
                        { yjs: _vm.data.current_state_text == "已结束" },
                        { zbz: _vm.data.current_state_text == "直播中" },
                      ],
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.data.current_state_text || "") + " "
                      ),
                    ]
                  ),
                  _c("dsf-nc-image", {
                    attrs: {
                      src: _vm._f("imgFormat")(_vm.data["cover"]),
                      "error-src": _vm.defaultSrc,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "item_right_box" }, [
                _c("div", { staticClass: "date" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dsf.date.format(_vm.data.time, "mm-dd hh:ii")
                      ) +
                      "  |   "
                  ),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.data.current_state_text == "已结束"
                          ? "回放"
                          : "进入直播"
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name ell" }, [
                  _vm._v(_vm._s(_vm.data.title || "")),
                ]),
                _c("div", { staticClass: "foot" }, [
                  _c(
                    "div",
                    { staticClass: "foot_left" },
                    [
                      _c("dsf-icon", { attrs: { name: "icon_person1" } }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.data.job || "") +
                            " · " +
                            _vm._s(_vm.data.teacher_text || "")
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "views_count" },
                    [
                      _c("dsf-icon", { attrs: { name: "yanjing1" } }),
                      _c("span", [_vm._v(_vm._s(_vm.data.views || 0))]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "date" }, [
      _vm._v(" 3月23日 20:00 | "),
      _c("span", [_vm._v("回放")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }