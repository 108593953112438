<template>
  <div class="dsf_course_poylv_player" :style="{ width: itemWidth }">
    <div class="course-player-inner" :style="{ height: docHeight }">
      <div class="course-player-left">
        <div class="course-header-left">
          <div class="course-header-left-wrap">
            <span class="course-header-left-title ellipsis" :title="chapterInfo.title || ''" v-if="chapterInfo">{{ chapterInfo.title || "" }}</span>
            <!-- <el-rate v-model="rateNumber" disabled show-score text-color="#ff9900"></el-rate> -->
            <div class="course-header-left-buttons" v-if="curItemType !== 'document' && curItemType !== 'link'">
              <button :class="{ 'course-header-left-buttons-sel': playPattern }" @click="changePattern(true)">视频模式</button>
              <button :class="{ 'course-header-left-buttons-sel': !playPattern }" @click="changePattern(false)">讲义模式</button>
            </div>
          </div>
          <div class="course-header-left-event">
            <span :class="[isAssessed ? 'active-item' : '']" @click="handleComment()">
              <i class="iconfont icon-pingjia2"></i>
              评价
              <!-- {{ isAssessed ? "已评价1" : "评价2" }} -->
            </span>
            <span @click="handleCollect()">
              <i class="iconfont icon-shoucang2"></i>
              {{ hasCollected ? "已收藏" : "收藏" }}
            </span>
            <span @click="joinStudy">
              <i class="iconfont icon-add"></i>
              {{ hasJoin ? "取消学习计划" : "加入学习计划" }}
            </span>
          </div>
        </div>
        <div class="course-player-pattern">
          <!-- 播放模式 -->
          <div
            ref="dragItem"
            class="course-player-drag"
            @mousedown.stop="playerMousedown('dragItem', $event)"
            @mousemove.stop="playerMousemove('dragItem', $event)"
            @mouseup.stop="playerMouseup('dragItem', $event)"
            v-if="curPlayItem && curItemType !== 'document' && curItemType !== 'link'"
          >
            <dsfNcPoylvPlayer
              ref="coursePlayer"
              v-if="curPlayItem"
              :videoSource="videoSrc"
              @playerHasPlay="playerHasPlay()"
              @playerHasPause="playerHasPause()"
              @playHasEnd="playEnd"
            />
          </div>
          <!-- 讲义模式 -->
          <div class="course-document-pattern" v-if="curPlayItem && docUrl && curItemType !== 'link'">
            <iframe :src="docUrl" frameborder="0" scrolling="no"></iframe>
          </div>
        </div>
      </div>
      <div class="course-player-right" ref="rightBox">
        <div class="course-toggle-bar-wrap" @click="toggle()">
          <div class="toggle-bar">
            <i>||</i>
          </div>
        </div>
        <div class="right-box-inner">
          <div class="right-header-wrap">
            <div class="right-header" v-if="!dsf.isEmptyObject(baseInfo) && baseInfo.teacherList && baseInfo.teacherList.length > 0">
              <div class="teacher" @click="toTeacherDetail(baseInfo.teacherList[0])">
                <div class="avatar">
                  <el-image :src="baseInfo.teacherList[0].photo | imgFormat" fit="cover"></el-image>
                </div>
                <div class="desc">
                  <h3 class="name">{{ baseInfo.teacherList[0].name }}</h3>
                  <div class="dept ellipsis-2">{{ baseInfo.teacherList[0].unitJob }}</div>
                  <span class="total">{{ baseInfo.teacherList[0].courseNum || 0 }}门精品课程</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right-tab-items">
            <div class="tab-menus-wrap">
              <div class="tab-menus">
                <span :class="['tab-menus-name', getCurTabSelMenu($index)]" v-for="(item, $index) in tabList" :key="item.id" @click="tabMenuChange($index)">
                  {{ item.name }}
                </span>
              </div>
            </div>
            <div class="tab-content" :style="`height:${winH - 220}px`">
              <div class="content-item" v-if="tabIdx == 0">
                <dsf-nc-course-chapter :chapters="chapterInfo" @changeCurPlayCourse="changeCurPlayCourse" />
              </div>
              <div class="content-item" v-if="tabIdx == 1">
                <div class="introduce" v-if="baseInfo" v-html="baseInfo.content || ''"></div>
              </div>
              <div class="content-item" v-if="tabIdx == 2">
                <dsf-nc-note-item :curItem="getCurPlayItem" :relationId="queryiId" :queryiType="queryiType" :item="baseInfo" />
              </div>
              <!-- <div class="content-item" v-if="tabIdx == 3">
                <dsf-nc-comment />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 200px"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import commentDialog from "./commentDialog";
export default dsf.component({
  name: "DsfNcCoursePoylvPlayer",
  ctrlCaption: "课程播放",
  mixins: [$mixins.control],
  props: {
    moreUrl: {
      type: String,
      default: "/",
    },
    teacherDetailUrl: {
      type: String,
      default: "",
    },
    itemMargin: {
      type: String,
      default: "50px 0 58px 0",
    },
    tabList: {
      type: Array,
      default: () => {
        return [
          {
            name: "目录",
            id: 1223,
          },
          {
            name: "介绍",
            id: 1212,
          },
          {
            name: "笔记",
            id: 1211,
          },
        ];
      },
    },
  },
  computed: {
    ...mapGetters(["getCurPlayItem"]),
    getCurPlayTime() {
      let time = "00:00:00";
      if (this.$refs.coursePlayer) {
        time = this.$refs.coursePlayer.currentPlayTime();
      }
      return time;
    },
    videoSrc() {
      let src = "";
      const data = this.curPlayItem;
      const type = data.rTypeValue;
      const fileUrl = this.filterFileUrl(data);
      switch (type) {
        case "link":
          src = data.linkPcUrl;
          break;
        case "document":
          src = "preview/file?url=" + fileUrl;
          break;
        case "audio":
          src = fileUrl;
          break;
        case "video":
          src = fileUrl;
          break;
        default:
          break;
      }
      return dsf.url.getWebPath(src);
    },

    videoType() {
      const data = this.curPlayItem;
      const fileUrl = this.filterFileUrl(data);
      if (!fileUrl) return {};
      let arr = fileUrl.split(".");
      let extName = arr[arr.length - 1];
      let type = "";
      switch (extName) {
        case "mp3":
          type = "audio/mp3";
          break;
        case "m3u8":
          type = "video/m3u8";
          break;
        case "mp4":
          type = "video/mp4";
          break;
        default:
          type = "";
          break;
      }
      return type;
    },
    hasCollected() {
      let { isCollected } = this.initItem;
      return isCollected;
    },
    hasJoin() {
      let { isPlaned } = this.initItem;
      return isPlaned;
    },
  },
  components: {},
  data() {
    return {
      tabIdx: 0,
      playPattern: true, //默认播放模式 只有在讲义模式下播放器才允许拖拽
      docHeight: window.innerHeight + "px", //讲义模式高度
      winH: window.innerHeight,
      chapterInfo: [], //目录信息
      curItemType: null, //当前播放类型
      curPlayItem: null, //当前播放课件
      baseInfo: {},
      isFold: false,
      playTimer: null,
      queryiId: this.$route.query.id || "fa00c6889bd446e5a6364e891cf34930",
      queryiType: this.$route.query.ztb || "course",
      isView: this.$route.query.isView || 0,
      initItem: {},
      playConfig: {}, //播放器配置
      docUrl: null,
      count: 0,
      preUrl: dsf.config.setting_public_file_host,
      coordinate: null, //拖拽坐标
      rateNumber: 8,
    };
  },
  mounted() {
    if (!this.isDesign) {
      if (this.queryiId) {
        this.loadBaseInfo(); //获取基础信息老师信息\简介
        this.loadCourses();
      } else {
        dsf.layer.pc.message("当前课程id为空", false);
      }
    }
  },
  methods: {
    reloadData() {
      if (this.queryiId) {
        this.loadBaseInfo(); //获取基础信息老师信息\简介
        this.loadCourses();
      }
    },
    loadBaseInfo() {
      const sourceUrl = "nc/course/play/getPlayBase";
      let params = {
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, res => {
        this.baseInfo = res.data;
      });
    },
    loadCurrentItem(it) {
      const sourceUrl = "nc/course/play/getPlayRecord";
      let params = {
        coursewareId: it.id,
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, ({ data }) => {
        const seektime = this.formatSeekTime(data.lastWatchPoint);
        this.docUrl = null;
        this.playPattern = true;
        this.curItemType = data.rTypeValue;
        this.curPlayItem = data;
        this.$set(this.chapterInfo, "curPlayId", data.id);
        this.setCurrentProgress(data.finishedRate);
        this.setCurrentSeektime(seektime);
        this.setCurrentPlayItem(data);
        if (data.rTypeValue == "link") return dsf.layer.openWindow(data.linkPcUrl);
        if (data.rTypeValue == "document") {
          if (data.fileUrl) {
            const preUrl = this.preUrl;
            let docUrl = preUrl + `/preview/file?url=${preUrl}${JSON.parse(data.fileUrl).file[0].relativePath}`;
            this.docUrl = docUrl;
            this.playPattern = false;
            this.setSaveProgress();
          } else {
            return dsf.layer.pc.message("文稿地址为空，请联系管理员上传相关文稿", false);
          }
        }
      });
    },
    loadCourses() {
      const sourceUrl = "nc/course/play/getPlayTrend";
      let params = {
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, res => {
        if (!dsf.isEmptyObject(res.data)) {
          const data = res.data;
          const curData = data.locationSite;
          const playTree = data.playTree;
          if (curData && playTree) {
            this.initItem = data;
            // playTree.curPlayId = curData.id;
            this.chapterInfo = playTree;
            this.loadCurrentItem(curData); //查询当前课件进度信息
          }
        }
        // this.seektime = seektime;
      });
    },
    // 讲义模式切换
    changePattern(state) {
      if (this.playPattern == state) return;
      const type = this.curItemType;
      if (type === "link" || type === "document") {
        return dsf.layer.pc.message("当前课件暂无视频", false);
      } else {
        if (!state) {
          let { fileAdditionUrl } = this.curPlayItem;
          if (fileAdditionUrl) {
            const preUrl = this.preUrl;
            let docUrl = preUrl + `/preview/file?url=${preUrl}${JSON.parse(fileAdditionUrl)[0].relativePath}`;
            this.docUrl = docUrl;
            this.resetPlayerStyle(state);
            this.setSaveProgress();
            this.playPattern = false;
          } else {
            return dsf.layer.pc.message("当前课件暂无相关文档", false);
          }
        } else {
          this.docUrl = null;
          this.playPattern = true;
          this.resetPlayerStyle(state);
        }
      }
    },
    getCurTabSelMenu(idx) {
      return this.tabIdx == idx ? "tab-menus-name-sel" : "";
    },
    tabMenuChange(idx) {
      this.tabIdx = idx;
    },
    setImage(item) {
      const data = item;
      let src = "";
      if (dsf.isDef(data) && data[0]) {
        src = data[0].photo ? dsf.url.getWebPath(JSON.parse(data[0].photo)[0].relativePath) : "";
      }
      return src;
    },
    // 目录切换
    changeCurPlayCourse(item) {
      const sourceUrl = "nc/course/play/getPlayRecord";
      let params = {
        coursewareId: item.id,
        courseId: this.queryiId,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, res => {
        this.loadCurrentItem(item);
        this.resetPlayerStyle(true);
      });
    },
    playerPlaySaveProgress(query) {
      const { courseId, coursewareId, watchPoint, pulseTime, pulseRate } = query;
      const sourceUrl = "nc/course/play/pulseSaveRecord";
      let params = {
        courseId,
        coursewareId,
        watchPoint,
        pulseTime,
        pulseRate,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadPost(
        sourceUrl,
        params,
        res => {
          res.success && this.setCurrentPlayProgress(res.data.finishedRate);
          !res.success && dsf.layer.pc.message("保存学习进度失败,请重新登录或联系管理员", false);
        },
        err => {
          dsf.layer.pc.message(err.message, false);
        }
      );
    },
    //当前视频播放时间
    playerPlayingTime(e) {
      //秒转换时间格式
      this.formatSeconds(Math.ceil(e));
    },
    playNextCourse() {
      let { chapterInfo, curPlayItem } = this;
      let list = chapterInfo.children || [];
      let idx = -1;
      _.each(list, (item, index) => {
        if (item.id == curPlayItem.id) {
          console.log(index);
          idx = index;
        }
      });
      if (idx !== -1) {
        ++idx;
        if (idx == list.length) idx = 0;
        console.log(list[idx]);
        this.loadCurrentItem(list[idx]);
      }
    },
    nextCourse() {
      this.playNextCourse();
    },
    //当前视频播放结束
    playEnd() {
      const isAuto = localStorage.getItem("isAuto") || 0;
      if (isAuto == 1) {
        this.playNextCourse();
      }
    },
    //视频播放
    playerHasPlay(idx) {
      this.clearPlayInterval();
      this.setSaveProgress();
    },
    setSaveProgress() {
      let that = this;
      /**
       * 设置保存进度参数
       *
       * @param courseId 课程id
       * @param coursewareId 课件id
       * @param pulseTime 保存间隔时间
       * @param pulseRate 当前播放倍数
       * @param watchPoint 当前观看点节点 00:00:00
       *
       */
      this.playTimer = setInterval(() => {
        let params = {
          courseId: this.queryiId,
          coursewareId: this.curPlayItem.id,
          watchPoint: this.getCurPlayTime,
          pulseRate: 1,
          pulseTime: 10,
        };
        if (this.isView == 1) params = Object.assign(params, { isView: 1 });
        that.playerPlaySaveProgress(params);
      }, 1000 * 2);
    },
    //视频暂停
    playerHasPause() {
      let params = {
        courseId: this.queryiId,
        coursewareId: this.curPlayItem.id,
        watchPoint: this.getCurPlayTime,
        pulseRate: 1,
        pulseTime: 10,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      this.clearPlayInterval();
      this.playerPlaySaveProgress(params);
    },
    handleCollect() {
      const sourceUrl = "dsfa/collect/saveOrCancel";
      let params = {
        ywlxValue: "course",
        ywlxText: "课程",
        ywid: this.queryiId,
      };
      commoeLoadPost(
        sourceUrl,
        params,
        ({ message, data }) => {
          dsf.layer.pc.message(message, true);
          this.initItem.isCollected = data.isDo;
        },
        err => {
          dsf.layer.pc.message(err.message, false);
        }
      );
    },
    handleComment() {
      dsf.layer.openDialog({
        content: commentDialog,
        title: "评价",
        width: "400px",
        height: "200px",
        params: {
          id: this.queryiId,
          type: this.queryiType,
          isComment: this.initItem.isAssessed,
        },
      });
    },
    toggle() {
      this.isFold = !this.isFold;
      this.$refs.rightBox.style.width = !this.isFold ? "480px" : 0;
      window.playerResize?.();
    },
    //加入学习计划
    joinStudy() {
      const sourceUrl = "nc/learn/plan/saveOrCancel";
      const params = {
        id: this.queryiId,
      };
      commoeLoadPost(sourceUrl, params, ({ message, data }) => {
        dsf.layer.pc.message(message, true);
        this.initItem.isPlaned = data.isDo;
      });
    },
    //清楚定时器
    clearPlayInterval() {
      if (this.playTimer) clearInterval(this.playTimer);
    },
    //秒转换时间格式并保存到store
    formatSeconds(value) {
      let data = dsf.date.secondsToTime(value);
      let time = data.hours + ":" + data.minutes + ":" + data.seconds;
      this.setCurrentPlayTime(time);
    },
    formatSeekTime(t) {
      if (!t) t = "00:00:00";
      t = t.split(":");
      var s = t[0] * 60 * 60 + t[1] * 60 + t[2] * 1;
      return s;
    },
    filterFileUrl(item) {
      let sourceUrl = "";
      if (item.rTypeValue == "document") {
        if (item.fileAdditionUrl) {
          const preUrl = this.preUrl;
          sourceUrl = preUrl + `/preview/file?url=${preUrl}${JSON.parse(item.fileAdditionUrl)[0].relativePath}`;
          this.setSaveProgress(); //预览文件保存进度
        }
      } else {
        if (item && item.fileUrl) {
          const sourceItem = JSON.parse(item.fileUrl);
          if (sourceItem.file) {
            sourceUrl = sourceItem.file[0].relativePath;
          } else {
            if (dsf.isArray(sourceItem)) {
              sourceUrl = sourceItem[0].relativePath;
            } else {
              sourceUrl = sourceItem.sd.relative || sourceItem.sd.relativePath;
            }
          }
        }
      }
      return sourceUrl;
    },
    toTeacherDetail(item) {
      let sourceUrl = this.teacherDetailUrl;
      if (sourceUrl) {
        dsf.layer.openWindow(sourceUrl + item.id);
      }
    },
    resetPlayerStyle(state) {
      //讲义模式下 播放器Fixed
      const h = this.videoType == "audio/mp3" ? "60px" : "248px";
      let playerStyle = this.$refs.dragItem.style;
      playerStyle.zIndex = "23";
      playerStyle.position = !state ? "fixed" : "static";
      playerStyle.right = !state ? "20px" : 0;
      playerStyle.top = !state ? "20px" : 0;
      playerStyle.width = !state ? "440px" : "100%";
      playerStyle.height = !state ? h : "100%";
      playerStyle.cursor = !state ? "move" : "none";
      window.playerResize?.();
    },
    playerMousedown(name, e) {
      if (!this.playPattern) {
        const { clientX, clientY } = e;
        const left = this.$refs[name].offsetLeft;
        const top = this.$refs[name].offsetTop;
        this.coordinate = {
          clientX,
          clientY,
          left,
          top,
        };
      }
    },
    playerMousemove(name, e) {
      if (this.coordinate && !this.playPattern) {
        let { clientX, clientY } = e;
        let winW = window.innerWidth;
        let winH = window.innerHeight;
        let x = this.coordinate.left + (clientX - this.coordinate.clientX);
        let y = this.coordinate.top + (clientY - this.coordinate.clientY);
        x = x <= 0 ? 0 : x >= winW - this.$refs[name].offsetWidth ? winW - this.$refs[name].offsetWidth : x;
        y = y <= 0 ? 0 : y >= winH - this.$refs[name].offsetHeight ? winH - this.$refs[name].offsetHeight : y;
        this.$refs[name].style.left = x + "px";
        this.$refs[name].style.top = y + "px";
      }
    },
    playerMouseup() {
      !this.playPattern && (this.coordinate = null);
    },
    ...mapMutations({
      setCurrentPlayItem: "SET_CURRENT_PLAY_ITEM",
      setCurrentPlayTime: "SET_CURRENT_PLAY_TIME",
      setCurrentProgress: "SET_CURRENT_PLAY_PROGRESS",
      setCurrentSeektime: "SET_CURRENT_PLAY_SEEKTIME",
      setCurrentPlayProgress: "SET_CURRENT_PLAY_PROGRESS",
    }),
  },
  beforeDestroy() {
    this.clearPlayInterval();
  },
});

function commoeLoadGet(sourceUrl, params = {}, succFn, failFun, method = "get") {
  // const loading = dsf.layer.pc.loading();
  dsf.http
    .get(sourceUrl, params, $$webRoot.nc)
    .done(res => {
      res.success && succFn && succFn(res);
      if (!res.success) error(res);
    })
    .error(err => {
      error(err);
    })
    .always(res => {
      // dsf.layer.pc.closeLoading(loading);
    });
  function error(err) {
    failFun && failFun(err);
    dsf.layer.pc.message(err.message, false);
  }
}

function commoeLoadPost(sourceUrl, params, succFn, failFun, method) {
  // const loading = dsf.layer.pc.loading();
  dsf.http
    .post(sourceUrl, params, $$webRoot.nc)
    .done(res => {
      res.success && succFn && succFn(res);
      if (!res.success) error(res);
    })
    .error(err => {
      error(err);
    })
    .always(res => {
      // dsf.layer.pc.closeLoading(loading);
    });
  function error(err) {
    failFun && failFun(err);
    dsf.layer.pc.message(err.message, false);
  }
}
</script>
