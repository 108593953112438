var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_learningStatistics ds-control",
      style: { margin: _vm.itemMargin, width: _vm.itemWidth },
    },
    [
      _c(
        "div",
        _vm._l(_vm.itemList, function (item) {
          return _c("div", { key: item, staticClass: "item" }, [
            _c(
              "div",
              {
                staticClass: "item-inner",
                on: {
                  click: function ($event) {
                    return _vm.toDetail(item)
                  },
                },
              },
              [
                _c("i", { class: ["iconfont", item.icon] }),
                _c("p", { staticClass: "count" }, [
                  _vm._v(_vm._s(item.finishedNum || 0)),
                ]),
                _c("p", { staticClass: "des" }, [_vm._v(_vm._s(item.name))]),
              ]
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }