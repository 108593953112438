<template>
  <div class="dsf_nc_footer_two" v-if="isDesign" :style="{ width: itemWidth, margin: margin }">
    <div class="nc-footer-inner">
      <div class="footer-top">
        <div class="footer-top-left">
          <dl>
            <dd class="address">地址：上海市大木桥路108号6楼</dd>
            <dd class="email">邮箱：qyfwy@shaitc.com</dd>
            <dd class="copyNumber">版权所有 © 上海市中小型企业发展服务中心</dd>
            <dd class="policeNumber">网站备案号：沪ICP020577号</dd>
          </dl>
        </div>
        <div class="footer-top-center">
          <dl>
            <dd class="phone_text">服务云咨询热线</dd>
            <dd class="phone">400-820-3302</dd>
          </dl>
        </div>
        <div class="footer-top-right">
          <div class="item qrcode-one">
            <DsfImage v-if="data.qrcodeOneImg" :src="JSON.parse(data.qrcodeOneImg || '{}').relativePath" />
            <dsf-icon name="ico" v-else class="qrcode-icon"></dsf-icon>
            <p>APP下载</p>
          </div>
          <div class="item qrcode-one">
            <DsfImage v-if="data.qrcodeTwoImg" :src="JSON.parse(data.qrcodeTwoImg || '{}').relativePath" />
            <dsf-icon name="ico" v-else class="qrcode-icon"></dsf-icon>
            <p>微信公众号</p>
          </div>
          <div class="item qrcode-one">
            <DsfImage v-if="data.qrcodeThreeImg" :src="JSON.parse(data.qrcodeThreeImg || '{}').relativePath" />
            <dsf-icon name="ico" v-else class="qrcode-icon"></dsf-icon>
            <p>微博</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dsf_nc_footer_two" v-else :style="{ width: itemWidth, margin: margin }">
    <div class="nc-footer-inner">
      <div class="footer-top">
        <div class="footer-top-left">
          <dl>
            <dd class="address" v-if="data.addressTwo">地址：{{ data.addressTwo }}</dd>
            <dd class="email" v-if="data.email">邮箱：{{ data.email }}</dd>
            <dd class="copyNumber" v-if="data.copyright">版权所有 © {{ data.copyright }}</dd>
            <dd class="policeNumber" v-if="data.websiteInternet">网站备案号：{{ data.websiteInternet }}</dd>
          </dl>
        </div>
        <div class="footer-top-center">
          <dl v-if="data.hotLine">
            <dd class="phone_text">{{ phoneText }}</dd>
            <dd class="phone">{{ data.hotLine }}</dd>
          </dl>
        </div>
        <div class="footer-top-right">
          <div class="item qrcode-one">
            <DsfImage v-if="data.qrcodeOneImg" :src="JSON.parse(data.qrcodeOneImg || '{}').relativePath" />
            <dsf-icon name="ico" v-else class="qrcode-icon"></dsf-icon>
            <p>{{ data.qrcodeOneName }}</p>
          </div>
          <div class="item qrcode-one">
            <DsfImage v-if="data.qrcodeTwoImg" :src="JSON.parse(data.qrcodeTwoImg || '{}').relativePath" />
            <dsf-icon name="ico" v-else class="qrcode-icon"></dsf-icon>
            <p>{{ data.qrcodeTwoName }}</p>
          </div>
          <div class="item qrcode-one">
            <DsfImage v-if="data.qrcodeThreeImg" :src="JSON.parse(data.qrcodeThreeImg || '{}').relativePath" />
            <dsf-icon name="ico" v-else class="qrcode-icon"></dsf-icon>
            <p>{{ data.qrcodeThreeName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcFooterTwo",
  ctrlCaption: "门户页脚2",
  mixins: [$mixins.control],
  props: {
    itemWidth: {
      type: String,
      default: "100%",
    },
    margin: {
      type: String,
      default: "20px 0 0 0",
    },
    sourceUrl: {
      type: String,
      default: "nc/saas/count/home",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    copyNumber: {
      type: String,
      default: "上海市中肖型企业发展服务中心",
    },
    copyNumberSourceUrl: {
      type: String,
      default: "",
    },
    policeNumber: {
      type: String,
      default: "网站备案号：沪ICU020577号",
    },
    policeNumberSourceUrl: {
      type: String,
      default: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006053",
    },
    phoneText: {
      type: String,
      default: "服务云咨询热线",
    },
    phone: {
      type: String,
      default: "400-820-3302",
    },
    address: {
      type: String,
      default: "地址：上海市大木桥路108号6楼",
    },
    email: {
      type: String,
      default: "邮箱: 13523829131m@sina.cn",
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    !this.isDesign && this.initFooter();
  },
  methods: {
    initFooter() {
      const { sourceUrl } = this;
      dsf.http
        .get(sourceUrl, { unitId: this.queryString.unitId }, $$webRoot.nc)
        .done(({ message, success, data }) => {
          if (!success) {
            error(message);
          } else {
            this.faceInfo = [
              {
                name: "资源总量",
                total: data.resourceNum,
              },
              {
                name: "今日点击访问量",
                total: data.todayView,
              },
              {
                name: "历史点击访问总量",
                total: data.totalView,
              },
            ];
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {});
      function error(message) {
        dsf.layer.pc.message(message, false);
      }
    },
  },
});
</script>
