<template>
  <div class="dsf_nc_newsDetail" :style="{ width: width }">
    <div class="news_wrap">
      <h5 class="news_title">{{ news[keyField.title] || "" }}</h5>
      <h3 class="subtitle">{{ news[keyField.subhead] || "" }}</h3>
      <p class="brief" v-if="news[keyField.brief]">摘要：{{ news[keyField.brief] || "" }}</p>
      <div class="news_info">
        <span>{{ news[keyField.time] || "" }}</span>
        <span>
          <dsf-icon name="yanjing1"></dsf-icon>
          {{ news[keyField.readingNumber] || "" }}
        </span>
      </div>
      <!-- 多图 -->
      <div v-if="news[keyField.type] == '1' && news.articlesImagesVos.length" class="moreImg">
        <div v-for="(item, index) in news.articlesImagesVos" :key="index">
          <dsf-nc-image :src="item.imagePath | imgFormat" :error-src="defaultSrc" />
          <div class="news_content" v-html="item.content"></div>
        </div>
      </div>
      <!-- 视频 -->
      <div v-if="news[keyField.type] == '2' && news[keyField.video]" class="news-video">
        <dsfNcPlayer :videoSource="getVideoPlay(news[keyField.video])"></dsfNcPlayer>
      </div>
      <!-- 外链 -->
      <div v-if="news[keyField.type] == '3'" class="news-link">
        <a :href="news[keyField.externalLink]" target="_blank">
          <span>{{ news[keyField.source] || "点击跳转" }}</span>
        </a>
      </div>
      <!-- externalLink -->
      <div class="news_content" v-html="news[keyField.content]" v-if="news[keyField.content]"></div>
      <!-- <div v-else class="noDate">暂无数据</div> -->

      <!--  分享-->
      <!-- <div class="btns">
        <div class="btn-box">
          <dsf-icon :name="news[keyField.isLike] == '1' ? 'dianzanhong' : 'dianzanbeifen'" @click="likeClick"></dsf-icon>
          <span>{{ news[keyField.likeNum] || "0" }}</span>
        </div>
        <div class="btn-box">
          <dsf-icon :name="news[keyField.isCollect] == '1' ? 'icon_love_active' : 'icon_love_normal'" @click="collectClick"></dsf-icon>
          <span>{{ news[keyField.collectNum] || "0" }}</span>
        </div>
        <div class="btn-box">
          <el-popover placement="right-start" title="二维码" width="200" trigger="click">
            <div style="text-align: center"><img :src="qrCodeUrl" alt="" style="width: 160px; height: 160px" /></div>
            <dsf-icon slot="reference" name="fenxiangfangshi1" @click="showCode"></dsf-icon>
          </el-popover>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcNewsDetail",
  ctrlCaption: "新闻详情",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    reqUrl: {
      type: String,
      default: "cms/articles/queryArticlesById",
    },
    likeUrl: {
      type: String,
      default: "",
    },
    collectUrl: {
      type: String,
      default: "",
    },
    codeUrl: {
      type: String,
      default: "",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "title", to: "title", type: "text", desc: "新闻标题" },
          { from: "subtitle", to: "subhead", type: "text", desc: "副标题" },
          { from: "brief", to: "brief", type: "text", desc: "摘要" },
          // { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "publishTime", to: "time", type: "text", desc: "时间" },
          { from: "pageView", to: "readingNumber", type: "text", desc: "查看人数" },
          { from: "content", to: "content", type: "text", desc: "内容" },
          { from: "type", to: "type", type: "text", desc: "新闻类型" },
          { from: "video", to: "video", type: "text", desc: "视频" },
          { from: "source", to: "source", type: "text", desc: "来源" },
          { from: "externalLink", to: "externalLink", type: "text", desc: "外链" },
        ];
      },
    },
  },
  computed: {},
  data() {
    return {
      id: "",
      news: {},
      keyField: {},
      qrCodeUrl: "",
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  mounted() {
    this.id = this.queryString["id"] || "";
    this.keysMap.map(item => {
      this.keyField[item.to] = item.from;
    });
    if (!this.isDesign) {
      this.initData();
    } else {
      this.news = {
        title: "杭州余杭区试点数字防疫 仓前街道用钉钉“防疫通”建起在线精准防控",
        subtitle: "建起在线精准防控",
        brief: "摘要",
        content: "<p>正在进行中：“数字化”“数字中国”被列为“十四五”规划的核心之一，数字经济化，还是数智化，成为疫情之后几乎每家企业…</p>",
        pageView: 15,
        publishTime: "2019-08-27",
        isLike: "0",
        likeNum: 2,
        isCollect: "1",
        collectNum: 61,
        type: 0,
        cover: null,
        externalLink: "",
        source: "",
      };
    }
  },
  methods: {
    getVideoPlay(item) {
      try {
        return dsf.url.getWebPath(JSON.parse(item)[0].relativePath);
      } catch (err) {
        return "";
      }
    },
    initData() {
      if (!this.reqUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.reqUrl, { id: this.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.news = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    //点赞
    likeClick() {
      if (!this.likeUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.likeUrl, { ywlxValue: "xw", ywid: this.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.news.isLike = data.isDo ? "1" : "0";
            if (data.isDo) {
              this.news.likeNum += 1;
            } else {
              this.news.likeNum -= 1;
              if (this.news.likeNum <= 0) {
                this.news.likeNum = 0;
              }
            }
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    //收藏
    collectClick() {
      if (!this.collectUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.collectUrl, { ywlxValue: "xw", ywid: this.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.news.isCollect = data.isDo ? "1" : "0";
            if (data.isDo) {
              this.news.collectNum += 1;
            } else {
              this.news.collectNum -= 1;
              if (this.news.collectNum <= 0) {
                this.news.collectNum = 0;
              }
            }
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    showCode() {
      if (this.$listeners["showCode"]) {
        this.$dispatch("showCode", this.id);
      } else {
        let qrPath = dsf.url.getWebPath(this.codeUrl + "?id=" + this.id);
        // qrPath = encodeURIComponent(qrPath);
        // let qrCodeUrl = dsf.url.getWebPath("nc/ncqrcode/buildQrCode") + "?rexdirect=" + qrPath;
        this.qrCodeUrl = qrPath;
      }
    },

    goDetail(item) {
      if (this.$listeners["click"]) {
        this.$dispatch("click", item);
      } else {
        if (this.detailUrl) {
          let url = this.$replace(this.detailUrl, item);
          url && dsf.layer.openWindow(url);
        }
      }
    },
  },
});
</script>
