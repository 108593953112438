<template>
  <div v-if="isDesign" class="ds_teacher_detail">
    <div class="ds_teacher_detail_inner">
      <div class="cover">
        <el-image :src="1 | imgFormat"></el-image>
      </div>
      <div class="desc">
        <h3 class="desc-name">
          <span class="name">名称</span>
        </h3>
        <div class="dept">
          <span>阿里巴巴</span>
        </div>
        <div class="info">
          <span>
            <b>35</b>
            门课程
          </span>
          <span>
            <b>25</b>
            人订阅
          </span>
        </div>
        <div class="tags">
          <span class="tag">标签</span>
        </div>
      </div>
      <button class="subcrible">立即订阅</button>
    </div>
  </div>
  <div v-else class="ds_teacher_detail">
    <div class="ds_teacher_detail_inner" v-if="it">
      <div class="detail_inner">
        <div class="cover">
          <!-- <el-image :src="it.photo | imgFormat"></el-image> -->
          <DsfNcDefaultHeader
            class="default-avatar"
            :src="it.photo"
            :width="'180px'"
            :height="'240px'"
            :round="false"
            :defaultHeaderUrl="imgErrorSrc"
          />
        </div>
        <div class="desc">
          <div class="desc_top">
            <h3 class="desc-name">
              <span class="name">{{ it.name }}</span>
              <span class="position">{{ it.position }}</span>
            </h3>
            <div class="dept ell">
              <!-- <span style="margin-right: 20px">{{ it.deptText }}</span> -->
              <span>{{ it.deptText }}</span>
            </div>
            <div class="abstracr ell4">
              <!-- {{ it.abstracts }} -->
              <span
                class="font"
                v-html="it.abstracts && it.abstracts.length > 220 ? it.abstracts.slice(0, 220) + '  ...' : it.abstracts || '暂无数据'"
              ></span>
              <span v-if="it.abstracts && it.abstracts.length > 220" class="more" @click="dialogTeacherVisible = true">查看更多</span>
            </div>
          </div>
          <el-dialog title="师资介绍" class="teacher_dialog" :visible.sync="dialogTeacherVisible" width="30%" append-to-body>
            <span class="dialog_font" v-html="it.abstracts"></span>
          </el-dialog>
          <div class="info">
            <span>
              <b>{{ it.courseCount }}</b>
              门课程
            </span>
            <span>
              <b>{{ it.subscription }}</b>
              人订阅
            </span>
          </div>
          <div class="tags" v-if="it.adeptText">
            <span class="tag" v-for="item in it['adeptText'].split('^')" :key="item">{{ item }}</span>
          </div>
        </div>
        <button class="subcrible" @click="toSubscrible(it)">{{ it["mySubscribed"] ? "取消订阅" : "立即订阅" }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcTeacherCardDetail",
  ctrlCaption: "师资详情顶部",
  mixins: [$mixins.control],
  props: {
    bgImage: {
      type: String,
      default: "../../../static/img/product/teacher-detail-bg.png",
    },
  },
  data() {
    return {
      it: null,
      queryiId: this.$route.query.id,
      dialogTeacherVisible: false,
      imgErrorSrc: dsf.config.setting_public_user_default_header || dsf.config.setting_public_card_default_img,
    };
  },
  mounted() {
    if (!this.isDesign) this.loadTeacherDetail();
  },
  methods: {
    loadTeacherDetail() {
      const sourceUrl = "nc/teacher/info";
      const params = {
        id: this.queryiId,
      };
      const loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            console.log("message=====>", data);
            this.it = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(message, false);
      }
    },
    toSubscrible(it) {
      let that = this;
      const sourceUrl = "/dsfa/subscribe/saveOrCancel";
      const params = {
        ywlxValue: "teacher",
        ywlxText: "师资",
        ywid: it.teacherId,
      };
      const loading = dsf.layer.pc.loading();
      dsf.http
        .post(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.it.mySubscribed = data.isDo;
            dsf.layer.pc.message(message);
            let timer = setTimeout(() => {
              that.loadTeacherDetail();
              clearTimeout(timer);
            }, 1000);
          } else {
            error();
          }
        })
        .error(err => {
          error();
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error() {
        dsf.layer.pc.message("订阅失败", false);
      }
    },
  },
});
</script>
