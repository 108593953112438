<template>
  <div class="dsf_nc_saas saas_setting_footer_model3">
    <div class="subtitle margint20 marginb20">效果预览</div>
    <footerThree itemWidth="100%" margin="9px 0 50px 0" :data="data" />
    <div class="subtitle margint20 marginb20">底部链接</div>
    <el-table :data="data.footUrls" style="width: 100%" :row-style="{ height: '60px' }" border>
      <el-table-column prop="name" label="名称" width="200" align="center">
        <template slot-scope="scope">
          <el-input type="text" v-if="scope.row.active" v-model="scope.row.name"></el-input>
          <span v-else>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="链接" align="center">
        <template slot-scope="scope">
          <el-input type="text" v-if="scope.row.active" v-model="scope.row.url"></el-input>
          <span v-else>{{ scope.row.url }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120" align="center">
        <template slot-scope="scope">
          <div class="tablebtns">
            <dsf-icon name="tianxie" @click="toEdit(scope)" />
            <dsf-icon name="delete" @click="toDetle(scope)" />
            <dsf-icon name="shang" @click="toUp(scope)" />
            <dsf-icon name="xia" @click="toDown(scope)" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="add_nav" @click="addNav">
      <dsf-icon name="jia"></dsf-icon>
      新增导航栏链接
    </div>
    <div class="setting">
      <div>
        <div class="subtitle margint20 marginb20">底部信息配置</div>
        <div class="line">
          <label>备案号</label>
          <div>
            <el-input type="text" v-model="data.internetNumTwo" placeholder="请输入备案号"></el-input>
          </div>
        </div>
        <div class="line">
          <label>公安备案图标</label>
          <div class="upload">
            <el-upload accept="image/jpg,image/jpeg,image/png" class="avatar-uploader" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess">
              <DsfImage v-if="data.policeInternetImgTwo" :src="JSON.parse(data.policeInternetImgTwo || '{}').relativePath" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-upload accept="image/jpg,image/jpeg,image/png" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess">
              <dsf-button size="small">上传</dsf-button>
            </el-upload>
            <dsf-button size="small" type="plain" @click="clearIcon">删除</dsf-button>
            <dsf-nc-crop-image
              :show-tips-text="true"
              :resize="false"
              :edit-img="editImg"
              :edit-file-img="editFileImg"
              :aspect-ratio="aspectRatio"
              :request-url="setRequestUrl"
              @saveFile="editFile"
              @handleClose="handleClose"
            ></dsf-nc-crop-image>
          </div>
        </div>
        <div class="line">
          <label>公安备案号</label>
          <div>
            <el-input type="text" v-model="data.policeInternetNumTwo" placeholder="请输入公安备案号"></el-input>
          </div>
        </div>
        <div class="line">
          <label>等级备案号</label>
          <div>
            <el-input type="text" v-model="data.levelInternetNumTwo" placeholder="请输入等级备案号"></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <dsf-button type="plain" @click="backPage">取消</dsf-button>
      <dsf-button type="primary" @click="saveModel">保存</dsf-button>
    </div>
  </div>
</template>
<script>
import footerThree from "../../base/saas/footerThree.vue";
export default dsf.component({
  name: "DsfNcSaasSettingFooterModel3",
  ctrlCaption: "页尾模板设置3",
  mixins: [$mixins.control],
  props: {
    bindURL: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { footerThree },
  data() {
    return {
      editImg: false,
      editFileImg: "",
      aspectRatio: "20/20",
      menuList: [],
      radioValue: null,
    };
  },
  computed: {
    setRequestUrl() {
      return dsf.url.getWebPath("file/upload/");
    },
  },
  methods: {
    handleAvatarSuccess(res) {
      this.editFileImg = res.data[0];
      this.editImg = true;
    },
    editFile(files) {
      this.editImg = false;
      this.data.policeInternetImgTwo = JSON.stringify(files);
    },
    handleClose() {
      this.editImg = false;
    },
    clearIcon() {
      this.data.policeInternetImgTwo = "";
    },
    //向上移动
    toUp(scope) {
      var idx = scope.$index;
      if (idx == 0) return;
      const it = this.data.footUrls[idx];
      const temp = this.data.footUrls[idx - 1];
      this.$set(this.data.footUrls, idx, temp);
      this.$set(this.data.footUrls, idx - 1, it);
    },
    //向下移动
    toDown(scope) {
      var idx = scope.$index;
      if (idx == this.data.footUrls.length - 1) return;
      const it = this.data.footUrls[idx];
      const temp = this.data.footUrls[idx + 1];
      this.$set(this.data.footUrls, idx, temp);
      this.$set(this.data.footUrls, idx + 1, it);
    },
    //更新编辑状态
    toEdit(scope) {
      if (scope.row.active == true) {
        //saveMenu
      }
      var m = this.data.footUrls.map((nav, index) => {
        if (index == scope.$index) {
          nav.active = !nav.active;
        } else {
          nav.active = false;
        }
        return nav;
      });
      this.$set(this.data, "footUrls", m);
      // scope.row.active = true;
    },
    //删除单个菜单
    toDetle(scope) {
      var id = scope.row.menu_id;
      if (id) {
        const sourceUrl = "/nc/**";
        let params = {
          id: id,
          unitId: this.queryString.unitId,
        };
        const loading = dsf.layer.loading();
        dsf.http
          .get(sourceUrl, params, $$webRoot.nc)
          .done(({ success, data, message }) => {
            dsf.layer.pc.message("删除菜单:" + message, success);
          })
          .error(() => {
            dsf.layer.pc.message("删除菜单出错:" + err, false);
          })
          .always(res => {
            dsf.layer.closeLoading(loading);
          });
      } else {
        this.data.footUrls.splice(scope.$index, 1);
      }
    },
    addNav() {
      this.data.footUrls.forEach(item => {
        item.active = false;
      });
      this.data.footUrls.push({ active: true });
    },
    saveModel() {
      let footUrls = this.data.footUrls.map((item, index) => {
        item.sort = index;
        delete item.active;
        return item;
      });
      this.$emit("saveModel", { ...this.data, footUrls: JSON.stringify(footUrls) });
    },
    backPage() {
      this.$emit("back");
    },
  },
});
</script>
