<template>
  <div class="dsf_searchOne" v-if="visible" :style="{ padding: padding }">
    <el-input v-model="searchValue.txt" :placeholder="inputPlace" class="txt" size="small" v-if="showInput"></el-input>
    <el-date-picker
      v-if="showDate"
      v-model="searchValue.date"
      class="date"
      type="daterange"
      range-separator="--"
      size="small"
      value-format="yyyy-MM-dd HH:mm:ss"
      :default-time="['00:00:00', '23:59:59']"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    ></el-date-picker>
    <el-button round size="small" class="btn" @click="search">查询</el-button>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSearchOne",
  ctrlCaption: "搜索1",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    showInput: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    inputPlace: {
      type: String,
      default: "请输入笔记内容",
    },
    padding: {
      type: String,
      default: "20px 0",
    },
  },
  data() {
    return {
      searchValue: {
        txt: "",
        date: null,
      },
    };
  },
  components: {},
  methods: {
    //抛出查询时间
    search() {
      if (!this.searchValue.date) {
        this.searchValue.date = ["", ""];
      }
      this.$dispatch("handleSearch", this.searchValue);
    },
  },
});
</script>
