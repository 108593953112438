<template>
  <div class="dsf_nc_saas saas_setting_footer">
    <div class="title t1 margint20 marginb20">
      <span @click="settingModel = false">页尾设置</span>
      {{ settingModel ? "> 设置信息" : "" }}
    </div>
    <template v-if="!settingModel">
      <settingFooterTitle title="模板一" :index="'1'" :radioValue="radioValue" @change="change" @setBtn="setBtn"></settingFooterTitle>
      <footerOne itemWidth="100%" margin="9px 0 50px 0" :data="dataModel1" />
      <settingFooterTitle title="模板二" :index="'2'" :radioValue="radioValue" @change="change" @setBtn="setBtn"></settingFooterTitle>
      <footerTwo itemWidth="100%" margin="9px 0 50px 0" :data="dataModel2" />
      <settingFooterTitle title="模板三" :index="'3'" :radioValue="radioValue" @change="change" @setBtn="setBtn"></settingFooterTitle>
      <footerThree itemWidth="100%" margin="9px 0 0px 0" :data="dataModel3" />
    </template>
    <template v-else>
      <settingfootermodel1 v-if="settingModel == '1'" @back="settingModel = false" :data="dataModel1" @saveModel="saveModel"></settingfootermodel1>
      <settingfootermodel2 v-if="settingModel == '2'" @back="settingModel = false" :data="dataModel2" @saveModel="saveModel"></settingfootermodel2>
      <settingfootermodel3 v-if="settingModel == '3'" @back="settingModel = false" :data="dataModel3" @saveModel="saveModel"></settingfootermodel3>
    </template>
  </div>
</template>
<script>
import settingfootermodel1 from "./settingfootermodel1.vue";
import settingfootermodel2 from "./settingfootermodel2.vue";
import settingfootermodel3 from "./settingfootermodel3.vue";
import settingFooterTitle from "../../base/saas/settingFooterTitle.vue";
import footerOne from "../../base/saas/footerOne.vue";
import footerTwo from "../../base/saas/footerTwo.vue";
import footerThree from "../../base/saas/footerThree.vue";
export default dsf.component({
  name: "DsfNcSaasSettingFooter",
  ctrlCaption: "页尾设置",
  mixins: [$mixins.control],
  props: {
    bindURL: {
      type: String,
      default: "",
    },
  },
  components: { settingFooterTitle, footerOne, footerTwo, footerThree, settingfootermodel1, settingfootermodel2, settingfootermodel3 },
  data() {
    return {
      settingModel: false,
      saasGroupId: "",
      radioValue: "1",
      dataModel1: {},
      dataModel2: {},
      dataModel3: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    change(e) {
      this.radioValue = e;
      this.saveData(
        {
          footTemplate: this.radioValue,
        },
        "模板应用"
      );
    },
    setBtn(index) {
      this.settingModel = index;
      // this.$emit("changeFooterPage", "#/pc/nc/saas/setting/footermodel" + index);
    },
    getData() {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/getSaasGropu";
      let params = { unitId: this.queryString.unitId };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          this.saasGroupId = data.saasGroupId;
          this.radioValue = data.footTemplate || "1";
          this.dataModel1 = {
            companyPhone: data.companyPhone,
            addressOne: data.addressOne,
            dataSource: JSON.parse(data.dataSource || "[]"),
            internetNumOne: data.internetNumOne,
            copyNumberSourceUrl: "",
            policeInternetImgOne: data.policeInternetImgOne,
            policeInternetNumOne: data.policeInternetNumOne,
            policeNumberSourceUrl: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006053",
            levelInternetNumOne: data.levelInternetNumOne,
            levNumberSourceUrl: "http://icp.chinaz.com/home/info?host=xuexibang.cn",
          };
          this.dataModel2 = {
            addressTwo: data.addressTwo,
            email: data.email,
            copyright: data.copyright,
            websiteInternet: data.websiteInternet,
            hotLine: data.hotLine,
            qrcodeOneImg: data.qrcodeOneImg,
            qrcodeTwoImg: data.qrcodeTwoImg,
            qrcodeThreeImg: data.qrcodeThreeImg,
            qrcodeOneName: data.qrcodeOneName,
            qrcodeTwoName: data.qrcodeTwoName,
            qrcodeThreeName: data.qrcodeThreeName,
          };
          this.dataModel3 = {
            footUrls: JSON.parse(data.footUrls || "[]"),
            internetNumTwo: data.internetNumTwo,
            policeInternetImgTwo: data.policeInternetImgTwo,
            policeInternetNumTwo: data.policeInternetNumTwo,
            levelInternetNumTwo: data.levelInternetNumTwo,
          };
        })
        .error(() => {
          dsf.layer.pc.message("获取数据异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    saveModel(data) {
      this.saveData(data);
    },
    //统一保存接口
    saveData(params, mes = "保存", callback) {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/saveSaasGropu";
      const header = {
        "Content-Type": "application/json",
      };
      dsf.http
        .post(sourceUrl, { ...params, saasGroupId: this.saasGroupId, unitId: this.queryString.unitId }, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.pc.message(mes + "成功！", success);
            callback && callback();
          } else {
            dsf.layer.pc.message(mes + "失败：" + message, success);
          }
        })
        .error(() => {
          dsf.layer.pc.message("保存失败:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
  },
});
</script>
