var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsfNcLivePlayer",
      class: { isDesign: _vm.isDesign },
      style: { padding: _vm.playerPadding, marign: _vm.playerMargin },
    },
    [
      _vm.isDesign
        ? _c(
            "div",
            { staticClass: "dsfNcLivePlayer-isDesign" },
            [
              _c("dsf-icon", { attrs: { name: "icon_video" } }),
              _vm._v(" 当前暂无直播 "),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "playerWarp",
              class: { fullScreen: _vm.isFullScreen },
              style: {
                width: _vm.playerWarpWidth,
                height: _vm.playerWarpHeight,
              },
            },
            [
              _c("div", {
                class: {
                  isSwitch: _vm.isSwitch,
                  fullScreen: _vm.isFullScreen,
                  infont: _vm.infont,
                },
                style: {
                  height: _vm.liveVideoHeight,
                  width: _vm.liveVideoWidth,
                },
                attrs: { id: "liveVideo" },
              }),
              _vm.showPptVideo
                ? _c("div", {
                    class: {
                      isSwitch: _vm.isSwitch,
                      fullScreen: _vm.isFullScreen,
                      infont: !_vm.infont,
                    },
                    style: {
                      width: _vm.pptVideoWidth,
                      height: _vm.pptVideoHeight,
                    },
                    attrs: { id: "pptVideo" },
                  })
                : _vm._e(),
              _c("div", {
                style: { top: _vm.chatWarpTop, height: _vm.chatHeight },
                attrs: { id: "chatwrap" },
              }),
              _vm.showControl
                ? _c("div", {
                    staticClass: "ppt-control-use",
                    style: { width: _vm.controlWidth },
                  })
                : _vm._e(),
              _vm.onLine
                ? _c(
                    "span",
                    {
                      staticClass: "ppt-apply is-apply",
                      class: { "apply-cancel": _vm.onLineing },
                      style: { right: parseInt(this.charWidth) - 110 + "px" },
                      attrs: { id: "applyContact" },
                      on: { click: _vm.handleOnline },
                    },
                    [_vm._v(" " + _vm._s(_vm.lineText) + " ")]
                  )
                : _vm._e(),
              _c("div", {
                style: {
                  height: _vm.localHeight,
                  width: _vm.localWidth,
                  top: _vm.localTop,
                },
                attrs: { id: "local" },
              }),
              _c("div", {
                style: {
                  height: _vm.localHeight,
                  width: _vm.localWidth,
                  top: _vm.localTop,
                },
                attrs: { id: "local2" },
              }),
            ]
          ),
      _vm.showSigned
        ? _c("div", { staticClass: "player-signed-in" }, [
            _c("div", { staticClass: "signed-head" }, [_vm._v("签到")]),
            _c("div", { staticClass: "signed-content" }, [
              _c("div", { staticClass: "player-signed" }, [
                _c("div", [
                  _c("span", { staticClass: "signed-time" }, [
                    _vm._v(_vm._s(_vm.Signtime)),
                  ]),
                  _vm._v(" 秒 "),
                ]),
                _c("li", { staticClass: "sign-message" }, [
                  _vm._v(_vm._s(_vm.signMessage)),
                ]),
                _c(
                  "span",
                  { staticClass: "btn-signed", on: { click: _vm.sign } },
                  [_vm._v("签到")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.mutedPlay
        ? _c("div", { attrs: { id: "isvideo-box" } }, [
            _vm._v(" 是否允许浏览器播放声音? "),
            _c(
              "span",
              {
                staticClass: "ppt-control-volume-cancel isvideo",
                on: { click: _vm.handleMuted },
              },
              [_vm._v("播放声音")]
            ),
            _c(
              "span",
              {
                staticClass: "ppt-control-volume-close isvideoclose",
                on: {
                  click: function ($event) {
                    _vm.mutedPlay = false
                  },
                },
              },
              [_vm._v("忽略")]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }