<template>
  <div class="ds_instructions_news" v-if="!isDesign">
    <div class="content" v-html="news.content || ''"></div>
  </div>
  <div v-else>操作指南富文本内容</div>
</template>

<script>
export default dsf.component({
  name: "DsfNcInstructionsNews",
  ctrlCaption: "操作指南",
  mixins: [$mixins.control],
  props: {
    src: {
      type: String,
      default: "/cms/articles/queryArticlesById?id=4373974d78f84bfcb164a836c6a964b4",
    },
  },
  data() {
    return {
      news: {
        content: "",
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.src, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.news = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
