var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDesign
    ? _c("div", { staticClass: "dsf_nc_ckplayer" }, [
        _vm.videoSource
          ? _c(
              "div",
              {
                staticClass: "ckplayer-inner",
                style: { width: _vm.width },
                attrs: { id: "ckplayerInner" },
              },
              [
                !_vm.showVideoPlayer && _vm.videoSrc().type == "audio/mp3"
                  ? _c("div", { staticClass: "audio" }, [
                      _c(
                        "audio",
                        {
                          attrs: {
                            controls: "",
                            src:
                              _vm.videoSrc().type === "audio/mp3"
                                ? _vm.videoSrc().src
                                : "",
                            id: "playAudio",
                            controlsList: "nodownload",
                          },
                        },
                        [_vm._m(0)]
                      ),
                      _c("div", [
                        !_vm.allowDrag
                          ? _c("div", { staticClass: "hidetimeline" })
                          : _vm._e(),
                      ]),
                    ])
                  : _c("div", { staticStyle: { height: "100%" } }, [
                      _c("div", {
                        ref: "ckPlayer",
                        style: "height: 100%;",
                        attrs: { id: "dsfNcCkplayer" },
                      }),
                    ]),
              ]
            )
          : _vm._e(),
        _vm.videoSrc().type === "audio/mp3"
          ? _c(
              "div",
              {
                staticClass: "audio-cover-wrap",
                class: _vm.audioPlay ? "audio-play" : "audio-paused",
              },
              [
                _vm.itemCover
                  ? _c("dsf-nc-image", {
                      staticClass: "audio-cover",
                      attrs: { src: _vm.itemCover },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _c("div", { staticClass: "dsf_nc_ckplayer" }, [
        _c(
          "div",
          { staticClass: "ckplayer-inner", style: { width: _vm.width } },
          [
            _c("div", { staticStyle: { height: "100%" } }, [
              !_vm.itemCover
                ? _c("div", {
                    ref: "ckPlayer",
                    staticStyle: { height: "100%" },
                    attrs: { id: "dsfNcCkplayer" },
                  })
                : _vm._e(),
            ]),
          ]
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Your browser does not support the "),
      _c("code", [_vm._v("audio")]),
      _vm._v(" element. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }