import userInfo from "./userInfo.vue";
import userHeader from "./userHeader.vue";
import cardItemByNote from "./cardItemByNote.vue";
import cardItemByExam from "./cardItemByExam.vue";
import userAccount from "./userAccount.vue";
import userPortrayal from "./userPortrayal.vue";
import learningPortfolio from "./learningPortfolio.vue";
import userInfoPage from "./userInfoPage.vue";
import mytask from "./mytask";
import mytaskDetail from "./mytaskDetail";
export default [userInfo, userHeader, cardItemByNote, cardItemByExam, userAccount, userPortrayal, learningPortfolio, userInfoPage, mytask, mytaskDetail];
