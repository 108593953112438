<template>
  <div :style="controlStyle" class="ds-control">
    <div class="dsf-home-pending-process" :isDesign="isDesign" :style="panelStyle">
      <!-- 设计器 -->
      <template v-if="isDesign">
        <DsfVirtualList :isDesign="isDesign" :styleAttrs="{ hoverBright: true }" @count="getCount" :rowPadding="[0, 20, 0, 20]" :rowMargin="[0, 20, 0, 20]">
          <template v-slot:list-row="scope">
            <div v-for="index in 3" :key="index" class="dsf-home-pending-process-row" :read="false">
              <div class="dsf-uhppr-normal">
                <!-- {{scope.row.sign}} -->
                <!-- <template v-for="(sign, index) in scope.row.sign">
                <div :key="index" class="dsf-uhppr-tag">{{sign}}</div>
              </template> -->
                <div class="dsf-uhppr-name-box">
                  <div class="dsf-uhppr-name ellipsis" :title="scope.row && scope.row.title">[费用申请流程]家具购置申请</div>
                  <span class="dsf-uhppr-desc ellipsis" title="请尽快处理，后交张三疯审核">描述</span>
                </div>
                <div v-if="nodeNameShow" class="dsf-uhppr-node ellipsis" title="审批中">审批中</div>
                <div class="dsf-uhppr-ren ellipsis" title="管理员">管理员</div>
                <div class="dsf-uhppr-time">2020-10-01</div>
                <div class="dsf-uhppr-operating">
                  <template v-if="$slots['operating']">
                    <slot name="operating" v-bind="scope" />
                  </template>
                  <template v-else>
                    <div class="dsf-uhppr-operating-item" @click="e => operatingClick(e, { type: 'gz', ...scope })">
                      <DsfIcon name="aixin" style="color: #fc6a6a" />
                      <div class="dsf-uhppr-operating-item-text">关注</div>
                    </div>
                    <div class="dsf-uhppr-operating-item" @click="e => operatingClick(e, { type: 'hf', ...scope })">
                      <DsfIcon name="xiaoxi" style="color: #007ef7" />
                      <div class="dsf-uhppr-operating-item-text">回复</div>
                    </div>
                    <div class="dsf-uhppr-operating-item" @click="e => operatingClick(e, { type: 'zd', ...scope })">
                      <DsfIcon name="zhiding2" style="color: #efaf02" />
                      <div class="dsf-uhppr-operating-item-text">置顶</div>
                    </div>
                    <div v-if="scope.row.type == 0" class="dsf-uhppr-operating-item" @click="e => operatingClick(e, { type: 'th', ...scope })">
                      <DsfIcon name="chehuisekuai" style="color: #fd7911" />
                      <div class="dsf-uhppr-operating-item-text">退回</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </DsfVirtualList>
      </template>
      <template v-else>
        <DsfNcVirtucalList
          :url="url"
          :list="list"
          :is-design="isDesign"
          :style-attrs="{ hoverBright: true }"
          :row-padding="[0, 0, 0, 0]"
          :row-margin="[0, 20, 0, 20]"
          :url-query="urlQuery"
          @row-click="rowClick"
          :status="status"
          :isBeenRead="isBeenRead"
          @count="getCount"
          :tabActive="tabActive"
        >
          <template v-slot:list-row="scope">
            <slot name="row" v-bind="scope">
              <!-- :read="scope.row.status == 0" -->
              <div class="dsf-home-pending-process-row">
                <!-- v-if="status == 1 ? scope.row.status == 1 : true" -->
                <div class="dsf-uhppr-normal">
                  <!-- <template v-for="(sign, index) in scope.row.sign">
                  <div :key="index" class="dsf-uhppr-tag">{{ sign }}</div>
                </template> -->
                  <div class="dsf-uhppr-name-box">
                    <div class="dsf-uhppr-name ellipsis" :title="scope.row && scope.row.title">
                      <img v-if="scope.row.overDueState == 1" class="dsf-uhppr-ontop" :src="require('_platform/assets/styles/themes/img/home/db_danger.png')" />
                      <img v-if="scope.row.isUrge == 1" class="dsf-uhppr-ontop" :src="require('_platform/assets/styles/themes/img/home/db_cui.png')" />
                      <img v-if="scope.row.emergency > 0 && scope.row.emergency < 3" class="dsf-uhppr-ontop" :src="require('_platform/assets/styles/themes/img/home/db_ji.png')" />
                      <img v-if="scope.row.emergency > 2 && scope.row.emergency < 5" class="dsf-uhppr-ontop" :src="require('_platform/assets/styles/themes/img/home/db_te.png')" />
                      <img v-if="scope.row.isBack == 1" class="dsf-uhppr-ontop" :src="require('_platform/assets/styles/themes/img/home/db_tui.png')" />
                      <img v-if="scope.row.secret > 1" class="dsf-uhppr-ontop" :src="require('_platform/assets/styles/themes/img/home/db_mi.png')" />
                      <img v-if="scope.row.isTop == 1" class="dsf-uhppr-ontop" :src="require('_platform/assets/styles/themes/img/home/top.png')" />
                      <div class="message_status">
                        <i :class="['iconfont', scope.row.status == 1 || scope.row.status == 2 ? 'icon-24gf-envelopeOpen color2' : 'icon-envelope-full color1']"></i>
                        <span :class="['status_text']" v-if="scope.row.type == 0">{{ scope.row.status == 2 ? "[已审核]" : "[待审核]" }}</span>
                        <span :class="['status_text']" v-if="scope.row.type == 1">[{{ scope.row.moduleName }}]</span>
                      </div>

                      <template v-if="scope.row.signs.length">
                        <template v-for="(sign, index) in scope.row.signs">
                          <img v-if="sign.icon" :key="index" class="dsf-uhppr-ontop" :src="dsf.url.getWebPath(sign.icon)" />
                        </template>
                      </template>
                      <div class="title ellipsis">{{ scope.row && scope.row.title }}</div>
                    </div>
                    <!-- <span
                    class="dsf-uhppr-desc ellipsis"
                    title="请尽快处理，后交张三疯审核"
                    >
                    请尽快处理，后交张三疯审核
                  </span> -->
                  </div>
                  <div v-if="nodeNameShow && scope.row && scope.row.nodeName" class="dsf-uhppr-node ellipsis" :title="scope.row && scope.row.nodeName">
                    {{ scope.row && scope.row.nodeName }}
                  </div>
                  <div v-if="scope.row && scope.row.sendUserName" class="dsf-uhppr-ren ellipsis" :title="scope.row && scope.row.sendUserName">申请人: {{ scope.row && scope.row.sendUserName }}</div>
                  <div class="dsf-uhppr-time">
                    {{ scope.row && scope.row.sendDateTime | time }}
                  </div>
                  <div class="dsf-uhppr-operating">
                    <slot name="operating" v-bind="scope">
                      <div class="dsf-uhppr-operating-item" @click.stop="e => operatingClick(e, { type: 'gz', ...scope })">
                        <template v-if="scope.row.isConcern == 1">
                          <DsfIcon name="aixin_shixin" style="color: #fc6a6a" />
                          <div class="dsf-uhppr-operating-item-text">取消关注</div>
                        </template>
                        <template v-else>
                          <DsfIcon name="aixin" style="color: #fc6a6a" />
                          <div v-if="scope.row.isConcern == 0" class="dsf-uhppr-operating-item-text">关注</div>
                        </template>
                      </div>
                      <div class="dsf-uhppr-operating-item" @click.stop="e => operatingClick(e, { type: 'zd', ...scope })">
                        <template v-if="scope.row.isTop == 1">
                          <DsfIcon name="zhiding2" style="color: #efaf02; transform: scaleY(-1)" />
                          <div class="dsf-uhppr-operating-item-text">取消置顶</div>
                        </template>
                        <template v-else>
                          <DsfIcon name="zhiding2" style="color: #efaf02" />
                          <div class="dsf-uhppr-operating-item-text">置顶</div>
                        </template>
                      </div>
                      <div class="dsf-uhppr-operating-item" @click.stop="e => operatingClick(e, { type: 'cz', ...scope })">
                        <DsfIcon name="xiaoxi" style="color: #007ef7" />
                        <div class="dsf-uhppr-operating-item-text">操作</div>
                      </div>
                      <div v-if="scope.row.type == 0" class="dsf-uhppr-operating-item" @click.stop="e => operatingClick(e, { type: 'th', ...scope })">
                        <DsfIcon name="tuihuibeifen" style="color: #007ef7" />
                        <div class="dsf-uhppr-operating-item-text">退回</div>
                      </div>
                    </slot>
                  </div>
                </div>
              </div>
            </slot>
          </template>
        </DsfNcVirtucalList>
      </template>
    </div>
  </div>
</template>
<script>
import DsfNcVirtucalList from "../virtualList/virtualList.vue";
export default dsf.component({
  name: "DsfNcHomePendingProcess",
  ctrlCaption: "首页待办",
  mixins: [$mixins.control],
  components: { DsfNcVirtucalList },
  filters: {
    time(timeStr) {
      return dsf.date.format(timeStr, "mm-dd");
    },
  },
  props: {
    tabActive: {
      type: Object,
    },
    isBeenRead: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 0,
    },
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "100%",
    },
    // 边框
    padding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 列表数据
    list: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: () => "",
    },
    viewedUrl: {
      type: String,
      default: "/message/info/markRead",
    },
    toConfig: {
      type: Object,
      default: () => ({}),
    },
    pageSize: {
      type: [String, Number],
      default: 100,
    },
    nodeNameShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    urlQuery() {
      return {
        pageSize: this.pageSize,
      };
    },
    controlStyle() {
      let { width, padding, height } = this;
      return { width, padding: padding.join("px ") + "px", height: "100%" };
    },
    panelStyle() {
      let { height } = this;
      return { height };
    },
  },

  data() {
    return {};
  },

  watch: {},

  created() {
    if (this.isDesign) {
      this.$dispatch("design-height-change", "100%");
    }
    this.flowVm = new Vue($mixins.flowInfo);
    this.flowVm.closeOpener = () => {
      this.reloadData();
    };
  },
  methods: {
    beenRead() {},
    getCount(count) {
      console.log("246", count);
      this.$emit("count", count);
    },
    async rowClick({ args }) {
      let { toConfig, isDesign } = this;
      if (isDesign) return;
      let _this = this;
      let row = args.row || {};
      let title = "";
      let fetchArr = row.title.match(/^\[(.*?)\]/, "$1");
      if (fetchArr) {
        if (fetchArr.length >= 2) {
          title = fetchArr[1] || "";
        }
      }
      console.log(title);
      let url = row.url;
      const next = async () => {
        if (url) await this.redRow(row._id);
        let openConfig = {
          ...toConfig,
          title,
          url,
          state: row.type == 0 ? 3 : 1,
          closed: () => {
            if (_this.$homePanel) {
              _this.$homePanel.reloadData();
            } else {
              _this.reloadData();
            }
          },
        };
        if (this.$listeners["itemClick"]) return this.$dispatch("itemClick", { args, openConfig });
        this.$open(openConfig);
      };
      if (row.type == 0) {
        this.$http
          .get("/wfp/getPageUrl", {
            sID: row.objectId,
            sPID: row.pId,
            sCurLinkID: row.linkId,
            sWFID: row.wfId,
            sNodeID: row.nodeId,
          })
          .then(res => {
            if (res.data.success && res.data.data) {
              url = res.data.data || "";
              next();
            } else {
              dsf.layer.message(res.data.Message || "请求异常", false);
            }
          })
          .catch(res => {
            dsf.layer.message("请求异常", false);
          });
      } else {
        next();
      }
    },
    redRow(id) {
      return this.$http.post(this.viewedUrl, {
        id,
        type: "workrecord",
      });
    },
    operatingClick(e, args) {
      e.stopPropagation();
      if (this.$listeners["operatingClick"]) {
        this.$dispatch("operatingClick", args);
      } else {
        if (args.type == "zd") {
          this.ontop(args);
        } else if (args.type == "gz") {
          this.focus(args);
        } else if (args.type == "cz") {
          this.rowClick({ args });
        } else if (args.type == "th") {
          this.tuihui(args);
        }
      }
    },
    tuihui(args) {
      let row = args.row;
      this.flowVm.getSendBackLink([row.processId, row.linkId]);
    },
    ontop(args) {
      this.$http
        .post("/message/info/addOrCancelTop", {
          id: args.row._id,
        })
        .then(({ data }) => {
          if (data && data.success) {
            dsf.layer.message("操作成功", true);
            if (this.$homePanel) {
              this.$homePanel.reloadData();
            } else {
              this.reloadData();
            }
          } else {
            dsf.layer.message(data.message, false);
          }
        })
        .catch(() => {
          dsf.layer.message("操作失败", false);
        });
    },
    focus(args) {
      this.$http
        .post("/message/info/addOrCancelConcern", {
          id: args.row._id,
        })
        .then(({ data }) => {
          if (data && data.success) {
            dsf.layer.message("操作成功", true);
            if (this.$homePanel) {
              this.$homePanel.reloadData();
            } else {
              this.reloadData();
            }
          } else {
            dsf.layer.message(data.message, false);
          }
        })
        .catch(() => {
          dsf.layer.message("操作失败", false);
        });
    },
    reloadData() {
      _.each(this.$children, child => {
        child?.reloadData && child.reloadData();
      });
    },
  },
});
</script>
