<template>
  <div class="dsf_specialdetail_social_todybest" :style="{ width: itemWidth, display: visible ? 'block' : 'none', margin: itemSpace.replace(/,/g, ' ') }">
    <div class="social_title">
      <div class="desc">
        <span class="font">{{ title }}</span>
        <span class="more" v-if="showMore" @click="!!showMore && clickMore()"></span>
      </div>
      <span class="line"></span>
    </div>
    <div class="todybest_wrap">
      <!-- <dsf-image :src="''" class="head_img" width="80px" height="80px" /> -->
      <el-image :src="setImg('')" width="80px" height="80px" class="head_img" fit="cover">
        <div slot="error" class="image-slot">
          <img :src="dsf.config.setting_error_img" alt="" style="width: 80px; height: 80px" />
        </div>
      </el-image>
      <div class="name">张三</div>
      <div class="phone">13162123382</div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSpecialDetailSocialTodyBest",
  ctrlCaption: "专题班详情社区今日最佳",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "今日学霸",
    },
    url: {
      type: String,
      default: "",
    },
    moreUrl: {
      type: String,
      default: "/",
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickMore() {
      console.log("点击更多了---");
      this.$open({ url: this.moreUrl });
    },
  },
});
</script>
