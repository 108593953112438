import specialDetail from "./specialDetail";
import specialDetailIntro from "./specialDetailIntro";
import specialDetailComplete from "./specialDetailComplete";
import specialDetailSocialRank from "./specialDetailSocialRank";
import specialDetailIntroCommon from "./specialDetailIntroCommon";
import specialDetailCourseLists from "./specialDetailCourseLists";
import specialDetailIntroTeacher from "./specialDetailIntroTeacher";
import specialDetailIntroRecommend from "./specialDetailIntroRecommend";
import specialDetailSocialTodyBest from "./specialDetailSocialTodyBest";
import specialSourceControll from "./specialSourceControll";
import specialApply from "./specialApply";
import specialDetailRelevantInformation from "./specialDetailRelevantInformation";
import personalInformation from "./personalInformation";
import specialCard from "./specialCard";
import specialCardByNoHover from "./specialCardByNoHover";
import rankdetail from "./rankdetail";
import specialShopCard from "./specialShopCard";
import personalInforForm from "./personalInforForm.vue";
import specialAnnouncementNotice from "./specialAnnouncementNotice";
import specialDetailHeader from "./specialDetailHeader";

export default [
  specialDetail,
  specialDetailIntro,
  specialDetailComplete,
  specialDetailSocialRank,
  specialDetailCourseLists,
  specialDetailIntroCommon,
  specialDetailIntroTeacher,
  specialDetailIntroRecommend,
  specialDetailSocialTodyBest,
  specialSourceControll,
  specialApply, //专题班报名
  specialDetailRelevantInformation,
  personalInformation,
  specialCard, //专题卡片
  specialCardByNoHover, //专题卡片无hover遮罩
  rankdetail,
  specialShopCard,
  personalInforForm, //报名表单
  specialAnnouncementNotice,
  specialDetailHeader,
];
