var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_ncpc_rank_detail" }, [
    _c("div", { staticClass: "rank_detail_top" }, [
      _c("div", { staticClass: "title" }, [_vm._v("学员排行榜")]),
      _vm.headerData && _vm.headerData.length > 0
        ? _c(
            "ul",
            { staticClass: "rank_top_wrap" },
            _vm._l(_vm.headerData, function (it, idx) {
              return _c(
                "li",
                {
                  key: idx,
                  class: [
                    "rank_top_items",
                    idx == 0
                      ? "silver"
                      : idx == 1
                      ? "gold"
                      : idx == 2
                      ? "bronze"
                      : "",
                  ],
                },
                [
                  it
                    ? _c(
                        "div",
                        { staticClass: "hearder_img" },
                        [
                          it.photo
                            ? _c("dsf-nc-image", {
                                attrs: {
                                  src: _vm._f("imgFormat")(it.photo),
                                  "error-src": _vm.defaultSrc,
                                },
                              })
                            : _c("dsf-nc-image", {
                                attrs: { src: "", "error-src": _vm.defaultSrc },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  it ? _c("div", { staticClass: "ranking" }) : _vm._e(),
                  it
                    ? _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(it.name || "")),
                      ])
                    : _vm._e(),
                  it
                    ? _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(it.time || 0) + "分钟"),
                      ])
                    : _c("div", { staticClass: "no_data" }, [
                        _vm._v("暂无数据"),
                      ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "rank_detail_container" }, [
      _c("div", { staticClass: "ower_rank" }, [
        _c("div", { staticClass: "rankcount" }, [
          _vm._v(_vm._s(_vm.myselfData.rank)),
        ]),
        _c(
          "div",
          { staticClass: "rank_name" },
          [
            _c("dsf-nc-image", {
              attrs: {
                src: _vm._f("imgFormat")(_vm.myselfData.photo),
                "error-src": _vm.defaultSrc,
              },
            }),
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.myselfData.name || "暂无")),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "rank_time" }, [
          _vm._v(_vm._s(_vm.myselfData.time || "0") + "分钟"),
        ]),
      ]),
      _vm._m(0),
      _vm.tableData && _vm.tableData.length > 3
        ? _c(
            "ul",
            { staticClass: "rank_wrap" },
            _vm._l(_vm.tableData.slice(3), function (item, index) {
              return _c("li", { key: index, staticClass: "rank_items" }, [
                _c("div", { staticClass: "rankcount" }, [
                  _vm._v(_vm._s(index + 4)),
                ]),
                _c(
                  "div",
                  { staticClass: "rank_name" },
                  [
                    _c("dsf-nc-image", {
                      attrs: {
                        src: _vm._f("imgFormat")(item.photo),
                        "error-src": _vm.defaultSrc,
                      },
                    }),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name || "")),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "rank_time" }, [
                  _vm._v(_vm._s(item.time)),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.tableData && _vm.tableData.length > 3
        ? _c("div", { staticClass: "more", on: { click: _vm.handlerClick } }, [
            _vm._v(_vm._s(_vm.moreText)),
          ])
        : _c("div", {
            staticClass: "ds-empty-data",
            domProps: { textContent: _vm._s("暂无数据") },
          }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rank_header" }, [
      _c("div", { staticClass: "rankcount" }, [_vm._v("排名")]),
      _c("div", { staticClass: "rank_name" }, [_vm._v("学员")]),
      _c("div", { staticClass: "rank_time" }, [_vm._v("学习时长")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }