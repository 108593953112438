var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "dsf_ncpc_manage_teachresource",
          style: { width: _vm.itemWidth },
        },
        [
          _c(
            "ul",
            { staticClass: "manage_resource_wrap" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "resource_items",
                  on: {
                    click: function ($event) {
                      return _vm.clickItem(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "items_left" }, [
                    _c("i", { staticClass: "icon" }),
                    _c("div", { staticClass: "font" }, [
                      _vm._v(
                        _vm._s(item.name || "") +
                          "（" +
                          _vm._s(item.sum || 0) +
                          "）"
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "add_new",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickNewEdit(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "add_icon icon iconfont icon-tianjiawenjian",
                      }),
                      _c("div", { staticClass: "add_font" }, [_vm._v("新建")]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }