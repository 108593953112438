<template>
  <div class="dsf_nc_news ds-control" :style="{ width: itemWidth }">
    <top-title title="消息动态" :showBtn="false" />
    <div class="nitembox">
      <div class="nitem" v-for="(sd, index) in list" :key="index + 1">
        <span v-if="sd.a == 1" class="look_sel"></span>
        <span v-else></span>
        <p class="padmin">[系统消息]</p>
        <p class="ptitle ell">关于青年干部培训班的通知关于青年干部培训班的通知通知通知通知</p>
        <p class="pname">{{ sd.name }}</p>
        <p class="ptime">2019-10-28</p>
      </div>
    </div>
  </div>
</template>

<script>
import topTitle from "../../base/onStudy/topTitle";
export default dsf.component({
  name: "DsfNcNews",
  ctrlCaption: "消息动态",
  mixins: [$mixins.control],
  props: {
    itemWidth: {
      type: String,
      default: "790px",
    },
  },
  components: {
    topTitle,
  },
  data() {
    return {
      list: [
        { a: 1, name: "张三" },
        { a: 2, name: "系统管理员" },
        { a: 3, name: "张三" },
      ],
    };
  },
  methods: {},
});
</script>
