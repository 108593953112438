<template>
  <div class="ds-nc-shop-list-detail ds-control">
    <template v-if="isDesign">
      <div class="header" :class="showBgStyle">
        <div class="header-inner">
          <div class="header-left-img"><dsf-image /></div>
          <div class="header-right-desc">
            <h3 class="title">供给侧结构性改革网络布局</h3>
            <div class="has-mj-icon"></div>
            <p class="dept">
              <span class="resource">来源：网络学院-前端</span>
              <span class="scale">销量：25</span>
            </p>
            <ul class="price">
              <li>
                <span class="prefix">组织价</span>
                <dsf-icon />
                <b class="num">850</b>
              </li>
              <li>
                <span class="prefix">个人价</span>
                <dsf-icon />
                <b class="num">950</b>
              </li>
              <li>
                <span class="prefix">赠</span>
                <dsf-icon />
                <b class="num">850</b>
              </li>
            </ul>
            <p class="time-end">
              <i class="has-group">3人团</i>
              活动倒计时：02天 20时27分
            </p>
            <div class="buttons">
              <button class="has-bg" :style="{ width: btnWidth }">发起拼单</button>
              <button :style="{ width: btnWidth }">单独购买</button>
              <template v-if="shopButtons.length">
                <button v-for="it in shopButtons" :key="it.text" @click="toEmit(it)" :style="{ width: btnWidth }">
                  {{ it.text }}
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="group-buy">
          <div class="menmber-num menmber-bg">
            <p>3人正在拼单，可直接参与</p>
            <p>（限组织购买）</p>
          </div>
          <ul class="groups">
            <li>
              <dsf-image class="avatar" />
              <span>双杨</span>
            </li>
            <li>
              <dsf-image class="avatar" />
              <span>双杨</span>
            </li>
            <li>
              <dsf-image class="avatar" />
            </li>
          </ul>
          <div class="menmber-num remind-text">
            <p class="diff">还差一人拼成</p>
            <p class="time">剩余时间23:20:10</p>
          </div>
          <button class="to-buy">去拼单</button>
        </div>
        <div class="detail-content">
          <div class="left-intro">
            <h3 class="title-name">简介</h3>
            <p class="intro con">213</p>
          </div>
          <div class="right-recommend">
            <h3 class="title-name">相关推荐</h3>
            <div class="con" slot-name="recommend" :class="{ insert: isDesign }">
              <slot name="recommend"></slot>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="it">
      <div class="header" :class="showBgStyle">
        <div class="header-inner">
          <div class="header-left-img">
            <dsf-image :errorSrc="defaultSrc" :src="it.cover | imgFormat" />
          </div>
          <div class="header-right-desc">
            <h3 class="title">{{ it.name }}</h3>
            <!-- <div class="has-mj-icon"></div> -->
            <div class="special-item" v-if="type === 'special'">
              <span>必修: {{ it.needPeriod || 0 }}学时</span>
              <span>选修: {{ it.noNeedPeriod || 0 }}学时</span>
            </div>
            <p class="dept no-after" v-if="type === 'live' || type === 'channel'">
              <span class="resource">资源数：{{ it.subNum || 0 }}</span>
              <span class="scale">学时：{{ it.indentNum || 0 }}</span>
              <span class="pad-right">来源：{{ it.source || "-" }}</span>
              <span class="pad-right">销量：{{ it.indentNum || 0 }}</span>
              <span class="pad-right">库存：{{ it.numLimit || 0 }}</span>
            </p>
            <p class="dept" v-else>
              <span class="resource">来源：{{ it.source || "-" }}</span>
              <span class="scale">销量：{{ it.indentNum || 0 }}</span>
              <span>库存：{{ it.numLimit || 0 }}</span>
            </p>
            <ul class="price">
              <li>
                <span class="prefix">组织价</span>
                <span class="num-wrap">
                  <dsf-icon name="jinbi" />
                  <b class="num">{{ it.unitPrice || "免费" }}</b>
                  <!-- <b class="old-price">25</b> -->
                </span>
              </li>
              <li>
                <span class="prefix">个人价</span>
                <span class="num-wrap">
                  <dsf-icon name="jinbi" />
                  <b class="num">{{ it.userPrice || "免费" }}</b>
                  <!-- <b class="old-price">25</b> -->
                </span>
              </li>
              <li v-if="it.giftCoupon">
                <span class="prefix">赠</span>
                <span class="num-wrap">
                  <dsf-icon name="quan" />
                  <b class="num">{{ it.giftCoupon || 0 }}</b>
                </span>
              </li>
            </ul>
            <p class="time-end" v-if="it.activitySuffix">
              <i class="has-group">3人团</i>
              活动倒计时：02天 20时27分
            </p>
            <div class="buttons" v-if="!readonly">
              <!-- <button class="has-bg" @click="handleItem(0)">发起拼单</button> -->
              <!-- <button @click="handleItem(1)">单独购买</button> -->
              <button @click="handleItem(2)" :style="{ width: btnWidth }">加购物车</button>
              <template v-if="shopButtons.length">
                <button v-for="it in shopButtons" :key="it.text" @click="toEmit(it)" :style="{ width: btnWidth }">
                  {{ it.text }}
                </button>
              </template>
              <button @click="handleItem(3)" v-if="it.viewFile" :style="{ width: btnWidth }">试听</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <!-- <div class="group-buy">
          <div class="menmber-num menmber-bg">
            <p>3人正在拼单，可直接参与</p>
            <p>（限组织购买）</p>
          </div>
          <ul class="groups">
            <li>
              <dsf-image class="avatar" />
              <span>双杨</span>
            </li>
            <li>
              <dsf-image class="avatar" />
              <span>双杨</span>
            </li>
            <li>
              <dsf-image class="avatar" />
            </li>
          </ul>
          <div class="menmber-num remind-text">
            <p class="diff">还差一人拼成</p>
            <p class="time">剩余时间23:20:10</p>
          </div>
          <button class="to-buy">去拼单</button>
        </div> -->

        <div class="detail-content">
          <div class="left-intro">
            <h3 class="title-name">简介</h3>
            <p class="intro con" v-html="it.content || ''" v-if="it"></p>
          </div>
          <div class="right-recommend">
            <h3 class="title-name">相关推荐</h3>
            <div class="con" slot-name="recommend" :class="{ insert: isDesign }">
              <slot name="recommend"></slot>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="shoppingcars-fixed" :style="{ right: fixedStyle + 'px' }" @click="toShoppingCar" v-if="!readonly">
      <i class="icon-detail-shopping"></i>
      <span class="number" v-if="carCount">{{ carCount }}</span>
      <span class="my-car">我的购物车</span>
    </div>
  </div>
</template>

<script>
const ADDTOSHOPPINGCARS = "/nc/shop/shopcart/addCart",
  LOADDETAIL = "/nc/shop/goods/queryGoodsInfoById",
  CREATEORDER = "/nc/shop/shopIndent/commitIndent",
  SHOPPINGCARCOUNT = "/nc/shop/shopcart/getMyCartCount";

import shopPurchaseMethod from "../../base/shop/shopPurchaseMethod.vue";
export default dsf.component({
  name: "DsfNcShopListDetail",
  ctrlCaption: "商城详情",
  mixins: [$mixins.layout],
  props: {
    detailBg: {
      type: String,
      default: "course",
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "time", to: "time", type: "text", desc: "倒计时" },
          { from: "is_new", to: "isNew", type: "boolean", desc: "标签" },
          {
            from: "teacher_text",
            to: "teacher",
            type: "text",
            desc: "授课教师",
          },
          { from: "sales", to: "sales", type: "text|number", desc: "销量" },
          {
            from: "integral",
            to: "integral",
            type: "text|number",
            desc: "兑换总积分",
          },
          {
            from: "o_integral",
            to: "o_integral",
            type: "text|number",
            desc: "兑换积分",
          },
          {
            from: "giveIntegral",
            to: "giveIntegral",
            type: "text|number",
            desc: "赠送积分",
          },
          { from: "money", to: "money", type: "text|number", desc: "代金券" },
          {
            from: "prefixText",
            to: "prefixText",
            type: "text",
            desc: "前缀文字",
            prefix: "秒杀价",
          },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
    shoppingCarUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shoppingcars",
    },
    orderUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shoporders",
    },
    slots: {
      type: Array,
      default() {
        return [{ name: "recommend", controls: [] }];
      },
    },
    shopButtons: {
      type: Array,
      default() {
        return [];
      },
    },
    btnWidth: {
      type: String,
      default: "160px",
    },
  },
  components: {
    shopPurchaseMethod,
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      it: null,
      carCount: 0,
      type: this.$route.query.type || "course",
      readonly: this.$route.query.isview == 1,
    };
  },
  computed: {
    showBgStyle() {
      let prefix = this.$route.query?.type || this.detailBg;
      return "header-bg-" + prefix;
    },
    fixedStyle() {
      let _ww = window.innerWidth || 1400;
      if (_ww < 1400) {
        _ww = 1400;
      }
      const _rw = parseInt((_ww - 1200) / 2) - 100;
      return _rw;
    },
  },
  created() {
    if (!this.isDesign) {
      this.loadDatail();
      this.loadShoppingCarCount();
    }
  },
  methods: {
    toEmit(it) {
      let ev = "toHandle";
      if (it.actionName) ev = it.actionName;
      this.$emit(ev, it);
    },
    loadDatail() {
      const id = this.$route.query?.id;
      if (!id) return;
      const loading = dsf.layer.loading();
      let params = {
        id: id, //考试id
        pageSize: 2,
      };
      this.dsf.http
        .get(LOADDETAIL, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.it = data.info;
          } else {
            dsf.layer.message(message || "获取商品信息异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取商品信息异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    handleItem(type) {
      this.$checkLogin().then(state => {
        if (state) {
          switch (type) {
            case 0:
              break;
            case 1:
              this.createOrder();
              break;
            case 2:
              this.openPurchMethod();
              break;
            case 3:
              this.toPreviewItem();
              break;
          }
        } else {
          console.log("未登录");
        }
      });
    },
    createOrder() {
      const loading = dsf.layer.loading();
      const header = {
        "Content-Type": "application/json",
      };
      this.dsf.http
        .post(CREATEORDER, [this.it.id], { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.message("创建订单成功", true);
            dsf.layer.openWindow(this.orderUrl);
          } else {
            dsf.layer.message(message || "提交订单异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "提交订单异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    toShoppingCar() {
      dsf.layer.openWindow(this.shoppingCarUrl);
    },
    addToShoppingCars(it) {
      const loading = dsf.layer.loading();
      let opts = {};
      if (it) {
        let { text, value } = it;
        opts.shopTypeValue = value;
        opts.shopTypeText = text;
      }
      let params = {
        goodId: this.it.id,
        shopTypeValue: "private", //private：个人购买 unit：组织购买
        shopTypeText: "个人购买",
      };
      const header = {
        "Content-Type": "application/json",
      };
      params = Object.assign(params, opts);
      this.dsf.http
        .post(ADDTOSHOPPINGCARS, params, { headers: header }, $$webRoot.nc)
        .done(({ success, state, message }) => {
          if (success) {
            this.loadShoppingCarCount();
            dsf.layer.message(message, true);
          } else {
            if (state == 65006) {
              dsf.layer.message("当前商品已在购物车", true);
            } else {
              dsf.layer.message(message || "获取商品信息异常", false);
            }
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取商品信息异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    loadShoppingCarCount() {
      const loading = dsf.layer.loading();
      this.dsf.http
        .get(SHOPPINGCARCOUNT, {}, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (!success) {
            dsf.layer.message(message || "查询购物车数量异常", false);
          } else {
            this.carCount = data;
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "查询购物车数量异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    openPurchMethod() {
      const iad = dsf.getCookie("iad") || 0;
      if (iad == 1) {
        this.$openDialog({
          title: "加入购物车",
          width: "420px",
          height: "110px",
          content: shopPurchaseMethod,
          showClose: true,
          params: {
            cb: it => {
              console.log(it);
              this.addToShoppingCars(it);
            },
          },
        });
      } else {
        this.addToShoppingCars(null);
      }
    },
    toPreviewItem() {
      const { it } = this;
      const preUrl = dsf.config.setting_public_file_host;
      if (it.viewFile) {
        let sourcePath = JSON.parse(it.viewFile);
        let url = dsf.url.getWebPath(`/preview/file?url=${preUrl}${sourcePath[0].relativePath}`);
        dsf.layer.openWindow(":" + url);
      }
    },
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
  },
});
</script>
