var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_search_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            { staticClass: "item-left-img" },
            [
              _c("el-image", { attrs: { src: _vm._f("imgFormat")("") } }),
              _c("span", { staticClass: "item-type" }, [_vm._v("课程")]),
              _c(
                "span",
                { staticClass: "item-time" },
                [
                  _c("dsf-icon", { attrs: { name: "shijian" } }),
                  _vm._v(" 00:20:21 "),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "item-right-desc" }, [
            _vm._m(0),
            _vm._m(1),
            _c("ul", { staticClass: "item-bottom-desc" }, [
              _c(
                "li",
                [
                  _c("dsf-icon", { attrs: { name: "shijian" } }),
                  _vm._v(" 5学时 "),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("dsf-icon", { attrs: { name: "dianzan_kuai" } }),
                  _vm._v(" 10 "),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("dsf-icon", { attrs: { name: "pinglun" } }),
                  _vm._v(" 15 "),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "dsf_search_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            {
              staticClass: "item-left-img",
              on: {
                click: function ($event) {
                  return _vm.toDetail(_vm.data)
                },
              },
            },
            [
              _c(
                "el-image",
                { attrs: { src: _vm._f("imgFormat")(_vm.data.cover) } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.dsf.config.setting_public_card_default_img,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c("span", { staticClass: "item-type" }, [
                _vm._v(_vm._s(_vm.data.typename)),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "item-right-desc" }, [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "name ellipsis-2" }, [
                _vm._v(_vm._s(_vm.data.title)),
              ]),
            ]),
            _vm.pageType == "inland"
              ? _c("p", { staticClass: "ds-introduction" }, [
                  _vm._v(
                    " 以习近平新时代中国特色社会主义思想为指导，深入学习贯彻党的十九大和十九届二中、三中、四中、五中、六中全会精神，持续开展党史学习教育，牢固树立“四个意识”，坚定“四个自信”，做到“两个维护”，准确把握全面从严治党工作要求和税务系统党的建设工作规范以及意识形态、政务公开、保密管理、舆情应对等工作的新形势和新要求，熟练掌握办公室（党委办公室）工作基本制度、基本流程，切实提升组织、沟通、协调、执行、写作等办文办事办会基本能力，更好履行办公室工作职责，服务学院教育培训事业高质量发展。 "
                  ),
                ])
              : _vm._e(),
            _c("p", { staticClass: "item-teacher-name" }, [
              _c("span", [_vm._v(_vm._s(_vm.data.skjs))]),
              _vm.data.position
                ? _c("span", [_vm._v(" - " + _vm._s(_vm.data.position))])
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "name ellipsis-2" }, [_vm._v("我是一个标题")]),
      _c("span", { staticClass: "task" }, [_vm._v("任务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "item-teacher-name" }, [
      _c("span", [_vm._v("张三")]),
      _c("span", [_vm._v("项目经理")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }