var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_special_detail",
      style: { display: _vm.visible ? "block" : "none" },
    },
    [
      _c(
        "div",
        {
          staticClass: "special_detail_innerwrap",
          style: { width: _vm.itemWidth },
        },
        [
          _c(
            "div",
            { staticClass: "detail_imgbox cover" },
            [
              _c("dsf-nc-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.detailData.cover),
                  width: "530px",
                  height: "280px",
                  "error-src": _vm.defaultSrc,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "detail_conent" }, [
            _c("div", { staticClass: "detail_name ell" }, [
              _vm._v(_vm._s(_vm.detailData.name)),
            ]),
            _c("div", { staticClass: "bm_date font" }, [
              _vm._v(
                "报名日期：" +
                  _vm._s(
                    _vm.setDate(
                      _vm.detailData.bmsjSdate,
                      _vm.detailData.bmsjEdate,
                      "至"
                    )
                  )
              ),
            ]),
            _c("div", { staticClass: "px_date font" }, [
              _vm._v(
                "培训日期：" +
                  _vm._s(
                    _vm.setDate(
                      _vm.detailData.pxsjSdate,
                      _vm.detailData.pxsjEdate,
                      "至"
                    )
                  )
              ),
            ]),
            _c("div", { staticClass: "jytj font" }, [
              _vm._v(
                " 结业条件：选修 " +
                  _vm._s(_vm.detailData.electives || 0) +
                  "    必修 " +
                  _vm._s(_vm.detailData.compulsory || 0) +
                  "    学时 " +
                  _vm._s(_vm.detailData.period) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "student font" }, [
              _vm._v("学员人数：" + _vm._s(_vm.detailData.studentCount)),
            ]),
            _c("div", { staticClass: "foot_wrap" }, [
              _c(
                "ul",
                { staticClass: "labe_wrap" },
                _vm._l(_vm.labelLists, function (item, index) {
                  return _c("li", { key: index, staticClass: "labe_item" }, [
                    _vm._v(_vm._s(item)),
                  ])
                }),
                0
              ),
              _c("div", { staticClass: "btn_box" }, [
                _vm.setStatus(_vm.detailData) &&
                _vm.isshowbtn &&
                _vm.detailData.state != 2
                  ? _c(
                      "div",
                      {
                        staticClass: "btn",
                        style:
                          "" +
                          (_vm.setStatus(_vm.detailData) == "待审核" ||
                          _vm.setStatus(_vm.detailData) == "已结业" ||
                          _vm.setStatus(_vm.detailData) == "审核中" ||
                          _vm.setStatus(_vm.detailData) == "已拒绝" ||
                          _vm.detailData.state == -1 ||
                          (_vm.detailData.state == 0 &&
                            _vm.detailData.signStatus != 1) ||
                          (_vm.detailData.state == 2 &&
                            _vm.detailData.trainingStatus != 1) ||
                          _vm.isview
                            ? "background: #aaa; cursor: default"
                            : ""),
                        on: {
                          click: function ($event) {
                            !_vm.isview && _vm.clickEnter(_vm.detailData)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.setStatus(_vm.detailData)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.detailData.state == 5
                  ? _c("div", { staticClass: "reason" }, [
                      _vm._v(_vm._s(_vm.detailData.reason)),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }