<template>
  <div class="dsf_nc_saas_create_page" style="height: 100%; display: flex; align-items: center">
    <el-input v-model="pageName" placeholder="页面名称"></el-input>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcSaasCreatePage",
  ctrlCaption: "新增页面",
  mixins: [$mixins.control],
  components: {},
  props: {},
  computed: {},
  data() {
    return {
      pageName: "",
    };
  },
  mounted() {},
  methods: {},
});
</script>
