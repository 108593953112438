<template>
  <div class="dsf_learningStatistics ds-control" :style="{ margin: itemMargin, width: itemWidth }">
    <div>
      <div class="item" v-for="item in itemList" :key="item">
        <div class="item-inner" @click="toDetail(item)">
          <i :class="['iconfont', item.icon]"></i>
          <p class="count">{{ item.finishedNum || 0 }}</p>
          <p class="des">{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTitle from "../../base/onStudy/topTitle";
export default dsf.component({
  name: "DsfNcLearningStatistics",
  ctrlCaption: "学习统计",
  mixins: [$mixins.control],
  props: {
    itemMargin: {
      type: String,
      default: "0 0 0 0",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
  },
  components: {
    topTitle,
  },
  data() {
    return {
      itemList: [
        {
          ur: "",
          des: "学习课程",
          count: 12311,
        },
        {
          ur: "",
          des: "专题班",
          count: 12311,
        },
        {
          ur: "",
          des: "考试",
          count: 12311,
        },
        {
          ur: "",
          des: "证书",
          count: 12311,
        },
        {
          ur: "",
          des: "笔记",
          count: 12311,
        },
        {
          ur: "",
          des: "评价",
          count: 12311,
        },
      ],
      menus: null,
    };
  },
  mounted() {
    this.loadLeaingStatistic();
  },
  methods: {
    loadLeaingStatistic() {
      const sourceUrl = "nc/record/statistic/finishedDataNum";
      const loading = dsf.layer.pc.loading();
      let params = {};
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data }) => {
          if (!success) {
            error(res);
          } else {
            if (success) {
              this.itemList = data;
            }
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    toDetail(item) {
      item.url && dsf.layer.openWindow(item.url);
    },
  },
});
</script>
