var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specialdetail_relevevatn_information",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "intro_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.showMore && _vm.moreStyle == "tb"
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !_vm.isview && !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm.showMore && _vm.moreStyle == "wz"
            ? _c(
                "span",
                {
                  staticClass: "more_font",
                  on: {
                    click: function ($event) {
                      !_vm.isview && !!_vm.showMore && _vm.clickMore()
                    },
                  },
                },
                [_vm._v("更多")]
              )
            : _vm._e(),
        ]),
        _vm.showLine ? _c("span", { staticClass: "line" }) : _vm._e(),
      ]),
      _vm.lists.length
        ? _c(
            "ul",
            { staticClass: "relevant_information_wrap" },
            _vm._l(_vm.lists, function (item, index) {
              return _c("li", { key: index, staticClass: "infomation_items" }, [
                _c("i", { class: "icon " + item["nc_class_file.type"] }),
                _c("span", { staticClass: "name ell" }, [
                  _vm._v(_vm._s(item["nc_class_file.title"])),
                ]),
                _c(
                  "span",
                  {
                    class: [
                      "ylook",
                      {
                        gray_font:
                          (!_vm.specialState && _vm.resouceType == "special") ||
                          _vm.isview,
                      },
                    ],
                    on: {
                      click: function ($event) {
                        ;(!_vm.isview &&
                          _vm.resouceType == "special" &&
                          _vm.specialState &&
                          _vm.handleClickLook(item)) ||
                          (!_vm.isview &&
                            _vm.resouceType != "special" &&
                            _vm.handleClickLook(item))
                      },
                    },
                  },
                  [_vm._v(" 预览 ")]
                ),
                item["nc_class_file.download_free"] &&
                item["nc_class_file.download_free"].value == 1
                  ? _c(
                      "span",
                      {
                        class: [
                          "ydown",
                          {
                            gray_font:
                              (!_vm.specialState &&
                                _vm.resouceType == "special") ||
                              _vm.isview,
                          },
                        ],
                        on: {
                          click: function ($event) {
                            ;(!_vm.isview &&
                              _vm.resouceType == "special" &&
                              _vm.specialState &&
                              _vm.handleClickDown(item)) ||
                              (!_vm.isview &&
                                _vm.resouceType != "special" &&
                                _vm.handleClickDown(item))
                          },
                        },
                      },
                      [_vm._v(" 下载 ")]
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _c("div", {
            staticClass: "ds-empty-data",
            domProps: { textContent: _vm._s("暂无数据") },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }