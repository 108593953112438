var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-dataGrid-child-test" },
    [
      _vm.$list.checkboxColumn.show
        ? _c("el-checkbox", {
            model: {
              value: _vm.isSelected,
              callback: function ($$v) {
                _vm.isSelected = $$v
              },
              expression: "isSelected",
            },
          })
        : _vm._e(),
      _c("img", {
        attrs: {
          src:
            "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=134348695,4168793225&fm=26&gp=0.jpg",
          alt: "",
        },
      }),
      _c("div", { staticClass: "username" }, [
        _vm._v(_vm._s(_vm.getVal("ds_create_user_name"))),
      ]),
      _c("div", [_vm._v("年龄：24")]),
      _c("div", [_vm._v("职级：司令")]),
      _c("div", { staticClass: "btns" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("DsfButtonBar", {
              staticClass: "ds-no-padding",
              attrs: {
                "max-number": _vm.$list.rowButtonsMaxNumber,
                "more-button-text": _vm.$list.rowButtonsMoreText,
                "more-button-style": _vm.$list.rowButtonsMoreStyle,
                buttons: _vm.getRowButtons(),
                local: _vm.local,
                "emit-prefix": "row-button",
                size: "small",
                align: "center",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }