var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_collectionCourse ds-control",
      style: { margin: _vm.itemMargin, width: _vm.itemWidth },
    },
    [
      _c("div", { staticClass: "dsf_collectionCourse_inner" }, [
        _c(
          "div",
          _vm._l(_vm.lists, function (item) {
            return _c(
              "div",
              { key: item, staticClass: "item" },
              [
                _c("van-image", {
                  staticClass: "img",
                  attrs: {
                    width: "120",
                    height: "70",
                    fit: "cover",
                    src: item.ur,
                  },
                }),
                _c("p", { staticClass: "text" }, [_vm._v(_vm._s(item.des))]),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }