var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_title", style: { padding: _vm.padding } },
    [
      _c("div", { staticClass: "title-inner" }, [
        _c("div", { staticClass: "title-wrap" }, [
          _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", { staticClass: "title-more" }, [
            _c(
              "span",
              {
                attrs: { slot: "more" },
                on: { click: _vm.toDetail },
                slot: "more",
              },
              [_vm._v("更多>>")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }