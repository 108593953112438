<template>
  <div class="DsfNcPcDataCenter" :style="{ width: width }" v-if="visible">
    <div class="content">
      <div class="container-item">
        <h3 class="item-title">学习汇总统计</h3>
        <div class="item-content">
          <ul class="items-study">
            <li v-for="(item, index) in studySummary" :key="index">
              <div class="items-study-item">
                <h4 class="study-item-title">{{ item.label }}</h4>
                <p class="study-item-info">
                  <b class="ell">{{ studySummaryData[item.from] }}</b>
                  <span>{{ item.suffix }}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="container-item container-item-margin jschoose">
        <h3 class="item-title">
          登录学习统计
          <div class="choosebox">
            <div class="citem">
              <span>设备:</span>
              <ul type="deviceType">
                <li v-for="(item, index) in deviceTypeList" :key="index" :class="{ liveact: item.type == deviceType }" @click="loginLearn('deviceType', item.type)">
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="citem">
              <span>时间:</span>
              <ul type="queryType">
                <li v-for="(item, index) in queryTypeList" :key="index" :class="{ liveact: item.type == queryType }" @click="loginLearn('queryType', item.type)">
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="citem">
              <ul type="granularity">
                <li v-for="(item, index) in granularityList" :key="index" :class="{ liveact: item.type == granularity }" @click="loginLearn('granularity', item.type)">
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </h3>
        <div class="item-content">
          <div class="item-login-info">
            <h3 class="item-eCharts-title">学习访问量</h3>
            <!-- v-show="LoginLearnData.length" -->
            <div id="EChartsLogin" style="width: 100%; height: 260px"></div>
          </div>
        </div>
      </div>

      <div class="container-item container-item-margin">
        <h3 class="item-title">
          登录情况统计
          <ul class="item-tab" type="loginSth">
            <li v-for="(item, index) in loginSthList" :key="index" :class="{ liveact: item.type == loginSth }" @click="changeLogin(item.type)">
              {{ item.name }}
            </li>
          </ul>
        </h3>
        <div class="item-content">
          <div class="item-con-box item-box-float-l">
            <h3 class="item-eCharts-title">用户登录情况</h3>
            <ul class="login-situation jslogin-situation">
              <li>
                可用用户数 :
                <span>{{ loginConditionData[loginDescKeys[0].from] || 0 }}</span>
              </li>
              <li>
                已登用户数 :
                <span>{{ loginConditionData[loginDescKeys[1].from] || 0 }}</span>
              </li>
              <li style="display: block">
                活跃度 :
                <span class="rate-num">{{ loginConditionData[loginDescKeys[2].from] || 0 }}%</span>
              </li>
            </ul>
            <div id="EChartsLoginDesc" style="width: 100%; height: 200px"></div>
          </div>
          <div class="item-con-box item-box-float jsitem-box-float">
            <h3 class="item-eCharts-title title-margin">用户登录来源</h3>
            <p class="item-com item-com-1">
              <span :style="{ background: this.loginDescKeys[4].suffix || '#32c5ff' }"></span>
              PC端登录:{{ loginConditionData[loginDescKeys[4].from] || 0 }}
            </p>
            <p class="item-com item-com-2">
              <span :style="{ background: this.loginDescKeys[5].suffix || '#0079ff' }"></span>
              移动端登录:{{ loginConditionData[loginDescKeys[5].from] || 0 }}
            </p>
            <p class="item-com item-com-3">
              <span :style="{ background: this.loginDescKeys[6].suffix || '#fa6400' }"></span>
              PC&移动端登录:{{ loginConditionData[loginDescKeys[6].from] || 0 }}
            </p>
            <div class="item-com-all">
              <span class="item-pc" :style="{ width: num1 + '%', background: this.loginDescKeys[4].suffix || '#32c5ff' }">{{ num1 }}%</span>
              <span class="item-mobile" :style="{ width: num2 + '%', background: this.loginDescKeys[5].suffix || '#0079ff' }">{{ num2 }}%</span>
              <span class="item-pc-mobile" :style="{ width: num3 + '%', background: this.loginDescKeys[6].suffix || '#fa6400' }">{{ num3 }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcDataCenter",
  ctrlCaption: "数据中心",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    studySummaryUrl: {
      type: String,
      default: "nc/record/statistic/viewLearnGather",
    },
    loginLearnUrl: {
      type: String,
      default: "nc/record/statistic/viewLearnDetail",
    },
    loginConditionUrl: { type: String, default: "nc/record/statistic/viewLoginGather" },
    width: {
      type: String,
      default: "1200px",
    },
    studySummary: {
      type: Array,
      default: () => {
        return [
          { from: "userNum", to: "students", type: "text", desc: "封面", label: "学员数", suffix: "人" },
          { from: "courseNum", to: "course", type: "text", desc: "考试名称", label: "课程数", suffix: "门" },
          { from: "teacherNum", to: "teacher", type: "text", desc: "开始时间", label: "师资数", suffix: "人" },
          { from: "classNum", to: "special", type: "text", desc: "结束时间", label: "专题数", suffix: "个" },
          { from: "periodNum", to: "cumulativeTime", type: "text", desc: "总分", label: "累计学时", suffix: "学时" },
          { from: "durationNum", to: "cumulativeHours", type: "text", desc: "及格分数", label: "累计学习时长（分钟）", suffix: "分钟" },
          { from: "learningNum", to: "learningNum", type: "text", desc: "人数", label: "课程在学人数", suffix: "人" },
          { from: "finishedfNum", to: "finishedfNum", type: "text", desc: "人数", label: "课程完成人数", suffix: "人" },
          // { from: "commentNum", to: "questions", type: "text", desc: "考试次数", label: "问答", suffix: "个" },
          // { from: "summaryNum", to: "work", type: "text", desc: "已考次数", label: "作业/小结", suffix: "篇" },
        ];
      },
    },
    loginLearnKeys: {
      type: Array,
      default: () => {
        return [
          { from: "text", to: "time", type: "text", desc: "时间" },
          { from: "value", to: "num", type: "text", desc: "数量", suffix: "#3c8ddb" },
        ];
      },
    },
    loginDescKeys: {
      type: Array,
      default: () => {
        return [
          { from: "userNum", to: "sum", type: "text", desc: "可用用户数 " },
          { from: "loginNum", to: "logined", type: "text", desc: "已登用户数", suffix: "#32C5FF" },
          { from: "loginRate", to: "liveness", type: "text", desc: "活跃度" },
          { from: "noLoginNum", to: "notLogin", type: "text", desc: "未登录人数", suffix: "#FA6400" },
          { from: "pcLoginNum", to: "pcLogin", type: "text", desc: "pc端登录", suffix: "#32c5ff" },
          { from: "mobileLoginNum", to: "mobileLogin", type: "text", desc: "移动端登录", suffix: "#0079ff" },
          { from: "pcMobileLoginNum", to: "allLogin", type: "text", desc: "PC&移动端登录", suffix: "#fa6400" },
        ];
      },
    },
  },
  data() {
    return {
      deviceTypeList: [
        { name: "全部", type: "0" },
        { name: "pc端", type: "1" },
        { name: "移动端", type: "2" },
      ],
      queryTypeList: [
        { name: "今天", type: "1" },
        { name: "昨天", type: "2" },
        { name: "最近7天", type: "3" },
        { name: "最近30天", type: "4" },
      ],
      granularityList: [
        { name: "按时", type: "1" },
        { name: "按日", type: "2" },
      ],
      loginSthList: [
        { name: "今日", type: "1" },
        { name: "历史", type: "2" },
      ],
      deviceType: "0",
      queryType: "1",
      granularity: "1",
      loginSth: "1",
      studySummaryData: {},
      LoginLearnData: [],
      loginConditionData: {},
      num1: 0,
      num2: 0,
      num3: 0,
    };
  },
  created() {},
  mounted() {
    this.loadScript(() => {
      if (!this.isDesign) {
        this.getStudySummary();
        this.getLoginLearn();
        this.getLoginCondition();
      } else {
        this.studySummaryData = {
          userNum: "1",
          courseNum: "23",
          teacherNum: "2232",
          classNum: "22232",
          periodNum: "2232",
          durationNum: "322",
          commentNum: "232",
          summaryNum: "5",
          learningNum: "1000",
          finishedfNum: "1000",
        };
        this.LoginLearnData = [
          { text: "2021-09-09", value: 4 },
          { text: "2021-09-10", value: 30 },
        ];
        this.loginConditionData = {
          userNum: 32,
          loginNum: 22,
          loginRate: 0.12,
          noLoginNum: 10,
          pcLoginNum: 10,
          mobileLoginNum: 12,
          pcMobileLoginNum: 22,
        };
        this.initLoginLearning();
        this.initLoginDesc();
        this.getRatio();
      }
    });
  },
  methods: {
    //获取学习汇总
    getStudySummary() {
      if (!this.studySummaryUrl) return;
      const loading = dsf.layer.loading();
      this.dsf.http
        .get(this.studySummaryUrl, {}, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.studySummaryData = res.data;
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },

    //点击选项
    loginLearn(name, type) {
      this[name] = type;
      this.getLoginLearn();
    },
    changeLogin(type) {
      this.loginSth = type;
      this.getLoginCondition();
    },
    getLoginLearn() {
      if (!this.loginLearnUrl) return;
      const loading = dsf.layer.loading();
      this.dsf.http
        .get(
          this.loginLearnUrl,
          {
            deviceType: this.deviceType,
            queryType: this.queryType,
            granularity: this.granularity,
          },
          $$webRoot.nc
        )
        .done(res => {
          if (res.success) {
            this.LoginLearnData = res.data;
            this.initLoginLearning();
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    //
    getLoginCondition() {
      if (!this.loginConditionUrl) return;
      const loading = dsf.layer.loading();
      this.dsf.http
        .get(
          this.loginConditionUrl,
          {
            queryType: this.loginSth,
          },
          $$webRoot.nc
        )
        .done(res => {
          if (res.success) {
            this.loginConditionData = res.data;
            this.initLoginDesc();
            this.getRatio();
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    initLoginLearning() {
      if (!document.getElementById("EChartsLogin")) return;
      let xarr = [],
        yarr = [];
      if (!this.LoginLearnData.length) {
        document.getElementById("EChartsLogin").innerHTML = '<div class="no_data">暂无数据</div>';
        return;
      }
      for (var i = 0, len = this.LoginLearnData.length; i < len; i++) {
        xarr.push(this.LoginLearnData[i][this.loginLearnKeys[0].from]);
        yarr.push(this.LoginLearnData[i][this.loginLearnKeys[1].from]);
      }
      var intervalNum = 0;
      if (xarr.length > 20) {
        intervalNum = 1;
      }
      if (xarr.length > 32) {
        intervalNum = 2;
      }
      if (xarr.length > 40) {
        intervalNum = 3;
      }
      if (xarr.length > 60) {
        intervalNum = 4;
      }
      let eChartsLogin = echarts.init(document.getElementById("EChartsLogin"));
      let option = {
        color: [this.loginLearnKeys[0].suffix || "#C90C0A"],
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          axisLabel: { interval: intervalNum, rotate: 30 },
          type: "category",
          data: xarr,
        },
        yAxis: {
          minInterval: 1,
          type: "value",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#EEE",
              type: "dotted",
            },
          },
        },
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            data: yarr,
            type: "line",
            symbolSize: 10,
            symbol: "circle",
          },
        ],
      };
      eChartsLogin.setOption(option);
    },
    initLoginDesc() {
      if (document.getElementById("EChartsLoginDesc")) return;
      let eChartsLoginDesc = echarts.init(document.getElementById("EChartsLoginDesc"));
      let option = {
        color: [this.loginDescKeys[1].suffix || "#32C5FF", this.loginDescKeys[3].suffix] || "#FA6400",
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "45%"],
            label: {
              normal: {
                formatter: "{b}\n{c}\n({d}%)",
                color: "#666",
                lineHeight: 13.2,
              },
            },
            data: [
              { value: this.loginConditionData[this.loginDescKeys[3].from] || 0, name: "未登录人数" },
              { value: this.loginConditionData[this.loginDescKeys[1].from] || 0, name: "已登录人数" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      eChartsLoginDesc.setOption(option);
    },
    getRatio() {
      let totlenum =
        Number(this.loginConditionData[this.loginDescKeys[4].from] || 0) +
        Number(this.loginConditionData[this.loginDescKeys[5].from] || 0) +
        Number(this.loginConditionData[this.loginDescKeys[6].from] || 0);
      this.num1 = (((this.loginConditionData[this.loginDescKeys[4].from] || 0) / totlenum) * 100 + "").substr(0, 5);
      this.num2 = (((this.loginConditionData[this.loginDescKeys[5].from] || 0) / totlenum) * 100 + "").substr(0, 5);
      this.num3 = (((this.loginConditionData[this.loginDescKeys[6].from] || 0) / totlenum) * 100 + "").substr(0, 5);
      if (totlenum == 0) {
        console.log(1111);
        this.num1 = this.num2 = this.num3 = 0;
      }
    },
    // 加载js
    loadScript(callback) {
      this.$http
        .importFiles([[dsf.url.getWebPath("$/js/libs/echarts/echarts.min.js"), ".js"]])
        .then(() => {
          callback?.();
        })
        .catch(() => {
          dsf.layer.message("加载echarts文件报错", false);
          reject();
        });
    },
  },
});
</script>
