var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-register-control" },
    [
      _c("div", { staticClass: "dsf-register-step" }, [
        _c(
          "div",
          {
            staticClass: "dsf-register-setp",
            class: { "current-step": _vm.step >= 1 },
          },
          [
            _c("div", { staticClass: "step-cricle" }, [
              _c(
                "div",
                { staticClass: "cricle normal" },
                [
                  _vm.step > 1
                    ? _c("dsf-icon", { attrs: { name: "icon_gou" } })
                    : _c("span", [_vm._v("1")]),
                ],
                1
              ),
              _c("p", [_vm._v("注册")]),
            ]),
            _c("div", { staticClass: "right-line" }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "dsf-register-setp",
            class: { "current-step": _vm.step >= 2 },
          },
          [
            _c("div", { staticClass: "left-line" }),
            _c("div", { staticClass: "step-cricle" }, [
              _c(
                "div",
                { staticClass: "cricle" },
                [
                  _vm.step > 2
                    ? _c("dsf-icon", { attrs: { name: "icon_gou" } })
                    : _c("span", [_vm._v("2")]),
                ],
                1
              ),
              _c("p", [_vm._v("手机验证")]),
            ]),
            _c("div", { staticClass: "right-line" }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "dsf-register-setp",
            class: { "current-step": _vm.step >= 3 },
          },
          [_c("div", { staticClass: "left-line" }), _vm._m(0)]
        ),
      ]),
      _c("div", { staticClass: "dsf-register-content" }, [
        _vm.step == 1
          ? _c(
              "div",
              [
                _c("div", { staticClass: "hint" }, [
                  _vm._v(
                    "请务必填写真实姓名和身份证号，设置并妥善保管密码。注册完成之后，身份证号将无法更改并做为登录名使用。"
                  ),
                ]),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "userInfo-ruleForm",
                    attrs: {
                      model: _vm.userInfo,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入姓名", clearable: "" },
                          model: {
                            value: _vm.userInfo.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userInfo,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userInfo.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "身份证号", prop: "IDCode" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入身份证号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.userInfo.IDCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userInfo,
                                "IDCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userInfo.IDCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "设置密码", prop: "password" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "password",
                            placeholder: "请输入密码",
                            clearable: "",
                            maxlength: "15",
                          },
                          model: {
                            value: _vm.userInfo.password,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userInfo,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userInfo.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "确认密码", prop: "confirmPassword" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "password",
                            placeholder: "请再次输入密码",
                            clearable: "",
                            maxlength: "15",
                          },
                          model: {
                            value: _vm.userInfo.confirmPassword,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userInfo,
                                "confirmPassword",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userInfo.confirmPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "dsf-register-next-btn",
                            class: { disabled: _vm.disabled },
                            attrs: { type: "primary", disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm("ruleForm")
                              },
                            },
                          },
                          [_vm._v(" 下一步 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.step === 2
          ? _c(
              "div",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.phone,
                      expression: "phone",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "dsf-register-input",
                  attrs: { type: "text", placeholder: "请输入手机号" },
                  domProps: { value: _vm.phone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.phone = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "dsf-register-btn",
                    on: { click: _vm.showDialog },
                  },
                  [_vm._v("请点击进行验证")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "dsf-register-next-btn",
                    class: {
                      disabled:
                        !_vm.phone || !_vm.dsf.validate("isMobile", this.phone),
                    },
                    attrs: {
                      type: "primary",
                      disabled:
                        !_vm.phone || !_vm.dsf.validate("isMobile", this.phone),
                    },
                    on: { click: _vm.handleClickNext },
                  },
                  [_vm._v(" 下一步 ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.step === 3
          ? _c(
              "div",
              { staticClass: "step-three" },
              [
                _vm._m(1),
                _c("p", { staticClass: "success-tip" }, [_vm._v("注册成功 !")]),
                _c("p", { staticClass: "mind" }, [
                  _vm._v("请牢记您的用户名和密码："),
                ]),
                _c("div", { staticClass: "showUser" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-name" }, [
                      _vm._v("用户名"),
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.phone)),
                    ]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-name" }, [
                      _vm._v("密    码"),
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.userInfo.password)),
                    ]),
                  ]),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "dsf-register-success-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.reLogin },
                  },
                  [_vm._v("去 登 录")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "modal-append-to-body": false,
            visible: _vm.dialogVisible,
            width: "480px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dsf-register-dialog" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("手机短信验证码")]),
              _c("div", { staticClass: "phone" }, [
                _vm._v("当前手机号：" + _vm._s(_vm.hiddenPhone)),
              ]),
              _c(
                "div",
                { staticClass: "dsf-register-input-2" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.code,
                        expression: "code",
                      },
                    ],
                    attrs: { type: "text", placeholder: "请输入验证码" },
                    domProps: { value: _vm.code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.code = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "dsf-register-next-btn",
                      class: { disabled: _vm.interval },
                      attrs: {
                        type: "primary",
                        disabled: Boolean(_vm.interval),
                      },
                      on: { click: _vm.getCode },
                    },
                    [_vm._v(" " + _vm._s(_vm.timeMsg) + " ")]
                  ),
                ],
                1
              ),
              _vm.codeAuthResult && _vm.code
                ? _c("p", { staticClass: "error-msg" }, [
                    _c("i", { staticClass: "iconfont icon-tixingshixin" }),
                    _vm._v(" " + _vm._s(_vm.codeAuthResult) + " "),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "dsf-register-dialog-next-btn",
                  class: { disabled: !_vm.code || !_vm.isShowCodeBtn },
                  attrs: {
                    type: "primary",
                    disabled: !_vm.code || !_vm.isShowCodeBtn,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.authCode(2)
                    },
                  },
                },
                [_vm._v(" 下一步 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-cricle" }, [
      _c("div", { staticClass: "cricle" }, [_vm._v("3")]),
      _c("p", [_vm._v("注册成功")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-succss" }, [
      _c("i", { staticClass: "el-icon-check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }