<template>
  <div class="dsf-changePwd-control">
    <el-form :model="userInfo" :rules="rules" ref="ruleForm" label-width="100px" class="userInfo-ruleForm">
      <el-form-item label="单位" prop="deptName" class="no-marging">
        <p>{{ userInfo.deptName }}</p>
      </el-form-item>
      <el-form-item label="用户名" prop="loginName" class="no-marging">
        <p>{{ userInfo.loginName }}</p>
      </el-form-item>
      <el-form-item label="原密码" prop="oldPassword">
        <el-input v-model.trim="userInfo.oldPassword" type="password" placeholder="请输入密码" clearable show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model.trim="userInfo.newPassword" type="password" placeholder="请输入密码" clearable show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword" class="confirmPassword">
        <el-input v-model.trim="userInfo.confirmPassword" type="password" placeholder="请确认密码" clearable show-password></el-input>
      </el-form-item>
      <p class="step-tip">{{ messageTips }}</p>
      <el-form-item class="btn-form">
        <div class="btns">
          <el-button type="primary" class="sumit" @click="submitForm('ruleForm')">确 认</el-button>
          <el-button class="close" @click="closePage" v-if="!hideCancelButton">关 闭</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcChangePwd",
  ctrlCaption: "修改密码",
  mixins: [$mixins.control],
  data() {
    return {
      userInfo: {},
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          // { min: 6, message: "长度最小8个字符", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          // { min: 6, message: "长度最小8个字符", trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              console.log(this.userInfo.newPassword);
              if (!value) {
                return callback(new Error("请确认密码"));
              }
              if (value != this.userInfo.newPassword) {
                return callback(new Error("两次密码不一致"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      messageTips: "",
    };
  },
  props: {
    reqUrl: {
      type: String,
      default: "/nc/org/user/detail",
    },
    submitUrl: {
      type: String,
      default: "nc/org/user/updatePassword",
    },
    hideCancelButton: {
      type: [Boolean, String, Number],
      default: false,
    },
  },
  computed: {},
  watch: {},
  async mounted() {
    if (this.isDesign) {
      this.userInfo = {
        deptName: "成都",
        loginName: "张三",
      };
    } else {
      this.getData();
      let res = await this.getiInterval();
      if (res.success) {
        this.messageTips = res.data.notice;
      }
    }
    this.hideCancelButton = Boolean(this.queryString["hideCancelButton"] || 0);
  },

  beforeDestroy() {},
  methods: {
    getData() {
      if (this.reqUrl) {
        const loading = dsf.layer.pc.loading();
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              this.userInfo = res.data;
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          })
          .always(function () {
            dsf.layer.pc.closeLoading(loading);
          });
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          //验证密码规则
          await this.selfverify();
          if (this.$listeners["handleSubmit"]) {
            this.$dispatch("handleSubmit", this.userInfo);
          } else {
            const loading = dsf.layer.pc.loading();
            this.dsf.http
              .post(this.submitUrl, { originPassword: dsf.md5(dsf.md5(this.userInfo.oldPassword)), newPassword: dsf.md5(dsf.md5(this.userInfo.newPassword)) }, $$webRoot.nc)
              .done(res => {
                if (res.success) {
                  dsf.cookies.remove("reset_pwd");
                  dsf.layer
                    .confirm({ message: "修改密码成功, 是否重新登录?", title: "提示", confirmButtonText: "是", cancelButtonText: "否" })
                    .then(() => {
                      this.reLogin();
                    })
                    .catch(() => {
                      this.$root.close();
                      console.log("取消重新登录");
                    });
                } else {
                  this.dsf.layer.message(res.message || "获取数据异常", false);
                }
              })
              .error(response => {
                this.dsf.layer.message(response.message || "获取数据异常", false);
              })
              .always(function () {
                dsf.layer.pc.closeLoading(loading);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getiInterval() {
      return new Promise(resolve => {
        this.dsf.http
          .get("user/password/getUpdatePwdRule", {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              resolve(res);
            } else {
              this.dsf.layer.message(res.message || "获取密码规则错误", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取密码规则错误", false);
          });
      });
    },
    selfverify() {
      return new Promise(resolve => {
        this.dsf.http
          .get("nc/reg/ncVerifyPwd", { pwd: dsf.base64.encode(this.userInfo.newPassword) }, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              if (res.data.success) {
                resolve(res.data.success);
              } else {
                this.dsf.layer.message(res.data.message, false);
              }
            } else {
              this.dsf.layer.message(res.message || "验证密码失败", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "验证密码失败", false);
          });
      });
    },
    closePage() {
      if (this.$listeners["handleClosePage"]) {
        this.$dispatch("handleClosePage", this.userInfo);
      } else {
        // dsf.layer.closeWindow();
        // this.close();
        this.$root.close();
      }
    },
    reLogin() {
      dsf.storage.session.clear();
      dsf.cookies.remove("userId");
      dsf.cookies.set("isLogin", "");
      dsf.cookies.set("authorization_token", "");
      this.$root.close();
      window.location.href = location.origin + dsf.url.getWebPath(dsf.config.setting_public_pcindex_url) + "?showLogin=1";
      window.location.reload();
    },
  },
});
</script>
