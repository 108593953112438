var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_nc_saas_create_page",
      staticStyle: { height: "100%", display: "flex", "align-items": "center" },
    },
    [
      _c("el-input", {
        attrs: { placeholder: "页面名称" },
        model: {
          value: _vm.pageName,
          callback: function ($$v) {
            _vm.pageName = $$v
          },
          expression: "pageName",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }