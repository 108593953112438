<template>
  <div class="dsf_userPortrayal" v-if="visible" :style="{ width: width, height: height }">
    <userTitle :title="title" :showMore="false" v-if="showHeader"></userTitle>
    <div ref="drawChart" :style="{ width: width, height: getHeight }"></div>
  </div>
</template>

<script>
import userTitle from "./userTitle.vue";
export default dsf.component({
  name: "DsfNcUserPortrayal",
  ctrlCaption: "用户画像",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "用户画像",
    },
    width: {
      type: String,
      default: "480px",
    },
    height: {
      type: String,
      default: "400px",
    },
    reqUrl: { type: String, default: "" },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "xxdUser", to: "xxdUser", type: "text", desc: "用户学习度" },
          { from: "wcdUser", to: "wcdUser", type: "text", desc: "用户完成度" },
          { from: "cjdUser", to: "cjdUser", type: "text", desc: "用户成就度" },
          { from: "gxdUser", to: "gxdUser", type: "text", desc: "用户贡献度" },
          { from: "cydUser", to: "cydUser", type: "text", desc: "用户参与度" },
          { from: "xxdAvg", to: "xxdAvg", type: "text", desc: "平均学习度" },
          { from: "wcdAvg", to: "wcdAvg", type: "text", desc: "平均完成度" },
          { from: "cjdAvg", to: "cjdAvg", type: "text", desc: "平均成就度" },
          { from: "gxdAvg", to: "gxdAvg", type: "text", desc: "平均贡献度" },
          { from: "cydAvg", to: "cydAvg", type: "text", desc: "平均参与度" },
        ];
      },
    },
    resData: {
      tyep: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      keys: {},
    };
  },
  computed: {
    getHeight() {
      return parseInt(this.height) - 41 + "px";
    },
  },
  components: {
    userTitle,
  },
  created() {
    this.keysMap.forEach(item => {
      this.keys[item.to] = item.from;
    });
  },
  mounted() {
    if (!window.echarts) {
      this.loadScript(() => {
        if (this.isDesign) {
          this.resData = {
            cjdAvg: "0",
            cjdUser: "0",
            cydAvg: "1",
            cydUser: "77",
            gxdAvg: "0",
            gxdUser: "0",
            wcdAvg: "0",
            wcdUser: "10",
            xxdAvg: "5",
            xxdUser: "297",
          };
          this.initChart();
        } else {
          this.getData();
        }
      });
    }
  },
  methods: {
    // 加载js
    loadScript(callback) {
      this.$http
        .importFiles([[dsf.url.getWebPath("$/js/libs/echarts/echarts.min.js"), ".js"]])
        .then(() => {
          callback?.();
        })
        .catch(() => {
          dsf.layer.message("加载fabric文件报错", false);
          reject();
        });
    },
    getData() {
      if (this.reqUrl) {
        const loading = dsf.layer.loading();
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              this.resData = res.data;
              this.initChart();
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      }
    },
    initChart() {
      let keys = this.keys;
      let sportsIcon = {
        a: require("../../../assets/imgs/aicon.png"),
        b: require("../../../assets/imgs/bicon.png"),
        c: require("../../../assets/imgs/cicon.png"),
        d: require("../../../assets/imgs/dicon.png"),
        e: require("../../../assets/imgs/eicon.png"),
      };
      let mineData = [];
      let avgData = [];
      mineData.push(
        this.resData[keys.xxdUser] || 0,
        this.resData[keys.wcdUser] || 0,
        this.resData[keys.cjdUser] || 0,
        this.resData[keys.gxdUser] || 0,
        this.resData[keys.cydUser] || 0
      );
      avgData.push(this.resData[keys.xxdAvg] || 0, this.resData[keys.wcdAvg] || 0, this.resData[keys.cjdAvg] || 0, this.resData[keys.gxdAvg] || 0, this.resData[keys.cydAvg] || 0);

      const portraitChart = window.echarts.init(this.$refs.drawChart);
      let option = {
        legend: {
          data: ["平均", "我"],
          left: "15px",
          top: "15px",
          orient: "vertical",
        },
        radar: [
          {
            name: {
              textStyle: {
                fontSize: "15",
                color: "#333333",
              },
              formatter: function (params) {
                var str = "";
                switch (params) {
                  case "学习度":
                    str = "{d|}\n" + params + "";
                    break;
                  case "完成度":
                    str = "{c|}\n" + params;
                    break;
                  case "成就度":
                    str = "{b|}\n" + params;
                    break;
                  case "贡献度":
                    str = "{a|}\n" + params;
                    break;
                  case "参与度":
                    str = "{e|}\n" + params;
                    break;
                }
                return str;
              },
              lineHeight: 30,
              rich: {
                a: {
                  color: "red",
                  width: 20,
                  height: 26,
                  align: "center",
                  backgroundColor: {
                    image: sportsIcon.a,
                  },
                },
                b: {
                  width: 22,
                  height: 22,
                  align: "center",
                  backgroundColor: {
                    image: sportsIcon.b,
                  },
                },
                c: {
                  width: 22,
                  height: 22,
                  align: "center",
                  backgroundColor: {
                    image: sportsIcon.c,
                  },
                },
                d: {
                  width: 24,
                  height: 24,
                  align: "center",
                  backgroundColor: {
                    image: sportsIcon.d,
                  },
                },
                e: {
                  width: 20,
                  height: 20,
                  align: "center",
                  backgroundColor: {
                    image: sportsIcon.e,
                  },
                },
              },
            },
            indicator: [{ name: "学习度" }, { name: "完成度" }, { name: "成就度" }, { name: "贡献度" }, { name: "参与度" }],
            center: ["50%", "59.8%"],
            radius: "50%",
            startAngle: 90,
            splitNumber: 4,

            splitArea: {
              areaStyle: {
                /*color: ['rgba(255, 255, 255, 1)',
                                                        'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)',
                                                        'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],*/
                color: "#fff",
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 4,
              },
            },
            axisLine: {
              lineStyle: {
                color: "#0091FF",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#0091FF",
              },
            },
          },
        ],
        series: [
          {
            name: "雷达图",
            type: "radar",
            symbolSize: 0.01,
            lineStyle: {
              width: 0,
            },
            data: [
              {
                value: avgData,
                name: "平均",
                itemStyle: {
                  normal: {
                    color: "#FF6666",
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#FF6666",
                  },
                },
              },
              {
                value: mineData,
                name: "我",
                itemStyle: {
                  normal: {
                    color: "#39A5FE",
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#39A5FE",
                  },
                },
              },
            ],
          },
        ],
      };
      portraitChart.setOption(option);
    },
  },
});
</script>
<style lang="scss" scoped></style>
