<template>
  <div class="dsf_nc_footer" v-if="isDesign" :style="{ width: itemWidth, margin: margin }">
    <div class="nc-footer-inner">
      <div class="footer-top">
        <div class="footer-top-left">
          <dl>
            <dd v-for="item in faceMapping" :key="item.name">
              <span class="name">{{ item.name }}</span>
              <b class="total">{{ item.total }}</b>
            </dd>
          </dl>
        </div>
        <div class="footer-top-right">
          <div class="footer-top-right-text">
            <span>座机：100-400100400</span>
            <span>地址：四川省成都市</span>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <span class="item" @click="toPage(copyNumberSourceUrl)">沪ICP备18014503号-5</span>
        <span class="item" @click="toPage(policeNumberSourceUrl)">
          <img class="police-icon-new" v-if="data.policeInternetImgOne" :src="JSON.parse(data.policeInternetImgOne || '{}').relativePath" />
          <i v-else class="polic-icon"></i>
          沪公网安备 31010602006053号
        </span>
        <span class="item" @click="toPage(levNumberSourceUrl)">等级备案号：31010643029-21001</span>
      </div>
    </div>
  </div>
  <div class="dsf_nc_footer" v-else :style="{ width: itemWidth, margin: margin }">
    <div class="nc-footer-inner" v-if="data">
      <div class="footer-top">
        <div class="footer-top-left">
          <dl>
            <dd v-for="item in renderFaceInfo" :key="item.name">
              <span class="name">{{ item.name }}</span>
              <b class="total">{{ item.total }}</b>
            </dd>
          </dl>
        </div>
        <div class="footer-top-right">
          <div class="footer-top-right-text">
            <span v-if="data.companyPhone">座机：{{ data.companyPhone }}</span>
            <span v-if="data.addressOne">地址：{{ data.addressOne }}</span>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <span class="item" :key="data.internetNumOne" @click="toPage(copyNumberSourceUrl)" v-if="data.internetNumOne && data.internetNumOne != ''">
          {{ data.internetNumOne }}
        </span>
        <span class="item" :key="data.policeInternetNumOne" @click="toPage(policeNumberSourceUrl)" v-if="data.policeInternetNumOne && data.policeInternetNumOne != ''">
          <img class="police-icon-new" v-if="data.policeInternetImgOne" :src="JSON.parse(data.policeInternetImgOne || '{}').relativePath" />
          <i v-else class="polic-icon"></i>
          {{ data.policeInternetNumOne }}
        </span>
        <span class="item" :key="data.levelInternetNumOne" @click="toPage(levNumberSourceUrl)" v-if="data.levelInternetNumOne && data.levelInternetNumOne != ''">
          等级备案号：{{ data.levelInternetNumOne }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcFooterOne",
  ctrlCaption: "门户页脚1",
  mixins: [$mixins.control],
  props: {
    itemWidth: {
      type: String,
      default: "100%",
    },
    margin: {
      type: String,
      default: "20px 0 0 0",
    },
    sourceUrl: {
      type: String,
      default: "nc/saas/count/home",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    copyNumberSourceUrl: {
      type: String,
      default: "http://icp.chinaz.com/home/info?host=xuexibang.cn",
    },
    policeNumberSourceUrl: {
      type: String,
      default: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006053",
    },
    levNumberSourceUrl: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      faceMapping: {
        resourceNum: {
          name: "资源总量",
          total: 0,
        },
        todayView: {
          name: "今日点击访问量",
          total: 0,
        },
        totalView: {
          name: "历史点击访问量",
          total: 0,
        },
      },
      faceInfo: {},
    };
  },
  computed: {
    renderFaceInfo() {
      let arr = [];
      if (this.data.dataSource && dsf.isArray(this.data.dataSource)) {
        this.data.dataSource.forEach(source => {
          this.faceMapping[source];
          let info = {};
          info.name = this.faceMapping[source].name;
          info.total = this.faceInfo[source];
          arr.push(info);
        });
      }
      return arr;
    },
  },
  created() {},
  mounted() {
    !this.isDesign && this.initFooter();
  },
  methods: {
    toPage(it) {
      it && window.open(it);
    },
    initFooter() {
      const { sourceUrl } = this;
      if (!this.data) {
        this.data = {};
      }
      dsf.http
        .get(sourceUrl, { unitId: this.queryString.unitId }, $$webRoot.nc)
        .done(({ message, success, data }) => {
          if (!success) {
            error(message);
          } else {
            this.faceInfo = data;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {});
      function error(message) {
        dsf.layer.pc.message(message, false);
      }
    },
  },
});
</script>
