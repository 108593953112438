var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-login" }, [
    _c(
      "div",
      { staticClass: "login-box-info margin-top" },
      [
        _c("dsf-icon", { attrs: { name: "icon_zhanghao" } }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.loginForm.username,
              expression: "loginForm.username",
            },
          ],
          attrs: {
            type: "text",
            placeholder: _vm.nameHolder,
            autocomplete: "new-password",
          },
          domProps: { value: _vm.loginForm.username },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.loginForm, "username", $event.target.value)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "login-box-info" },
      [
        _c("dsf-icon", { attrs: { name: "suozi" } }),
        !_vm.showPwd
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.loginForm.password,
                  expression: "loginForm.password",
                },
              ],
              attrs: {
                type: "password",
                placeholder: _vm.pwdHolder,
                autocomplete: "new-password",
              },
              domProps: { value: _vm.loginForm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.loginForm, "password", $event.target.value)
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.loginForm.password,
                  expression: "loginForm.password",
                },
              ],
              attrs: {
                type: "text",
                placeholder: _vm.pwdHolder,
                autocomplete: "new-password",
              },
              domProps: { value: _vm.loginForm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.loginForm, "password", $event.target.value)
                },
              },
            }),
        !_vm.showPwd
          ? _c("dsf-icon", {
              staticClass: "show-pwd",
              attrs: { name: "biyan" },
              on: { click: _vm.changePwdType },
            })
          : _c("dsf-icon", {
              staticClass: "show-pwd",
              attrs: { name: "yanjing1" },
              on: { click: _vm.changePwdType },
            }),
      ],
      1
    ),
    _c("div", { staticClass: "login-box-info login-code" }, [
      _c(
        "div",
        { staticClass: "login-code-wrap" },
        [
          _c("dsf-icon", { attrs: { name: "yanzhengma1" } }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.loginForm.code,
                expression: "loginForm.code",
              },
            ],
            attrs: { type: "text", placeholder: _vm.codeHolder },
            domProps: { value: _vm.loginForm.code },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.loginForm, "code", $event.target.value)
              },
            },
          }),
        ],
        1
      ),
      _vm.imgCode
        ? _c("img", {
            staticClass: "img-code",
            attrs: { src: _vm.imgCode, alt: "" },
            on: {
              click: function ($event) {
                return _vm.getImgCode()
              },
            },
          })
        : _vm._e(),
    ]),
    _c("div", { staticClass: "login-box-handle" }, [
      _c(
        "div",
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.setRemindState },
              model: {
                value: _vm.loginForm.checked,
                callback: function ($$v) {
                  _vm.$set(_vm.loginForm, "checked", $$v)
                },
                expression: "loginForm.checked",
              },
            },
            [_vm._v("记住密码")]
          ),
        ],
        1
      ),
      _c("div", { on: { click: _vm.toChangePwd } }, [_vm._v("忘记密码")]),
      _vm.dsf.config.setting_nc_reg_is_register == 1
        ? _c("div", { on: { click: _vm.toRegister } }, [_vm._m(0)])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "login-box-button" },
      [
        _c(
          "el-button",
          {
            attrs: {
              loading: _vm.loading,
              type: "info",
              "native-type": "submit",
              size: "small",
            },
            on: { click: _vm.loginHandle },
          },
          [_vm._v("登 录")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint" }, [
      _vm._v(" 没有账号？ "),
      _c("span", { staticClass: "pointer" }, [_vm._v("去注册")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }