<template>
  <div class="dsf-dataGrid-child-test">
    <el-checkbox v-if="$list.checkboxColumn.show" v-model="isSelected"></el-checkbox>
    <img src="https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=134348695,4168793225&fm=26&gp=0.jpg" alt="">
    <div class="username">{{ getVal('ds_create_user_name') }}</div>
    <div>年龄：24</div>
    <div>职级：司令</div>
    <div class="btns">
      <div class="box">
        <DsfButtonBar
          class="ds-no-padding"
          :max-number="$list.rowButtonsMaxNumber"
          :more-button-text="$list.rowButtonsMoreText"
          :more-button-style="$list.rowButtonsMoreStyle"
          :buttons="getRowButtons()"
          :local="local"
          emit-prefix="row-button"
          size="small"
          align="center">
        </DsfButtonBar>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfDataGridChildTest",
  ctrlCaption: "数据列表测试子项",
  mixins: [$mixins.control, $mixins.dataGridChildControl],
  props: {}
});
</script>