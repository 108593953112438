<template>
  <div class="dsf_nc_saas_page_design page_index" :class="{ isDesign: isDesign }">
    <div class="design_content" v-for="(component, index) in pageList" :key="index">
      <component :is="component.type" :data="component"></component>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcSaasPagePreview",
  ctrlCaption: "首页组件",
  mixins: [$mixins.control],
  props: {
    bindURL: {
      type: String,
      default: "",
    },
    pageList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      pageName: "",
      components: [],
    };
  },
  mounted() {
    this.getComponentsJson();
  },
  methods: {
    getComponentsJson() {
      const loading = dsf.layer.loading();
      dsf.http
        .get(
          "meta/list/data",
          {
            pageNum: 1,
            pageSize: 15,
            query: '{"searchValue":""}',
            order: "[]",
            filter: "[]",
            namespace: "nc.saas.setting.component",
            pageName: "list",
          },
          $$webRoot.nc
        )
        .done(({ success, data, message }) => {
          if (success) {
            this.components = JSON.parse(data[0]["nc_saas_setting_component.component_json"]);
            this.getPageData();
          }
        })
        .error(err => {
          dsf.layer.pc.message("获取数据异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    //获取页面楼层信息
    getPageData() {
      if (location.href.indexOf(dsf.config.setting_public_pcindex_url) != -1 || this.isDesign) {
        return false;
      } else {
        this.pageList = JSON.parse(localStorage.getItem("previewDesign") || "[]");
      }
    },
  },
});
</script>
