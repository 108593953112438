<template>
  <div class="dsf_nc_assessment" :class="getCss">
    <el-form ref="form" :model="formModel" label-width="80px">
      <el-form-item v-if="conditions" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">考核条件</div>
          <div class="font">{{ conditionsLabel }}</div>
        </span>
        <div class="conditions_content">
          <el-form-item label="满足学时" class="dsf_item_form">
            选修
            <el-input
              v-model="formModel.electivesPeriod"
              @change="eletivesChange(formModel.electivesPeriod, periodData.electivesPeriod)"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
            ></el-input>
            <span>学时</span>
            <span>/{{ periodData.electivesPeriod || 0 }}学时&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;必修 {{ periodData.compulsoryPeriod || 0 }}/{{ periodData.compulsoryPeriod || 0 }}学时</span>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item v-if="exam" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">考试</div>
          <div class="font">{{ examTitleLabel }}</div>
        </span>
        <div class="conditions_content">
          <DsfDataChoice
            v-model="examlists"
            :autoComplete="examAutoComplete"
            :metaDataType="examMetaDataType"
            :chooseMetaData="examChooseMetaData"
            :source="examSource"
            :maxItemsNumber="examMaxItemsNumber"
            :label="examLabel"
            :showLabel="examShowLabel"
            :textKey="examTextKey"
            :valueKey="examValueKey"
            :dialogUrl="examDialogUrl"
            :dialogTextKey="examDialogTextKey"
            :dialogValueKey="examDialogValueKey"
            :dialogTitle="examDialogSetting.split(',')[2]"
            :dialogWidth="examDialogSetting.split(',')[0]"
            :dialogHeight="examDialogSetting.split(',')[1]"
            @change="changeExamDataChoice"
          />
          <el-form-item label="奖励学时:" class="dsf_item_form">
            <el-input
              v-model="formModel.examHurs"
              @change="handleChangevalue(formModel.examHurs, 'examHurs', 'formModel')"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
            />
            学时
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item v-if="questionnaire" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">问卷</div>
          <div class="font">{{ questTitleLabel }}</div>
        </span>
        <div class="conditions_content">
          <DsfDataChoice
            v-model="questlists"
            :autoComplete="questAutoComplete"
            :metaDataType="questMetaDataType"
            :chooseMetaData="questChooseMetaData"
            :source="questSource"
            :maxItemsNumber="questMaxItemsNumber"
            :label="questLabel"
            :showLabel="questShowLabel"
            :textKey="questTextKey"
            :valueKey="questValueKey"
            :dialogUrl="questDialogUrl"
            :dialogTextKey="questDialogTextKey"
            :dialogValueKey="questDialogValueKey"
            :dialogTitle="questDialogSetting.split(',')[2]"
            :dialogWidth="questDialogSetting.split(',')[0]"
            :dialogHeight="questDialogSetting.split(',')[1]"
            @change="changeQuestDataChoice"
          />
          <el-form-item label="奖励学时" class="dsf_item_form">
            <el-input
              v-model="formModel.evaluatePeriod"
              @change="handleChangevalue(formModel.evaluatePeriod, 'evaluatePeriod', 'formModel')"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
            />
            学时
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 作业 -->
      <el-form-item v-if="homework" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">作业</div>
          <div class="font">{{ homeworkTitleLabel }}</div>
          <el-switch v-model="switchStatus"></el-switch>
        </span>
        <div class="" v-if="switchStatus">
          <el-form-item label="作业描述" class="dsf_item_form">
            <el-input class="dsf_input" v-model="homeworkForm.homeworkName" />
          </el-form-item>
          <el-form-item label="作业说明" class="dsf_item_form">
            <el-input type="textarea" class="dsf_input" :rows="5" :resize="'none'" v-model="homeworkForm.homeworkRequirements"></el-input>
          </el-form-item>
          <el-form-item label="成绩类型" class="dsf_item_form">
            <el-select class="dsf_input" v-model="homeworkForm.homeworkScoreTypeText" placeholder="下拉框，等级制、分数制">
              <el-option v-for="item in scoreType" :key="item.value" :label="item.text" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="奖励学时" class="dsf_item_form">
            <el-input
              v-model="homeworkForm.homeworkPeriod"
              @change="handleChangevalue(homeworkForm.homeworkPeriod, 'homeworkPeriod', 'homeworkForm')"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
            />
            学时
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item v-if="exam" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">证书</div>
          <div class="font">{{ certificateTitleLabel }}</div>
        </span>
        <div class="conditions_content">
          <DsfDataChoice
            v-model="certificateLists"
            :autoComplete="certificateAutoComplete"
            :metaDataType="certificateMetaDataType"
            :chooseMetaData="certificateChooseMetaData"
            :source="certificateSource"
            :maxItemsNumber="certificateMaxItemsNumber"
            :label="certificateLabel"
            :showLabel="certificateShowLabel"
            :textKey="certificateTextKey"
            :valueKey="certificateValueKey"
            :dialogUrl="certificateDialogUrl"
            :dialogTextKey="certificateDialogTextKey"
            :dialogValueKey="certificateDialogValueKey"
            :dialogTitle="certificateDialogSetting.split(',')[2]"
            :dialogWidth="certificateDialogSetting.split(',')[0]"
            :dialogHeight="certificateDialogSetting.split(',')[1]"
            @change="changeCertificateDataChoice"
          />
        </div>
      </el-form-item>
      <el-form-item class="dsf_btns">
        <DsfButtonBar :buttons="buttons" :max-number="maxNumber" @save="onSubmit" @close="onClose" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default dsf.component({
  name: "DsfNcAssessment",
  ctrlCaption: "考核设置",
  mixins: [$mixins.control, $mixins.buttonBar],
  props: {
    showColorLump: {
      type: Boolean,
      default: true,
    },
    showWeight: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default() {
        return [
          {
            actionName: "save",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-baocun",
            id: "Ld3EJ3ytFxJpgA3Q",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "保存",
            title: "",
          },
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-guanbi2",
            id: "BeIBdsm19uqcoaVc",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "关闭",
            title: "",
          },
        ];
      },
    },
    maxNumber: {
      type: Number,
      default: 3,
    },
    //考核条件
    conditions: {
      type: Boolean,
      default: true,
    },
    conditionsLabel: {
      type: String,
      default: "",
    },
    // 考试
    exam: {
      type: Boolean,
      default: true,
    },
    examLabel: {
      type: String,
      default: "考试名称",
    },
    examShowLabel: {
      type: Boolean,
      default: true,
    },
    examTitleLabel: {
      type: String,
      default: "",
    },
    examDialogUrl: {
      type: String,
      default: "#/pc/nc/exam/exampaperlist", // #/pc/nc/teacher/selectTeacher
    },
    examDialogSetting: {
      type: String,
      default: "800px,500px,请选择",
    },
    examDialogTextKey: {
      type: String,
      default: "",
    },
    examDialogValueKey: {
      type: String,
      default: "",
    },
    examAutoComplete: {
      type: Boolean,
      default: false,
    },
    examMetaDataType: {
      type: String,
      default: "0",
    },
    examChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },
    examSource: {
      type: String,
      default: "", // /nc/teacher/search/name?
    },
    examMaxItemsNumber: {
      type: Number,
      default: 20,
    },
    examTextKey: {
      type: String,
      default: "text",
    },
    examValueKey: {
      type: String,
      default: "value",
    },
    //问卷
    questionnaire: {
      type: Boolean,
      default: false,
    },
    questTitleLabel: {
      type: String,
      default: "",
    },
    questLabel: {
      type: String,
      default: "评估模板",
    },
    questShowLabel: {
      type: Boolean,
      default: true,
    },
    questDialogUrl: {
      type: String,
      default: "#/pc/nc/exam/exampaperlist", //#/pc/nc/teacher/selectTeacher
    },
    questDialogSetting: {
      type: String,
      default: "800px,500px,请选择",
    },
    questDialogTextKey: {
      type: String,
      default: "",
    },
    questDialogValueKey: {
      type: String,
      default: "",
    },
    questAutoComplete: {
      type: Boolean,
      default: false,
    },
    questMetaDataType: {
      type: String,
      default: "0",
    },
    questChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },
    questSource: {
      type: String,
      default: "", ///nc/teacher/search/name?
    },
    questMaxItemsNumber: {
      type: Number,
      default: 20,
    },
    questTextKey: {
      type: String,
      default: "text",
    },
    // 作业
    homework: {
      type: Boolean,
      default: true,
    },
    homeworkTitleLabel: {
      type: String,
      default: "",
    },
    // 证书
    certificate: {
      type: Boolean,
      default: true,
    },
    certificateTitleLabel: {
      type: String,
      default: "",
    },
    certificateLabel: {
      type: String,
      default: "证书名称",
    },
    certificateShowLabel: {
      type: Boolean,
      default: true,
    },
    certificateDialogUrl: {
      type: String,
      default: "#/pc/nc/exam/exampaperlist", //#/pc/nc/teacher/selectTeacher
    },
    certificateDialogSetting: {
      type: String,
      default: "800px,500px,请选择",
    },
    certificateDialogTextKey: {
      type: String,
      default: "",
    },
    certificateDialogValueKey: {
      type: String,
      default: "",
    },
    certificateAutoComplete: {
      type: Boolean,
      default: false,
    },
    certificateMetaDataType: {
      type: String,
      default: "0",
    },
    certificateChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },
    certificateSource: {
      type: String,
      default: "", //nc/teacher/search/name?/
    },
    certificateMaxItemsNumber: {
      type: Number,
      default: 20,
    },
    certificateTextKey: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      examlists: [], // 考试
      questlists: [], // 问卷
      certificateLists: [], //证书
      checkList: [], //获证书条件
      periodData: {},
      id: this.$route.query.id || "0e3eb4580a8744e7b2b37b4aa0680c62",
      formModel: {
        ncPackId: this.$route.query.id || "0e3eb4580a8744e7b2b37b4aa0680c62",
        electivesPeriod: "", //(选修学时_文本)
        compulsoryPeriod: "", //必修学时_文本
        examHurs: "", //考试奖励学时
        examValue: "", //试卷_值
        examText: "", //试卷_文本
        evaluatePeriod: "", // (评估学时_文本)
        evaluateValue: "", // (评估_值)
        evaluateText: "", // (评估_文本)
        certificateValue: "", // (证书_值)
        certificateText: "", // (证书_文本)
        enableEvaluate: 0, //  (开启评估_文本: 1:开启， 0：关闭)
        enableExam: 0, // (开启考试_文本: 1:开启， 0：关闭)
        enableCertificate: 0, // (开启证书_文本: 1:开启， 0：关闭)
        homeworkId: "", // 作业id
        homeworkName: "", //作业标题
      },
      homeworkForm: {
        homeworkId: "", // 作业id
        homeworkName: "", //作业标题
        homeworkPeriod: "", //作业学时
        homeworkRequirements: "", //作业要求
        homeworkScoreTypeText: "", //作业打分类文本
        homeworkScoreTypeValue: "", //作业打分类型值,
      },
      // 作业
      scoreType: [
        { text: "等级制", value: "1" },
        { text: "分数制", value: "2" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getHomeWork"]),
    getCss() {
      const $class = this.$getClass();
      $class.push(this.showColorLump ? "ds-showColorLump" : "", this.showWeight ? "ds_bold" : "");
      return $class;
    },
    switchStatus() {
      // 作业开关
      return this.formModel.homeworkEnabled == 1 || false;
    },
  },
  mounted() {
    if (!this.isDesign) {
      this.assessmentInfo(this.id);
      this.initPeriod(); // 查学时
      this.initData(this.getHomeWork); // 查数据
    }
  },
  methods: {
    ...mapActions(["assessmentInfo"]),
    // 获取默认数据
    initData(data) {
      // let loading = dsf.layer.pc.loading();
      // let id = this.id;
      // dsf.http
      //   .get("/nc/class/assessment/info", { id }, $$webRoot.nc)
      //   .done(res => {
      //     if (!res.success) {
      //       this.error(res);
      //     } else {
      // let { data } = res;
      this.formModel = Object.assign({}, data);
      this.homeworkForm = {
        homeworkId: this.formModel.homeworkId, // 作业id
        homeworkName: this.formModel.homeworkName, //作业标题
        homeworkPeriod: this.formModel.homeworkPeriod, //作业学时
        homeworkRequirements: this.formModel.homeworkRequirements, //作业要求
        homeworkScoreTypeText: this.formModel.homeworkScoreTypeText, //作业打分类文本
        homeworkScoreTypeValue: this.formModel.homeworkScoreTypeValue, //作业打分类型值,
        homeworkEnabled: this.formModel.homeworkEnabled, // 0不启用1启用
      };

      this.formModel.ncPackId = this.formModel.ncPackId || this.id;
      // 获取证书条件 多选框默认选中
      if (this.formModel.enableEvaluate == 1) {
        this.checkList.push("enableEvaluate");
      }
      if (this.formModel.enableExam == 1) {
        this.checkList.push("enableExam");
      }
      if (this.formModel.enableCertificate == 1) {
        this.checkList.push("enableCertificate");
      }

      /*
              考试名称
              examValue: "", //试卷_值
              examText: "", //试卷_文本
            */
      let examValueArr = this.formModel.examValue ? this.formModel.examValue.split(",") : "";
      let examTextArr = this.formModel.examText ? this.formModel.examText.split(",") : "";
      for (var i = 0; i < examValueArr.length; i++) {
        var obj = {
          value: examValueArr[i],
          text: examTextArr[i],
        };
        this.examlists.push(obj);
      }
      /*
              评估模板
              evaluateValue: "", // (评估_值)
              evaluateText: "", // (评估_文本)
            */
      let evaluateValueArr = this.formModel.evaluateValue ? this.formModel.evaluateValue.split(",") : "";
      let evaluateTextArr = this.formModel.evaluateText ? this.formModel.evaluateText.split(",") : "";
      for (var i = 0; i < evaluateValueArr.length; i++) {
        var obj = {
          value: evaluateValueArr[i],
          text: evaluateTextArr[i],
        };
        this.questlists.push(obj);
      }
      /*
              证书名称
              certificateValue: "", // (证书_值)
              certificateText: "", // (证书_文本)
            */
      let certificateValueArr = this.formModel.certificateValue ? this.formModel.certificateValue.split(",") : "";
      let certificateTextArr = this.formModel.certificateText ? this.formModel.certificateText.split(",") : "";
      for (var i = 0; i < certificateValueArr.length; i++) {
        var obj = {
          value: certificateValueArr[i],
          text: certificateTextArr[i],
        };
        this.certificateLists.push(obj);
      }
      //   }
      // })
      // .error(err => {
      //   this.error(err);
      // })
      // .always(res => {
      //   dsf.layer.pc.closeLoading(loading);
      // });
    },
    // 查学时
    initPeriod() {
      let loading = dsf.layer.pc.loading();
      let id = this.id;
      dsf.http
        .get("/nc/pack/unit/period", { id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.periodData = data;
            this.formModel.compulsoryPeriod = data.compulsoryPeriod ? Number(data.compulsoryPeriod) : 0;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    // 考核条件
    eletivesChange(val, item) {
      if (/^\d\.\d+?$/.test(val)) {
        val = Number(val).toFixed(2);
      }
      // console.log(val, "考核条件==》");
      if (Number(val) < Number(item) || Number(val) == Number(item)) {
        this.formModel.electivesPeriod = val;
      } else {
        this.formModel.electivesPeriod = item || 0;
      }

      if (val.includes(".")) {
        return (this.formModel.electivesPeriod = Number(val).toFixed(2));
      }
    },
    // 数据改变 强制保留小数点后两位
    handleChangevalue(value, key, formName) {
      if (value.includes(".")) {
        switch (formName) {
          case "formModel":
            return (this.formModel[key] = Number(value).toFixed(2));
          case "homeworkForm":
            return (this.homeworkForm[key] = Number(value).toFixed(2));
        }
        // return (this.formModel[key] = Number(value).toFixed(2));
      }
    },
    changeExamDataChoice(item) {
      // dsfDataChoice组件 考试值改变时候
      this.formModel.examValue = "";
      this.formModel.examText = "";
      this.change(item.args, "exam");
    },
    changeQuestDataChoice(item) {
      // dsfDataChoice组件 问卷值改变时候
      this.formModel.evaluateValue = "";
      this.formModel.evaluateText = "";
      this.change(item.args, "quest");
    },
    changeCertificateDataChoice(item) {
      // dsfDataChoice组件 证书值改变时候
      this.formModel.certificateValue = "";
      this.formModel.certificateText = "";
      this.change(item.args, "certificate");
    },
    change(array, type) {
      array.forEach(element => {
        switch (type) {
          case "exam":
            this.formModel.examValue = element.value;
            this.formModel.examText = element.text;
            break;
          case "quest":
            this.formModel.evaluateValue = element.value;
            this.formModel.evaluateText = element.text;
            break;
          case "certificate":
            this.formModel.certificateValue = element.value;
            this.formModel.certificateText = element.text;
            break;
          default:
            break;
        }
      });
    },
    handleCheckedChange(value) {
      this.formModel.enableEvaluate = value.includes("enableEvaluate") ? 1 : 0;
      this.formModel.enableCertificate = value.includes("enableCertificate") ? 1 : 0;
      this.formModel.enableExam = value.includes("enableExam") ? 1 : 0;
    },
    homeworkSave(sourceUrl, params, succFn, failFun) {
      const loading = dsf.layer.pc.loading("提交中...");
      let headers = {
        "Content-Type": "application/json",
      };
      dsf.http
        .post(sourceUrl, params, { headers }, $$webRoot.nc)
        .done(res => {
          res.success && succFn && succFn(res);
          if (!res.success) error(res);
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        failFun && failFun(err);
        dsf.layer.pc.message(err.message, false);
      }
    },
    onSubmit() {
      let headers = {
        "Content-Type": "application/json",
      };

      if (this.homework) {
        // 配置了有作业时候
        let scoreType = this.scoreType.filter(item => item.value == this.homeworkForm.homeworkScoreTypeText)[0];
        this.homeworkForm.homeworkScoreTypeValue = scoreType ? scoreType.text : "";
        const homeworkUrl = "/nc/homework/assessment";
        this.homeworkSave(
          homeworkUrl,
          JSON.stringify(this.homeworkForm),
          res => {
            let { data } = res;
            let homework = {
              homeworkId: data.nc_homework_id,
              homeworkName: data.name,
            };
            this.formModel = Object.assign(this.formModel, this.homeworkForm, homework);

            this.assessmentSave();
          },
          err => {
            dsf.layer.pc.message(err.message, false);
          }
        );
      } else {
        // 没有配置作业模块
        this.assessmentSave();
      }
    },
    assessmentSave() {
      let headers = {
        "Content-Type": "application/json",
      };
      let loading = dsf.layer.pc.loading();

      dsf.http
        .post(
          "/nc/class/assessment/save",
          JSON.stringify(this.formModel),
          {
            headers,
          },
          $$webRoot.nc
        )
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            dsf.layer.pc.message("保存成功", true);
            this.$emit("submit_succ", data);
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    onClose() {
      window.close();
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
