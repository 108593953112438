<template>
  <div class="dsf_liveDetail" v-if="visible">
    <div class="live-top">
      <div class="live-top-inner" id="LiveHeader">
        <div class="live-top-inner-l ell">
          <button class="live-btn live-btn-playback" :class="state.value == 0 ? 'live-btn-appoint' : 'live-btn-playback'">
            {{ state.value == 1 ? "正在直播" : state.value == 0 ? "未开始" : "直播回放" }}
          </button>
          <span class="live-title" :title="liveInfo.title">
            <span>{{ liveInfo.title || "暂无标题" }}</span>
            <div class="header-rate" v-if="assessId">
              <el-rate v-model="liveRate" :max="5" disabled show-score text-color="#ff9900" allow-half></el-rate>
              <span>分</span>
            </div>
          </span>
        </div>
        <div class="live-top-inner-r">
          <div @click="handleComment()" class="pingjia" v-if="state.value == 1">
            <div>
              <i class="iconfont icon-pingjia1"></i>
            </div>
            <span>评价</span>
          </div>
          <!-- <p @click="handleExam" class="pingjia" v-if="state.value == 1">
            <i class="iconfont icon-icon_exam"></i>
            考核
          </p> -->
          <!--<p @click="handleQues" class="pingjia" v-if="state.value == 1">
            <i class="iconfont icon-icon_article"></i>
            问卷
          </p> -->

          <div class="dingyue">
            <dsf-icon class="icon-yanjing1"></dsf-icon>
            <span>{{ views || 0 }}</span>
            <button v-if="state.value == 0" class="live-btn live-btn-handle-appoint" @click="liveAppointHandle">
              {{ !subscribe ? "订阅" : "取消订阅" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="live-content">
      <div class="live-box" :style="{ width: conWidth, height: playerHeight }" v-if="getMask && isLook == '1'">
        <div class="live-cover img-box">
          <div class="pointer">
            <!-- <el-image :src="liveInfo.cover | imgFormat" fit="cover"></el-image> -->
            <dsf-nc-image :src="liveInfo.cover | imgFormat" :error-src="defaultSrc" />
            <div class="live-time-wrap">
              <div class="live-time-inner" @click="checkLogin">
                <p class="p1">{{ state.value === 2 || state.value === 3 ? "回放中" : "直播中" }}</p>
                <p class="p2">{{ state.value === 2 || state.value === 3 ? "点击进入回放" : "点击进入直播" }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="live-box" :style="{ width: conWidth, height: playerHeight }" v-else>
        <DsfNcLivePlayer
          v-if="getLivePlayer"
          playerWidth="100%"
          :isDesign="isDesign"
          :ckplayerWarmUp="ckplayerWarmUp"
          :playerHeight="playerHeight"
          :channelId="liveInfo.channelValue"
          :liveInfo="liveData"
          @liveEnd="liveEnd"
          :enableAsk="enableAsk"
          :userPic="userPic"
        ></DsfNcLivePlayer>
        <DsfNcCkplayer
          v-if="videoShow"
          ref="coursePlayer"
          width="100%"
          :ctrl_tab_stop="altTabStop"
          :hint="false"
          :height="playerHeight"
          :playerParams="playerParams"
          :videoSource="videoSource"
          :videoStatus="false"
          @playerPlayingTime="playerPlayingTime"
          @playerHasPlay="playerHasPlay"
          @playerHasPause="playerHasPause"
          @playHasEnd="playHasEnd"
        />
        <div class="live-cover img-box" v-if="state.value == 0">
          <el-image :src="liveInfo.cover | imgFormat" fit="cover"></el-image>
          <div class="live-time-wrap">
            <div class="live-time-inner">
              <p class="p1">{{ liveInfo.stateValue == 0 ? "该直播间被停用" : "未开始" }}</p>
              <p class="p2">开播时间: {{ !!liveInfo.timeSdate ? liveInfo.timeSdate : "开播时间未定" }}</p>
            </div>
          </div>
        </div>
        <div class="live-cover img-box" v-if="getLiveState">
          <div v-if="liveInfo.stateValue == 0">
            <el-image :src="liveInfo.cover | imgFormat" fit="cover"></el-image>
            <div class="live-time-wrap">
              <div class="live-time-inner">
                <p class="p1">该直播间被停用</p>
                <p class="p2">直播时间: {{ liveInfo.timeSdate || "" }}</p>
              </div>
            </div>
          </div>
          <div class="pointer" v-else-if="liveInfo.typeValue == 2 && state.value !== 0 && liveInfo.stateValue != 0">
            <!-- <el-image :src="liveInfo.cover | imgFormat" fit="cover"></el-image> -->
            <dsf-nc-image :src="liveInfo.cover | imgFormat" :error-src="defaultSrc" />
            <div class="live-time-wrap">
              <div class="live-time-inner" @click="goLiveLink">
                <p class="p1">{{ state.value === 2 || state.value === 3 ? "回放中" : "直播中" }}</p>
                <p class="p2">{{ state.value === 2 || state.value === 3 ? "点击进入回放" : "点击进入直播" }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            <!-- <el-image :src="liveInfo.cover | imgFormat" fit="cover"></el-image> -->
            <dsf-nc-image :src="liveInfo.cover | imgFormat" :error-src="defaultSrc" />
            <div class="live-time-wrap">
              <div class="live-time-inner">
                <p class="p1">直播结束</p>
                <p class="p2">直播时间: {{ liveInfo.timeSdate || "" }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-comment-warp">
        <div class="d-comment">
          <ul class="d-comment-handle">
            <li @click="handleClickTag" :class="{ isred: tag }">
              <dsf-icon class="pointer" :class="[tag ? 'icon-dianzan1' : 'icon-dianzan']"></dsf-icon>
              <span id="LiveDz" class="live-dz" :title="!tag ? '点赞' : '取消点赞'">{{ tagTotal }}</span>
            </li>
            <li @click="handleClickCollect" :class="{ isred: collect }">
              <dsf-icon class="pointer" :class="[collect ? 'icon-aixin_shixin' : 'icon-shoucang2 ']"></dsf-icon>
              <span id="LiveSc" class="live-sc" :title="!collect ? '收藏' : '取消收藏'">{{ collectTotal }}</span>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="con-tab-head-wrap">
        <ul class="con-tabs" :style="{ width: conWidth }">
          <template v-for="(item, index) in tabs">
            <li :class="{ tabHasSel: activeName == item.id }" :key="index" @click="handleClickTab(item)" v-if="item.show">
              <span>{{ item.name }}</span>
            </li>
          </template>
        </ul>
        <div class="tabs-content" :style="{ width: conWidth }">
          <div class="introduction" v-if="activeName == 1">
            <div class="introduction-left">
              <h3 class="course-tab-title">直播简介</h3>
              <div class="course-desc-warp">
                <div class="live-info live-info-bottom">
                  <div class="live-info-inner">
                    <div class="live-desc" id="LiveIntroduction">
                      <div class="live-info-sub-title">
                        <p class="live-s-title">{{ liveInfo.title }}</p>
                        <p class="live-s-time">
                          开播时间：{{ formatterDate(liveInfo.timeSdate) }}
                          <span style="margin: 0 2px">-</span>
                          {{ dsf.date.format(liveInfo.timeEdate, "yyyy-mm-dd hh:ii") }}
                        </p>
                      </div>
                      <p class="live-text" v-html="liveInfo.content"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="introduction-right">
              <DsfNcLiveInformation></DsfNcLiveInformation>
            </div>
          </div>
          <!-- 考核 -->

          <div class="check-box" v-if="activeName == 3">
            <h3 class="course-tab-title" v-if="checkObj.hasHomeWork == '1' || checkObj.hasExam == '1'">
              直播考核
              <span>完成直播学习后方可参与以下考核</span>
            </h3>
            <div class="check-items">
              <div class="check-item pointer" :class="{ noClick: !finishedRate }" @click="handleClickMore('1', checkObj.exam)" v-if="checkObj.hasExam == 1">
                <div class="successCheck" v-if="checkObj.exam.state_value == '1'"></div>
                <dsf-icon class="icon-kaoshi"></dsf-icon>
                <p class="item-title">直播考试</p>
                <p class="item-detail">
                  【{{ checkObj.exam.exam_necessary_value == 1 ? "必修" : "选修" }}】
                  <span class="type">{{ checkObj.exam.state_value == 1 ? "通过: " + Number(checkObj.exam.score || 0) + "分" : "待完成" }}</span>
                </p>
              </div>
              <div class="check-item pointer" :class="{ noClick: !finishedRate }" @click="handleClickMore('2', checkObj.homeWork)" v-if="checkObj.hasHomeWork == '1'">
                <div class="successCheck" v-if="checkObj.homeWork.state_value == '1'"></div>
                <dsf-icon class="icon-lianxi"></dsf-icon>
                <p class="item-title">直播作业</p>
                <!-- {{ checkObj.homeWork.homeWorkName }} -->
                <p class="item-detail">
                  【{{ checkObj.homeWork.homework_necessary_value == 1 ? "必修" : "选修" }}】
                  <!--  + checkObj.homeWork.score + "分"  -->
                  <span class="type">{{ checkObj.homeWork.state_value == "1" ? "通过" : checkObj.homeWork.state_text }}</span>
                </p>
              </div>

              <!-- <div class="check-item pointer" :class="{ noClick: !finishedRate }" @click="handleClickMore('3')">
                <dsf-icon class="icon-wenjuan"></dsf-icon>
                <p class="item-title">调查问卷</p>
                <p class="item-detail">
                  【选填】
                  <span class="type">待完成</span>
                </p>
              </div> -->
            </div>
            <h3 class="course-tab-title" v-if="checkObj.hasCert == '1'">
              直播证书
              <span>通过直播的学习及考核便有资格获得结业证书。</span>
            </h3>
            <div class="certificate" v-if="checkObj.hasCert == '1'">
              <!-- <img :src="checkObj.certificateurl" alt="" /> -->
              <dsf-nc-image :src="(checkObj.cert && checkObj.cert.certificateurl) | imgFormat" :error-src="errorImg" />
              <el-button v-if="checkObj.cert.state_value == 1" @click="download(checkObj.cert)">下载证书</el-button>
            </div>
          </div>

          <div class="note-box">
            <dsf-nc-note-item
              v-if="activeName == 2"
              :relationId="liveId"
              :liveCover="liveCover"
              :relationTitle="liveInfo.title"
              :isLive="true"
              :btnWidth="'100px'"
              :fontColor="'#333'"
            />
          </div>
          <comment v-if="activeName == 3" class="note-box"></comment>
        </div>
      </div>
    </div>
    <div id="isvideo-box" v-if="mutedPlay">
      是否允许浏览器播放声音?
      <span class="ppt-control-volume-cancel isvideo" @click="handleMuted">播放声音</span>
      <span class="ppt-control-volume-close isvideoclose" @click="mutedPlay = false">忽略</span>
    </div>
  </div>
</template>

<script>
import { comment } from "../comment";
export default dsf.component({
  name: "DsfNcLiveDetail",
  ctrlCaption: "直播详情页",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    conWidth: {
      type: String,
      default: "1200px",
    },
    playerHeight: {
      type: String,
      default: "650px",
    },
    ckplayerPause: {
      type: Boolean,
      default: false,
    },
    ckplayerShowProgress: {
      type: Boolean,
      default: true,
    },
    altTabStop: {
      type: Boolean,
      default: true,
    },
    //是否开启课程记录学时
    isCoursePlayerTime: {
      type: Boolean,
      default: true,
    },
    //非直播也记录学时
    notlivePlayerTime: {
      type: Boolean,
      default: true,
    },
    ckplayerWarmUp: {
      type: Boolean,
      default: false,
    },
    enableAsk: {
      type: Boolean,
      default: false,
    },
    userPic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      errorImg: require("../../../assets/imgs/pcimg/live/checkErrorBg.png"),
      sourceUrl: "nc/live/info",
      liveId: "",
      liveInfo: {},
      liveData: {},
      state: {
        value: "5",
        text: "直播预设",
      },
      subscribeTotal: 0,
      views: 0,
      subscribe: false,
      tag: false,
      tagTotal: 0,
      collect: false,
      collectTotal: 0,
      curPlayItem: {},
      activeName: 1,
      tabs: [
        { name: "简介", id: 1, show: true },
        { name: "考核", id: 3, show: true },
        { name: "笔记", id: 2, show: true },
      ],
      mutedPlay: false,
      ckPlayer: null,
      videoSource: "",
      videoShow: false,
      playerParams: {
        playerID: "ncCourseCkplayer",
        container: "#dsfNcCkplayer",
        variable: "player",
        html5m3u8: true,
        loop: false,
        autoplay: false,
        sortPlay: true,
        next: "nextCourse",
        defaultPoster: "", //封面图片
        // seek: seekTime,
        playbackrate: 1,
        drag: "start",
        debug: false,
        hideautoplayBtn: true, //隐藏自动播放按钮
      },
      liveCover: "",
      playTimer: null,
      time: "0",
      plansecond: 0,
      finishedRate: false,
      liveRate: 0,
      assessId: "",
      checkObj: {},
      isLook: "0",
    };
  },
  computed: {
    //保利威视
    getLivePlayer() {
      let flag = false;
      if (this.state.value === 1 || this.state.value === 2 || this.state.value === 3) {
        //直播中或者直播结束 并且是发布状态
        if (this.liveInfo.typeValue === "1" && this.liveInfo.stateValue != 0) {
          //类型是保利直播
          flag = true;
        }
      }
      return flag;
    },

    //获取直播状态
    getLiveState() {
      let flag = false;
      //直播结束
      if (this.state.value === 2 || this.state.value === 3) {
        if (this.liveInfo.stateValue == 0) {
          //停用
          flag = true;
        }
        if (this.liveInfo.typeValue == 2) {
          // 链接
          flag = true;
        }
      } else if (this.state.value === 1) {
        if (this.liveInfo.typeValue == 2) {
          // 链接直播中 不管停用不停用
          flag = true;
        }
        //课件直播.视频直播停用
        if ((this.liveInfo.typeValue == 1 || this.liveInfo.typeValue == 3 || this.liveInfo.typeValue == 4) && this.liveInfo.stateValue == 0) {
          flag = true;
        }
      }
      return flag;
    },
    getMask() {
      return !dsf.getToken() && dsf.getToken() == "";
    },
  },
  components: {
    comment,
  },
  created() {
    this.create();
    this.liveId = this.queryString["id"] || "";
  },
  mounted() {
    this.getLiveInfo();
    this.getCheck();
    var that = this;
  },
  beforeDestroy() {
    this.clearPlayInterval();
  },
  methods: {
    create() {
      window.addEventListener("beforeunload", e => this.beforeunloadHandler(e)); //3
      let isOpen = localStorage.getItem("isOpen"); //1
      let reload = localStorage.getItem("reload"); //2
      this.timer2 = setTimeout(() => {
        localStorage.removeItem("reload"); //4
      }, 2000);
      console.log(isOpen, reload, isOpen == "open", reload != "reload", "reload");
      if (isOpen == "open" && reload != "reload") {
        localStorage.removeItem("isOpen");
        localStorage.removeItem("reload");
        this.timer3 = setTimeout(() => {
          let open1 = localStorage.getItem("isOpen"); //7
          let load1 = localStorage.getItem("reload");
          if (open1 == "open" && load1 !== "reload") {
            this.$alert("网站重复打开即将关闭", {
              confirmButtonText: "确定",
              callback: action => {},
            });
            this.timer4 = setTimeout(() => {
              window.opener = null;
              location.href = "about:blank";
              window.close();
              localStorage.removeItem("reload");
              localStorage.removeItem("isOpen"); //6
            }, 2000);
          } else {
            history.go(0);
          }
        }, 1000);
      } else {
        setInterval(() => {
          localStorage.setItem("isOpen", "open"); //5
        }, 1000);
      }
    },
    beforeunloadHandler(e) {
      //根据事件进行操作进行操作
      console.log(e);
      console.log("浏览器刷新");
      localStorage.setItem("reload", "reload");
    },
    checkLogin() {
      return new Promise(resolve => {
        this.$checkLogin(true, true).then(res => {
          if (res) resolve(res);
        });
      });
    },
    //清除定时器
    clearPlayInterval() {
      if (this.playTimer) clearInterval(this.playTimer);
    },
    saveProgressTimer() {
      this.playTimer = setInterval(() => {
        this.saveRecord();
      }, 1000 * 10);
    },
    //播放器播放时间
    playerPlayingTime(e) {
      this.time = this.formatSeconds(Math.ceil(e));
    },
    formatSeconds(value) {
      let data = dsf.date.secondsToTime(value);
      let time = data.hours + ":" + data.minutes + ":" + data.seconds;
      return time;
    },
    playerHasPlay() {
      this.clearPlayInterval();
      //直播时
      if (this.state.value == 1) {
        if (this.isCoursePlayerTime) {
          this.saveProgressTimer();
        }
      } else {
        //非直播
        if (this.notlivePlayerTime) {
          this.saveProgressTimer();
        }
      }
    },
    playerHasPause() {
      this.clearPlayInterval();
    },
    playHasEnd() {
      this.clearPlayInterval();
      //直播时
      if (this.state.value == 1) {
        if (this.isCoursePlayerTime) {
          this.saveRecord();
        }
      } else {
        //非直播
        if (this.notlivePlayerTime) {
          this.saveRecord();
        }
      }
    },
    //点击考核
    handleClickMore(val, options) {
      // if (this.isCoursePlayerTime || this.notlivePlayerTime) {
      //   //课件直播中
      //   if (this.state.value == 1 && this.liveInfo.typeValue == 3) {
      //     if (!this.finishedRate) return dsf.layer.message("学习进度未达标", false);
      //   } else if (this.state.value == 2 || this.state.value == 3) {
      //     //回放和结束
      //     if (this.liveInfo.typeValue == 3 && this.notlivePlayerTime) {
      //       //非直播课件记录学时
      //       if (!this.finishedRate) return dsf.layer.message("学习进度未达标", false);
      //     }
      //   }
      // }
      //1 考试 2作业 3问卷
      // if (val == 1) {
      // }
      if (!this.finishedRate) return;
      if (val == 1) {
        this.handleExam(options);
      } else if (val == 2) {
        this.$openDialog({
          width: "620px",
          height: "auto",
          title: "直播作业",
          content: "DsfNcHomeworkSummary",
          params: {
            state: options.state_value || "",
            homeworkId: options.homeWorkId,
            studentId: options.homeWorkStudentId || "",
            readyonly: options.state_value == 0 ? false : true,
            showgrade: "0",
            cb: data => {
              if (data) {
                this.getCheck();
              }
            },
          },
          dialogArgs: {},
        });
      } else if (val == 3) {
        this.handleQues(options);
      }
    },
    //保利直播结束
    liveEnd() {
      // this.finishedRate = true;
    },
    //保存进度
    saveRecord() {
      let params = {
        courseId: this.liveId,
        type: "live",
        coursewareId: this.liveInfo.coursewareValue,
        watchPoint: this.time,
        pulseTime: "10",
        pulseRate: "1",
      };
      let url = "nc/course/play/pulseSaveRecord";
      this.$http
        .post(url, params, $$webRoot.nc)
        .done(res => {
          // if (res.data.finishedRate == 100) {
          //   //进度100% 可以点击考核
          //   this.finishedRate = true;
          // } else {
          //   this.finishedRate = false;
          // }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message || "进度保存失败", false);
        })
        .always(function () {});
    },
    //获取视频播放
    getVideoPlay() {
      let flag = false;
      if (this.state.value === 1 || this.state.value === 2 || this.state.value === 3) {
        //直播中和直播结束和直播回放
        //3课件 4视频
        if (this.liveInfo.typeValue === "3" || this.liveInfo.typeValue === "4") {
          //视频播放并且有video
          if (this.liveInfo.stateValue !== "0") {
            //启用状态
            flag = true;
          }
        }
        //链接直播 直播连接存在 并且未停用
        // if (this.liveInfo.typeValue === "2" && this.liveInfo.liveUrl && this.liveInfo.stateValue !== "0") {
        //   //弹出窗口
        // }
      }
      return flag;
    },
    getLiveInfo() {
      let params = {
        id: this.liveId,
      };
      const loading = dsf.layer.loading();
      this.$http
        .get(this.sourceUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.liveInfo = res.data.ncLiveInfo;
            this.liveCover = this.liveInfo.cover;
            this.liveData = res.data;
            this.state = res.data.state;
            this.subscribeTotal = res.data.subscribeTotal;
            this.views = res.data.ncLiveInfo.views || 0;
            this.tag = res.data.tag;
            this.tagTotal = res.data.tagTotal;
            this.subscribe = res.data.subscribe;
            this.collect = res.data.collect;
            this.collectTotal = res.data.collectTotal;
            this.plansecond = res.data.plansecond || 0;
            this.liveRate = Number(this.liveInfo.assessAverageScore || 0);
            this.assessId = res.data.assessId;
            this.isLook = res.data.loginCheck || "0";
            // 2 链接直播 3课件 4视频
            // if ((this.state.value != 0 && this.liveInfo.typeValue == 3) || (this.state.value != 0 && this.liveInfo.typeValue == 4) || (this.state.value != 0 && this.liveInfo.typeValue == 2)) {
            if (this.state.value != 0 && this.liveInfo.typeValue != 1 && this.liveInfo.stateValue != 0) {
              this.setvideo();
            }
            //根据时间判断是否可以考核
            let time = res.data.countDown || 0;

            if (time < 1000) {
              this.finishedRate = true;
            } else {
              time = Math.floor(time / 1000) * 1000;
              setTimeout(() => {
                this.finishedRate = true;
              }, time);
            }
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    // 订阅
    async liveAppointHandle() {
      await this.checkLogin();
      let params = {
        ywid: this.liveId,
        ywlxValue: "live",
        ywlxText: "直播",
      };
      let loading = dsf.layer.pc.loading();
      let appointUrl = "dsfa/subscribe/saveOrCancel";
      this.$http
        .post(appointUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.subscribe = res.data.isDo;
            this.setNum(this.subscribe, "subscribeTotal");
          }
          dsf.layer.pc.message(res.message, true);
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    //点赞
    async handleClickTag() {
      await this.checkLogin();
      const dzUrl = "dsfa/tags/saveOrCancel";
      let params = {
        ywlxValue: "live",
        ywlxText: "直播",
        ywid: this.liveId,
      };
      const loading = dsf.layer.pc.loading();
      this.$http
        .post(dzUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.tag = res.data.isDo;
            this.setNum(this.tag, "tagTotal");
          }
          dsf.layer.pc.message(res.message, true);
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    // 收藏
    async handleClickCollect() {
      await this.checkLogin();
      const collectUrl = "dsfa/collect/saveOrCancel";
      let params = {
        ywlxValue: "live",
        ywlxText: "直播",
        ywid: this.liveId,
      };
      const loading = dsf.layer.pc.loading();
      this.$http
        .post(collectUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.collect = res.data.isDo;
            this.setNum(this.collect, "collectTotal");
          }
          dsf.layer.pc.message(res.message, true);
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    async setvideo() {
      if (this.liveInfo.typeValue != 2) {
        this.liveCover = "";
      }
      let vUrl = "",
        vType = "video/mp4";
      //是否切片   视频直播或者回放
      if (this.liveInfo.splitAddr && this.liveInfo.splitAddr.hd) {
        vUrl = this.videosHandle(this.liveInfo.splitAddr);
      } else if (this.liveInfo.video && this.liveInfo.video !== "[]") {
        vUrl = this.liveInfo.video ? JSON.parse(this.liveInfo.video)[0].relativePath : "";
        vType = this.liveInfo.video ? JSON.parse(this.liveInfo.video)[0].contentType : "video/mp4";
      }

      // 课件直播
      if (this.liveInfo.typeValue == 3) {
        this.videoSource = await this.getCourseVideoSrc();
      } else {
        // 未切片
        this.videoSource = dsf.url.getWebPath(vUrl);
        //切片
        if (this.liveInfo.splitAddr && this.liveInfo.splitAddr.hd) {
          this.videoSource = vUrl;
        }
      }
      console.log(this.videoSource);
      this.videoShow = false;
      this.videoShow = this.getVideoPlay();
      // 封面图片
      this.playerParams.defaultPoster = this.getUrl(this.liveInfo.cover);
      //直播时.设置player
      if (this.state.value == 1) {
        //切换不允许暂停
        this.altTabStop = false;
        this.playerParams.keyDown = {
          space: false, //是否启用空格键切换播放/暂停
          left: false, //是否启用左方向键快退
          right: false, //是否启用右方向键快进
          up: true, //是否支持上方向键增加音量
          down: true, //是否支持下方向键减少音量
        };
        this.playerParams.autoplay = true;
        this.playerParams.timeScheduleAdjust = 0;
        this.playerParams.islive = true;
        // this.playerParams.live = true;
        this.playerParams.seek = this.plansecond || 0;
        this.playerParams.click = false;
        this.playerParams.ckplayerPause = this.ckplayerPause;
        this.playerParams.loaded = "ckPlayerLoad";
        this.playerParams.loop = true;
        //是否显示进度条
        this.playerParams.hideProgress = this.ckplayerShowProgress;
        // 增加播放结束函数
        // this.playerParams.ended = "endedHandler";
        this.time = this.plansecond || 0;

        window.ckPlayerLoad = () => {
          this.ckPlayerLoad();
        };

        // window.endedHandler = function () {};
      } else {
        setTimeout(() => {
          window.playerLoaded();
          // if (this.$refs.coursePlayer) {
          //   this.ckPlayer = this.$refs.coursePlayer.ckPlayer;
          //   this.ckPlayer.addListener("screenshot", this.$refs.coursePlayer.playerScreenshot); //监听截图功能
          // }
        }, 200);
      }
    },
    getCourseVideoSrc() {
      return new Promise((resolve, reject) => {
        this.dsf.http
          .get(
            "nc/courseware/queryVideoUrlById",
            {
              coursewareId: this.liveInfo.coursewareValue,
            },
            $$webRoot.nc
          )
          .done(d => {
            if (d.success) {
              // videoSource
              if (d.data.fileUrl && d.data.fileUrl.length > 2) {
                let video = dsf.url.getWebPath(JSON.parse(d.data.fileUrl).sd.relative);
                !video && dsf.layer.message("视频不可用", false);
                resolve(video);
              }
            } else {
              this.dsf.layer.message(d.message || "查询异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message("服务器异常", false);
          });
      });
    },
    ckPlayerLoad() {
      this.mutedPlay = true;
      setTimeout(() => {
        window.playerLoaded();
        this.ckPlayer = this.$refs.coursePlayer.ckPlayer;
        this.ckPlayer.videoMute();
        this.ckPlayer.videoPlay();
        this.ckPlayer && this.ckPlayer.addListener("screenshot", this.$refs.coursePlayer.playerScreenshot); //监听截图功能
        //  this.ckPlayer.videoSeek(this.plansecond);
      }, 200);
    },
    handleMuted() {
      this.mutedPlay = false;
      this.ckPlayer.videoEscMute();
    },
    //获取视频源
    videosHandle(data) {
      let videoArr = [];
      if (data) {
        videoArr.push(
          [data.cd.url + "?t=1", "video/" + this.getFileExt(data.cd.url).substring(1), "清晰", 0],
          [data.sd.url + "?t=2", "video/" + this.getFileExt(data.sd.url).substring(1), "标清", 0],
          [data.hd.url + "?t=3", "video/" + this.getFileExt(data.hd.url).substring(1), "高清", 0]
        );
      }
      return videoArr;
    },
    //获取视频格式
    getFileExt(filePath) {
      if (filePath != "") {
        if (filePath.indexOf("?") > -1) {
          filePath = filePath.split("?")[0];
        }
        let pos = "." + filePath.replace(/.+\./, "");
        return pos.toLowerCase();
      }
      return "";
    },
    formatterDate(time, format) {
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    setNum(val, tag) {
      if (val) {
        ++this[tag];
      } else {
        --this[tag];
        if (this[tag] < 0) {
          this[tag] = 0;
        }
      }
    },
    goLiveLink() {
      dsf.layer.openWindow(this.liveInfo.liveUrl);
    },
    getUrl(cover) {
      return cover ? dsf.url.getWebPath(JSON.parse(cover)[0].relativePath) : "";
    },
    async handleClickTab(item) {
      // if (item.id == 3 && this.state.value != 3) {
      //   return;
      // }
      await this.checkLogin();
      //限制直播后才能点击考核
      this.activeName = item.id;
    },
    //评价
    async handleComment() {
      // this.$checkLogin().then(res => {
      // if (res) {
      await this.checkLogin();
      this.$openDialog({
        title: "评价",
        width: "1000px",
        height: "525px",
        params: {
          path: "#/pc/nc/assess/livepc?relaid=" + this.liveId + "&id=" + (this.assessId || ""),
        },
        yes: result => {
          console.log(result);
        },
        showClose: false,
        hasFooter: false,
        center: "center",
      });
      // }
      // });
    },
    getCheck() {
      let params = {
        liveId: this.liveId,
      };
      if (dsf.getToken()) {
        this.$http
          .get("nc/live/assess/getLiveCheck", params, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              this.checkObj = res.data;
              if (this.checkObj.hasHomeWork != "1" && this.checkObj.hasExam != "1" && this.checkObj.hasCert != "1") {
                this.tabs[1].show = false;
              }
            }
          })
          .error(function (err) {
            dsf.layer.message(err.message, false);
          })
          .always(function () {});
      }
    },
    //考试
    handleExam(options) {
      const params = {
        examId: options.examId,
        businessId: this.liveId,
        businessType: "live_assess_exam",
        deviceType: "pc",
      };
      this.$toExamPage(params, data => {
        const path = dsf.url.getWebPath(data, $$webRoot.exam);
        window.open(location.origin + path);
        // this.$open(
        //   {
        //     url: ":" + path,
        //   },
        //   2
        // );
      });
    },
    //问卷
    handleQues() {
      dsf.layer.message("暂未开放，敬请期待");
    },
    download(row) {
      let array = JSON.parse(row.certificateurl || "[]");
      let imgsrc = "";
      if (array.length) {
        imgsrc = array[0].absolutePath || "";
      }
      let name = row.name || "证书";
      if (!imgsrc) return dsf.layer.message("证书地址为空", false);
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "证书"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
  },
  destroyed() {
    //进行监听销毁
    localStorage.removeItem("isOpen");
    localStorage.removeItem("reload");
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    clearTimeout(this.timer4);
    window.removeEventListener("beforeunload", e => this.beforeunloadHandler(e));
  },
});
</script>
<style lang="scss" scoped></style>
