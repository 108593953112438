<template>
  <div class="dsf_ncpc_manage_learntask">
    <ul class="learn_task_wrap" v-if="isDesign">
      <li class="learn_task_items">
        <div class="status">进行中</div>
        <div class="items_contens">
          <div class="items_top">
            <div class="items_name ell">培训班名培训班名培训班名培训班名培训班名</div>
            <div class="items_details">进入管理</div>
          </div>
          <div class="items_center">
            <div class="date">培训日期：{{ setDate("2020.05.01", "2020.05.31", "至") }}</div>
            <div class="period">
              <div class="bx period_font">必修：0 门 0 学时</div>
              <div class="xx period_font">选修 0 门 0 学时</div>
            </div>
          </div>
          <div class="items_bottom">
            <p>
              <span class="yellow_font">6</span>
              <span>学员人数</span>
            </p>
            <p>
              <span class="yellow_font">2</span>
              <span>报到人数</span>
            </p>
            <p>
              <span class="yellow_font">1</span>
              <span>结业人数</span>
            </p>
            <p>
              <span class="yellow_font">33.33%</span>
              <span>报到率</span>
            </p>
            <p>
              <span class="yellow_font">33.33%</span>
              <span>结业率</span>
            </p>
            <p>
              <span class="yellow_font">0</span>
              <span>待批阅</span>
            </p>
            <p>
              <span class="yellow_font">0</span>
              <span>待回答</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- 专题班 -->
    <ul class="learn_task_wrap" v-else-if="!isDesign && learnTaskType == 1">
      <li class="learn_task_items">
        <div class="status">{{ data.currentStateText }}</div>
        <div class="items_contens">
          <div class="items_top">
            <div class="items_name ell">{{ data.name }}</div>
            <div class="items_details" @click="clickItem(data)">进入管理</div>
          </div>
          <div class="items_center">
            <div class="date">培训日期： {{ setDate(data.pxsjSdate, data.pxsjEdate, "至") }}</div>
            <div class="period" v-if="showCompulsoryElectives">
              <div class="bx period_font">必修：{{ data.compulsory || 0 }}门 {{ data.compulsoryPeriod || 0.0 }}学时</div>
              <div class="xx period_font">选修{{ data.electives || 0 }}门 {{ data.electivesPeriod || 0.0 }}学时</div>
            </div>
          </div>
          <div class="items_bottom">
            <p>
              <span class="yellow_font">{{ data.studentCount || 0 }}</span>
              <span>学员人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.dutyCount || 0 }}</span>
              <span>报到人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.graduationCount || 0 }}</span>
              <span>结业人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.dutyRatio || 0 }}</span>
              <span>报到率</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.graduationRatio || 0 }}</span>
              <span>结业率</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.uncheckCount || 0 }}</span>
              <span>待批阅</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.unAnswerCount || 0 }}</span>
              <span>待回答</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- 课程 -->
    <ul class="learn_task_wrap" v-else-if="!isDesign && learnTaskType == 2">
      <li class="learn_task_items">
        <div class="status">{{ data.currentStateText }}</div>
        <div class="items_contens">
          <div class="items_top">
            <div class="items_name ell">{{ data.name }}</div>
            <div class="items_details" @click="clickItem(data)">进入管理</div>
          </div>
          <div class="items_center">
            <div class="date">任务日期： {{ setDate(data.pxsjSdate, data.pxsjEdate, "至") }}</div>
          </div>
          <div class="items_bottom">
            <p>
              <span class="yellow_font">{{ data.studentCount || 0 }}</span>
              <span>应学人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.dutyCount || 0 }}</span>
              <span>已学人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.finishCount || 0 }}</span>
              <span>完成人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.finishRatio || 0 }}</span>
              <span>完成率</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- 考试 -->
    <ul class="learn_task_wrap" v-else-if="!isDesign && learnTaskType == 3">
      <li class="learn_task_items">
        <div class="status">{{ data.currentStateText }}</div>
        <div class="items_contens">
          <div class="items_top">
            <div class="items_name ell">{{ data.name }}</div>
            <div class="items_details" @click="clickItem(data)">进入管理</div>
          </div>
          <div class="items_center">
            <div class="date">任务日期： {{ setDate(data.pxsjSdate, data.pxsjEdate, "至") }}</div>
          </div>
          <div class="items_bottom">
            <p>
              <span class="yellow_font">{{ data.studentCount || 0 }}</span>
              <span>应考人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.dutyCount || 0 }}</span>
              <span>已考人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.finishCount || 0 }}</span>
              <span>完成人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ data.finishRatio || 0 }}</span>
              <span>完成率</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcLearnTask",
  ctrlCaption: "学习任务",
  mixins: [$mixins.control, $mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [];
      },
    },
    keysMapSpeical: {
      type: Array,
      default() {
        return [
          { from: "currentStateText", to: "currentStateText", type: "text", desc: "状态" },
          { from: "name", to: "name", type: "text", desc: "名称" },
          { from: "compulsory", to: "compulsory", type: "text", desc: "必修几门" },
          { from: "compulsoryPeriod", to: "compulsoryPeriod", type: "text", desc: "必修学时" },
          { from: "electives", to: "electives", type: "text", desc: "选修几门" },
          { from: "electivesPeriod", to: "electivesPeriod", type: "text", desc: "选修学时" },
          { from: "studentCount", to: "studentCount", type: "text|number", desc: "学员人数" },
          { from: "dutyCount", to: "dutyCount", type: "text|number", desc: "报到人数" },
          { from: "graduationCount", to: "graduationCount", type: "text|number", desc: "结业人数" },
          { from: "dutyRatio", to: "dutyRatio", type: "text", desc: "报到率" },
          { from: "graduationRatio", to: "graduationRatio", type: "text", desc: "结业率" },
          { from: "uncheckCount", to: "uncheckCount", type: "text", desc: "待批阅" },
          { from: "unAnswerCount", to: "unAnswerCount", type: "text", desc: "待回答" },
          { from: "pxsjSdate", to: "pxsjSdate", type: "text", desc: "开始时间" },
          { from: "pxsjEdate", to: "pxsjEdate", type: "text", desc: "结束时间时间" },
        ];
      },
    },
    keysMapCourse: {
      type: Array,
      default() {
        return [
          { from: "currentStateText", to: "currentStateText", type: "text", desc: "状态" },
          { from: "name", to: "name", type: "text", desc: "名称" },
          { from: "studentCount", to: "studentCount", type: "text|number", desc: "应学人数" },
          { from: "dutyCount", to: "dutyCount", type: "text|number", desc: "已学人数" },
          { from: "finishCount", to: "finishCount", type: "text|number", desc: "完成人数" },
          { from: "finishRatio", to: "finishRatio", type: "text", desc: "完成率" },
          { from: "pxsjSdate", to: "pxsjSdate", type: "text", desc: "开始时间" },
          { from: "pxsjEdate", to: "pxsjEdate", type: "text", desc: "结束时间时间" },
        ];
      },
    },
    keysMapExam: {
      type: Array,
      default() {
        return [
          { from: "currentStateText", to: "currentStateText", type: "text", desc: "状态" },
          { from: "name", to: "name", type: "text", desc: "名称" },
          { from: "studentCount", to: "studentCount", type: "text|number", desc: "应学人数" },
          { from: "dutyCount", to: "dutyCount", type: "text|number", desc: "已学人数" },
          { from: "finishCount", to: "finishCount", type: "text|number", desc: "完成人数" },
          { from: "finishRatio", to: "finishRatio", type: "text", desc: "完成率" },
          { from: "pxsjSdate", to: "pxsjSdate", type: "text", desc: "开始时间" },
          { from: "pxsjEdate", to: "pxsjEdate", type: "text", desc: "结束时间时间" },
        ];
      },
    },
    learnTaskType: {
      type: String,
      default: "1",
    },
    showCompulsoryElectives: {
      type: Boolean,
      // default: this.learnTaskType == 1 ? true : false,
      default: true,
    },
    // url: {
    //   type: String,
    //   default: "/nc/class/manage/list", // 专题班：/nc/class/manage/list； 课程：nc/task/page/coursetaskdata； 考试：nc.task.page.examtaskdata
    // },
    // itemWidth: {
    //   type: String,
    //   default: "1020px",
    // },
    moreConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      params: {},
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign) {
      switch (this.learnTaskType) {
        case "1":
          this.keysMap = this.keysMapSpeical;
          break;
        case "2":
          this.keysMap = this.keysMapCourse;
          break;
        case "3":
          this.keysMap = this.keysMapExam;
          break;
        default:
          this.keysMap = this.keysMapSpeical;
          break;
      }
      // this.lists = [];
      // this.initData();
    }
  },
  methods: {
    // error(err) {
    //   dsf.layer.pc.message(err.message, false);
    // },
    // initData() {
    //   let loading = dsf.layer.pc.loading();
    //   dsf.http
    //     .get(this.url, this.params, $$webRoot.nc)
    //     .done(res => {
    //       if (!res.success) {
    //         this.error(res);
    //       } else {
    //         this.lists = res.data;
    //       }
    //     })
    //     .error(err => {
    //       this.error(err);
    //     })
    //     .always(res => {
    //       dsf.layer.pc.closeLoading(loading);
    //     });
    // },
    clickItem() {
      if (!this.moreConfig) return;
      let { url, state } = this.moreConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
    },
    setDate(startDate, endDate, interval) {
      let start = startDate ? startDate.slice(0, 10).replace(/\./g, "-") : "";
      let end = endDate ? endDate.slice(0, 10).replace(/\./g, "-") : "";
      return start && end ? `${start}  ${interval}  ${end}` : "暂无";
    },
  },
});
</script>
