<template>
  <div class="dsf_nc_saas saas_setting_page">
    <div class="title t1 margint20 marginb20">页面功能设置</div>
    <div class="top">
      <dsf-button @click="createPage">新增页面</dsf-button>
      <div class="search">
        <el-input placeholder="请输入检索关键字" v-model="searchValue" @keyup.enter.native="getData">
          <dsf-icon name="fangdajing" slot="append" @click="getData"></dsf-icon>
        </el-input>
      </div>
    </div>
    <el-table :data="pageList" ref="table" stripe style="width: 100%" border>
      <el-table-column prop="name" label="页面名称" align="center">
        <template slot-scope="scope">
          <p class="table_name">
            <dsf-icon name="shoucang_shixin" v-if="scope.row.pageStatus == '1'"></dsf-icon>
            <span>{{ scope.row.name }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="dsUpdateTime" label="修改时间" align="center"></el-table-column>
      <el-table-column fixed="right" label="操作" width="300px" align="center">
        <template slot-scope="scope">
          <div class="tablebtns">
            <el-button
              type="text"
              :style="{ display: scope.row.pageStatus == '1' && (btn.action == 'set_home_page' || btn.action == 'row_delete') ? 'none' : 'unset' }"
              @click="clickoption(btn, scope)"
              v-for="btn in buttons"
              :key="btn.action"
            >
              {{ btn.name }}
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import DsfNcSaasCreatePage from "../../base/saas/createNewPage.vue";
export default dsf.component({
  name: "DsfNcSaasSettingPage",
  ctrlCaption: "URL地址设置",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: 1,
      size: 15,
      total: 0,
      searchValue: "",
      pageList: [],
      deleteList: [],
      buttons: [
        {
          name: "设置为首页",
          action: "set_home_page",
        },
        {
          name: "设计页面",
          action: "design_page",
        },
        {
          name: "预览",
          action: "views",
        },
        {
          name: "删除",
          action: "row_delete",
        },
      ],
      saasGroupId: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/getSaasGropu";
      let params = {
        pageName: this.searchValue,
        unitId: this.queryString.unitId,
      };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.saasGroupId = data.saasGroupId;
            this.pageList = data.saasGroupPageDtoList || [];
          } else {
            dsf.layer.pc.message("获取页面列表:" + message, success);
          }
        })
        .error(() => {
          dsf.layer.pc.message("获取页面列表出错:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    //新建页面
    createPage() {
      this.$openDialog({
        title: "新增页面",
        content: DsfNcSaasCreatePage,
        width: "450px",
        height: "100px",
        showClose: true,
        round: true,
        center: "center",
        btns: [
          {
            text: "确定",
            handler: async vm => {
              var pageId = "";
              console.log(this.pageList);
              this.savePage(
                {
                  name: vm.pageName,
                  pageStatus: this.pageList.length == 0 ? "1" : "2",
                },
                data => {
                  window.open("/page.html#/pc/nc/saas/setting/pagedesign?id=" + data.saasGroupPageId);
                }
              );
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
    //保存页面模板接口
    savePage(params, callback) {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/saveSaasGroupPage";
      const header = {
        "Content-Type": "application/json",
      };
      dsf.http
        .post(sourceUrl, { ...params, saasGroupId: this.saasGroupId, unitId: this.queryString.unitId }, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.pc.message("成功！", success);
            this.getData();
            if (callback) {
              callback(data);
            }
          } else {
            dsf.layer.pc.message("操作异常:" + message, success);
          }
        })
        .error(() => {
          dsf.layer.pc.message("操作异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    //操作列点击事件
    clickoption(btn, scope) {
      this[btn.action](scope);
    },
    //设置为首页
    set_home_page(scope) {
      this.pageList.forEach((page, idnex) => {
        if (page.saasGroupPageId == scope.row.saasGroupPageId) {
          page.pageStatus = "1";
        } else {
          page.pageStatus = "2";
        }
      });
      this.savePage(scope.row);
    },
    //设计页面
    design_page(scope) {
      window.open("/page.html#/pc/nc/saas/setting/pagedesign?id=" + scope.row["saasGroupPageId"]);
    },
    //预览
    views(scope) {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/getSaasMainBody";
      let params = { saasGroupPageId: scope.row.saasGroupPageId, unitId: this.queryString.unitId };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          localStorage.setItem("previewDesign", JSON.stringify(data.saasMainBodyDetailList));
          window.open("/page.html#/pc/nc/saas/setting/pagepreview");
        })
        .error(() => {
          dsf.layer.pc.message("获取数据异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    //删除
    row_delete(scope) {
      dsf.layer
        .confirm({
          message: "确认是否要删除？",
        })
        .then(() => {
          scope.row["dsDeleted"] = "1";
          let deletePage = scope.row;
          this.pageList.splice(scope.$index, 1);
          this.savePage(deletePage);
        })
        .catch(err => {
          console.error(err);
        });
    },
  },
});
</script>
