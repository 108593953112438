<template>
  <div :class="`dsf_nc_title_three ${titleType}`" :style="{ borderBottomColor: bottomStyleColor, margin: controlStyle }">
    <div class="title-inner" :style="{ borderBottomColor: bottomStyleColor }">
      <div class="title_wrap">
        <div :class="['title', { active: active == index }]" v-for="(item, index) in menus" @click="handlerClick(menus, item, index)" :key="'title' + item.name">
          <dsf-icon :name="item.icon"></dsf-icon>
          <span class="font">{{ item.name }}</span>
        </div>
      </div>
      <div class="title-more" v-if="showMore">
        <span slot="more" @click="_clickBefore(toUrl)">{{ moreFont }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcTitleThree",
  ctrlCaption: "门户标题",
  mixins: [$mixins.control],
  props: {
    showMore: {
      type: Boolean,
      default: false,
    },
    moreFont: {
      type: String,
      default: "更多 >>",
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    bottomColor: {
      type: String,
      default: "",
    },
    titleType: {
      type: String,
      default: "big",
    },
    bottomLine: {
      type: Boolean,
      default: true,
      desc: "显示默认主题色,点击否后在选择可配置自定义颜色",
    },

    menus: {
      type: Array,
      default: () => [
        {
          icon: "xxkc1",
          bg: "",
          name: "最新课程",
          field: "",
          width: "",
        },
      ],
    },
    margin: {
      type: Array,
      default: () => [10, 10, 10, 10],
    },
  },
  data() {
    return {
      active: 0,      menusItems: "",
    };
  },
  created() {},
  mounted() {
    if (this.menus.length) {
      this.menusItems = this.menus[0];
    }
  },
  computed: {
    controlStyle() {
      let { margin, isDesign } = this;
      let style = margin.join("px ") + "px";
      if (isDesign) {
        style = "10px 10px 10px 10px";
      }
      return style;
    },
    bottomStyleColor() {
      let { bottomLine, isDesign, bottomColor } = this;
      let style = "";
      if (bottomLine) {
        style = bottomColor;
      } else {
        style = "transparent";
      }
      return style;
    },
  },
  methods: {
    toUrl() {
      let { url, state } = this.moreUrl;
      if (this.menus.length) {
        url = this.$replace(url, this.menusItems);
      }
      this.$open({ url, state });
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.$checkLogin();
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
    handlerClick(menus, item, index) {
      if (menus.length < 1) return;
      this.active = index;
      this.menusItems = item;
      this.$dispatch("itemClick", item, index);
    },
  },
});
</script>
