<template>
  <div class="dsf-shopping-car-item">
    <div class="car-item-head">
      <dl class="head head-posi">
        <dd class="all all-checked">
          <el-checkbox v-model="checkAlled" :indeterminate="isIndeterminate" @change="handleAllChecked">全选</el-checkbox>
        </dd>
        <dd class="info">商品信息</dd>
        <dd class="state">商品类型</dd>
        <dd class="dept">购买方式</dd>
        <dd class="money">金额</dd>
        <dd class="handle center">操作</dd>
      </dl>
    </div>
    <template v-if="isDesign">
      <div class="car-item">
        <dl class="head body">
          <dd class="all">
            <el-checkbox value="checked"></el-checkbox>
          </dd>
          <dd class="info">
            <div class="item-card">
              <div class="item-card-img-wrap">
                <dsf-image />
              </div>
              <div class="item-card-desc">
                <h3 class="name ellipsis">供给侧结构性改革网络布局课</h3>
                <p class="info">
                  <span>老师: 张三</span>
                  <span>销量: 1200</span>
                  <span>库存: 10</span>
                </p>
                <div class="give">
                  <span class="prefix">赠</span>
                  <dsf-icon name="quan" />
                  <span class="num">25</span>
                </div>
              </div>
            </div>
          </dd>
          <dd class="state">课程</dd>
          <dd class="dept">
            <el-select v-model="selectType" class="select-type">
              <el-option v-for="item in selType" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </dd>
          <dd class="money has-color">
            <dsf-icon name="jinbi" />
            850
          </dd>
          <dd class="handle">
            <span class="handle-item">移入我的收藏</span>
            <span class="handle-item del">删除</span>
          </dd>
        </dl>
      </div>
    </template>
    <template v-else>
      <div class="car-item" v-for="(it, index) in list" :key="it.shopId">
        <dl class="head body">
          <dd class="all">
            <el-checkbox v-model="it.checked" @change="handleItemChecked" :disabled="it.isValid === 'invalid'"></el-checkbox>
          </dd>
          <dd class="info">
            <div class="item-card">
              <div class="item-card-img-wrap" @click="toDetail(it)">
                <dsf-image :error-src="defaultSrc" :src="it.cover | imgFormat" />
                <div class="item-card-valid" v-if="it.isValid === 'invalid'">已失效</div>
              </div>
              <div class="item-card-desc">
                <h3 class="name ellipsis" @click="toDetail(it)">{{ it.goodName }}</h3>
                <div class="info car-info" v-if="it.goodTypeValue === 'course'">
                  <span :title="it.teachText ? it.teachText.replace(/\^/g, '、') : '-'">老师: {{ it.teachText ? it.teachText.replace(/\^/g, "、") : "-" }}</span>
                  <div class="bottom">
                    <span>销量: {{ it.sales || 0 }}</span>
                    <span>库存: {{ it.store || 0 }}</span>
                  </div>
                </div>
                <div class="info special" v-else-if="it.goodTypeValue === 'class'">
                  <span>必修: {{ it.period || "-" }}学时</span>
                  <span>选修: {{ it.noNeedPeriod || 0 }}学时</span>
                  <span>库存: {{ it.store || 0 }}</span>
                  <span>销量: {{ it.sales || 0 }}</span>
                </div>
                <div class="info live car-info" v-else-if="it.goodTypeValue === 'live'">
                  <span :title="it.teacherUnitName">讲师: {{ it.teacherUnitName || "-" }}</span>
                  <div class="bottom">
                    <span>销量: {{ it.sales || 0 }}</span>
                  </div>
                </div>
                <div class="info" v-else-if="it.goodTypeValue === 'channel'">
                  <span>资源数: {{ it.subNum || 0 }}</span>
                  <span>学时: {{ it.period || 0 }}学时</span>
                  <span>销量: {{ it.sales || 0 }}</span>
                </div>
                <div class="give" v-if="it.giftCoupon">
                  <span class="prefix">赠</span>
                  <dsf-icon name="quan" />
                  <span class="num">{{ it.giftCoupon }}</span>
                </div>
              </div>
            </div>
          </dd>
          <dd class="state">{{ it.goodTypeText || "-" }}</dd>
          <dd class="dept">
            <el-select v-model="it.shopTypeText" class="select-type" @change="changeSelect(index)" :disabled="!iad || it.isValid === 'invalid'">
              <el-option v-for="item in selType" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </dd>
          <dd class="money has-color">
            <div>
              <dsf-icon name="jinbi" v-if="it.realPrice" />
              {{ it.realPrice || "免费" }}
              <span class="o-price" v-if="it.originalPrice">{{ it.originalPrice }}</span>
            </div>
          </dd>
          <dd class="handle">
            <span class="handle-item" @click="handleCarItem('collect', it)">移入我的收藏</span>
            <span class="handle-item del" @click="handleCarItem('del', it)">删除</span>
          </dd>
        </dl>
      </div>
    </template>

    <div class="bottom-check-out">
      <div class="inner-left">
        <div class="handle-item">
          <el-checkbox v-model="checkAlled" :indeterminate="isIndeterminate" @change="handleAllChecked">全选</el-checkbox>
        </div>
        <div class="handle-item" @click="handleCarItem('del')">删除</div>
        <div class="handle-item" @click="handleCarItem('collect')">移入我的收藏</div>
        <div class="handle-item" @click="handleCarItem('clear')">清除失效产品</div>
      </div>
      <div class="inner-right">
        <div class="handle-item text">
          已选
          <span class="checked-num">{{ checkedPrice.count }}</span>
          件商品，总计：
          <dsf-icon name="jinbi" />
          <b>{{ checkedPrice.price }}</b>
        </div>
        <div class="handle-item no-margin-right">
          <button class="btn-check-out" :class="{ 'some-checked': someItemChecked }" :disabled="!someItemChecked" @click="submitOrder">提交订单</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CREATEORDER = "/nc/shop/shopIndent/commitIndent",
  CLEARSHOPPINGCAR = "/nc/shop/shopcart/removeShopCartById",
  TYPECHANGE = "/nc/shop/shopcart/changeShopCartType";
export default {
  name: "dsfShoppingCarItem",
  props: {
    list: {
      type: Array,
      data: () => {
        return [];
      },
    },
    orderUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shoporders?id=",
    },
    detailUrl: {
      type: String,
      default: "/page.html#/pc/nc/page/pc/shop/shopitemdetail?",
    },
  },
  data() {
    return {
      checkAlled: false,
      isIndeterminate: false,
      selType: [
        {
          value: "个人购买",
          label: "个人购买",
        },
        {
          value: "组织购买",
          label: "组织购买",
        },
      ],
      defaultSrc: dsf.config.setting_nc_image_default_img,
      someItemChecked: false,
      iad: dsf.getCookie("iad") == 1,
    };
  },
  created() {},
  mounted() {},
  computed: {
    checkedPrice() {
      var price = 0,
        count = 0,
        ids = [],
        invalid = [];
      _.each(this.list, item => {
        if (item.checked) {
          price += Number(item.originalPrice);
          ++count;
          ids.push(item.shopId);
        }
        if (item.isValid === "invalid") invalid.push(item.shopId);
      });
      return {
        price,
        count,
        ids,
        invalid,
      };
    },
  },
  methods: {
    submitOrder() {
      const loading = dsf.layer.loading();
      const header = {
        "Content-Type": "application/json",
      };
      this.dsf.http
        .post(CREATEORDER, this.checkedPrice.ids, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.message("创建订单成功", true);
            const sourceUrl = this.orderUrl + data;
            dsf.layer.openWindow(sourceUrl);
            this.$emit("createOrder");
          } else {
            dsf.layer.message(message || "提交订单异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "提交订单异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    handleCarItem(state, item) {
      if (item?.isValid === "invalid" && state !== "del") return;
      if (state === "collect") return dsf.layer.message("收藏暂未开放，敬请期待");
      let ids = JSON.parse(JSON.stringify(this.checkedPrice.ids)) || [];
      if (state === "clear") {
        ids = JSON.parse(JSON.stringify(this.checkedPrice.invalid));
      }
      if (item) ids.push(item.shopId);
      if (ids.length === 0) {
        dsf.layer.message(state === "clear" ? "当前没有失效的商品" : "请选择要删除的商品", false);
        return;
      }
      const loading = dsf.layer.loading();
      const header = {
        "Content-Type": "application/json",
      };
      this.dsf.http
        .post(CLEARSHOPPINGCAR, ids, { headers: header }, $$webRoot.nc)
        .done(({ success, message }) => {
          if (success) {
            this.$emit("handleSuccess");
            dsf.layer.message(message, true);
          } else {
            dsf.layer.message(message || "操作异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "接口异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    handleAllChecked(val) {
      _.each(this.list, item => {
        if (item.isValid !== "invalid") {
          item.checked = val;
        }
      });
      let allChecked = this.list.every(item => item.checked === true);
      console.log(allChecked);
      if (allChecked) {
        this.isIndeterminate = false;
        this.someItemChecked = val;
      } else {
        this.checkAlled = false;
        this.someItemChecked = false;
      }
    },
    changeSelect(idx) {
      let { list } = this;
      let text = list[idx].shopTypeText;
      let params = {
        shopCartId: list[idx].shopId,
        shopTypeValue: text === "组织购买" ? "unit" : "private",
      };
      const loading = dsf.layer.loading();
      const header = {
        "Content-Type": "application/json",
      };
      this.dsf.http
        .post(TYPECHANGE, params, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.message(message, true);
            this.list[idx].realPrice = data.realPrice;
            this.list[idx].originalPrice = data.originalPrice;
          } else {
            dsf.layer.message(message || "购买方式异常", false);
            this.list[idx].shopTypeText = text === "组织购买" ? "个人购买" : "组织购买";
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "购买方式异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    handleItemChecked() {
      this.someItemChecked = this.list.some(item => item.checked === true);
      let allChecked = this.list.every(item => item.checked === true);
      let allUnChecked = this.list.every(item => item.checked === false);
      if (this.someItemChecked) this.isIndeterminate = true;
      if (allChecked) {
        this.isIndeterminate = false;
        this.checkAlled = true;
      }
      if (allUnChecked) {
        this.isIndeterminate = false;
        this.checkAlled = false;
      }
    },
    toDetail(it) {
      if (it) {
        const path = `${this.detailUrl}id=${it.nc_shop_goods_id}&type=${it.goodTypeValue}`;
        dsf.layer.openWindow(path);
      }
    },
  },
  watch: {
    list() {
      this.handleItemChecked();
    },
  },
};
</script>
