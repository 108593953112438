var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_credential" }, [
    _vm.isDesign
      ? _c("div", { staticClass: "dsf_credential-wrap" }, [
          _c(
            "div",
            { staticClass: "img-box" },
            [_c("el-image", { attrs: { src: _vm.isDesignSrc, fit: "cover" } })],
            1
          ),
        ])
      : _c("div", { staticClass: "dsf_credential-wrap" }, [
          _c(
            "div",
            { staticClass: "img-box" },
            [_c("el-image", { attrs: { src: _vm.src, fit: "cover" } })],
            1
          ),
          _vm.isDownLoad
            ? _c(
                "div",
                { staticClass: "dsf_credential-download" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.add(_vm.src)
                        },
                      },
                    },
                    [_vm._v("下载证书")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }