var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_ncpc_task_detail" }, [
        _vm._m(0),
        _c("div", { staticClass: "detail_innerbox" }, [
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _c("ul", { staticClass: "detail_wrap detail_course" }, [
            _c("li", { staticClass: "detail_items" }, [
              _c("div", { staticClass: "items_imgs" }, [_c("dsf-nc-image")], 1),
              _vm._m(4),
            ]),
          ]),
          _c(
            "ul",
            { staticClass: "detail_wrap detail_exams" },
            _vm._l(4, function (item) {
              return _c("li", { key: item, staticClass: "detail_items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("结业考试")]),
                _c("div", { staticClass: "date" }, [
                  _vm._v("1997-04-13 12:00～13:00（限时50分钟）"),
                ]),
                _vm._m(5, true),
                _vm._m(6, true),
                _c(
                  "div",
                  { staticClass: "enter_exam", on: { click: _vm.enterExam } },
                  [_c("el-button", [_vm._v("进入考试")])],
                  1
                ),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "detail_award detail_i" }, [
            _c("h5", [_vm._v("任务奖励")]),
            _c(
              "div",
              { staticClass: "complete_certificate" },
              [
                _c("DsfNcCredential", {
                  attrs: { "certificate-src": _vm.certificateSrc },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "dsf_ncpc_task_detail" }, [
        _c("div", { staticClass: "title_box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.taskDetail.task_name)),
          ]),
        ]),
        _c("div", { staticClass: "detail_innerbox" }, [
          _c("div", { staticClass: "detail_date detail_i" }, [
            _c("h5", [_vm._v("周期")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.dsf.date.format(
                    _vm.taskDetail.task_cycle_sdate,
                    "yyyy-mm-dd"
                  )
                ) +
                  " 至 " +
                  _vm._s(
                    _vm.dsf.date.format(
                      _vm.taskDetail.task_cycle_edate,
                      "yyyy-mm-dd"
                    )
                  )
              ),
            ]),
          ]),
          _c("div", { staticClass: "detail_desc detail_i" }, [
            _c("h5", [_vm._v("描述")]),
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.taskDetail.task_desc || "暂无数据") + " "
              ),
            ]),
          ]),
          _vm.taskDetail.task_type_value == 3
            ? _c("div", { staticClass: "detail_request detail_i" }, [
                _c("h5", [_vm._v("学时要求")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.taskDetail.study_hour || 0) + "学时"),
                ]),
              ])
            : _vm._e(),
          _vm.taskDetail.task_type_value == 3
            ? _c("div", { staticClass: "detail_request detail_i" }, [
                _c("h5", [_vm._v("获得学时")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.taskDetail.progress || 0) + "学时"),
                ]),
              ])
            : _vm._e(),
          _vm.taskDetail.task_content
            ? _c("div", { staticClass: "detail_desc detail_i" }, [
                _c("h5", [_vm._v("任务内容")]),
                _vm.taskDetail.task_type_value == 1
                  ? _c(
                      "ul",
                      { staticClass: "detail_wrap detail_course" },
                      _vm._l(_vm.taskDetail.task_content, function (item) {
                        return _c(
                          "li",
                          {
                            key: item._id,
                            staticClass: "detail_items",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickItem(
                                  item,
                                  _vm.taskDetail.task_type_value
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "items_imgs" },
                              [
                                _c("dsf-nc-image", {
                                  attrs: {
                                    src: _vm._f("imgFormat")(item.cover),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "items_right" }, [
                              _c("div", { staticClass: "item_name ell" }, [
                                _vm._v(_vm._s(item.title || "")),
                              ]),
                              _c("div", { staticClass: "items_bottom_box" }, [
                                _c(
                                  "div",
                                  { staticClass: "items_bottom_box_left" },
                                  [
                                    _c("div", { staticClass: "item_center" }, [
                                      _c("div", { staticClass: "teacher" }, [
                                        _vm._v(_vm._s(item.teachername || "")),
                                      ]),
                                      _c("div", { staticClass: "job ell" }, [
                                        _vm._v(_vm._s(item.job || "")),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "footer" }, [
                                      _c("div", { staticClass: "xs" }, [
                                        _c("i", {
                                          staticClass:
                                            "icon iconfont icon-shijian2",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.period || 0) +
                                            "学时 "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "ckl" }, [
                                        _c("i", {
                                          staticClass:
                                            "icon iconfont icon-yanjing1",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.view_totle || 0) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "items_bottom_box_right" },
                                  [
                                    _c("div", { staticClass: "rate" }, [
                                      _vm._v(
                                        _vm._s(item.progress || "0") + "%"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "font" }, [
                                      _vm._v("当前进度"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.taskDetail.task_type_value == 2
                  ? _c(
                      "ul",
                      { staticClass: "detail_wrap detail_exams" },
                      _vm._l(_vm.taskDetail.task_content, function (item) {
                        return _c(
                          "li",
                          { key: item._id, staticClass: "detail_items" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("结业考试"),
                            ]),
                            _c("div", { staticClass: "detail_inner" }, [
                              _c("div", { staticClass: "score" }, [
                                _c("div", { staticClass: "total_score" }, [
                                  _vm._v(
                                    "总分：" + _vm._s(item.score || 100) + "分"
                                  ),
                                ]),
                                _c("div", { staticClass: "pass_score" }, [
                                  _vm._v(
                                    "及格分数：" +
                                      _vm._s(item.pass_score || 60) +
                                      "分"
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "score_num" }, [
                                _c("div", { staticClass: "total_num" }, [
                                  _vm._v(
                                    "考试次数：" +
                                      _vm._s(
                                        (item.exam_count * 1 || 0) +
                                          (item.surplus_count * 1 || 0)
                                      ) +
                                      "次"
                                  ),
                                ]),
                                _c("div", { staticClass: "have_num" }, [
                                  _vm._v(
                                    "已考/剩余次数：" +
                                      _vm._s(item.exam_count || 0) +
                                      "/" +
                                      _vm._s(item.surplus_count || 0) +
                                      "次"
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "enter_exam",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickItem(
                                        item,
                                        _vm.taskDetail.task_type_value
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("el-button", [
                                    _vm._v(
                                      _vm._s(
                                        Number(item.surplus_count) != 0
                                          ? "进入考试"
                                          : "查看"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.taskDetail.is_assess_value && _vm.taskDetail.task_type_value == 3
            ? _c("div", { staticClass: "detail_i" }, [
                _c("h5", [_vm._v("考核要求")]),
                _vm.taskDetail.task_assess_exam
                  ? _c(
                      "ul",
                      { staticClass: "detail_wrap detail_exams" },
                      _vm._l(_vm.taskDetail.task_assess_exam, function (item) {
                        return _c(
                          "li",
                          { key: item._id, staticClass: "detail_items" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("结业考试"),
                            ]),
                            _c("div", { staticClass: "detail_inner" }, [
                              _c("div", { staticClass: "score" }, [
                                _c("div", { staticClass: "total_score" }, [
                                  _vm._v("总分：" + _vm._s(item.score) + "分"),
                                ]),
                                _c("div", { staticClass: "pass_score" }, [
                                  _vm._v(
                                    "及格分数：" +
                                      _vm._s(item.pass_score) +
                                      "分"
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "score_num" }, [
                                _c("div", { staticClass: "total_num" }, [
                                  _vm._v(
                                    "考试次数：" +
                                      _vm._s(
                                        (item.exam_count * 1 || 0) +
                                          (item.surplus_count * 1 || 0)
                                      ) +
                                      "次"
                                  ),
                                ]),
                                _c("div", { staticClass: "have_num" }, [
                                  _vm._v(
                                    "已考/剩余次数：" +
                                      _vm._s(item.exam_count || 0) +
                                      "/" +
                                      _vm._s(item.surplus_count || 0) +
                                      "次"
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  class: ["enter_exam"],
                                  on: {
                                    click: function ($event) {
                                      return _vm.enterExam(item)
                                    },
                                  },
                                },
                                [
                                  _c("el-button", [
                                    _vm._v(
                                      _vm._s(
                                        Number(item.surplus_count) != 0
                                          ? "进入考试"
                                          : "查看"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.taskDetail.is_reward_value &&
          _vm.taskDetail.task_type_value == 3 &&
          _vm.certificateSrc
            ? _c(
                "div",
                { staticClass: "detail_award detail_i certificate_wrap" },
                [
                  _c("h5", [_vm._v("任务奖励")]),
                  _c(
                    "div",
                    {
                      class: [
                        "complete_certificate",
                        !_vm.certificateSrc ? "gray_btn" : "",
                      ],
                    },
                    [
                      _c("DsfNcCredential", {
                        attrs: { "certificate-src": _vm.certificateSrc },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: [
                        "re_certificate",
                        !_vm.taskCertificateState ? "gray_btn" : "",
                      ],
                      on: {
                        click: function ($event) {
                          _vm.taskCertificateState && _vm.clickGetCertificate()
                        },
                      },
                    },
                    [_c("el-button", [_vm._v("重新生成证书")])],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title_box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("第二课时课后作业")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail_date detail_i" }, [
      _c("h5", [_vm._v("周期")]),
      _c("span", [_vm._v("2009-01-15 至 2021-09-21")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail_desc detail_i" }, [
      _c("h5", [_vm._v("描述")]),
      _c("span", [
        _vm._v(
          " 成设养难类多消角你这最强党示二农自统九影七张始立验因区地片件际法到容眼叫意单资总他王离还拉据管五达单再。住设声委济情六极议持风还百部史理验色低型系前格表别儿资准近水强米难此记程美号易实眼器却般步在把省现。响并例段气数运流说周么率标示认展论性事红意导比务入低国量建温步想满件体使于管达白义飞值立各林山看真金铁色海太教已研价华。化程质行管部只当成革具风她花地那十大状转积火史龙点况火开代器立识机然去周林才门老基题育各于常年车直对许圆者织无分元社共级但打。除应观低老群界得五改结是其养没质家节半入见度记美关龙见满导例期引众五全具近用厂律都许知因治走织查传共部很圆圆。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail_request detail_i" }, [
      _c("h5", [_vm._v("学时要求")]),
      _c("span", [_vm._v("50学时")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "items_right" }, [
      _c("div", { staticClass: "item_name ell" }, [
        _vm._v("产业互联网——构建智能+时代数字生态新图景"),
      ]),
      _c("div", { staticClass: "items_bottom_box" }, [
        _c("div", { staticClass: "items_bottom_box_left" }, [
          _c("div", { staticClass: "item_center" }, [
            _c("div", { staticClass: "teacher" }, [_vm._v("张三老师")]),
            _c("div", { staticClass: "job" }, [_vm._v("xxxx高级讲师")]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "xs" }, [
              _c("i", { staticClass: "icon iconfont icon-shijian2" }),
              _vm._v(" 2学时 "),
            ]),
            _c("div", { staticClass: "ckl" }, [
              _c("i", { staticClass: "icon iconfont icon-yanjing1" }),
              _vm._v(" 200 "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "items_bottom_box_right" }, [
          _c("div", { staticClass: "rate" }, [_vm._v("42%")]),
          _c("div", { staticClass: "font" }, [_vm._v("当前进度")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "score" }, [
      _c("div", { staticClass: "total_score" }, [_vm._v("总分：100分")]),
      _c("div", { staticClass: "pass_score" }, [_vm._v("及格分数：60分")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "score_num" }, [
      _c("div", { staticClass: "total_num" }, [_vm._v("考试次数：5次")]),
      _c("div", { staticClass: "have_num" }, [_vm._v("已考/剩余次数：1/4次")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }