<template>
  <div class="dsf_ncpc_special_announcement_notice" v-if="isDesign">
    <div class="announcement_notice_title">
      <span class="font">{{ titleName }}</span>
    </div>
    <div class="message_top">
      <el-checkbox class="message_checkbox" v-model="checked" shape="square">只看未读(2)</el-checkbox>
      <div class="font">全部标为已读</div>
    </div>
    <div class="message_content">
      <ul class="message_wrap">
        <li class="message_items">
          <div class="title_box">
            <span class="title ell">
              <span class="font">开班提醒</span>
              <i class="is_new"></i>
            </span>
            <span class="date">2014-05-20</span>
          </div>
          <div class="content ell">与其等待与能力匹配的机会，不如培养与机会匹配的能力与其等待与能力匹配的机会，不如培养与机会匹配的能力</div>
        </li>
        <li class="message_items">
          <div class="title_box">
            <span class="title ell">
              <span class="font ell">结业提醒</span>
              <i class="is_new"></i>
            </span>
            <span class="date">2014-05-20</span>
          </div>
          <div class="content ell">您已经成功结业啦！</div>
        </li>
        <li class="message_items">
          <div class="title_box">
            <span class="title ell"><span class="font">其他</span></span>
            <span class="date">2014-05-20</span>
          </div>
          <div class="content ell">与其等待与能力匹配的机会，不如培养与机会匹配的能力与其等待与能力匹配的机会，不如培养与机会匹配的能力</div>
        </li>
      </ul>
    </div>
  </div>
  <div class="dsf_ncpc_special_announcement_notice" v-else>
    <div class="announcement_notice_title">
      <span class="font">{{ titleName }}</span>
    </div>
    <div class="message_top">
      <el-checkbox class="message_checkbox" v-model="checked" shape="square" @change="clickCheckBox">只看未读({{ noReadTotal || 0 }})</el-checkbox>
      <div class="font" @click="markReadAll">全部标为已读</div>
    </div>
    <div class="message_content" v-if="messageLists && messageLists.length">
      <ul class="message_wrap">
        <li class="message_items" v-for="(item, index) in messageLists" :key="item._id" @click="handleClick(item)">
          <div class="title ell">
            <i :class="['is_new', { active: item.status != 1 }]"></i>
            <span class="font ell">
              {{ item.title || "" }}
            </span>
          </div>
          <div class="date">{{ dsf.date.format(item.sendDateTime, "yyyy-mm-dd") }}</div>
        </li>
      </ul>
      <div class="announcement_notice_page" :style="{ 'text-align': paginationPosition }">
        <el-pagination
          ref="pagination"
          :class="[paginationStyle]"
          :hide-on-single-page="false"
          :total="pageCount"
          :page-size="pageSize"
          :page-sizes="limits"
          :current-page.sync="currentPage"
          :layout="'slot,' + pagerLayout"
          @size-change="pageSizeChange"
          @current-change="pageSizeChange"
        ></el-pagination>
      </div>
    </div>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcSpecialAnnouncementNotice",
  ctrlCaption: "通知公告",
  mixins: [$mixins.control],
  props: {
    titleName: {
      type: String,
      default: "通知公告",
    },
    // 分页位置
    paginationPosition: {
      type: String,
      default: "right",
    },
    // 分页样式
    paginationStyle: {
      type: String,
      default: "style1",
    },
    //分页页码按钮最多显示个数
    pagerCount: {
      type: Number,
      default: 5,
    },
    //一页显示多少条记录
    pageSize: {
      type: Number,
      default: 12,
    },
    // 每页显示条数(逗号分隔)
    limits: {
      type: Array,
      default() {
        return [12, 24, 36, 48, 60, 100];
      },
    },
    // 分页组建的布局,自定义分页排版(逗号分隔)
    pagerLayout: {
      type: String,
      default: "prev,pager,next,jumper",
    },
  },
  data() {
    return {
      params: {
        type: "announcement.class.notice",
        pageNum: 1,
        pageSize: 10,
      },
      currentPage: 1,
      pageCount: 0,
      url: "message/info/getMessageList",
      noReadTotal: 0,
      messageLists: [],
      checked: false,
    };
  },
  computed: {},
  created() {
    if (!this.isDesign) {
      this.params.pageSize = this.pageSize;
      this.queryMessageTotal(this.checked);
      this.initData();
    }
  },
  methods: {
    initData() {
      this.dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          let { success, data, message } = res;
          if (success) {
            this.messageLists = data;
          } else {
            dsf.layer.message(message || "获取数据异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "获取数据异常", false);
        });
    },
    queryMessageTotal(type) {
      this.dsf.http
        .get(
          "message/info/queryMessageTotal",
          {
            status: 0,
            // type: "announcement,read,workrecord,all",
            type: this.params.type,
          },
          $$webRoot.nc
        )
        .done(res => {
          let { success, data, message } = res;
          if (success) {
            // this.noReadTotal = data.announcement;
            this.noReadTotal = data[this.params.type];
            // this.pageCount = data.all;
            if (this.params.status == "0") {
              this.pageCount = data[this.params.type];
            }
          } else {
            dsf.layer.message(message || "获取数据异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "获取数据异常", false);
        });
      this.dsf.http
        .get(
          "message/info/queryMessageTotal",
          {
            // status: 0,
            // type: "announcement,read,workrecord,all",
            type: this.params.type,
          },
          $$webRoot.nc
        )
        .done(res => {
          let { success, data, message } = res;
          if (success) {
            // this.noReadTotal = data.announcement;
            // this.noReadTotal = data["announcement.class.notice"];
            // this.pageCount = data.all;

            if (this.params.status != "0") {
              this.pageCount = data[this.params.type];
            }
          } else {
            dsf.layer.message(message || "获取数据异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "获取数据异常", false);
        });
    },
    pageSizeChange(evt) {
      this.params.pageNum = evt;
      this.initData();
    },
    markRead(id) {
      this.dsf.http
        .post("/message/info/markRead", { id }, $$webRoot.nc)
        .done(res => {
          let { success, data, message } = res;
          if (!success) {
            dsf.layer.message(message || "获取数据异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "获取数据异常", false);
        });
    },
    handleClick(item) {
      // this.$open({ url: item.url });

      console.log("handleClick=====>", item);
      this.markRead(item._id);
      let title = item.moduleName;
      dsf.layer
        .confirm({
          title,
          message: `<div style="color: #606266; font-size: 14px; line-height: 22px; padding-bottom: 25px">${item.content || "暂无内容"}</div>`,
          customClass: "dsf_nc_announcement_confirm",
        })
        .then(() => {})
        .catch(() => {});
    },
    clickCheckBox() {
      this.queryMessageTotal(this.checked);
      if (this.checked) {
        this.params.status = 0;
        this.initData();
      } else {
        this.params.status = "";
        this.initData();
      }
    },
    markReadAll() {
      this.dsf.http
        .post("/nc/index/notice/markReadAll", { wfid: this.params.type }, $$webRoot.nc)
        .done(res => {
          let { success, data, message } = res;
          this.initData();
          this.queryMessageTotal(this.checked);
          if (!success) {
            dsf.layer.message(message || "操作异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "操作异常", false);
        });
    },
  },
});
</script>
