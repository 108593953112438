<template>
  <div class="dsf-nc-dataGrid-child-list">
    <div class="dsf-nc-dataGrid-child-list-left">
      <div class="dsf-nc-dataGrid-child-list-left-date">
        <div>{{ date.year }}-{{ date.month }}</div>
        <div>{{ date.day }}</div>
        <div>{{ date.year }}-{{ date.month }}-{{ date.day }}</div>
      </div>
    </div>
    <div class="dsf-nc-dataGrid-child-list-right" :class="{'checkbox': $list.checkboxColumn.show}">
      <div class="dsf-nc-dataGrid-child-list-right-checkbox" v-if="$list.checkboxColumn.show">
        <el-checkbox v-model="isSelected" :disabled="disabled"></el-checkbox>
      </div>
      <div class="dsf-nc-dataGrid-child-list-header">
        <div class="dsf-nc-dataGrid-child-list-header-title">
          {{ isDesign ? '梦创双杨' : getVal('name') }}
        </div>
        <DsfButtonBar
          class="dsf-nc-dataGrid-child-list-header-btns"
          :max-number="$list.rowButtonsMaxNumber"
          :more-button-text="$list.rowButtonsMoreText"
          :more-button-style="$list.rowButtonsMoreStyle"
          :buttons="getRowButtons()"
          :local="local"
          emit-prefix="row-button"
          align="center">
        </DsfButtonBar>
      </div>
      <div class="dsf-nc-dataGrid-child-list-body">
        <div class="dsf-nc-dataGrid-child-list-body-tips">温馨提示</div>
        <div class="dsf-nc-dataGrid-child-list-body-data">
          <div class="dsf-nc-dataGrid-child-list-body-data-item">
            <span class="label">报名人数</span>
            <span class="value">49</span>
          </div>
          <div class="dsf-nc-dataGrid-child-list-body-data-item">
            <span class="label">实际报道人数</span>
            <span class="value">48</span>
          </div>
          <div class="dsf-nc-dataGrid-child-list-body-data-item">
            <span class="label">评价情况</span>
            <span class="value">!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default dsf.component({
  name: "DsfNcDataGridChildList",
  ctrlCaption: "数据列表-单列列表",
  mixins: [$mixins.control, $mixins.dataGridChildControl],
  props: {},
  computed: {
    date() {
      if (this.isDesign) {
        return { year: '2020', month: '12', day: '31' };
      }
      let d = this.getVal('ds_create_time');
      if (!d) {
        return { year: '', month: '', day: '' };
      }
      d = d.replace(/\s\S*$/, '').split('-');
      return { year: d[0], month: d[1], day: d[2] };
    }
  }
});
</script>