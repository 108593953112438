<template>
  <div v-if="isDesign" class="ds_teacher_detail_new">
    <div class="ds_teacher_detail_inner">
      <div class="detail_inner">
        <div class="cover">
          <DsfNcDefaultHeader
            class="default-avatar"
            :src="''"
            :width="'180px'"
            :height="'240px'"
            :round="false"
            :defaultHeaderUrl="dsf.config.setting_public_user_default_header"
          />
        </div>
        <div class="desc">
          <div class="desc_top">
            <div class="top_warp">
              <h3 class="desc-name">
                <span class="name">田新</span>
                <span class="position">董事长、博士生导师</span>
              </h3>
              <button class="subcrible">立即订阅</button>
            </div>
            <div class="dept ell">
              <span>海底捞创始人、海底捞餐饮股份有限公司</span>
            </div>
            <div class="abstracr ell4">
              <span class="font">
                1994年创建以经营川味火锅为主的餐饮企业海底捞。2020年4月6日，张勇、舒萍夫妇以1150亿元人民币财富名列《胡润全球百强企业家》第62位。2020年5月12日，张勇/舒萍以1172.1亿元人民币位列2020新财富500富人榜第10位；张勇、舒萍夫妇以190亿美元财富位列《2020福布斯新加坡富豪榜》第1位。八十年代初，在简阳城关镇第二中学就读的他，已经为自己树立了人生目标。
              </span>
            </div>
          </div>
          <div class="tags" v-if="adeptText">
            <span class="tag" v-for="item in adeptText" :key="item">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="info_box">
      <div class="info_inner">
        <div class="item course">
          <span>31</span>
          <span>课程</span>
        </div>
        <div class="item live">
          <span>35</span>
          <span>直播</span>
        </div>
        <div class="item resouce">
          <span>37</span>
          <span>资料</span>
        </div>
        <div class="item score">
          <span>2.43</span>
          <span>总体评分</span>
        </div>
        <div class="item view">
          <span>3870</span>
          <span>浏览量</span>
        </div>
        <div class="item subscrible">
          <span>4145</span>
          <span>订阅量</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ds_teacher_detail_new">
    <div class="ds_teacher_detail_inner">
      <div class="detail_inner">
        <div class="cover">
          <DsfNcDefaultHeader class="default-avatar" :src="it.photo" :width="'180px'" :height="'240px'" :round="false" :defaultHeaderUrl="imgErrSrc" />
        </div>
        <div class="desc">
          <div class="desc_top">
            <div class="top_warp">
              <h3 class="desc-name">
                <span class="name">{{ it.name }}</span>
                <span class="position">{{ it.position }}</span>
              </h3>
              <button class="subcrible" @click="toSubscrible(it)">{{ it["mySubscribed"] ? "取消订阅" : "立即订阅" }}</button>
            </div>
            <div class="dept ell">
              <span>{{ it.deptText }}</span>
            </div>
            <div class="abstracr ell4">
              <span class="font ell4" v-html="it.abstracts || ''"></span>
              <span v-if="it.abstracts && it.abstracts.length > 220" class="more" @click="dialogTeacherVisible = true">查看更多</span>
            </div>
          </div>
          <el-dialog title="师资介绍" class="teacher_dialog" :visible.sync="dialogTeacherVisible" width="30%" append-to-body>
            <span class="dialog_font" v-html="it.abstracts"></span>
          </el-dialog>
          <div class="tags" v-if="it.adeptText">
            <span class="tag" v-for="item in it['adeptText'].split('^')" :key="item">{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="info_box"></div>
    </div>
    <div class="info_box">
      <div class="info_inner">
        <div class="item course">
          <span>{{ it.courseCount || 0 }}</span>
          <span>课程</span>
        </div>
        <div class="item live">
          <span>{{ it.liveCount || 0 }}</span>
          <span>直播</span>
        </div>
        <div class="item resouce">
          <span>{{ it.fileCount || 0 }}</span>
          <span>资料</span>
        </div>
        <div class="item score">
          <span>{{ it.avgScore || 0 }}</span>
          <span>总体评分</span>
        </div>
        <div class="item view">
          <span>{{ it.hits || 0 }}</span>
          <span>浏览量</span>
        </div>
        <div class="item subscrible">
          <span>{{ it.subscription || 0 }}</span>
          <span>订阅量</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcTeacherDetail",
  ctrlCaption: "师资详情顶部",
  mixins: [$mixins.control, $mixins.card],
  props: {
    bgImage: {
      type: String,
      default: "../../../static/img/product/teacher-detail-bg.png",
    },
  },
  data() {
    return {
      it: {},
      queryiId: this.$route.query.id,
      dialogTeacherVisible: false,
      adeptText: ["数字经济", "教育", "电商", "企业"],
      imgErrSrc:
        dsf.config.setting_nc_image_teacher_header_img || dsf.config.setting_public_teacher_default_header || dsf.config.setting_public_user_default_card_header || this.errorSrc,
    };
  },
  mounted() {
    if (!this.isDesign) {
      this.adeptText = [];
      this.loadTeacherDetail();
    }
  },
  methods: {
    loadTeacherDetail(type) {
      const sourceUrl = "nc/teacher/info";
      const params = {
        id: this.queryiId,
      };
      if (type) {
        params["type"] = 1;
      }
      const loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            // console.log("message=====>", data);
            this.it = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(message, false);
      }
    },
    toSubscrible(it) {
      // this.$openDialog({
      //   title: "分享卡片",
      //   width: "455px",
      //   height: "auto",
      //   content: "DsfNcTeacherShare",
      //   params: {
      //     type: "teacher",
      //     clickConfig: "#/pc/nc/pageteacher/teachersDetail?id=@[teacherId]",
      //     id: this.$route.query.id || this.queryString.id || "",
      //     cd: res => {
      //       console.log("cd=====>", res);
      //     },
      //   },
      // });
      // // this.$open({ url: "#/pc/nc/page/pc/teacher/teacherShareCard?id=" + this.$route.query.id || this.queryString.id || "", state: 3, width: "500px" });
      // return;

      let that = this;
      const sourceUrl = "/dsfa/subscribe/saveOrCancel";
      const params = {
        ywlxValue: "teacher",
        ywlxText: "师资",
        ywid: it.teacherId,
      };
      const loading = dsf.layer.pc.loading();
      dsf.http
        .post(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.it.mySubscribed = data.isDo;
            dsf.layer.pc.message(message);
            let timer = setTimeout(() => {
              that.loadTeacherDetail("1");
              clearTimeout(timer);
            }, 1000);
          } else {
            error();
          }
        })
        .error(err => {
          error();
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error() {
        dsf.layer.pc.message("订阅失败", false);
      }
    },
  },
});
</script>
