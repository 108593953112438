<template>
  <div class="dsf_search_column" v-if="isDesign">
    <div class="item_inner">
      <!-- @click="$emit('toDetail', item.id)" -->
      <div class="item-left-img">
        <el-image :src="'' | imgFormat"></el-image>
        <span class="item-type">课程</span>
        <span class="item-time">
          <dsf-icon name="shijian" />
          00:20:21
        </span>
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">我是一个标题</span>
          <span class="task">任务</span>
        </div>
        <p class="item-teacher-name">
          <span>张三</span>
          <span>项目经理</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <dsf-icon name="shijian" />
            5学时
          </li>
          <li>
            <dsf-icon name="dianzan_kuai" />
            10
          </li>
          <li>
            <dsf-icon name="pinglun" />
            15
          </li>
        </ul>
      </div>
      <div class="circle-wrap">
        <el-progress
          width="60"
          stroke-width="5"
          :color="curColor"
          type="circle"
          :percentage="33"
          class="progress-circle"
        ></el-progress>
        <p>当前进度</p>
      </div>
    </div>
  </div>
  <div class="dsf_search_column" v-else>
    <div class="item_inner">
      <div class="item-left-img" @click="toDetail(data)">
        <dsf-image
          :src="data['cover'] | imgFormat"
          :error-src="dsf.config.setting_nc_image_default_img"
        />
        <span class="item-type">{{ data.typename }}</span>
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">{{ data.title }}</span>
        </div>
        <p class="ds-introduction" v-if="pageType=='inland'">
          以习近平新时代中国特色社会主义思想为指导，深入学习贯彻党的十九大和十九届二中、三中、四中、五中、六中全会精神，持续开展党史学习教育，牢固树立“四个意识”，坚定“四个自信”，做到“两个维护”，准确把握全面从严治党工作要求和税务系统党的建设工作规范以及意识形态、政务公开、保密管理、舆情应对等工作的新形势和新要求，熟练掌握办公室（党委办公室）工作基本制度、基本流程，切实提升组织、沟通、协调、执行、写作等办文办事办会基本能力，更好履行办公室工作职责，服务学院教育培训事业高质量发展。
        </p>
        <p class="item-name">
          <!-- <span>{{ data.skjs || 0 }}门课程</span>
          <span>{{ data.cJobUnit || 0 }}个学时</span> -->
        </p>
        <!-- <ul class="item-bottom-desc">
          <li>{{ data.clickcs || 0 }}人参与</li>
        </ul> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dsfSearchChannelColunm",
  ctrlCaption: "频道卡片",
  props: {
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pageType:{
      type:String,
      default:"tacitly"
    },
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toDetail(item) {
      this.$emit("toDetail", { name: "channel", id: item.id });
    },
  },
};
</script>
