<template>
  <div class="dsf_specialdetail_intro_teacher" :style="{ width: itemWidth, display: visible ? 'block' : 'none', margin: itemSpace.replace(/,/g, ' ') }">
    <div class="intro_title">
      <div class="desc">
        <span class="font">{{ title }}</span>
        <span class="more" v-if="showMore" @click="!isview && !!showMore && clickMore()"></span>
      </div>
      <span class="line" v-if="showLine"></span>
    </div>
    <ul class="teacher_wrap" v-if="lists.length">
      <li class="teacher_items" v-for="(item, index) in lists" :key="index">
        <div class="head_img">
          <DsfNcImage :src="item.photo | imgFormat" :error-src="dsf.config.setting_public_user_default_card_header" width="40px" height="40px" class="img_box" />
        </div>
        <div class="font name">{{ item.name }}</div>
        <div class="font phone" v-if="item.phone">
          <span v-if="!item.chekPhone">{{ getPhone(item.phone, 0) }}</span>
          <span v-else>{{ getPhone(item.phone, 1) }}</span>
          &nbsp;&nbsp;
          <dsf-icon name="biyan" v-if="!item.chekPhone" @click="!isview && checkPhone(index)"></dsf-icon>
          <dsf-icon name="yanjing1" v-else @click="checkPhone(index)"></dsf-icon>
        </div>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default dsf.component({
  name: "DsfNcSpecialDetailIntroTeacher",
  ctrlCaption: "专题班详情简介教师",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "带教老师",
    },
    url: {
      type: String,
      default: "/nc/class/teacher/list",
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lists: [
        {
          cover: "",
          name: "nc超级管理员",
          phone: "13162123382",
        },
        {
          cover: "",
          name: "张老师",
          phone: "13162123382",
        },
      ],
      params: {
        id: "",
      },
      chekPhone: true,
      isview: false,
    };
  },
  created() {
    this.params.id = this.queryString.id || "36f9cc6f479d41c3a7def8b9232da784";
    this.isview = this.queryString.isview == 1 ? true : false;
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  computed: {
    ...mapGetters(["getSpecialInfo"]),
  },
  methods: {
    reData() {
      this.initData();
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    checkPhone(index) {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      if (this.checkSpecialState()) {
        return;
      }
      this.lists[index]["chekPhone"] = !this.lists[index]["chekPhone"];
    },
    clickMore() {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      console.log("点击更多了---");
      // this.$open({ url: this.moreUrl });
      let { url, state } = this.moreUrl;
      this.$open({ url, state });
    },
    checkSpecialState() {
      // 查看专题班的状态
      if (this.getSpecialInfo.state === 0 || this.getSpecialInfo.state === 4 || this.getSpecialInfo.state === -1 || this.getSpecialInfo.state === 5) {
        // 报名成功前
        return true;
      } else {
        return false;
      }
    },
    getPhone(data, type) {
      console.log("teacher===>", this.getSpecialInfo);
      if (this.checkSpecialState() && type == 1) {
        return;
      }

      let phone = dsf.base64.decode(data);
      let text = "";
      if (type) {
        text = phone;
      } else {
        if (phone) {
          text = phone.substr(0, 3) + "****" + phone.substr(7);
        }
      }
      return text;
    },
  },
  watch: {
    lists: {
      handler(val) {
        val.forEach(element => {
          this.$set(element, "chekPhone", false);
        });
      },
      immediate: true,
    },
  },
});
</script>
