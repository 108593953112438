var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds_newsListModel ds-control", style: _vm.controlStyle },
    [
      _c(
        "div",
        { staticClass: "left-tab" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              attrs: {
                "default-active": _vm.defaultActive,
                "unique-opened": "",
                "default-openeds": _vm.defaultOpeneds,
              },
              on: { select: _vm.selectMenu },
            },
            [
              _vm._l(_vm.menus, function (item) {
                return [
                  item.children && item.children.length
                    ? _c(
                        "el-submenu",
                        { key: item.id, attrs: { index: item.id } },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("dsf-icon", { attrs: { name: item.icon } }),
                              _c("span", [_vm._v(_vm._s(item.name))]),
                            ],
                            1
                          ),
                          _c(
                            "el-menu-item-group",
                            _vm._l(item.children, function (i) {
                              return _c(
                                "el-menu-item",
                                { key: i.id, attrs: { index: i.id } },
                                [_vm._v(_vm._s(i.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      )
                    : _c(
                        "el-menu-item",
                        { key: item.id, attrs: { index: item.id } },
                        [
                          _c("dsf-icon", { attrs: { name: item.icon } }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ],
                        1
                      ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.newsList.length
            ? [
                _c(
                  "div",
                  { staticClass: "ds_newsListModel-box" },
                  _vm._l(_vm.newsList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "ds_newsListModel-row",
                        style: _vm.rowStyle,
                        on: {
                          click: function ($event) {
                            return _vm._clickBefore(_vm.goDetail, item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "title-box" }, [
                          _c("p", { staticClass: "title ell2" }, [
                            _vm._v(_vm._s(item[_vm.keyField.title])),
                          ]),
                          _c(
                            "div",
                            { staticClass: "img-video" },
                            [
                              item.type === "1"
                                ? _vm._l(
                                    item[_vm.keyField.imgArray] || [],
                                    function (i, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: idx,
                                          staticClass:
                                            "cover-img more-img-cover",
                                        },
                                        [
                                          _c("dsf-nc-image", {
                                            attrs: {
                                              src: _vm._f("imgFormat")(
                                                i[_vm.keyField.imgMore]
                                              ),
                                              "error-src": _vm.defaultSrc,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : item.type === "2" && item.video
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "cover-img" },
                                      [
                                        _c("dsf-nc-image", {
                                          attrs: {
                                            src: _vm._f("imgFormat")(
                                              item.cover
                                            ),
                                            "error-src": _vm.defaultSrc,
                                          },
                                        }),
                                        _c("dsf-icon", {
                                          staticClass: "play",
                                          attrs: { name: "icon-play" },
                                        }),
                                        _c("div", {
                                          staticClass: "cover-mask",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : item &&
                                  item[_vm.keyField.cover] &&
                                  item[_vm.keyField.cover].length > 2
                                ? _c(
                                    "div",
                                    { staticClass: "cover-img" },
                                    [
                                      _c("dsf-nc-image", {
                                        attrs: {
                                          src: _vm._f("imgFormat")(item.cover),
                                          "error-src": _vm.defaultSrc,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "detail" }, [
                            item[_vm.keyField.source]
                              ? _c("p", { staticClass: "source" }, [
                                  _vm._v(_vm._s(item[_vm.keyField.source])),
                                ])
                              : _vm._e(),
                            _c("p", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(
                                  item[_vm.keyField.time]
                                    ? _vm.dsf.date.format(
                                        item[_vm.keyField.time],
                                        "yyyy.mm.dd"
                                      )
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "ds-datagrid-pager" },
                  [
                    _c("el-pagination", {
                      staticClass: "style3",
                      attrs: {
                        "current-page": _vm.pageNumber,
                        "page-sizes": [10, 20, 30, 40],
                        "page-size": _vm.pageSize,
                        layout: "total, prev, pager, next, jumper,sizes",
                        total: _vm.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _c("div", { staticClass: "noDate" }, [_vm._v("暂无数据")]),
        ],
        2
      ),
      _c("div", { staticClass: "hot" }, [
        _c("div", { class: "insert " + (_vm.isDesign ? "insert-wrap" : "") }, [
          _c(
            "div",
            {
              class: ["insert-content", { "insert-border": _vm.isDesign }],
              attrs: { "slot-name": "hot" },
            },
            [_vm._t("hot")],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }