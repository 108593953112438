var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDesign
    ? _c("div", { staticClass: "ds_instructions_news" }, [
        _c("div", {
          staticClass: "content",
          domProps: { innerHTML: _vm._s(_vm.news.content || "") },
        }),
      ])
    : _c("div", [_vm._v("操作指南富文本内容")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }