var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_check_briefsummary", class: _vm.getCss },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            width: _vm.dialogWidth,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": true,
            "append-to-body": true,
            "custom-class": "nc_pc_briefsummary_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _vm.showTitle
            ? _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.titleName)),
              ])
            : _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.showWrit ? "撰写小结" : "查看小结")),
              ]),
          _vm.showWrit
            ? _c(
                "div",
                { staticClass: "briefsummary_writting" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.formModel,
                        "label-width": "80px",
                        rules: _vm.rules,
                        disabled: _vm.readyonly || _vm.homeworkState == 1,
                      },
                    },
                    [
                      _c("div", { staticClass: "homework_desc" }, [
                        _vm._v(
                          "作业描述：" +
                            _vm._s(_vm.homeWorkDesc.requirements || "暂无数据")
                        ),
                      ]),
                      _c("el-form-item", { staticClass: "dsf_form_item" }, [
                        _c(
                          "div",
                          { staticClass: "conditions_content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "dsf_item_form",
                                attrs: { label: "标题", prop: "title" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "dsf_input",
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.formModel.title,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formModel, "title", $$v)
                                    },
                                    expression: "formModel.title",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { staticClass: "dsf_form_item" }, [
                        _c(
                          "div",
                          { staticClass: "conditions_content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "dsf_item_form",
                                attrs: { label: "内容", prop: "content" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "dsf_input",
                                  attrs: {
                                    type: "textarea",
                                    rows: 10,
                                    resize: "none",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.formModel.content,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formModel, "content", $$v)
                                    },
                                    expression: "formModel.content",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { staticClass: "dsf_btns" },
                        [
                          _c("DsfButtonBar", {
                            attrs: {
                              buttons: _vm.buttons,
                              "max-number": _vm.maxNumber,
                            },
                            on: {
                              save: function ($event) {
                                return _vm.onSubmit("form")
                              },
                              close: _vm.onClose,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showRead
            ? _c("div", { staticClass: "nc_pc_check_briefsummary_dialog" }, [
                _c("div", { staticClass: "content_wrapper" }, [
                  _c("div", { staticClass: "teach" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "score",
                          _vm.checkForm.state_value == "2"
                            ? "pass"
                            : _vm.checkForm.state_value == "-1"
                            ? "nopass"
                            : "",
                        ],
                      },
                      [
                        _c("div", { staticClass: "left" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("分数: "),
                          ]),
                          _vm.checkForm.score_type_value == 2
                            ? _c("span", { staticClass: "font" }, [
                                _vm._v(
                                  _vm._s(_vm.checkForm.score || "0") + "分"
                                ),
                              ])
                            : _c("span", { staticClass: "font" }, [
                                _vm._v(_vm._s(_vm.checkForm.score || "暂无")),
                              ]),
                        ]),
                        _c("div", { staticClass: "right" }),
                      ]
                    ),
                    _c("div", { staticClass: "py" }, [
                      _c("span", { staticClass: "label" }, [_vm._v("评语: ")]),
                      _c("span", { staticClass: "font" }, [
                        _vm._v(_vm._s(_vm.checkForm.reviews || "暂无")),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "briefsummayr" }, [
                    _c("div", { staticClass: "xiaojieTitle" }, [
                      _vm._v(_vm._s(_vm.checkForm.title)),
                    ]),
                    _c("div", { staticClass: "xiaojie_content" }, [
                      _vm._v(_vm._s(_vm.checkForm.content)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "dsf_btns" },
                    [
                      _c("DsfButtonBar", {
                        attrs: {
                          buttons: _vm.checkButtons,
                          "max-number": _vm.maxNumber,
                        },
                        on: {
                          reclick: function ($event) {
                            return _vm.onReSubmit()
                          },
                          close: function ($event) {
                            return _vm.onClose()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }