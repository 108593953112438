var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds_teacher_detail_new" }, [
        _c("div", { staticClass: "ds_teacher_detail_inner" }, [
          _c("div", { staticClass: "detail_inner" }, [
            _c(
              "div",
              { staticClass: "cover" },
              [
                _c("DsfNcDefaultHeader", {
                  staticClass: "default-avatar",
                  attrs: {
                    src: "",
                    width: "180px",
                    height: "240px",
                    round: false,
                    defaultHeaderUrl:
                      _vm.dsf.config.setting_public_user_default_header,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "desc" }, [
              _vm._m(0),
              _vm.adeptText
                ? _c(
                    "div",
                    { staticClass: "tags" },
                    _vm._l(_vm.adeptText, function (item) {
                      return _c("span", { key: item, staticClass: "tag" }, [
                        _vm._v(_vm._s(item)),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(1),
      ])
    : _c("div", { staticClass: "ds_teacher_detail_new" }, [
        _c("div", { staticClass: "ds_teacher_detail_inner" }, [
          _c("div", { staticClass: "detail_inner" }, [
            _c(
              "div",
              { staticClass: "cover" },
              [
                _c("DsfNcDefaultHeader", {
                  staticClass: "default-avatar",
                  attrs: {
                    src: _vm.it.photo,
                    width: "180px",
                    height: "240px",
                    round: false,
                    defaultHeaderUrl: _vm.imgErrSrc,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "desc" },
              [
                _c("div", { staticClass: "desc_top" }, [
                  _c("div", { staticClass: "top_warp" }, [
                    _c("h3", { staticClass: "desc-name" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.it.name)),
                      ]),
                      _c("span", { staticClass: "position" }, [
                        _vm._v(_vm._s(_vm.it.position)),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "subcrible",
                        on: {
                          click: function ($event) {
                            return _vm.toSubscrible(_vm.it)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.it["mySubscribed"] ? "取消订阅" : "立即订阅"
                          )
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "dept ell" }, [
                    _c("span", [_vm._v(_vm._s(_vm.it.deptText))]),
                  ]),
                  _c("div", { staticClass: "abstracr ell4" }, [
                    _c("span", {
                      staticClass: "font ell4",
                      domProps: { innerHTML: _vm._s(_vm.it.abstracts || "") },
                    }),
                    _vm.it.abstracts && _vm.it.abstracts.length > 220
                      ? _c(
                          "span",
                          {
                            staticClass: "more",
                            on: {
                              click: function ($event) {
                                _vm.dialogTeacherVisible = true
                              },
                            },
                          },
                          [_vm._v("查看更多")]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "el-dialog",
                  {
                    staticClass: "teacher_dialog",
                    attrs: {
                      title: "师资介绍",
                      visible: _vm.dialogTeacherVisible,
                      width: "30%",
                      "append-to-body": "",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogTeacherVisible = $event
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "dialog_font",
                      domProps: { innerHTML: _vm._s(_vm.it.abstracts) },
                    }),
                  ]
                ),
                _vm.it.adeptText
                  ? _c(
                      "div",
                      { staticClass: "tags" },
                      _vm._l(_vm.it["adeptText"].split("^"), function (item) {
                        return _c("span", { key: item, staticClass: "tag" }, [
                          _vm._v(_vm._s(item)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "info_box" }),
        ]),
        _c("div", { staticClass: "info_box" }, [
          _c("div", { staticClass: "info_inner" }, [
            _c("div", { staticClass: "item course" }, [
              _c("span", [_vm._v(_vm._s(_vm.it.courseCount || 0))]),
              _c("span", [_vm._v("课程")]),
            ]),
            _c("div", { staticClass: "item live" }, [
              _c("span", [_vm._v(_vm._s(_vm.it.liveCount || 0))]),
              _c("span", [_vm._v("直播")]),
            ]),
            _c("div", { staticClass: "item resouce" }, [
              _c("span", [_vm._v(_vm._s(_vm.it.fileCount || 0))]),
              _c("span", [_vm._v("资料")]),
            ]),
            _c("div", { staticClass: "item score" }, [
              _c("span", [_vm._v(_vm._s(_vm.it.avgScore || 0))]),
              _c("span", [_vm._v("总体评分")]),
            ]),
            _c("div", { staticClass: "item view" }, [
              _c("span", [_vm._v(_vm._s(_vm.it.hits || 0))]),
              _c("span", [_vm._v("浏览量")]),
            ]),
            _c("div", { staticClass: "item subscrible" }, [
              _c("span", [_vm._v(_vm._s(_vm.it.subscription || 0))]),
              _c("span", [_vm._v("订阅量")]),
            ]),
          ]),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc_top" }, [
      _c("div", { staticClass: "top_warp" }, [
        _c("h3", { staticClass: "desc-name" }, [
          _c("span", { staticClass: "name" }, [_vm._v("田新")]),
          _c("span", { staticClass: "position" }, [
            _vm._v("董事长、博士生导师"),
          ]),
        ]),
        _c("button", { staticClass: "subcrible" }, [_vm._v("立即订阅")]),
      ]),
      _c("div", { staticClass: "dept ell" }, [
        _c("span", [_vm._v("海底捞创始人、海底捞餐饮股份有限公司")]),
      ]),
      _c("div", { staticClass: "abstracr ell4" }, [
        _c("span", { staticClass: "font" }, [
          _vm._v(
            " 1994年创建以经营川味火锅为主的餐饮企业海底捞。2020年4月6日，张勇、舒萍夫妇以1150亿元人民币财富名列《胡润全球百强企业家》第62位。2020年5月12日，张勇/舒萍以1172.1亿元人民币位列2020新财富500富人榜第10位；张勇、舒萍夫妇以190亿美元财富位列《2020福布斯新加坡富豪榜》第1位。八十年代初，在简阳城关镇第二中学就读的他，已经为自己树立了人生目标。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info_box" }, [
      _c("div", { staticClass: "info_inner" }, [
        _c("div", { staticClass: "item course" }, [
          _c("span", [_vm._v("31")]),
          _c("span", [_vm._v("课程")]),
        ]),
        _c("div", { staticClass: "item live" }, [
          _c("span", [_vm._v("35")]),
          _c("span", [_vm._v("直播")]),
        ]),
        _c("div", { staticClass: "item resouce" }, [
          _c("span", [_vm._v("37")]),
          _c("span", [_vm._v("资料")]),
        ]),
        _c("div", { staticClass: "item score" }, [
          _c("span", [_vm._v("2.43")]),
          _c("span", [_vm._v("总体评分")]),
        ]),
        _c("div", { staticClass: "item view" }, [
          _c("span", [_vm._v("3870")]),
          _c("span", [_vm._v("浏览量")]),
        ]),
        _c("div", { staticClass: "item subscrible" }, [
          _c("span", [_vm._v("4145")]),
          _c("span", [_vm._v("订阅量")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }