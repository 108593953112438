<template>
  <div class="dsf_ncpc_manage_teachresource" v-if="visible" :style="{ width: itemWidth }">
    <ul class="manage_resource_wrap">
      <li class="resource_items" v-for="(item, index) in lists" :key="index" @click="clickItem(item)">
        <div class="items_left">
          <i class="icon"></i>
          <div class="font">{{ item.name || "" }}（{{ item.sum || 0 }}）</div>
        </div>
        <div class="add_new" @click.stop="clickNewEdit(item)">
          <i class="add_icon icon iconfont icon-tianjiawenjian"></i>
          <div class="add_font">新建</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import newEditResource from "./newEditResource";
export default dsf.component({
  name: "DsfNcPcTeachResource",
  ctrlCaption: "教学资源库",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "教学资源库",
    },
    url: {
      type: String,
      default: "/nc/menu/count/role/code?code=011",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lists: [
        {
          code: "exam",
          name: null,
          params: null,
          picture: null,
          skip: null,
          sum: 0,
          url: null,
        },
      ],
      // params: { code: "011" },
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickItem(item) {
      let { code } = item;
      let url = "";
      switch (code) {
        case "courses": // 课程
          url = "/index.html#/pc/nc/courses/info/tree";
          break;
        case "live": // 直播
          url = "/index.html#/pc/nc/live/info/main";
          break;
        case "teacher": // 师资
          url = "/index.html#/pc/nc/teacher/list";
          break;
        case "exam": // 考试
          let exam = `${location.origin}/exam/page.html#/pc/exam/manage/list?type=1`;
          // url = `/index.html#/iframeloader?src=${encodeURIComponent(exam)}`;
          url = `/index.html#/iframeloader?src=%2Fexam%2Fpage.html%23%2Fpc%2Fexam%2Fmanage%2Flist%3Ftype%3D1`;
          // url = "/exam/page.html#/pc/exam/manage/list?type=1";
          // dsf.layer.openWindow(":" + url);
          break;
        case "class": // 专题班
          url = "/index.html#/pc/nc/class/list";
          break;
        case "channel": // 频道
          url = "/index.html#/pc/nc/channel/list";
          break;
        default:
          break;
      }
      // if (code != "exam") {
      this.$open({ url, state: 2 });
      // }
      // console.log("点击某一项 打开列表页面", url, item);
    },
    clickNewEdit(item) {
      let { code } = item;
      let url = newEditResource(code);
      if (code != "exam") {
        this.$open({ url, state: 2 });
      }
      // console.log("点击新建", item, url);
    },
  },
});
</script>
