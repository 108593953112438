var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_saas_footer saas_setting_footer_title" },
    [
      _c(
        "div",
        { staticClass: "title_text_radio" },
        [
          _c(
            "el-radio-group",
            {
              on: { change: _vm.change },
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            [
              _c("el-radio", { attrs: { label: _vm.index } }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "title_button",
          on: {
            click: function ($event) {
              return _vm.$emit("setBtn", _vm.index)
            },
          },
        },
        [_vm._v(_vm._s(_vm.btnText))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }