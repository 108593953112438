<template>
  <div class="dsf_period_list" v-if="visible" :style="controlStyle">
    <ul class="peridod_list_wrap">
      <li class="lists_items" v-for="(item, index) in lists" :key="index" :style="itemsStyle">
        <div class="name">{{ item.unitName || "" }}</div>
        <div class="xx">
          选修需完成
          <input
            type="text"
            v-model="item.electivesPeriod"
            :placeholder="item.electivesPeriod"
            @change="eletivesChange(item.electivesPeriod, item)"
            onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
          />
          / {{ item.elePeriods || 0 }} 学时
        </div>
        <div class="bx">
          必修需完成
          <!-- <input type="text" class="compulsory"v-model="item.compulsoryPeriod" :placeholder="item.compulsoryPeriod" @change="compulsoryChange(item.compulsoryPeriod, item)" /> -->
          {{ item.compulsoryPeriod || 0 }}
          / {{ item.comPeriods || 0 }} 学时
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPeriodList",
  ctrlCaption: "学时列表",
  mixins: [$mixins.layout],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "/nc/pack/unit/getList",
    },
    margin: {
      default: () => [10, 10, 10, 100],
    },
    padding: {
      default: () => [10, 10, 10, 100],
    },
  },
  computed: {
    controlStyle() {
      let { margin, isDesign } = this;
      let style = {
        margin: margin.join("px ") + "px",
      };
      if (isDesign) {
        style.margin = "10px 10px 10px 10px";
      }
      return style;
    },
    itemsStyle() {
      let { padding, isDesign } = this;
      let style = {
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "10px 10px 10px 10px";
      }
      return style;
    },
  },
  data() {
    return {
      lists: [
        {
          unitName: "第一单元",
          compulsoryPeriod: "2.5",
          compulsoryHursed: "2",
          electivesPeriod: "2.5",
          electiveHursed: "1.5",
          comPeriods: "5",
          elePeriods: "3",
          unitId: "188852c8ae6a4938b38cc9ee69b107551",
        },
      ],
      params: {
        id: "",
      },
      unitLists: [
        {
          unitName: "第一单元",
          compulsoryPeriod: "2.5",
          compulsoryHursed: "2",
          electivesPeriod: "2.5",
          electiveHursed: "1.5",
          comPeriods: "5",
          elePeriods: "3",
          unitId: "188852c8ae6a4938b38cc9ee69b107551",
        },
      ],
      compulsoryValue: "", // 必修
      electivesValue: "", //选修
    };
  },
  components: {},
  created() {
    this.params.id = this.queryString["id"] || "4ea119c0df9f4840a575f06baa0b8ca6";
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.unitLists = [];
      this.initData();
    }
  },
  watch: {
    unitLists: {
      handler: function (val) {
        let obj = {};
        this.lists = val.reduceRight((items, next) => {
          obj[next.unitId] ? "" : (obj[next.unitId] = true && items.push(next));
          return items;
        }, []);

        this.$emit("eletivesChange", this.lists);
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            this.lists = res.data;
            this.unitLists = this.lists;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    eletivesChange(val, item) {
      if (Number(val) < Number(item.elePeriods) || Number(val) == Number(item.elePeriods)) {
        this.unitLists.push(item);
      } else {
        item.electivesPeriod = item.elePeriods;
        this.unitLists.push(item.elePeriods);
      }
      // let obj = {};
      // let unitLists = this.unitLists.reduceRight((items, next) => {
      //   obj[next.unitId] ? "" : (obj[next.unitId] = true && items.push(next));
      //   return items;
      // }, []);

      // this.$emit("eletivesChange", val, item, unitLists);
    },
    compulsoryChange(val, item) {
      console.log("135135===>", val, item);
      this.$emit("compulsoryChange", val, item);
    },
  },
});
</script>
