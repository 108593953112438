var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_userTitle" },
    [
      _c("div", { staticClass: "dsf_userTitle-title" }, [
        _c("span", { staticClass: "dsf_userTitle-txt" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm.showMore
          ? _c("span", { staticClass: "dsf_userTitle-icon pointer" }, [
              _c(
                "span",
                { staticClass: "more-text", on: { click: _vm.goDetail } },
                [_vm._v("更多")]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改" + _vm.title,
            visible: _vm.dialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("iframe", {
            attrs: {
              src: _vm.moreUrl,
              frameborder: "0",
              width: "100%",
              height: "600px",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }