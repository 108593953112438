<template>
  <div v-show="showBar" class="ds-control ds-button-bar" :class="getCss">
    <div class="bar-wrap">
      <template v-for="(button, $index) in showButtonsReal">
        <template v-if="button.isUpload">
          <el-upload
            :key="$index"
            class="ds-button-update"
            :headers="getHeaders()"
            :accept="getFileAccept(button)"
            :data="getUploadData(button)"
            :show-file-list="false"
            :action="uploadUrl(button)"
            :on-success="handleAvatarSuccess(button)"
            :before-upload="beforeAvatarUpload(button)"
          >
            <DsfButton
              :icon="getIcon(button)"
              :title="button.title"
              :disabled="button.disabled"
              :size="size"
              :type="button.type"
              :btn-style="button.btnStyle"
            >
              {{ button.text }}
            </DsfButton>
          </el-upload>
        </template>
        <template
          v-else-if="
            button.isLinkSend && button.isLinkSendFold && (button.actionName === 'system_flow_send' || button.actionName === 'system_batch_flow_send')
          "
        >
          <el-popover
            :key="$index"
            v-model="button.popVisible"
            :popper-class="'ds-morebutton-popover'"
            trigger="manual"
            placement="top"
            :class="['ds-button__flow']"
          >
            <template v-if="flowButtons.length > 0" v-slot:default>
              <el-row :style="{ width: flowButtons.length > 5 ? '240px' : '80px' }">
                <el-col v-for="(flow, index) in flowButtons" :key="index" :span="flowButtons.length > 5 ? 8 : 24" style="text-align: center">
                  <template>
                    <DsfButton
                      :key="index"
                      style="width: 80px; margin-bottom: 5px; margin-top: 5px"
                      :icon="getIcon(flow)"
                      :disabled="flow.disabled"
                      :size="size"
                      :type="flow.type"
                      :title="flow.title"
                      :btn-style="'icon-text'"
                      @click="clickHandler(flow, $event)"
                    >
                      {{ flow.text }}
                    </DsfButton>
                  </template>
                </el-col>
              </el-row>
            </template>
            <DsfButton
              slot="reference"
              :icon="getIcon(button)"
              :title="button.title"
              :disabled="button.disabled"
              :size="size"
              :type="button.type"
              :btn-style="button.btnStyle"
              @click="addFlowBtns(button)"
            >
              {{ button.text }}
            </DsfButton>
          </el-popover>
        </template>
        <template v-else-if="button.actionName === 'show-course-type'">
          <DsfButton
            :key="$index"
            :icon="getIcon(button)"
            :title="button.title"
            :disabled="button.disabled"
            :size="size"
            :type="button.type"
            :btn-style="button.btnStyle"
            :style="button.style"
            @click="showCorseType(button, $event)"
          >
            {{ button.text }}
          </DsfButton>
        </template>
        <template v-else>
          <DsfButton
            :key="$index"
            :icon="getIcon(button)"
            :title="button.title"
            :disabled="button.disabled"
            :size="size"
            :type="button.type"
            :btn-style="button.btnStyle"
            :style="button.style"
            @click="clickHandler(button, $event)"
          >
            {{ button.text }}
          </DsfButton>
        </template>
      </template>
      <el-popover
        v-if="moreButtonsReal.length"
        :popper-class="'ds-morebutton-popover'"
        width="auto"
        trigger="click"
        :class="['ds-button__more', size]"
        @show="popoverShow"
        @hide="popoverClose"
      >
        <template v-slot:default>
          <el-row :style="{ width: moreButtonsReal.length > 5 ? '240px' : '80px' }">
            <el-col v-for="(button, $index) in moreButtonsReal" :key="$index" :span="moreButtonsReal.length > 5 ? 8 : 24" style="text-align: center">
              <template v-if="button.isUpload">
                <el-upload
                  :key="$index"
                  class="ds-button-update"
                  :headers="getHeaders()"
                  :accept="getFileAccept(button)"
                  :data="getUploadData(button)"
                  :show-file-list="false"
                  :action="uploadUrl(button)"
                  :on-success="handleAvatarSuccess(button)"
                  :before-upload="beforeAvatarUpload(button)"
                >
                  <DsfButton
                    :icon="getIcon(button)"
                    :title="button.title"
                    :disabled="button.disabled"
                    :size="size"
                    :type="button.type"
                    :btn-style="'icon-text'"
                    style="width: 80px; margin-bottom: 5px; margin-top: 5px"
                  >
                    {{ button.text }}
                  </DsfButton>
                </el-upload>
              </template>
              <template v-else>
                <DsfButton
                  :key="$index"
                  style="width: 80px; margin-bottom: 5px; margin-top: 5px"
                  :icon="getIcon(button)"
                  :disabled="button.disabled"
                  :size="size"
                  :type="button.type"
                  :title="button.title"
                  :btn-style="'icon-text'"
                  @click="clickHandler(button, $event)"
                >
                  {{ button.text }}
                </DsfButton>
              </template>
            </el-col>
          </el-row>
        </template>
        <DsfButton
          v-if="moreButtonsReal.length && moreButtonStyle != 'icon-text'"
          slot="reference"
          :title="moreButtonText"
          :btn-style="moreButtonStyle"
          :icon="moreFold ? 'shuangjiantouxia' : 'xiangshangzhanhang'"
          :size="size"
          class="plain"
          :class="[moreButtonStyle]"
        >
          {{ moreButtonText }}
        </DsfButton>
        <DsfButton
          v-else-if="moreButtonsReal.length && moreButtonStyle == 'icon-text'"
          slot="reference"
          :title="moreButtonText"
          :btn-style="moreButtonStyle"
          icon="gengduo1"
          :size="size"
          class="plain"
          :class="[moreButtonStyle]"
        >
          {{ moreButtonText }}
        </DsfButton>
      </el-popover>
      <!-- @click.stop="showCourseType = false" -->
      <div>
        <el-dialog :visible.sync="showCourseType" :show-close="false" :append-to-body="true" class="ds_buttons_dialog">
          <div class="choose-dialog">
            <div class="choose-inner">
              <div class="item" v-for="item in showCards" :key="item.name">
                <template v-if="item.isUpload">
                  <el-upload
                    key="courseUpload"
                    class="ds-button-update"
                    :headers="getHeaders()"
                    :accept="item.fileAccept"
                    :show-file-list="false"
                    :action="item.uploadUrl"
                    :on-success="itemUploadSuccess"
                    :on-error="itemUploadError"
                    :before-upload="itemBeforeUpload"
                    :auto-upload="true"
                  >
                    <div class="ds-icon-name-box">
                      <i :class="`ds_icon ${item.icon}`"></i>
                      <span class="name">{{ item.name }}</span>
                    </div>
                    <div class="label">{{ item.remark }}</div>
                  </el-upload>
                </template>
                <template v-else>
                  <div class="ds-button-dialog" @click="openDialog(item)">
                    <div class="ds-icon-name-box">
                      <i :class="`ds_icon ${item.icon}`"></i>
                      <span class="name">{{ item.name }}</span>
                    </div>
                    <div class="label">{{ item.remark }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
// import { getButtonHander } from "../../../register/buttonRegister";
/**
 * @class DsfButtonBar
 * @description 按纽组
 * @example
 * <DsfButtonBar></DsfButtonBar>
 */
export default dsf.component({
  name: "DsfNcButtons",
  ctrlCaption: "按纽组",
  mixins: [$mixins.control, $mixins.buttonBar],
  /**
   * @memberof DsfButtonBar
   * @name Props
   * @property {Object} [owner = null] 所有者
   * @property {String} [size = ''] 大小
   * @property {String} [align = center] 居中方式
   * @property {Array} [buttons = []] 按钮
   * @property {String} [emitPrefix = ''] 前缀
   * @property {Function} [renderButton = null] 渲染按钮回调掉方法
   */
  props: {
    size: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "center",
    },
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
    emitPrefix: {
      type: String,
      default: "",
    },
    renderButton: {
      type: Function,
      default: null,
    },
    maxNumber: {
      type: Number,
      default: 4,
    },
    moreButtonStyle: {
      type: String,
      default: "",
    },
    moreButtonText: {
      type: String,
      default: "更多",
    },
    courseButtons: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    isShowCourseType() {
      let button = _.filter(this.buttons, (button, index) => {
        if (button.actionName == "show-course-type") {
          return true;
        } else {
          return false;
        }
      });
      return button.length > 0;
    },
    owner() {
      return this.$buttonOwner;
    },
    showBar() {
      if (this.isDesign) {
        return true;
      } else {
        return this.validButtons.length > 0;
      }
    },
    getCss() {
      return {
        ...this.$getClass(),
        ["align-" + this.align]: true,
      };
    },
    validButtons() {
      let buttons = _.filter(this.buttons, (button, index) => {
        if (this.showAndHide(button)) {
          this.disabledFactor(button);
          return true;
        }
        return false;
      });
      return buttons;
    },
    moreButtonsReal() {
      return _.filter(this.validButtons, (button, index) => {
        return index >= this.maxNumber;
      });
    },
    showButtonsReal() {
      return _.filter(this.validButtons, (button, index) => {
        return index < this.maxNumber;
      });
    },
  },
  data() {
    return {
      showButtons: [],
      moreButtons: [],
      moreFold: true,
      flowButtons: [],
      buttonHandler: dsf.buttons.getButtonHander,
      client: "pc",
      flowCurInfo: null,
      showCourseType: false,
      showCards: [
        {
          icon: "icon-icon_video",
          name: "视频",
          fileAccept: "video/*",
          params: "",
          uploadUrl: "/file/upload",
          isUpload: true,
          remark: "支持mp4、avi、等其它各种格式的视频，单个文件不能超过5G",
        },
        {
          icon: "icon-icon_audio",
          name: "音频",
          fileAccept: "audio/*",
          params: "",
          uploadUrl: "/file/upload",
          isUpload: true,
          remark: "支持mp3、asas、等其它各种格式的视频，单个文件不能超过5G",
        },
        {
          icon: "icon-icon_richMedia",
          name: "富媒体",
          fileAccept: "",
          params: "",
          isUpload: false,
          remark: "支持各类主流视频网站链接，请勿设置不安全的网站链接",
        },
        {
          icon: "icon-icon_manuscripts",
          name: "文稿",
          fileAccept: "",
          params: "",
          isUpload: false,
          remark: "支持word、pdf、txt等主流文档格式，单个文件大小不能超过100M",
        },
        {
          icon: "icon-icon_other",
          name: "其他",
          fileAccept: "",
          params: "",
          isUpload: false,
          remark: "其它资源链接，请勿设置不安全的链接",
        },
      ],
      loading: null,
    };
  },
  created() {
    this.courseButtons = changeUploadUrl(this.courseButtons);
  },
  mounted() {
    if (this.isShowCourseType && this.courseButtons.length) {
      this.showCards = changeUploadUrl(this.courseButtons);
    }
  },
  updated() {
    // console.log(this.buttons)
  },
  watch: {
    showCards: {
      handler: function (items) {
        if (items) {
          items.forEach(element => {
            if (element.isUpload) {
              element.uploadUrl = dsf.url.getWebPath(element.uploadUrl);
            }
          });
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {},
  methods: {
    reloadData() {
      this.$forceUpdate();
    },
    showCorseType() {
      this.showCourseType = true;
    },
    saveCoursewareByFile(param) {
      let url = "nc/courseware/saveCoursewareByFile";
      let params = {
        fileJson: param,
      };
      let loading = dsf.layer.pc.loading();
      dsf.http
        .post(url, params, $$webRoot.nc)
        .done(res => {
          let { data } = res;
          if (!res.success) {
            this.error(res);
          } else {
            // this.$dispatch("uploadSuccess", data);
            this.$emit("uploadSuccess", data);
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    openDialog(item) {
      let { url, opentype } = item;
      this.$open({ url, state: opentype });
      this.showCourseType = false;
    },
    itemUploadSuccess(it) {
      // console.log(it);
      dsf.layer.pc.closeLoading(this.loading);
      this.saveCoursewareByFile(JSON.stringify(it.data));
      this.showCourseType = false;
      // this.$dispatch("uploadSuccess");
      // this.$emit("uploadSuccess");
    },
    itemUploadError() {
      dsf.layer.pc.closeLoading(this.loading);
    },
    itemBeforeUpload() {
      this.loading = dsf.layer.pc.loading();
      // console.log(11111111);
    },
    disabledFactor(button) {
      let result = false;
      if (!this.isDesign) {
        //非设计模式下运行表达式
        if (button.disabledFactor) {
          let obj = {
            $vm: {
              value: this.$vm,
            },
          };
          let data = Object.create(this.local, obj);
          result = this.$eval(button.disabledFactor, data) !== false;
        } else {
          result = false;
        }
      }
      button.disabled = result;
    },
    showAndHide(button) {
      // console.log(button);
      let result = true;
      if (!this.isDesign) {
        //非设计模式下运行表达式
        if (button.if) {
          let obj = {
            $vm: {
              value: this.$vm,
            },
          };
          let data = Object.create(this.local, obj);
          result = this.$eval(button.if, data) !== false;
        } else {
          if (button.syncRead && (this?.owner?.readOnly || this?.$vm?.$data?.$forceReadOnly)) {
            result = false;
          } else {
            result = true;
          }
        }
      }
      // 流程按钮需要判断
      if (result && button.isSystem && (button.actionName.indexOf("system_flow") > -1 || button.actionName.indexOf("system_batch_flow") > -1)) {
        result = this.hideFlowBtn(button);
      }
      if (button.visible === false) {
        result = false;
      }
      if (this.renderButton) {
        result = this.renderButton(button, result);
      }
      return result;
    },
    getIcon(button) {
      let icon = button.icon;
      if (icon && icon.startsWith("icon-")) {
        return icon.replace("icon-", "");
      }
      return icon;
    },
    popoverShow() {
      this.moreFold = false;
    },
    popoverClose() {
      this.moreFold = true;
    },
    //触发指定按钮的点击事件
    triggerButtonClick(button) {
      this.clickHandler(button);
    },

    //上传类按钮处理 start
    uploadUrl(button) {
      let params = this.getButtonParams(button);
      let url = params[0];
      //this.$replace(button.params[0].value || button.params[0].default);
      return dsf.url.getWebPath(url);
    },
    getFileAccept(button) {
      let params = this.getButtonParams(button);
      let allowList = params[2]?.split("|");
      let map = _.map(allowList, el => {
        return dsf.mime[el.toLowerCase()];
      });
      return map.join(",");
    },
    beforeAvatarUpload(button) {
      return (file => {
        let params = this.getButtonParams(button);
        let allowList = params[2]?.split("|");
        let mark = false;
        for (let i = 0; i < allowList.length; i++) {
          if (file.name.toLowerCase().endsWith("." + allowList[i].toLowerCase())) {
            mark = true;
            break;
          }
        }
        if (!mark) {
          dsf.layer.message("只能上传" + allowList.join(",") + "类型的文件", false);
          return false;
        }
      }).bind(this);
    },
    handleAvatarSuccess(button) {
      return ((res, file) => {
        this.clickHandler(button, undefined, res);
      }).bind(this);
    },
    getHeaders() {
      return dsf.getDefaultHttpHeader();
    },
    getUploadData(button) {
      let params = this.getButtonParams(button);
      let data = params[1];
      data = dsf.url.queryStringParse(data);
      return data;
    },
    hidePopVisible(button) {
      this.buttons.forEach(btn => {
        if (btn.popVisible) {
          btn.popVisible = false;
        }
      });
      document.removeEventListener("click", this.hidePopVisible);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
function changeUploadUrl(arr = []) {
  const localOrigin = location.origin;
  if (localOrigin.indexOf("xuexibang") > -1) {
    const uploadUrl = dsf.config.setting_nc_public_upload_url || "/file/upload";
    _.each(arr, it => {
      if (it.isUpload) {
        it.uploadUrl = uploadUrl;
      }
    });
  }
  return arr;
}
</script>
