<template>
  <div class="dsf_special_detail" :style="{ display: visible ? 'block' : 'none' }">
    <div class="special_detail_innerwrap" :style="{ width: itemWidth }">
      <div class="detail_imgbox cover">
        <!-- <dsf-image :src="detailData.cover | imgFormat" width="530px" height="280px" /> -->
        <dsf-nc-image :src="detailData.cover | imgFormat" width="530px" height="280px" :error-src="defaultSrc" />
      </div>
      <div class="detail_conent">
        <div class="detail_name ell">{{ detailData.name }}</div>
        <div class="bm_date font">报名日期：{{ setDate(detailData.bmsjSdate, detailData.bmsjEdate, "至") }}</div>
        <div class="px_date font">培训日期：{{ setDate(detailData.pxsjSdate, detailData.pxsjEdate, "至") }}</div>
        <div class="jytj font">
          结业条件：选修 {{ detailData.electives || 0 }}&nbsp;&nbsp;&nbsp; 必修 {{ detailData.compulsory || 0 }}&nbsp;&nbsp;&nbsp; 学时
          {{ detailData.period }}
        </div>
        <div class="student font">学员人数：{{ detailData.studentCount }}</div>
        <div class="foot_wrap">
          <ul class="labe_wrap">
            <li class="labe_item" v-for="(item, index) in labelLists" :key="index">{{ item }}</li>
          </ul>
          <div class="btn_box">
            <div
              class="btn"
              @click="!isview && clickEnter(detailData)"
              v-if="setStatus(detailData) && isshowbtn && detailData.state != 2"
              :style="`${
                setStatus(detailData) == '待审核' ||
                setStatus(detailData) == '已结业' ||
                setStatus(detailData) == '审核中' ||
                setStatus(detailData) == '已拒绝' ||
                detailData.state == -1 ||
                (detailData.state == 0 && detailData.signStatus != 1) ||
                (detailData.state == 2 && detailData.trainingStatus != 1) ||
                isview
                  ? 'background: #aaa; cursor: default'
                  : ''
              }`"
            >
              {{ setStatus(detailData) }}
            </div>
            <div class="reason" v-if="detailData.state == 5">{{ detailData.reason }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default dsf.component({
  name: "DsfNcPcSpecialDetail",
  ctrlCaption: "专题班详情",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/info",
    },
    enterStudyUrl: {
      type: Object,
      default: () => ({
        url: `page.html#/pc/nc/pagecourse/coursePlayer?id=@[lastCourseId]&type=ztb`,
      }),
    },
    visible: {
      type: Boolean,
      default: true,
    },
    isshowbtn: {
      type: Boolean,
      default: true,
    },
    itemWidth: {
      type: String,
      default: "1200px",
    },
  },
  data() {
    return {
      detailData: {},
      params: {
        id: "",
      },
      personalInfoUrl: "/nc/class/apply/info",
      labelLists: [],
      defaultSrc: dsf.config.setting_nc_image_default_img,
      clickApplyBtn: false,
      // isShowPersonInforForm: false,
      isview: false,
    };
  },
  created() {
    this.params.id = this.queryString.id || "b9b594f762d241f49189236b0d841c4f";
    this.isview = this.queryString.isview == 1 ? true : false;
  },
  async mounted() {
    if (!this.isDesign) {
      // this.initData();
      await this.specialInfo(this.params);
      this.detailData = this.getSpecialInfo;

      this.labelLists = this.detailData.labelsText ? this.detailData.labelsText.split("^") : [];

      this.$nextTick(() => {
        this.$page.resize();
      });
    }
  },
  computed: {
    ...mapGetters(["getSpecialInfo", "getSpecialApplyState", "getSpecialApplyInfor"]),
  },
  watch: {
    getSpecialInfo: {
      handler(data) {
        if (data) {
          this.detailData = data;
        }
      },
      immediate: true,
    },
    getSpecialApplyState: {
      handler(data) {
        if (data && !this.isDesign) {
          this.specialInfo(this.params);
        }
      },
      immediate: true,
    },
    getSpecialApplyInfor: {
      handler(data) {
        if (data && this.detailData.state == 0 && this.detailData.signStatus == 1 && this.clickApplyBtn) {
          // 未报名且点击报名表单后处理
          if (dsf.isEmptyObject(data.items) || data.items != "[]") {
            this.$openDialog({
              title: "填写个人信息",
              width: "520px",
              height: "auto",
              content: "DsfNcPersonalInformationForm",
              params: {
                cb: data => {
                  if (data) {
                    this.specialApply({ id: this.params.id, data });
                  }
                },
              },
              dialogArgs: {},
            });
          } else {
            this.specialApply(this.params);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["specialInfo", "specialApply", "specialApplyInfo"]),
    clickEnter(item) {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      // console.log("点击按钮", item);
      let status = item.state; // 状态
      let id = item.classId; //专题班id
      let courseId = item.lastCourseId; // 课程id
      let signStatus = item.signStatus; // 1 能报名
      // let trainingStatus = item.trainingStatus; //1能学课
      let url = "";

      if (status == 0 && signStatus == 1) {
        this.clickApplyBtn = true; // 申请按钮
        this.specialApplyInfo({ id: this.params.id });
      }
      // else if (status == 2 && trainingStatus == 1) {
      //   let type = item.lastResourceType;

      //   switch (type) {
      //     case "course": // 课程
      //       url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${courseId}&classid=${id}&type=ztb`;
      //       break;
      //     case "video": // 视频
      //       url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${courseId}&classid=${id}&type=ztb`;
      //       break;
      //     case "audio": // 音频
      //       url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${courseId}&classid=${id}&type=ztb`;
      //       break;
      //     case "draft": // 文稿
      //       url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${courseId}&classid=${id}&type=ztb`;
      //       break;
      //     case "media": // 富媒体
      //       url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${courseId}&classid=${id}&type=ztb`;
      //       break;
      //     case "live": // 直播
      //       url = `/page.html#/pc/nc/pagelive/liveDetail?id=${courseId}`;
      //       break;
      //     case "exam": // 考试
      //       this.toExamDetail(item, id);
      //       break;
      //     default:
      //       break;
      //   }
      //   // 进入学习
      //   this.$open({ url, state: 2 });
      // }
    },
    toExamDetail(item) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: item["lastCourseId"], //考试id
        businessId: item["lastResourceSubId"], //业务id
        businessType: 1,
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    setDate(startDate, endDate, interval) {
      // 设置日期格式
      let start = startDate ? startDate.slice(0, 10) : "";
      let end = endDate ? endDate.slice(0, 10) : "";
      return start && end ? `${start}  ${interval}  ${end}` : "暂无";
    },
    setStatus(data) {
      // 设置状态
      let status = data.state; // 状态
      let studentCount = data.studentCount; // 学员人数
      let sfxzrs = data.txrs; //是否有人数限制
      let html = "";
      switch (status) {
        case -1:
          html = "立即报名";
          break;
        case 0:
          if (sfxzrs && studentCount >= sfxzrs) {
            html = "人数已满";
            return;
          }
          html = "报名";
          break;
        case 1:
          html = "待审核";
          break;
        case 2:
          html = "开始学习";
          break;
        case 3:
          html = "已结业";
          break;
        case 4:
          html = "审核中";
          break;
        case 5:
          html = "已拒绝";
          break;
        default:
          html = "";
      }
      return html;
    },
  },
});
</script>
