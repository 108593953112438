import { render, staticRenderFns } from "./coursewarePlayer.vue?vue&type=template&id=a02bdf66&"
import script from "./coursewarePlayer.vue?vue&type=script&lang=js&"
export * from "./coursewarePlayer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/base/course/coursewarePlayer.vue"
export default component.exports