var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_liveDetail" }, [
        _c("div", { staticClass: "live-top" }, [
          _c(
            "div",
            { staticClass: "live-top-inner", attrs: { id: "LiveHeader" } },
            [
              _c("div", { staticClass: "live-top-inner-l ell" }, [
                _c(
                  "button",
                  {
                    staticClass: "live-btn live-btn-playback",
                    class:
                      _vm.state.value == 0
                        ? "live-btn-appoint"
                        : "live-btn-playback",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.state.value == 1
                            ? "正在直播"
                            : _vm.state.value == 0
                            ? "未开始"
                            : "直播回放"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "live-title",
                    attrs: { title: _vm.liveInfo.title },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.liveInfo.title || "暂无标题")),
                    ]),
                    _vm.assessId
                      ? _c(
                          "div",
                          { staticClass: "header-rate" },
                          [
                            _c("el-rate", {
                              attrs: {
                                max: 5,
                                disabled: "",
                                "show-score": "",
                                "text-color": "#ff9900",
                                "allow-half": "",
                              },
                              model: {
                                value: _vm.liveRate,
                                callback: function ($$v) {
                                  _vm.liveRate = $$v
                                },
                                expression: "liveRate",
                              },
                            }),
                            _c("span", [_vm._v("分")]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _c("div", { staticClass: "live-top-inner-r" }, [
                _vm.state.value == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "pingjia",
                        on: {
                          click: function ($event) {
                            return _vm.handleComment()
                          },
                        },
                      },
                      [_vm._m(0), _c("span", [_vm._v("评价")])]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "dingyue" },
                  [
                    _c("dsf-icon", { staticClass: "icon-yanjing1" }),
                    _c("span", [_vm._v(_vm._s(_vm.views || 0))]),
                    _vm.state.value == 0
                      ? _c(
                          "button",
                          {
                            staticClass: "live-btn live-btn-handle-appoint",
                            on: { click: _vm.liveAppointHandle },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(!_vm.subscribe ? "订阅" : "取消订阅") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "live-content" }, [
          _vm.getMask && _vm.isLook == "1"
            ? _c(
                "div",
                {
                  staticClass: "live-box",
                  style: { width: _vm.conWidth, height: _vm.playerHeight },
                },
                [
                  _c("div", { staticClass: "live-cover img-box" }, [
                    _c(
                      "div",
                      { staticClass: "pointer" },
                      [
                        _c("dsf-nc-image", {
                          attrs: {
                            src: _vm._f("imgFormat")(_vm.liveInfo.cover),
                            "error-src": _vm.defaultSrc,
                          },
                        }),
                        _c("div", { staticClass: "live-time-wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass: "live-time-inner",
                              on: { click: _vm.checkLogin },
                            },
                            [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.state.value === 2 ||
                                      _vm.state.value === 3
                                      ? "回放中"
                                      : "直播中"
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.state.value === 2 ||
                                      _vm.state.value === 3
                                      ? "点击进入回放"
                                      : "点击进入直播"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "live-box",
                  style: { width: _vm.conWidth, height: _vm.playerHeight },
                },
                [
                  _vm.getLivePlayer
                    ? _c("DsfNcLivePlayer", {
                        attrs: {
                          playerWidth: "100%",
                          isDesign: _vm.isDesign,
                          ckplayerWarmUp: _vm.ckplayerWarmUp,
                          playerHeight: _vm.playerHeight,
                          channelId: _vm.liveInfo.channelValue,
                          liveInfo: _vm.liveData,
                          enableAsk: _vm.enableAsk,
                          userPic: _vm.userPic,
                        },
                        on: { liveEnd: _vm.liveEnd },
                      })
                    : _vm._e(),
                  _vm.videoShow
                    ? _c("DsfNcCkplayer", {
                        ref: "coursePlayer",
                        attrs: {
                          width: "100%",
                          ctrl_tab_stop: _vm.altTabStop,
                          hint: false,
                          height: _vm.playerHeight,
                          playerParams: _vm.playerParams,
                          videoSource: _vm.videoSource,
                          videoStatus: false,
                        },
                        on: {
                          playerPlayingTime: _vm.playerPlayingTime,
                          playerHasPlay: _vm.playerHasPlay,
                          playerHasPause: _vm.playerHasPause,
                          playHasEnd: _vm.playHasEnd,
                        },
                      })
                    : _vm._e(),
                  _vm.state.value == 0
                    ? _c(
                        "div",
                        { staticClass: "live-cover img-box" },
                        [
                          _c("el-image", {
                            attrs: {
                              src: _vm._f("imgFormat")(_vm.liveInfo.cover),
                              fit: "cover",
                            },
                          }),
                          _c("div", { staticClass: "live-time-wrap" }, [
                            _c("div", { staticClass: "live-time-inner" }, [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.liveInfo.stateValue == 0
                                      ? "该直播间被停用"
                                      : "未开始"
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  "开播时间: " +
                                    _vm._s(
                                      !!_vm.liveInfo.timeSdate
                                        ? _vm.liveInfo.timeSdate
                                        : "开播时间未定"
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getLiveState
                    ? _c("div", { staticClass: "live-cover img-box" }, [
                        _vm.liveInfo.stateValue == 0
                          ? _c(
                              "div",
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: _vm._f("imgFormat")(
                                      _vm.liveInfo.cover
                                    ),
                                    fit: "cover",
                                  },
                                }),
                                _c("div", { staticClass: "live-time-wrap" }, [
                                  _c(
                                    "div",
                                    { staticClass: "live-time-inner" },
                                    [
                                      _c("p", { staticClass: "p1" }, [
                                        _vm._v("该直播间被停用"),
                                      ]),
                                      _c("p", { staticClass: "p2" }, [
                                        _vm._v(
                                          "直播时间: " +
                                            _vm._s(_vm.liveInfo.timeSdate || "")
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm.liveInfo.typeValue == 2 &&
                            _vm.state.value !== 0 &&
                            _vm.liveInfo.stateValue != 0
                          ? _c(
                              "div",
                              { staticClass: "pointer" },
                              [
                                _c("dsf-nc-image", {
                                  attrs: {
                                    src: _vm._f("imgFormat")(
                                      _vm.liveInfo.cover
                                    ),
                                    "error-src": _vm.defaultSrc,
                                  },
                                }),
                                _c("div", { staticClass: "live-time-wrap" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "live-time-inner",
                                      on: { click: _vm.goLiveLink },
                                    },
                                    [
                                      _c("p", { staticClass: "p1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.state.value === 2 ||
                                              _vm.state.value === 3
                                              ? "回放中"
                                              : "直播中"
                                          )
                                        ),
                                      ]),
                                      _c("p", { staticClass: "p2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.state.value === 2 ||
                                              _vm.state.value === 3
                                              ? "点击进入回放"
                                              : "点击进入直播"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("dsf-nc-image", {
                                  attrs: {
                                    src: _vm._f("imgFormat")(
                                      _vm.liveInfo.cover
                                    ),
                                    "error-src": _vm.defaultSrc,
                                  },
                                }),
                                _c("div", { staticClass: "live-time-wrap" }, [
                                  _c(
                                    "div",
                                    { staticClass: "live-time-inner" },
                                    [
                                      _c("p", { staticClass: "p1" }, [
                                        _vm._v("直播结束"),
                                      ]),
                                      _c("p", { staticClass: "p2" }, [
                                        _vm._v(
                                          "直播时间: " +
                                            _vm._s(_vm.liveInfo.timeSdate || "")
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
          _c("div", { staticClass: "d-comment-warp" }, [
            _c("div", { staticClass: "d-comment" }, [
              _c("ul", { staticClass: "d-comment-handle" }, [
                _c(
                  "li",
                  {
                    class: { isred: _vm.tag },
                    on: { click: _vm.handleClickTag },
                  },
                  [
                    _c("dsf-icon", {
                      staticClass: "pointer",
                      class: [_vm.tag ? "icon-dianzan1" : "icon-dianzan"],
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "live-dz",
                        attrs: {
                          id: "LiveDz",
                          title: !_vm.tag ? "点赞" : "取消点赞",
                        },
                      },
                      [_vm._v(_vm._s(_vm.tagTotal))]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    class: { isred: _vm.collect },
                    on: { click: _vm.handleClickCollect },
                  },
                  [
                    _c("dsf-icon", {
                      staticClass: "pointer",
                      class: [
                        _vm.collect ? "icon-aixin_shixin" : "icon-shoucang2 ",
                      ],
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "live-sc",
                        attrs: {
                          id: "LiveSc",
                          title: !_vm.collect ? "收藏" : "取消收藏",
                        },
                      },
                      [_vm._v(_vm._s(_vm.collectTotal))]
                    ),
                  ],
                  1
                ),
                _c("li"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "tabs" }, [
          _c("div", { staticClass: "con-tab-head-wrap" }, [
            _c(
              "ul",
              { staticClass: "con-tabs", style: { width: _vm.conWidth } },
              [
                _vm._l(_vm.tabs, function (item, index) {
                  return [
                    item.show
                      ? _c(
                          "li",
                          {
                            key: index,
                            class: { tabHasSel: _vm.activeName == item.id },
                            on: {
                              click: function ($event) {
                                return _vm.handleClickTab(item)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(item.name))])]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "tabs-content", style: { width: _vm.conWidth } },
              [
                _vm.activeName == 1
                  ? _c("div", { staticClass: "introduction" }, [
                      _c("div", { staticClass: "introduction-left" }, [
                        _c("h3", { staticClass: "course-tab-title" }, [
                          _vm._v("直播简介"),
                        ]),
                        _c("div", { staticClass: "course-desc-warp" }, [
                          _c(
                            "div",
                            { staticClass: "live-info live-info-bottom" },
                            [
                              _c("div", { staticClass: "live-info-inner" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "live-desc",
                                    attrs: { id: "LiveIntroduction" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "live-info-sub-title" },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "live-s-title" },
                                          [_vm._v(_vm._s(_vm.liveInfo.title))]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "live-s-time" },
                                          [
                                            _vm._v(
                                              " 开播时间：" +
                                                _vm._s(
                                                  _vm.formatterDate(
                                                    _vm.liveInfo.timeSdate
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  margin: "0 2px",
                                                },
                                              },
                                              [_vm._v("-")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.dsf.date.format(
                                                    _vm.liveInfo.timeEdate,
                                                    "yyyy-mm-dd hh:ii"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("p", {
                                      staticClass: "live-text",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.liveInfo.content),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "introduction-right" },
                        [_c("DsfNcLiveInformation")],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.activeName == 3
                  ? _c("div", { staticClass: "check-box" }, [
                      _vm.checkObj.hasHomeWork == "1" ||
                      _vm.checkObj.hasExam == "1"
                        ? _c("h3", { staticClass: "course-tab-title" }, [
                            _vm._v(" 直播考核 "),
                            _c("span", [
                              _vm._v("完成直播学习后方可参与以下考核"),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "check-items" }, [
                        _vm.checkObj.hasExam == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "check-item pointer",
                                class: { noClick: !_vm.finishedRate },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickMore(
                                      "1",
                                      _vm.checkObj.exam
                                    )
                                  },
                                },
                              },
                              [
                                _vm.checkObj.exam.state_value == "1"
                                  ? _c("div", { staticClass: "successCheck" })
                                  : _vm._e(),
                                _c("dsf-icon", { staticClass: "icon-kaoshi" }),
                                _c("p", { staticClass: "item-title" }, [
                                  _vm._v("直播考试"),
                                ]),
                                _c("p", { staticClass: "item-detail" }, [
                                  _vm._v(
                                    " 【" +
                                      _vm._s(
                                        _vm.checkObj.exam
                                          .exam_necessary_value == 1
                                          ? "必修"
                                          : "选修"
                                      ) +
                                      "】 "
                                  ),
                                  _c("span", { staticClass: "type" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.checkObj.exam.state_value == 1
                                          ? "通过: " +
                                              Number(
                                                _vm.checkObj.exam.score || 0
                                              ) +
                                              "分"
                                          : "待完成"
                                      )
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkObj.hasHomeWork == "1"
                          ? _c(
                              "div",
                              {
                                staticClass: "check-item pointer",
                                class: { noClick: !_vm.finishedRate },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickMore(
                                      "2",
                                      _vm.checkObj.homeWork
                                    )
                                  },
                                },
                              },
                              [
                                _vm.checkObj.homeWork.state_value == "1"
                                  ? _c("div", { staticClass: "successCheck" })
                                  : _vm._e(),
                                _c("dsf-icon", { staticClass: "icon-lianxi" }),
                                _c("p", { staticClass: "item-title" }, [
                                  _vm._v("直播作业"),
                                ]),
                                _c("p", { staticClass: "item-detail" }, [
                                  _vm._v(
                                    " 【" +
                                      _vm._s(
                                        _vm.checkObj.homeWork
                                          .homework_necessary_value == 1
                                          ? "必修"
                                          : "选修"
                                      ) +
                                      "】 "
                                  ),
                                  _c("span", { staticClass: "type" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.checkObj.homeWork.state_value == "1"
                                          ? "通过"
                                          : _vm.checkObj.homeWork.state_text
                                      )
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm.checkObj.hasCert == "1"
                        ? _c("h3", { staticClass: "course-tab-title" }, [
                            _vm._v(" 直播证书 "),
                            _c("span", [
                              _vm._v(
                                "通过直播的学习及考核便有资格获得结业证书。"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.checkObj.hasCert == "1"
                        ? _c(
                            "div",
                            { staticClass: "certificate" },
                            [
                              _c("dsf-nc-image", {
                                attrs: {
                                  src: _vm._f("imgFormat")(
                                    _vm.checkObj.cert &&
                                      _vm.checkObj.cert.certificateurl
                                  ),
                                  "error-src": _vm.errorImg,
                                },
                              }),
                              _vm.checkObj.cert.state_value == 1
                                ? _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.download(_vm.checkObj.cert)
                                        },
                                      },
                                    },
                                    [_vm._v("下载证书")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "note-box" },
                  [
                    _vm.activeName == 2
                      ? _c("dsf-nc-note-item", {
                          attrs: {
                            relationId: _vm.liveId,
                            liveCover: _vm.liveCover,
                            relationTitle: _vm.liveInfo.title,
                            isLive: true,
                            btnWidth: "100px",
                            fontColor: "#333",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.activeName == 3
                  ? _c("comment", { staticClass: "note-box" })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm.mutedPlay
          ? _c("div", { attrs: { id: "isvideo-box" } }, [
              _vm._v(" 是否允许浏览器播放声音? "),
              _c(
                "span",
                {
                  staticClass: "ppt-control-volume-cancel isvideo",
                  on: { click: _vm.handleMuted },
                },
                [_vm._v("播放声音")]
              ),
              _c(
                "span",
                {
                  staticClass: "ppt-control-volume-close isvideoclose",
                  on: {
                    click: function ($event) {
                      _vm.mutedPlay = false
                    },
                  },
                },
                [_vm._v("忽略")]
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "iconfont icon-pingjia1" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }