import { render, staticRenderFns } from "./recentLive.vue?vue&type=template&id=26cb026f&scoped=true&"
import script from "./recentLive.vue?vue&type=script&lang=js&"
export * from "./recentLive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26cb026f",
  null
  
)

component.options.__file = "pc/components/live/recentLive.vue"
export default component.exports