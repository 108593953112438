var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specila_apply ds-control",
      style: { width: _vm.itemWidth, padding: _vm.itemPadding },
    },
    [
      _c("div", { staticClass: "dsf_specila_apply-title" }, [
        _vm._v("是否报名"),
      ]),
      _c("div", { staticClass: "dsf_specila_apply-content" }, [
        _c("div", { staticClass: "item-row" }, [
          _c(
            "div",
            { staticClass: "dsf_specila_apply-item margin-right-30" },
            [
              _c("label", { staticClass: "item-title" }, [_vm._v("允许报名")]),
              _c("el-switch", {
                attrs: { disabled: _vm.isview },
                model: {
                  value: _vm.allowed,
                  callback: function ($$v) {
                    _vm.allowed = $$v
                  },
                  expression: "allowed",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.allowed
        ? _c("div", { staticClass: "dsf_specila_apply-content" }, [
            _c("div", { staticClass: "item-row margin-bottom-10 row-1" }, [
              _c(
                "div",
                { staticClass: "dsf_specila_apply-item margin-right-30 time" },
                [
                  _c("label", { staticClass: "item-title required" }, [
                    _vm._v("报名时间"),
                  ]),
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      align: "right",
                      disabled: _vm.isview,
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dsf_specila_apply-item peopleCheck" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: _vm.isview },
                      model: {
                        value: _vm.reqParams.maxPeopleChecked,
                        callback: function ($$v) {
                          _vm.$set(_vm.reqParams, "maxPeopleChecked", $$v)
                        },
                        expression: "reqParams.maxPeopleChecked",
                      },
                    },
                    [
                      _c("label", { staticClass: "item-title" }, [
                        _vm._v("人数限制"),
                      ]),
                    ]
                  ),
                  _vm.reqParams.maxPeopleChecked
                    ? _c("el-input", {
                        staticClass: "item-input",
                        attrs: {
                          disabled: _vm.isview,
                          placeholder: "请输入人数限制",
                        },
                        model: {
                          value: _vm.reqParams.people,
                          callback: function ($$v) {
                            _vm.$set(_vm.reqParams, "people", $$v)
                          },
                          expression: "reqParams.people",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.rangeShow
              ? _c(
                  "div",
                  {
                    staticClass: "item-row margin-bottom-10",
                    staticStyle: { "margin-bottom": "20px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "dsf_specila_apply-item" },
                      [
                        _c("label", { staticClass: "item-title" }, [
                          _vm._v("报名范围"),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.changeRadio },
                            model: {
                              value: _vm.rangeType,
                              callback: function ($$v) {
                                _vm.rangeType = $$v
                              },
                              expression: "rangeType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("认证校友"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("所有人"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("指定组织"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { class: ["insert", { "insert-wrap": _vm.isDesign }] },
              [
                _vm._l(_vm.slots, function (j, idx) {
                  return [
                    _c(
                      "div",
                      {
                        key: idx,
                        class: [
                          "insert-content",
                          { "insert-border": _vm.isDesign },
                        ],
                        attrs: { "slot-name": j.name },
                      },
                      [
                        _vm.isDesign || _vm.rangeType == 2
                          ? _vm._t(j.name)
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "item-row margin-bottom-10",
                staticStyle: { "margin-bottom": "20px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "dsf_specila_apply-item margin-right-30" },
                  [
                    _c("label", { staticClass: "item-title" }, [
                      _vm._v("报名审核"),
                    ]),
                    _c("el-switch", {
                      attrs: { disabled: _vm.isview },
                      model: {
                        value: _vm.reqParams.audit,
                        callback: function ($$v) {
                          _vm.$set(_vm.reqParams, "audit", $$v)
                        },
                        expression: "reqParams.audit",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "item-row margin-bottom-10 item_flex" }, [
              _c(
                "div",
                { staticClass: "dsf_specila_apply-item margin-right-30" },
                [
                  _c("label", { staticClass: "item-title" }, [
                    _vm._v("报名表单"),
                  ]),
                  _c("el-switch", {
                    attrs: { disabled: _vm.isview },
                    model: {
                      value: _vm.reqParams.form,
                      callback: function ($$v) {
                        _vm.$set(_vm.reqParams, "form", $$v)
                      },
                      expression: "reqParams.form",
                    },
                  }),
                ],
                1
              ),
              _vm.reqParams.form
                ? _c(
                    "div",
                    { staticClass: "review", on: { click: _vm.clickReview } },
                    [_vm._v("预览")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.reqParams.form && _vm.allowed
        ? _c("div", { staticClass: "form-container" }, [
            _c(
              "div",
              { staticClass: "container-left" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("已设置选项")]),
                _vm._m(0),
                _vm._l(_vm.reqParams.items, function (item, index) {
                  return [
                    _c(
                      "div",
                      { key: index, staticClass: "input-row margin-bottom-10" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-width-70 input-title text-center",
                          },
                          [
                            _c("el-checkbox", {
                              attrs: { disabled: item.base || _vm.isview },
                              model: {
                                value: item.required,
                                callback: function ($$v) {
                                  _vm.$set(item, "required", $$v)
                                },
                                expression: "item.required",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "input-width-100 input-title" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "div",
                          { staticClass: "input-width-all input-title" },
                          [
                            _c("el-input", {
                              staticClass: "item-input",
                              attrs: { disabled: _vm.isview },
                              model: {
                                value: item.text,
                                callback: function ($$v) {
                                  _vm.$set(item, "text", $$v)
                                },
                                expression: "item.text",
                              },
                            }),
                          ],
                          1
                        ),
                        !item.base
                          ? _c("dsf-icon", {
                              staticClass: "deleteInput",
                              attrs: { name: "icon_wrong" },
                              on: {
                                click: function ($event) {
                                  return _vm.hanldeDeleteInput(item, index)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
            _c("div", { staticClass: "container-right" }, [
              _c("div", { staticClass: "title" }, [_vm._v("添加选项")]),
              _c("div", { staticClass: "checked-add" }, [
                _c("div", { staticClass: "common margin-bottom-10" }, [
                  _vm._v("常用项"),
                ]),
                _c(
                  "div",
                  { staticClass: "checed-add-container margin-bottom-10" },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: _vm.isview, border: "" },
                        on: { change: _vm.handleChangeCommon },
                        model: {
                          value: _vm.commonCheckboxGroup,
                          callback: function ($$v) {
                            _vm.commonCheckboxGroup = $$v
                          },
                          expression: "commonCheckboxGroup",
                        },
                      },
                      _vm._l(_vm.common, function (item) {
                        return _c(
                          "el-checkbox-button",
                          {
                            key: item.type,
                            staticClass:
                              "margin-bottom-10 margin-5 checkbox-button",
                            attrs: {
                              disabled: _vm.isview,
                              border: "",
                              label: item.name,
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "common margin-bottom-10" }, [
                  _vm._v("自定义项"),
                ]),
                _c(
                  "div",
                  { class: _vm.isview ? "disable" : "custom" },
                  [
                    _vm._l(_vm.custom, function (item) {
                      return [
                        _c(
                          "span",
                          {
                            key: item.type,
                            staticClass: "custom-btn margin-5",
                            on: {
                              click: function ($event) {
                                !_vm.isview && _vm.handleCustom(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.text))]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.btnsShow
        ? _c(
            "div",
            { staticClass: "dsf_specila_apply-btns text-center" },
            [
              _c("DsfButtonBar", {
                staticStyle: { display: "inline-block" },
                attrs: {
                  disabled: _vm.isview,
                  buttons: _vm.buttons,
                  "max-number": _vm.maxNumber,
                },
                on: { save: _vm.sumbitForm, clickBackSetp: _vm.clickBackSetp },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "自定义选项",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": "",
            "before-close": _vm.beforeClose,
            "custom-class": "NcSpecialApplyDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("label", { staticClass: "item-title" }, [_vm._v("报名选项")]),
              _c("el-input", {
                staticClass: "item-input",
                model: {
                  value: _vm.customItem.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.customItem, "name", $$v)
                  },
                  expression: "customItem.name",
                },
              }),
              _vm.customItem.type == "radiobox" ||
              _vm.customItem.type == "checkbox"
                ? _c(
                    "div",
                    { staticClass: "content-add" },
                    [
                      _c("p", { staticClass: "hint" }, [
                        _vm._v(
                          _vm._s(
                            _vm.customItem.type == "radiobox"
                              ? "单选框"
                              : "多选框"
                          ) + "选项列表"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "add-list" },
                        _vm._l(_vm.checkboxList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "add-input-box" },
                            [
                              _c("el-input", {
                                staticClass: "item-input require",
                                attrs: { required: "" },
                                model: {
                                  value: item.text,
                                  callback: function ($$v) {
                                    _vm.$set(item, "text", $$v)
                                  },
                                  expression: "item.text",
                                },
                              }),
                              !item.base
                                ? _c("dsf-icon", {
                                    staticClass: "deleteInput",
                                    attrs: { name: "icon_wrong" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCheckbox(item, index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "add_btn",
                          attrs: { size: "small" },
                          on: { click: _vm.addCheckbox },
                        },
                        [_vm._v("添 加")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.dialogSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-row margin-bottom-10" }, [
      _c("div", { staticClass: "input-width-70 input-title" }, [
        _vm._v("报名者必填"),
      ]),
      _c("div", { staticClass: "input-width-100 input-title ell" }, [
        _vm._v("报名选项"),
      ]),
      _c("div", { staticClass: "input-width-all input-title" }, [
        _vm._v("报名者提示语"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }