var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header_model header_model_type1" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c("dsf-image", {
                key: _vm.logoImg[0] && _vm.logoImg[0].relativePath,
                attrs: { src: _vm.logoImg[0] && _vm.logoImg[0].relativePath },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "login_search" },
            [
              _c("dsf-icon", { attrs: { name: "Magnifier" } }),
              _vm._v(" 登录 "),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "bottom" }, [
          _c("div", { staticClass: "dsf-college-home-header-menu" }, [
            _c("div", {
              staticClass: "dsf-college-home-header-menu-bar",
              style: _vm.barStyle,
            }),
            _c(
              "div",
              { staticClass: "dsf-college-home-header-menu-list" },
              [
                _vm._l(_vm.activeMenuList, function (menu, index) {
                  return [
                    _c(
                      "div",
                      {
                        key: menu["ncSaasSettingMenuId"] + "-" + index,
                        ref: "menu",
                        refInFor: true,
                        staticClass: "dsf-college-home-header-menu-item",
                        class: { active: _vm.activeMenuIndex === index },
                        on: {
                          click: function ($event) {
                            return _vm._clickBefore(
                              _vm.menuItemClick,
                              menu,
                              index
                            )
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(menu["title"]) + " ")]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }