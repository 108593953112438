var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_nc_saas_page_design page_index",
      class: { isDesign: _vm.isDesign },
    },
    _vm._l(_vm.pageList, function (component, index) {
      return _c(
        "div",
        { key: index, staticClass: "design_content" },
        [_c(component.type, { tag: "component", attrs: { data: component } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }