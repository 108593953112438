<template>
  <div class="ds_homework">
    <div class="ds_homework_inner">
      <div class="ds_homework_desc dsf_homework_shadow" :style="{ margin: $conmargin }">
        <div class="ds_homework_text" v-if="item">
          <h3 class="ds_homework_title">{{ item.title }}</h3>
          <h3 class="ds_homework_content" v-html="item.content"></h3>
        </div>
      </div>
      <div class="ds_homework_handle" :style="{ width: menuWidth, margin: $handlemargin }" v-if="!readOnly">
        <div class="ds_homework_handle_item">
          <div class="ds_homework_item_top dsf_homework_shadow" v-if="item && item.state_value != 2 && item.state_value != -1">
            <div class="top_inner" v-if="homeItem">
              <p class="name">批阅情况</p>
              <p class="state">
                <span>{{ homeItem.have_read_count || 0 }}</span>
                /{{ homeItem.all_count || 0 }}
              </p>
              <p class="progress">
                <el-progress :percentage="showPercent"></el-progress>
              </p>
            </div>
          </div>
          <div class="ds_homework_item_bottom dsf_homework_shadow">
            <div class="bottom_inner" v-if="item">
              <el-form
                :label-position="labelPosition"
                label-width="80px"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                :disabled="item.state_value == 2 || item.state_value == -1"
              >
                <el-form-item label="分数" size="small" prop="lv" v-if="item.score_type_value == 1">
                  <el-select v-model="ruleForm.lv" placeholder="请选择">
                    <el-option label="优秀" value="lv1"></el-option>
                    <el-option label="良好" value="lv2"></el-option>
                    <el-option label="一般" value="lv3"></el-option>
                    <el-option label="较差" value="lv4"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="分数" size="small" prop="rate" v-else>
                  <el-input v-model.number="ruleForm.rate"></el-input>
                </el-form-item>
                <el-form-item label="评语" prop="reviews" size="small">
                  <el-input type="textarea" v-model="ruleForm.reviews"></el-input>
                </el-form-item>
                <!-- <el-form-item label="推荐" prop="type">
                  <el-checkbox-group v-model="ruleForm.type">
                    <el-checkbox label="班级" name="class"></el-checkbox>
                    <el-checkbox label="门户" name="main"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item> -->
              </el-form>
              <!-- -->
              <div class="bottom_btn" v-if="item.state_value == 1">
                <!-- <DsfButton size="small" class="apply-btn" @click="handle(0)">取消</DsfButton> -->
                <DsfButton size="small" class="btn-reback" @click="handle(-1, 'ruleForm')">退回</DsfButton>
                <DsfButton size="small" class="btn-pass" @click="handle(2, 'ruleForm')">通过</DsfButton>
                <DsfButton size="small" @click="handle(3, 'ruleForm')">下一篇</DsfButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcHomework",
  ctrlCaption: "作业批阅",
  mixins: [$mixins.control],
  props: {
    menuWidth: {
      type: String,
      default: "400px",
    },
    contentMargin: {
      type: Array,
      default() {
        return ["10", "10", "10", "10"];
      },
    },
    handleMargin: {
      type: Array,
      default() {
        return ["10", "10", "10", "10"];
      },
    },
    id: {
      type: String,
      default: "",
    },
    readonly: {
      type: [String, Number],
      default: 0,
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "num", to: "num", type: "text", desc: "数量" },
        ];
      },
    },
  },
  computed: {
    $conmargin() {
      let margin = this.contentMargin;
      return [dsf.pxToRem(margin[0]), dsf.pxToRem(margin[1]), dsf.pxToRem(margin[2]), dsf.pxToRem(margin[3])].join(" ");
    },
    $handlemargin() {
      let margin = this.handleMargin;
      return [dsf.pxToRem(margin[0]), dsf.pxToRem(margin[1]), dsf.pxToRem(margin[2]), dsf.pxToRem(margin[3])].join(" ");
    },
    showPercent() {
      let { homeItem } = this;
      let percent = 0;
      if (homeItem) {
        let { have_read_count, all_count } = homeItem;
        percent = parseInt((have_read_count / all_count) * 100);
      }
      return percent;
    },
  },
  data() {
    let checkRate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("分数不能为空或者为0"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value > 100) {
            callback(new Error("不能大于100"));
          } else if (value < 0) {
            callback(new Error("不能小于0"));
          } else {
            callback();
          }
        }
      }, 500);
    };
    return {
      homeItem: null,
      item: null,
      labelPosition: "right",
      readOnly: this.queryString?.readonly || this.$route.query.readonly || this.readonly || null,
      ruleForm: {
        rate: "",
        reviews: "",
        type: [],
        lv: "",
      },
      rules: {
        rate: [{ validator: checkRate, trigger: "blur" }],
      },
      lvConfig: {
        lv1: "优秀",
        lv2: "良好",
        lv3: "一般",
        lv4: "较差",
      },
    };
  },
  mounted() {
    if (!this.isDesign) {
      if (this.readOnly) {
        this.readHomework();
      } else {
        this.initHomework();
      }
    } else {
      if (this.readOnly === "1" || this.readOnly) {
        this.readHomework();
      }
    }
  },
  methods: {
    handle(handleType, formName) {
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          if (handleType === 0) {
            window.close();
          } else if (handleType === 3) {
            this.findNextHomework();
          } else {
            const sourceUrl = `/nc/homework/reading/${this.item.nc_homework_student_id}`;
            const { rate, reviews, lv } = this.ruleForm;
            /**
             * score_type_value === 1 等级制
             */
            if (!rate && !lv) return this.error("请填写分数");
            if (!reviews) return this.error("请填写评语");
            const params = {
              score: this.item.score_type_value == 1 ? this.lvConfig[lv] : rate,
              reviews,
              state: {
                value: handleType,
                text: handleType === 2 ? "通过" : "退回",
              },
            };
            let loading = dsf.layer.pc.loading();
            params.state = JSON.stringify(params.state);
            const header = {
              "Content-Type": "application/json",
            };
            dsf.http
              .post(sourceUrl, params, { headers: header }, $$webRoot.nc)
              .done(res => {
                if (!res.success) {
                  this.error(res);
                } else {
                  const id = this.homeItem.waitReadingIds;
                  if (!id) {
                    // dsf.layer.pc.confirm("成功加入购物车,是否前往购物车查看？").then(() => {
                    //   dsf.layer.openWindow(this.shoppingCarUrl);
                    // });

                    dsf.layer.pc
                      .confirm("当前已经是最后一篇作业了，是否结束批阅？")
                      .then(() => {
                        window.close();
                      })
                      .catch(() => {
                        console.log("继续批阅");
                      });
                  }
                  id && this.findNextHomework();
                  this.$emit("hasHandledSuccess", res);
                }
              })
              .error(err => {
                this.error(err);
              })
              .always(res => {
                dsf.layer.pc.closeLoading(loading);
              });
          }
        } else {
          return false;
        }
      });
    },
    initHomework(id) {
      let itemId = this.$route.query.id;
      if (this.queryString.id) itemId = this.queryString.id;
      if (this.id) itemId = this.id;
      if (id) itemId = id;
      if (!itemId) return dsf.layer.pc.message("当前没有待批阅的作业");
      const sourceUrl = `/nc/homework/assessment/wait-reading/${itemId}`;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            const it = res.data.info;
            this.homeItem = res.data;
            this.item = it;
            //当前已批阅的作业 赋值显示
            if (it.state_value == 2 || it.state_value == -1) {
              let score = it.score;
              const config = { 优秀: "lv1", 良好: "lv2", 一般: "lv3", 较差: "lv4" };
              if (it.score_type_value == 1) {
                this.ruleForm.lv = config[score];
              } else {
                this.ruleForm.rate = score;
              }
              this.ruleForm.reviews = it.reviews;
            }
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    readHomework() {
      let itemId = this.$route.query.id;
      if (this.queryString.id) itemId = this.queryString.id;
      if (this.id) itemId = this.id;
      if (!itemId) return dsf.layer.pc.message("当前作业没有对应ID");
      const sourceUrl = `/nc/homework/assessment/wait-reading/${itemId}`;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            const it = res.data.info;
            this.homeItem = res.data;
            this.item = it;
            //当前已批阅的作业 赋值显示
            // if (it.state_value == 2 || it.state_value == -1) {
            //   let score = it.score;
            //   const config = { 优秀: "lv1", 良好: "lv2", 一般: "lv3", 较差: "lv4" };
            //   if (it.score_type_value == 1) {
            //     this.ruleForm.lv = config[score];
            //   } else {
            //     this.ruleForm.rate = score;
            //   }
            //   this.ruleForm.reviews = it.reviews;
            // }
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    findNextHomework() {
      const id = this.homeItem.waitReadingIds;
      if (id) {
        this.ruleForm = {
          rate: "",
          reviews: "",
          type: [],
          lv: "",
        };
        this.initHomework(id);
      } else {
        dsf.layer.pc.message("已经是最后一篇作业了");
      }
    },
    error(err) {
      let msg = typeof err === "string" ? err : err.message;
      dsf.layer.pc.message(msg || "获取作业异常", false);
    },
  },
});
</script>
