var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_saas saas_setting_url" }, [
    _c("div", { staticClass: "title t1 margint20 marginb20" }, [
      _vm._v("URL地址设置"),
    ]),
    _c("div", { staticClass: "subtitle margint20 marginb20" }, [
      _vm._v("简易设置"),
    ]),
    _c(
      "div",
      { staticClass: "line url_input" },
      [
        _vm.urlaction
          ? [
              _vm._v(" http:// "),
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.url,
                  callback: function ($$v) {
                    _vm.url = $$v
                  },
                  expression: "url",
                },
              }),
              _vm._v(" .xuexibang.com "),
            ]
          : [_vm._v(" " + _vm._s(_vm.realURL) + " ")],
        _vm.urlaction
          ? _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("dsf-button", { on: { click: _vm.saveURL } }, [
                  _vm._v("保存"),
                ]),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "text" }, on: { click: _vm.copyURL } },
                  [_vm._v("复制")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "text" }, on: { click: _vm.openURL } },
                  [_vm._v("打开")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "text" }, on: { click: _vm.changeURL } },
                  [_vm._v("修改")]
                ),
              ],
              1
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }