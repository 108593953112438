<template>
  <div class="dsf_course" :style="{ width: itemWidth }">
    <dsf-nc-title :moreUrl="moreUrl" :padding="itemPadding" :title="title" />
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCourse",
  ctrlCaption: "门户课程",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "精品课程",
    },
    moreUrl: {
      type: String,
      default: "/",
    },
    itemWidth: {
      type: String,
      default: "1200px",
    },
    itemPadding: {
      type: String,
      default: "50px 0 60px 0",
    },
  },
  components: {},
});
</script>
