<template>
  <div class="dsf_nc_saas_header_menu_select">
    <div class="top">
      <div class="select">
        <dsf-icon name="093info"></dsf-icon>
        已选：{{ this.selectMenuList.length }} 条
      </div>
      <div class="search">
        <el-input placeholder="请输入检索关键字" v-model="searchValue">
          <dsf-icon name="fangdajing" slot="append"></dsf-icon>
        </el-input>
      </div>
    </div>
    <el-table :data="menuList" ref="table" stripe style="width: 100%" border @selection-change="handleSelectionChange" @select-all="handleSelectAll" height="520">
      <el-table-column type="selection" width="50" align="center"></el-table-column>
      <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
      <el-table-column prop="menu_name" label="菜单名称" align="center"></el-table-column>
      <el-table-column prop="menu_id" label="编码" align="center"></el-table-column>
    </el-table>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="size"
      :page-sizes="[10, 15, 30, 50]"
      layout="total, prev, pager, next,jumper,sizes"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcSaasHeaderMenuSelect",
  ctrlCaption: "菜单选择",
  mixins: [$mixins.control],
  components: {},
  props: {},
  computed: {},
  data() {
    return {
      selectMenuList: [],
      selectCurrent: [],
      menuList: [
        {
          menu_name: "123",
          menu_id: "sdf",
        },
        {
          menu_name: "444",
          menu_id: "dfgv",
        },
        {
          menu_name: "fgh",
          menu_id: "xcv",
        },
      ],
      selectFlag: true,
      page: 1,
      size: 15,
      total: 0,
      searchValue: "",
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    searchBtn() {
      this.page = 1;
      this.loadList();
    },
    loadList() {
      this.selectFlag = false;
      let sourceUrl = "/nc/getSysMenuList";
      let params = {
        page: this.page,
        size: this.size,
        searchValue: this.searchValue,
        unitId: this.queryString.unitId,
      };
      const loading = dsf.layer.loading();
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          dsf.layer.pc.message("获取菜单:" + message, success);
          this.menuList = data.list;
          this.total = data.total;
          this.tickPageChoosed();
          this.selectFlag = true;
        })
        .error(err => {
          dsf.layer.pc.message("获取菜单出错:" + err, false);
          this.selectFlag = true;
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    handleSizeChange(val) {
      this.size = val;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.loadList();
    },
    include(select, item) {
      let flag = false;
      select.forEach((enty, i) => {
        if (enty.menu_id == item.menu_id) {
          flag = true;
          return;
        }
      });
      return flag;
    },
    tickPageChoosed() {
      this.menuList.forEach(row => {
        if (this.include(this.selectMenuList, row)) {
          this.$refs.table.toggleRowSelection(row);
          let selectEnty = {
            ...row,
          };
          this.selectCurrent.push(selectEnty);
        }
      });
    },
    handleSelectionChange(val, row) {
      if (this.selectFlag) {
        //如果当前的选择，不在新选择里，则移出选择
        this.selectCurrent.forEach(menu => {
          if (!this.include(val, menu) && this.include(this.selectMenuList, menu)) {
            this.selectMenuList.forEach((enty, i) => {
              if (enty.menu_id == menu.menu_id) {
                this.selectMenuList.splice(i, 1);
              }
            });
          }
        });
        //如果新的选择，不在选择里，则加入选择
        val.forEach(selectNew => {
          let selectEnty = {
            ...selectNew,
          };
          if (!this.include(this.selectMenuList, selectEnty)) {
            this.selectMenuList.push(selectEnty);
          }
        });
        //复制给当前选择
        this.selectCurrent = JSON.parse(JSON.stringify(val));
      }
    },
    handleSelectAll(menus) {
      if (menus.length) {
        this.selectMenuList.forEach(select => {
          var push = true;
          menus.forEach(menu => {
            if (menu.menu_id == this.selectMenuList.menu_id) {
              push = false;
              return;
            }
          });
          // if (push) {
          // }
        });
      }
    },
  },
});
</script>
