<template>
  <div class="dsf_collectionCourse ds-control" :style="{ margin: itemMargin, width: itemWidth }">
    <div class="dsf_collectionCourse_inner">
      <!-- <top-title title="收藏课程"></top-title> -->
      <div>
        <div class="item" v-for="item in lists" :key="item">
          <van-image class="img" width="120" height="70" fit="cover" :src="item.ur" />
          <p class="text">{{ item.des }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTitle from "../../base/onStudy/topTitle";
export default dsf.component({
  name: "DsfNcCollectionCourse",
  ctrlCaption: "收藏课程",
  mixins: [$mixins.control],
  props: {
    itemMargin: {
      type: String,
      default: "20px 0 0 0",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
  },
  components: {
    topTitle,
  },
  data() {
    return {
      lists: [
        {
          ur: "https://img01.yzcdn.cn/vant/cat.jpeg",
          des: "上海全力打造全国新一轮全面开 放新高地的新举措",
        },
        {
          ur: "https://img01.yzcdn.cn/vant/cat.jpeg",
          des: "上海全力打造全国新一轮全面开 放新高地的新举措",
        },
        {
          ur: "https://img01.yzcdn.cn/vant/cat.jpeg",
          des: "上海全力打造全国新一轮全面开 放新高地的新举措",
        },
      ],
    };
  },
});
</script>
