<template>
  <div class="dsf_nc_saas saas_setting_header">
    <div class="title t1 margint20 marginb20">
      <span @click="changeType = false">页头设置</span>
      {{ changeType ? "> 切换页头模版" : "" }}
    </div>
    <template v-if="changeType">
      <change-header-type :useType="useType" :menuList="menuList" :logoImg="imageUrl" @changeUseType="changeUseType"></change-header-type>
    </template>
    <template v-else>
      <div class="subtitle margint20 marginb20">
        预览效果
        <div class="button_box">
          <dsf-button type="small" @click="tochangepage">切换页头模版</dsf-button>
        </div>
      </div>
      <headerOne v-if="useType == '1'" :menuList="menuList" :logoImg="imageUrl"></headerOne>
      <headerTwo v-else-if="useType == '2'" :menuList="menuList" :logoImg="imageUrl"></headerTwo>
      <div class="subtitle margint20 marginb20">Logo图片</div>
      <div class="line uploadLogo">
        <el-upload accept="image/jpg,image/jpeg,image/png" class="avatar-uploader" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl[0] && imageUrl[0].relativePath" :src="imageUrl[0] && imageUrl[0].relativePath" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span>注：上传logo图片尺寸为 421 × 114px，png格式</span>
        <dsf-nc-crop-image
          :show-tips-text="true"
          :resize="false"
          :edit-img="editImg"
          :edit-file-img="editFileImg"
          :aspect-ratio="aspectRatio"
          :request-url="setRequestUrl"
          @saveFile="editFile"
          @handleClose="handleClose"
        ></dsf-nc-crop-image>
      </div>
      <div class="subtitle margint30 marginb20">
        导航栏
        <div class="button_box">
          <dsf-button type="small" @click="clearMenu">清空</dsf-button>
          <dsf-button type="small" @click="openSelectMenu">选择菜单</dsf-button>
          <dsf-button type="small" @click="addMenu">新增</dsf-button>
        </div>
      </div>
      <el-table :data="menuList" stripe style="width: 100%" :row-style="{ height: '60px' }" border>
        <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
        <el-table-column prop="title" label="显示名称" width="200" align="center">
          <template slot-scope="scope">
            <el-input type="text" v-if="scope.row.active" v-model="scope.row['title']"></el-input>
            <span v-else>{{ scope.row["title"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="menuUrl" label="菜单地址" align="center">
          <template slot-scope="scope">
            <el-input type="text" v-if="scope.row.active" v-model="scope.row['menuUrl']"></el-input>
            <span v-else>{{ scope.row["menuUrl"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" width="120" align="center" class-name="table_action">
          <template slot-scope="scope">
            <el-switch v-if="scope.row.active" active-value="1" inactive-value="0" @change="changeSwitch(scope.row)" v-model="scope.row['state'].value" active-text="是" inactive-text="否"></el-switch>
            <span v-else>{{ scope.row["state"].value == "1" ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <div class="tablebtns" v-if="!scope.row.active">
              <dsf-icon name="tianxie" v-if="!scope.row.dsfaMenuId" @click="switchActive(scope)" />
              <dsf-icon name="delete" @click="toDetle(scope)" />
              <dsf-icon name="shang" @click="toUp(scope)" />
              <dsf-icon name="xia" @click="toDown(scope)" />
            </div>
            <div class="tablebtns" v-else>
              <dsf-icon name="dui" @click="switchActive(scope)" />
              <dsf-icon name="cancel" @click="revertSwitch(scope)" />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="buttons">
        <dsf-button @click="saveMenu">保存</dsf-button>
      </div>
    </template>
  </div>
</template>
<script>
import changeheadertype from "./changeheadertype.vue";
import headerOne from "../../base/saas/headerOne.vue";
import headerTwo from "../../base/saas/headerTwo.vue";
export default dsf.component({
  name: "DsfNcSaasSettingHeader",
  ctrlCaption: "页头设置",
  mixins: [$mixins.control],
  components: {
    "change-header-type": changeheadertype,
    headerOne,
    headerTwo,
  },
  props: {},
  data() {
    return {
      menuList: [],
      reqMenuList: [],
      changeType: false,
      useType: "1",
      beforeData: {},
      imageUrl: [],
      saasGroupId: "",
      deleteMenu: [],
      editImg: false,
      editFileImg: "",
      aspectRatio: "421/114",
    };
  },
  computed: {
    setRequestUrl() {
      return dsf.url.getWebPath("file/upload/");
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/getSaasGropu";
      let params = { unitId: this.queryString.unitId };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          this.imageUrl = JSON.parse(data.logoImg || "[]");
          this.useType = data.headTemplate;
          this.saasGroupId = data.saasGroupId;
          this.getMenuList();
        })
        .error(() => {
          dsf.layer.pc.message("获取数据异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    changeSwitch(row) {
      row["state"].text = row["state"].value == "1" ? "是" : "否";
    },
    getMenuList() {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saasSetting/menu/getMenuList";
      let params = {
        saasGroupId: this.saasGroupId,
      };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.menuList = data;
            this.menuList.forEach((menu, index) => {
              var item = menu;
              item.active = false;
              this.$set(this.menuList, index, item);
            });
          } else {
            dsf.layer.pc.message("获取菜单出错：" + message, false);
          }
        })
        .error(() => {
          dsf.layer.pc.message("获取菜单出错:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
      } catch (err) {
        console.log(err);
      }
    },
    //logo图片上传
    handleAvatarSuccess(res) {
      this.editFileImg = res.data[0];
      this.editImg = true;
    },
    editFile(files) {
      this.editImg = false;
      this.imageUrl = [files];
      this.saveData({ logoImg: JSON.stringify(this.imageUrl) }, "logo图片上传");
    },
    handleClose() {
      this.editImg = false;
    },
    //切换页头模板
    tochangepage() {
      this.changeType = true;
    },
    //切换页头模板
    changeUseType(type) {
      this.useType = type;
      this.saveData({ headTemplate: type }, "页头模板应用");
    },
    //向上移动
    toUp(scope) {
      var idx = scope.$index;
      const { menuList } = this;
      if (idx == 0) return;
      const it = menuList[idx];
      const temp = menuList[idx - 1];
      this.$set(this.menuList, idx, temp);
      this.$set(this.menuList, idx - 1, it);
    },
    //向下移动
    toDown(scope) {
      var idx = scope.$index;
      const { menuList } = this;
      if (idx == menuList.length - 1) return;
      const it = menuList[idx];
      const temp = menuList[idx + 1];
      this.$set(this.menuList, idx, temp);
      this.$set(this.menuList, idx + 1, it);
    },
    //还原修改记录
    revertSwitch(scope) {
      this.beforeData.active = false;
      this.$set(this.menuList, scope.$index, this.beforeData);
    },
    //切换修改状态
    switchActive(scope) {
      var m = this.menuList.map((menu, index) => {
        if (index == scope.$index) {
          if (!menu.active) {
            this.beforeData = JSON.parse(JSON.stringify(menu));
          }
          menu.active = !menu.active;
        } else {
          menu.active = false;
        }
        return menu;
      });
      this.$set(this, "menuList", m);
    },
    //更新编辑状态
    toEdit(scope) {
      if (scope.row.active == true) {
        //saveMenu
      }
      var m = this.menuList.map((menu, index) => {
        if (index == scope.$index) {
          menu.active = !menu.active;
        } else {
          menu.active = false;
        }
        return menu;
      });
      this.$set(this, "menuList", m);
    },
    //删除单个菜单
    toDetle(scope) {
      dsf.layer
        .confirm({
          message: "确认是否要删除？",
        })
        .then(() => {
          scope.row["dsDeleted"] = "1";
          this.deleteMenu.push(scope.row);
          this.menuList.splice(scope.$index, 1);
        })
        .catch(err => {
          console.error(err);
        });
    },
    //清空菜单
    clearMenu() {
      dsf.layer
        .confirm({ message: "确认要清空所有菜单?", title: "提示", confirmButtonText: "是", cancelButtonText: "否" })
        .then(() => {
          this.menuList = [];
        })
        .catch(() => {
          dsf.layer.pc.message("已取消！", false);
        });
    },
    //保存菜单
    saveMenu() {
      const loading = dsf.layer.loading();
      const header = {
        "Content-Type": "application/json",
      };
      let menuList = this.menuList.map((item, index) => {
        item["dsOrder"] = index;
        return item;
      });
      menuList = menuList.concat(this.deleteMenu);
      this.dsf.http
        .post("/nc/saasSetting/menu/saveMenuList", { menuList: menuList, saasGroupId: this.saasGroupId }, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.pc.message("保存成功！", success);
          } else {
            dsf.layer.pc.message("保存失败：" + message, success);
          }
        })
        .error(err => {})
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    //选择菜单
    openSelectMenu() {
      this.selectMenuDialog = true;
      let url = "#/pc/nc/userinfo/selectmenu?saasGroupId=" + this.saasGroupId;
      this.$openDialog({
        title: "菜单选择",
        params: {
          path: url,
        },
        width: "100vh",
        height: "65vh",
        showClose: true,
        round: true,
        center: "center",
        btns: [
          {
            text: "确定",
            handler: async vm => {
              let selectMenu = await vm.yes();
              if (selectMenu.length == 0) {
                dsf.layer.message("请至少选中一条菜单进行操作！");
                return false;
              } else {
                selectMenu.forEach(sm => {
                  var push = true;
                  this.menuList.forEach(m => {
                    if (m["dsfaMenuId"] == sm["nc_userinfo_selectmenudata.dsfa_menu_id"]) {
                      push = false;
                    }
                  });
                  if (push) {
                    let menu = {
                      dsfaMenuId: sm["nc_userinfo_selectmenudata.dsfa_menu_id"],
                      menuUrl: sm["nc_userinfo_selectmenudata.menu_url"],
                      ncSaasSettingMenuId: "",
                      state: { text: "是", value: "1" },
                      title: sm["nc_userinfo_selectmenudata.name"],
                      active: false,
                    };
                    this.menuList.push(menu);
                  }
                });
              }
            },
          },
          {
            text: "取消",
            handler: function (index, layero, win) {},
          },
        ],
      });
    },
    //新增菜单
    addMenu() {
      let menu = {
        active: true,
        menuUrl: "",
        ncSaasSettingMenuId: "",
        state: { text: "是", value: "1" },
        title: "",
      };
      this.beforeData = JSON.parse(JSON.stringify(menu));
      this.menuList.push(menu);
    },
    //统一保存接口
    saveData(params, mes, callback) {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/saveSaasGropu";
      const header = {
        "Content-Type": "application/json",
      };
      dsf.http
        .post(sourceUrl, { ...params, saasGroupId: this.saasGroupId, unitId: this.queryString.unitId }, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.pc.message(mes + "成功！", success);
            callback && callback();
          } else {
            dsf.layer.pc.message(mes + "失败：" + message, success);
          }
        })
        .error(() => {
          dsf.layer.pc.message("保存失败:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
  },
});
</script>
