var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_saas saas_setting_header" },
    [
      _c("div", { staticClass: "title t1 margint20 marginb20" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                _vm.changeType = false
              },
            },
          },
          [_vm._v("页头设置")]
        ),
        _vm._v(" " + _vm._s(_vm.changeType ? "> 切换页头模版" : "") + " "),
      ]),
      _vm.changeType
        ? [
            _c("change-header-type", {
              attrs: {
                useType: _vm.useType,
                menuList: _vm.menuList,
                logoImg: _vm.imageUrl,
              },
              on: { changeUseType: _vm.changeUseType },
            }),
          ]
        : [
            _c("div", { staticClass: "subtitle margint20 marginb20" }, [
              _vm._v(" 预览效果 "),
              _c(
                "div",
                { staticClass: "button_box" },
                [
                  _c(
                    "dsf-button",
                    {
                      attrs: { type: "small" },
                      on: { click: _vm.tochangepage },
                    },
                    [_vm._v("切换页头模版")]
                  ),
                ],
                1
              ),
            ]),
            _vm.useType == "1"
              ? _c("headerOne", {
                  attrs: { menuList: _vm.menuList, logoImg: _vm.imageUrl },
                })
              : _vm.useType == "2"
              ? _c("headerTwo", {
                  attrs: { menuList: _vm.menuList, logoImg: _vm.imageUrl },
                })
              : _vm._e(),
            _c("div", { staticClass: "subtitle margint20 marginb20" }, [
              _vm._v("Logo图片"),
            ]),
            _c(
              "div",
              { staticClass: "line uploadLogo" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                      "before-upload": _vm.beforeAvatarUpload,
                    },
                  },
                  [
                    _vm.imageUrl[0] && _vm.imageUrl[0].relativePath
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src:
                              _vm.imageUrl[0] && _vm.imageUrl[0].relativePath,
                          },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _c("span", [
                  _vm._v("注：上传logo图片尺寸为 421 × 114px，png格式"),
                ]),
                _c("dsf-nc-crop-image", {
                  attrs: {
                    "show-tips-text": true,
                    resize: false,
                    "edit-img": _vm.editImg,
                    "edit-file-img": _vm.editFileImg,
                    "aspect-ratio": _vm.aspectRatio,
                    "request-url": _vm.setRequestUrl,
                  },
                  on: { saveFile: _vm.editFile, handleClose: _vm.handleClose },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "subtitle margint30 marginb20" }, [
              _vm._v(" 导航栏 "),
              _c(
                "div",
                { staticClass: "button_box" },
                [
                  _c(
                    "dsf-button",
                    { attrs: { type: "small" }, on: { click: _vm.clearMenu } },
                    [_vm._v("清空")]
                  ),
                  _c(
                    "dsf-button",
                    {
                      attrs: { type: "small" },
                      on: { click: _vm.openSelectMenu },
                    },
                    [_vm._v("选择菜单")]
                  ),
                  _c(
                    "dsf-button",
                    { attrs: { type: "small" }, on: { click: _vm.addMenu } },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.menuList,
                  stripe: "",
                  "row-style": { height: "60px" },
                  border: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    width: "60",
                    label: "序号",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "title",
                    label: "显示名称",
                    width: "200",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.active
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: scope.row["title"],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "title", $$v)
                                  },
                                  expression: "scope.row['title']",
                                },
                              })
                            : _c("span", [_vm._v(_vm._s(scope.row["title"]))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "menuUrl",
                    label: "菜单地址",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.active
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: scope.row["menuUrl"],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "menuUrl", $$v)
                                  },
                                  expression: "scope.row['menuUrl']",
                                },
                              })
                            : _c("span", [
                                _vm._v(_vm._s(scope.row["menuUrl"])),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "state",
                    label: "状态",
                    width: "120",
                    align: "center",
                    "class-name": "table_action",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.active
                            ? _c("el-switch", {
                                attrs: {
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-text": "是",
                                  "inactive-text": "否",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeSwitch(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row["state"].value,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row["state"], "value", $$v)
                                  },
                                  expression: "scope.row['state'].value",
                                },
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row["state"].value == "1"
                                      ? "是"
                                      : "否"
                                  )
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    label: "操作",
                    width: "120",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.active
                            ? _c(
                                "div",
                                { staticClass: "tablebtns" },
                                [
                                  !scope.row.dsfaMenuId
                                    ? _c("dsf-icon", {
                                        attrs: { name: "tianxie" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.switchActive(scope)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c("dsf-icon", {
                                    attrs: { name: "delete" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDetle(scope)
                                      },
                                    },
                                  }),
                                  _c("dsf-icon", {
                                    attrs: { name: "shang" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toUp(scope)
                                      },
                                    },
                                  }),
                                  _c("dsf-icon", {
                                    attrs: { name: "xia" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDown(scope)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "tablebtns" },
                                [
                                  _c("dsf-icon", {
                                    attrs: { name: "dui" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.switchActive(scope)
                                      },
                                    },
                                  }),
                                  _c("dsf-icon", {
                                    attrs: { name: "cancel" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.revertSwitch(scope)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("dsf-button", { on: { click: _vm.saveMenu } }, [
                  _vm._v("保存"),
                ]),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }