<template>
  <div class="DsfNcDefaultHeader">
    <div class="img-box" :style="style">
      <img :src="getUrl" alt="" @error="headLoadError" @load="headLoadSuccess" v-show="showIcon" />
    </div>
  </div>
</template>

<script>
// 默认头像
export default dsf.component({
  name: "DsfNcDefaultHeader",
  props: {
    src: {
      default: "",
      type: String,
    },
    defaultHeaderUrl: {
      default: "",
      type: String,
    },
    round: {
      default: true,
      type: Boolean,
    },
    width: {
      default: "",
      type: String,
    },
    height: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      showIcon: false,
      style: {},
    };
  },
  components: {},
  computed: {
    getUrl() {
      let src = this.src ? (!this.src.startsWith("[") ? this.src : JSON.parse(this.src || "[]")) : "";
      if (!src.length) {
        return this.defaultHeaderUrl || dsf.config.setting_public_user_default_card_header || dsf.config.setting_public_user_default_header;
      }
      if (!this.src.startsWith("[")) {
        return dsf.url.getWebPath(this.src);
      }
      return dsf.url.getWebPath(src[0].relativePath || src[0].url);
    },
  },
  created() {},
  mounted() {
    this.filstyle();
  },
  methods: {
    filstyle(val) {
      let style = {
        "background-image": val ? `url(${this.defaultHeaderUrl || dsf.config.setting_public_user_default_card_header || dsf.config.setting_public_user_default_header})` : "",
        backgroundSize: "100% 100%",
        width: this.width,
        height: this.height,
        "border-radius": this.round ? "50%" : "inherit",
      };
      this.style = style;
    },
    // 头像加载成功时
    headLoadSuccess() {
      this.showIcon = true;
    },
    // 头像加载失败时
    headLoadError() {
      this.showIcon = false;
      // headLoadSuccess
      this.filstyle(1);
    },
  },
});
</script>
