<template>
  <div class="ds_hotlist" :style="controlStyle">
    <div class="ds_nc_deftitle">
      <div class="def-title">
        <div class="leftcon">
          <i></i>
          <span>{{ title }}</span>
        </div>
        <template v-if="moreurl">
          <div class="rightbtn">
            <el-button @click="morebtn" type="primary" size="mini">
              更多
              <i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </div>
        </template>
      </div>
    </div>
    <div class="content" v-if="hotList.length">
      <!-- <div class="news_row" :style="rowStyle" v-for="(item, index) in news" :key="index" @click="goDetail(item)">
        <p class="news_title">{{ item[keyField.title] || "" }}</p>
      </div> -->
      <div class="hotlist-content course-left" :style="rowStyle" v-for="(v, index) in hotList" :key="index" @click="goDetail(item)">
        <span class="elps ell">
          <span :class="'num ' + 'num' + (index + 1)">{{ index + 1 }}</span>
          {{ v[keyField.title] || "" }}
        </span>
        <span>{{ v[keyField.num] || 0 }}</span>
        <p :class="'bg ' + 'bg' + (index + 1)"></p>
      </div>
    </div>
    <div v-else class="noDate">暂无数据</div>
  </div>
</template>

<script>
// isDesign
export default dsf.component({
  name: "DsfNcHotList",
  ctrlCaption: "榜单",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "课程排行",
    },
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "384px",
    },
    margin: {
      type: Array,
      default() {
        return [];
      },
    },
    padding: {
      type: Array,
      default() {
        return [20, 0, 20, 0];
      },
    },
    rowPadding: {
      type: Array,
      default() {
        return [];
      },
    },
    rowMargin: {
      type: Array,
      default() {
        return [0, 0, 20, 0];
      },
    },
    moreurl: {
      type: String,
      default: "",
    },
    detailUrl: {
      type: String,
      default: "",
    },
    reqUrl: {
      type: String,
      default: "nc/kck/mhkc/homePageFiveRanking",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "num", to: "num", type: "text", desc: "数量" },
        ];
      },
    },
  },
  computed: {
    controlStyle() {
      let { margin, width, height, padding, isDesign, visible } = this;
      let style = {
        margin: margin.join("px ") + "px",
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    rowStyle() {
      let { rowPadding, rowMargin } = this;
      let style = {
        margin: rowMargin.join("px ") + "px",
        padding: rowPadding.join("px ") + "px",
      };
      return style;
    },
  },
  data() {
    return {
      hotList: [],
      keyField: {},
    };
  },
  mounted() {
    this.keysMap.map(item => {
      this.keyField[item.to] = item.from;
    });
    if (!this.isDesign) {
      this.initData();
    } else {
      this.hotList = [
        {
          title: "阿里研究院副院长安筱鹏：一文讲透数字化的8个关键问题",
          num: 55,
        },
        {
          title: "《2021线上新品消费趋势报告》：新品年年翻倍品年年翻倍",
          num: 55,
        },
        {
          title: "《2021线上新品消费趋势报告》：新品年年翻倍品年年翻倍2",
          num: 55,
        },
        {
          title: "《2021线上新品消费趋势报告》：新品年年翻倍品年年翻倍3",
          num: 55,
        },
        {
          title: "《2021线上新品消费趋势报告》：新品年年翻倍品年年翻倍4",
          num: 55,
        },
      ];
    }
  },
  methods: {
    initData() {
      if (!this.reqUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.reqUrl, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.hotList = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    morebtn() {
      if (this.$listeners["moreurl"]) {
        this.$dispatch("moreurl");
      } else {
        if (this.moreurl) {
          dsf.layer.openWindow(this.moreurl);
        }
      }
    },
    goDetail(item) {
      if (this.$listeners["click"]) {
        this.$dispatch("click", item);
      } else {
        if (this.detailUrl) {
          let url = this.$replace(this.detailUrl, item);
          url && dsf.layer.openWindow(url);
        }
      }
    },
  },
});
</script>
