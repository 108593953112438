var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_learningPortfolio" }, [
        _c("div", { staticClass: "dsf_learningPortfolio_content" }, [
          _c("div", { staticClass: "dsf_learningPortfolio-head" }, [
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("学习档案，已陪伴你")]),
              _c("span", { staticClass: "date" }, [
                _c("span", [_vm._v(_vm._s(_vm.resData[_vm.keys1.day] || 0))]),
                _vm._v(" 天 "),
              ]),
            ]),
            _c("div", { staticClass: "head-content" }, [
              _c("div", { staticClass: "head-content-item" }, [
                _c("div", { staticClass: "num-box" }, [
                  _c("p", { staticClass: "num" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.resData[_vm.keys1.special] || 0)),
                    ]),
                    _vm._v(" 个 "),
                  ]),
                  _c("p", { staticClass: "init" }, [_vm._v("专题班总计")]),
                ]),
              ]),
              _c("div", { staticClass: "head-content-item" }, [
                _c("div", { staticClass: "num-box" }, [
                  _c("p", { staticClass: "num" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.resData[_vm.keys1.statistics] || 0)),
                    ]),
                    _vm._v(" 学时 "),
                  ]),
                  _c("p", { staticClass: "init" }, [_vm._v("学时总计")]),
                ]),
              ]),
              _c("div", { staticClass: "head-content-item" }, [
                _c("div", { staticClass: "num-box" }, [
                  _c("p", { staticClass: "num" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatterDate2(
                            _vm.resData[_vm.keys1.time] || "0:0:00",
                            "hh"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" 小时 "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatterDate2(
                            _vm.resData[_vm.keys1.time] || "0:0:00",
                            "mm"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" 分钟 "),
                  ]),
                  _c("p", { staticClass: "init" }, [_vm._v("学习时长总计")]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dsf_learningPortfolio-content",
              style: { width: _vm.width },
            },
            [
              _vm.dsf.isEmptyObject(_vm.resData) || !_vm.resData.items.length
                ? _c(
                    "div",
                    { staticClass: "dsf_learningPortfolio-content-nodata" },
                    [
                      _c("img", { attrs: { src: _vm.nodataImg, alt: "" } }),
                      _c("p", { staticClass: "p1" }, [_vm._v("暂无学习档案")]),
                      _c("p", { staticClass: "p2" }, [
                        _vm._v("学习档案在每年年度根据学习情况自动生成"),
                      ]),
                    ]
                  )
                : _vm._l(_vm.resData.items, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "dsf_learningPortfolio-content-item",
                      },
                      [
                        _c("div", { staticClass: "left" }, [
                          _c("p", { staticClass: "year" }, [
                            _vm._v(_vm._s(item[_vm.keys2.year] || 0)),
                          ]),
                          _vm._m(0, true),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "div",
                            { staticClass: "top-content margin-bottom-30" },
                            [
                              _c("div", { staticClass: "time-box" }, [
                                item[_vm.keys2.totalAll]
                                  ? _c("div", { staticClass: "time1" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item[_vm.keys2.totalAll] || "")
                                        ),
                                      ]),
                                      _c("span", [_vm._v("总学习时长")]),
                                    ])
                                  : _vm._e(),
                                item[_vm.keys2.pcTotal]
                                  ? _c("div", { staticClass: "time2" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item[_vm.keys2.pcTotal] || "")
                                        ),
                                      ]),
                                      _c("span", [_vm._v("PC学习时长")]),
                                    ])
                                  : _vm._e(),
                                item[_vm.keys2.appTotal]
                                  ? _c("div", { staticClass: "time3" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item[_vm.keys2.appTotal] || "")
                                        ),
                                      ]),
                                      _c("span", [_vm._v("APP学习时长")]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "right-item margin-bottom-30" },
                            [
                              _vm._m(1, true),
                              _c("div", { staticClass: "right-item-content" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "baseInfo-content-top margin-bottom-20",
                                  },
                                  [
                                    item[_vm.keys3.No]
                                      ? _c("div", { staticClass: "item" }, [
                                          _vm._v(
                                            "档案编号：" +
                                              _vm._s(item[_vm.keys3.No])
                                          ),
                                        ])
                                      : _vm._e(),
                                    item[_vm.keys3.dossierStartTime]
                                      ? _c("div", { staticClass: "item" }, [
                                          _vm._v(
                                            "建档时间：" +
                                              _vm._s(
                                                _vm.formatterDate3(
                                                  item[
                                                    _vm.keys3.dossierStartTime
                                                  ],
                                                  "yyyy-mm-dd"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    item[_vm.keys3.dossierEndTime]
                                      ? _c("div", { staticClass: "item" }, [
                                          _vm._v(
                                            "最后更新时间：" +
                                              _vm._s(
                                                _vm.formatterDate3(
                                                  item[
                                                    _vm.keys3.dossierEndTime
                                                  ],
                                                  "yyyy-mm-dd"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "baseInfo-user margin-bottom-20",
                                  },
                                  [
                                    item[_vm.keys3.name]
                                      ? _c("div", { staticClass: "item" }, [
                                          _vm._v(
                                            "姓名：" +
                                              _vm._s(item[_vm.keys3.name])
                                          ),
                                        ])
                                      : _vm._e(),
                                    item[_vm.keys3.userSex]
                                      ? _c("div", { staticClass: "item" }, [
                                          _vm._v(
                                            "性别：" +
                                              _vm._s(item[_vm.keys3.userSex])
                                          ),
                                        ])
                                      : _vm._e(),
                                    item[_vm.keys3.userBirthday]
                                      ? _c("div", { staticClass: "item" }, [
                                          _vm._v(
                                            "出生年月：" +
                                              _vm._s(
                                                item[_vm.keys3.userBirthday]
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c("div", { staticClass: "baseInfo-detail" }, [
                                  item[_vm.keys3.userUnit]
                                    ? _c("div", { staticClass: "item" }, [
                                        _vm._v(
                                          "所属单位：" +
                                            _vm._s(item[_vm.keys3.userUnit])
                                        ),
                                      ])
                                    : _vm._e(),
                                  item[_vm.keys3.userJobTitle]
                                    ? _c("div", { staticClass: "item" }, [
                                        _vm._v(
                                          "干部类别：" +
                                            _vm._s(item[_vm.keys3.userJobTitle])
                                        ),
                                      ])
                                    : _vm._e(),
                                  item[_vm.keys3.userJob]
                                    ? _c("div", { staticClass: "item" }, [
                                        _vm._v(
                                          "职务：" +
                                            _vm._s(item[_vm.keys3.userJob])
                                        ),
                                      ])
                                    : _vm._e(),
                                  item[_vm.keys3.userJobGrade]
                                    ? _c("div", { staticClass: "item" }, [
                                        _vm._v(
                                          "职级：" +
                                            _vm._s(item[_vm.keys3.userJobGrade])
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "right-item margin-bottom-30" },
                            [
                              _vm._m(2, true),
                              _c(
                                "div",
                                { staticClass: "right-item-content study-box" },
                                [
                                  _c("div", { staticClass: "row-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "learning-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(
                                              _vm.keysMap4[0].suffix,
                                              item,
                                              "专题班列表"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _c("p", { staticClass: "p1" }, [
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                _vm._s(
                                                  item[
                                                    _vm.keys4.classFinishedNum
                                                  ] || 0
                                                )
                                              ),
                                            ]),
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                "/" +
                                                  _vm._s(
                                                    item[
                                                      _vm.keys4
                                                        .classFinishedPeriod
                                                    ] || 0
                                                  )
                                              ),
                                            ]),
                                          ]),
                                          _c("p", { staticClass: "p2" }, [
                                            _vm._v("专题班 (个/学时)"),
                                          ]),
                                        ]),
                                        _c("div", {
                                          staticClass: "icon icon_ztb",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "learning-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(
                                              _vm.keysMap4[2].suffix,
                                              item,
                                              "课程列表"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _c("p", { staticClass: "p1" }, [
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                _vm._s(
                                                  item[
                                                    _vm.keys4.courseFinishedNum
                                                  ] || 0
                                                )
                                              ),
                                            ]),
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                "/" +
                                                  _vm._s(
                                                    item[
                                                      _vm.keys4
                                                        .courseFinishedPeriod
                                                    ] || 0
                                                  )
                                              ),
                                            ]),
                                          ]),
                                          _c("p", { staticClass: "p2" }, [
                                            _vm._v("课程 (个/学时)"),
                                          ]),
                                        ]),
                                        _c("div", {
                                          staticClass: "icon icon_kc",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "learning-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(
                                              _vm.keysMap4[4].suffix,
                                              item,
                                              "证书列表"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _c("p", { staticClass: "p1" }, [
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                _vm._s(
                                                  item[
                                                    _vm.keys4
                                                      .certificateFinishedNum
                                                  ] || 0
                                                )
                                              ),
                                            ]),
                                            item[_vm.keys4.certificateHours]
                                              ? _c(
                                                  "span",
                                                  { staticClass: "big" },
                                                  [
                                                    _vm._v(
                                                      "/" +
                                                        _vm._s(
                                                          item[
                                                            _vm.keys4
                                                              .certificateHours
                                                          ] || 0
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item[_vm.keys4.certificateHours]
                                              ? _c("span", [_vm._v("学时")])
                                              : _vm._e(),
                                          ]),
                                          _c("p", { staticClass: "p2" }, [
                                            _vm._v("证书 (个)"),
                                          ]),
                                        ]),
                                        _c("div", {
                                          staticClass: "icon icon_zhengshu",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "row-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "learning-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(
                                              _vm.keysMap4[7].suffix,
                                              item,
                                              "直播列表"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _c("p", { staticClass: "p1" }, [
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                _vm._s(
                                                  item[
                                                    _vm.keys4.liveFinishedNum
                                                  ] || 0
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("p", { staticClass: "p2" }, [
                                            _vm._v("直播 (个)"),
                                          ]),
                                        ]),
                                        _c("div", {
                                          staticClass: "icon icon_live",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "learning-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(
                                              _vm.keysMap4[9].suffix,
                                              item,
                                              "频道列表"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _c("p", { staticClass: "p1" }, [
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                _vm._s(
                                                  item[
                                                    _vm.keys4.channelFinishedNum
                                                  ] || 0
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("p", { staticClass: "p2" }, [
                                            _vm._v("频道 (个)"),
                                          ]),
                                        ]),
                                        _c("div", {
                                          staticClass: "icon icon_channel",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "learning-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(
                                              _vm.keysMap4[11].suffix,
                                              item,
                                              "考试列表"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _c("p", { staticClass: "p1" }, [
                                            _c("span", { staticClass: "big" }, [
                                              _vm._v(
                                                _vm._s(
                                                  item[
                                                    _vm.keys4.examFinishedNum
                                                  ] || 0
                                                )
                                              ),
                                            ]),
                                            item[_vm.keys4.examHours]
                                              ? _c(
                                                  "span",
                                                  { staticClass: "big" },
                                                  [
                                                    _vm._v(
                                                      "/" +
                                                        _vm._s(
                                                          item[
                                                            _vm.keys4.examHours
                                                          ] || 0
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item[_vm.keys4.examHours]
                                              ? _c("span", [_vm._v("学时")])
                                              : _vm._e(),
                                          ]),
                                          _c("p", { staticClass: "p2" }, [
                                            _vm._v("考试 (个)"),
                                          ]),
                                        ]),
                                        _c("div", {
                                          staticClass: "icon icon_exam",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "right-item result margin-bottom-20",
                            },
                            [
                              _vm._m(3, true),
                              _c("div", { staticClass: "right-item-content" }, [
                                _c(
                                  "div",
                                  { staticClass: "finish result-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item[_vm.keys5.requiredIsGoThrough]
                                          ? "完成"
                                          : "未完成"
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "center" }, [
                                  _c("div", { staticClass: "result-item" }, [
                                    _c("span", { staticClass: "span1" }, [
                                      _vm._v(" 必修专题班： "),
                                      _c(
                                        "span",
                                        { staticClass: "span2 time1" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item[
                                                _vm.keys5
                                                  .requiredClassFinishedNum
                                              ] || 0
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        " /" +
                                          _vm._s(
                                            item[
                                              _vm.keys5.requiredClassAllNum
                                            ] || 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "result-item" }, [
                                    _c("span", { staticClass: "span1" }, [
                                      _vm._v(" 必修课程： "),
                                      _c(
                                        "span",
                                        { staticClass: "span2 time2" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item[
                                                _vm.keys5
                                                  .requiredCourseFinishedNum
                                              ] || 0
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        " /" +
                                          _vm._s(
                                            item[
                                              _vm.keys5.requiredCourseAllNum
                                            ] || 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "result-item" }, [
                                    _c("span", { staticClass: "span1" }, [
                                      _vm._v(" 课程学时： "),
                                      _c(
                                        "span",
                                        { staticClass: "span2 time3" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item[
                                                _vm.keys5
                                                  .requiredPeriodFinishedNum
                                              ] || 0
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        " /" +
                                          _vm._s(
                                            item[
                                              _vm.keys5.requiredPeriodAllNum
                                            ] || 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "result-item isFinish" },
                                    [
                                      item[_vm.keys5.requiredIsGoThrough]
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "isFinish-icon icon_finish",
                                            },
                                            [
                                              _c("dsf-icon", {
                                                staticClass:
                                                  "dsf_learningPortfolio-icon",
                                                attrs: { name: "icon_ytg" },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", {
                                            staticClass:
                                              "isFinish-icon icon_fail",
                                          }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-box" }, [
      _c("div", { staticClass: "top" }, [_c("div", { staticClass: "top-1" })]),
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("基本信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("学习情况")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("学习结果")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }