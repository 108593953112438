var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showBar,
          expression: "showBar",
        },
      ],
      staticClass: "ds-control ds-button-bar",
      class: _vm.getCss,
    },
    [
      _c(
        "div",
        { staticClass: "bar-wrap" },
        [
          _vm._l(_vm.showButtonsReal, function (button, $index) {
            return [
              button.isUpload
                ? [
                    _c(
                      "el-upload",
                      {
                        key: $index,
                        staticClass: "ds-button-update",
                        attrs: {
                          headers: _vm.getHeaders(),
                          accept: _vm.getFileAccept(button),
                          data: _vm.getUploadData(button),
                          "show-file-list": false,
                          action: _vm.uploadUrl(button),
                          "on-success": _vm.handleAvatarSuccess(button),
                          "before-upload": _vm.beforeAvatarUpload(button),
                        },
                      },
                      [
                        _c(
                          "DsfButton",
                          {
                            attrs: {
                              icon: _vm.getIcon(button),
                              title: button.title,
                              disabled: button.disabled,
                              size: _vm.size,
                              type: button.type,
                              "btn-style": button.btnStyle,
                            },
                          },
                          [_vm._v(" " + _vm._s(button.text) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : button.isLinkSend &&
                  button.isLinkSendFold &&
                  (button.actionName === "system_flow_send" ||
                    button.actionName === "system_batch_flow_send")
                ? [
                    _c(
                      "el-popover",
                      {
                        key: $index,
                        class: ["ds-button__flow"],
                        attrs: {
                          "popper-class": "ds-morebutton-popover",
                          trigger: "manual",
                          placement: "top",
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.flowButtons.length > 0
                              ? {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-row",
                                        {
                                          style: {
                                            width:
                                              _vm.flowButtons.length > 5
                                                ? "240px"
                                                : "80px",
                                          },
                                        },
                                        _vm._l(
                                          _vm.flowButtons,
                                          function (flow, index) {
                                            return _c(
                                              "el-col",
                                              {
                                                key: index,
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                attrs: {
                                                  span:
                                                    _vm.flowButtons.length > 5
                                                      ? 8
                                                      : 24,
                                                },
                                              },
                                              [
                                                [
                                                  _c(
                                                    "DsfButton",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        width: "80px",
                                                        "margin-bottom": "5px",
                                                        "margin-top": "5px",
                                                      },
                                                      attrs: {
                                                        icon: _vm.getIcon(flow),
                                                        disabled: flow.disabled,
                                                        size: _vm.size,
                                                        type: flow.type,
                                                        title: flow.title,
                                                        "btn-style":
                                                          "icon-text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickHandler(
                                                            flow,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(flow.text) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: button.popVisible,
                          callback: function ($$v) {
                            _vm.$set(button, "popVisible", $$v)
                          },
                          expression: "button.popVisible",
                        },
                      },
                      [
                        _c(
                          "DsfButton",
                          {
                            attrs: {
                              slot: "reference",
                              icon: _vm.getIcon(button),
                              title: button.title,
                              disabled: button.disabled,
                              size: _vm.size,
                              type: button.type,
                              "btn-style": button.btnStyle,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addFlowBtns(button)
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v(" " + _vm._s(button.text) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : button.actionName === "show-course-type"
                ? [
                    _c(
                      "DsfButton",
                      {
                        key: $index,
                        style: button.style,
                        attrs: {
                          icon: _vm.getIcon(button),
                          title: button.title,
                          disabled: button.disabled,
                          size: _vm.size,
                          type: button.type,
                          "btn-style": button.btnStyle,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showCorseType(button, $event)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(button.text) + " ")]
                    ),
                  ]
                : [
                    _c(
                      "DsfButton",
                      {
                        key: $index,
                        style: button.style,
                        attrs: {
                          icon: _vm.getIcon(button),
                          title: button.title,
                          disabled: button.disabled,
                          size: _vm.size,
                          type: button.type,
                          "btn-style": button.btnStyle,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickHandler(button, $event)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(button.text) + " ")]
                    ),
                  ],
            ]
          }),
          _vm.moreButtonsReal.length
            ? _c(
                "el-popover",
                {
                  class: ["ds-button__more", _vm.size],
                  attrs: {
                    "popper-class": "ds-morebutton-popover",
                    width: "auto",
                    trigger: "click",
                  },
                  on: { show: _vm.popoverShow, hide: _vm.popoverClose },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c(
                              "el-row",
                              {
                                style: {
                                  width:
                                    _vm.moreButtonsReal.length > 5
                                      ? "240px"
                                      : "80px",
                                },
                              },
                              _vm._l(
                                _vm.moreButtonsReal,
                                function (button, $index) {
                                  return _c(
                                    "el-col",
                                    {
                                      key: $index,
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        span:
                                          _vm.moreButtonsReal.length > 5
                                            ? 8
                                            : 24,
                                      },
                                    },
                                    [
                                      button.isUpload
                                        ? [
                                            _c(
                                              "el-upload",
                                              {
                                                key: $index,
                                                staticClass: "ds-button-update",
                                                attrs: {
                                                  headers: _vm.getHeaders(),
                                                  accept: _vm.getFileAccept(
                                                    button
                                                  ),
                                                  data: _vm.getUploadData(
                                                    button
                                                  ),
                                                  "show-file-list": false,
                                                  action: _vm.uploadUrl(button),
                                                  "on-success": _vm.handleAvatarSuccess(
                                                    button
                                                  ),
                                                  "before-upload": _vm.beforeAvatarUpload(
                                                    button
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "DsfButton",
                                                  {
                                                    staticStyle: {
                                                      width: "80px",
                                                      "margin-bottom": "5px",
                                                      "margin-top": "5px",
                                                    },
                                                    attrs: {
                                                      icon: _vm.getIcon(button),
                                                      title: button.title,
                                                      disabled: button.disabled,
                                                      size: _vm.size,
                                                      type: button.type,
                                                      "btn-style": "icon-text",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(button.text) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : [
                                            _c(
                                              "DsfButton",
                                              {
                                                key: $index,
                                                staticStyle: {
                                                  width: "80px",
                                                  "margin-bottom": "5px",
                                                  "margin-top": "5px",
                                                },
                                                attrs: {
                                                  icon: _vm.getIcon(button),
                                                  disabled: button.disabled,
                                                  size: _vm.size,
                                                  type: button.type,
                                                  title: button.title,
                                                  "btn-style": "icon-text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickHandler(
                                                      button,
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(button.text) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2122975982
                  ),
                },
                [
                  _vm.moreButtonsReal.length &&
                  _vm.moreButtonStyle != "icon-text"
                    ? _c(
                        "DsfButton",
                        {
                          staticClass: "plain",
                          class: [_vm.moreButtonStyle],
                          attrs: {
                            slot: "reference",
                            title: _vm.moreButtonText,
                            "btn-style": _vm.moreButtonStyle,
                            icon: _vm.moreFold
                              ? "shuangjiantouxia"
                              : "xiangshangzhanhang",
                            size: _vm.size,
                          },
                          slot: "reference",
                        },
                        [_vm._v(" " + _vm._s(_vm.moreButtonText) + " ")]
                      )
                    : _vm.moreButtonsReal.length &&
                      _vm.moreButtonStyle == "icon-text"
                    ? _c(
                        "DsfButton",
                        {
                          staticClass: "plain",
                          class: [_vm.moreButtonStyle],
                          attrs: {
                            slot: "reference",
                            title: _vm.moreButtonText,
                            "btn-style": _vm.moreButtonStyle,
                            icon: "gengduo1",
                            size: _vm.size,
                          },
                          slot: "reference",
                        },
                        [_vm._v(" " + _vm._s(_vm.moreButtonText) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  staticClass: "ds_buttons_dialog",
                  attrs: {
                    visible: _vm.showCourseType,
                    "show-close": false,
                    "append-to-body": true,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showCourseType = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "choose-dialog" }, [
                    _c(
                      "div",
                      { staticClass: "choose-inner" },
                      _vm._l(_vm.showCards, function (item) {
                        return _c(
                          "div",
                          { key: item.name, staticClass: "item" },
                          [
                            item.isUpload
                              ? [
                                  _c(
                                    "el-upload",
                                    {
                                      key: "courseUpload",
                                      staticClass: "ds-button-update",
                                      attrs: {
                                        headers: _vm.getHeaders(),
                                        accept: item.fileAccept,
                                        "show-file-list": false,
                                        action: item.uploadUrl,
                                        "on-success": _vm.itemUploadSuccess,
                                        "on-error": _vm.itemUploadError,
                                        "before-upload": _vm.itemBeforeUpload,
                                        "auto-upload": true,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ds-icon-name-box" },
                                        [
                                          _c("i", {
                                            class: "ds_icon " + item.icon,
                                          }),
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(_vm._s(item.name)),
                                          ]),
                                        ]
                                      ),
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v(_vm._s(item.remark)),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ds-button-dialog",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDialog(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ds-icon-name-box" },
                                        [
                                          _c("i", {
                                            class: "ds_icon " + item.icon,
                                          }),
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(_vm._s(item.name)),
                                          ]),
                                        ]
                                      ),
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v(_vm._s(item.remark)),
                                      ]),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }