<template>
  <div class="dsf_ncpc_navmenus" v-if="visible" :style="{ width: itemWidth, margin: controlStyle }">
    <ul class="common_wrap" v-if="dataSource == 'json' || isDesign">
      <li class="common_items" v-for="(item, index) in dataList" :key="index" @click="clickItem(item)" :style="itemStyle">
        <i :class="`icon iconfont ${item.icon}`"></i>
        <div class="font">{{ item.name }}</div>
      </li>
    </ul>
    <ul class="common_wrap" v-if="dataSource == 'http'">
      <li class="common_items" v-for="(item, index) in dataList" :key="index" @click="clickItem(item)" :style="itemStyle">
        <i :class="`icon iconfont ${item.icon}`"></i>
        <div class="font">{{ item.name }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcNavMenus",
  ctrlCaption: "网络学院快捷菜单",
  mixins: [$mixins.cardList],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    // 一行几列
    colNum: {
      type: Number,
      default: 3,
    },
    margin: {
      default: () => [10, 10, 10, 10],
    },
    // 数据来源
    dataSource: {
      type: String,
      default: "json",
    },
    dataSourceUrl: {
      type: String,
      default: "",
    },
    dataSourceList: {
      type: Array,
      default() {
        return [];
      },
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "menu_icon", to: "icon", type: "text", desc: "图标" },
          { from: "menu_name", to: "name", type: "text", desc: "名字" },
          { from: "menu_url", to: "url", type: "text", desc: "地址" },
          { from: "menu_opentype_value", to: "opentype", type: "text", desc: "打开方式" },
        ];
      },
    },
    dataFormat: {
      type: Function,
      default: null,
    },
  },
  computed: {
    controlStyle() {
      let { margin, isDesign } = this;
      let style = margin.join("px ") + "px";
      if (isDesign) {
        style = "10px 10px 10px 10px";
      }
      return style;
    },
    dataList() {
      if (this.isDesign) {
        if (!this.dataSourceList.length) {
          return this.defaultData;
        }
      }
      if (this.lists && this.dataSource == "http") {
        _.forEach(this.lists, item => {
          let res = {};
          _.forEach(this.keysMap, ({ to, from, type }) => {
            let d = item[from];
            if (type === "Boolean") {
              res[to] = !!d;
            } else {
              res[to] = d;
            }
            return res;
          });
          Object.assign(item, res);
        });
        return this.lists;
      }
      return this.dataSourceList || [];
    },
    itemStyle() {
      let w = (100 / this.colNum).toFixed(2) + "%";
      return {
        width: w,
        flex: "0 0 " + w,
      };
    },
  },
  data() {
    return {
      defaultData: [
        {
          name: "班级公告",
          icon: "icon-pingtaigonggao",
          url: "",
        },
        {
          name: "问卷",
          icon: "icon-ziyuan2",
          url: "",
        },
        {
          name: "通讯录",
          icon: "icon-xinwen",
          url: "",
        },
      ],
      lists: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // if (this.isDesign || this.dataSource != "http") {
      //   return;
      // }
      if (this.dataSourceUrl == "") {
        this.lists = [];
        return;
      }
      this.loading = true;
      this.$http
        .get(this.dataSourceUrl, {}, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.loading = false;
            this.lists = data;
          } else {
            dsf.layer.message(message, false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {
          this.isLoading = false;
        });
    },
    clickItem(item) {
      console.log("点击了某一项", item);
      let url = item.url;
      if (!url) {
        return;
      }
      this.$open({
        url,
        state: item.opentype,
      });
    },
  },
});
</script>
