var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf-nc-spacil-shop-card" }, [
        _c(
          "div",
          { staticClass: "cover dsf-nc-image" },
          [
            _c("dsf-nc-image", { attrs: { "error-src": _vm.defaultSrc } }),
            _vm._m(0),
          ],
          1
        ),
        _vm._m(1),
        _vm._m(2),
        _c("div", { staticClass: "shop-buy-items no-padding" }, [
          _c("div", { staticClass: "ms" }, [
            _c("span", { staticClass: "prefix" }, [_vm._v("秒杀")]),
            _c(
              "span",
              { staticClass: "c-sale" },
              [_c("dsf-icon", { attrs: { name: "jinbi" } }), _vm._v(" 8500 ")],
              1
            ),
            _c("span", { staticClass: "o-sale" }, [_vm._v("1200")]),
          ]),
          _c("div", { staticClass: "ms" }, [
            _c("span", { staticClass: "prefix present" }, [_vm._v("赠")]),
            _c(
              "span",
              { staticClass: "jf" },
              [_c("dsf-icon", { attrs: { name: "jinbi" } }), _vm._v(" 10 ")],
              1
            ),
            _c(
              "span",
              { staticClass: "dyq" },
              [_c("dsf-icon", { attrs: { name: "quan" } }), _vm._v(" 20 ")],
              1
            ),
          ]),
        ]),
      ])
    : _c(
        "div",
        {
          staticClass: "dsf-nc-spacil-shop-card",
          attrs: { title: _vm.data["name"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "cover dsf-nc-image" },
            [
              _c("dsf-nc-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  {
                    staticClass: "card-state",
                    style:
                      "background-color: " +
                      _vm.showBgColor(_vm.data["stateText"]),
                  },
                  [_vm._v(_vm._s(_vm.data["stateText"]))]
                ),
                _vm.data["timeDuring"]
                  ? _c("div", { staticClass: "card-info" }, [
                      _c("span", [
                        _vm._v(
                          "报名截止还剩 " + _vm._s(_vm.data["timeDuring"])
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title ellipsis-2" }, [
              _vm._v(_vm._s(_vm.data["name"])),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("span", [
              _c("i", { staticClass: "iconfont icon-icon-bi" }),
              _c("b", [_vm._v(_vm._s(_vm.data["needPeriod"] || 0) + "学时")]),
            ]),
            _c("span", [
              _c("i", { staticClass: "iconfont icon-icon-xuan" }),
              _c("b", [_vm._v(_vm._s(_vm.data["noNeedPeriod"] || 0) + "学时")]),
            ]),
            _c("span", [
              _c("i", { staticClass: "iconfont icon-xiaoliang" }),
              _c("b", [_vm._v(_vm._s(_vm.data["indentNum"] || 0))]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "shop-buy-items no-padding special-padding" },
            [
              _c("div", { staticClass: "ms" }, [
                _vm.activitySuffix && _vm.getPrefix("activitySuffix")
                  ? _c("span", { staticClass: "prefix" }, [
                      _vm._v(
                        _vm._s(
                          _vm.prefixText ? _vm.getPrefix("activitySuffix") : ""
                        )
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "span",
                  { staticClass: "c-sale" },
                  [
                    _vm.data["price"]
                      ? _c("dsf-icon", { attrs: { name: "jinbi" } })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.data["price"] || "免费") + " "),
                  ],
                  1
                ),
                _vm.data["old_price"]
                  ? _c("span", { staticClass: "o-sale" }, [
                      _vm._v(_vm._s(_vm.data["old_price"])),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "ms" }, [
                _vm.data["giftPayment"]
                  ? _c("span", { staticClass: "prefix present" }, [
                      _vm._v("赠"),
                    ])
                  : _vm._e(),
                _vm.data["giftPayment"]
                  ? _c(
                      "span",
                      { staticClass: "jf" },
                      [
                        _c("dsf-icon", { attrs: { name: "jinbi" } }),
                        _vm._v(" " + _vm._s(_vm.data["giftPayment"]) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.data["giftCoupon"]
                  ? _c(
                      "span",
                      { staticClass: "dyq" },
                      [
                        _c("dsf-icon", { attrs: { name: "quan" } }),
                        _vm._v(" " + _vm._s(_vm.data["giftCoupon"]) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c(
        "div",
        {
          staticClass: "card-state",
          staticStyle: { "background-color": "#0080ff" },
        },
        [_vm._v("报名")]
      ),
      _c("div", { staticClass: "card-info" }, [
        _c("span", [_vm._v("报名截止还剩 4天08:09:21")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("网络学院卡片组件")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("span", [
        _c("i", { staticClass: "iconfont icon-icon-bi" }),
        _c("b", [_vm._v("10")]),
        _vm._v(" 学时 "),
      ]),
      _c("span", [
        _c("i", { staticClass: "iconfont icon-icon-xuan" }),
        _c("b", [_vm._v("5")]),
        _vm._v(" 学时 "),
      ]),
      _c("span", [
        _c("i", { staticClass: "iconfont icon-xiaoliang" }),
        _c("b", [_vm._v("25")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }