<template>
  <div class="dsf_channel_column" v-if="isDesign">
    <div class="item_inner">
      <div class="item-left-img">
        <dsf-image class="img" :src="'' | imgFormat" />
        <div class="item-right-desc">
          <div class="title ell2">我是一个标题1111</div>
          <div class="resource_period">
            <span>资源数: 8</span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span>学时: 3</span>
          </div>
          <div class="subscribed">订阅人数: 1382</div>
        </div>
      </div>
      <div class="circle-wrap">
        <el-progress width="60" stroke-width="5" :color="curColor" type="circle" :percentage="33" class="progress-circle"></el-progress>
        <p>当前进度</p>
      </div>
    </div>
  </div>
  <div class="dsf_channel_column" v-else>
    <div class="item_inner">
      <div class="item-left-img">
        <dsf-image class="img" :src="data.cover | imgFormat" />
        <div class="item-right-desc">
          <div class="title ell2">{{ data.title || "" }}</div>
          <div class="resource_period">
            <span>资源数: {{ data.resource || 0 }}</span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span>学时: {{ data.period || 0 }}</span>
          </div>
          <div class="subscribed">订阅人数: {{ data.subscribed || 0 }}</div>
        </div>
      </div>
      <div class="circle-wrap">
        <el-progress width="60" stroke-width="5" :color="curColor" type="circle" :percentage="data.finishedRateOrScore" class="progress-circle"></el-progress>
        <p>当前进度</p>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcCardChannelColumn",
  ctrlCaption: "学习任务-频道卡片",
  mixins: [$mixins.card],
  props: {
    title: {
      type: String,
      default: "标题",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "resource", to: "resource", type: "text|number", desc: "资源数" },
          { from: "period", to: "period", type: "text|number", desc: "学时" },
          { from: "subscribed", to: "subscribed", type: "text|number", desc: "订阅人数" },
          { from: "finishedRateOrScore", to: "finishedRateOrScore", type: "text|number", desc: "完成度" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
    },
    toPage(item) {
      this.$emit("toDetail", item._id);
    },
    formatPercent(it) {
      return it || "0";
    },
  },
});
</script>
