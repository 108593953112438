var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf-nc-spacil-detail-header",
      style: { width: _vm.cssWidth, padding: _vm.padding },
    },
    [
      _c("div", { staticClass: "crumbs" }, [
        _c("div", { staticClass: "wrap" }, [
          _c("span", { staticClass: "fister-text", on: { click: _vm.back } }, [
            _vm._v(_vm._s(_vm.fisterText) + " > "),
          ]),
          _c("span", { staticClass: "second-text" }, [
            _vm._v(_vm._s(_vm.secondText)),
          ]),
        ]),
        _c("div", [
          _vm.isback
            ? _c("img", {
                attrs: {
                  src: require("../../../assets/imgs/fanhui.png"),
                  alt: "返回",
                },
                on: { click: _vm.back },
              })
            : _vm._e(),
        ]),
      ]),
      !_vm.isCrumbs
        ? _c("div", { staticClass: "spacil-info" }, [
            _c("div", { staticClass: "nameandtags" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.detail["name"])),
              ]),
              _c(
                "div",
                { staticClass: "tabs-wrap" },
                _vm._l(_vm.label, function (item, index) {
                  return _c("div", { key: index, staticClass: "tags-item" }, [
                    _vm._v(_vm._s(item)),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "desc" }, [
              _c("div", { staticClass: "desc-item" }, [
                _vm._v(
                  "报名日期：" +
                    _vm._s(
                      _vm.dsf.date.format(_vm.detail["bmsjSdate"], "yyyy-mm-dd")
                    ) +
                    " 至 " +
                    _vm._s(
                      _vm.dsf.date.format(_vm.detail["bmsjEdate"], "yyyy-mm-dd")
                    )
                ),
              ]),
              _c("div", { staticClass: "desc-item" }, [
                _vm._v(
                  "培训日期：" +
                    _vm._s(
                      _vm.dsf.date.format(_vm.detail["pxsjSdate"], "yyyy-mm-dd")
                    ) +
                    " 至 " +
                    _vm._s(
                      _vm.dsf.date.format(_vm.detail["pxsjEdate"], "yyyy-mm-dd")
                    )
                ),
              ]),
              _c("div", { staticClass: "desc-item" }, [
                _vm._v(
                  "结业条件：必修 " +
                    _vm._s(_vm.detail["compulsory"]) +
                    " 选修 " +
                    _vm._s(_vm.detail["electives"]) +
                    " 学时 " +
                    _vm._s(_vm.detail["period"])
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }