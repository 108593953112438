var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-navtab ds-side-navtab ds_nc_ali_sidebar",
      class: { "ds-no-padding": !_vm.showPadding },
      style: { height: _vm.isDesign ? "100%" : _vm.defaultHeight },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: {
            "tab-position": "left",
            type: _vm.tabStyle == "customer-tab-pad" ? "" : _vm.tabStyle,
            closable: _vm.enableDelete,
          },
          on: {
            "tab-click": function ($event) {
              return _vm._clickBefore(_vm.tabClick)
            },
            "tab-remove": _vm.tabRemove,
          },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm._t("default"),
          _vm._l(
            _vm.slots.filter(function (tab) {
              return _vm.isHide(tab) !== true
            }),
            function (d) {
              return _c(
                "el-tab-pane",
                {
                  key: d.name,
                  style: { padding: _vm.$padding },
                  attrs: {
                    disabled: _vm.isdisable(d),
                    label: d.title,
                    name: d.name,
                    "slot-name": d.name,
                    lazy: _vm.isLazy(d),
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label_font",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", {
                        class:
                          "ali_sidebar_icon" +
                          (_vm.activeName == d.name ? " active" : ""),
                      }),
                      _vm._v(" " + _vm._s(d.title) + " "),
                      d.badge !== null
                        ? _c("span", {
                            staticClass: "el-badge",
                            domProps: { innerHTML: _vm._s(d.badge) },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm.isDesign ? _vm._t(d.name) : _vm._e(),
                  _vm.activeName == d.name && !_vm.isDesign
                    ? _c(
                        "div",
                        { class: ["insert", { "insert-wrap": !_vm.isDesign }] },
                        [_vm._t(d.name)],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }