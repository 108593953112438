<template>
  <div v-if="loadComplete" class="ds-tab-panel-setting">
    <div class="ds-tab-panel-setting-title">
      <div class="ds-tab-panel-setting-title-text">已选标签</div>
      <div class="ds-tab-panel-setting-title-sbu">（长按标签可拖拽排序）</div>
    </div>
    <div class="ds-tab-panel-setting-warp" type="select">
      <draggable :value="selectTab" v-if="selectTab" v-bind="options" @update="update">
        <template v-for="(item, i) in selectTab">
          <el-tag :key="i" closable @close="removeTag(item, i)">
            {{ item ? item.title : "" }}
          </el-tag>
        </template>
      </draggable>
    </div>
    <div class="ds-tab-panel-setting-title">
      <div class="ds-tab-panel-setting-title-text">可选标签</div>
      <div class="ds-tab-panel-setting-title-sbu">（都不选时，显示所有）</div>
    </div>
    <div class="ds-tab-panel-setting-warp">
      <el-checkbox-group v-model="selectTabValues">
        <template v-for="tab in tabs">
          <el-checkbox :key="tab.name" :label="tab.name" size="medium" border>
            {{ tab.title }}
          </el-checkbox>
        </template>
      </el-checkbox-group>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "DsfNcTabPanelSetting",
  mixins: [$mixins.tabPanelSetting],
  created() {
    if (this.tabs) {
      this.tabs.forEach((item, index) => {
        if (item.name == "read") {
          console.log(index);
          this.tabs.splice(index, 1);
        }
      });
    }
  },
  computed: {
    selectTab() {
      let arr = [];
      if (this.selectTabs.length > 0) {
        this.selectTabs.forEach((item, index) => {
          if (item && item.name != "read") {
            // this.selectTabs.splice(index, 1);
            arr.push(item);
          }
        });
      }
      console.log(arr);
      return arr;
    },
  },
};
</script>
