var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_ncpc_manage_myinfo",
      style: { width: _vm.itemWidth, display: _vm.visible ? "block" : "none" },
    },
    [
      _c("div", { staticClass: "my_company" }, [
        _vm._v(_vm._s(_vm.info.unitName || "")),
      ]),
      _c("div", { staticClass: "manager_name" }, [
        _vm._v("管理员：" + _vm._s(_vm.info.name || "")),
      ]),
      _c("div", { staticClass: "organiz_count" }, [
        _vm._v("组织人数：" + _vm._s(_vm.info.unitUserCount || 0)),
      ]),
      _c(
        "div",
        { staticClass: "manage_bottom" },
        [
          _c("p", { staticClass: "font" }, [_vm._v("年度学时完成情况")]),
          _c("div", { staticClass: "counts_wrap" }, [
            _c("div", { staticClass: "count_left" }, [
              _c("i", { staticClass: "icon" }),
              _c("div", { staticClass: "progress_count" }, [
                _vm._v(_vm._s(_vm.info.periodRatio || 0) + "%"),
              ]),
            ]),
            _c("div", { staticClass: "hours" }, [
              _vm._v(
                _vm._s(_vm.info.currentPeriod || 0) +
                  "/" +
                  _vm._s(_vm.info.totalPeriod || 0)
              ),
            ]),
          ]),
          _c("el-progress", {
            attrs: {
              percentage: _vm.info.currentPeriod || 0,
              "stroke-width": "11",
              color: "#0A55D4",
              "show-text": false,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }