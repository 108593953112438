<template>
  <div class="dsf_nc_course_chapter">
    <div class="cousrse-chapter-inner">
      <el-collapse v-if="!dsf.isEmptyObject(chapters)" v-model="activeNames">
        <el-collapse-item :name="index" v-for="(item, index) in resetChapters" :key="item.name + index" :class="{ 'no-active': !item.children }">
          <template slot="title">
            <div class="cousrse-item-head">
              <div class="icon-wrap">
                <dsf-icon :name="getTitleIcon(item.rTypeValue)" />
              </div>
              <div class="title-wrap">
                <div class="title-inner">
                  <h3 class="title ellipsis" :title="item.title || ''">
                    {{ item.title }}
                    {{ item.ext }}
                  </h3>
                  <span class="item-state" :style="`width:40px;color:#FFF;background-color:${item.isRequired == 1 ? '#C90C0A' : '#69C33B'} `" v-if="item.isRequired != null">
                    {{ isRequiredItemText(item) }}
                  </span>
                  <span class="item-state" v-if="!showState(item)" :class="{ 'item-finish': item.isFinished == 1 }">
                    {{ showText(item) }}
                  </span>
                </div>
                <p class="study-hour" v-if="item.period">{{ item.period }}学时</p>
              </div>
              <span class="to-handle" v-if="showHandle(item) && !isView" @click="handle(item)">{{ item.isFinished ? "查看" : "提交" }}</span>
            </div>
          </template>
          <div class="chapter-content-wrap">
            <div ref="chapterList" class="chapter-content">
              <div
                v-for="(it, index) in item.children"
                :key="it.id"
                @click="item.rTypeValue === 'course' && itemFn(it, index)"
                :class="['tab-content-desc', { 'desc-item-sel': chapters.curPlayId == it.id || idx == index }]"
              >
                <div class="wrap">
                  <el-progress
                    width="40"
                    stroke-width="3"
                    :color="curColor"
                    type="circle"
                    :percentage="it.finishedRate"
                    class="progress-circle"
                    :format="formatPercent"
                    v-if="item.rTypeValue === 'course'"
                  ></el-progress>
                  <span class="item-icon" :class="{ 'item-means': item.rTypeValue === 'file', 'nc-icon': item.rTypeValue === 'course' }">
                    <dsf-icon :name="item.rTypeValue === 'course' ? getCourseIcon(it) : getIcon(it, item.rTypeValue)" />
                  </span>
                  <span class="con-title ellipsis" :class="{ 'no-pointer': item.rTypeValue === 'file' }" :title="it.title">{{ it.title }}</span>

                  <span class="item-handle" v-if="item.rTypeValue === 'file'">
                    <i class="preview" @click="fileHandle(it, 0)">预览</i>
                    <i class="down" @click="fileHandle(it, 1)" v-show="it.extValue == 1">下载</i>
                  </span>
                </div>
                <dsf-icon name="bofang1" v-if="chapters.curPlayId == it.id || idx == index" />
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-if="homeworkParams.show">
      <DsfNcCheckBriefSummary
        :show.sync="homeworkParams.show"
        :homeworkId="homeworkParams.homeworkId"
        :homeworkState="homeworkParams.homeworkState"
        :titleName="homeworkParams.title"
        :show-title="homeworkParams.showTitle"
        :readyonly="homeworkParams.readyonly"
        :required="homeworkParams.required"
        :dialog-width="'520px'"
        @breisfsSummarySubmit="homeworkSuccess"
      />
    </div>
    <div v-if="showCertificate">
      <DsfNcCertificate :show.sync="showCertificate" :imgUrl="certificateUrl" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "dsfNcCourseChapter",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    moreUrl: {
      type: String,
      default: "",
    },
    titlePadding: {
      type: String,
      default: "50px 0 58px 0",
    },
    chapters: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      activeNames: [0],
      winH: window.innerHeight,
      idx: -1,
      briefsummaryUrl: "/nc/homework/assessment/writing",
      showCertificate: false,
      certificateUrl: null,
      homeworkParams: {
        homeworkId: "",
        homeworkState: 0,
        title: "课后作业",
        showTitle: true,
        show: false,
        readyonly: false,
        required: true,
      },
      preUrl: dsf.config.setting_public_file_host,
      isView: this.$route.query.isView || 0,
    };
  },
  computed: {
    videoSrc() {
      let src = "";
      const data = this.getCurPlayItem;
      if (data && data.fileUrl && data.fileUrl.length > 0) {
        src = dsf.url.getWebPath(JSON.parse(data.fileUrl)[0].relativePath);
      }
      return src;
    },
    curColor() {
      return this.$root.currentTheme?.normal || "#fc7001";
    },
    resetChapters() {
      const { chapters } = this;
      let list = [];
      if (!dsf.isEmptyObject(chapters)) {
        list = [
          {
            title: chapters.title,
            children: chapters.children,
            rTypeValue: "course",
            isFinished: chapters.isFinished,
          },
        ];
        let nArr_1 = chapters.addChildren;
        let nArr_2 = chapters.useFile;
        if (nArr_2) {
          list.push({
            title: "资料",
            ...chapters.useFile,
          });
        }
        if (nArr_1) list = [...list, ...nArr_1];
      }
      return list;
    },
    ...mapGetters(["getCurPlayItem", "getCurPlayProgress"]),
  },
  created() {},
  mounted() {
    this.$nextTick(function () {
      if (this.$refs.chapterList) {
        let timer = setTimeout(() => {
          let itOfsetH = this.$refs.chapterList.offsetTop;
          let listH = this.winH - itOfsetH;
          let itH = this.$refs.chapterList.offsetHeight;
          if (itH > this.winH - itOfsetH) {
            this.$refs.chapterList.style.height = listH + "px";
            this.$refs.chapterList.style.overflowY = "scroll";
          }
          clearTimeout(timer);
        }, 5500);
      }
    });
  },
  methods: {
    homeworkSuccess() {
      this.homeworkParams.show = false;
      this.homeworkParams.readyonly = true;
      this.homeworkParams.homeworkState = 1;
      _.each(this.resetChapters, item => {
        if (item.id === this.homeworkParams.homeworkId) {
          item.isFinished = 1;
        }
      });
    },
    isRequiredItemText(it) {
      let state = "";
      if (it) state = it.rTypeValue;
      const config = {
        course: it.isRequired === 1 ? "必修" : "选修",
        file: it.isRequired === 1 ? "必修" : "选修",
        exam: it.isRequired === 1 ? "必考" : "选考",
        homework: it.isRequired === 1 ? "必填" : "选填",
        certificate: null,
      };
      return config[state];
    },
    handle(it) {
      this.$checkLogin().then(res => {
        if (res) {
          if (it.rTypeValue === "exam") {
            const params = {
              examId: it["id"],
              businessId: this.$route.query.id,
              businessType: it["extValue"],
              deviceType: "pc",
            };
            this.$toExamPage(params);
          } else if (it.rTypeValue === "homework") {
            let dialogTitle = "课后作业";
            let state = "";
            let readyonly = false;
            if (it.extStr) {
              state = 1;
              readyonly = true;
            } else {
              state = 0;
              readyonly = false;
            }

            this.$openDialog({
              width: "620px",
              height: "auto",
              title: dialogTitle,
              content: "DsfNcHomeworkSummary",
              params: {
                state: state,
                homeworkId: it.id,
                studentId: it.extStr,
                readyonly: readyonly,
                cb: data => {
                  if (data) {
                    this.$parent.loadCourses();
                  }
                },
              },
              dialogArgs: {},
            });
          } else if (it.rTypeValue === "certificate") {
            if (it.extStr && it.isFinished) {
              this.showCertificate = true;
              this.certificateUrl = it.extStr;
            }
          } else {
            console.log(it);
          }
        }
      });
    },
    fileHandle(it, state) {
      this.$checkLogin().then(res => {
        if (res) {
          if (!state) {
            if (it.extStr) {
              let url = dsf.url.getWebPath(`/preview/file?url=${this.preUrl}${it.extStr}`);
              dsf.layer.openWindow(":" + url);
            } else {
              dsf.layer.message("当前预览地址为空，请上传对应文件", false);
            }
          } else {
            this.beforDownload("nc/class/teaching/file/download", { fileId: it.id, classId: this.$route.query.id }, res => {
              const url = dsf.url.getWebPath("file/download");
              if (!it["extStr"]) return dsf.layer.message("当前下载地址为空，请上传对应文件", false);
              $(
                `<form action="${url}" method="get">
                 <input type="text" name="files" value="${it.ext}"/>
                 <input type="text" name="zipName" value="${it.title || ""}"/>
               </form>`
              )
                .appendTo("body")
                .submit()
                .remove();
            });
          }
        }
      });
    },
    beforDownload(sourceUrl, params, succFn, failFun) {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          res.success && succFn && succFn(res);
          if (!res.success) error(res);
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        failFun && failFun(err);
        dsf.layer.pc.message(err.message, false);
      }
    },
    showHandle(it) {
      let state = true;
      if (it.rTypeValue === "certificate" && (!it.extStr || it.isFinished == 0)) state = false;
      if (it.rTypeValue === "course") state = false;
      if (it.courseExam === "other") state = false;
      if (it.rTypeValue === "file") state = false;
      return state;
    },
    itemFn(item, idx) {
      this.chapters.curPlayId = item.id;
      this.$emit("changeCurPlayCourse", item);
    },
    getCourseIcon(it) {
      let type = it.rTypeValue;
      let icon = "";
      switch (type) {
        case "video":
          icon = "kejianziliao-shipin";
          break;
        case "document":
          icon = "kejianziliao-wenbenwenjian";
          break;
        case "audio":
          icon = "kejianziliao-yinpinwenjian";
          break;
        case "other":
          icon = "kejianziliao-lianjie";
          break;
        case "link":
          icon = "kejianziliao-lianjie";
          break;
        case "medium":
          icon = "fumeitixiaoxi";
          break;
        default:
          icon = "";
          break;
      }
      return icon;
    },
    getIcon(it, itemType) {
      let type = it.rTypeValue;
      if (itemType === type) type = "c-video";
      let icon = "default-icon-biaoge";
      switch (type) {
        case "video":
          icon = "default-video-icon";
          break;
        case "document":
          icon = "default-icon-document";
          break;
        case "audio":
          icon = "default-icon-music";
          break;
        case "other":
          icon = "charulianjie";
          break;
        case "ppt":
          icon = "default-icon-ppt";
          break;
        case "pdf":
          icon = "default-icon-pdf";
          break;
        case "courseware":
          icon = "default-icon-music";
          break;
        case "xlsx":
          icon = "default-icon-biaoge";
          break;
        case "xls":
          icon = "default-icon-biaoge";
          break;
        case "c-video":
          icon = "default-icon-m-video";
          break;
        case "doc":
          icon = "default-icon-document";
          break;
        case "docx":
          icon = "default-icon-document";
          break;
        case "txt":
          icon = "default-icon-txt";
          break;
        case "medium":
          icon = "default-icon-medium";
          break;
        default:
          icon = "default-icon-biaoge";
          break;
      }
      return icon;
    },
    getTitleIcon(it) {
      if (!it) it = "xx";
      let config = {
        exam: "icon-wenjuantiaocha",
        homework: "icon-kehouzuoye",
        xx: "icon-kehoulianxi",
        certificate: "icon-kechengzhengshu",
        course: "icon-kejian",
        file: "wenjianjia",
      };
      return config[it];
    },
    formatPercent(it) {
      return it || "0";
    },
    itemTileChange() {
      console.log("change");
    },
    showState(it) {
      let isHide = true;
      if (it) {
        if (it.rTypeValue === "certificate") isHide = false;
        if (it.rTypeValue === "courseware" || it.rTypeValue === "course") isHide = false;
      }
      return isHide;
    },
    showText(item) {
      let config = {
        homework: item?.isRequired == 0 || item?.isRequired == null ? "选填" : "必填",
        exam: item?.isRequired == 0 || item?.isRequired == null ? "选答" : "必答",
        courseware: item?.isFinished == 0 || item?.isFinished == null ? "未完成" : "已完成",
        course: item?.isFinished == 0 || item?.isFinished == null ? "未完成" : "已完成",
        certificate: item.isFinished ? "已获得" : "未获得",
      };
      let text = config[item?.rTypeValue];
      return text;
    },
  },
  watch: {
    getCurPlayProgress(n, o) {
      let finshRate = this.getCurPlayProgress;
      let id = this.getCurPlayItem.id;
      this.$nextTick(() => {
        if (n && this.getCurPlayItem) {
          const data = this.chapters.children;
          _.each(data, (item, idx) => {
            if (id == item.id) {
              this.$set(this.chapters.children[idx], "finishedRate", finshRate);
            }
          });
        }
      });
    },
  },
  beforeDestroy() {},
};
</script>
