var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_news ds-control", style: { width: _vm.itemWidth } },
    [
      _c("top-title", { attrs: { title: "消息动态", showBtn: false } }),
      _c(
        "div",
        { staticClass: "nitembox" },
        _vm._l(_vm.list, function (sd, index) {
          return _c("div", { key: index + 1, staticClass: "nitem" }, [
            sd.a == 1 ? _c("span", { staticClass: "look_sel" }) : _c("span"),
            _c("p", { staticClass: "padmin" }, [_vm._v("[系统消息]")]),
            _c("p", { staticClass: "ptitle ell" }, [
              _vm._v(
                "关于青年干部培训班的通知关于青年干部培训班的通知通知通知通知"
              ),
            ]),
            _c("p", { staticClass: "pname" }, [_vm._v(_vm._s(sd.name))]),
            _c("p", { staticClass: "ptime" }, [_vm._v("2019-10-28")]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }