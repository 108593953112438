<template>
  <div class="dsf_nc_live_teacher_card_main">
    <ul class="live_card_wrap" v-if="isDesign">
      <li class="live_card_item">
        <div class="img_box">
          <div :class="['state', { wks: data.current_state_text == '未开始' }, { yjs: data.current_state_text == '已结束' }, { zbz: data.current_state_text == '直播中' }]">
            {{ data.current_state_text || "" }}
          </div>
          <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
          <div class="date">开播时间：2021-07-24 14:30</div>
        </div>
        <div class="item_content">
          <div class="title">一带一路与中华文化海外传播</div>
          <div class="foot">
            <div class="foot_left">
              <dsf-icon name="icon_person1"></dsf-icon>
              <span>北京行政学院主任 · 邓邓</span>
            </div>
            <div class="views_count">
              <dsf-icon name="yanjing1"></dsf-icon>
              <span>4527</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="live_card_wrap" v-else>
      <li class="live_card_item" @click="handlerClick(data)">
        <div class="img_box">
          <div :class="['state', { wks: data.current_state_text == '未开始' }, { yjs: data.current_state_text == '已结束' }, { zbz: data.current_state_text == '直播中' }]">
            {{ data.current_state_text || "" }}
          </div>
          <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
          <div class="date">开播时间：{{ data.time || "" }}</div>
        </div>
        <div class="item_content">
          <div class="title ell">{{ data.title || "" }}</div>
          <div class="foot">
            <div class="foot_left">
              <dsf-icon name="icon_person1"></dsf-icon>
              <span>{{ data.job || "" }} · {{ data.teacher_text || "" }}</span>
            </div>
            <div class="views_count">
              <dsf-icon name="yanjing1"></dsf-icon>
              <span>{{ data.views || 0 }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcLiveTeacherCardMain",
  ctrlCaption: "名师首页直播卡片",
  mixins: [$mixins.card],
  props: {
    clickConfig: {
      type: Object,
      default: null,
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "nc_live_page_teacherlives.time_sdate", to: "time", type: "text", desc: "直播时间" },
          { from: "nc_live_page_teacherlives.title", to: "title", type: "text", desc: "直播标题" },
          { from: "nc_live_page_teacherlives.current_state_text", to: "current_state_text", type: "text", desc: "标签" },
          { from: "nc_live_page_teacherlives.views", to: "views", type: "text", desc: "直播观看量" },
          { from: "nc_live_page_teacherlives.job", to: "job", type: "text", desc: "教师职务" },
          { from: "nc_live_page_teacherlives.teacher_text", to: "teacher_text", type: "text", desc: "教师名称" },
        ];
      },
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  components: {},
  computed: {},
  methods: {
    handlerClick() {
      if (!this.clickConfig) return;

      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);

      this.$open({
        url,
        state,
      });
      this.clickAfter();
    },
  },
});
</script>
