var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc-questions-pc" }, [
    _vm.isDesign
      ? _c("div", { staticClass: "dsf_nc-question-info-wrap" }, [
          _c("div", { staticClass: "dsf_nc-before" }, [_vm._v("匿名")]),
          _vm._m(0),
        ])
      : _c("div", { staticClass: "dsf_nc-question-info-wrap" }, [
          _vm.data.is_anonymity_value == "1"
            ? _c("div", { staticClass: "dsf_nc-before" }, [_vm._v("匿名")])
            : _vm._e(),
          _c("div", { staticClass: "dsf_nc-right-info" }, [
            _c("div", { staticClass: "questions-name-wrap" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.data.name)),
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("span", [
                  _vm._v("填报方式：" + _vm._s(_vm.data.repeat_show_text)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "right-wrap" }, [
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.data.time_dsplay_text)),
              ]),
              _c("div", { staticClass: "state_btn" }, [
                _vm._v(_vm._s(_vm.data.scope_text)),
              ]),
              _c(
                "div",
                {
                  staticClass: "state_btn_second second_item1",
                  class: {
                    second_item2:
                      _vm.data.record_status_value != "1" &&
                      _vm.data.asq_status_value != "1",
                  },
                  on: { click: _vm.toPage },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.data.record_status_value == "1"
                        ? "查看问卷"
                        : "进入填报"
                    )
                  ),
                ]
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dsf_nc-right-info" }, [
      _c("div", { staticClass: "questions-name-wrap" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("问卷名称问卷名称问卷名称问卷名称问卷名称问卷名称问卷名称"),
        ]),
        _c("div", { staticClass: "desc" }, [
          _c("span", [_vm._v("填报方式：xxxxx")]),
        ]),
      ]),
      _c("div", { staticClass: "right-wrap" }, [
        _c("div", { staticClass: "time" }, [_vm._v("27天12小时25分钟后结束")]),
        _c("div", { staticClass: "state_btn" }, [_vm._v("公开")]),
        _c("div", { staticClass: "state_btn_second second_item1" }, [
          _vm._v("进入填报"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }