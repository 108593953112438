<template>
  <div class="dsf_login">
    <div class="close-btn" v-if="isview">
      <dsf-icon name="guanbi2" @click="$root.close()"></dsf-icon>
    </div>
    <div class="dsf_login_inner" :style="{ width: boxWidth }">
      <el-tabs :tab-position="tabPosition" v-model="tabIndex">
        <el-tab-pane label="账号登录" name="textLogin">
          <textLogin
            ref="textLogin"
            :sourceUrl="sourceUrl"
            :nameHolder="nameHolder"
            :pwdHolder="pwdHolder"
            :codeHolder="codeHolder"
            :toUrl="toUrl"
            :login-success="_loginSuccess"
            :login-fail="_loginFail"
            @changePassword="changePassword"
            @toRegister="toRegister"
            :isreloadCurrentPage="isreloadCurrentPage"
          />
        </el-tab-pane>
        <el-tab-pane label="验证码登录" name="codeLogin">
          <codeLogin ref="codeLogin" :login-success="_loginSuccess" :login-fail="_loginFail" @changePassword="changePassword" @toRegister="toRegister" :isreloadCurrentPage="isreloadCurrentPage" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { textLogin, codeLogin } from "../../base/login";
export default dsf.component({
  name: "DsfNcDialogLogin",
  ctrlCaption: "登录",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "登录",
    },
    moreUrl: {
      type: String,
      default: "/",
    },
    boxWidth: {
      type: String,
      default: "300px",
    },
    sourceUrl: {
      type: String,
      default: "/nc/login/login",
    },
    nameHolder: {
      type: String,
      default: "请输入账号",
    },
    pwdHolder: {
      type: String,
      default: "请输入密码",
    },
    codeHolder: {
      type: String,
      default: "动态验证码",
    },
    toUrl: {
      type: String,
      default: "",
    },
    loginSuccess: {
      type: Function,
      required: false,
    },
    loginFail: {
      type: Function,
      required: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isreloadCurrentPage: false,
      isview: true,
      tabIndex: "textLogin",
    };
  },
  created() {
    if (!this.$dialog.$attrs["show-close"]) {
      this.isview = this.$dialog.$attrs["show-close"];
    }
  },
  mounted() {
    this.isreloadCurrentPage = this.$dialog.dialogArgs?.isreloadCurrentPage || false;
  },
  components: {
    textLogin,
    codeLogin,
  },
  watch: {
    tabIndex: {
      handler(v) {
        let that = this;
        document.onkeydown = function (e) {
          let key = e.keyCode;
          if (key == 13) that.$refs[v].loginHandle();
        };
      },
      immediate: true,
    },
  },
  methods: {
    yes() {
      return new Promise(resolve => {
        this.resolve = resolve;
      });
    },
    _loginSuccess() {
      this.resolve?.(true);
      this.loginSuccess?.();
    },
    _loginFail() {
      this.resolve?.(false);
      this.loginFail?.();
    },
    changePassword() {
      const path = dsf.config.setting_public_login_forget_password;
      if (this.$listeners["changePwd"]) {
        this.$dispatch("changePwd", path);
      } else {
        path && dsf.layer.openWindow(path);
      }
    },
    toRegister() {
      const path = dsf.config.setting_public_login_register;
      if (this.$listeners["toRegister"]) {
        this.$dispatch("toRegister", path);
      } else {
        path && this.$open({ url: path, noTitle: !this.noTitle, state: 3, width: "430px", minHeight: "500px", class: "register-dialog" });
        // path && this.$open({ url: path, state: 3, width: "400px", height: "auto", minHeight: "500px", class: "register-dialog" });
      }
    },
  },
  computed: {
    // isview() {
    //   return this.$route.query.isview == 1 ? false : true;
    // },
  },
});
</script>
