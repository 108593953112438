<template>
  <div class="dsf_nc_saas_footer saas_setting_footer_title">
    <div class="title_text_radio">
      <el-radio-group v-model="radio" @change="change">
        <el-radio :label="index">{{ title }}</el-radio>
      </el-radio-group>
    </div>
    <div class="title_button" @click="$emit('setBtn', index)">{{ btnText }}</div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcSaasSettingFooterTitle",
  ctrlCaption: "页尾单选按钮设置",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "模板标题",
    },
    index: {
      type: Number,
      required: true,
    },
    radioValue: {
      type: Number,
      required: true,
    },
    btnText: {
      type: String,
      default: "设置信息",
    },
  },
  data() {
    return {
      radio: null,
    };
  },
  methods: {
    change(e) {
      this.$emit("change", e);
    },
  },
  watch: {
    radioValue: {
      handler(n, o) {
        if (n == this.index) {
          this.radio = this.index;
        } else {
          this.radio = null;
        }
      },
      immediate: true,
    },
  },
});
</script>
