<template>
  <el-dialog
    :visible.sync="show"
    :width="'100%'"
    :close-on-press-escape="false"
    :show-close="false"
    :append-to-body="true"
    @close="close"
    :custom-class="'nc_pc_manage_dialog'"
  >
    <div class="dsf_ncpc_manage_addsource" v-show="isDesign || visible">
      <ul class="addsource_wrap">
        <li class="addsource_items" v-for="(item, index) in lists" :key="item.code + index" @click="handlerClick(item, index)">
          <i :class="`icon icon-${item.code}`"></i>
          <span class="name">{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
import newEditResource from "./newEditResource";
export default dsf.component({
  name: "DsfNcPcAddSource",
  ctrlCaption: "新增资源",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "/nc/menu/count/role/code?code=011",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lists: [
        {
          name: "专题班",
          code: "class",
        },
        {
          name: "课程",
          code: "courses",
        },
        {
          name: "考试",
          code: "exam",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, {}, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.lists = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    handlerClick(item) {
      let { code } = item;
      let url = newEditResource(code);
      if (code != "exam") {
        this.$open({ url, state: 2 });
      }
    },
    close() {
      this.$emit("update:show", false);
    },
  },
});
</script>
