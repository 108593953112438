var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-nc-dataGrid-child-list" }, [
    _c("div", { staticClass: "dsf-nc-dataGrid-child-list-left" }, [
      _c("div", { staticClass: "dsf-nc-dataGrid-child-list-left-date" }, [
        _c("div", [
          _vm._v(_vm._s(_vm.date.year) + "-" + _vm._s(_vm.date.month)),
        ]),
        _c("div", [_vm._v(_vm._s(_vm.date.day))]),
        _c("div", [
          _vm._v(
            _vm._s(_vm.date.year) +
              "-" +
              _vm._s(_vm.date.month) +
              "-" +
              _vm._s(_vm.date.day)
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "dsf-nc-dataGrid-child-list-right",
        class: { checkbox: _vm.$list.checkboxColumn.show },
      },
      [
        _vm.$list.checkboxColumn.show
          ? _c(
              "div",
              { staticClass: "dsf-nc-dataGrid-child-list-right-checkbox" },
              [
                _c("el-checkbox", {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.isSelected,
                    callback: function ($$v) {
                      _vm.isSelected = $$v
                    },
                    expression: "isSelected",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "dsf-nc-dataGrid-child-list-header" },
          [
            _c(
              "div",
              { staticClass: "dsf-nc-dataGrid-child-list-header-title" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.isDesign ? "梦创双杨" : _vm.getVal("name")) +
                    " "
                ),
              ]
            ),
            _c("DsfButtonBar", {
              staticClass: "dsf-nc-dataGrid-child-list-header-btns",
              attrs: {
                "max-number": _vm.$list.rowButtonsMaxNumber,
                "more-button-text": _vm.$list.rowButtonsMoreText,
                "more-button-style": _vm.$list.rowButtonsMoreStyle,
                buttons: _vm.getRowButtons(),
                local: _vm.local,
                "emit-prefix": "row-button",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dsf-nc-dataGrid-child-list-body" }, [
      _c("div", { staticClass: "dsf-nc-dataGrid-child-list-body-tips" }, [
        _vm._v("温馨提示"),
      ]),
      _c("div", { staticClass: "dsf-nc-dataGrid-child-list-body-data" }, [
        _c(
          "div",
          { staticClass: "dsf-nc-dataGrid-child-list-body-data-item" },
          [
            _c("span", { staticClass: "label" }, [_vm._v("报名人数")]),
            _c("span", { staticClass: "value" }, [_vm._v("49")]),
          ]
        ),
        _c(
          "div",
          { staticClass: "dsf-nc-dataGrid-child-list-body-data-item" },
          [
            _c("span", { staticClass: "label" }, [_vm._v("实际报道人数")]),
            _c("span", { staticClass: "value" }, [_vm._v("48")]),
          ]
        ),
        _c(
          "div",
          { staticClass: "dsf-nc-dataGrid-child-list-body-data-item" },
          [
            _c("span", { staticClass: "label" }, [_vm._v("评价情况")]),
            _c("span", { staticClass: "value" }, [_vm._v("!")]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }