var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_ncpc_manage_platform_dynamic",
      style: { width: _vm.itemWidth, display: _vm.visible ? "block" : "none" },
    },
    [
      _c("div", { staticClass: "dynamic_message" }, [
        _vm.isDesign
          ? _c("ul", { staticClass: "message_wrap" }, [
              _vm._m(0),
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
            ])
          : _vm._e(),
        !_vm.isDesign
          ? _c(
              "ul",
              { staticClass: "message_wrap" },
              _vm._l(_vm.loginLists, function (item) {
                return _c(
                  "li",
                  {
                    key: item.nc_log_login_id,
                    staticClass: "message_items ell",
                  },
                  [
                    _c("span", { staticClass: "yellow_font" }, [
                      _vm._v(_vm._s(item.ds_create_user_name)),
                    ]),
                    _vm._v(
                      "   " +
                        _vm._s(item.ds_create_time) +
                        "  " +
                        _vm._s(item.in_out_text) +
                        " "
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "login_user_info" }, [
        _c("div", {
          ref: "loginUserEchats",
          staticClass: "login_user_echarts",
          style: { width: "100%", height: "190px" },
          attrs: { id: "loginUserEchats" },
        }),
      ]),
      _c("div", { staticClass: "learn_time" }, [
        _c("div", {
          ref: "learnTimeEchats",
          staticClass: "learn_time_echarts",
          style: { width: "100%", height: "190px" },
          attrs: { id: "learnTimeEchats" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "message_items ell" }, [
      _c("span", { staticClass: "yellow_font" }, [_vm._v("张三")]),
      _vm._v(" 完成了《XXXX》课程学习 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "message_items ell" }, [
      _c("span", { staticClass: "yellow_font" }, [_vm._v("王五")]),
      _vm._v(" 被 "),
      _c("span", { staticClass: "yellow_font" }, [_vm._v("李四")]),
      _vm._v(" 邀请加入了本平台 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "message_items ell" }, [
      _c("span", { staticClass: "yellow_font" }, [_vm._v("赵六")]),
      _vm._v(" 被 "),
      _c("span", { staticClass: "yellow_font" }, [_vm._v("管理员")]),
      _vm._v(" 添加到了本平台 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "message_items ell" }, [
      _c("span", { staticClass: "yellow_font" }, [_vm._v("王德发")]),
      _vm._v(" 退出了本平台 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }