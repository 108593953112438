var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "face",
      attrs: {
        title: "提示",
        visible: _vm.showFace,
        "show-close": false,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showFace = $event
        },
      },
    },
    [
      [
        _c("div", { staticClass: "faceQrcode" }, [
          _c("img", { attrs: { src: _vm.socketImg, alt: "" } }),
        ]),
        _c("div", { staticClass: "desc" }, [_vm._v("扫一扫人脸识别")]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }