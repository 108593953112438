<template>
  <div class="dsf_userInfo" v-if="visible" :style="{ width: width, height: height }">
    <userTitle :title="title" :moreUrl="moreUrl" :showMore="showMore" v-if="showHeader"></userTitle>
    <div class="userInfo-content">
      <p class="margin-bottom-20">
        <span class="padding-left-10">姓名：</span>
        <span class="margin-left-10">{{ resData[keys.name] }}</span>
      </p>
      <p class="margin-bottom-20">
        <span class="padding-left-10">性别：</span>
        <span class="margin-left-10">{{ resData[keys.sex] || "" }}</span>
      </p>
      <p class="margin-bottom-20">
        <span class="padding-left-10">职务：</span>
        <span class="margin-left-10">{{ resData[keys.job] || "" }}</span>
      </p>
      <!-- require -->
      <p class="margin-bottom-20">
        <span class="padding-left-10">手机号：</span>
        <span class="margin-left-10">{{ resData[keys.phone || ""] }}</span>
      </p>
      <div class="tags">
        <!-- <span class="padding-left-10">兴趣爱好：</span>
        <span class="margin-left-10">{{ resData[keys.hobbies] || "" }}</span> -->
        <p class="padding-left-10 table-cell tag-title" style="padding-bottom: 20px">兴趣爱好：</p>
        <p class="margin-left-10 table-cell tag-box" v-if="resData[keys.hobbies]">
          <span class="tag" v-for="(item, index) in resData[keys.hobbies].split('^')" :key="index">{{ item }}</span>
        </p>
      </div>
      <div class="tags">
        <p class="padding-left-10 table-cell tag-title">个人标签：</p>
        <p class="margin-left-10 table-cell tag-box" v-if="resData[keys.tags]">
          <span class="tag" v-for="(item, index) in resData[keys.tags].split('^')" :key="index">{{ item }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import userTitle from "./userTitle.vue";
export default dsf.component({
  name: "DsfNcUserInfo",
  ctrlCaption: "个人信息",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "个人信息",
    },
    width: {
      type: String,
      default: "480px",
    },
    height: {
      type: String,
      default: "400px",
    },
    moreUrl: { type: String, default: "" },
    reqUrl: { type: String, default: "" },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "name", to: "name", type: "text", desc: "姓名" },
          { from: "sex", to: "sex", type: "text", desc: "性别" },
          { from: "job", to: "job", type: "text", desc: "职务" },
          { from: "phone", to: "phone", type: "text|number", desc: "手机号" },
          { from: "hobbies", to: "hobbies", type: "text", desc: "兴趣爱好" },
          { from: "tags", to: "tags", type: "text", desc: "个人标签" },
        ];
      },
    },
    resData: {
      tyep: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      keys: {},
    };
  },
  components: {
    userTitle,
  },
  created() {
    this.keysMap.forEach(item => {
      this.keys[item.to] = item.from;
    });
  },
  mounted() {
    if (this.isDesign) {
      this.resData = {
        name: "张三",
        sex: "男",
        job: "教师",
        phone: 13549785461,
        hobbies: "爱好^数字化^数字化",
        tags: "数字化^数字化^数字化^数字化^数字化",
      };
    } else {
      this.getData();
    }
  },
  methods: {
    getData() {
      if (this.reqUrl) {
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              this.resData = res.data;
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          });
      }
    },
  },
});
</script>
