<template>
  <div class="dsf_nc_check_briefsummary" :class="getCss">
    <el-dialog
      :visible.sync="show"
      :width="dialogWidth"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      :append-to-body="true"
      @close="onClose"
      :custom-class="'nc_pc_briefsummary_dialog'"
    >
      <span slot="title" v-if="showTitle">{{ titleName }}</span>
      <span slot="title" v-else>{{ showWrit ? "撰写小结" : "查看小结" }}</span>
      <div class="briefsummary_writting" v-if="showWrit">
        <el-form ref="form" :model="formModel" label-width="80px" :rules="rules" :disabled="readyonly || homeworkState == 1">
          <div class="homework_desc">作业描述：{{ homeWorkDesc.requirements || "暂无数据" }}</div>
          <el-form-item class="dsf_form_item">
            <div class="conditions_content">
              <el-form-item label="标题" class="dsf_item_form" prop="title">
                <el-input class="dsf_input" v-model="formModel.title" placeholder="请输入" />
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item class="dsf_form_item">
            <div class="conditions_content">
              <el-form-item label="内容" class="dsf_item_form" prop="content">
                <el-input type="textarea" class="dsf_input" :rows="10" :resize="'none'" v-model="formModel.content" placeholder="请输入"></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item class="dsf_btns">
            <DsfButtonBar :buttons="buttons" :max-number="maxNumber" @save="onSubmit('form')" @close="onClose" />
          </el-form-item>
        </el-form>
      </div>
      <div class="nc_pc_check_briefsummary_dialog" v-if="showRead">
        <div class="content_wrapper">
          <div class="teach">
            <div :class="['score', checkForm.state_value == '2' ? 'pass' : checkForm.state_value == '-1' ? 'nopass' : '']">
              <div class="left">
                <span class="label">分数:&nbsp;</span>
                <span class="font" v-if="checkForm.score_type_value == 2">{{ checkForm.score || "0" }}分</span>
                <span class="font" v-else>{{ checkForm.score || "暂无" }}</span>
              </div>
              <div class="right"></div>
            </div>
            <div class="py">
              <span class="label">评语:&nbsp;</span>
              <span class="font">{{ checkForm.reviews || "暂无" }}</span>
            </div>
          </div>
          <div class="briefsummayr">
            <div class="xiaojieTitle">{{ checkForm.title }}</div>
            <div class="xiaojie_content">{{ checkForm.content }}</div>
          </div>
          <div class="dsf_btns">
            <DsfButtonBar :buttons="checkButtons" :max-number="maxNumber" @reclick="onReSubmit()" @close="onClose()" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCheckBriefSummary",
  ctrlCaption: "撰写查看小结",
  mixins: [$mixins.control, $mixins.buttonBar],
  props: {
    buttons: {
      type: Array,
      default() {
        return [
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-guanbi2",
            id: "BeIBdsm19uqcoaVc",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "关闭",
            title: "",
            type: "plain",
          },
          {
            actionName: "save",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-baocun",
            id: "Ld3EJ3ytFxJpgA3Q",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "保存",
            title: "",
          },
        ];
      },
    },
    checkButtons: {
      type: Array,
      default() {
        return [
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-guanbi2",
            id: "BeIBdsm19uqcoaVc",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "关闭",
            title: "",
            type: "plain",
          },
          {
            actionName: "reclick",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-baocun",
            id: "Ld3EJ3ytFxJpgA3Q",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "重新提交",
            title: "",
          },
        ];
      },
    },
    maxNumber: {
      type: Number,
      default: 3,
    },
    titleName: {
      type: String,
      default: "撰写小结",
    },
    homeworkId: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "/nc/homework/assessment/writing",
    },
    homeworkState: {
      type: String || Number,
      default: "",
    },
    readyonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    showReadOrWrite: {
      type: Boolean,
      default: false,
    },
    homeworkStudentId: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: String,
      default: "50%",
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showWrit: false,
      showRead: false,
      formModel: {
        homeworkId: "",
        title: "",
        content: "",
      },
      rules: {
        title: [{ required: this.required, trigger: "blur", validator: this.validator }],
        content: [{ required: this.required, message: "请填写内容", trigger: "blur" }],
      },
      checkForm: {},
      homeWorkDesc: {},
    };
  },
  mounted() {
    if (!this.isDesign) {
      this.formModel.homeworkId = this.homeworkId || "1a21b294abe84ae5a864b320b20c9765";
      /*homeworkState: 1 待批阅； -1 退回； 2 成功*/
      console.log(this.homeworkState);

      if (this.showReadOrWrite) {
        this.checkData();
        this.showRead = true;
      } else {
        if (this.homeworkState == 1) {
          this.initData();
        }
        this.showWrit = true;
      }
      if (this.homeworkState == 1) {
        this.buttons = this.buttons.filter(item => {
          return item.actionName == "close";
        });
      }
      if (this.homeworkState == 2) {
        this.checkButtons = this.checkButtons.filter(item => {
          return item.actionName == "close";
        });
      }
      this.initHomeWorkDesc();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(
          "nc/homework/student/details",
          {
            homeworkId: this.formModel.homeworkId,
          },
          $$webRoot.nc
        )
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.formModel = {
              title: data.title, //作业
              content: data.content,
              homeworkId: data.nc_homework_id,
            };
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    checkData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(`nc/homework/assessment/wait-reading/${this.homeworkStudentId}`, {}, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.checkForm = data.info || {};
            this.formModel = {
              title: this.checkForm.title, //作业
              content: this.checkForm.content,
              homeworkId: this.checkForm.nc_homework_id,
            };
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    initHomeWorkDesc() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(`nc/homework/info/${this.formModel.homeworkId}`, {}, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.homeWorkDesc = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    onReSubmit() {
      this.showWrit = true;
      this.showRead = false;
    },
    validator(rule, value, callback) {
      if (value.length > 50) {
        callback(new Error("标题长度不能超过50字"));
      } else if (value == null || value == "") {
        callback(new Error("请填写标题"));
      } else {
        callback();
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          let loading = dsf.layer.pc.loading();
          let headers = {
            "Content-Type": "application/json",
          };
          dsf.http
            .post(
              this.url,
              JSON.stringify(this.formModel),
              {
                headers,
              },
              $$webRoot.nc
            )
            .done(res => {
              let { success } = res;
              if (!success) {
                this.error(res);
              } else {
                dsf.layer.pc.message(`${this.titleName}成功`, true);
                // this.$emit("update:show", false);
                this.$emit("breisfsSummarySubmit");
              }
            })
            .error(err => {
              this.error(err);
            })
            .always(res => {
              dsf.layer.pc.closeLoading(loading);
            });
        } else {
          return false;
        }
      });
      // if (this.required && (!this.formModel.title || !this.formModel.content)) return dsf.layer.pc.message("请完成作业填写", false);

      // console.log("8787===>onSubmit", this.formModel);
    },
    onClose() {
      this.$emit("update:show", false);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
