var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf-nc-channel-shop-card" }, [
        _c("div", { staticClass: "content" }, [
          _vm.isDesign
            ? _c(
                "div",
                { staticClass: "item-wrap", style: { width: _vm.cardWidth } },
                [
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    [
                      _c("el-image", {
                        attrs: { src: _vm._f("imgFormat")("/"), fit: "cover" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("p", { staticClass: "title" }, [
                      _vm._v("上海全力打造全国新一轮全面开放新高地的新举措"),
                    ]),
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "shop-buy-items no-padding posi-bottom" },
                      [
                        _c("div", { staticClass: "ms" }, [
                          _c("span", { staticClass: "prefix" }, [
                            _vm._v("秒杀"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "c-sale" },
                            [
                              _c("dsf-icon", { attrs: { name: "jinbi" } }),
                              _vm._v(" 8500 "),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "o-sale" }, [
                            _vm._v("1200"),
                          ]),
                        ]),
                        _c("div", { staticClass: "ms" }, [
                          _c("span", { staticClass: "prefix present" }, [
                            _vm._v("赠"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "jf" },
                            [
                              _c("dsf-icon", { attrs: { name: "jinbi" } }),
                              _vm._v(" 10 "),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "dyq" },
                            [
                              _c("dsf-icon", { attrs: { name: "quan" } }),
                              _vm._v(" 20 "),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            : _c(
                "div",
                { staticClass: "item-wrap", style: { width: _vm.cardWidth } },
                [
                  _c(
                    "div",
                    { staticClass: "img-box", on: { click: _vm.goDetail } },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.data["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info" }, [
                    _c("p", { staticClass: "title ellipsis-2" }, [
                      _vm._v(_vm._s(_vm.data["name"])),
                    ]),
                    _c("div", { staticClass: "detail" }, [
                      _c("div", { staticClass: "teacher" }, [
                        _c("span", { staticClass: "job" }, [
                          _vm._v(_vm._s(_vm.data["courseNum"] || 0) + "门课程"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.data["classHours"] || 0) + "个学时"
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "sales" }, [
                        _vm._v("销量：" + _vm._s(_vm.data["indentNum"] || 0)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "shop-buy-items no-padding posi-bottom" },
                      [
                        _c("div", { staticClass: "ms" }, [
                          _vm.prefixText && _vm.getPrefix("prefixText")
                            ? _c("span", { staticClass: "prefix" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.prefixText
                                      ? _vm.getPrefix("prefixText")
                                      : ""
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            { staticClass: "c-sale" },
                            [
                              _c("dsf-icon", { attrs: { name: "jinbi" } }),
                              _vm._v(
                                " " + _vm._s(_vm.data["price"] || "免费") + " "
                              ),
                            ],
                            1
                          ),
                          _vm.data["activityOldPrice"]
                            ? _c("span", { staticClass: "o-sale" }, [
                                _vm._v(_vm._s(_vm.data["activityOldPrice"])),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "ms" }, [
                          _vm.data["giftPayment"]
                            ? _c("span", { staticClass: "prefix present" }, [
                                _vm._v("赠"),
                              ])
                            : _vm._e(),
                          _vm.data["giftPayment"]
                            ? _c(
                                "span",
                                { staticClass: "jf" },
                                [
                                  _c("dsf-icon", { attrs: { name: "jinbi" } }),
                                  _vm._v(
                                    " " + _vm._s(_vm.data["giftPayment"]) + " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.data["giftCoupon"]
                            ? _c(
                                "span",
                                { staticClass: "dyq" },
                                [
                                  _c("dsf-icon", { attrs: { name: "quan" } }),
                                  _vm._v(
                                    " " + _vm._s(_vm.data["giftCoupon"]) + " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail" }, [
      _c("div", { staticClass: "teacher" }, [
        _c("span", { staticClass: "job" }, [_vm._v("15门课程")]),
        _c("span", [_vm._v("15个学时")]),
      ]),
      _c("div", { staticClass: "sales" }, [_vm._v("销量：342")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }