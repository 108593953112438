import manageMyInfo from "./manageMyInfo";
import manageLearnTask from "./manageLearnTask";
import manageLearnSpecial from "./manageLearnSpecial";
import manageTeachResource from "./manageTeachResource";
import managePlatformDynamic from "./managePlatformDynamic";
import dataCenter from "./dataCenter";
import manageAddSource from "./manageAddSource";
import manageAddSourceNew from "./manageAddSourceNew";

export default [manageMyInfo, manageLearnTask, manageTeachResource, managePlatformDynamic, manageLearnSpecial, dataCenter, manageAddSource, manageAddSourceNew];
