var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_saas saas_setting_footer_model1" },
    [
      _c("div", { staticClass: "subtitle margint20 marginb20" }, [
        _vm._v("效果预览"),
      ]),
      _c("footerTwo", {
        attrs: { itemWidth: "100%", margin: "9px 0 50px 0", data: _vm.data },
      }),
      _c("div", { staticClass: "setting" }, [
        _c("div", [
          _c("div", [
            _c("div", { staticClass: "subtitle margint20 marginb20" }, [
              _vm._v("基本信息配置"),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("label", [_vm._v("公司地址")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入公司地址" },
                    model: {
                      value: _vm.data.addressTwo,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "addressTwo", $$v)
                      },
                      expression: "data.addressTwo",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("label", [_vm._v("邮箱")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.data.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "email", $$v)
                      },
                      expression: "data.email",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("label", [_vm._v("版权所有©")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入版权所有©" },
                    model: {
                      value: _vm.data.copyright,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "copyright", $$v)
                      },
                      expression: "data.copyright",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("label", [_vm._v("网站备案号")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入网站备案号" },
                    model: {
                      value: _vm.data.websiteInternet,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "websiteInternet", $$v)
                      },
                      expression: "data.websiteInternet",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("label", [_vm._v("咨询热线")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入咨询热线" },
                    model: {
                      value: _vm.data.hotLine,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "hotLine", $$v)
                      },
                      expression: "data.hotLine",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", [
          _c("div", { staticClass: "subtitle margint20 marginb20" }, [
            _vm._v("推广二维码"),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("二维码（一）")]),
            _c(
              "div",
              { staticClass: "upload" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess1,
                    },
                  },
                  [
                    _vm.data.qrcodeOneImg
                      ? _c("DsfImage", {
                          staticClass: "avatar",
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeOneImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ],
                  1
                ),
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess1,
                    },
                  },
                  [
                    _c("dsf-button", { attrs: { size: "small" } }, [
                      _vm._v("上传"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "dsf-button",
                  {
                    attrs: { size: "small", type: "plain" },
                    on: { click: _vm.clearIcon1 },
                  },
                  [_vm._v("删除")]
                ),
                _c("el-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.data.qrcodeOneName,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "qrcodeOneName", $$v)
                    },
                    expression: "data.qrcodeOneName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("二维码（二）")]),
            _c(
              "div",
              { staticClass: "upload" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess2,
                    },
                  },
                  [
                    _vm.data.qrcodeTwoImg
                      ? _c("DsfImage", {
                          staticClass: "avatar",
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeTwoImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ],
                  1
                ),
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess2,
                    },
                  },
                  [
                    _c("dsf-button", { attrs: { size: "small" } }, [
                      _vm._v("上传"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "dsf-button",
                  {
                    attrs: { size: "small", type: "plain" },
                    on: { click: _vm.clearIcon2 },
                  },
                  [_vm._v("删除")]
                ),
                _c("el-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.data.qrcodeTwoName,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "qrcodeTwoName", $$v)
                    },
                    expression: "data.qrcodeTwoName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }, [
            _c("label", [_vm._v("二维码（三）")]),
            _c(
              "div",
              { staticClass: "upload" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess3,
                    },
                  },
                  [
                    _vm.data.qrcodeThreeImg
                      ? _c("DsfImage", {
                          staticClass: "avatar",
                          attrs: {
                            src: JSON.parse(_vm.data.qrcodeThreeImg || "{}")
                              .relativePath,
                          },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ],
                  1
                ),
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: "image/jpg,image/jpeg,image/png",
                      action: "/file/upload",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess3,
                    },
                  },
                  [
                    _c("dsf-button", { attrs: { size: "small" } }, [
                      _vm._v("上传"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "dsf-button",
                  {
                    attrs: { size: "small", type: "plain" },
                    on: { click: _vm.clearIcon3 },
                  },
                  [_vm._v("删除")]
                ),
                _c("el-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.data.qrcodeThreeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "qrcodeThreeName", $$v)
                    },
                    expression: "data.qrcodeThreeName",
                  },
                }),
                _c("dsf-nc-crop-image", {
                  attrs: {
                    "show-tips-text": true,
                    resize: false,
                    "edit-img": _vm.editImg,
                    "edit-file-img": _vm.editFileImg,
                    "aspect-ratio": _vm.aspectRatio,
                    "request-url": _vm.setRequestUrl,
                  },
                  on: { saveFile: _vm.editFile, handleClose: _vm.handleClose },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "footer_btn" },
        [
          _c(
            "dsf-button",
            { attrs: { type: "plain" }, on: { click: _vm.backPage } },
            [_vm._v("取消")]
          ),
          _c(
            "dsf-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveModel } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }