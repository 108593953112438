var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isDesign || _vm.visible,
          expression: "isDesign || visible",
        },
      ],
      staticClass: "ds-control",
      class: { "ds-no-padding": !_vm.isDesign },
      style: _vm.controlStyle,
    },
    [
      _c(
        "div",
        {
          class:
            "ds-nc-home-panel " +
            (_vm.styleType ? "ds-nc-home-panel-" + _vm.styleType : ""),
          style: _vm.panelStyle,
          attrs: { "is-design": _vm.isDesign, showShadow: _vm.showShadow },
        },
        [
          _c("div", { staticClass: "ds-nc-home-panel-header" }, [
            _c(
              "div",
              { staticClass: "ds-nc-home-panel-header-left" },
              [
                _vm._t("header-title", function () {
                  return [
                    _vm.titleIcon
                      ? _c(
                          "div",
                          { staticClass: "ds-nc-home-panel-icon" },
                          [_c("DsfIcon", { attrs: { name: _vm.titleIcon } })],
                          1
                        )
                      : _vm._e(),
                    _vm.title
                      ? _c("div", { staticClass: "ds-nc-home-panel-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ])
                      : _vm._e(),
                  ]
                }),
                _vm._t("header-right"),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "ds-nc-home-panel-header-center" },
              [_vm._t("header-center")],
              2
            ),
            _c(
              "div",
              { staticClass: "ds-nc-home-panel-header-right" },
              [
                _vm._t("header-right"),
                _vm.moreIcon || _vm.moreText
                  ? _c(
                      "div",
                      {
                        staticClass: "ds-nc-home-panel-more",
                        on: { click: _vm.more },
                      },
                      [
                        _vm.moreText
                          ? [
                              _c(
                                "span",
                                { staticClass: "ds-nc-home-panel-more-font" },
                                [_vm._v(_vm._s(_vm.moreText))]
                              ),
                            ]
                          : _vm._e(),
                        _c("DsfIcon", { attrs: { name: _vm.moreIcon } }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "ds-nc-home-panel-content",
              style: { padding: _vm.padding.join("px ") + "px" },
              attrs: { "slot-name": "default" },
            },
            [
              _vm.isDesign
                ? [_vm._t("default")]
                : [
                    _c(
                      "DsfVirtualScroll",
                      { attrs: { isDesign: _vm.isDesign } },
                      [_vm._t("default")],
                      2
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
      _c("DsfNcPcAddSource", {
        attrs: { show: _vm.showDialog },
        on: {
          "update:show": function ($event) {
            _vm.showDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }