var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-navtab ds-nc-tabs",
      class: { "ds-no-padding": !_vm.showPadding },
      style: { height: _vm.isDesign ? "100%" : _vm.tabHeight },
    },
    [
      _c(
        "div",
        { staticClass: "ds-tab-title" },
        _vm._l(
          _vm.slots.filter(function (tab) {
            return _vm.isHide(tab) !== true
          }),
          function (d, index) {
            return _c(
              "div",
              {
                key: d.name,
                class: { "is-active": index === _vm.curIdx },
                on: {
                  click: function ($event) {
                    return _vm.tabChange(index)
                  },
                },
              },
              [_vm._v(" " + _vm._s(d.title) + " ")]
            )
          }
        ),
        0
      ),
      _vm._l(
        _vm.slots.filter(function (tab) {
          return _vm.isHide(tab) !== true
        }),
        function (d, $index) {
          return [
            $index === _vm.curIdx
              ? _c(
                  "div",
                  {
                    key: d.name,
                    staticClass: "tab-pannel",
                    attrs: {
                      label: d.title,
                      name: d.name,
                      "slot-name": d.name,
                    },
                  },
                  [_vm._t(d.name)],
                  2
                )
              : _vm._e(),
          ]
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }