var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-selectPlatform-pc-control", style: _vm.controlStyle },
    [
      _c("div", { staticClass: "dsf-selectPlatform-title" }, [
        _vm._v("选择平台"),
      ]),
      !_vm.isAapplying
        ? _c("div", { staticClass: "dsf-selectPlatform-content" }, [
            _c("div", { staticClass: "content-title" }, [
              _vm._v("请选择创建或加入平台："),
            ]),
            _c("div", { staticClass: "join-row" }, [
              _c(
                "div",
                {
                  staticClass: "col",
                  class: { active: _vm.joinType == 1 },
                  style: { width: !_vm.showCreatePlantform ? "48%" : "" },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.joinType,
                        callback: function ($$v) {
                          _vm.joinType = $$v
                        },
                        expression: "joinType",
                      },
                    },
                    [_vm._v("加入平台")]
                  ),
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "你的同伴也许正在使用学习邦，赶紧加入平台来一起学习吧！"
                    ),
                  ]),
                ],
                1
              ),
              _vm.showCreatePlantform
                ? _c(
                    "div",
                    {
                      staticClass: "col",
                      class: { active: _vm.joinType == 2 },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.joinType,
                            callback: function ($$v) {
                              _vm.joinType = $$v
                            },
                            expression: "joinType",
                          },
                        },
                        [_vm._v("创建平台")]
                      ),
                      _c("p", { staticClass: "hint" }, [
                        _vm._v(
                          "创建平台组织，作为管理员，邀请团队成员共同学习！"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "col",
                  class: { active: _vm.joinType == 3 },
                  style: { width: !_vm.showCreatePlantform ? "48%" : "" },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      model: {
                        value: _vm.joinType,
                        callback: function ($$v) {
                          _vm.joinType = $$v
                        },
                        expression: "joinType",
                      },
                    },
                    [_vm._v("试用平台")]
                  ),
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "加入平台默认组织，作为平台学员进行学习、了解平台！"
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "dsf-selectPlatform-content" }, [_vm._m(0)]),
      !_vm.isAapplying
        ? _c("div", { staticClass: "type-content-detail" }, [
            _vm.joinType == 2
              ? _c(
                  "div",
                  { staticClass: "creat-platform" },
                  [
                    _c("div", { staticClass: "content-title" }, [
                      _vm._v("请填写创建的平台资料"),
                    ]),
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "creatForm",
                        attrs: { model: _vm.creatForm, "label-width": "100px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "平台类型：", required: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "width-400",
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.handleChangePlatform },
                                model: {
                                  value: _vm.creatForm.platformType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.creatForm, "platformType", $$v)
                                  },
                                  expression: "creatForm.platformType",
                                },
                              },
                              _vm._l(
                                _vm.platformTypeList,
                                function (item, idx) {
                                  return _c("el-option", {
                                    key: idx,
                                    attrs: {
                                      label: item.text,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "width-500",
                            attrs: { label: "平台名称：", required: "" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.creatForm.unitname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.creatForm, "unitname", $$v)
                                },
                                expression: "creatForm.unitname",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "人员规模：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "width-400",
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.handleChangePeople },
                                model: {
                                  value: _vm.creatForm.peopleScale,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.creatForm, "peopleScale", $$v)
                                  },
                                  expression: "creatForm.peopleScale",
                                },
                              },
                              _vm._l(_vm.peopleScaleList, function (item, idx) {
                                return _c("el-option", {
                                  key: idx,
                                  attrs: {
                                    label: item.text,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isLogin
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "width-500",
                                attrs: { label: "所在地区：" },
                              },
                              [
                                _c("DsfAreaSelect", {
                                  attrs: {
                                    range: ["0", "2"],
                                    showLabel: false,
                                  },
                                  model: {
                                    value: _vm.creatForm.areaselectList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.creatForm,
                                        "areaselectList",
                                        $$v
                                      )
                                    },
                                    expression: "creatForm.areaselectList",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "width-500",
                            attrs: { label: "详细地址：" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.creatForm.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.creatForm, "address", $$v)
                                },
                                expression: "creatForm.address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.joinType == 1
              ? _c("div", { staticClass: "join-platform" }, [
                  _c("div", { staticClass: "content-title" }, [
                    _vm._v("请选择要加入的平台"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "search-box" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入搜索内容" },
                          model: {
                            value: _vm.searchText,
                            callback: function ($$v) {
                              _vm.searchText = $$v
                            },
                            expression: "searchText",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "append" },
                              on: { click: _vm.handleSearch },
                              slot: "append",
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "platform-row" },
                    _vm._l(_vm.joinList, function (item, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "platform-row-col" },
                        [
                          _c("el-radio", {
                            staticClass: "platform-radio",
                            attrs: { label: item.unitid },
                            on: { change: _vm.handleChangeJionPlatfrom },
                            model: {
                              value: _vm.joinPlatform,
                              callback: function ($$v) {
                                _vm.joinPlatform = $$v
                              },
                              expression: "joinPlatform",
                            },
                          }),
                          _c("div", { staticClass: "col" }, [
                            _c("div", {
                              staticClass: "col-header imgType",
                              class: {
                                imgType1: item.platform_type_value == 0,
                                imgType2: item.platform_type_value == 1,
                                imgType3: item.platform_type_value == 2,
                                imgType4: item.platform_type_value == 3,
                              },
                            }),
                            _c("div", { staticClass: "col-positon" }, [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.unitname)),
                              ]),
                              item.platform_type_value
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "type",
                                      class: {
                                        type1: item.platform_type_value == 0,
                                        type2: item.platform_type_value == 1,
                                        type3: item.platform_type_value == 2,
                                        type4: item.platform_type_value == 3,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.platform_type_text) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "col-address ell" }, [
                                _vm._v(
                                  _vm._s(item.area_text) + _vm._s(item.address)
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.joinType == 3
              ? _c("div", { staticClass: "join-platform" }, [
                  _c("div", { staticClass: "content-title" }, [
                    _vm._v("试用平台"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "platform-row" },
                    _vm._l(_vm.testList, function (item, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "platform-row-col" },
                        [
                          _c("el-radio", {
                            staticClass: "platform-radio",
                            attrs: { label: item.unitid },
                            on: { change: _vm.changeTestList },
                            model: {
                              value: _vm.testPlatform,
                              callback: function ($$v) {
                                _vm.testPlatform = $$v
                              },
                              expression: "testPlatform",
                            },
                          }),
                          _c("div", { staticClass: "col" }, [
                            _c("div", {
                              staticClass: "col-header imgType",
                              class: {
                                imgType1: item.platform_type_value == 0,
                                imgType2: item.platform_type_value == 1,
                                imgType3: item.platform_type_value == 2,
                                imgType4: item.platform_type_value == 3,
                              },
                            }),
                            _c("div", { staticClass: "col-positon" }, [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.unitname)),
                              ]),
                              item.platform_type_value
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "type",
                                      class: {
                                        type1: item.platform_type_value == 0,
                                        type2: item.platform_type_value == 1,
                                        type3: item.platform_type_value == 2,
                                        type4: item.platform_type_value == 3,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.platform_type_text) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "col-address ell" }, [
                                _vm._v(
                                  _vm._s(item.area_text) + _vm._s(item.address)
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "type-content-detail applying" }, [
            _c("div", { staticClass: "content-title" }, [_vm._v("加入平台")]),
            _c("div", { staticClass: "join-platform" }, [
              _vm.joinType != 3
                ? _c(
                    "div",
                    { staticClass: "platform-row" },
                    _vm._l(_vm.joinPlatformCache, function (item, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "platform-row-col" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("div", {
                              staticClass: "col-header imgType",
                              class: {
                                imgType1: item.platform_type_value == 0,
                                imgType2: item.platform_type_value == 1,
                                imgType3: item.platform_type_value == 2,
                                imgType4: item.platform_type_value == 3,
                              },
                            }),
                            _c("div", { staticClass: "col-positon" }, [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.unitname)),
                              ]),
                              item.platform_type_value
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "type",
                                      class: {
                                        type1: item.platform_type_value == 0,
                                        type2: item.platform_type_value == 1,
                                        type3: item.platform_type_value == 2,
                                        type4: item.platform_type_value == 3,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.platform_type_text) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "col-address ell" }, [
                                _vm._v(
                                  _vm._s(item.area_text) + _vm._s(item.address)
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.joinType == 3
                ? _c(
                    "div",
                    { staticClass: "platform-row" },
                    _vm._l(_vm.testPlatformCache, function (item, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "platform-row-col" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("div", {
                              staticClass: "col-header imgType",
                              class: {
                                imgType1: item.platform_type_value == 0,
                                imgType2: item.platform_type_value == 1,
                                imgType3: item.platform_type_value == 2,
                                imgType4: item.platform_type_value == 3,
                              },
                            }),
                            _c("div", { staticClass: "col-positon" }, [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.unitname)),
                              ]),
                              item.platform_type_value
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "type",
                                      class: {
                                        type1: item.platform_type_value == 0,
                                        type2: item.platform_type_value == 1,
                                        type3: item.platform_type_value == 2,
                                        type4: item.platform_type_value == 3,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.platform_type_text) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "col-address ell" }, [
                                _vm._v(
                                  _vm._s(item.area_text) + _vm._s(item.address)
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "content-title-applying" }, [
              _vm._v("申请理由"),
            ]),
            _c("div", { staticClass: "applying-content" }, [
              _vm._v(_vm._s(_vm.applyReason)),
            ]),
          ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogJoinVisible,
            width: "520px",
            title: "申请理由",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogJoinVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "apply-dialog" }, [
            _c("div", { staticClass: "hint" }, [
              _vm._v("加入该平台需填写申请理由，审批通过后方可加入。"),
            ]),
            _c(
              "div",
              { staticClass: "reason" },
              [
                _c("span", { staticClass: "reasonLabel" }, [_vm._v("理由：")]),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入",
                    maxlength: "1000",
                    "show-word-limit": "",
                  },
                  model: {
                    value: _vm.applyReason,
                    callback: function ($$v) {
                      _vm.applyReason =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "applyReason",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "apply-btns" }, [
              _c(
                "div",
                {
                  staticClass: "cancel-btn",
                  on: {
                    click: function ($event) {
                      _vm.dialogJoinVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
              _c(
                "div",
                { staticClass: "submit-btn", on: { click: _vm.applySubmit } },
                [_vm._v("提交")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "380px",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "join-dialog" }, [
            _c("div", { staticClass: "bg-box" }),
            _c(
              "div",
              {
                staticClass: "dialog-success-text",
                class: { "dialog-success-text-2": _vm.joinType != 2 },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.joinType == 2
                      ? "恭喜您，创建成功！"
                      : "恭喜您，加入成功！"
                  )
                ),
              ]
            ),
            _vm.joinType != 2
              ? _c("div", { staticClass: "hint-text" }, [
                  _vm._v("快前往首页进行学习吧"),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "dialog-btn pointer",
                on: { click: _vm.handleGoLogin },
              },
              [_vm._v("前往登录")]
            ),
          ]),
        ]
      ),
      !_vm.isAapplying
        ? _c("div", { staticClass: "btns" }, [
            _c("div", { staticClass: "cancel-btn", on: { click: _vm.close } }, [
              _vm._v("取消"),
            ]),
            _c(
              "div",
              { staticClass: "submit-btn", on: { click: _vm.onSubmit } },
              [_vm._v("确认")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "applying" }, [
      _c("div", { staticClass: "message-box" }),
      _c("div", { staticClass: "message-hint" }, [
        _vm._v("申请提交成功，待审核"),
      ]),
      _c("div", { staticClass: "message-hint2" }, [
        _vm._v("申请成功/失败将以短信方式通知您，请留意短信信息！"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }