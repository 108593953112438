<template>
  <div class="dsf_nc_note_item dsf_nc_advice_item">
    <div class="dsf_nc_note_item_inner dsf_nc_advice_item_inner">
      <el-form @submit.native.prevent>
        <el-form-item class="note-text">
          <el-input type="textarea" :placeholder="setPlaceholder" v-model="form.desc"></el-input>
        </el-form-item>
        <button class="advice-save" @click="saveAdvice" :style="{ width: btnWidth }">保存</button>
      </el-form>
      <div class="note-list-wrap">
        <div class="note-list" ref="noteList">
          <div class="advice-head">建议列表</div>
          <div class="note-list-item" v-for="item in list" :key="item._id">
            <div class="note-list-item-desc">
              <div class="note-info advice-info">
                <div class="advice-date">
                  <span>时间: {{ item.timespan || "00:00:00" }}</span>
                  <span>{{ item["publish_date"] }} 发布</span>
                </div>
                <div class="advice-text" v-html="item['content']"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "dsfNcAdvice",
  props: {
    setPlaceholder: {
      type: String,
      default: "请输入意见建议",
    },
    relationId: {
      type: String,
      default: "",
    },

    queryiType: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        desc: "",
        public: false,
        showType: "我的",
      },
      list: [],
      tempText: "",
      hasLoaded: false,
      loadCount: 0,
    };
  },
  computed: {
    ...mapGetters(["getScreenshot", "getCurPlayTime"]),
  },
  created() {},
  mounted() {
    this.loadAdviceList();
    console.log(this.item);
  },
  methods: {
    loadAdviceList(state) {
      const sourceUrl = "dbsource/interface/nc/operate/suggestion/listsource";
      const params = {
        sourceId: this.relationId,
      };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data }) => {
          if (success) {
            if (data.length < params.pageSize) this.hasLoaded = true;
            this.list = data;
            this.setNoteListHeight();
          }
        })
        .error(err => {
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {});
    },
    setNoteListHeight() {
      this.$nextTick(function () {
        const it = this.$refs.noteList;
        let winH = window.innerHeight;
        let itTop = it.offsetTop;
        let itH = it.offsetHeight;
        if (winH - itTop < itH) {
          this.$refs.noteList.style.height = winH - itTop + "px";
          this.$refs.noteList.style.overflowY = "scroll";
        }
      });
    },
    saveAdvice(idx, data) {
      let item = this.form;
      if (item.desc == "") return dsf.layer.pc.message("建议内容不能为空", false);
      const sourceUrl = "/meta/persistData";
      let p = {
        namespace: "nc.operate.suggestion",
        pageName: "edit",
        data: JSON.stringify({
          "nc_operate_suggestion.r_type": { text: this.queryiType == "course" ? "课程" : "专题班", value: this.queryiType },
          "nc_operate_suggestion.content": this.form.desc,
          "nc_operate_suggestion.source": { text: this.item.title, value: this.item.id },
          "nc_operate_suggestion.timespan": this.getCurPlayTime,
        }),
      };
      console.log(p);
      dsf.http
        .post(sourceUrl, p, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.loadCount = 0;
            this.loadAdviceList();
            this.form.desc = "";
            dsf.layer.pc.message("保存成功", true);
          } else {
            dsf.layer.pc.message(res.message, false);
          }
        })
        .error(err => {
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {});
    },
  },
  beforeDestroy() {},
};
</script>
