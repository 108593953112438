var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_cardItemByLive" }, [
        _c("div", { staticClass: "content" }, [
          _vm.isDesign
            ? _c("div", { staticClass: "item-wrap pointer" }, [
                _c(
                  "div",
                  { staticClass: "img-box" },
                  [
                    _c("el-image", {
                      attrs: {
                        src: _vm._f("imgFormat")(_vm.src),
                        fit: "cover",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "info" }, [
                  _c("p", { staticClass: "ell2" }, [
                    _vm._v("上海全力打造全国新一轮全面开放新高地的新举措"),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _c("span", [_vm._v("张三")]),
                    _c(
                      "span",
                      [
                        _c("dsf-icon", { staticClass: "icon-yanjing" }),
                        _c("span", { staticClass: "num" }, [_vm._v("4527")]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _c(
                "div",
                {
                  staticClass: "item-wrap pointer",
                  style: { width: _vm.cardWidth },
                  on: {
                    click: function ($event) {
                      return _vm._clickBefore(_vm.goDetail)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "img-box", style: { width: _vm.cardWidth } },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.data["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "info", style: { width: _vm.cardWidth } },
                    [
                      _c("p", { staticClass: "ell2" }, [
                        _vm._v(_vm._s(_vm.data["title"])),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _c("span", [_vm._v(_vm._s(_vm.data["teacher"]))]),
                        _c(
                          "span",
                          { staticClass: "views" },
                          [
                            _c("dsf-icon", { staticClass: "icon-yanjing" }),
                            _c("span", { staticClass: "num" }, [
                              _vm._v(_vm._s(_vm.data["views"] || 0)),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }