import { ncTitle } from "./titles";
import course from "./course";
import seach from "./search";
import sideBar from "./sidebar";
import periodList from "./periodlist";
import other from "./other";
import onStudy from "./onStudy";
import navmenus from "./navmenus";
import live from "./live";
import saas from "./saas";
const components = [ncTitle, ...course, ...seach, ...sideBar, ...live, ...periodList, ...other, ...onStudy, ...navmenus, ...saas];

export default components;
