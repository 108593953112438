var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfSection",
    {
      attrs: {
        mainWidth: "1200px",
        margin: [0, 0, 0, 0],
        padding: [10, 10, 10, 10],
        backgroundConfig: {
          color: "#F9F9F9",
          image: "",
          position: "center",
          repeat: "no-repeat",
          size: "cover",
        },
        foregroundConfig: {
          color: "rgba(255, 255, 255, 0)",
          image: "",
          size: "cover",
          repeat: "no-repeat",
          position: "center",
        },
      },
    },
    [
      _vm.showTitle
        ? _c("DsfNcTitle", {
            attrs: {
              title: _vm.title,
              caption: "DsfNcTitle",
              showBottomLine: true,
              titleSize: "big",
              titleBold: false,
              clickConfig: _vm.clickConfigTitle,
              padding: "80px 0 60px 0",
            },
          })
        : _vm._e(),
      _c("DsfPlatformCardBox", {
        attrs: {
          columnNumber: 3,
          columnWidth: "384px",
          marginTop: "15px",
          rowKey: "_id",
          url:
            "/inc/meta/list/data?pageNum=1&pageSize=6&query=%7B%22searchValue%22%3A%22%22%7D&order=%5B%5D&filter=%5B%5D&namespace=nc.class.page&pageName=main&t=1623231933366",
          checkbox: false,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("DsfNcSpecilaCard", {
                  attrs: {
                    local: scope.row,
                    keysMap: _vm.keysMap,
                    clickConfig: _vm.clickConfig,
                    "click-before": _vm.checkLogin,
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }