<template>
  <ul v-if="isDesign" class="ds-nc-card-course-two">
    <li class="card-course-items">
      <dsf-nc-image />
      <span class="title">健全多层次社会保障体系，让社会保障制度更公平（上）</span>
      <div class="footer">
        <span>主讲人：杨宜勇</span>
        <span>选课人次：284</span>
      </div>
    </li>
  </ul>
  <ul v-else class="ds-nc-card-course-two" :title="data['title']" @click="_clickBefore(click)">
    <li class="card-course-items">
      <dsf-nc-image :src="data['cover'] | imgFormat" style="width: 100%; height: 158px" :error-src="defaultSrc" />
      <span class="title">{{ data["title"] }}</span>
      <div class="footer">
        <span>主讲人：{{ data["teacher"] }}</span>
        <span>选课人次：{{ data["num"] }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
export default dsf.component({
  name: "DsfNcCourseCardTwo",
  ctrlCaption: "课程卡片2",
  mixins: [$mixins.card],
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "title", to: "title", type: "text", desc: "课题" },
          { from: "teacher", to: "teacher", type: "text", desc: "授课教师" },
          { from: "num", to: "num", type: "text|number", desc: "选课人次" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
  },
});
</script>
