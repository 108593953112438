import { render, staticRenderFns } from "./userback.vue?vue&type=template&id=52f4c448&scoped=true&"
import script from "./userback.vue?vue&type=script&lang=js&"
export * from "./userback.vue?vue&type=script&lang=js&"
import style0 from "./userback.vue?vue&type=style&index=0&id=52f4c448&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f4c448",
  null
  
)

component.options.__file = "pc/components/other/userback.vue"
export default component.exports