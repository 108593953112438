var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds_newslist", style: _vm.controlStyle }, [
    _vm.newsList.length
      ? _c(
          "div",
          { staticClass: "content" },
          _vm._l(_vm.newsList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "ds_newslist-row",
                style: _vm.rowStyle,
                on: {
                  click: function ($event) {
                    return _vm._clickBefore(_vm.goDetail, item)
                  },
                },
              },
              [
                _c("p", { staticClass: "time" }, [
                  _vm._v(
                    _vm._s(
                      item[_vm.keyField.time]
                        ? _vm.dsf.date.format(
                            item[_vm.keyField.time],
                            "yyyy.mm.dd"
                          )
                        : ""
                    )
                  ),
                ]),
                _c("p", { staticClass: "title ell" }, [
                  _vm._v(_vm._s(item[_vm.keyField.title])),
                ]),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "noDate" }, [_vm._v("暂无数据")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }