var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfSection",
    {
      attrs: {
        mainWidth: "1200px",
        margin: [0, 0, 0, 0],
        padding: [10, 10, 10, 10],
        backgroundConfig: {
          color: "#fff",
          image: "",
          size: "cover",
          repeat: "no-repeat",
          position: "center",
        },
        foregroundConfig: {
          color: "rgba(255, 255, 255, 0)",
          image: "",
          size: "cover",
          repeat: "no-repeat",
          position: "center",
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dsf_nc_xinwenzixun" },
        [
          _vm.showTitle
            ? _c("DsfNcTitle", {
                attrs: {
                  showMore: _vm.showMore,
                  title: _vm.title,
                  caption: "DsfNcTitle",
                  showBottomLine: true,
                  titleSize: "big",
                  titleBold: false,
                  clickConfig: _vm.clickConfigTitle,
                  padding: "80px 0 60px 0",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "wrap" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "item pointer",
                  on: {
                    click: function ($event) {
                      return _vm._clickBefore(_vm.goDetail, item)
                    },
                  },
                },
                [_vm._v(_vm._s(index + 1) + "、" + _vm._s(item.title))]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }