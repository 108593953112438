<template>
  <div class="header_model header_model_type1">
    <div class="header">
      <div class="top">
        <div class="logo">
          <dsf-image :src="logoImg[0] && logoImg[0].relativePath" :key="logoImg[0] && logoImg[0].relativePath"></dsf-image>
        </div>
        <div class="login_search">
          <dsf-icon name="Magnifier"></dsf-icon>
          登录
        </div>
      </div>
      <div class="bottom">
        <div class="dsf-college-home-header-menu">
          <div class="dsf-college-home-header-menu-bar" :style="barStyle"></div>
          <div class="dsf-college-home-header-menu-list">
            <template v-for="(menu, index) in activeMenuList">
              <div
                ref="menu"
                class="dsf-college-home-header-menu-item"
                :key="menu['ncSaasSettingMenuId'] + '-' + index"
                :class="{ active: activeMenuIndex === index }"
                @click="_clickBefore(menuItemClick, menu, index)"
              >
                {{ menu["title"] }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcHeaderOne",
  ctrlCaption: "门户页头1",
  mixins: [$mixins.control],
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    logoImg: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {
      activeMenuIndex: 0,
      showMore: false,
      barStyle: {},
      changeType: false,
      activeMenuList: [],
    };
  },
  watch: {
    activeMenuIndex: {
      handler(to) {
        this.updateBarStyle();
      },
      deep: true,
    },
    menuList: {
      handler(v) {
        this.activeMenuIndex = 0;
        this.activeMenuList = this.menuList.filter(m => {
          return m["state"].value == "1";
        });
        this.updateBarStyle();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.updateBarStyle();
  },
  methods: {
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
      } catch (err) {
        console.log(err);
      }
    },
    updateBarStyle() {
      let l = this.activeMenuList.length;
      if (this.activeMenuIndex < 0 || this.activeMenuIndex >= l) {
        this.barStyle = { display: "none" };
        return;
      }
      this.$nextTick(() => {
        let activeMenu = this.$refs.menu?.[this.activeMenuIndex];
        if (!activeMenu) {
          this.barStyle = { display: "none" };
        } else {
          let left = activeMenu.offsetLeft + activeMenu.offsetWidth / 2;
          this.barStyle = {
            transform: `translate(calc(${left}px - 50%), 0) scale(.5)`,
          };
        }
        this.$forceUpdate();
      });
    },
    menuItemClick(item, index) {
      if (!dsf.getToken()) return;
      if (item.menu_opentype_value != 2) {
        this.activeMenuIndex = index;
      }
      if (this.lastLevelClick) {
        if ((!item.children || !item.children.length) && item["menuUrl"]) {
          // menuUtils.openPage.call(this.homeRoot, item);
        }
      } else {
        // menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
      }
    },
    menuShowMore() {
      this.showMore = !this.showMore;
      console.log(this.showMore);
    },
  },
});
</script>
