import userback from "./userback";
import buttons from "./buttons";
import assessment from "./assessment";
import credential from "./credential";
import systemannouncement from "./systemannouncement";
import hotlist from "./hotlist";
import statisticalSummary from "./statisticalSummary";
import nchomepanel from "./nchomepanel";
import homework from "./homework";
import briefsummary from "./briefsummary";
import assessmentnew from "./assessmentnew";
import checkBriefsummary from "./checkBriefsummary";
import homeworkSummary from "./homeworkSummary";
import shopActiveTime from "./shopActiveTime";
import resouceList from "./resouceList";

export default [
  buttons,
  assessment,
  credential,
  systemannouncement,
  hotlist,
  statisticalSummary,
  nchomepanel,
  homework,
  briefsummary,
  assessmentnew,
  checkBriefsummary,
  homeworkSummary,
  shopActiveTime,
  resouceList,
];
