var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf_special_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            { staticClass: "item-left-img" },
            [
              _c("el-image", { attrs: { src: _vm._f("imgFormat")("") } }),
              _c("span", { staticClass: "item-type" }, [_vm._v("专题班")]),
              _c("span", { staticClass: "item-time" }, [
                _vm._v("2012-10-20 ~ 2012-10-25"),
              ]),
            ],
            1
          ),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "circle-wrap" },
            [
              _c("el-progress", {
                staticClass: "progress-circle",
                attrs: {
                  width: "60",
                  "stroke-width": "5",
                  color: _vm.curColor,
                  type: "circle",
                  percentage: 33,
                },
              }),
              _c("p", [_vm._v("当前进度")]),
            ],
            1
          ),
        ]),
      ])
    : _c("div", { staticClass: "dsf_special_column" }, [
        _c("div", { staticClass: "item_inner" }, [
          _c(
            "div",
            {
              staticClass: "item-left-img",
              on: {
                click: function ($event) {
                  return _vm._clickBefore(_vm.click)
                },
              },
            },
            [
              _c("dsf-nc-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
              _c("span", { staticClass: "item-type" }, [
                _vm._v(_vm._s(_vm.data.typeText)),
              ]),
              _c("span", { staticClass: "item-time" }, [
                _vm._v(
                  _vm._s(_vm.data.sTime) + " ~ " + _vm._s(_vm.data.endTime)
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "item-right-desc" }, [
            _c(
              "div",
              {
                staticClass: "title",
                on: {
                  click: function ($event) {
                    return _vm._clickBefore(_vm.click)
                  },
                },
              },
              [
                _vm.data.isNeed
                  ? _c("span", { staticClass: "item-need" }, [
                      _vm._v(_vm._s(_vm.data.isNeed)),
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "name ellipsis-2" }, [
                  _vm._v(_vm._s(_vm.data.title)),
                ]),
                _vm.data.isTask
                  ? _c("span", { staticClass: "task" }, [_vm._v("任务")])
                  : _vm._e(),
              ]
            ),
            _c("p", { staticClass: "item-teacher-name" }, [
              _c("span", [_vm._v(_vm._s(_vm.data.cTeacher))]),
              _c("span", [_vm._v(_vm._s(_vm.data.cJobUnit))]),
            ]),
            _c("ul", { staticClass: "item-bottom-desc" }, [
              _c("li", [
                _c("span", { staticClass: "desc-span" }, [_vm._v("必修")]),
                _vm._v(" " + _vm._s(_vm.data.cPeriod || 0) + "门课程 "),
              ]),
              _c("li", [
                _c("span", { staticClass: "desc-span" }, [_vm._v("选修")]),
                _vm._v(" " + _vm._s(_vm.data.cTagNum || 0) + "门课程 "),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "circle-wrap" },
            [
              _c("el-progress", {
                staticClass: "progress-circle",
                attrs: {
                  width: "60",
                  "stroke-width": "5",
                  color: _vm.curColor,
                  type: "circle",
                  percentage: _vm.data.finishedRateOrScore,
                  format: _vm.formatPercent,
                },
              }),
              _c("p", [_vm._v("当前进度")]),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-right-desc" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "name ellipsis-2" }, [
          _vm._v("我是一个标题"),
        ]),
        _c("span", { staticClass: "task" }, [_vm._v("任务")]),
      ]),
      _c("p", { staticClass: "item-teacher-name" }, [
        _c("span", [_vm._v("张三")]),
        _c("span", [_vm._v("项目经理")]),
      ]),
      _c("ul", { staticClass: "item-bottom-desc" }, [
        _c("li", [
          _c("span", { staticClass: "desc-span" }, [_vm._v("必修")]),
          _vm._v(" 10门课程 "),
        ]),
        _c("li", [
          _c("span", { staticClass: "desc-span" }, [_vm._v("选修")]),
          _vm._v(" 5门课程 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }