var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        width: "100%",
        "close-on-press-escape": false,
        "show-close": false,
        "append-to-body": true,
        "custom-class": "nc_pc_manage_dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isDesign || _vm.visible,
              expression: "isDesign || visible",
            },
          ],
          staticClass: "dsf_ncpc_manage_addsource",
        },
        [
          _c(
            "ul",
            { staticClass: "addsource_wrap" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "li",
                {
                  key: item.code + index,
                  staticClass: "addsource_items",
                  on: {
                    click: function ($event) {
                      return _vm.handlerClick(item, index)
                    },
                  },
                },
                [
                  _c("i", { class: "icon icon-" + item.code }),
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }