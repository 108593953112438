<template>
  <div class="dsf-nc-live-shop-card" v-if="visible">
    <div class="content">
      <div class="item-wrap" v-if="isDesign" :style="{ width: cardWidth }">
        <div class="img-box">
          <el-image :src="'/' | imgFormat" fit="cover"></el-image>
        </div>
        <div class="info">
          <div class="time-box">
            <span class="time">{{ formatterDate(11223232, "mm月dd日 hh:ii") }}</span>
            <span class="playType pointer">开启提醒</span>
          </div>
          <p class="ell">上海全力打造全国新一轮全面开放新高地的新举措</p>
          <div class="detail">
            <div class="teacher">
              <span class="job">网院</span>
              <span>测试</span>
            </div>
            <div class="sales">销量：230</div>
          </div>
          <div class="shop-buy-items no-padding posi-bottom">
            <div class="ms">
              <span class="prefix">秒杀价</span>
              <span class="c-sale">
                <dsf-icon />
                8500
              </span>
              <span class="o-sale">1200</span>
            </div>
            <div class="ms">
              <span class="prefix present">赠</span>
              <span class="jf">
                <dsf-icon />
                10
              </span>
              <span class="dyq">20</span>
            </div>
          </div>
        </div>
        <div class="live-type">未开始</div>
      </div>
      <div class="item-wrap pointer" v-else :style="{ width: cardWidth }" @click="_clickBefore(goDetail)">
        <div class="img-box">
          <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
        </div>
        <div class="info">
          <div class="time-box">
            <span class="time">{{ data["timeYMD"] }} {{ data["timeHMS"] }}</span>
            <span class="playType pointer">{{ getType(data) }}</span>
          </div>
          <p class="ell">{{ data["title"] }}</p>
          <div class="detail">
            <div class="teacher">
              <span class="job" v-if="data['teacherJobName']">{{ data["teacherJobName"] }}</span>
              <span>{{ data["teacher"] }}</span>
            </div>
            <div class="sales">销量：{{ data["indentNum"] || 0 }}</div>
          </div>

          <div class="shop-buy-items no-padding posi-bottom">
            <div class="ms">
              <span class="prefix" v-if="prefixText && getPrefix('prefixText')">{{ prefixText ? getPrefix("prefixText") : "" }}</span>
              <span class="c-sale">
                <dsf-icon name="jinbi" />
                {{ data["price"] || "免费" }}
              </span>
              <span class="o-sale" v-if="data['old_price']">{{ data["old_price"] }}</span>
            </div>
            <div class="ms" v-if="data['giftPayment']">
              <span class="prefix present">赠</span>
              <span class="jf">
                <dsf-icon />
                {{ data["giftPayment"] }}
              </span>
              <span class="dyq" v-if="data['giftCoupon']">{{ data["giftCoupon"] }}</span>
            </div>
          </div>
        </div>
        <div class="live-type" v-if="data['stateText']">{{ data["stateText"] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcShopLive",
  ctrlCaption: "商城直播卡片",
  mixins: [$mixins.card],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    cardWidth: {
      type: String,
      default: "580px",
    },
    detailUrl: {
      type: String,
      default: "/pc/nc/pagelive/liveDetail?id=",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "stateText", to: "stateText", type: "text", desc: "直播文字" },
          { from: "stateValue", to: "stateValue", type: "text|number", desc: "直播状态" },
          { from: "subscribe", to: "subscribe", type: "text", desc: "是否预约" },
          { from: "timeYMD", to: "timeYMD", type: "text|number", desc: "日期" },
          { from: "timeHMS", to: "timeHMS", type: "text|number", desc: "直播时间" },
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "teacher", to: "teacher", type: "text", desc: "授课教师" },
          { from: "teacherJobName", to: "teacherJobName", type: "text", desc: "职位" },
          { from: "id", to: "id", type: "text", desc: "id" },
          { from: "indentNum", to: "indentNum", type: "text|number", desc: "销量" },
          { from: "price", to: "price", type: "text|number", desc: "价格" },
          { from: "activityOldPrice", to: "old_price", type: "text|number", desc: "原价" },
          { from: "giftPayment", to: "giftPayment", type: "text|number", desc: "赠送积分" },
          { from: "giftCoupon", to: "giftCoupon", type: "text|number", desc: "代金券" },
          { from: "prefixText", to: "prefixText", type: "text", desc: "前缀文字", prefix: "秒杀价" },
        ];
      },
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  components: {},
  computed: {},
  methods: {
    getType(data) {
      if (data["stateValue"] == 1) {
        return "查看";
      } else if (data["stateValue"] == 2) {
        return "查看";
      } else if (data["stateValue"] == 0) {
        return "查看";
      }
    },
    goDetail() {
      if (this.$listeners["click"]) {
        this.$dispatch("click", this.data);
      } else {
        if (this.detailUrl) {
          dsf.layer.openWindow(this.detailUrl + this.data.id);
        }
      }
    },
    liveAppointHandle(data) {
      let params = {
        ywid: data.id,
        ywlxValue: "live",
        ywlxText: "直播",
      };
      let loading = dsf.layer.pc.loading();
      let appointUrl = "dsfa/subscribe/saveOrCancel";
      this.$http
        .post(appointUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            data.subscribe = res.data.isDo;
          }
          dsf.layer.pc.message(res.message, true);
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    formatterDate(time, format) {
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    getUrl(cover) {
      return cover ? dsf.url.getWebPath(JSON.parse(cover)[0].relativePath) : "";
    },
  },
});
</script>
