<template>
  <div class="text-login code-login">
    <div class="login-box-info margin-top">
      <dsf-icon name="icon_phone" />
      <input type="text" :placeholder="nameHolder" v-model="loginForm.phone" autocomplete="new-password" />
    </div>
    <!-- <div class="login-box-info">
      <dsf-icon name="suozi" />
      <input type="password" :placeholder="pwdHolder" v-model="loginForm.password" autocomplete="new-password" />
    </div> -->
    <div class="login-box-info login-code">
      <div class="login-code-wrap">
        <dsf-icon name="yanzhengma1" />
        <input type="text" :placeholder="codeHolder" v-model="loginForm.code" />
      </div>
      <el-button type="info" class="btn-send-msg" size="small" @click="throttleLoadMsgCode">{{ msgText }}</el-button>
    </div>
    <div class="login-box-handle">
      <!-- <div>
        <el-checkbox v-model="loginForm.checked" @change="setRemindState">记住密码</el-checkbox>
      </div> -->
      <!-- <div @click="toChangePwd">忘记密码</div>-->
      <!-- <div @click="toRegister" v-if="dsf.config.setting_nc_reg_is_register == 1">
        <div class="hint">
          没有账号？
          <span class="pointer">去注册</span>
        </div>
      </div> -->
    </div>
    <div class="login-box-button">
      <el-button :loading="loading" type="info" native-type="submit" size="small" @click="submitCodeLogin">登 录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        phone: "",
        code: "",
      },
      msgSourceUrl: "/nc/org/user/sendNcVerifCode",
      validatorSourceUrl: "/nc/org/user/verifyNcCode",
      msgText: "获取验证码",
      count: 60,
      allowSend: true,
      throttleLoadMsgCode: null,
    };
  },
  props: {
    title: {
      type: String,
      default: "登录",
    },
    moreUrl: {
      type: String,
      default: "/",
    },
    boxWidth: {
      type: String,
      default: "300px",
    },
    sourceUrl: {
      type: String,
      default: "/nc/login/login",
    },
    nameHolder: {
      type: String,
      default: "请输入手机号",
    },
    pwdHolder: {
      type: String,
      default: "请输入密码",
    },
    codeHolder: {
      type: String,
      default: "验证码",
    },
    toUrl: {
      type: String,
      default: "",
    },
    loginSuccess: {
      type: Function,
      required: false,
    },
    loginFail: {
      type: Function,
      required: false,
    },
  },
  components: {},

  computed: {},

  mounted() {
    this.throttleLoadMsgCode = _.throttle(this.getMsgCode, 2000);
  },

  methods: {
    getMsgCode() {
      const phone = this.loginForm.phone;
      if (!this.allowSend) return;
      if (!phone) return dsf.layer.message("请输入手机号", false);
      if (!dsf.validate.map.isMobile.validator(phone)) return dsf.layer.message("请输入正确的手机号", false);
      const loading = dsf.layer.pc.loading();
      let params = {
        phone,
        codetype: "login",
      };
      this.allowSend = false;
      dsf.http
        .get(this.msgSourceUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            dsf.layer.pc.message(res.message, true);
            let timer = setInterval(() => {
              --this.count;
              if (this.count === 0) {
                this.allowSend = true;
                this.count = 60;
                this.msgText = `重新获取`;
                clearInterval(timer);
              } else {
                this.msgText = `${this.count}秒后重新获取`;
              }
            }, 1000);
          } else {
            this.allowSend = true;
            dsf.layer.pc.message(res.message, false);
          }
        })
        .error(err => {
          this.allowSend = true;
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    loginHandle() {
      this.submitCodeLogin();
    },
    submitCodeLogin() {
      const { phone, code } = this.loginForm;
      if (!phone) return dsf.layer.message("请输入手机号", false);
      if (!dsf.validate.map.isMobile.validator(phone)) return dsf.layer.message("请输入正确的手机号", false);
      if (!code) return dsf.layer.message("请填写验证码", false);
      let params = {
        phone,
        code,
        codetype: "login",
      };
      this.loading = true;
      dsf.http
        .get(this.validatorSourceUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            // dsf.layer.pc.message(res.message, true);
            // dsf.cookies.set("isLogin", 1);
            // this.loginSuccess?.();
            // let timer = setTimeout(() => {
            //   window.location.reload();
            //   clearTimeout(timer);
            // }, 1000);
            this.codeLogin();
          } else {
            dsf.layer.pc.message(res.message, false);
          }
        })
        .error(err => {
          this.loginFail?.();
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {
          this.loading = false;
        });
      return false;
    },
    codeLogin() {
      const { phone, code } = this.loginForm;
      let params = {
        phone,
        code,
        typeValue: 5,
        clientValue: 0,
      };
      dsf.http
        .post("/nc/login/login", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            dsf.layer.pc.message(res.message, true);
            dsf.cookies.set("isLogin", 1);
            this.loginSuccess?.();
            let timer = setTimeout(() => {
              window.location.reload();
              clearTimeout(timer);
            }, 1000);
          } else {
            dsf.layer.pc.message(res.message, false);
          }
        })
        .error(err => {
          this.loginFail?.();
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {});
    },
    toChangePwd() {
      this.$emit("changePassword");
    },
    toRegister() {
      this.$emit("toRegister");
    },
  },
};
</script>
