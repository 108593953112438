var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_login" }, [
    _vm.isview
      ? _c(
          "div",
          { staticClass: "close-btn" },
          [
            _c("dsf-icon", {
              attrs: { name: "guanbi2" },
              on: {
                click: function ($event) {
                  return _vm.$root.close()
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "dsf_login_inner", style: { width: _vm.boxWidth } },
      [
        _c(
          "el-tabs",
          {
            attrs: { "tab-position": _vm.tabPosition },
            model: {
              value: _vm.tabIndex,
              callback: function ($$v) {
                _vm.tabIndex = $$v
              },
              expression: "tabIndex",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "账号登录", name: "textLogin" } },
              [
                _c("textLogin", {
                  ref: "textLogin",
                  attrs: {
                    sourceUrl: _vm.sourceUrl,
                    nameHolder: _vm.nameHolder,
                    pwdHolder: _vm.pwdHolder,
                    codeHolder: _vm.codeHolder,
                    toUrl: _vm.toUrl,
                    "login-success": _vm._loginSuccess,
                    "login-fail": _vm._loginFail,
                    isreloadCurrentPage: _vm.isreloadCurrentPage,
                  },
                  on: {
                    changePassword: _vm.changePassword,
                    toRegister: _vm.toRegister,
                  },
                }),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "验证码登录", name: "codeLogin" } },
              [
                _c("codeLogin", {
                  ref: "codeLogin",
                  attrs: {
                    "login-success": _vm._loginSuccess,
                    "login-fail": _vm._loginFail,
                    isreloadCurrentPage: _vm.isreloadCurrentPage,
                  },
                  on: {
                    changePassword: _vm.changePassword,
                    toRegister: _vm.toRegister,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }