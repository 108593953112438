<template>
  <div class="dsf_onStudy_title">
    <div class="title-inner">
      <span class="title">{{ title }}</span>
      <ul class="menus">
        <li v-for="item in menus" :key="item.name" @click="tabItemChange(item)">
          <span>
            {{ item.name }}
            <i class="dot" v-if="item.total > 0">{{ item.total || 0 }}</i>
          </span>
          <!-- <el-badge :value="100" :max="10" class="item">
            <el-button size="small">{{item.name}}</el-button>
          </el-badge> -->
        </li>
      </ul>
      <div class="right right-more" v-if="showMore">
        <slot name="more">...</slot>
      </div>
      <div class="right right-btn" v-if="showBtn">
        <slot name="right">任务</slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dsf_onStudy_title",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    menus: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    goto() {
      this.likedFun(1);
    },
    tabItemChange(item) {
      this.$emit("tabItemChange");
    },
  },
};
</script>
