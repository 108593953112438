<template>
  <div class="dsf_ncpc_students_bbs" :style="{ width: itemWidth, display: visible ? 'block' : 'none', margin: itemSpace.replace(/,/g, ' ') }">
    <div class="intro_title">
      <div class="desc">
        <span class="font">{{ title }}</span>
        <span class="more" v-if="showMore && moreStyle == 'tb'" @click="!!showMore && clickMore()"></span>
        <span class="more_font" v-else-if="showMore && moreStyle == 'wz'" @click="!!showMore && clickMore()">更多</span>
      </div>
      <span class="line" v-if="showLine"></span>
    </div>
    <ul class="students_bbs_wrap" v-if="commentLists.length">
      <li class="students_bbs_items" v-for="(item, index) in commentLists" :key="index">
        <div class="user_info">
          <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.dsCreateUserId" @error="errorHandler">
            <dsf-nc-image :src="''" :error-src="dsf.config.setting_nc_image_teacher_header_img || dsf.config.setting_public_teacher_default_header" />
          </el-avatar>
        </div>
        <div class="right">
          <div class="user_name ell">{{ item.dsCreateUserName || "" }}</div>
          <div class="plnr" v-html="item.plnr">
            <!-- <span v-if="item.plnr && item.plnr.length > 40" class="font" @click="clickFold(item, index)">{{ item.fold ? "展开" : "收起" }}</span> -->
          </div>
          <div class="time">{{ item.showTime || "" }}发布</div>
        </div>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcStudentsBbs",
  ctrlCaption: "学员论坛",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "学员论坛",
    },
    url: {
      type: String,
      default: "/dsfa/platform/comment/queryComment",
    },
    page: {
      type: Number,
      default: 3,
    },
    showLine: {
      type: Boolean,
      default: false,
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    moreStyle: {
      type: String,
      default: "wz",
    },
    itemWidth: {
      type: String,
      default: "400px",
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    itemSpace: {
      type: [String, Array],
      default: "0,20px,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bbsData: {
        comment: [
          {
            dsCreateUserName: "岩青苑",
            plnr: "品牌也远不是强调名称，标志，符号或者商标。它融合了一系列定义其形象的独特价值，并作为一份不成文的合同",
            showTime: "2020.10.01 14:00",
            dsCreateUserId: "4bc6fb2bf1e84d7a9993bcd69917bcd0",
          },
          {
            dsCreateUserName: "格莎",
            plnr: "品牌也远不是强调名称，标志，符号或者商标。",
            showTime: "2020.10.01 14:00",
            dsCreateUserId: "iiyD1XSoUxTCBGJNp8RMCcwiEiE",
          },
          {
            dsCreateUserName: "岩青苑",
            plnr: "品牌也远不是强调名称，标志，符号或者商标。它融合了一系列定义其形象的独特价值，并作为一份不成文的合同",
            showTime: "2020.10.01 14:00",
            dsCreateUserId: "AptXl7iiZ0VNuo0euRuyiSwwiEiE",
          },
          {
            dsCreateUserName: "岩青苑",
            plnr: "品牌也远不是强调名称，标志，符号或者商标。",
            showTime: "2020.10.01 14:00",
            dsCreateUserId: "AptXl7iiZ0VNuo0euRuyiSwwiEiE",
          },
        ],
        total: 7,
      },
      params: {
        pageNo: 0,
        pageSize: 4,
        childPageNo: 1,
        childPageSize: 10,
        keywords: "",
        businessType: "bbs_topic",
        businessId: "",
        orderWay: "down",
        orderType: "time",
        btnPara: JSON.stringify([
          {
            level: "-1",
            btn: [
              { name: "tags", ywlx: "bbs_topic" },
              { name: "collect", ywlx: "bbs_topic" },
              { name: "concern", ywlx: "bbs_topic" },
              { name: "subscribe", ywlx: "bbs_topic" },
            ],
          },
        ]),
      },
      commentLists: [],
    };
  },
  created() {
    this.params.businessId = this.queryString["id"] || "2436ae59e1ce4787a10b9cf140b18e7b";
  },
  mounted() {
    if (!this.isDesign) {
      this.commentLists = [];
      this.initData();
    }
    this.commentLists = this.bbsData.comment;
  },
  watch: {
    commentLists: {
      handler(data) {
        data.forEach(element => {
          this.$set(element, "fold", true);
        });
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(this.url, this.params, $$webRoot.bbs)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.bbsData = res.data;
            this.commentLists = this.bbsData.comment;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    errorHandler() {
      return true;
    },
    clickFold(item, index) {
      item.fold = !item.fold;
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    clickMore() {
      this.$page.$options.parent.$refs.navtab6.activeName = "students";
      this.$page.$options.parent.$refs.rowhalf3._data.cols[0].addClass("el-col-24");
      this.$page.$options.parent.$refs.rowhalf3._data.cols[1].addClass("ds-hide");
    },
  },
});
</script>
