var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds_flexible_box_three", style: _vm.boxStyle },
    [
      _c(
        "div",
        {
          class: ["insert", "insert-left", { "insert-wrap": _vm.isDesign }],
          style: {
            width: _vm.leftBarStyle,
            margin: _vm.itemsStyle,
            border: _vm.border,
          },
        },
        [
          _c(
            "div",
            {
              class: ["insert-content", { "insert-border": _vm.isDesign }],
              attrs: { "slot-name": "flex-box-three-left" },
            },
            [_vm._t("flex-box-three-left")],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          class: ["insert", "insert-center", { "insert-wrap": _vm.isDesign }],
          style: {
            width: _vm.centerBarStyle,
            margin: _vm.itemsStyle,
            border: _vm.border,
          },
        },
        [
          _c(
            "div",
            {
              class: ["insert-content", { "insert-border": _vm.isDesign }],
              attrs: { "slot-name": "flex-box-three-center" },
            },
            [_vm._t("flex-box-three-center")],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          class: ["insert", "insert-right", { "insert-wrap": _vm.isDesign }],
          style: {
            width: _vm.rightBarStyle,
            margin: _vm.itemsStyle,
            border: _vm.border,
          },
        },
        [
          _c(
            "div",
            {
              class: ["insert-content", { "insert-border": _vm.isDesign }],
              attrs: { "slot-name": "flex-box-three-right" },
            },
            [_vm._t("flex-box-three-right")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }