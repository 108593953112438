<template>
  <div class="dsf_course_column" v-if="isDesign">
    <div class="item_inner">
      <!-- @click="$emit('toDetail', item.id)" -->
      <div class="item-left-img">
        <dsf-image :src="'' | imgFormat" />
        <span class="item-type">课程</span>
        <span class="item-time">
          <dsf-icon name="shijian" />
          00:20:21
        </span>
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">我是一个标题</span>
          <span class="task">任务</span>
        </div>
        <p class="item-teacher-name">
          <span>张三</span>
          <span>项目经理</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <dsf-icon name="shijian" />
            5学时
          </li>
          <li>
            <dsf-icon name="dianzan_kuai" />
            10
          </li>
          <li>
            <dsf-icon name="pinglun" />
            15
          </li>
        </ul>
      </div>
      <div class="circle-wrap">
        <el-progress width="60" stroke-width="5" :color="curColor" type="circle" :percentage="33" class="progress-circle"></el-progress>
        <p>当前进度</p>
      </div>
    </div>
  </div>
  <div class="dsf_course_column" v-else>
    <div class="item_inner">
      <!-- @click="$emit('toDetail', item.id)" -->
      <div class="item-left-img" @click="_clickBefore(click)">
        <dsf-image :src="data.cover | imgFormat" />
        <span class="item-type">{{ data.typeText }}</span>
        <span class="item-time">
          <dsf-icon name="shijian" />
          {{ data.cDuration }}
        </span>
      </div>
      <div class="item-right-desc">
        <div class="title" @click="_clickBefore(click)">
          <span class="name ellipsis-2">{{ data.title }}</span>
          <span class="task" v-if="data.isTask">任务</span>
        </div>
        <p class="item-teacher-name">
          <span>{{ data.cTeacher }}</span>
          -
          <span>{{ data.cJobUnit }}</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <dsf-icon name="shijian" />
            {{ data.cPeriod || 0 }}学时
          </li>
          <!-- <li>
            <dsf-icon name="dianzan_kuai" />
            {{ data.cTagNum || 0 }}
          </li>
          <li>
            <dsf-icon name="pinglun" />
            {{ data.cCommentNum }}
          </li> -->
        </ul>
      </div>
      <div class="circle-wrap">
        <el-progress
          width="60"
          stroke-width="5"
          :color="curColor"
          type="circle"
          :percentage="data.finishedRateOrScore"
          class="progress-circle"
          :format="formatPercent"
        ></el-progress>
        <p>当前进度</p>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcCardCourseColumn",
  ctrlCaption: "学习任务-课程卡片",
  mixins: [$mixins.card],
  props: {
    title: {
      type: String,
      default: "标题",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "typeText", to: "typeText", type: "boolean", desc: "类型" },
          { from: "cDuration", to: "cDuration", type: "text", desc: "课程时长" },
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "isTask", to: "isTask", type: "text|number", desc: "任务" },
          { from: "cTeacher", to: "cTeacher", type: "text|number", desc: "教师" },
          { from: "cJobUnit", to: "cJobUnit", type: "text|number", desc: "职位" },
          { from: "cPeriod", to: "cPeriod", type: "text|number", desc: "学时" },
          { from: "cTagNum", to: "cTagNum", type: "text|number", desc: "收藏" },
          { from: "cCommentNum", to: "cCommentNum", type: "text|number", desc: "评论数" },
          { from: "finishedRateOrScore", to: "finishedRateOrScore", type: "text|number", desc: "完成度" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
    },
    toPage(item) {
      this.$emit("toDetail", item._id);
    },
    formatPercent(it) {
      return it || "0";
    },
  },
});
</script>
