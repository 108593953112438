import { render, staticRenderFns } from "./DataGridChildTest.vue?vue&type=template&id=1ca4d9cb&"
import script from "./DataGridChildTest.vue?vue&type=script&lang=js&"
export * from "./DataGridChildTest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/list/DataGridChildTest.vue"
export default component.exports