var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_onStudy_title" }, [
    _c("div", { staticClass: "title-inner" }, [
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "ul",
        { staticClass: "menus" },
        _vm._l(_vm.menus, function (item) {
          return _c(
            "li",
            {
              key: item.name,
              on: {
                click: function ($event) {
                  return _vm.tabItemChange(item)
                },
              },
            },
            [
              _c("span", [
                _vm._v(" " + _vm._s(item.name) + " "),
                item.total > 0
                  ? _c("i", { staticClass: "dot" }, [
                      _vm._v(_vm._s(item.total || 0)),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
      _vm.showMore
        ? _c(
            "div",
            { staticClass: "right right-more" },
            [
              _vm._t("more", function () {
                return [_vm._v("...")]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.showBtn
        ? _c(
            "div",
            { staticClass: "right right-btn" },
            [
              _vm._t("right", function () {
                return [_vm._v("任务")]
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }