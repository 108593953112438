var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specialdetail_complete",
      style: { display: _vm.visible ? "block" : "none" },
    },
    [
      _c(
        "div",
        { staticClass: "dsf_nc_special_detail_complete_schedule" },
        [
          _vm._m(0),
          _vm._l(_vm.unitAssess, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "complete_schedule_desc" },
              [
                _c("div", { staticClass: "schedule_title" }, [
                  _vm._v(_vm._s(item.unitName || "")),
                ]),
                _c(
                  "div",
                  { staticClass: "progress bx_progress" },
                  [
                    _c("div", { staticClass: "bx_font font" }, [
                      _vm._v("必修"),
                    ]),
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        percentage: item.compulsoryper || 0,
                        "stroke-width": "20",
                      },
                    }),
                    _c("span", { staticClass: "pivot_txt" }, [
                      _vm._v(
                        _vm._s(item.compulsoryHursed || 0) +
                          "/" +
                          _vm._s(item.compulsoryPeriod || 0) +
                          " 学时"
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "progress xx_progress" },
                  [
                    _c("div", { staticClass: "xx_font font" }, [
                      _vm._v("选修"),
                    ]),
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        percentage: item.electiveper || 0,
                        "stroke-width": "20",
                      },
                    }),
                    _c("span", { staticClass: "pivot_txt" }, [
                      _vm._v(
                        _vm._s(item.electiveHursed || 0) +
                          "/" +
                          _vm._s(item.electivesPeriod || 0) +
                          " 学时"
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm.homeworkId || _vm.examId
        ? _c("div", { staticClass: "dsf_nc_special_detail_complete_examine" }, [
            _vm._m(1),
            _c("div", { staticClass: "complete_examine_desc" }, [
              _vm.homeworkId
                ? _c(
                    "div",
                    {
                      class: [
                        "brief",
                        "btn_box",
                        { khwc: _vm.homeworkState == 2 },
                        { gray: !_vm.examStateValue || _vm.isview },
                      ],
                      on: {
                        click: function ($event) {
                          !_vm.isview && _vm.examStateValue && _vm.clickBrief()
                        },
                      },
                    },
                    [
                      _c("dsf-icon", { attrs: { name: "xiaojie" } }),
                      _c("div", { staticClass: "btn" }, [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v(_vm._s(_vm.homework.fontBtn)),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "brief_score score",
                          style: _vm.homework.color,
                        },
                        [_vm._v(_vm._s(_vm.homework.font))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isShowExam && _vm.examId
                ? _c(
                    "div",
                    {
                      class: [
                        "exam",
                        "btn_box",
                        { khwc: _vm.examPass == 1 },
                        { gray: !_vm.examStateValue || _vm.isview },
                      ],
                      on: {
                        click: function ($event) {
                          !_vm.isview &&
                            _vm.examStateValue &&
                            _vm.clickExam(_vm.scheduleData)
                        },
                      },
                    },
                    [
                      _c("dsf-icon", { attrs: { name: "kaoshi" } }),
                      _c("div", { staticClass: "btn" }, [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v(_vm._s(_vm.exam.fontBtn)),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "exam_score score",
                          style: _vm.exam.color,
                        },
                        [_vm._v(_vm._s(_vm.exam.font))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.showCertificate && _vm.certificateState
        ? _c(
            "div",
            { staticClass: "dsf_nc_special_detail_complete_certificate" },
            [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "complete_certificate" },
                [
                  _c("DsfNcCredential", {
                    attrs: {
                      "certificate-src": _vm.certificateSrc,
                      isDownLoad: _vm.isDownLoad,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "complete_title" }, [
      _c("span", { staticClass: "font" }, [_vm._v("进度")]),
      _c("span", { staticClass: "font des" }, [
        _vm._v("进度=已获学时/考核学时*100%"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "complete_title" }, [
      _c("span", { staticClass: "font" }, [_vm._v("考核")]),
      _c("span", { staticClass: "font des" }, [_vm._v("学习进度需要达到100%")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "complete_title" }, [
      _c("span", { staticClass: "font" }, [_vm._v("证书")]),
      _c("span", { staticClass: "font des" }, [
        _vm._v("通过专题班的学习及考核便有资格获得结业证书。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }