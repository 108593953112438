<template>
  <div class="dsf_nc_system_announcement" v-if="show">
    <div class="dsf_system_box">
      <div class="dsf_system_header">
        <!-- <el-image :src="imgUrl"></el-image> -->
        <dsf-image :src="imgUrl" fit="cover"></dsf-image>
        <dsf-button class="dsf_close" icon="guanbi2" size="small" btn-style="icon-text" @click.stop="handlerClose" />
      </div>
      <div class="dsf_system_content">
        <div class="system_title">{{ title }}</div>
        <div class="system_content" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcSystemAnnouncement",
  ctrlCaption: "系统公告",
  mixins: [$mixins.control],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgUrl: require("../../../assets/imgs/pcimg/system/img_xtgg.png"),
      info: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    handlerClose() {
      this.$emit("update:show", false);
      sessionStorage.setItem("systemStatus", 2);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
