<template>
  <div class="dsf_nc_briefsummary" :class="getCss">
    <el-dialog
      :visible.sync="show"
      :width="'50%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :append-to-body="true"
      @close="onClose"
      :custom-class="'nc_pc_briefsummary_dialog'"
    >
      <span slot="title">{{ titleName }}</span>
      <el-form ref="form" :model="formModel" label-width="80px" :rules="rules" :disabled="readyonly">
        <el-form-item class="dsf_form_item">
          <div class="conditions_content">
            <el-form-item label="标题" class="dsf_item_form" prop="title">
              <el-input class="dsf_input" v-model="formModel.title" placeholder="请输入" />
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item class="dsf_form_item">
          <div class="conditions_content">
            <el-form-item label="内容" class="dsf_item_form" prop="content">
              <el-input type="textarea" class="dsf_input" :rows="10" :resize="'none'" v-model="formModel.content" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item class="dsf_btns">
          <DsfButtonBar :buttons="buttons" :max-number="maxNumber" @save="onSubmit('form')" @close="onClose" />
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcBriefSummary",
  ctrlCaption: "小结",
  mixins: [$mixins.control, $mixins.buttonBar],
  props: {
    buttons: {
      type: Array,
      default() {
        return [
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-guanbi2",
            id: "BeIBdsm19uqcoaVc",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "关闭",
            title: "",
            type: "plain",
          },
          {
            actionName: "save",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-baocun",
            id: "Ld3EJ3ytFxJpgA3Q",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "保存",
            title: "",
          },
        ];
      },
    },
    maxNumber: {
      type: Number,
      default: 3,
    },
    titleName: {
      type: String,
      default: "撰写小结",
    },
    homeworkId: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "/nc/homework/assessment/writing",
    },
    homeworkState: {
      type: String || Number,
      default: "",
    },
    readyonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formModel: {
        homeworkId: "",
        title: "",
        content: "",
      },
      rules: {
        title: [{ required: this.required, trigger: "blur", validator: this.validator }],
        content: [{ required: this.required, message: "请填写内容", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (!this.isDesign) {
      this.formModel.homeworkId = this.homeworkId;
      if (this.homeworkState == -1) {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(
          "nc/homework/student/details",
          {
            homeworkId: this.formModel.homeworkId || "67bd9bb769f744df975444ab381973f3",
          },
          $$webRoot.nc
        )
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.formModel = {
              title: data.title, //作业
              content: data.content,
              homeworkId: data.nc_homework_id,
            };
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    validator(rule, value, callback) {
      if (value.length > 50) {
        callback(new Error("标题长度不能超过50字"));
      } else if (value == null || value == "") {
        callback(new Error("请填写标题"));
      } else {
        callback();
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          let loading = dsf.layer.pc.loading();
          let headers = {
            "Content-Type": "application/json",
          };
          dsf.http
            .post(
              this.url,
              JSON.stringify(this.formModel),
              {
                headers,
              },
              $$webRoot.nc
            )
            .done(res => {
              let { success } = res;
              if (!success) {
                this.error(res);
              } else {
                dsf.layer.pc.message(`${this.titleName}成功`, true);
                this.$emit("update:show", false);
              }
            })
            .error(err => {
              this.error(err);
            })
            .always(res => {
              dsf.layer.pc.closeLoading(loading);
            });
        } else {
          return false;
        }
      });
      // if (this.required && (!this.formModel.title || !this.formModel.content)) return dsf.layer.pc.message("请完成作业填写", false);

      // console.log("8787===>onSubmit", this.formModel);
    },
    onClose() {
      this.$emit("update:show", false);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
