<!--
 * @Author: RONGWEI PENG
 * @Date: 2021-11-03 14:34:42
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-11-05 19:03:26
 * @FilePath: /dsf-product-nc/packages/@dsf/cli-nc/pc/components/live/livesignin.vue
-->
<template>
  <div class="ds-live-signin">
    <div class="live-signin-title next title">
      <span :style="title_style">
        {{ title }}
      </span>
    </div>
    <div class="live-signin-box" id="myCanvasContainer">
      <canvas width="300" height="300" id="myCanvas" ref="canvas">
        <p>换个现代浏览器吧！</p>
      </canvas>

      <div id="tags">
        <ul>
          <li class="item_bg" v-for="(item, index) in members" :key="index">
            <a href="javascript:void(0);" @click="checkItem(item)">
              <!-- <span class="img_name">
                {{ item.name }}
              </span> -->
              <img class="item_img" :src="setImg(item.photo)" />
              <!-- <img class="item_img" :src="setImg(item.photo)" :onerror="defaultSrc" /> -->
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import canvas from "jquery-tagcanvas";
export default dsf.component({
  name: "DsfNcLiveSignin",
  ctrlCaption: "现场签到",
  mixins: [$mixins.control],
  props: {
    title: { type: String, default: "现场签到" },
    title_style: { type: String, default: "color:red" },
    activity_id: { type: String, default: "c1d2842b372946739582040dc441ce69" },
    data_url: { type: String, default: "/nc/idj/user/wall/list" },
    time: { type: Number, default: 5 },
    bg_img: { type: String, default: "../../../assets/imgs/mobile/bg.png" },
  },
  data() {
    return {
      members: [],
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  watch: {},

  mounted() {
    const that = this;
    console.log("this.isDesign==", that.isDesign);
    if (!that.isDesign) {
      that.getLists(that.activity_id);
      setInterval(function () {
        that.getLists(that.activity_id);
      }, that.time * 1000);
    } else {
      for (var i = 1; i < 101; i++) {
        that.members.push({
          id: i,
          name: i + "号",
          photo: "http://ww1.sinaimg.cn/large/005Nnjxwly1gw4fj182fxj30m80go3zh.jpg",
        });
      }
    }
  },

  methods: {
    init() {
      try {
        TagCanvas.Start("myCanvas", "tags", {
          textColour: null,
          dragControl: 1,
          decel: 0.95,
          textHeight: 14,
          minSpeed: 0.01,
          initial: [0.1 * Math.random() + 0.01, -(0.1 * Math.random() + 0.01)],
          zoomMax: 3,
          zoomMin: 0.3,
          depth: 0.9,
          txtOpt: false,
        });
      } catch (e) {
        document.getElementById("myCanvasContainer").style.display = "none";
      }
    },
    checkItem(item) {
      console.log("checkItem", item);
      return false;
    },
    onKeyup(e) {
      console.log(e);
      // 空格或回车键
      if (e.keyCode === 13 || e.keyCode === 32) {
        this.$refs.action.$el.click();
      }
    },
    setImg(cover) {
      // let src = this.defaultSrc;
      let src = "http://ww1.sinaimg.cn/large/005Nnjxwly1gw4fj182fxj30m80go3zh.jpg";
      // let src = "../../../assets/imgs/404.png";
      if (cover) {
        src = JSON.parse(cover)[0].absolutePath;
      }
      return src;
    },
    getLists(id) {
      let that = this;
      this.dsf.http
        .get(that.data_url, { id })
        .done(res => {
          if (res.success) {
            console.log("getLists", res);
            that.members = res.data;
            var canvas = that.$refs.canvas;
            canvas.width = document.body.offsetWidth;
            canvas.height = document.body.offsetHeight;
            that.$nextTick(function () {
              that.init();
            });
            // document.body.addEventListener("keyup", this.onKeyup);
          } else {
            this.dsf.layer.message(res.message, false);
          }
        })
        .error(err => {
          this.dsf.layer.message(err.message, false);
        })
        .always(function () {});
    },
  },
});
</script>
