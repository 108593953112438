import { render, staticRenderFns } from "./teacherCard.vue?vue&type=template&id=d57ada2c&"
import script from "./teacherCard.vue?vue&type=script&lang=js&"
export * from "./teacherCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/components/teacher/teacherCard.vue"
export default component.exports