<template>
  <div class="dsf_live_title" :style="{ width: itemWidth }">
    <dsf-nc-title :moreUrl="moreUrl" :padding="itemPadding" :title="title" />
    <live-item />
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcLive",
  ctrlCaption: "门户直播",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "精彩直播",
    },
    moreUrl: {
      type: String,
      default: "/",
    },
    itemWidth: {
      type: String,
      default: "1200px",
    },
    itemPadding: {
      type: String,
      default: "50px 0 60px 0",
    },
  },
  components: {},
});
</script>
