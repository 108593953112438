import { render, staticRenderFns } from "./courseShopCard.vue?vue&type=template&id=d63f95cc&"
import script from "./courseShopCard.vue?vue&type=script&lang=js&"
export * from "./courseShopCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/components/course/courseShopCard.vue"
export default component.exports