<template>
  <div v-if="isDesign" class="ds_channel_detail_top">
    <div class="ds_channel_detail_wrap">
      <div class="channel-detail-inner">
        <div class="cover">
          <el-image src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"></el-image>
        </div>
        <div class="desc">
          <h3 class="desc-name">
            <span class="name">2</span>
          </h3>
          <div class="info">
            <span>发布: 2020-10-21</span>
          </div>
          <div class="info">
            <span>资源数：共 30门</span>
          </div>
          <div class="info info-bottom">
            <span>浏览 12221次</span>
          </div>
        </div>
        <div class="channel-button">
          <!-- <dsf-icon name="dianzanhong" /> -->
          <dsf-icon name="iconfontzhizuobiaozhun13" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ds_channel_detail_top">
    <div class="ds_channel_detail_wrap" v-if="it">
      <div class="channel-detail-inner">
        <div class="cover">
          <dsf-image :src="it.cover | imgFormat" />
        </div>
        <div class="desc">
          <h3 class="desc-name">
            <span class="name">{{ it.title }}</span>
          </h3>
          <div class="info">
            <span>发布: {{ it.time }}</span>
          </div>
          <div class="info">
            <span>资源数: {{ it.courseCount || 0 }}门</span>
          </div>
          <div class="info info-bottom">
            <span>浏览: {{ it.hits || 0 }}次&nbsp;&nbsp;|&nbsp;&nbsp;订阅数: {{ it.subscription || 0 }}</span>
          </div>
          <div class="info info-bottom" v-if="it.labelText">
            <span v-for="item in it.labelText.split('^')" :key="item" class="tags">{{ item }}</span>
          </div>
        </div>
        <div class="channel-button">
          <button class="subcrible" @click="toSubscrible(it, 'mySubscribed')">{{ showSubText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcChannelCardDetailTop",
  ctrlCaption: "学习专区卡片详情顶部",
  mixins: [$mixins.control],
  props: {
    sourceUrl: {
      type: String,
      default: "nc/pack/channel/info",
    },
    coursePath: {
      type: String,
      default: "/pc/nc/pagecourse/coursePlayer?id=",
    },
  },
  computed: {
    showSubText() {
      let text = "取消订阅";
      if (this.it) {
        text = this.it.mySubscribed ? "取消订阅" : "立即订阅";
      }
      return text;
    },

    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  data() {
    return {
      it: null,
      queryiId: this.$route.query.id,
      activeName: "first",
      state: false,
      list: [],
    };
  },
  mounted() {
    if (!this.isDesign) {
      this.reData();
    }
  },
  methods: {
    reData() {
      this.loadChannelCardDetail();
    },
    loadChannelCardDetail() {
      const { sourceUrl } = this;
      const params = {
        id: this.queryiId,
      };
      const loading = dsf.layer.pc.loading();
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.it = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(err, false);
      }
    },
    toSubscrible(it, type) {
      this.$checkLogin().then(res => {
        if (res) {
          const sourceUrl = type === "mySubscribed" ? "/dsfa/subscribe/saveOrCancel" : "/dsfa/tags/saveOrCancel";
          const params = {
            ywlxValue: "channel",
            ywlxText: "频道",
            ywid: it.channelId,
          };
          const loading = dsf.layer.pc.loading();
          dsf.http
            .post(sourceUrl, params, $$webRoot.nc)
            .done(({ success, data, message }) => {
              if (success) {
                this.it[type] = data.isDo;
                dsf.layer.pc.message(message, true);
                this.reData();
              } else {
                error();
              }
            })
            .error(err => {
              error();
            })
            .always(res => {
              dsf.layer.pc.closeLoading(loading);
            });
        }
      });
      function error() {
        dsf.layer.pc.message("操作失败", false);
      }
    },
    getBgcColor(it) {
      if (!it) it = "course";
      let config = {
        course: "#69C33B",
        live: "#FF9F50",
        exam: "#538CEE",
      };
      return config[it];
    },
  },
});
</script>
