var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_specialdetail_social_rank",
      style: {
        width: _vm.itemWidth,
        display: _vm.visible ? "block" : "none",
        margin: _vm.itemSpace.replace(/,/g, " "),
      },
    },
    [
      _c("div", { staticClass: "social_title" }, [
        _c("div", { staticClass: "desc" }, [
          _c("span", { staticClass: "font" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.showMore && _vm.moreStyle == "tb"
            ? _c("span", {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    !!_vm.showMore && _vm.clickMore()
                  },
                },
              })
            : _vm.showMore && _vm.moreStyle == "wz"
            ? _c(
                "span",
                {
                  staticClass: "more_font",
                  on: {
                    click: function ($event) {
                      !!_vm.showMore && _vm.clickMore()
                    },
                  },
                },
                [_vm._v("更多")]
              )
            : _vm._e(),
        ]),
        _vm.showLine ? _c("span", { staticClass: "line" }) : _vm._e(),
      ]),
      _c(
        "ul",
        { staticClass: "rank_wrap" },
        _vm._l(_vm.lists, function (item, index) {
          return _c("li", { key: index, staticClass: "rank_items" }, [
            _c("div", { staticClass: "rank_left" }, [
              _c("div", {
                class: [
                  "rank_count",
                  index == 0
                    ? "gold"
                    : index == 1
                    ? "silver"
                    : index == 2
                    ? "bronze"
                    : "",
                ],
                domProps: { innerHTML: _vm._s(index > 2 ? index + 1 : "") },
              }),
              _c(
                "div",
                { staticClass: "heard" },
                [
                  _c("dsf-nc-image", {
                    attrs: {
                      src: _vm._f("imgFormat")(item.photo),
                      "error-src":
                        _vm.dsf.config.setting_public_user_default_card_header,
                    },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "name ell" }, [
                _vm._v(_vm._s(item.name || "无名")),
              ]),
            ]),
            _c("div", { staticClass: "time" }, [
              _vm._v(_vm._s(item.time || 0) + "分钟"),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }