var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfSection",
    {
      attrs: {
        mainWidth: "100%",
        margin: [0, 0, 0, 0],
        padding: [0, 0, 0, 0],
        backgroundConfig: null,
      },
    },
    [
      _c("DsfNcProductBanner", {
        attrs: {
          ratio: "19/6",
          url: _vm.url,
          showIndicators:
            _vm.data.bindAttr.model == "vertical" ? "none" : "inside",
          vertical: _vm.data.bindAttr.model == "vertical",
          indicatorWidth: 16,
          indicatorHeight: 16,
          imageKeyName: "imgUrl",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }