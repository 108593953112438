import UserInvitation from "../components/login/userInvitation.vue";
console.log("NC");
let router = [
  {
    path: "/pc/invitation",
    meta: {
      title: "用户邀请",
      needLogin: false,
    },
    component: UserInvitation,
  },
];

export default router;
