<template>
  <div class="dsf_nc_bcertificate" :class="getCss">
    <el-dialog
      width="800px"
      :close-on-press-escape="false"
      :show-close="false"
      :append-to-body="true"
      @close="onClose"
      :custom-class="'nc_pc_certificate_dialog'"
      :visible.sync="show"
    >
      <span slot="title">结业证书</span>
      <div class="img-wrap">
        <img :src="imgUrl" alt="" />
      </div>
      <DsfButtonBar :buttons="buttons" :max-number="maxNumber" @close="onClose" @save="downloadImg" />
    </el-dialog>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCertificate",
  ctrlCaption: "小结",
  mixins: [$mixins.control, $mixins.buttonBar],
  props: {
    buttons: {
      type: Array,
      default() {
        return [
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "",
            id: "BeIBdsm19uqcoaVc",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "关闭",
            title: "",
            type: "plain",
          },
          {
            actionName: "save",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "",
            id: "Ld3EJ3ytFxJpgA3Q",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "下载",
            title: "",
          },
        ];
      },
    },
    imgUrl: {
      type: String,
      default: "",
    },
    maxNumber: {
      type: Number,
      default: 3,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(
          "nc/homework/student/details",
          {
            homeworkId: this.formModel.homeworkId || "67bd9bb769f744df975444ab381973f3",
          },
          $$webRoot.nc
        )
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.formModel = {
              title: data.title, //作业
              content: data.content,
              homeworkId: data.nc_homework_id,
            };
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    onClose() {
      this.$emit("update:show", false);
    },
    downloadImg() {
      let a = document.createElement("a");
      let filename = "证书.png";
      a.href = this.imgUrl;
      a.download = filename;
      a.click();
    },
  },
});
</script>
