<template>
  <DsfSection
    mainWidth="1200px"
    :margin="[0, 0, 0, 0]"
    :padding="[10, 10, 10, 10]"
    :backgroundConfig="{
      color: '#fff',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
    :foregroundConfig="{
      color: 'rgba(255, 255, 255, 0)',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
  >
    <!--nctitle-->
    <DsfNcTitle
      v-if="showTitle"
      :showMore="showMore"
      :title="title"
      caption="DsfNcTitle"
      :showBottomLine="true"
      titleSize="big"
      :titleBold="false"
      :clickConfig="clickConfigTitle"
      padding="50px 0 60px 0"
    ></DsfNcTitle>
    <!--platformcardbox-->
    <DsfPlatformCardBox
      :columnNumber="3"
      columnWidth="380px"
      marginTop="15px"
      rowKey="_id"
      url="/inc/meta/list/data?pageNum=1&pageSize=6&query=%7B%22searchValue%22%3A%22%22%2C%22initials%22%3A%22%22%7D&order=%5B%5D&filter=%5B%5D&namespace=nc.pageteacher&pageName=teacherList"
      :checkbox="false"
    >
      <template v-slot:default="scope">
        <!--ncteachercard-->
        <DsfNcTeacherCard :local="scope.row" :clickConfig="clickConfig" :keysMap="keysMap"></DsfNcTeacherCard>
      </template>
    </DsfPlatformCardBox>
  </DsfSection>
</template>

<script>
export default dsf.component({
  name: "DsfNcSaasHomeMingshifengcai",
  ctrlCaption: "名师风采",
  mixins: [$mixins.control],
  props: {
    moreText: {
      type: String,
      default: "查看更多",
    },
    sourceUrl: {
      type: String,
      default: "/inc/meta/list/data",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      clickConfig: {
        url: "#/pc/nc/pageteacher/teachersDetail?id=@[_id]",
        state: 2,
      },
      clickConfigTitle: {
        height: "60vh",
        state: 2,
        title: "对话框标题",
        url: "/ncIndex.html#/pc/nc/pageteacher/teacherList",
        width: "1000px",
      },
      keysMap: [
        {
          from: "nc_teacher_data_mainTeacherData.photo",
          to: "avatar",
          type: "text",
          desc: "头像",
        },
        {
          from: "nc_teacher_data_mainTeacherData.name",
          to: "name",
          type: "text",
          desc: "名称",
        },
        {
          from: "nc_teacher_data_mainTeacherData.dept_text",
          to: "unit",
          type: "text",
          desc: "任职单位",
        },
        {
          from: "nc_teacher_data_mainTeacherData.position",
          to: "position",
          type: "text",
          desc: "职位",
        },
        {
          from: "nc_teacher_data_mainTeacherData.course_count",
          to: "courseNum",
          type: "text|number",
          desc: "课程数量",
          suffix: "门精品课程",
        },
        {
          from: "nc_teacher_data_mainTeacherData.score",
          to: "score",
          type: "text",
          desc: "评分",
        },
        {
          from: "nc_teacher_data_mainTeacherData.subscribed_num",
          to: "subscribed_num",
          type: "text",
          desc: "订阅数",
        },
      ],
    };
  },
  computed: {
    title() {
      return this.data["bindAttr"]["title"];
    },
    showTitle() {
      return this.data["bindAttr"]["showTitle"] == 1 ? true : false;
    },
    showMore() {
      return this.data["bindAttr"]["showMore"] == 1 ? true : false;
    },
  },
  created() {},
  mounted() {},
  methods: {},
});
</script>
