<template>
  <div class="ds_mainNewsList" :style="controlStyle">
    <div class="content" v-if="news.length">
      <div
        class="news_row"
        :style="rowStyle"
        :class="{ active: index == active }"
        v-on:mouseover="changeActive(index)"
        v-for="(item, index) in news"
        :key="index"
        @click="_clickBefore(goDetail, item)"
      >
        <p class="news_title">{{ item[keyField.title] || "" }}</p>
        <div class="news_content" v-if="index == active">
          <!-- <div class="detail">{{ item[keyField.content] || "" }}</div> -->
          <div class="detail" v-html="item[keyField.content]"></div>
          <div class="bottom">
            <div class="number">
              <dsf-icon name="yanjing1"></dsf-icon>
              <span>{{ item[keyField.readingNumber] || 0 }}</span>
            </div>
            <div class="time">{{ item[keyField.time] ? dsf.date.format(item[keyField.time], "yyyy-mm-dd") : "" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="noDate">暂无数据</div>
  </div>
</template>

<script>
// isDesign
export default dsf.component({
  name: "DsfNcMainNewsList",
  ctrlCaption: "首页新闻列表",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: window.innerHeight - 180 - 70 - 20 - 43 + "px",
    },
    width: {
      type: String,
      default: "100%",
    },
    margin: {
      type: Array,
      default() {
        return [];
      },
    },
    padding: {
      type: Array,
      default() {
        return [20, 24.5, 20, 24.5];
      },
    },
    rowPadding: {
      type: Array,
      default() {
        return [];
      },
    },
    rowMargin: {
      type: Array,
      default() {
        return [0, 0, 20, 0];
      },
    },
    detailUrl: {
      type: String,
      default: "",
    },
    reqUrl: {
      type: String,
      default: "cms/articles/queryArticlesByCatalogCode",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "title", to: "title", type: "text", desc: "新闻标题" },
          { from: "subtitle", to: "content", type: "text", desc: "内容" },
          { from: "pageView", to: "readingNumber", type: "text", desc: "查看人数" },
          { from: "publishTime", to: "time", type: "text", desc: "时间" },
        ];
      },
    },
  },
  computed: {
    controlStyle() {
      let { margin, width, height, padding, isDesign, visible } = this;
      let style = {
        margin: margin.join("px ") + "px",
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    rowStyle() {
      let { rowPadding, rowMargin } = this;
      let style = {
        margin: rowMargin.join("px ") + "px",
        padding: rowPadding.join("px ") + "px",
      };
      return style;
    },
  },
  data() {
    return {
      active: 0,
      news: [],
      keyField: {},
    };
  },
  mounted() {
    this.keysMap.map(item => {
      this.keyField[item.to] = item.from;
    });
    if (!this.isDesign) {
      this.initData();
    } else {
      this.news = [
        {
          title: "阿里研究院副院长安筱鹏：一文讲透数字化的8个关键问题",
          subtitle:
            "正在进行中：“数字化”“数字中国”被列为“十四五”规划的核心之一，数字经济席卷各行各业，新一轮们丰富的想象空间。不论是数字化，还是数智化，成为疫情之后几乎每家企业…",
          pageView: 15,
          publishTime: "2019-08-27",
        },
        {
          title: "《2021线上新品消费趋势报告》：新品年年翻倍品年年翻倍",
          subtitle: "“宁可转型升级死话。某种程度上，这代表了不少正松枪声已响起。无形的革新重。不论是数字化，还是数智化，成为疫情之后几乎每家企业…",
          pageView: 15,
          publishTime: "2019-08-2222",
        },
      ];
    }
  },
  methods: {
    changeActive(index) {
      this.active = index;
    },

    initData() {
      if (!this.reqUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.reqUrl, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.news = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        if (this.$checkLogin) {
          this.$checkLogin().then(res => {
            if (res) next(...params);
          });
        } else {
          next(...params);
        }
      } catch (err) {
        console.log(err);
      }
    },
    goDetail(item) {
      if (this.$listeners["click"]) {
        this.$dispatch("click", item);
      } else {
        // if (this.detailUrl) {
        //   let url = this.$replace(this.detailUrl, item);
        //   url && dsf.layer.openWindow(url);
        // }
        if (item.type == 3) {
          item.externalLink && window.open(item.externalLink);
        } else {
          if (this.detailUrl) {
            let url = this.$replace(this.detailUrl, item);
            url && dsf.layer.openWindow(url);
          }
        }
      }
      //获取积分
      dsf.http
        .get("nc/cms/articles/queryArticlesById", { id: item.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            dsf.layer.message(res.message || "积分异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(res.message || "积分异常", false);
        })
        .always(res => {});
    },
  },
});
</script>
