var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "dsf-nc-spacil-card-no-hover" }, [
        _c(
          "div",
          { staticClass: "cover dsf-nc-image" },
          [
            _c("dsf-nc-image", { attrs: { "error-src": _vm.defaultSrc } }),
            _vm._m(0),
          ],
          1
        ),
        _vm._m(1),
        _vm._m(2),
      ])
    : _c(
        "div",
        {
          staticClass: "dsf-nc-spacil-card-no-hover",
          attrs: { title: _vm.data["title"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "cover dsf-nc-image" },
            [
              _c("dsf-nc-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.defaultSrc,
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  {
                    staticClass: "card-state",
                    style:
                      "background-color: " +
                      _vm.showBgColor(_vm.data["stateText"]),
                  },
                  [_vm._v(_vm._s(_vm.data["stateText"]))]
                ),
                _c("div", { staticClass: "card-info" }, [
                  _vm.data["show_description"] == 1
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.data["state_description"])),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.data["sDate"]) +
                            " 至 " +
                            _vm._s(_vm.data["eDate"])
                        ),
                      ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.data["title"])),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("span", [
              _c("i", { staticClass: "iconfont icon-icon-bi" }),
              _c("b", [_vm._v(_vm._s(_vm.data["compulsoryHours"]) + "学时")]),
            ]),
            _c("span", [
              _c("i", { staticClass: "iconfont icon-icon-xuan" }),
              _c("b", [_vm._v(_vm._s(_vm.data["electivesHours"]) + "学时")]),
            ]),
            _c("span", [
              _c("i", { staticClass: "iconfont icon-icon-people" }),
              _c("b", [_vm._v(_vm._s(_vm.data["views"]))]),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c(
        "div",
        {
          staticClass: "card-state",
          staticStyle: { "background-color": "#0080ff" },
        },
        [_vm._v("报名")]
      ),
      _c("div", { staticClass: "card-info" }, [
        _c("span", [_vm._v("2019.07.24 ～ 2019.08.27")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("网络学院卡片组件")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("span", [
        _c("i", { staticClass: "iconfont icon-wodexuanzhong" }),
        _c("b", [_vm._v("1234")]),
      ]),
      _c("span", [
        _c("i", { staticClass: "iconfont icon-wodexuanzhong" }),
        _c("b", [_vm._v("1234")]),
      ]),
      _c("span", [
        _c("i", { staticClass: "iconfont icon-wodexuanzhong" }),
        _c("b", [_vm._v("1234")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }