<template>
  <div v-if="isDesign" class="ds_channel_card">
    <div class="channel_card_wrap">
      <div class="cover"><dsf-nc-image :error-src="defaultSrc" /></div>
      <div class="desc">
        <h3 class="desc-title"><span class="name ell">1</span></h3>
        <div class="info">
          <div>资源数: 8 ｜ 学时: 3</div>
          <div class="subscrip">订阅人数: 1382</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ds_channel_card" @click="click">
    <div class="channel_card_wrap">
      <div class="cover"><dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" /></div>
      <div class="desc">
        <h3 class="desc-title">
          <span class="name ell" :title="data.title || ''">{{ data.title || "" }}</span>
        </h3>
        <div class="info">
          <div>资源数: {{ data.course_count || 0 }} ｜ 学时: {{ data.period || 0 }}</div>
          <div class="subscrip">订阅人数: {{ data.subscribed_num || 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcChannelCard",
  ctrlCaption: "学习专区卡片",
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "nc_class_page_mainData.cover", to: "cover", type: "text", desc: "封面" },
          { from: "nc_channel_page_mainData.title", to: "title", type: "text", desc: "标题" },
          { from: "nc_class_page_mainData.course_count", to: "course_count", type: "text", desc: "课程数" },
          { from: "nc_class_page_mainData.period", to: "period", type: "text", desc: "学时" },
          { from: "nc_class_page_mainData.subscribed_num", to: "subscribed_num", type: "text", desc: "订阅数" },
        ];
      },
    },

    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },

  mounted() {},

  methods: {
    loadChannelList() {},

    click() {
      if (!this.clickConfig) return;

      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);

      this.$open({
        url,
        state,
      });
      this.clickAfter();
    },
  },
});
</script>
