<template>
  <div class="dsf_specialdetail_intro_recommend" :style="{ width: itemWidth, display: visible ? 'block' : 'none', margin: itemSpace.replace(/,/g, ' ') }">
    <div class="intro_title">
      <div class="desc">
        <span class="font">{{ title }}</span>
        <span class="more" v-if="showMore && moreStyle == 'tb'" @click="!isview && !!showMore && clickMore()"></span>
        <span class="more_font" v-else-if="showMore && moreStyle == 'wz'" @click="!isview && !!showMore && clickMore()">更多</span>
      </div>
      <span class="line" v-if="showLine"></span>
    </div>
    <ul class="recommend_wrap" v-if="lists.length && type == 'class'">
      <li class="recommend_items" v-for="(item, index) in lists" :key="index" @click="!isview && clickItem(item)">
        <div class="head_img cover">
          <dsf-image :src="item.cover | imgFormat" width="140px" height="80px" />
        </div>
        <div class="recommend_desc">
          <div class="desc_title ell">{{ item.name }}</div>
          <div class="desc_data">{{ setDate(item.pxsjSdate, item.pxsjEdate, " ～ ") }}</div>
          <div class="foot">
            <span class="bx">必修 {{ item.compulsoryPeriod || 0 }}</span>
            <span class="xx">选修 {{ item.electivesPeriod || 0 }}</span>
            <span class="person">
              <i class="icon iconfont icon-tab-mine-fill"></i>
              {{ item.studentCount || 0 }}
            </span>
          </div>
        </div>
      </li>
    </ul>
    <ul class="recommend_wrap channel_recommend" v-else-if="lists.length && type == 'channel'">
      <li class="recommend_items" v-for="(item, index) in lists" :key="index" @click="!isview && clickItem(item)">
        <div class="head_img cover">
          <dsf-image :src="item.cover | imgFormat" width="140px" height="80px" />
        </div>
        <div class="recommend_desc">
          <div class="desc_title ell">{{ item.title }}</div>
          <div class="desc_data">资源数:{{ item.courseCount || 0 }} | 学时: {{ item.period || 0 }}</div>
          <div class="foot">
            <span class="subscribed">订阅数: {{ item.subscription || 0 }}</span>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSpecialDetailIntroRecommend",
  ctrlCaption: "专题班详情简介相关推荐",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "相关推荐",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "/nc/class/recommend",
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    moreStyle: {
      type: String,
      default: "tb",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "class",
    },
  },
  data() {
    return {
      lists: [
        {
          cover: "",
          name: "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          date: "2019.07.24 ～ 2019.08.27",
          compulsoryPeriod: "20",
          electivesPeriod: "58.5",
          studentCount: "4527",
        },
        {
          cover: "",
          name: "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          date: "2019.07.24 ～ 2019.08.27",
          compulsoryPeriod: "20",
          electivesPeriod: "58.5",
          studentCount: "4527",
        },
      ],
      params: {
        id: "",
        pageNum: 1,
        pageSize: 3,
      },
      isview: false,
    };
  },
  created() {
    this.params.id = this.queryString.id || "011ec79ad5fc40c9b42f5aa9a53440a0";
    this.isview = this.queryString.isview == 1 ? true : false;
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    reData() {
      this.initData();
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      // this.params
      // this.url = this.url + "&id=" + this.$route.query.id;
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickMore() {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      console.log("点击更多了---");
      let { url, state } = this.moreUrl;
      this.$open({ url, state });
      // this.$open({ url: this.moreUrl });
    },
    clickItem(item) {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      if (!this.itemConfig) return;
      let { url, state } = this.itemConfig;
      url = this.$replace(url, item);
      this.$open({ url, state });
      console.log("点击了某一项", item, this.itemConfig);
    },
    setImg(data) {
      let cover = data["cover"];
      if (cover) {
        cover = dsf.config.webRoot + JSON.parse(cover)[0]["relativePath"];
      }
      return cover || "/";
    },
    setDate(startDate, endDate, interval) {
      let start = startDate ? startDate.slice(0, 10).replace(/-/g, ".") : "";
      let end = endDate ? endDate.slice(0, 10).replace(/-/g, ".") : "";
      return start && end ? `${start}  ${interval}  ${end}` : "暂无";
    },
  },
});
</script>
