<template>
  <DsfSection
    mainWidth="1200px"
    :margin="[0, 0, 0, 0]"
    :padding="[10, 10, 10, 10]"
    :backgroundConfig="{
      color: '#fff',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
    :foregroundConfig="{
      color: 'rgba(255, 255, 255, 0)',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
  >
    <!--nctitle33-->
    <DsfNcTitle
      v-if="showTitle"
      :showMore="showMore"
      :title="title"
      caption="DsfNcTitle"
      :showBottomLine="true"
      titleSize="big"
      :titleBold="false"
      :clickConfig="clickConfigTitle"
      padding="80px 0 60px 0"
    ></DsfNcTitle>
    <!--courseList-->
    <DsfPlatformCardBox :columnNumber="4" columnWidth="280px" marginTop="15px" rowKey="_id" :url="sourceUrl" :checkbox="false">
      <template v-slot:default="scope">
        <!--nccoursecard-->
        <DsfNcCourseCard :local="scope.row" :keysMap="keysMap" :clickConfig="clickConfig" :click-before="checkLoginCourse"></DsfNcCourseCard>
      </template>
    </DsfPlatformCardBox>
  </DsfSection>
</template>

<script>
export default dsf.component({
  name: "DsfNcSaasHomeJingpinkecheng",
  ctrlCaption: "精品课程",
  mixins: [$mixins.control],
  props: {
    moreText: {
      type: String,
      default: "查看更多",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      list: [],
      clickConfig: {
        url: "page.html#/pc/nc/pagecourse/coursePlayer?id=@[_id]",
        state: 2,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
      clickConfigTitle: {
        url: "/ncIndex.html#/pc/nc/pagecourse/courseList",
        state: 4,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
      keysMap: [
        {
          from: "nc_courses_page_homesource.cover",
          to: "cover",
          type: "text",
          desc: "封面",
        },
        {
          from: "nc_courses_page_homesource.is_new",
          to: "isNew",
          type: "boolean",
          desc: "标签",
        },
        {
          from: "nc_courses_page_homesource.title",
          to: "title",
          type: "text",
          desc: "课题",
        },
        {
          from: "nc_courses_page_homesource.teacher_text",
          to: "teacher",
          type: "text",
          desc: "授课教师",
        },
        {
          from: "nc_courses_page_homesource.view_totle",
          to: "views",
          type: "text|number",
          desc: "浏览量",
        },
      ],
    };
  },
  computed: {
    title() {
      return this.data["bindAttr"]["title"];
    },
    showTitle() {
      return this.data["bindAttr"]["showTitle"] == 1 ? true : false;
    },
    showMore() {
      return this.data["bindAttr"]["showMore"] == 1 ? true : false;
    },
    sourceUrl() {
      let query = '{"searchValue":""}';
      if (this.data.bindAttr["selectType"] && this.data.bindAttr["selectType"][0]) {
        let type = this.data.bindAttr["selectType"][0];
        query = '{"searchValue":"","classification_value":["' + type.value + '"]}';
      }
      return (
        "/inc/meta/list/data?pageNum=1&pageSize=8&query=" +
        encodeURIComponent(query) +
        "&order=" +
        encodeURIComponent("[]") +
        "&filter=" +
        encodeURIComponent("[]") +
        "&namespace=nc.courses.page&pageName=indexCourse"
      );
    },
  },
  created() {},
  mounted() {},
  methods: {
    checkLoginCourse: function (data) {
      var _this = this;
      if (data["nc_courses_page_homesource.login_limit_value"] == "0") {
        return true;
      }
      return this.$checkLogin().then(function (state) {
        if (state) {
          if (_this.$refs.courseList) {
            _this.$refs.courseList.reloadData();
          }
        } else {
          return false;
        }
      });
    },
  },
});
</script>
