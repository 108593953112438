var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_study_task ds-control",
      style: { width: _vm.itemWidth },
    },
    [
      _c("div", { staticClass: "dsf_study_task_inner" }, [
        !_vm.isDesign
          ? _c(
              "div",
              { staticClass: "content" },
              [
                _vm.taskType == "course"
                  ? _c("course-column", {
                      attrs: { list: _vm.itemList },
                      on: { toDetail: _vm.toDetailPage },
                    })
                  : _vm._e(),
                _vm.taskType == "class"
                  ? _c("special-column", {
                      attrs: { list: _vm.itemList },
                      on: { toDetail: _vm.toDetailPage },
                    })
                  : _vm._e(),
                _vm.taskType == "exam"
                  ? _c("exam-column", {
                      attrs: { list: _vm.itemList },
                      on: { toDetail: _vm.toDetailPage },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _c("div", [
              _vm._v(" " + _vm._s(_vm.sourceUrl + ":" + _vm.taskType) + " "),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }