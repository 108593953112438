<template>
  <div class="dsf_live_information-mobile" :style="{ width: itemWidth, display: visible ? 'block' : 'none', margin: itemSpace.replace(/,/g, ' ') }">
    <div class="intro_title">
      <div class="desc">
        <h3 class="course-tab-title">{{ title }}</h3>
        <span class="more" v-if="showMore" @click="!!showMore && clickMore()"></span>
      </div>
    </div>
    <ul class="relevant_information_wrap" v-if="lists.length">
      <li class="infomation_items" v-for="(item, index) in lists" :key="index">
        <i :class="`icon ${item['nc_class_file.type']}`"></i>
        <span class="name ell">{{ item["nc_class_file.title"] }}</span>
        <span class="ylook" @click="handleClickLook(item)">预览</span>
        <span class="segmentation" v-if="item['nc_class_file.download_free'] && item['nc_class_file.download_free'].value == 1">|</span>
        <span class="ydown" @click="handleClickDown(item)" v-if="item['nc_class_file.download_free'] && item['nc_class_file.download_free'].value == 1">下载</span>
      </li>
    </ul>
    <div class="no_data" v-else>暂无数据</div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcLiveInformation",
  ctrlCaption: "直播简介相关资料",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "相关资料",
    },
    url: {
      type: String,
      default: "/meta/list/data",
    },
    page: {
      type: Number,
      default: 10,
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    itemWidth: {
      type: String,
      default: "100%",
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lists: [
        {
          "nc_class_file.title": "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          "nc_class_file.type": "doc",
        },
        {
          "nc_class_file.title": "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          "nc_class_file.type": "xlsx",
        },
      ],
      params: {
        id: "",
        pageNum: 1,
        pageSize: this.page,
        query: JSON.stringify({ searchValue: "", class_id: "@[url('classId')]", publish_state_value: "[1]" }),
        order: JSON.stringify([
          { code: "publish_state_value", sort: "desc" },
          { code: "publish_time", sort: "desc" },
          { code: "ds_update_time", sort: "desc" },
        ]),
        filter: "[]",
        namespace: "nc.class.teaching",
        pageName: "upload",
        classId: "",
      },
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "f5e7063e252d42ceb5520a6c5f9dd03e";
    this.params.classId = this.params.id;
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    checkLogin() {
      return new Promise(resolve => {
        this.$checkLogin(true, true).then(res => {
          if (res) resolve(res);
        });
      });
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickMore() {
      let { url, state } = this.moreUrl;
      this.$open({ url, state });
    },
    async handleClickLook(item) {
      await this.checkLogin();
      // 预览
      let relativePath = item["nc_class_file.file"];
      if (!relativePath) return;
      relativePath = JSON.parse(relativePath)[0].relativePath;
      let root = dsf.url.getWebPath("preview/file");
      const preUrl = dsf.config.setting_public_file_host;
      let url = `${root}?url=${preUrl}${relativePath}`;
      // window.open(url);
      dsf.layer.openWindow(":" + url);
    },
    // 下载前
    handlerDownBefore(sourceUrl, params, succFn, failFun) {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          res.success && succFn && succFn(res);
          if (!res.success) error(res);
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        failFun && failFun(err);
        dsf.layer.pc.message(err.message, false);
      }
    },
    async handleClickDown(item) {
      await this.checkLogin();
      // 查看是否允许下载
      // if (item["nc_class_file.downloads"] == 0) return dsf.layer.message("该资料暂不支持下载,请联系管理员", false);
      this.handlerDownBefore("nc/class/teaching/file/download", { fileId: item._id, classId: this.params.id }, res => {
        // 下载
        var url = dsf.url.getWebPath("file/download");
        let file = item["nc_class_file.file"];
        if (!file) return;
        file = JSON.parse(file)[0];
        $(
          `<form action="${url}" method="get">
          <input type="text" name="files" value="${file.id}"/>
          <input type="text" name="zipName" value="${file.originalFileName || ""}"/>
        </form>`
        )
          .appendTo("body")
          .submit()
          .remove();
      });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
