var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "dsf_nc_title_three " + _vm.titleType,
      style: {
        borderBottomColor: _vm.bottomStyleColor,
        margin: _vm.controlStyle,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "title-inner",
          style: { borderBottomColor: _vm.bottomStyleColor },
        },
        [
          _c(
            "div",
            { staticClass: "title_wrap" },
            _vm._l(_vm.menus, function (item, index) {
              return _c(
                "div",
                {
                  key: "title" + item.name,
                  class: ["title", { active: _vm.active == index }],
                  on: {
                    click: function ($event) {
                      return _vm.handlerClick(_vm.menus, item, index)
                    },
                  },
                },
                [
                  _c("dsf-icon", { attrs: { name: item.icon } }),
                  _c("span", { staticClass: "font" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _vm.showMore
            ? _c("div", { staticClass: "title-more" }, [
                _c(
                  "span",
                  {
                    attrs: { slot: "more" },
                    on: {
                      click: function ($event) {
                        return _vm._clickBefore(_vm.toUrl)
                      },
                    },
                    slot: "more",
                  },
                  [_vm._v(_vm._s(_vm.moreFont))]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }