var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "dsf_liveTitle", style: { padding: _vm.titlePadding } },
        [
          _c(
            "div",
            {
              staticClass: "txt",
              style: { fontSize: _vm.titleFont, width: _vm.titleWidth },
            },
            [
              _vm.src
                ? _c("img", {
                    staticClass: "title-img",
                    attrs: { src: _vm.src, alt: "" },
                  })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.title))]),
              _vm.titleEg
                ? _c(
                    "span",
                    { staticClass: "en", style: { fontSize: _vm.titleFont } },
                    [_vm._v(_vm._s(_vm.titleEg))]
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }