import { render, staticRenderFns } from "./studyTask.vue?vue&type=template&id=5dbdff0e&"
import script from "./studyTask.vue?vue&type=script&lang=js&"
export * from "./studyTask.vue?vue&type=script&lang=js&"
import style0 from "./studyTask.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/components/onStudy/studyTask.vue"
export default component.exports