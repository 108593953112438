<template>
  <div class="dsf_ncpc_subscribe_dynamic" :style="{ width: itemWidth, display: visible ? 'block' : 'none', margin: itemSpace.replace(/,/g, ' ') }">
    <div class="intro_title">
      <div class="desc">
        <span class="font">{{ title }}</span>
        <span class="more" v-if="showMore && moreStyle == 'tb'" @click="!!showMore && clickMore()"></span>
        <span class="more_font" v-else-if="showMore && moreStyle == 'wz'" @click="!!showMore && clickMore()">更多</span>
      </div>
      <span class="line" v-if="showLine"></span>
    </div>
    <ul class="subscribe_dynamic_wrap" v-if="lists.length">
      <li class="subscribe_dynamic_items" v-for="(item, index) in lists" :key="index">
        <div class="left">
          <el-avatar class="photo" :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.userId" @error="errorHandler">
            <dsf-nc-image class="avatar" :src="''" :error-src="defaultAvatar" />
          </el-avatar>
          <div class="name">{{ item.userName || "" }}</div>
        </div>
        <div class="date">{{ item.subscribeDateTime || "暂无" }}&nbsp;&nbsp;订阅</div>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcSubscribeDynamic",
  ctrlCaption: "订阅动态",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "订阅动态",
    },
    url: {
      type: String,
      default: "/nc/teacher/subscribed/log",
    },
    page: {
      type: Number,
      default: 3,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    moreStyle: {
      type: String,
      default: "tb",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lists: [
        {
          userName: "花伦琦",
          subscribeDateTime: "1974-07-10  12:00",
          photo: "",
        },
        {
          userName: "储菁致",
          subscribeDateTime: "1974-07-10  12:00",
          photo: "",
        },
        {
          userName: "储菁致",
          subscribeDateTime: "1974-07-10  12:00",
          photo: "",
        },
      ],
      defaultAvatar: dsf.config.setting_nc_image_teacher_header_img || dsf.config.setting_public_teacher_default_header || this.errorSrc,
      params: {
        id: "",
        index: 1,
        size: 3,
      },
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "f5e7063e252d42ceb5520a6c5f9dd03e";
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    errorHandler() {
      return true;
    },
  },
});
</script>
