var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds_newsNewsCard-pc", style: _vm.controlStyle },
    [
      _vm.isDesign
        ? _c(
            "div",
            {
              staticClass: "ds_newsNewsCard-row",
              on: {
                click: function ($event) {
                  return _vm._clickBefore(_vm.goDetail, _vm.data)
                },
              },
            },
            [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "cover-img" },
                [
                  _c("dsf-nc-image", {
                    attrs: { src: "", "error-src": _vm.defaultSrc },
                  }),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "ds_newsNewsCard-row",
              on: {
                click: function ($event) {
                  return _vm._clickBefore(_vm.goDetail, _vm.data)
                },
              },
            },
            [
              _c("div", { staticClass: "title-box" }, [
                _c(
                  "p",
                  {
                    staticClass: "title ell2",
                    class: {
                      isHeight:
                        _vm.data["cover"] && _vm.data["cover"].length > 2,
                    },
                  },
                  [_vm._v(_vm._s(_vm.data["title"]))]
                ),
                _c("div", { staticClass: "detail" }, [
                  _vm.data["source"]
                    ? _c("p", { staticClass: "source" }, [
                        _vm._v(_vm._s(_vm.data["source"])),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "time" }, [
                    _vm._v(
                      _vm._s(
                        _vm.data["time"]
                          ? _vm.dsf.date.format(_vm.data["time"], "yyyy.mm.dd")
                          : ""
                      )
                    ),
                  ]),
                ]),
              ]),
              _vm.data && _vm.data["cover"] && _vm.data["cover"].length > 2
                ? _c(
                    "div",
                    { staticClass: "cover-img" },
                    [
                      _c("dsf-nc-image", {
                        attrs: {
                          src: _vm._f("imgFormat")(_vm.data["cover"]),
                          "error-src": _vm.defaultSrc,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title ell2" }, [
        _vm._v("关于印发《生态环境部2021年度培训计划》的通知"),
      ]),
      _c("div", { staticClass: "detail" }, [
        _c("p", { staticClass: "source" }, [_vm._v("来源")]),
        _c("p", { staticClass: "time" }, [_vm._v("2021/04/05 10:00:00")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }