import instructions from "./instructionsnews.vue";
import mainNewsList from "./mainNewsList.vue";
import newsDetail from "./newsDetail.vue";
import recommendNews from "./recommendNews.vue";
import newsList from "./newsList.vue";
import newsListModel from "./newsListModel.vue";
import recommendNewsListModelTwo from "./recommendNewsListModelTwo.vue";
import newsDetailModelTwo from "./newsDetailModelTwo.vue";
import newsCard from "./newsCard.vue";
import hotRecommendNews from "./hotRecommendNews.vue";
export default [hotRecommendNews, instructions, mainNewsList, newsDetail, recommendNews, newsList, newsListModel, recommendNewsListModelTwo, newsDetailModelTwo, newsCard];
