<template>
  <div>
    <div class="subtitle margint20 marginb20">预览效果</div>
    <headerOne :menuList="menuList" :logoImg="logoImg">
      <div class="style_use">
        <b>经典风格</b>
        <span>经典风格页头，适用于各种网院样式。</span>
        <dsf-button :disabled="useType == '1'" type="small" @click="toggle('1')">{{ useType == "1" ? "使用中" : "应用" }}</dsf-button>
      </div>
    </headerOne>
    <headerTwo :menuList="menuList" :logoImg="logoImg">
      <div class="style_use">
        <b>简约风格</b>
        <span>居中式简洁页头，简洁大方导航上增加了搜索功能。</span>
        <dsf-button :disabled="useType == '2'" type="small" @click="toggle('2')">{{ useType == "2" ? "使用中" : "应用" }}</dsf-button>
      </div>
    </headerTwo>
  </div>
</template>
<script>
import headerOne from "../../base/saas/headerOne.vue";
import headerTwo from "../../base/saas/headerTwo.vue";
export default dsf.component({
  name: "DsfNcSaasChangeHeaderType",
  ctrlCaption: "切换页头模板",
  mixins: [$mixins.control],
  props: {
    useType: {
      type: String,
      default: "1",
    },
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    logoImg: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    headerOne,
    headerTwo,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
      } catch (err) {
        console.log(err);
      }
    },
    toggle(type) {
      this.$emit("changeUseType", type);
    },
  },
});
</script>
