let url;
function newEditResource(code) {
  switch (code) {
    case "courses":
      url = "/page.html#/pc/nc/courses/info/edit";
      break;
    case "live":
      url = "/page.html#/pc/nc/live/info/edit";
      break;
    case "teacher":
      url = "/page.html#/pc/nc/teacher/edit";
      break;
    case "exam":
      url = "/exam/page.html#/pc/exam/manage/twoedit";
      dsf.layer.openWindow(":" + url);
      break;
    case "class":
      url = "/page.html#/pc/nc/class/info";
      break;
    case "channel":
      url = "/page.html#/pc/nc/channel/edit";
      break;
    default:
      break;
  }
  return url;
}

export default newEditResource;
