<template>
  <div class="exam_card">
    <ul class="exam_card_wrap">
      <li class="exam_card_items" v-if="isDesign">
        <div class="title">10.28考试-4 第二批</div>
        <div class="exam_content">
          <div class="exam_date">
            <span class="lable">考试时间：</span>
            <span class="font">2021-12-25 12:00 至 2022-01-01 12:00</span>
          </div>
          <div class="exam_center">
            <div class="row">
              <div class="item">
                <span class="lable">考试时长：</span>
                <span class="font">90分钟</span>
              </div>
              <div class="item">
                <span class="lable">考试总分：</span>
                <span class="font">100分</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span class="lable">及格分数：</span>
                <span class="font">60分</span>
              </div>
              <div class="item">
                <span class="lable">考试次数：</span>
                <span class="font">5次</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span class="lable">已考次数：</span>
                <span class="font">1次</span>
              </div>
              <div class="item">
                <span class="lable">剩余次数：</span>
                <span class="font">4次</span>
              </div>
            </div>
          </div>
        </div>
        <div class="exam_footer">
          <div class="bmck normal btn" v-if="true">报名参考</div>
          <div class="ksck normal btn" v-if="false">开始考试</div>
          <div class="ksxz btn">考生须知</div>
        </div>
      </li>
      <li class="exam_card_items" v-else>
        <div class="title">{{ data["title"] }}</div>
        <div class="exam_content">
          <div class="exam_date">
            <span class="lable">考试时间：</span>
            <span class="font">{{ dsf.date.format(data["sdate"], "yyyy-mm-dd") }} 至 {{ dsf.date.format(data["edate"], "yyyy-mm-dd") }}</span>
          </div>
          <div class="exam_center">
            <div class="row">
              <div class="item">
                <span class="lable">考试时长：</span>
                <span class="font">{{ data["exam_duration"] }}分钟</span>
              </div>
              <div class="item">
                <span class="lable">考试总分：</span>
                <span class="font">{{ data["grossScore"] }}分</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span class="lable">及格分数：</span>
                <span class="font">{{ data["passScore"] }}分</span>
              </div>
              <div class="item">
                <span class="lable">考试次数：</span>
                <span class="font">{{ data["exam_total_count"] }}次</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span class="lable">已考次数：</span>
                <span class="font">{{ data["exam_count"] }}次</span>
              </div>
              <div class="item">
                <span class="lable">剩余次数：</span>
                <span class="font">{{ data["surplus_count"] }}次</span>
              </div>
            </div>
          </div>
        </div>
        <div class="exam_footer">
          <div class="bmck normal btn" v-if="true">报名参考</div>
          <div class="ksck normal btn" v-if="false">开始考试</div>
          <div class="ksxz btn">考生须知</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcExamCard",
  ctrlCaption: "考试卡片",
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "title", to: "title", type: "text", desc: "考试标题" },
          { from: "sdate", to: "sdate", type: "text", desc: "开始时间" },
          { from: "edate", to: "edate", type: "text", desc: "结束时间" },
          { from: "exam_duration", to: "exam_duration", type: "text", desc: "考试时长" },
          { from: "grossScore", to: "grossScore", type: "text", desc: "考试总分" },
          { from: "passScore", to: "passScore", type: "text", desc: "及格分数" },
          { from: "exam_total_count", to: "exam_total_count", type: "text", desc: "考试次数" },
          { from: "exam_count", to: "exam_count", type: "text", desc: "已考次数" },
          { from: "surplus_count", to: "surplus_count", type: "text", desc: "剩余次数" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
});
</script>
