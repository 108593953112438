<template>
  <div class="dsf_nc_title" :style="{ padding: padding }">
    <div class="title-inner">
      <div class="title-wrap">
        <h3 class="title">{{ title }}</h3>
        <div class="title-more">
          <span slot="more" @click="toDetail">更多>></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dsfNcTitle",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    moreUrl: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "50px 0 10px 0",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    toDetail() {
      let { moreUrl } = this;
      if (moreUrl) {
        this.$checkLogin().then(() => {
          dsf.layer.openWindow(moreUrl + item._id);
        });
      }
    },
  },
};
</script>
