var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-special-source-control-item" }, [
    _c(
      "div",
      { staticClass: "control-item", class: { "item-readonly": _vm.readonly } },
      [
        _c("div", { staticClass: "controll-head" }, [
          _c(
            "div",
            { staticClass: "chapter-name-wrap" },
            [
              _c("el-input", {
                staticClass: "chapter-name",
                attrs: {
                  placeholder: "请输入维护章节名称",
                  disabled: _vm.readonly,
                },
                on: { blur: _vm.chapterNameBlur },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "controll-head-desc" }, [
            _c(
              "dl",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMustBe,
                    expression: "showMustBe",
                  },
                ],
                staticClass: "controll-head-desc-item",
              },
              [
                _vm._v(" 必修 "),
                _c("span", [_vm._v(_vm._s(_vm.desc.compulsory || 0))]),
                _vm._v(" 门 "),
              ]
            ),
            _c(
              "dl",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMustBe,
                    expression: "showMustBe",
                  },
                ],
                staticClass: "controll-head-desc-item",
              },
              [
                _vm._v(" 选修 "),
                _c("span", [_vm._v(_vm._s(_vm.desc.electives || 0))]),
                _vm._v(" 门 "),
              ]
            ),
            _c("dl", { staticClass: "controll-head-desc-item" }, [
              _vm._v(" 共 "),
              _c("span", [_vm._v(_vm._s(_vm.desc.totalPeriod || 0))]),
              _vm._v(" 学时 "),
            ]),
            _c(
              "dl",
              { staticClass: "chapter-handle" },
              [
                _c("dsf-button", {
                  attrs: { icon: "icon_up1", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("chapterToUp", _vm.idx)
                    },
                  },
                }),
                _c("dsf-button", {
                  attrs: { icon: "icon_down1", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("chapterToDown", _vm.idx)
                    },
                  },
                }),
                _c("dsf-button", {
                  staticClass: "control-item-delete",
                  attrs: { icon: "icon_del1", size: "mini" },
                  on: { click: _vm.deleteChaterItem },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "controll-buttons" }, [
          _c(
            "div",
            { staticClass: "controll-sort" },
            [
              _vm._v(" 是否按序学习 "),
              _c("el-switch", {
                attrs: {
                  "active-color": _vm.curColor,
                  width: "36",
                  disabled: _vm.readonly,
                },
                on: { change: _vm.changeSort },
                model: {
                  value: _vm.isSort,
                  callback: function ($$v) {
                    _vm.isSort = $$v
                  },
                  expression: "isSort",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "controll-button-wrap" },
            [
              _vm._l(_vm.buttons, function (it) {
                return _c("dsf-button", {
                  key: it.id,
                  attrs: { size: "small", text: it.text },
                  on: {
                    click: function ($event) {
                      return _vm.toOpenDialog(it)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "controll-content" }, [
          _c(
            "div",
            { staticClass: "content-inner" },
            _vm._l(_vm.list, function (it, index) {
              return _c(
                "dl",
                {
                  key: it._id,
                  staticClass: "item",
                  style: "cursor: " + (_vm.readonly ? "unset" : "pointer"),
                },
                [
                  _c(
                    "dd",
                    {
                      staticClass: "title ellipsis",
                      attrs: { title: it["name"] },
                    },
                    [
                      _c("dsf-icon", {
                        attrs: { name: _vm.getSurceItemIcon(it["typeText"]) },
                      }),
                      _vm._v(" " + _vm._s(it["name"]) + " "),
                    ],
                    1
                  ),
                  _c(
                    "dd",
                    {
                      staticClass: "name ellipsis",
                      attrs: { title: it["teacher"] },
                    },
                    [_vm._v(_vm._s(it["teacher"] || ""))]
                  ),
                  _vm.showTime
                    ? _c(
                        "dd",
                        {
                          staticClass: "time",
                          staticStyle: { width: "344px" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              readonly: _vm.readonly,
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "picker-options": _vm.pickerDiabled,
                              format: "yyyy-MM-dd HH:mm",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeTime(index)
                              },
                            },
                            model: {
                              value: it.time,
                              callback: function ($$v) {
                                _vm.$set(it, "time", $$v)
                              },
                              expression: "it.time",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "dd",
                    {
                      class: [
                        "study-time",
                        { allowEdit: it["typeText"] !== "课程" },
                      ],
                    },
                    [
                      _c("el-input", {
                        staticClass: "study-time-input",
                        attrs: {
                          placeholder: "学时",
                          readonly: it["typeText"] == "课程",
                          type: "number",
                          min: "0",
                          max: _vm.maxHours,
                          disabled: _vm.readonly,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.studyHourBlur(it["period"])
                          },
                        },
                        model: {
                          value: it["period"],
                          callback: function ($$v) {
                            _vm.$set(it, "period", $$v)
                          },
                          expression: "it['period']",
                        },
                      }),
                      _vm._v(" 学时 "),
                    ],
                    1
                  ),
                  _c(
                    "dd",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showMustBe,
                          expression: "showMustBe",
                        },
                      ],
                      staticClass: "specila-select",
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "specila-select-select",
                          attrs: { disabled: _vm.readonly },
                          on: {
                            change: function ($event) {
                              return _vm.changeSelect(index)
                            },
                          },
                          model: {
                            value: it["categoryText"],
                            callback: function ($$v) {
                              _vm.$set(it, "categoryText", $$v)
                            },
                            expression: "it['categoryText']",
                          },
                        },
                        _vm._l(_vm.courseClass, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "dd",
                    { staticClass: "buttons" },
                    [
                      _c("dsf-button", {
                        attrs: {
                          icon: "yidong",
                          size: "mini",
                          title: "按住拖拽可调整顺序",
                        },
                        on: {
                          mousedown: function ($event) {
                            return _vm.toMoveDown(index, $event)
                          },
                        },
                      }),
                      _c("dsf-button", {
                        attrs: { icon: "icon_up1", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.toUp(index)
                          },
                        },
                      }),
                      _c("dsf-button", {
                        attrs: { icon: "icon_down1", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.toDown(index)
                          },
                        },
                      }),
                      _c("dsf-button", {
                        attrs: { icon: "icon_del1", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.toDetle(it["subId"], _vm.idx)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
    _vm.moreBtns
      ? _c("div", {
          staticClass: "mask",
          on: {
            click: function ($event) {
              _vm.moreBtns = false
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }