<template>
  <div class="dsf_nc_statistical_summary">
    <ul class="card">
      <li :style="{ width: item.width, background: item.bg }" v-for="(item, idx) in menus" :key="idx" @click="itemClick(item)">
        <p>
          <dsf-icon :name="item.icon"></dsf-icon>
        </p>
        <div>
          <span>{{ resData[item.field] || 0 }}</span>
          <label for="">{{ item.name }}</label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcStatisticalSummary",
  ctrlCaption: "统计汇总",
  mixins: [$mixins.control],
  props: {
    width: {
      type: String,
      default: "278px",
    },
    title: {
      type: String,
      default: "",
    },
    reqUrl: { type: String, default: "" },
    menus: {
      type: Array,
      default: () => [
        {
          icon: "icon_xm1",
          bg: "#F2BB74",
          name: "课程总数",
          field: "num1",
          width: "284px",
        },
        {
          icon: "icon_xm1",
          bg: "#7CC2DE",
          name: "课时总数",
          field: "num2",
          width: "284px",
        },
        {
          icon: "icon_xm1",
          bg: "#5FC1B8",
          name: "学员总数",
          field: "num3",
          width: "284px",
        },
        {
          icon: "icon_xm1",
          bg: "#FF8B62",
          name: "年度计划",
          field: "num4",
          width: "284px",
        },
      ],
    },
  },
  data() {
    return {
      resData: {},
    };
  },
  created() {},
  mounted() {
    if (this.isDesign) {
      this.resData = { num1: 0, num2: 40, num3: 2, num4: 111 };
    } else {
      this.initData();
    }
  },
  methods: {
    initData() {
      if (!this.reqUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.reqUrl, { id: this.id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.resData = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    itemClick(item) {
      if (this.$listeners["clickItem"]) {
        this.$dispatch("clickItem", item);
      }
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
