<template>
  <el-image class="dsf-nc-image" v-bind="$props" @load="$emit('load', $event)" @error="$emit('error', $event)">
    <template #placeholder>
      <slot name="placeholder">
        <i class="el-icon-loading"></i>
      </slot>
    </template>
    <template #error>
      <slot name="error">
        <img class="dsf-image-fail" :src="defaultImg" alt="" />
      </slot>
    </template>
  </el-image>
</template>

<script>
export default {
  name: "DsfNcImage",
  props: {
    // 图片源，同原生
    src: {
      type: String,
      required: false,
    },
    // 同原生 object-fit
    // fill / contain / cover / none / scale-down
    fit: {
      type: String,
      default: "cover",
    },
    // 原生 alt
    alt: {
      type: String,
      required: false,
    },
    // 原生 referrerPolicy
    referrerPolicy: {
      type: String,
      required: false,
    },
    // 是否开启懒加载
    lazy: {
      type: Boolean,
      default: false,
    },
    // 开启懒加载后，监听 scroll 事件的容器
    // 最近一个 overflow 值为 auto 或 scroll 的父元素
    scrollContainer: {
      type: [String, HTMLElement],
      required: false,
    },
    // 开启图片预览功能
    previewSrcList: {
      type: Array,
      required: false,
    },
    zIndex: {
      type: Number,
      default: 2000,
    },
    /**************/
    // 加载失败的图片
    errorSrc: {
      type: String,
      required: false,
    },
  },
  mounted() {},
  computed: {
    defaultImg() {
      return this.errorSrc || dsf.config.setting_public_card_default_img || dsf.config.setting_error_img;
    },
  },
};
</script>
