var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfSection",
    {
      attrs: {
        mainWidth: "1200px",
        margin: [0, 0, 0, 0],
        padding: [10, 10, 10, 10],
        backgroundConfig: {
          color: "#f9f9f9",
          image: "",
          size: "cover",
          repeat: "no-repeat",
          position: "center",
        },
        foregroundConfig: {
          color: "rgba(255, 255, 255, 0)",
          image: "",
          size: "cover",
          repeat: "no-repeat",
          position: "center",
        },
      },
    },
    [
      _vm.showTitle
        ? _c("DsfNcTitle", {
            attrs: {
              showMore: _vm.showMore,
              title: _vm.title,
              caption: "DsfNcTitle",
              showBottomLine: true,
              titleSize: "big",
              titleBold: false,
              clickConfig: _vm.clickConfigTitle,
              padding: "80px 0 60px 0",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "dsf_nc_tuwendaohang" }, [
        _c(
          "div",
          { staticClass: "images-wrap" },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "image-item",
                class: _vm.count == 4 ? "count4" : "",
                on: {
                  click: function ($event) {
                    return _vm.goDetail(item)
                  },
                },
              },
              [
                _c("DsfImage", {
                  attrs: {
                    src: item.image && item.image.relativePath,
                    "error-src": _vm.dsf.config.setting_public_card_default_img,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }