var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfSection",
    {
      attrs: {
        mainWidth: "1200px",
        margin: [0, 0, 0, 0],
        padding: [10, 10, 10, 10],
        backgroundConfig: {
          color: "#fff",
          image: "",
          size: "cover",
          repeat: "no-repeat",
          position: "center",
        },
        foregroundConfig: {
          color: "rgba(255, 255, 255, 0)",
          image: "",
          size: "cover",
          repeat: "no-repeat",
          position: "center",
        },
      },
    },
    [
      _vm.showTitle
        ? _c("DsfNcTitle", {
            attrs: {
              showMore: _vm.showMore,
              title: _vm.title,
              caption: "DsfNcTitle",
              showBottomLine: true,
              titleSize: "big",
              titleBold: false,
              clickConfig: _vm.clickConfigTitle,
              padding: "80px 0 60px 0",
            },
          })
        : _vm._e(),
      _c("DsfPlatformCardBox", {
        attrs: {
          columnNumber: 4,
          columnWidth: "280px",
          marginTop: "15px",
          rowKey: "_id",
          url: _vm.sourceUrl,
          checkbox: false,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("DsfNcCourseCard", {
                  attrs: {
                    local: scope.row,
                    keysMap: _vm.keysMap,
                    clickConfig: _vm.clickConfig,
                    "click-before": _vm.checkLoginCourse,
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }