<template>
  <div class="dsf_specialdetail_complete" :style="{ display: visible ? 'block' : 'none' }">
    <!-- 进度 -->
    <div class="dsf_nc_special_detail_complete_schedule">
      <div class="complete_title">
        <span class="font">进度</span>
        <span class="font des">进度=已获学时/考核学时*100%</span>
      </div>
      <div class="complete_schedule_desc" v-for="(item, index) in unitAssess" :key="index">
        <div class="schedule_title">{{ item.unitName || "" }}</div>
        <div class="progress bx_progress">
          <div class="bx_font font">必修</div>
          <el-progress :text-inside="true" :percentage="item.compulsoryper || 0" stroke-width="20"></el-progress>
          <span class="pivot_txt">{{ item.compulsoryHursed || 0 }}/{{ item.compulsoryPeriod || 0 }} 学时</span>
        </div>
        <div class="progress xx_progress">
          <div class="xx_font font">选修</div>
          <el-progress :text-inside="true" :percentage="item.electiveper || 0" stroke-width="20"></el-progress>
          <span class="pivot_txt">{{ item.electiveHursed || 0 }}/{{ item.electivesPeriod || 0 }} 学时</span>
        </div>
      </div>
    </div>
    <!-- 考核 -->
    <div class="dsf_nc_special_detail_complete_examine" v-if="homeworkId || examId">
      <div class="complete_title">
        <span class="font">考核</span>
        <span class="font des">学习进度需要达到100%</span>
      </div>
      <div class="complete_examine_desc">
        <!-- 小结 -->
        <!-- 0未报名、4待审核、没有作业都不显示 -->
        <!-- <div :class="['brief', 'btn_box', { khwc: homeworkState == 2 }]" v-if="isShowXiaojie && homeworkId && "> -->
        <div :class="['brief', 'btn_box', { khwc: homeworkState == 2 }, { gray: !examStateValue || isview }]" @click="!isview && examStateValue && clickBrief()" v-if="homeworkId">
          <dsf-icon name="xiaojie"></dsf-icon>
          <div class="btn">
            <span class="txt">{{ homework.fontBtn }}</span>
          </div>
          <!-- 分数制度： scheduleData.scoreType == 2 ；等级制 scheduleData.scoreType == 1-->
          <!-- <div class="brief_score score" v-if="homeworkState == 2">通过：{{ scheduleData.homeworkScore || "0" }}</div>
          <div class="brief_score score" v-else-if="homeworkState == -1 && scheduleData.homeworkScore">退回</div>
          <div class="brief_score score" v-else-if="homeworkState == 1">待批阅</div>
          <div class="brief_score score" v-else>待完成</div> -->
          <div class="brief_score score" :style="homework.color">{{ homework.font }}</div>
        </div>
        <!-- 考试 -->
        <div
          :class="['exam', 'btn_box', { khwc: examPass == 1 }, { gray: !examStateValue || isview }]"
          v-if="isShowExam && examId"
          @click="!isview && examStateValue && clickExam(scheduleData)"
        >
          <dsf-icon name="kaoshi"></dsf-icon>
          <div class="btn">
            <span class="txt">{{ exam.fontBtn }}</span>
          </div>
          <!-- <div class="exam_score score" v-if="examStateValue && examId">{{ scheduleData.score || "0" }}分</div>
          <div class="exam_score score" v-else>待完成</div> -->
          <div class="exam_score score" :style="exam.color">{{ exam.font }}</div>
        </div>
        <!-- 问卷 -->
        <!-- <div :class="['exam', 'btn_box', { gray: !examStateValue }]" v-if="false" @click="examStateValue && clickExam(scheduleData)">
          <dsf-icon name="kaoshi"></dsf-icon>
          <div class="btn">
            <span class="txt">填写问卷</span>
          </div>
          <div class="exam_score score" v-if="examStateValue && examId">{{ scheduleData.score || "0" }}分</div>
          <div v-else style="opacity: 0">通过：100分</div>
        </div> -->
      </div>
    </div>

    <!-- 证书 -->
    <div class="dsf_nc_special_detail_complete_certificate" v-if="showCertificate && certificateState">
      <!-- <div class="dsf_nc_special_detail_complete_certificate"> -->
      <div class="complete_title">
        <span class="font">证书</span>
        <span class="font des">通过专题班的学习及考核便有资格获得结业证书。</span>
      </div>
      <div class="complete_certificate">
        <DsfNcCredential :certificate-src="certificateSrc" :isDownLoad="isDownLoad" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default dsf.component({
  name: "DsfNcSpecialDetailComplete",
  ctrlCaption: "专题班详情结业",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/learning/assess",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    isShowXiaojie: {
      type: Boolean,
      default: false,
    },
    isShowExam: {
      type: Boolean,
      default: true,
    },
    // 报名地址
    examSingupUrl: {
      type: String,
      default: "exam/api/ksgl/kssz/append",
    },
    //详情地址
    examUrl: {
      type: String,
      default: "exam/api/sjgl/url",
    },
    showCheck: {
      type: Boolean,
      default: true,
    },
    showCertificate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      params: {
        id: "",
      },
      scheduleData: {},
      unitAssess: [],
      examStateValue: 0, // 考试和小结能不能点击 是否置灰
      certificateSrc: require("_nc/assets/imgs/nocredential.png"),
      isDownLoad: false,
      certificateUrl: "/nc/class/certificate",
      examId: "",
      examPass: 0,
      isShowHomework: false, // 作业页面
      isShowBriefSummary: false, // 小结页面
      showReadOrWrite: false, //小结显示只读或可写
      certificateState: false, //后台是否配置了证书
      homeworkId: "",
      homeworkName: "",
      homeworkDone: "", //是否完成作业
      briefsummaryUrl: "/nc/homework/assessment/writing",
      homeworkState: "", // -1 审核未通过,!-1 审核通过
      homeworkStudentId: "",
      homeworkTxt: "查看小结",
      examTxt: "开始考试",
      specialApplyUrl: "nc/class/info",
      specialDetailData: {},
      // dialogTitle: "撰写小结",
      isview: false,
    };
  },
  computed: {
    ...mapGetters(["getSpecialInfo"]),
    homework() {
      let params = {
        font: "",
        color: "",
        fontBtn: "",
      };
      if (this.scheduleData.homeworkStudentId) {
        if (this.scheduleData.homeworkState == 2) {
          //小结通过
          params.font = "通过：" + this.scheduleData.homeworkScore;
          params.color = "#69C33B";
          params.fontBtn = "查看小结";
        } else if (this.scheduleData.homeworkState == -1) {
          //小结被退回
          params.font = "退回";
          params.color = "#F72126";
          params.fontBtn = "查看小结";
        } else if (this.scheduleData.homeworkState == 1) {
          //小结待审核
          params.font = "待批阅";
          params.color = "#666666";
          params.fontBtn = "查看小结";
        } else {
          params.font = "待完成";
          params.color = "#666666";
          params.fontBtn = "撰写小结";
        }
      } else {
        params.font = "待完成";
        params.color = "#666666";
        params.fontBtn = "撰写小结";
      }
      return params;
    },
    exam() {
      let params = {
        font: "",
        color: "",
      };
      if (this.scheduleData.examId) {
        if (this.scheduleData.examPass == 1) {
          //考试通过
          params.font = this.scheduleData.score + "分";
          params.color = "#69C33B";
          params.fontBtn = "查看考试";
        } else {
          params.font = "待完成";
          params.color = "#666666";
          params.fontBtn = "开始考试";
        }
      } else {
        params.font = "待完成";
        params.color = "#666666";
        params.fontBtn = "开始考试";
      }
      return params;
    },
  },
  watch: {
    getSpecialInfo: {
      handler(data) {
        if (!this.isDesign && data) {
          this.params.id = this.queryString["id"] || "";
          this.specialDetailData = data;
          this.initData();
          if (dsf.getToken()) {
            this.certificateInitData();
          }
        }
        // console.log("结业tab====", this.specialDetailData);
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.isDesign) {
      this.isview = this.queryString.isview == 1 ? true : false;
      // this.initData();
      // this.certificateInitData();
    }
  },
  methods: {
    ...mapActions(["specialInfo"]),
    reData() {
      this.initData();
      this.certificateInitData();
      this.specialInfo(this.params);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.scheduleData = res.data;
            this.unitAssess = res.data.unitAssess.length ? res.data.unitAssess : [];
            this.examStateValue = Number(res.data.stateValue) || 0;
            this.examId = res.data.examId;
            this.examPass = Number(res.data.examPass) || 0;
            this.homeworkId = res.data.homeworkId;
            this.homeworkName = res.data.homeworkName;
            this.homeworkDone = res.data.homeworkDone;
            this.homeworkState = res.data.homeworkState;
            this.homeworkStudentId = res.data.homeworkStudentId;
            this.certificateState = res.data.certificateState;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    certificateInitData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.certificateUrl, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            if (data.length) {
              this.certificateSrc = data[0].certificateurl;
              if (!this.isview) {
                this.isDownLoad = true;
              }
            }
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickBrief() {
      // console.log("点击了小结按钮", url);
      // let dialogTitle = "";
      // if (this.homeworkState == 1 || this.homeworkState == 2 || this.homeworkState == -1) {
      //   dialogTitle = "查看小结";
      // } else {
      //   dialogTitle = "撰写小结";
      // }

      this.$openDialog({
        width: "620px",
        height: "auto",
        title: this.homework.fontBtn,
        content: "DsfNcHomeworkSummary",
        params: {
          state: this.homeworkState,
          homeworkId: this.homeworkId,
          studentId: this.homeworkStudentId,
          cb: data => {
            if (data) {
              this.reData();
            }
          },
        },
        dialogArgs: {},
      });
    },

    clickExam(data) {
      // 点击考试
      let id = data.examId;
      if (!id) return dsf.layer.message("暂无考试id", false);
      this.toExamDetail(id);
    },
    toExamDetail(id) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: id, //考试id
        businessId: this.params.id, //业务id
        businessType: 2, // 专题班考试： 考试类型2
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
  },
});
</script>
