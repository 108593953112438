<template>
  <div class="dsf_ncpc_class_photo">
    <div class="photo_title">{{ titleName }}</div>
    <div class="photo_content">
      <div class="top">
        <div class="count_box">
          <span>照片总数：{{ alibumData.photoCount || 0 }}张</span>
          <span>上传：{{ alibumData.userCount || 0 }}人</span>
        </div>
        <div :class="['upload_btn', classUnApply && !isManaged ? 'gray' : '']">
          <el-upload
            :class="['avatar_uploader', classUnApply && !isManaged ? 'gray' : '']"
            :action="actionUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :disabled="classUnApply && !isManaged"
          >
            上传照片
          </el-upload>
        </div>
      </div>
      <div class="photo_wrap">
        <div v-if="photoLists && photoLists.length" class="photo_wrap_box">
          <div class="photo_items" v-for="(item, index) in photoLists" :key="item._id + index">
            <div class="photo_item">
              <div class="detel" v-show="item['ipb_album_albumListData.mine'] || isManaged" @click="!classUnApply && clickDetele(item)">删除</div>
              <dsf-nc-image :src="item['ipb_album_albumListData.img'] | imgFormat" width="530px" height="280px" :error-src="dsf.config.setting_nc_image_defadivt_img" />
              <div class="footer">
                <div class="name ell">{{ item["ipb_album_albumListData.ds_create_user_name"] }}</div>
                <div class="date">{{ dsf.date.format(item["ipb_album_albumListData.ds_create_time"], "yyyy-mm-dd") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
        <div class="classphoto_page" :style="{ 'text-align': paginationPosition }">
          <el-pagination
            ref="pagination"
            :class="[paginationStyle]"
            :hide-on-single-page="false"
            :total="pageCount"
            :page-size="pageSize"
            :page-sizes="limits"
            :current-page.sync="currentPage"
            :layout="'slot,' + pagerLayout"
            @size-change="pageSizeChange"
            @current-change="pageSizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcClassPhoto",
  ctrlCaption: "班级照片",
  mixins: [$mixins.control, $mixins.buttonBar],
  props: {
    titleName: {
      type: String,
      default: "班级相册",
    },
    // 分页位置
    paginationPosition: {
      type: String,
      default: "right",
    },
    // 分页样式
    paginationStyle: {
      type: String,
      default: "style1",
    },
    //分页页码按钮最多显示个数
    pagerCount: {
      type: Number,
      default: 5,
    },
    //一页显示多少条记录
    pageSize: {
      type: Number,
      default: 8,
    },
    // 每页显示条数(逗号分隔)
    limits: {
      type: Array,
      default() {
        return [8, 16, 24, 32, 40, 50, 100];
      },
    },
    // 分页组建的布局,自定义分页排版(逗号分隔)
    pagerLayout: {
      type: String,
      default: "prev,pager,next,jumper",
    },
  },
  data() {
    return {
      actionUrl: "",
      currentPage: 1,
      pageCount: 0,
      params: {
        id: "",
        pageNum: 1,
        pageSize: 8,
        query: JSON.stringify({ searchValue: "" }),
        order: "[]",
        filter: "[]",
        namespace: "ipb.album",
        pageName: "photoAlbumList",
      },
      url: "meta/list/data",
      alibumUrl: "/nc/alibum/count",
      photoLists: [],
      defaultImg: require("../../../assets/imgs/SC1.png"),
      alibumData: {},
      imageUrl: "",
      classInfoData: {},
      classUnApply: false,
      isManaged: false,
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "c1d2842b372946739582040dc441ce69";
    this.params.pageSize = this.pageSize;
    this.isManaged = this.$route.query.manage == 1 || this.queryString["manage"] == 1 ? true : false;
  },
  mounted() {
    if (!this.isDesign) {
      this.actionUrl = `${$$webRoot.nc}file/upload/`;
      this.initData();
      this.getCount();
      this.getAliBum();
      this.classInfo();
    }
  },
  methods: {
    classInfo() {
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get("/nc/class/info", { id: this.params.id }, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            error(res);
          } else {
            this.classInfoData = data;
            this.classUnApply = data.state === 0;
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    pageSizeChange(evt) {
      this.params.pageNum = evt;
      this.initData();
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 4;

      if (!isJPG) {
        dsf.layer.message("上传头像图片只能是 JPG、JPEG、GIF、PNG 格式!", false);
      }
      if (!isLt2M) {
        dsf.layer.message("上传头像图片大小不能超过 4MB!", false);
      }
      return isJPG && isLt2M;
    },

    handleAvatarSuccess(res, file) {
      if (res.success) {
        this.imageUrl = JSON.stringify(res.data);
        this.uploadSave(this.imageUrl);
      }
    },
    uploadSave() {
      let params = {
        businessId: this.params.id,
        img: this.imageUrl,
      };
      dsf.http
        .post("/nc/alibum/save", params, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            dsf.layer.pc.message("上传成功", true);
            this.initData();
            this.getCount();
            this.getAliBum();
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {});
    },

    getAliBum() {
      let loading = dsf.layer.pc.loading();
      let params = {
        id: this.params.id,
      };
      dsf.http
        .get(this.alibumUrl, params, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.alibumData = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    getCount() {
      let loading = dsf.layer.pc.loading();
      let params = Object.assign({}, this.params, { pageSize: 99999 });
      dsf.http
        .get("meta/list/count", params, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.pageCount = data.count;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.photoLists = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickDetele(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deteleItem(item._id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deteleItem(ids) {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .post("/nc/alibum/delByIds", { ids }, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
            this.getCount();
            this.getAliBum();
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
