<template>
  <div class="dsf_nc_footer" :style="{ width: itemWidth, margin: margin }">
    <div class="nc-footer-inner">
      <div class="footer-top">
        <div class="footer-top-left">
          <dl>
            <dd v-for="item in faceInfo" :key="item.name">
              <span class="name">{{ item.name }}</span>
              <b class="total">{{ item.total }}</b>
            </dd>
          </dl>
        </div>
        <div class="footer-top-right">
          <div class="footer-top-right-text">
            <span>{{ phone }}</span>
            <span>{{ technology }}</span>
            <span>{{ address }}</span>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <template v-for="item in copyList">
          <span class="item" :key="item.title" @click="toPage(item)" v-if="item.title && item.title != ''">
            <i class="polic-icon" v-if="item.icon"></i>
            {{ item.title }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcFooter",
  ctrlCaption: "门户页脚",
  mixins: [$mixins.control],
  props: {
    itemWidth: {
      type: String,
      default: "100%",
    },
    margin: {
      type: String,
      default: "20px 0 0 0",
    },
    sourceUrl: {
      type: String,
      default: "nc/saas/count/home",
    },
    copyNumber: {
      type: String,
      default: "沪ICP备18014503号-5",
    },
    copyNumberSourceUrl: {
      type: String,
      default: "",
    },
    policeNumber: {
      type: String,
      default: "沪公网安备 31010602006053号",
    },
    policeNumberSourceUrl: {
      type: String,
      default: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006053",
    },
    levNumber: {
      type: String,
      default: "等级备案号：31010643029-21001",
    },
    levNumberSourceUrl: {
      type: String,
      default: "http://icp.chinaz.com/home/info?host=xuexibang.cn",
    },
    phone: {
      type: String,
      default: "座机：400-820-3302",
    },
    technology: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "地址：内蒙古呼和浩特市赛罕区敕勒川大街1号",
    },
  },
  components: {},
  data() {
    return {
      copyList: [
        {
          title: this.copyNumber,
          sourceUrl: this.copyNumberSourceUrl,
        },
        {
          title: this.policeNumber,
          sourceUrl: this.policeNumberSourceUrl,
          icon: true,
        },
        {
          title: this.levNumber,
          sourceUrl: this.levNumberSourceUrl,
        },
      ],
      faceInfo: [
        {
          name: "资源总量",
          total: 0,
        },
        {
          name: "今日点击访问量",
          total: 0,
        },
        {
          name: "历史点击访问总量",
          total: 0,
        },
      ],
    };
  },
  created() {},
  mounted() {
    !this.isDesign && this.initFooter();
  },
  methods: {
    toPage(it) {
      it.sourceUrl && window.open(it.sourceUrl);
    },
    initFooter() {
      const { sourceUrl } = this;
      dsf.http
        .get(sourceUrl, {}, $$webRoot.nc)
        .done(({ message, success, data }) => {
          if (!success) {
            error(message);
          } else {
            this.faceInfo = [
              {
                name: "资源总量",
                total: data.resourceNum,
              },
              {
                name: "今日点击访问量",
                total: data.todayView,
              },
              {
                name: "历史点击访问总量",
                total: data.totalView,
              },
            ];
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {});
      function error(message) {
        dsf.layer.pc.message(message, false);
      }
    },
  },
});
</script>
