var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds_homework" }, [
    _c("div", { staticClass: "ds_homework_inner" }, [
      _c(
        "div",
        {
          staticClass: "ds_homework_desc dsf_homework_shadow",
          style: { margin: _vm.$conmargin },
        },
        [
          _vm.item
            ? _c("div", { staticClass: "ds_homework_text" }, [
                _c("h3", { staticClass: "ds_homework_title" }, [
                  _vm._v(_vm._s(_vm.item.title)),
                ]),
                _c("h3", {
                  staticClass: "ds_homework_content",
                  domProps: { innerHTML: _vm._s(_vm.item.content) },
                }),
              ])
            : _vm._e(),
        ]
      ),
      !_vm.readOnly
        ? _c(
            "div",
            {
              staticClass: "ds_homework_handle",
              style: { width: _vm.menuWidth, margin: _vm.$handlemargin },
            },
            [
              _c("div", { staticClass: "ds_homework_handle_item" }, [
                _vm.item &&
                _vm.item.state_value != 2 &&
                _vm.item.state_value != -1
                  ? _c(
                      "div",
                      {
                        staticClass: "ds_homework_item_top dsf_homework_shadow",
                      },
                      [
                        _vm.homeItem
                          ? _c("div", { staticClass: "top_inner" }, [
                              _c("p", { staticClass: "name" }, [
                                _vm._v("批阅情况"),
                              ]),
                              _c("p", { staticClass: "state" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.homeItem.have_read_count || 0)
                                  ),
                                ]),
                                _vm._v(
                                  " /" +
                                    _vm._s(_vm.homeItem.all_count || 0) +
                                    " "
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "progress" },
                                [
                                  _c("el-progress", {
                                    attrs: { percentage: _vm.showPercent },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "ds_homework_item_bottom dsf_homework_shadow",
                  },
                  [
                    _vm.item
                      ? _c(
                          "div",
                          { staticClass: "bottom_inner" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "ruleForm",
                                attrs: {
                                  "label-position": _vm.labelPosition,
                                  "label-width": "80px",
                                  model: _vm.ruleForm,
                                  rules: _vm.rules,
                                  disabled:
                                    _vm.item.state_value == 2 ||
                                    _vm.item.state_value == -1,
                                },
                              },
                              [
                                _vm.item.score_type_value == 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "分数",
                                          size: "small",
                                          prop: "lv",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "请选择" },
                                            model: {
                                              value: _vm.ruleForm.lv,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleForm,
                                                  "lv",
                                                  $$v
                                                )
                                              },
                                              expression: "ruleForm.lv",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "优秀",
                                                value: "lv1",
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "良好",
                                                value: "lv2",
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "一般",
                                                value: "lv3",
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "较差",
                                                value: "lv4",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "分数",
                                          size: "small",
                                          prop: "rate",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.ruleForm.rate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "rate",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "ruleForm.rate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "评语",
                                      prop: "reviews",
                                      size: "small",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea" },
                                      model: {
                                        value: _vm.ruleForm.reviews,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "reviews", $$v)
                                        },
                                        expression: "ruleForm.reviews",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.item.state_value == 1
                              ? _c(
                                  "div",
                                  { staticClass: "bottom_btn" },
                                  [
                                    _c(
                                      "DsfButton",
                                      {
                                        staticClass: "btn-reback",
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handle(-1, "ruleForm")
                                          },
                                        },
                                      },
                                      [_vm._v("退回")]
                                    ),
                                    _c(
                                      "DsfButton",
                                      {
                                        staticClass: "btn-pass",
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handle(2, "ruleForm")
                                          },
                                        },
                                      },
                                      [_vm._v("通过")]
                                    ),
                                    _c(
                                      "DsfButton",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handle(3, "ruleForm")
                                          },
                                        },
                                      },
                                      [_vm._v("下一篇")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }