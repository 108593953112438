<template>
  <div class="dsf_learningPortfolio" v-if="visible">
    <div class="dsf_learningPortfolio_content">
      <div class="dsf_learningPortfolio-head">
        <div class="title">
          <span>学习档案，已陪伴你</span>
          <span class="date">
            <span>{{ resData[keys1.day] || 0 }}</span>
            天
          </span>
        </div>

        <div class="head-content">
          <div class="head-content-item">
            <!-- <div class="head-content-img">
              <dsf-icon class="dsf_learningPortfolio-icon" name="icon_ztbtj"></dsf-icon>
            </div> -->
            <div class="num-box">
              <p class="num">
                <span>{{ resData[keys1.special] || 0 }}</span>
                个
              </p>
              <p class="init">专题班总计</p>
            </div>
          </div>
          <div class="head-content-item">
            <!-- <div class="head-content-img">
              <dsf-icon class="dsf_learningPortfolio-icon" name="icon_xszj"></dsf-icon>
            </div> -->
            <div class="num-box">
              <p class="num">
                <span>{{ resData[keys1.statistics] || 0 }}</span>
                学时
              </p>
              <p class="init">学时总计</p>
            </div>
          </div>
          <div class="head-content-item">
            <!-- <div class="head-content-img">
              <svg class="iconfont" aria-hidden="true">
                <use xlink:href="#icon-icon_xxsczj"></use>
              </svg>
              <dsf-icon class="dsf_learningPortfolio-icon" name="icon_xxsczj"></dsf-icon>
            </div> -->
            <div class="num-box">
              <p class="num">
                <span>{{ formatterDate2(resData[keys1.time] || "0:0:00", "hh") }}</span>
                小时
                <span>{{ formatterDate2(resData[keys1.time] || "0:0:00", "mm") }}</span>
                分钟
              </p>
              <p class="init">学习时长总计</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dsf_learningPortfolio-content" :style="{ width: width }">
        <div class="dsf_learningPortfolio-content-nodata" v-if="dsf.isEmptyObject(resData) || !resData.items.length">
          <img :src="nodataImg" alt="" />
          <p class="p1">暂无学习档案</p>
          <p class="p2">学习档案在每年年度根据学习情况自动生成</p>
        </div>
        <div class="dsf_learningPortfolio-content-item" v-for="(item, index) in resData.items" :key="index" v-else>
          <div class="left">
            <p class="year">{{ item[keys2.year] || 0 }}</p>
            <div class="line-box">
              <div class="top">
                <div class="top-1"></div>
              </div>
              <div class="line"></div>
            </div>
          </div>
          <div class="right">
            <div class="top-content margin-bottom-30">
              <div class="time-box">
                <div class="time1" v-if="item[keys2.totalAll]">
                  <span>{{ item[keys2.totalAll] || "" }}</span>
                  <span>总学习时长</span>
                </div>
                <div class="time2" v-if="item[keys2.pcTotal]">
                  <span>{{ item[keys2.pcTotal] || "" }}</span>
                  <span>PC学习时长</span>
                </div>
                <div class="time3" v-if="item[keys2.appTotal]">
                  <span>{{ item[keys2.appTotal] || "" }}</span>
                  <span>APP学习时长</span>
                </div>
              </div>
            </div>
            <div class="right-item margin-bottom-30">
              <div class="title"><span>基本信息</span></div>
              <div class="right-item-content">
                <div class="baseInfo-content-top margin-bottom-20">
                  <div class="item" v-if="item[keys3.No]">档案编号：{{ item[keys3.No] }}</div>
                  <div class="item" v-if="item[keys3.dossierStartTime]">建档时间：{{ formatterDate3(item[keys3.dossierStartTime], "yyyy-mm-dd") }}</div>
                  <div class="item" v-if="item[keys3.dossierEndTime]">最后更新时间：{{ formatterDate3(item[keys3.dossierEndTime], "yyyy-mm-dd") }}</div>
                </div>
                <div class="baseInfo-user margin-bottom-20">
                  <div class="item" v-if="item[keys3.name]">姓名：{{ item[keys3.name] }}</div>
                  <div class="item" v-if="item[keys3.userSex]">性别：{{ item[keys3.userSex] }}</div>
                  <div class="item" v-if="item[keys3.userBirthday]">出生年月：{{ item[keys3.userBirthday] }}</div>
                </div>
                <div class="baseInfo-detail">
                  <div class="item" v-if="item[keys3.userUnit]">所属单位：{{ item[keys3.userUnit] }}</div>
                  <div class="item" v-if="item[keys3.userJobTitle]">干部类别：{{ item[keys3.userJobTitle] }}</div>
                  <div class="item" v-if="item[keys3.userJob]">职务：{{ item[keys3.userJob] }}</div>
                  <div class="item" v-if="item[keys3.userJobGrade]">职级：{{ item[keys3.userJobGrade] }}</div>
                </div>
              </div>
            </div>
            <div class="right-item margin-bottom-30">
              <div class="title"><span>学习情况</span></div>
              <div class="right-item-content study-box">
                <div class="row-1">
                  <div class="learning-item" @click="goDetail(keysMap4[0].suffix, item, '专题班列表')">
                    <div class="content">
                      <p class="p1">
                        <span class="big">{{ item[keys4.classFinishedNum] || 0 }}</span>
                        <span class="big">/{{ item[keys4.classFinishedPeriod] || 0 }}</span>
                      </p>
                      <p class="p2">专题班 (个/学时)</p>
                    </div>
                    <div class="icon icon_ztb"></div>
                  </div>
                  <div class="learning-item" @click="goDetail(keysMap4[2].suffix, item, '课程列表')">
                    <div class="content">
                      <p class="p1">
                        <span class="big">{{ item[keys4.courseFinishedNum] || 0 }}</span>
                        <span class="big">/{{ item[keys4.courseFinishedPeriod] || 0 }}</span>
                      </p>
                      <p class="p2">课程 (个/学时)</p>
                    </div>
                    <div class="icon icon_kc"></div>
                  </div>
                  <div class="learning-item" @click="goDetail(keysMap4[4].suffix, item, '证书列表')">
                    <div class="content">
                      <p class="p1">
                        <span class="big">{{ item[keys4.certificateFinishedNum] || 0 }}</span>

                        <span class="big" v-if="item[keys4.certificateHours]">/{{ item[keys4.certificateHours] || 0 }}</span>
                        <span v-if="item[keys4.certificateHours]">学时</span>
                      </p>
                      <p class="p2">证书 (个)</p>
                    </div>
                    <div class="icon icon_zhengshu"></div>
                  </div>
                  <!-- <div class="learning-item" @click="goDetail(keysMap4[6].suffix,item,'问卷列表')">
                    <div class="content">
                      <p class="p1"> <span class="big">{{ item[keys4.questionnaireFinishedNum] || 0 }}</span></p>
                      <p class="p2">

                        问卷 (个)
                      </p>
                    </div><div class="icon">
                      <dsf-icon class="dsf_learningPortfolio-icon" name="wenjuan1"></dsf-icon>
                    </div>
                  </div> -->
                </div>
                <div class="row-2">
                  <div class="learning-item" @click="goDetail(keysMap4[7].suffix, item, '直播列表')">
                    <div class="content">
                      <p class="p1">
                        <span class="big">{{ item[keys4.liveFinishedNum] || 0 }}</span>
                        <!-- <span class="big">/{{ item[keys4.liveFinishedPeriod] || 0 }}</span>
                        个 -->
                      </p>
                      <p class="p2">直播 (个)</p>
                    </div>
                    <div class="icon icon_live"></div>
                  </div>
                  <div class="learning-item" @click="goDetail(keysMap4[9].suffix, item, '频道列表')">
                    <div class="content">
                      <p class="p1">
                        <span class="big">{{ item[keys4.channelFinishedNum] || 0 }}</span>

                        <!-- <span class="big">/{{ item[keys4.channelFinishedPeriod] || 0 }}</span>
                        个 -->
                      </p>
                      <p class="p2">频道 (个)</p>
                    </div>
                    <div class="icon icon_channel"></div>
                  </div>
                  <div class="learning-item" @click="goDetail(keysMap4[11].suffix, item, '考试列表')">
                    <div class="content">
                      <p class="p1">
                        <span class="big">{{ item[keys4.examFinishedNum] || 0 }}</span>

                        <span class="big" v-if="item[keys4.examHours]">/{{ item[keys4.examHours] || 0 }}</span>
                        <span v-if="item[keys4.examHours]">学时</span>
                      </p>
                      <p class="p2">考试 (个)</p>
                    </div>
                    <div class="icon icon_exam"></div>
                  </div>
                  <!-- <div class="learning-item" @click="goDetail(keysMap4[13].suffix,item,'评论列表')">
                    <div class="content">
                      <p class="p1"> <span class="big">{{ item[keys4.commentFinishedNum] || 0 }}</span></p>
                      <p class="p2">评论(个)
                      </p>
                    </div><div class="icon">
                      <dsf-icon class="dsf_learningPortfolio-icon" name="pinglun1"></dsf-icon>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="right-item result margin-bottom-20">
              <div class="title"><span>学习结果</span></div>
              <div class="right-item-content">
                <div class="finish result-item">{{ item[keys5.requiredIsGoThrough] ? "完成" : "未完成" }}</div>
                <div class="center">
                  <div class="result-item">
                    <span class="span1">
                      必修专题班：
                      <span class="span2 time1">{{ item[keys5.requiredClassFinishedNum] || 0 }}</span>
                      /{{ item[keys5.requiredClassAllNum] || 0 }}
                    </span>
                  </div>
                  <div class="result-item">
                    <span class="span1">
                      必修课程：
                      <span class="span2 time2">{{ item[keys5.requiredCourseFinishedNum] || 0 }}</span>
                      /{{ item[keys5.requiredCourseAllNum] || 0 }}
                    </span>
                  </div>
                  <div class="result-item">
                    <span class="span1">
                      课程学时：
                      <span class="span2 time3">{{ item[keys5.requiredPeriodFinishedNum] || 0 }}</span>
                      /{{ item[keys5.requiredPeriodAllNum] || 0 }}
                    </span>
                  </div>
                  <div class="result-item isFinish">
                    <div class="isFinish-icon icon_finish" v-if="item[keys5.requiredIsGoThrough]">
                      <dsf-icon class="dsf_learningPortfolio-icon" name="icon_ytg"></dsf-icon>
                    </div>
                    <div v-else class="isFinish-icon icon_fail"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcLearningPortfolio",
  ctrlCaption: "学习档案",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "1200px",
    },
    reqUrl: {
      type: String,
      default: "nc/record/dossier/queryDossierMuster",
    },
    keysMap: {
      type: Array,
      default: () => {
        //  label: "必修专题班", prefix: "1", suffix: "2"
        return [
          { from: "userCreateDays", to: "day", type: "text", desc: "陪伴天数" },
          { from: "classFinishedCnt", to: "special", type: "text", desc: "专题统计" },
          { from: "periodFinishedCnt", to: "statistics", type: "text", desc: "学时统计" },
          { from: "durationFinishedCnt", to: "time", type: "text", desc: "学习时长" },
        ];
      },
    },
    keysMap2: {
      type: Array,
      default: () => {
        return [
          { from: "dossierYear", to: "year", type: "text", desc: "年份" },
          { from: "allDurationCnt", to: "totalAll", type: "text", desc: "总学习时长" },
          { from: "pcDurationCnt", to: "pcTotal", type: "text", desc: "PC学习时长" },
          { from: "mobileDurationCnt", to: "appTotal", type: "text", desc: "APP学习时长" },
        ];
      },
    },
    keysMap3: {
      type: Array,
      default: () => {
        return [
          { from: "dossierCode", to: "No", type: "text", desc: "档案编号" },
          { from: "dossierStartTime", to: "dossierStartTime", type: "text", desc: "建档时间" },
          { from: "dossierEndTime", to: "dossierEndTime", type: "text", desc: "最后更新时间" },
          { from: "userName", to: "name", type: "text", desc: "姓名" },
          { from: "userSex", to: "userSex", type: "text", desc: "性别" },
          { from: "userBirthday", to: "userBirthday", type: "text", desc: "出生年月" },
          { from: "userUnit", to: "userUnit", type: "text", desc: "所属单位" },
          { from: "userJobTitle", to: "userJobTitle", type: "text", desc: "干部类别" },
          { from: "userJob", to: "userJob", type: "text", desc: "职务" },
          { from: "userJobGrade", to: "userJobGrade", type: "text", desc: "职级" },
        ];
      },
    },
    keysMap4: {
      type: Array,
      default: () => {
        return [
          { from: "classFinishedNum", to: "classFinishedNum", type: "text", desc: "专题班个数", suffix: "" },
          { from: "classFinishedPeriod", to: "classFinishedPeriod", type: "text", desc: "专题班总学时" },
          { from: "courseFinishedNum", to: "courseFinishedNum", type: "text", desc: "课程个数", suffix: "" },
          { from: "courseFinishedPeriod", to: "courseFinishedPeriod", type: "text", desc: "课程总学时" },
          { from: "certificateFinishedNum", to: "certificateFinishedNum", type: "text", desc: "证书个数", suffix: "" },
          { from: "certificateHours", to: "certificateHours", type: "text", desc: "证书学时" },
          { from: "questionnaireFinishedNum", to: "questionnaireFinishedNum", type: "text", desc: "问卷", suffix: "" },
          { from: "liveFinishedNum", to: "liveFinishedNum", type: "text", desc: "直播个数", suffix: "" },
          { from: "liveFinishedPeriod", to: "liveFinishedPeriod", type: "text", desc: "直播总学时" },
          { from: "channelFinishedNum", to: "channelFinishedNum", type: "text", desc: "频道个数", suffix: "" },
          { from: "channelFinishedPeriod", to: "channelFinishedPeriod", type: "text", desc: "频道总学时" },
          { from: "examFinishedNum", to: "examFinishedNum", type: "text", desc: "考试个数", suffix: "" },
          { from: "examHours", to: "examHours", type: "text", desc: "考试总学时" },
          { from: "commentFinishedNum", to: "commentFinishedNum", type: "text", desc: "评论", suffix: "" },
        ];
      },
    },
    keysMap5: {
      type: Array,
      default: () => {
        return [
          { from: "requiredIsGoThrough", to: "requiredIsGoThrough", type: "text", desc: "是否完成" },
          { from: "requiredClassFinishedNum", to: "requiredClassFinishedNum", type: "text", desc: "完成专题班" },
          { from: "requiredClassAllNum", to: "requiredClassAllNum", type: "text", desc: "必修专题班" },
          { from: "requiredCourseFinishedNum", to: "requiredCourseFinishedNum", type: "text", desc: "完成课程" },
          { from: "requiredCourseAllNum", to: "requiredCourseAllNum", type: "text", desc: "必修课程" },
          { from: "requiredPeriodFinishedNum", to: "requiredPeriodFinishedNum", type: "text", desc: "完成课程学时" },
          { from: "requiredPeriodAllNum", to: "requiredPeriodAllNum", type: "text", desc: "课程总学时" },
        ];
      },
    },
  },
  data() {
    return {
      nodataImg: require("../../../assets/imgs/pcimg/learningPortfolio/img_default.png"),
      resData: {},
      keys1: {},
      keys2: {},
      keys3: {},
      keys4: {},
      keys5: {},
    };
  },
  components: {},

  mounted() {
    this.keysMap.map(item => {
      this.keys1[item.to] = item.from;
    });
    this.keysMap2.map(item => {
      this.keys2[item.to] = item.from;
    });
    this.keysMap3.map(item => {
      this.keys3[item.to] = item.from;
    });
    this.keysMap4.map(item => {
      this.keys4[item.to] = item.from;
    });

    this.keysMap5.map(item => {
      this.keys5[item.to] = item.from;
    });
    if (this.isDesign) {
      this.resData = {
        userCreateDays: 236,
        classFinishedCnt: 2,
        periodFinishedCnt: 33,
        durationFinishedCnt: "222:12:22",
        items: [
          {
            dossierYear: 2021,
            allDurationCnt: "1:12:22",
            pcDurationCnt: "3:12:22",
            mobileDurationCnt: "123:12:22",
            dossierCode: "XXd122322",
            dossierStartTime: "2020-1-2",
            dossierEndTime: "2021-1-2",
            userName: "张三",
            userSex: "男",
            userBirthday: "1999-9-9",
            userUnit: "梦创双杨",
            userJobTitle: "处长",
            userJob: "xxxxxx",
            userJobGrade: "xxxxxx",
            classFinishedNum: "5",
            classFinishedPeriod: "20",
            courseFinishedNum: "3",
            courseFinishedPeriod: "15",
            certificateFinishedNum: "2",
            certificateHours: "53",
            questionnaireFinishedNum: "2",
            liveFinishedNum: "66",
            liveFinishedPeriod: "1",
            channelFinishedNum: "34",
            channelFinishedPeriod: "6",
            examFinishedNum: "5",
            examHours: "23",
            commentFinishedNum: "5",
            requiredIsGoThrough: "1",
            requiredClassFinishedNum: "40",
            requiredClassAllNum: "40",
            requiredCourseFinishedNum: "30",
            requiredCourseAllNum: "30",
            requiredPeriodFinishedNum: "50",
            requiredPeriodAllNum: "50",
          },
        ],
      };
    } else {
      this.getData();
    }
  },
  methods: {
    formatterDate(time) {
      if (!time) return "";
      time = time.replace(":", "时").replace(":", "分") + "秒";
      return time;
    },
    formatterDate2(time, format) {
      if (!time) return "";
      let arr = time.split(":");
      if (format === "hh") {
        return arr[0];
      }
      if (format === "mm") {
        return arr[1];
      }
    },
    formatterDate3(time, format) {
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    getData() {
      if (this.reqUrl) {
        const loading = dsf.layer.loading();
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              this.resData = res.data;
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      }
    },
    goDetail(url, item, title) {
      console.log(url);
      if (this.$listeners["click"]) {
        this.$dispatch("click", { url, item, title });
      } else {
        if (url) {
          let _url = this.$replace(url, item);
          this.$open({ url: _url, state: 3, title });
        }
      }
    },
  },
});
</script>
