var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_special_personal_information_form",
      staticStyle: { "max-height": "600px" },
    },
    [
      _c(
        "div",
        { staticClass: "dsf_special_person_infor_box" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "80px",
                disabled: _vm.isview,
              },
            },
            [
              _vm._l(_vm.fromItems, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: index,
                    attrs: { prop: item.rule },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", [_vm._v(_vm._s(item.name) + "：")]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    item.type == "textbox" && item.rule != "age"
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: { placeholder: item.text },
                              model: {
                                value: _vm.form[item.rule],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, item.rule, $$v)
                                },
                                expression: "form[item.rule]",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "ouaselect"
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: { placeholder: item.text },
                              model: {
                                value: _vm.form[item.rule],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, item.rule, $$v)
                                },
                                expression: "form[item.rule]",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "areaselect"
                      ? _c(
                          "div",
                          [
                            item.rule == "address"
                              ? _c("el-input", {
                                  attrs: { placeholder: item.text },
                                  model: {
                                    value: _vm.form[item.rule],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, item.rule, $$v)
                                    },
                                    expression: "form[item.rule]",
                                  },
                                })
                              : _vm._e(),
                            item.rule == "city"
                              ? _c("DsfAreaSelect", {
                                  attrs: {
                                    range: ["0", "1"],
                                    showLabel: false,
                                  },
                                  model: {
                                    value: _vm.areaselectList,
                                    callback: function ($$v) {
                                      _vm.areaselectList = $$v
                                    },
                                    expression: "areaselectList",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "uploadphoto"
                      ? _c(
                          "div",
                          [
                            _c("DsfUploadPhoto", {
                              attrs: { showLabel: false },
                              model: {
                                value: _vm.form[item.rule],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, item.rule, $$v)
                                },
                                expression: "form[item.rule]",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "uploadimg"
                      ? _c(
                          "div",
                          [
                            item.rule == "image1"
                              ? _c("DsfUploadImg", {
                                  attrs: {
                                    showLabel: false,
                                    readOnly: _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.image1,
                                    callback: function ($$v) {
                                      _vm.image1 = $$v
                                    },
                                    expression: "image1",
                                  },
                                })
                              : _vm._e(),
                            item.rule == "image2"
                              ? _c("DsfUploadImg", {
                                  attrs: { showLabel: false },
                                  model: {
                                    value: _vm.image2,
                                    callback: function ($$v) {
                                      _vm.image2 = $$v
                                    },
                                    expression: "image2",
                                  },
                                })
                              : _vm._e(),
                            item.rule == "image3"
                              ? _c("DsfUploadImg", {
                                  attrs: { showLabel: false },
                                  model: {
                                    value: _vm.image3,
                                    callback: function ($$v) {
                                      _vm.image3 = $$v
                                    },
                                    expression: "image3",
                                  },
                                })
                              : _vm._e(),
                            item.rule == "image4"
                              ? _c("DsfUploadImg", {
                                  attrs: { showLabel: false },
                                  model: {
                                    value: _vm.image4,
                                    callback: function ($$v) {
                                      _vm.image4 = $$v
                                    },
                                    expression: "image4",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.rule == "sex"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "0" },
                                model: {
                                  value: _vm.form[item.rule],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, item.rule, $$v)
                                  },
                                  expression: "form[item.rule]",
                                },
                              },
                              [_vm._v("男")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.form[item.rule],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, item.rule, $$v)
                                  },
                                  expression: "form[item.rule]",
                                },
                              },
                              [_vm._v("女")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.rule == "age"
                      ? _c(
                          "div",
                          [
                            _c("el-input-number", {
                              attrs: {
                                placeholder: item.text,
                                min: 1,
                                max: 200,
                                step: 1,
                                "step-strictly": "",
                              },
                              model: {
                                value: _vm.form[item.rule],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, item.rule, $$v)
                                },
                                expression: "form[item.rule]",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "checkbox"
                      ? _c("div", [
                          item.rule == "checkbox1"
                            ? _c(
                                "div",
                                { staticClass: "checbox" },
                                _vm._l(item.values, function (it, idx) {
                                  return _c(
                                    "el-checkbox-group",
                                    {
                                      key: idx + "checkbox",
                                      model: {
                                        value: _vm.checkbox1,
                                        callback: function ($$v) {
                                          _vm.checkbox1 = $$v
                                        },
                                        expression: "checkbox1",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: it.value,
                                            checked: it.check,
                                          },
                                        },
                                        [_vm._v(_vm._s(it.text))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          item.rule == "checkbox2"
                            ? _c(
                                "div",
                                { staticClass: "checbox" },
                                _vm._l(item.values, function (it, idx) {
                                  return _c(
                                    "el-checkbox-group",
                                    {
                                      key: idx + "checkbox",
                                      model: {
                                        value: _vm.checkbox2,
                                        callback: function ($$v) {
                                          _vm.checkbox2 = $$v
                                        },
                                        expression: "checkbox2",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: it.value,
                                            checked: it.check,
                                          },
                                        },
                                        [_vm._v(_vm._s(it.text))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          item.rule == "checkbox3"
                            ? _c(
                                "div",
                                { staticClass: "checbox" },
                                _vm._l(item.values, function (it, idx) {
                                  return _c(
                                    "el-checkbox-group",
                                    {
                                      key: idx + "checkbox",
                                      model: {
                                        value: _vm.checkbox3,
                                        callback: function ($$v) {
                                          _vm.checkbox3 = $$v
                                        },
                                        expression: "checkbox3",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: it.value,
                                            checked: it.check,
                                          },
                                        },
                                        [_vm._v(_vm._s(it.text))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          item.rule == "checkbox4"
                            ? _c(
                                "div",
                                { staticClass: "checbox" },
                                _vm._l(item.values, function (it, idx) {
                                  return _c(
                                    "el-checkbox-group",
                                    {
                                      key: idx + "checkbox",
                                      model: {
                                        value: _vm.checkbox4,
                                        callback: function ($$v) {
                                          _vm.checkbox4 = $$v
                                        },
                                        expression: "checkbox4",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: it.value,
                                            checked: it.check,
                                          },
                                        },
                                        [_vm._v(_vm._s(it.text))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    item.type == "radiobox"
                      ? _c(
                          "div",
                          { staticClass: "radiobox" },
                          _vm._l(item.values, function (it, idx) {
                            return _c(
                              "el-radio-group",
                              {
                                key: idx + "radiobox",
                                model: {
                                  value: _vm.form[item.rule],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, item.rule, $$v)
                                  },
                                  expression: "form[item.rule]",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: String(it.value) } },
                                  [_vm._v(_vm._s(it.text))]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              !_vm.isview
                ? _c(
                    "el-form-item",
                    { staticClass: "submit_btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btns",
                          on: {
                            click: function ($event) {
                              return _vm.cancel("form")
                            },
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btns",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.onSubmit("form")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }