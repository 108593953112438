var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle margint20 marginb20" }, [
        _vm._v("预览效果"),
      ]),
      _c(
        "headerOne",
        { attrs: { menuList: _vm.menuList, logoImg: _vm.logoImg } },
        [
          _c(
            "div",
            { staticClass: "style_use" },
            [
              _c("b", [_vm._v("经典风格")]),
              _c("span", [_vm._v("经典风格页头，适用于各种网院样式。")]),
              _c(
                "dsf-button",
                {
                  attrs: { disabled: _vm.useType == "1", type: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.toggle("1")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.useType == "1" ? "使用中" : "应用"))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "headerTwo",
        { attrs: { menuList: _vm.menuList, logoImg: _vm.logoImg } },
        [
          _c(
            "div",
            { staticClass: "style_use" },
            [
              _c("b", [_vm._v("简约风格")]),
              _c("span", [
                _vm._v("居中式简洁页头，简洁大方导航上增加了搜索功能。"),
              ]),
              _c(
                "dsf-button",
                {
                  attrs: { disabled: _vm.useType == "2", type: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.toggle("2")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.useType == "2" ? "使用中" : "应用"))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }