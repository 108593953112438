var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "dsf_ncpc_navmenus",
          style: { width: _vm.itemWidth, margin: _vm.controlStyle },
        },
        [
          _vm.dataSource == "json" || _vm.isDesign
            ? _c(
                "ul",
                { staticClass: "common_wrap" },
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "common_items",
                      style: _vm.itemStyle,
                      on: {
                        click: function ($event) {
                          return _vm.clickItem(item)
                        },
                      },
                    },
                    [
                      _c("i", { class: "icon iconfont " + item.icon }),
                      _c("div", { staticClass: "font" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.dataSource == "http"
            ? _c(
                "ul",
                { staticClass: "common_wrap" },
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "common_items",
                      style: _vm.itemStyle,
                      on: {
                        click: function ($event) {
                          return _vm.clickItem(item)
                        },
                      },
                    },
                    [
                      _c("i", { class: "icon iconfont " + item.icon }),
                      _c("div", { staticClass: "font" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }