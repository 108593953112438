var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_special_detail_course_item" }, [
    _vm.lists.length
      ? _c(
          "ul",
          { staticClass: "nc_detail_wrap" },
          _vm._l(_vm.lists, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                class: ["nc_detail_item", { gjms: item.advancedMode }],
              },
              [
                _c("div", { staticClass: "detail_top" }, [
                  _c("div", { staticClass: "title_box" }, [
                    _c("div", { staticClass: "item_title" }, [
                      _vm._v(_vm._s(item.unitName)),
                    ]),
                    _c("span", { staticClass: "font" }, [
                      _vm._v(
                        "   (选修: " +
                          _vm._s(item.electives || 0) +
                          "门/" +
                          _vm._s(item.electivesPeriod || 0) +
                          "学时  必修: " +
                          _vm._s(item.compulsory || 0) +
                          "门/" +
                          _vm._s(item.compulsoryPeriod || 0) +
                          "学时) "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "item_top_right",
                      on: {
                        click: function ($event) {
                          return _vm.clickFold(index)
                        },
                      },
                    },
                    [
                      !item.fold
                        ? _c("span", { staticClass: "font" }, [_vm._v("展开")])
                        : _c("span", { staticClass: "font" }, [_vm._v("收起")]),
                      item.fold
                        ? _c("span", {
                            staticClass: "icon iconfont icon-xia1 sq",
                          })
                        : _vm._e(),
                      !item.fold
                        ? _c("span", {
                            staticClass: "icon iconfont icon-shang1 sq",
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
                item.fold && item.advancedMode
                  ? _c(
                      "div",
                      { staticClass: "detail_desc" },
                      _vm._l(item.subList, function (items, idx) {
                        return _c(
                          "div",
                          { key: items.subId, staticClass: "detail_desc_item" },
                          [
                            _c("div", { staticClass: "detail_desc_left" }, [
                              _c(
                                "span",
                                { class: ["type_name", items.typeValue] },
                                [_vm._v(_vm._s(items.typeText))]
                              ),
                              _vm._v("   "),
                              _c("div", { staticClass: "type_category_box" }, [
                                _c(
                                  "span",
                                  { staticClass: "type_category ell" },
                                  [
                                    _vm._v(
                                      "【" +
                                        _vm._s(items.categoryText) +
                                        "】" +
                                        _vm._s(items.name)
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: items.name.length > 15,
                                        expression: "items.name.length > 15",
                                      },
                                    ],
                                    staticClass: "type_category_hover",
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "date font",
                                  style:
                                    "opacity:" +
                                    (items.timeSdate && items.timeEdate
                                      ? "1"
                                      : "0"),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dsf.date.format(
                                          items.timeSdate,
                                          "yyyy-mm-dd hh:ii"
                                        ) || ""
                                      ) +
                                      " 至 " +
                                      _vm._s(
                                        _vm.dsf.date.format(
                                          items.timeEdate,
                                          "yyyy-mm-dd hh:ii"
                                        ) || ""
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "wcxs font" }, [
                                _vm._v(_vm._s(items.period || "0") + "学时"),
                              ]),
                              _c("div", { staticClass: "teacher font ell" }, [
                                _vm._v(_vm._s(items.teacher || "-")),
                              ]),
                              _c("div", { staticClass: "progress" }, [
                                _c("span", { staticClass: "font" }, [
                                  _vm._v(
                                    "已学: " + _vm._s(items.progress || 0) + "%"
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "detail_desc_right" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "enter_btn",
                                  style:
                                    "" +
                                    (_vm.itemStatus(
                                      items,
                                      item,
                                      idx,
                                      idx > 0
                                        ? item.subList[idx - 1].progress
                                        : null
                                    ) &&
                                    items.playState &&
                                    !_vm.isview
                                      ? ""
                                      : "color: #aaa; cursor: default"),
                                  on: {
                                    click: function ($event) {
                                      _vm.showClick &&
                                        items.playState &&
                                        _vm.clickEnter(
                                          items,
                                          item.classId,
                                          item,
                                          idx,
                                          idx > 0
                                            ? item.subList[idx - 1].progress
                                            : null
                                        )
                                    },
                                  },
                                },
                                [_vm._v(" 进入 ")]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : item.fold && !item.advancedMode
                  ? _c(
                      "div",
                      { staticClass: "detail_desc" },
                      _vm._l(item.subList, function (items, idx) {
                        return _c(
                          "div",
                          { key: items.subId, staticClass: "detail_desc_item" },
                          [
                            _c("div", { staticClass: "detail_desc_left" }, [
                              _c(
                                "span",
                                { class: ["type_name", items.typeValue] },
                                [_vm._v(_vm._s(items.typeText))]
                              ),
                              _vm._v("   "),
                              _c("div", { staticClass: "type_category_box" }, [
                                _c(
                                  "span",
                                  { staticClass: "type_category ell" },
                                  [_vm._v(_vm._s(items.name))]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: items.name.length > 15,
                                        expression: "items.name.length > 15",
                                      },
                                    ],
                                    staticClass: "type_category_hover",
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  class: [
                                    "font",
                                    !!items.categoryValue &&
                                    Number(items.categoryValue)
                                      ? "bx"
                                      : "xx",
                                  ],
                                },
                                [_vm._v(_vm._s(items.categoryText))]
                              ),
                              _c("div", { staticClass: "duration_box" }, [
                                _c(
                                  "div",
                                  { staticClass: "font duration ell" },
                                  [_vm._v(_vm._s(_vm.setDuration(items)))]
                                ),
                                items.typeValue == "live" ||
                                items.typeValue == "exam"
                                  ? _c(
                                      "div",
                                      {
                                        class: [
                                          "duration_hover",
                                          {
                                            duration_none:
                                              _vm.setDuration(items) == "-",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.setDuration(items)) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "wcxs font" }, [
                                _vm._v(_vm._s(items.period || "0") + "学时"),
                              ]),
                              _c("div", { staticClass: "teacher font ell" }, [
                                _vm._v(_vm._s(items.teacher || "-")),
                              ]),
                              _c(
                                "div",
                                { staticClass: "progress" },
                                [
                                  _c("el-progress", {
                                    attrs: {
                                      percentage: items.progress || 0,
                                      "stroke-width": "10",
                                      "show-text": false,
                                    },
                                  }),
                                  _c("span", { staticClass: "pivot-txt" }, [
                                    _vm._v(_vm._s(items.progress || 0) + "%"),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "detail_desc_right" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "enter_btn",
                                  style:
                                    "" +
                                    (_vm.itemStatus(
                                      items,
                                      item,
                                      idx,
                                      idx > 0
                                        ? item.subList[idx - 1].progress
                                        : null
                                    ) &&
                                    items.playState &&
                                    !_vm.isview
                                      ? ""
                                      : "color: #aaa; cursor: default"),
                                  on: {
                                    click: function ($event) {
                                      _vm.showClick &&
                                        items.playState &&
                                        _vm.clickEnter(
                                          items,
                                          item.classId,
                                          item,
                                          idx,
                                          idx > 0
                                            ? item.subList[idx - 1].progress
                                            : null
                                        )
                                    },
                                  },
                                },
                                [_vm._v(" 进入 ")]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _c("div", {
          staticClass: "ds-empty-data",
          domProps: { textContent: _vm._s("暂无数据") },
        }),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            staticClass: "face",
            attrs: {
              title: "提示",
              visible: _vm.showFace1,
              "show-close": false,
              "close-on-click-modal": false,
              "modal-append-to-body": true,
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showFace1 = $event
              },
              beforeClose: _vm.beforeClose,
            },
          },
          [
            [
              _c("div", { staticClass: "faceQrcode" }, [
                _c("img", { attrs: { src: _vm.socketImg, alt: "" } }),
              ]),
              _c("div", { staticClass: "desc" }, [_vm._v("扫一扫人脸识别")]),
            ],
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }