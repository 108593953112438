var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c(
        "div",
        {
          staticClass: "dsf_nc_footer",
          style: { width: _vm.itemWidth, margin: _vm.margin },
        },
        [
          _c("div", { staticClass: "nc-footer-inner" }, [
            _c("div", { staticClass: "footer-top" }, [
              _c("div", { staticClass: "footer-top-left" }, [
                _c(
                  "dl",
                  _vm._l(_vm.faceMapping, function (item) {
                    return _c("dd", { key: item.name }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("b", { staticClass: "total" }, [
                        _vm._v(_vm._s(item.total)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "footer-bottom" }, [
              _c(
                "span",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.toPage(_vm.copyNumberSourceUrl)
                    },
                  },
                },
                [_vm._v("沪ICP备18014503号-5")]
              ),
              _c(
                "span",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.toPage(_vm.policeNumberSourceUrl)
                    },
                  },
                },
                [
                  _vm.data.policeInternetImgOne
                    ? _c("img", {
                        staticClass: "police-icon-new",
                        attrs: {
                          src: JSON.parse(_vm.data.policeInternetImgOne || "{}")
                            .relativePath,
                        },
                      })
                    : _c("i", { staticClass: "polic-icon" }),
                  _vm._v(" 沪公网安备 31010602006053号 "),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.toPage(_vm.levNumberSourceUrl)
                    },
                  },
                },
                [_vm._v("等级备案号：31010643029-21001")]
              ),
            ]),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "dsf_nc_footer",
          style: { width: _vm.itemWidth, margin: _vm.margin },
        },
        [
          _vm.data
            ? _c("div", { staticClass: "nc-footer-inner" }, [
                _c("div", { staticClass: "footer-top" }, [
                  _c("div", { staticClass: "footer-top-left" }, [
                    _c(
                      "dl",
                      _vm._l(_vm.renderFaceInfo, function (item) {
                        return _c("dd", { key: item.name }, [
                          _c("span", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c("b", { staticClass: "total" }, [
                            _vm._v(_vm._s(item.total)),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "footer-top-right" }, [
                    _c("div", { staticClass: "footer-top-right-text" }, [
                      _vm.data.companyPhone
                        ? _c("span", [
                            _vm._v("座机：" + _vm._s(_vm.data.companyPhone)),
                          ])
                        : _vm._e(),
                      _vm.data.addressOne
                        ? _c("span", [
                            _vm._v("地址：" + _vm._s(_vm.data.addressOne)),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "footer-bottom" }, [
                  _vm.data.internetNumOne && _vm.data.internetNumOne != ""
                    ? _c(
                        "span",
                        {
                          key: _vm.data.internetNumOne,
                          staticClass: "item",
                          on: {
                            click: function ($event) {
                              return _vm.toPage(_vm.copyNumberSourceUrl)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.data.internetNumOne) + " ")]
                      )
                    : _vm._e(),
                  _vm.data.policeInternetNumOne &&
                  _vm.data.policeInternetNumOne != ""
                    ? _c(
                        "span",
                        {
                          key: _vm.data.policeInternetNumOne,
                          staticClass: "item",
                          on: {
                            click: function ($event) {
                              return _vm.toPage(_vm.policeNumberSourceUrl)
                            },
                          },
                        },
                        [
                          _vm.data.policeInternetImgOne
                            ? _c("img", {
                                staticClass: "police-icon-new",
                                attrs: {
                                  src: JSON.parse(
                                    _vm.data.policeInternetImgOne || "{}"
                                  ).relativePath,
                                },
                              })
                            : _c("i", { staticClass: "polic-icon" }),
                          _vm._v(
                            " " + _vm._s(_vm.data.policeInternetNumOne) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.data.levelInternetNumOne &&
                  _vm.data.levelInternetNumOne != ""
                    ? _c(
                        "span",
                        {
                          key: _vm.data.levelInternetNumOne,
                          staticClass: "item",
                          on: {
                            click: function ($event) {
                              return _vm.toPage(_vm.levNumberSourceUrl)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " 等级备案号：" +
                              _vm._s(_vm.data.levelInternetNumOne) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-top-right" }, [
      _c("div", { staticClass: "footer-top-right-text" }, [
        _c("span", [_vm._v("座机：100-400100400")]),
        _c("span", [_vm._v("地址：四川省成都市")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }