<template>
  <div class="dsf_userback ds-control">
    <div class="dsf_userback_inenr">
      <el-form :model="back" ref="ruleForm" label-width="100px">
        <el-form-item label="类型" prop="class">
          <el-select v-model="back.class" placeholder="请选择活动区域">
            <el-option label="课程" value="kc"></el-option>
            <el-option label="学时" value="xs"></el-option>
            <el-option label="报名" value="bm"></el-option>
            <el-option label="专题班" value="ztb"></el-option>
            <el-option label="系统操作" value="stcz"></el-option>
            <el-option label="其他" value="qt"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" prop="desc">
          <el-input type="textarea" v-model="back.desc"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <div class="sms-code">
            <el-input v-model="back.code"></el-input>
            <div class="">验证码</div>
          </div>
        </el-form-item>
        <el-form-item>
          <button class="submit-button" @click="submitForm('back')">提交</button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcUserback",
  ctrlCaption: "用户反馈",
  mixins: [$mixins.control],
  props: {
    itemMargin: {
      type: String,
      default: "20px 0 0 0",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
  },
  components: {},
  data() {
    return {
      back: {
        class: "",
        desc: "",
        code: "",
      },
    };
  },
  methods: {
    submitForm() {
      return false;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/pc/index.scss";
.dsf_userback {
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
  min-height: 320px;
  .el-textarea {
    max-width: 217px;
  }
  .sms-code {
    display: flex;
    align-items: center;
    .el-input {
      width: 168px;
      margin-right: 20px;
    }
  }
  .submit-button {
    width: 85px;
    height: 38px;
    border: none;
    outline: none;
    color: #fff;
    line-height: 38px;
    text-align: center;
    @include font_5;
    @include background-theme("normal");
    border-radius: 3px;
    cursor: pointer;
  }
}
</style>
