var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_teach_assistant_topdetail" }, [
        _c(
          "div",
          {
            staticClass: "teach_assistant_topdetail_innerwrap",
            style: { width: _vm.itemWidth },
          },
          [
            _c(
              "div",
              { staticClass: "detail_imgbox" },
              [
                _c(
                  "el-image",
                  {
                    staticStyle: { width: "338px", height: "253px" },
                    attrs: { src: _vm._f("imgFormat")(_vm.detailData.cover) },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.dsf.config.setting_error_img,
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "detail_conent" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "detail_name ell" }, [
                  _vm._v(_vm._s(_vm.detailData.name)),
                ]),
                _vm._m(0),
              ]),
              _c("div", { staticClass: "detail_font" }, [
                _c("div", { staticClass: "font font1" }, [
                  _vm._v(
                    "开班日期：" +
                      _vm._s(
                        _vm.setDate(
                          _vm.detailData.pxsjSdate,
                          _vm.detailData.pxsjEdate,
                          "-"
                        )
                      )
                  ),
                ]),
                _c("div", { staticClass: "font font2" }, [
                  _vm._v(
                    "报名日期：" +
                      _vm._s(
                        _vm.setDate(
                          _vm.detailData.bmsjSdate,
                          _vm.detailData.bmsjEdate,
                          "-"
                        )
                      )
                  ),
                ]),
              ]),
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_right" }, [
      _vm._v(" 共有 "),
      _c("span", { staticClass: "font_main" }, [_vm._v("1")]),
      _vm._v(" 个专题班 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail_font" }, [
      _c("div", { staticClass: "font font1" }, [
        _vm._v("必修课程：7门  20学时"),
      ]),
      _c("div", { staticClass: "font font2" }, [_vm._v("选修：  4学时")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail_font" }, [
      _c("div", { staticClass: "font font1" }, [
        _vm._v("主办单位：中国干部网络学院"),
      ]),
      _c("div", { staticClass: "font font2" }, [_vm._v("承办单位：暂无")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "detail_font" }, [
      _c("div", { staticClass: "font" }, [_vm._v("学员人数：2912")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }