var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_banner" }, [
    _c(
      "div",
      { staticClass: "banner-inner" },
      [
        _c(
          "el-carousel",
          {
            class: _vm.indicatorClass,
            attrs: {
              trigger: "click",
              height: _vm.setHeight,
              interval: _vm.interval,
              loop: _vm.loop,
            },
          },
          _vm._l(_vm.bannerList, function (item) {
            return _c(
              "el-carousel-item",
              { key: item },
              [
                _c("el-image", { attrs: { src: item.url } }, [
                  _c(
                    "div",
                    {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.dsf.config.setting_error_img,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }