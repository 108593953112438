var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "dsf_userPortrayal",
          style: { width: _vm.width, height: _vm.height },
        },
        [
          _vm.showHeader
            ? _c("userTitle", { attrs: { title: _vm.title, showMore: false } })
            : _vm._e(),
          _c("div", {
            ref: "drawChart",
            style: { width: _vm.width, height: _vm.getHeight },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }