import settingurl from "./settingurl.vue";
import settingheader from "./settingheader.vue";
import settingpage from "./settingpage.vue";
import settingfooter from "./settingfooter.vue";
import settingfootermodel1 from "./settingfootermodel1.vue";
import settingfootermodel2 from "./settingfootermodel2.vue";
import settingfootermodel3 from "./settingfootermodel3.vue";
import pagedesign from "./pagedesign.vue";
import pagepreview from "./pagepreview.vue";

export default [settingurl, settingheader, settingpage, settingfooter, settingfootermodel1, settingfootermodel2, settingfootermodel3, pagedesign, pagepreview];
