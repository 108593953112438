var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_nc_note_item dsf_nc_advice_item" }, [
    _c(
      "div",
      { staticClass: "dsf_nc_note_item_inner dsf_nc_advice_item_inner" },
      [
        _c(
          "el-form",
          {
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "el-form-item",
              { staticClass: "note-text" },
              [
                _c("el-input", {
                  attrs: { type: "textarea", placeholder: _vm.setPlaceholder },
                  model: {
                    value: _vm.form.desc,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "desc", $$v)
                    },
                    expression: "form.desc",
                  },
                }),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "advice-save",
                style: { width: _vm.btnWidth },
                on: { click: _vm.saveAdvice },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "note-list-wrap" }, [
          _c(
            "div",
            { ref: "noteList", staticClass: "note-list" },
            [
              _c("div", { staticClass: "advice-head" }, [_vm._v("建议列表")]),
              _vm._l(_vm.list, function (item) {
                return _c(
                  "div",
                  { key: item._id, staticClass: "note-list-item" },
                  [
                    _c("div", { staticClass: "note-list-item-desc" }, [
                      _c("div", { staticClass: "note-info advice-info" }, [
                        _c("div", { staticClass: "advice-date" }, [
                          _c("span", [
                            _vm._v(
                              "时间: " + _vm._s(item.timespan || "00:00:00")
                            ),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(item["publish_date"]) + " 发布"),
                          ]),
                        ]),
                        _c("div", {
                          staticClass: "advice-text",
                          domProps: { innerHTML: _vm._s(item["content"]) },
                        }),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }