<template>
  <div class="dsf_nc-questions-pc">
    <div v-if="isDesign" class="dsf_nc-question-info-wrap">
      <div class="dsf_nc-before">匿名</div>
      <div class="dsf_nc-right-info">
        <div class="questions-name-wrap">
          <div class="title">问卷名称问卷名称问卷名称问卷名称问卷名称问卷名称问卷名称</div>
          <div class="desc">
            <span>填报方式：xxxxx</span>
          </div>
        </div>
        <div class="right-wrap">
          <div class="time">27天12小时25分钟后结束</div>
          <div class="state_btn">公开</div>
          <div class="state_btn_second second_item1">进入填报</div>
        </div>
      </div>
    </div>
    <div v-else class="dsf_nc-question-info-wrap">
      <div class="dsf_nc-before" v-if="data.is_anonymity_value == '1'">匿名</div>
      <div class="dsf_nc-right-info">
        <div class="questions-name-wrap">
          <div class="title">{{ data.name }}</div>
          <div class="desc">
            <span>填报方式：{{ data.repeat_show_text }}</span>
          </div>
        </div>
        <div class="right-wrap">
          <div class="time">{{ data.time_dsplay_text }}</div>
          <div class="state_btn">{{ data.scope_text }}</div>
          <div class="state_btn_second second_item1" @click="toPage" :class="{second_item2:data.record_status_value != '1' && data.asq_status_value != '1'}">{{ data.record_status_value == "1" ? "查看问卷" : "进入填报" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcQuestionsCard",
  ctrlCaption: "Pc问卷卡片",
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "asq_info_batch_userlistdata.asq_info_id", to: "asqInfoId", type: "text", desc: "asqInfoId" },
          { from: "asq_info_batch_userlistdata.asq_info_batch_id", to: "batchId", type: "text", desc: "batchId" },
          { from: "asq_info_batch_userlistdata.asq_info_record_id", to: "recordId", type: "text", desc: "recordId" },
          { from: "asq_info_batch_userlistdata.name", to: "name", type: "text", desc: "标题" },
          { from: "asq_info_batch_userlistdata.repeat_show_text", to: "repeat_show_text", type: "text", desc: "填报方式" },
          { from: "asq_info_batch_userlistdata.time_dsplay_text", to: "time_dsplay_text", type: "text", desc: "结束倒计时" },
          { from: "asq_info_batch_userlistdata.is_anonymity_text", to: "anonymity_text", type: "text", desc: "是否匿名文字" },
          { from: "asq_info_batch_userlistdata.is_anonymity_value", to: "is_anonymity_value", type: "text", desc: "是否匿名值" },
          { from: "asq_info_batch_userlistdata.real_status_text", to: "real_status_text", type: "boolean", desc: "是否发布文字" },
          { from: "asq_info_batch_userlistdata.real_status_value", to: "real_status_value", type: "text", desc: "是否发布值" },
          { from: "asq_info_batch_userlistdata.scope_text", to: "scope_text", type: "text", desc: "是否公开文字" },
          { from: "asq_info_batch_userlistdata.scope_value", to: "scope_value", type: "text|number", desc: "是否公开值" },
          { from: "asq_info_batch_userlistdata.record_status_value", to: "record_status_value", type: "text|number", desc: "是否已填报" },
          { from: "asq_info_batch_userlistdata.asq_status_value", to: "asq_status_value", type: "text|number", desc: "是否已结束" },
        ];
      },
    },
    toPath: {
      type: String,
      default: "/asq/asq-info/gotoAsqPaper?type=pc&asqInfoId=@[asqInfoId]&batchId=@[batchId]",
    },
    toPathWatch: {
      type: String,
      default: "/asq/asq-info/gotoAsqPaper?type=pc&asqInfoId=@[asqInfoId]&batchId=@[batchId]",
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    toPage() {
      if (this.data.record_status_value == "1") {
        var url = this.$replace(this.toPathWatch, this.data);
      } else {
        if (this.data.asq_status_value != "1") {
          return
        }
        var url = this.$replace(this.toPath, this.data);
      }
      this.$open({
        url: url,
        state: 2,
      });
    },
  },
});
</script>
