import { render, staticRenderFns } from "./channelCardDetail.vue?vue&type=template&id=07cb471d&"
import script from "./channelCardDetail.vue?vue&type=script&lang=js&"
export * from "./channelCardDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/components/channel/channelCardDetail.vue"
export default component.exports