<template>
  <div class="dsf_teach_assistant_topdetail" v-if="visible">
    <div class="teach_assistant_topdetail_innerwrap" :style="{ width: itemWidth }">
      <div class="detail_imgbox">
        <!-- <dsf-image :src="setImg(detailData)" width="338px" height="253px" /> -->
        <el-image :src="detailData.cover | imgFormat" style="width: 338px; height: 253px">
          <div slot="error" class="image-slot">
            <img :src="dsf.config.setting_error_img" alt="" />
          </div>
        </el-image>
      </div>
      <div class="detail_conent">
        <div class="top">
          <div class="detail_name ell">{{ detailData.name }}</div>
          <div class="top_right">
            共有
            <span class="font_main">1</span>
            个专题班
          </div>
        </div>
        <div class="detail_font">
          <div class="font font1">开班日期：{{ setDate(detailData.pxsjSdate, detailData.pxsjEdate, "-") }}</div>
          <div class="font font2">报名日期：{{ setDate(detailData.bmsjSdate, detailData.bmsjEdate, "-") }}</div>
        </div>
        <div class="detail_font">
          <div class="font font1">必修课程：7门&nbsp;&nbsp;20学时</div>
          <div class="font font2">选修：&nbsp;&nbsp;4学时</div>
        </div>
        <div class="detail_font">
          <div class="font font1">主办单位：中国干部网络学院</div>
          <div class="font font2">承办单位：暂无</div>
        </div>
        <div class="detail_font">
          <div class="font">学员人数：2912</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcTeachAssistantTopDetail",
  ctrlCaption: "教辅中心顶部",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/info",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    itemWidth: {
      type: String,
      default: "1200px",
    },
  },
  data() {
    return {
      detailData: {
        bcbh: "ZTB2021061800092",
        bm: "网络学院",
        bmsjEdate: null,
        bmsjSdate: null,
        bzrText: "吕勤^宋尚朴",
        bzrValue: "f445282c9db54cc4b90881f4c5569bcb^a017cc55bbf04aa4a0db180ded303b1c",
        classId: "011ec79ad5fc40c9b42f5aa9a53440a0",
        compulsory: "0",
        compulsoryPeriod: "0.0",
        content:
          '<p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">&nbsp; &nbsp; &nbsp; &nbsp;普惠金融的关键在于解决小微企业等弱势借贷群体的借贷障碍。而借贷障碍的根源往往在于企业与金融机构之间的信息不对称。金融科技以及区块链技术可以有效提升借贷双方的信任程度，在保障借贷双方信息对称的基础上，保证借贷安全等级。为提升领导干部对金融科技与普惠金融的了解，阿里学堂推出“金融科技助力普惠金融”专题班。</span></p>',
        cover:
          '[{"originalFileName":"816f7a18efc14502a61fcff3ff949549.png","name":"816f7a18efc14502a61fcff3ff949549","suffix":"png","size":36714,"contentType":"image/jpeg","relativePath":"/upload/files/20210618/525f35fff54f47b4aeebba90572e49bd.png","absolutePath":"/dsf5//upload/files/20210618/525f35fff54f47b4aeebba90572e49bd.png","uploadDate":"2021-06-18 14:48:35","id":"8fb6e8797c3f41219d08bdbd753aab24"}]',
        electives: "1",
        electivesPeriod: "0.0",
        fzbmText: null,
        fzbmValue: null,
        labelsText: "高效^测试^语文",
        labelsValue: "5be5338d4d5b405db1c06336edbef5d4^98540327951b4a278a4de02557bab8bc^ec7afeaabe42405fbe212ceab08c9e6d",
        lastCourseId: "4a5fecfa726045ba8468b5797b733c61",
        name: "金融科技助力普惠金融",
        period: "0.0",
        pxsjEdate: "2021-08-19 23:59:59",
        pxsjSdate: "2021-08-05 00:00:00",
        signStatus: "1",
        signUp: 0,
        state: 0,
        studentCount: 5,
        trainingStatus: "0",
        txrs: null,
      },
      params: {
        id: "",
      },
      labelLists: [],
      defaultImg: require("../../../assets/imgs/SC1.png"),
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "011ec79ad5fc40c9b42f5aa9a53440a0";
  },
  mounted() {
    if (!this.isDesign) {
      this.detailData = {};
      this.initData();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            this.detailData = res.data;
            this.labelLists = this.detailData.labelsText ? this.detailData.labelsText.split("^") : [];
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickMore() {
      console.log("点击更多了---");
      this.$open({ url: this.moreUrl });
    },
    clickEnter(item) {
      console.log("点击按钮", item);
      let id = item.classId;
      let loading = dsf.layer.pc.loading();
      function error(err) {
        dsf.layer.pc.message(err.message, false);
      }
      dsf.http
        .get("nc/class/enroll", { id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            error(res);
          } else {
            dsf.layer.pc.message(res.message);
            this.initData();
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    setImg(data) {
      // 设置图片
      let cover = data["cover"];
      if (cover) {
        cover = dsf.config.webRoot + JSON.parse(cover)["outputImage"];
      }
      return cover || "/";
    },
    setDate(startDate, endDate, interval) {
      // 设置日期格式
      let start = startDate ? startDate.slice(0, 10) : "";
      let end = endDate ? endDate.slice(0, 10) : "";
      return start && end ? `${start}  ${interval}  ${end}` : "暂无";
    },
    setStatus(data) {
      // 设置状态
      let status = data.state; // 状态
      let studentCount = data.studentCount; // 学员人数
      let sfxzrs = data.txrs; //是否有人数限制
      let html = "";
      switch (status) {
        case 0:
          if (sfxzrs && studentCount >= sfxzrs) {
            html = "人数已满";
            return;
          }
          html = "报名";
          break;
        case 1:
          html = "待审核";
          break;
        case 2:
          html = "开始学习";
          break;
        case 3:
          html = "已结业";
          break;
        default:
          html = "";
      }
      return html;
    },
  },
});
</script>
