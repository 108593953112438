var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DsfNcDefaultHeader" }, [
    _c("div", { staticClass: "img-box", style: _vm.style }, [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIcon,
            expression: "showIcon",
          },
        ],
        attrs: { src: _vm.getUrl, alt: "" },
        on: { error: _vm.headLoadError, load: _vm.headLoadSuccess },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }