<template>
  <div class="dsf-special-source-controll ds-control">
    <div class="dsf-special-source-model" v-if="showModel">
      <el-switch v-model="modelLv" inactive-text="高级模式" class="model" width="20" @change="modelChange" :disabled="readonly"></el-switch>
      <div class="items-wrap" v-if="modelLv">
        <el-radio-group v-model="modelText" @change="radioChange" :disabled="readonly">
          <el-radio label="无学习限制"></el-radio>
          <el-radio label="不允许提前学习"></el-radio>
          <el-radio label="只能在规定时间段内学习"></el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-if="chapter.length > 0">
      <template v-for="(item, index) in chapter">
        <specialSourceControllItem
          :buttons="buttons"
          :list="item.list"
          :stateConfig="item.config"
          :key="item.name"
          :name="item.name"
          :unitId="item.unitId"
          :desc="item.desc"
          :idx="index"
          :sort="item.sort"
          :sourceUrl="sourceUrl"
          :maxHours="maxHours"
          :showTime="modelLv"
          :showMustBe="showMustBe"
          :sDate="sDate"
          :eDate="eDate"
          @deleteChaterItem="deleteChaterItem"
          @updateChapterList="updateChapterList"
          @chapterToUp="chapterToUp"
          @chapterToDown="chapterToDown"
          @updateSaveList="updateSaveList"
        />
      </template>
    </div>
    <div class="add-control-btn" :class="{ 'item-readonly': readonly }">
      <dsf-button class="add-contol-item" size="medium" @click="addChapter">添加章节</dsf-button>
    </div>
  </div>
</template>

<script>
import specialSourceControllItem from "../../base/special/specialSourceControllItem";
export default dsf.component({
  name: "DsfNcSpecialSourceControll",
  ctrlCaption: "专题班资源配置",
  mixins: [$mixins.control],
  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
    maxHours: {
      type: Number,
      default: 5,
    },
    showModel: {
      type: Boolean,
      default: false,
    },
    limitTime: {
      type: Boolean,
      default: false,
    },
    modelSourceUrl: {
      type: String,
      default: "",
    },
    showMustBe: {
      type: Boolean,
      default: true,
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "tyep", to: "type", type: "text", desc: "类型" },
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "address", to: "address", type: "text", desc: "地址" },
          { from: "teacher", to: "teacher", type: "text", desc: "teacher" },
          { from: "startTime", to: "startTime", type: "text|number", desc: "开始时间" },
          { from: "studyHours", to: "studyHours", type: "text", desc: "学时" },
          { from: "nc_questionnaire_query_choiselistdata.batch_id", to: "batchId", type: "text", desc: "学时" },
        ];
      },
    },
  },
  components: {
    specialSourceControllItem,
  },
  data() {
    return {
      modelLv: false,
      chapter: [],
      config: {
        course: {
          code: [],
          text: "课程",
        },
        exam: {
          code: [],
          text: "考试",
        },
        asq: {
          code: [],
          text: "问卷",
        },
        live: {
          code: [],
          text: "直播",
        },
        article: {
          code: [],
          text: "文章",
        },
        channel: {
          code: [],
          text: "频道",
        },
      },
      sourceUrl: "/nc/pack/unit/save",
      id: this.$route.query.id,
      modelText: "无学习限制",
      modelInit: {},
      readonly: this.$route.query.isview == 1 ? true : false,
      sDate: dsf.url.queryString("sDate") || "",
      eDate: dsf.url.queryString("eDate") || "",
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign && this.$route.query.id) {
      this.loadModelInfo();
      if (this.queryString?.sDate && this.queryString?.eDate) {
        this.sDate = this.queryString.sDate;
        this.eDate = this.queryString.eDate;
      }
    }
  },
  methods: {
    reloadData() {
      let timer = setTimeout(() => {
        if (this.queryString?.sDate && this.queryString?.eDate) {
          this.sDate = dsf.getCookie("sDate") || this.queryString.sDate;
          this.eDate = dsf.getCookie("eDate") || this.queryString.eDate;
          clearTimeout(timer);
        }
      }, 50);
    },
    modelChange(it) {
      let nParams = {
        enableModelText: it ? "是" : "否",
        enableModelValue: it ? "1" : "0",
      };
      this.modelInit = Object.assign(this.modelInit, nParams);
      this.svaeModel();
    },
    radioChange(it) {
      let nParams = {
        modelText: it,
        modelValue: this.modelText === "无学习限制" ? 1 : it === "不允许提前学习" ? 2 : 3,
      };
      this.modelInit = Object.assign(this.modelInit, nParams);
      this.svaeModel();
    },
    svaeModel() {
      const sourceUrl = "/nc/pack/unit/model/save";
      dsf.http
        .post(sourceUrl, this.modelInit, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (!success) {
            error(message);
          } else {
            this.$emit("loadedList", {
              state: this.modelLv,
              list: this.chapter,
            });
          }
        })
        .error(({ message }) => {
          error(message);
        });
      function error(err) {
        dsf.layer.pc.message("高级模式配置异常:" + err, false);
      }
    },
    loadModelInfo() {
      const sourceUrl = "/nc/pack/unit/model/query";
      let params = {
        id: this.$route.query.id,
      };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            let { enableModelText, enableModelValue, modelText, modelValue, id } = data;
            if (!enableModelText) enableModelText = "否";
            if (!enableModelValue) enableModelValue = false;
            if (!modelText) modelText = "无学习限制";
            if (!modelValue) modelValue = 1;
            this.modelLv = enableModelText === "否" ? false : true;
            this.modelText = modelText;
            this.modelInit = { enableModelText, enableModelValue, modelText, modelValue, id };
            this.loadChaptersList();
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        });
      function error(err) {
        dsf.layer.pc.message("高级模式配置异常:" + err, false);
      }
    },
    addChapter() {
      if (this.readonly) return;
      this.chapter.push({
        name: "章节" + (this.chapter.length + 1),
        id: Math.random() + new Date().getTime(),
        list: [],
        config: this.config,
        desc: {
          compulsory: 0,
          electives: 0,
          totalPeriod: 0,
        },
      });
    },
    loadChaptersList() {
      const sourceUrl = "/nc/pack/unit/getList";
      let params = {
        id: this.$route.query.id,
      };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.chapter = this.filterList(data);
            this.$emit("loadedList", {
              state: this.modelLv,
              list: this.chapter,
            });
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        });
      function error(err) {
        dsf.layer.pc.message("查询章节列表:" + err, false);
      }
    },
    filterList(data) {
      let tArr = [];
      _.each(data, (it, idx) => {
        let temp = {
          name: it.unitName,
          desc: {
            compulsory: it.compulsory || 0,
            electives: it.electives || 0,
            totalPeriod: it.totalPeriod || 0,
          },
          unitId: it.unitId,
          list: it.subList,
          config: this.getconfig(it.subList),
          sort: it.orderTypeValue,
          order: idx + 1,
        };
        tArr.push(temp);
      });
      return tArr;
    },
    updateChapterList() {
      this.loadChaptersList();
    },
    getconfig(list) {
      let temp = JSON.parse(JSON.stringify(this.config));
      _.each(list, it => {
        if (it.timeSdate && it.timeEdate) {
          let Sdate = new Date(it.timeSdate);
          let Edate = new Date(it.timeEdate);
          it.time = [Sdate, Edate];
        } else {
          // if (sDate && eDate) {
          //   it.time = [sDate, eDate];
          // } else {
          it.time = ["", ""];
          // }
        }
        temp[it.typeValue]["code"].push(it);
      });
      return temp;
    },
    deleteChaterItem(idx) {
      this.chapter.splice(idx, 1);
      this.chapterOrder();
    },
    chapterToUp(idx) {
      const { chapter } = this;
      if (idx === 0) return;
      const it = chapter[idx];
      const temp = chapter[idx - 1];
      this.$set(this.chapter, idx, temp);
      this.$set(this.chapter, idx - 1, it);

      this.chapterOrder();
    },
    chapterToDown(idx) {
      const { chapter } = this;
      if (idx === chapter.length - 1) return;
      const it = chapter[idx];
      const temp = chapter[idx + 1];
      this.$set(this.chapter, idx, temp);
      this.$set(this.chapter, idx + 1, it);

      this.chapterOrder();
    },
    updateSaveList(item) {
      const { it, idx } = item;
      this.chapter[idx].list = it.subList;
      this.chapter[idx].name = it.unitName;
      this.chapter[idx].sort = it.orderTypeValue;
      this.chapterOrder();
    },
    chapterOrder() {
      const loading = dsf.layer.pc.loading();
      let list = [];
      _.each(this.chapter, (it, idx) => {
        list.push({
          unitName: it.name,
          unitId: it.unitId || "",
          classId: this.$route.query.id,
          order: idx + 1,
          orderTypeValue: it.sort,
          orderTypeText: it.sort == 1 ? "是" : "否",
          subList: it.list,
        });
      });
      dsf.http
        .post(
          this.sourceUrl,
          list,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
          $$webRoot.nc
        )
        .done(({ success, message }) => {
          if (success) {
            this.loadChaptersList();
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        dsf.layer.pc.message(err, false);
      }
    },
  },
});
</script>
