var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf_ncpc_manage_learntask" }, [
    _vm.isDesign
      ? _c("ul", { staticClass: "learn_task_wrap" }, [
          _c("li", { staticClass: "learn_task_items" }, [
            _c("div", { staticClass: "status" }, [_vm._v("进行中")]),
            _c("div", { staticClass: "items_contens" }, [
              _vm._m(0),
              _c("div", { staticClass: "items_center" }, [
                _c("div", { staticClass: "date" }, [
                  _vm._v(
                    "培训日期：" +
                      _vm._s(_vm.setDate("2020.05.01", "2020.05.31", "至"))
                  ),
                ]),
                _vm._m(1),
              ]),
              _vm._m(2),
            ]),
          ]),
        ])
      : !_vm.isDesign && _vm.learnTaskType == 1
      ? _c("ul", { staticClass: "learn_task_wrap" }, [
          _c("li", { staticClass: "learn_task_items" }, [
            _c("div", { staticClass: "status" }, [
              _vm._v(_vm._s(_vm.data.currentStateText)),
            ]),
            _c("div", { staticClass: "items_contens" }, [
              _c("div", { staticClass: "items_top" }, [
                _c("div", { staticClass: "items_name ell" }, [
                  _vm._v(_vm._s(_vm.data.name)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "items_details",
                    on: {
                      click: function ($event) {
                        return _vm.clickItem(_vm.data)
                      },
                    },
                  },
                  [_vm._v("进入管理")]
                ),
              ]),
              _c("div", { staticClass: "items_center" }, [
                _c("div", { staticClass: "date" }, [
                  _vm._v(
                    "培训日期： " +
                      _vm._s(
                        _vm.setDate(
                          _vm.data.pxsjSdate,
                          _vm.data.pxsjEdate,
                          "至"
                        )
                      )
                  ),
                ]),
                _vm.showCompulsoryElectives
                  ? _c("div", { staticClass: "period" }, [
                      _c("div", { staticClass: "bx period_font" }, [
                        _vm._v(
                          "必修：" +
                            _vm._s(_vm.data.compulsory || 0) +
                            "门 " +
                            _vm._s(_vm.data.compulsoryPeriod || 0.0) +
                            "学时"
                        ),
                      ]),
                      _c("div", { staticClass: "xx period_font" }, [
                        _vm._v(
                          "选修" +
                            _vm._s(_vm.data.electives || 0) +
                            "门 " +
                            _vm._s(_vm.data.electivesPeriod || 0.0) +
                            "学时"
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "items_bottom" }, [
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.studentCount || 0)),
                  ]),
                  _c("span", [_vm._v("学员人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.dutyCount || 0)),
                  ]),
                  _c("span", [_vm._v("报到人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.graduationCount || 0)),
                  ]),
                  _c("span", [_vm._v("结业人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.dutyRatio || 0)),
                  ]),
                  _c("span", [_vm._v("报到率")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.graduationRatio || 0)),
                  ]),
                  _c("span", [_vm._v("结业率")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.uncheckCount || 0)),
                  ]),
                  _c("span", [_vm._v("待批阅")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.unAnswerCount || 0)),
                  ]),
                  _c("span", [_vm._v("待回答")]),
                ]),
              ]),
            ]),
          ]),
        ])
      : !_vm.isDesign && _vm.learnTaskType == 2
      ? _c("ul", { staticClass: "learn_task_wrap" }, [
          _c("li", { staticClass: "learn_task_items" }, [
            _c("div", { staticClass: "status" }, [
              _vm._v(_vm._s(_vm.data.currentStateText)),
            ]),
            _c("div", { staticClass: "items_contens" }, [
              _c("div", { staticClass: "items_top" }, [
                _c("div", { staticClass: "items_name ell" }, [
                  _vm._v(_vm._s(_vm.data.name)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "items_details",
                    on: {
                      click: function ($event) {
                        return _vm.clickItem(_vm.data)
                      },
                    },
                  },
                  [_vm._v("进入管理")]
                ),
              ]),
              _c("div", { staticClass: "items_center" }, [
                _c("div", { staticClass: "date" }, [
                  _vm._v(
                    "任务日期： " +
                      _vm._s(
                        _vm.setDate(
                          _vm.data.pxsjSdate,
                          _vm.data.pxsjEdate,
                          "至"
                        )
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "items_bottom" }, [
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.studentCount || 0)),
                  ]),
                  _c("span", [_vm._v("应学人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.dutyCount || 0)),
                  ]),
                  _c("span", [_vm._v("已学人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.finishCount || 0)),
                  ]),
                  _c("span", [_vm._v("完成人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.finishRatio || 0)),
                  ]),
                  _c("span", [_vm._v("完成率")]),
                ]),
              ]),
            ]),
          ]),
        ])
      : !_vm.isDesign && _vm.learnTaskType == 3
      ? _c("ul", { staticClass: "learn_task_wrap" }, [
          _c("li", { staticClass: "learn_task_items" }, [
            _c("div", { staticClass: "status" }, [
              _vm._v(_vm._s(_vm.data.currentStateText)),
            ]),
            _c("div", { staticClass: "items_contens" }, [
              _c("div", { staticClass: "items_top" }, [
                _c("div", { staticClass: "items_name ell" }, [
                  _vm._v(_vm._s(_vm.data.name)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "items_details",
                    on: {
                      click: function ($event) {
                        return _vm.clickItem(_vm.data)
                      },
                    },
                  },
                  [_vm._v("进入管理")]
                ),
              ]),
              _c("div", { staticClass: "items_center" }, [
                _c("div", { staticClass: "date" }, [
                  _vm._v(
                    "任务日期： " +
                      _vm._s(
                        _vm.setDate(
                          _vm.data.pxsjSdate,
                          _vm.data.pxsjEdate,
                          "至"
                        )
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "items_bottom" }, [
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.studentCount || 0)),
                  ]),
                  _c("span", [_vm._v("应考人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.dutyCount || 0)),
                  ]),
                  _c("span", [_vm._v("已考人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.finishCount || 0)),
                  ]),
                  _c("span", [_vm._v("完成人数")]),
                ]),
                _c("p", [
                  _c("span", { staticClass: "yellow_font" }, [
                    _vm._v(_vm._s(_vm.data.finishRatio || 0)),
                  ]),
                  _c("span", [_vm._v("完成率")]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "items_top" }, [
      _c("div", { staticClass: "items_name ell" }, [
        _vm._v("培训班名培训班名培训班名培训班名培训班名"),
      ]),
      _c("div", { staticClass: "items_details" }, [_vm._v("进入管理")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "period" }, [
      _c("div", { staticClass: "bx period_font" }, [
        _vm._v("必修：0 门 0 学时"),
      ]),
      _c("div", { staticClass: "xx period_font" }, [
        _vm._v("选修 0 门 0 学时"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "items_bottom" }, [
      _c("p", [
        _c("span", { staticClass: "yellow_font" }, [_vm._v("6")]),
        _c("span", [_vm._v("学员人数")]),
      ]),
      _c("p", [
        _c("span", { staticClass: "yellow_font" }, [_vm._v("2")]),
        _c("span", [_vm._v("报到人数")]),
      ]),
      _c("p", [
        _c("span", { staticClass: "yellow_font" }, [_vm._v("1")]),
        _c("span", [_vm._v("结业人数")]),
      ]),
      _c("p", [
        _c("span", { staticClass: "yellow_font" }, [_vm._v("33.33%")]),
        _c("span", [_vm._v("报到率")]),
      ]),
      _c("p", [
        _c("span", { staticClass: "yellow_font" }, [_vm._v("33.33%")]),
        _c("span", [_vm._v("结业率")]),
      ]),
      _c("p", [
        _c("span", { staticClass: "yellow_font" }, [_vm._v("0")]),
        _c("span", [_vm._v("待批阅")]),
      ]),
      _c("p", [
        _c("span", { staticClass: "yellow_font" }, [_vm._v("0")]),
        _c("span", [_vm._v("待回答")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }