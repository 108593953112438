<template>
  <div class="dsf-special-source-control-item">
    <div class="control-item" :class="{ 'item-readonly': readonly }">
      <div class="controll-head">
        <div class="chapter-name-wrap">
          <el-input v-model="name" placeholder="请输入维护章节名称" class="chapter-name" @blur="chapterNameBlur" :disabled="readonly"></el-input>
        </div>
        <div class="controll-head-desc">
          <dl class="controll-head-desc-item" v-show="showMustBe">
            必修
            <span>{{ desc.compulsory || 0 }}</span>
            门
          </dl>
          <dl class="controll-head-desc-item" v-show="showMustBe">
            选修
            <span>{{ desc.electives || 0 }}</span>
            门
          </dl>
          <dl class="controll-head-desc-item">
            共
            <span>{{ desc.totalPeriod || 0 }}</span>
            学时
          </dl>
          <dl class="chapter-handle">
            <dsf-button icon="icon_up1" size="mini" @click="$emit('chapterToUp', idx)" />
            <dsf-button icon="icon_down1" size="mini" @click="$emit('chapterToDown', idx)" />
            <dsf-button icon="icon_del1" size="mini" class="control-item-delete" @click="deleteChaterItem" />
          </dl>
        </div>
      </div>
      <div class="controll-buttons">
        <div class="controll-sort">
          是否按序学习
          <el-switch v-model="isSort" :active-color="curColor" width="36" @change="changeSort" :disabled="readonly"></el-switch>
        </div>
        <div class="controll-button-wrap">
          <template>
            <dsf-button size="small" v-for="it in buttons" :key="it.id" :text="it.text" @click="toOpenDialog(it)" />
          </template>
          <!-- <div class="controll-more-btn" v-if="buttons.length > 3">
            <dsf-button class="more-btn" text="更多" size="small" icon="shuangjiantouxia" @click="showMoreBtns"></dsf-button>
            <div class="oth-btns" v-if="moreBtns">
              <dsf-button text="更多" size="small" icon="shuangjiantouxia"></dsf-button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="controll-content">
        <div class="content-inner">
          <dl class="item" v-for="(it, index) in list" :key="it._id" :style="`cursor: ${readonly ? 'unset' : 'pointer'}`">
            <dd class="title ellipsis" :title="it['name']">
              <dsf-icon :name="getSurceItemIcon(it['typeText'])" />
              {{ it["name"] }}
            </dd>
            <!-- <dd class="address">{{ it["subId"] || "" }}</dd> -->
            <dd class="name ellipsis" :title="it['teacher']">{{ it["teacher"] || "" }}</dd>
            <dd class="time" v-if="showTime" style="width: 344px">
              <el-date-picker
                :readonly="readonly"
                v-model="it.time"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeTime(index)"
                :picker-options="pickerDiabled"
                format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
            </dd>
            <dd :class="['study-time', { allowEdit: it['typeText'] !== '课程' }]">
              <el-input
                v-model="it['period']"
                placeholder="学时"
                class="study-time-input"
                :readonly="it['typeText'] == '课程'"
                type="number"
                min="0"
                :max="maxHours"
                @blur="studyHourBlur(it['period'])"
                :disabled="readonly"
              ></el-input>
              学时
            </dd>
            <dd class="specila-select" v-show="showMustBe">
              <el-select v-model="it['categoryText']" class="specila-select-select" @change="changeSelect(index)" :disabled="readonly">
                <el-option v-for="item in courseClass" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </dd>
            <dd class="buttons">
              <dsf-button icon="yidong" size="mini" title="按住拖拽可调整顺序" @mousedown="toMoveDown(index, $event)" />
              <dsf-button icon="icon_up1" size="mini" @click="toUp(index)" />
              <dsf-button icon="icon_down1" size="mini" @click="toDown(index)" />
              <!-- <dsf-button icon="icon_check" size="mini" @click="toPreview(index)" /> -->
              <dsf-button icon="icon_del1" size="mini" @click="toDetle(it['subId'], idx)" />
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="mask" v-if="moreBtns" @click="moreBtns = false"></div>
  </div>
</template>

<script>
export default {
  name: "DsfNcSpecialSourceControlItem",
  // ctrlCaption: "专题班资源配置列",
  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
    keys: {
      type: Array,
      default() {
        return [];
      },
    },
    sourceUrl: {
      type: String,
      default: "/nc/pack/unit/save",
    },
    idx: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    unitId: {
      type: String,
      default: "",
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: Object,
      default() {
        return {};
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    stateConfig: {
      type: Object,
      default() {
        return {
          course: {
            code: [],
            text: "课程",
          },
          exam: {
            code: [],
            text: "考试",
          },
          asq: {
            code: [],
            text: "问卷",
          },
          live: {
            code: [],
            text: "直播",
          },
          article: {
            code: [],
            text: "文章",
          },
          channel: {
            code: [],
            text: "频道",
          },
        };
      },
    },
    sort: {
      type: String,
      default: "0",
    },
    maxHours: {
      type: Number,
      default: 5,
    },
    showMustBe: {
      type: Boolean,
      default: true,
    },
    sDate: {
      type: String,
      default: "",
    },
    eDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      courseClass: [
        {
          value: "选修",
          label: "选修",
        },
        {
          value: "必修",
          label: "必修",
        },
      ],
      isSort: this.sort == "1" ? true : false,
      moreBtns: false,
      readonly: this.$route.query.isview == 1 ? true : false,
      pickerDiabled: {
        disabledDate(time) {
          // if (sDate && eDate) return time.getTime() < sDate || time.getTime() > eDate;
        },
      },
      moveBeforeIndex: 0,
      moveAfterIndex: 0,
    };
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },

  mounted() {
    this.initTime();
  },
  watch: {
    sDate(n) {
      if (n) {
        this.initTime();
      }
    },
  },
  methods: {
    reloadData() {
      this.initTime();
    },
    initTime() {
      if (this.sDate && this.eDate) {
        const sDate = new Date(this.sDate),
          eDate = new Date(this.eDate);
        this.pickerDiabled.disabledDate = time => {
          return time.getTime() < sDate || time.getTime() > eDate;
        };
      }
    },
    changeTime(idx) {
      if (!this.list[0]["time"] && this.list.length != 1) {
        dsf.layer.pc.message("时间配置请先填写第一条数据", false);
      } else {
        const time = this.list[idx]["time"];
        const sTime = time ? time[0] : null;
        const eTime = time ? time[1] : null;
        this.list[idx].timeSdate = sTime ? dsf.date.format(sTime, "yyyy-mm-dd hh:ii:ss") : null;
        this.list[idx].timeEdate = eTime ? dsf.date.format(eTime, "yyyy-mm-dd hh:ii:ss") : null;
        this.saveSpecilaList();
      }
    },
    clickMore() {
      this.$open({ url: this.moreUrl });
    },
    studyHourBlur(it) {
      if (Number(it) > Number(this.maxHours)) return;
      this.saveSpecilaList();
    },
    changeSort() {
      this.saveSpecilaList();
    },
    changeSelect(idx) {
      let value = this.list[idx]["categoryText"] == "必修" ? 1 : 0;
      this.list[idx]["categoryValue"] = value;
      this.$forceUpdate();
      this.saveSpecilaList();
    },
    filterList(state) {
      if (!state) return [];
      // if (state == "asq") state = "question";
      const text = this.stateConfig[state].text;
      // const text = this.stateConfig.text;
      const { list } = this;
      let temp = [];
      let ids = [];
      _.each(list, it => {
        if (it["typeText"] == text) {
          if (ids.indexOf(it["businessId"]) < 0) {
            ids.push(it["businessId"]);
            temp.push({
              title: it.name,
              _id: it["businessId"],
            });
          }
        }
      });
      return { value: temp };
    },
    toOpenDialog(d) {
      if (this.readonly) return;
      let that = this;
      let [title, url, size, saveName] = d.params;
      let width, height;
      if (size) {
        [width, height] = size.default.split(",");
      } else {
        [width, height] = ["80%", "500px"];
      }
      const state = this.getQueryParam(url.value.code, "typeValue") || "";
      if (url) {
        this.$openDialog({
          title: title.value.code || "弹框",
          width: width,
          height: height,
          footerAlign: "",
          params: {
            path: url.value.code,
            choiceParams: this.filterList(state),
          },
          btns: [
            {
              text: "确定",
              handler: async res => {
                const _name = saveName || "system-dialog-save";
                let data = await res.yes();
                let _list = [];
                let _data = [];
                if (Object.prototype.toString.call(data) == "[object Object]") data = [data];
                _.each(data, it => {
                  let keys = {
                    categoryValue: 0,
                    categoryText: "选修",
                  };
                  for (let key in it) {
                    let keyArr = key.split(".");
                    let nKey = keyArr.length == 1 ? keyArr[0] : keyArr[1];
                    keys[nKey] = it[key];
                  }
                  _.each(this.list, (item, index) => {
                    if (it._id == item.businessId) {
                      keys["subId"] = item.subId;
                      keys["categoryValue"] = item.categoryValue;
                      keys["categoryText"] = item.categoryText;
                    } else {
                      _list.push(item);
                    }
                  });
                  _data.push(keys);
                });
                this.initMapKeys(_data, _list, state);
              },
            },
            {
              text: "取消",
            },
          ],
        });
      }
    },
    unique(data) {
      let _data = [];
      let ids = [];
      _.each(data, it => {
        let id = it["businessId"] || it._id;
        if (ids.indexOf(id) < 0) {
          ids.push(id);
          _data.push(it);
        }
      });
      return _data;
    },
    initMapKeys(data, list, value) {
      const text = this.stateConfig[value].text;
      let newList = [];
      _.each(data, it => {
        if (value === "live") it.duration = it.time_sdate;
        let obj = {
          subId: it.subId, //唯一标识 （_id）（没有就不传）
          businessId: value === "asq" ? it["batch_id"] : it["_id"], //业务Id（所选资源的id）
          typeValue: value, //类型
          typeText: text, //类型
          name: it["title"] || it["name"], //名称
          categoryValue: it.categoryValue, //类别（必/选修）1：必修，0:选修
          categoryText: it.categoryText, // 类别（必/选修）1：必修，0:选修
          period: it["period"] || 0, //学时
          timeSdate: "", //开始日期 （没有就不传）
          timeEdate: "", //结束日期（没有就不传）
          order: it["$order"], //排序
          duration: it["duration"] || "", //时长
          teacher: it["teacher_text"] || "",
          _id: it["_id"],
          batchId: it["batch_id"],
          asqInfoId: it["asq_id"],
        };
        newList.push(obj);
      });
      this.list = this.unique([...list, ...newList]);
      this.saveSpecilaList(true);
    },
    saveSpecilaList() {
      /**
       * subList 课程列表
       * unitName 单元名称
       * unitId 单元id 没有就不传
       * classId; 课程id
       * orderTypeText;  是否按序  1：是，0：否
       * orderTypeValue;  是否按序  1：是，0：否
       **/
      let params = {
        unitName: this.name,
        unitId: this.unitId || "",
        classId: this.$route.query.id,
        orderTypeValue: this.isSort ? 1 : 0,
        orderTypeText: this.isSort ? "是" : "否",
        subList: this.list,
      };
      this.$emit("updateSaveList", { it: params, idx: this.idx });
    },
    loadChapterItemInfo() {
      const sourceUrl = "/nc/pack/unit/period";
      let params = {
        id: this.$route.query.id,
      };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.desc = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {});
      function error(err) {
        dsf.layer.pc.message("查询章节信息:" + err, false);
      }
    },
    getQueryParam(url, typeValue) {
      var params = url.split("&");
      if (!params && params.length < 1) return;
      for (var i = 0; i < params.length; i++) {
        var pair = params[i].split("=");
        if (pair[0] == typeValue) {
          return pair[1];
        }
      }
      return false;
    },
    //移动
    toMoveDown(idx, $event) {
      if (this.readonly) return;

      const { list } = this;
      this.moveBeforeIndex = idx;
      document.onmousemove = event => {
        var y = event.clientY - $("dl.item").offset().top;
        let afterIndex = 0;
        if (y < 0) {
          afterIndex = 0;
        } else {
          afterIndex = Math.floor(y / ($("dl.item").height() + 8));
          if (afterIndex > list.length - 1) {
            afterIndex = list.length - 1;
          }
        }
        if (this.moveAfterIndex != afterIndex) {
          const it = list[this.moveBeforeIndex];
          const temp = list[afterIndex];
          this.$set(this.list, this.moveBeforeIndex, temp);
          this.$set(this.list, afterIndex, it);
          this.moveBeforeIndex = afterIndex;
          this.moveAfterIndex = afterIndex;
        }
        document.onmouseup = () => {
          document.onmousemove = null;
          document.onmouseup = null;
          this.saveSpecilaList(false);
        };
      };
    },
    toUp(idx) {
      if (this.readonly) return;

      const { list } = this;
      if (idx == 0) return;
      const it = list[idx];
      const temp = list[idx - 1];
      this.$set(this.list, idx, temp);
      this.$set(this.list, idx - 1, it);
      this.saveSpecilaList(false);
    },
    toDown(idx) {
      if (this.readonly) return;
      const { list } = this;
      if (idx == list.length - 1) return;
      const it = list[idx];
      const temp = list[idx + 1];
      this.$set(this.list, idx, temp);
      this.$set(this.list, idx + 1, it);
      this.saveSpecilaList(false);
    },
    toPreview(idx) {},
    toDetle(id, idx) {
      if (this.readonly) return;
      let { unitId } = this;
      if (unitId) {
        const sourceUrl = "/nc/pack/unit/sub/delete";
        let params = {
          id: id,
        };
        dsf.http
          .get(sourceUrl, params, $$webRoot.nc)
          .done(({ success, data, message }) => {
            dsf.layer.pc.message("删除课件:" + message, success);
            if (success) {
              this.list.splice(idx, 1);
              this.$emit("updateChapterList");
            }
          })
          .error(() => {
            dsf.layer.pc.message("删除课件出错:" + err, false);
          })
          .always(res => {});
      } else {
        this.list.splice(idx, 1);
      }
    },
    getSurceItemIcon(text) {
      let icon = "";
      switch (text) {
        case "课程":
          icon = "icon_course";
          break;
        case "考试":
          icon = "icon_exam";
          break;
        case "问卷":
          icon = "icon_questionnaire";
          break;
        case "直播":
          icon = "icon_live";
          break;
        case "文章":
          icon = "icon_article";
          break;
        case "面授":
          icon = "icon_facetoface";
          break;
        default:
          icon = "kecheng";
          break;
      }
      return icon;
    },
    showMoreBtns() {
      this.moreBtns = !this.moreBtns;
    },
    chapterNameBlur() {
      if (!this.name) return dsf.layer.message("章节名称不能为空", false);
      this.saveSpecilaList();
    },
    deleteChaterItem() {
      if (this.readonly) return;

      let that = this;
      let { unitId, idx } = this;
      if (unitId) {
        dsf.layer
          .confirm({
            message: "您确定要删除当前章节吗？",
          })
          .then(function () {
            const sourceUrl = "/nc/pack/unit/delete";
            let params = {
              id: unitId,
            };
            dsf.http
              .get(sourceUrl, params, $$webRoot.nc)
              .done(({ success, data, message }) => {
                dsf.layer.pc.message("删除章节:" + message, success);
                if (success) that.$emit("deleteChaterItem", idx);
              })
              .error(() => {
                dsf.layer.pc.message("删除章节出错:" + err, false);
              })
              .always(res => {});
          });
      } else {
        that.$emit("deleteChaterItem", idx);
      }
    },
  },
};
</script>
