var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "dsf_nc_system_announcement" }, [
        _c("div", { staticClass: "dsf_system_box" }, [
          _c(
            "div",
            { staticClass: "dsf_system_header" },
            [
              _c("dsf-image", { attrs: { src: _vm.imgUrl, fit: "cover" } }),
              _c("dsf-button", {
                staticClass: "dsf_close",
                attrs: {
                  icon: "guanbi2",
                  size: "small",
                  "btn-style": "icon-text",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handlerClose.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "dsf_system_content" }, [
            _c("div", { staticClass: "system_title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c("div", {
              staticClass: "system_content",
              domProps: { innerHTML: _vm._s(_vm.content) },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }