var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDesign
    ? _c("div", { staticClass: "dsf_nc_ckplayer" }, [
        _c(
          "div",
          {
            staticClass: "ckplayer-inner",
            style: { width: _vm.width, height: _vm.v_height },
            attrs: { id: "_inner" },
          },
          [
            _c("div", { staticStyle: { height: "100%" } }, [
              _c("div", {
                ref: "ckplayer",
                staticStyle: { height: "100%" },
                attrs: { id: "_player" },
              }),
            ]),
          ]
        ),
      ])
    : _c("div", { staticClass: "dsf_nc_ckplayer" }, [
        _c(
          "div",
          {
            staticClass: "ckplayer-inner",
            style: { width: _vm.width, height: _vm.v_width },
          },
          [
            _c("div", { staticStyle: { height: "100%" } }, [
              _c("div", {
                ref: "ckplayer",
                staticStyle: { height: "100%" },
                attrs: { id: "_player" },
              }),
            ]),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }