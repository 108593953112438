<template>
  <div class="dsf_ncpc_manage_platform_dynamic" :style="{ width: itemWidth, display: visible ? 'block' : 'none' }">
    <div class="dynamic_message">
      <ul class="message_wrap" v-if="isDesign">
        <li class="message_items ell">
          <span class="yellow_font">张三</span>
          完成了《XXXX》课程学习
        </li>
        <li class="message_items ell">
          <span class="yellow_font">王五</span>
          被
          <span class="yellow_font">李四</span>
          邀请加入了本平台
        </li>
        <li class="message_items ell">
          <span class="yellow_font">赵六</span>
          被
          <span class="yellow_font">管理员</span>
          添加到了本平台
        </li>
        <li class="message_items ell">
          <span class="yellow_font">王德发</span>
          退出了本平台
        </li>
      </ul>
      <ul class="message_wrap" v-if="!isDesign">
        <li class="message_items ell" v-for="item in loginLists" :key="item.nc_log_login_id">
          <span class="yellow_font">{{ item.ds_create_user_name }}</span>
          &nbsp;
          {{ item.ds_create_time }}&nbsp;
          {{ item.in_out_text }}
        </li>
      </ul>
    </div>
    <!-- 近7日登录用户 -->
    <div class="login_user_info">
      <div class="login_user_echarts" id="loginUserEchats" ref="loginUserEchats" :style="{ width: '100%', height: '190px' }"></div>
    </div>
    <!-- 近7日学习时长 -->
    <div class="learn_time">
      <div class="learn_time_echarts" id="learnTimeEchats" ref="learnTimeEchats" :style="{ width: '100%', height: '190px' }"></div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcPlatformDynamic",
  ctrlCaption: "平台动态",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/login/list",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loginLists: [],
      params: {
        pageSize: 5,
      },
      loginUserInfo: {},
    };
  },
  created() {},
  mounted() {
    if (!window.echarts) {
      this.loadScript(() => {
        if (!this.isDesign) {
          this.initData(); // 列表请求
          this.initDrawLoginEcharts(); //近7日登录用户
          this.initDrawSevenDaysStudyAverage(); // 近7日学习时长
        } else {
          //近7日登录用户
          let userLoginData = {
            days: ["1", "2", "3", "4", "5", "6", "7"],
            count: [5, 10, 15, 20, 15, 30, 4],
          };
          this.drawLoginUserEcharts(userLoginData);

          // 近7日学习时长
          let sevenDaysStudyAverageData = {
            text: ["1", "2", "3", "4", "5", "6", "7"],
            value: ["2.0", "5.0", "10.5", "11.0", "20.0", "12.0", "11.0"],
          };
          this.learnEcharts(sevenDaysStudyAverageData);
        }
      });
    } else {
      if (!this.isDesign) {
        this.initData(); // 列表请求
        this.initDrawLoginEcharts(); //近7日登录用户
        this.initDrawSevenDaysStudyAverage(); // 近7日学习时长
      } else {
        //近7日登录用户
        let userLoginData = {
          days: ["1", "2", "3", "4", "5", "6", "7"],
          count: [5, 10, 15, 20, 15, 30, 4],
        };
        this.drawLoginUserEcharts(userLoginData);

        // 近7日学习时长
        let sevenDaysStudyAverageData = {
          text: ["1", "2", "3", "4", "5", "6", "7"],
          value: ["2.0", "5.0", "10.5", "11.0", "20.0", "12.0", "11.0"],
        };
        this.learnEcharts(sevenDaysStudyAverageData);
      }
    }
  },
  methods: {
    // 加载js
    loadScript(callback) {
      this.$http
        .importFiles([[dsf.url.getWebPath("$/js/libs/echarts/echarts.min.js"), ".js"]])
        .then(() => {
          callback?.();
        })
        .catch(() => {
          dsf.layer.message("加载fabric文件报错", false);
          reject();
        });
    },
    // 近7日登录用户
    drawLoginUserEcharts(data) {
      if (!document.getElementById("loginUserEchats")) return;
      let myChart = echarts.init(document.getElementById("loginUserEchats"));
      let option = {
        title: {
          text: "近7日登录用户",
          textAlign: "center",
          left: "50%",
          top: "10px",
          textStyle: {
            fontSize: 14,
            colot: "#333",
          },
        },
        tooltip: {},
        legend: {},
        xAxis: {
          data: data.days,
          axisTick: {
            // 坐标刻度长度
            length: 0,
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "#666",
            fontSize: 12,
            rotate: 30, // 刻度标签旋转的角度
          },
        },
        yAxis: {
          // type: "value",
          // data: [0, 5, 10, 15],
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "#666",
            fontSize: 10,
            rotate: 30,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            data: data.count,
            // data: [1234, 4567, 8950, 2124, 3456, 899, 1245],
            borderRadius: [100, 100, 0, 0],
            itemStyle: {
              barBorderRadius: [100, 100, 0, 0],
              color: "#2177FF",
            },
            barWidth: 10,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 近7日学习时长
    learnEcharts(data) {
      if (!document.getElementById("learnTimeEchats")) return;
      let myChart = echarts.init(document.getElementById("learnTimeEchats"));
      let option = {
        title: {
          text: "近7日人均学习时长",
          textAlign: "center",
          left: "50%",
          top: "10px",
          textStyle: {
            fontSize: 14,
            colot: "#333",
          },
        },
        tooltip: {},
        legend: {},
        xAxis: {
          // data: ["1", "2", "3", "4", "5", "6", "7"],
          data: data.text,
          axisTick: {
            show: false,
            length: 0,
          },
          axisLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "#666",
            fontSize: 12,
          },
        },
        yAxis: {
          // type: "value",
          // data: [0, 5, 10, 15],
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "#666",
            fontSize: 10,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "line",
            // data: [2, 10, 15, 20, 15, 30, 4],
            data: data.value,
            itemStyle: {
              color: "#F68F4A",
            },
            barWidth: 10,
            smooth: true,
            symbol: "none",
          },
        ],
      };
      myChart.setOption(option);
    },
    initData() {
      let loading = dsf.layer.pc.loading();

      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.loginLists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    initDrawLoginEcharts() {
      let loading = dsf.layer.pc.loading();

      dsf.http
        .get("nc/loginLog/loginLogWeek", {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.drawLoginUserEcharts(data);
            // console.log("253253==>", data);
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    initDrawSevenDaysStudyAverage() {
      let loading = dsf.layer.pc.loading();

      dsf.http
        .get("nc/record/statistic/sevenDaysStudyAverage", {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.learnEcharts(data);
            // console.log("253253==>", data);
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
