<template>
  <div class="dsf_ncpc_manage_learnspecial" :style="{ width: itemWidth, display: visible ? 'block' : 'none' }">
    <ul class="learn_special_wrap" v-if="lists.length">
      <li class="learn_special_items" v-for="(item, index) in lists" :key="index">
        <div class="status">{{ item.currentStateText }}</div>
        <div class="items_contens">
          <div class="items_top">
            <div class="items_name ell">{{ item.name }}</div>
            <div class="items_details" @click="clickItem(item)">进入管理</div>
          </div>
          <div class="items_center">
            <div class="date">培训日期：{{ setDate(item.pxsjSdate, item.pxsjEdate, "至") }}</div>
            <div class="period">
              <div class="bx period_font">必修：{{ item.compulsory || 0 }}门 {{ item.compulsoryPeriod || 0.0 }}学时</div>
              <div class="xx period_font">选修：{{ item.electives || 0 }}门 {{ item.electivesPeriod || 0.0 }}学时</div>
            </div>
          </div>
          <div class="items_bottom">
            <p>
              <span class="yellow_font">{{ item.studentCount || 0 }}</span>
              <span>学员人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ item.dutyCount || 0 }}</span>
              <span>报到人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ item.graduationCount || 0 }}</span>
              <span>结业人数</span>
            </p>
            <p>
              <span class="yellow_font">{{ item.dutyRatio || 0 }}</span>
              <span>报到率</span>
            </p>
            <p>
              <span class="yellow_font">{{ item.graduationRatio || 0 }}</span>
              <span>结业率</span>
            </p>
            <!-- <p>
              <span class="yellow_font">{{ item.uncheckCount || 0 }}</span>
              <span>待批阅</span>
            </p>
            <p>
              <span class="yellow_font">{{ item.unAnswerCount || 0 }}</span>
              <span>待回答</span>
            </p> -->
          </div>
        </div>
      </li>
    </ul>
    <div class="ds-empty-data" v-text="'暂无数据'" v-else></div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcLearnSpecial",
  ctrlCaption: "学习任务培训班",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/manage/list",
    },
    itemWidth: {
      type: String,
      default: "1020px",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    moreConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      params: {},
      lists: [
        {
          currentStateText: "进行中",
          name: "培训班名培训班名培训班名培训班名培训班名",
          pxsj_sdate: "2020.05.01",
          pxsj_edate: "2020.05.31",
          compulsory: "0", // 必修
          compulsoryperiod: "0", //必修学时
          electives: "18",
          electivesperiod: "4.5",
          studentCount: "6",
          dutyCount: "2",
          graduationCount: "1",
          dutyRatio: "33.33%",
          jjl: "1%",
          dql: "1",
          dhd: "1",
          dpy: "1",
          classId: "123456789",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickItem(item) {
      if (!this.moreConfig) return;
      let { url, state } = this.moreConfig;
      url = this.$replace(url, item);
      this.$open({ url, state });
    },
    setDate(startDate, endDate, interval) {
      let start = startDate ? startDate.slice(0, 10).replace(/\./g, "-") : "";
      let end = endDate ? endDate.slice(0, 10).replace(/\./g, "-") : "";
      return start && end ? `${start}  ${interval}  ${end}` : "暂无";
    },
  },
});
</script>
