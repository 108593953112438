var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-live-signin" }, [
    _c("div", { staticClass: "live-signin-title next title" }, [
      _c("span", { style: _vm.title_style }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "live-signin-box", attrs: { id: "myCanvasContainer" } },
      [
        _c(
          "canvas",
          {
            ref: "canvas",
            attrs: { width: "300", height: "300", id: "myCanvas" },
          },
          [_c("p", [_vm._v("换个现代浏览器吧！")])]
        ),
        _c("div", { attrs: { id: "tags" } }, [
          _c(
            "ul",
            _vm._l(_vm.members, function (item, index) {
              return _c("li", { key: index, staticClass: "item_bg" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.checkItem(item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "item_img",
                      attrs: { src: _vm.setImg(item.photo) },
                    }),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }