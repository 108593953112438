<template>
  <div class="dsf_nc_special_detail_complete_schedule">
    <div class="complete_title">
      <span class="font">进度</span>
      <span class="font des">进度=已获学时/考核学时*100%</span>
    </div>
    <div class="complete_schedule_progress"></div>
  </div>
</template>

<script>
export default {
  name: "dsfNcSpecialDetailCompleteSchedule",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
