export default {
  data() {
    return {
      examIds: [],
      examTimer: null,
      examTimerTime: null,
      examTimerState: null,
      examAnserCount: 0, //试题答对次数
      examPlayerNode: 0,
      examShowDialog: false,
    };
  },
  created() {},
  methods: {},
};