var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-home-tab-panel",
      class: { "ds-no-padding": !_vm.isDesign },
      style: _vm.ctrlStyle,
      attrs: { mode: _vm.styleType, showShadow: _vm.showShadow },
    },
    [
      _c(
        "div",
        { staticClass: "ds-home-tab-panel-warp", style: _vm.warpStyle },
        [
          _c(
            "div",
            { staticClass: "ds-home-tab-panel-head" },
            [
              _vm.titleIcon || _vm.resTitle
                ? _c(
                    "div",
                    { staticClass: "ds-home-tab-panel-head-title" },
                    [
                      _vm.titleIcon
                        ? _c("DsfIcon", {
                            staticClass: "ds-home-tab-panel-head-title-icon",
                            attrs: { name: _vm.titleIcon },
                          })
                        : _vm._e(),
                      _vm.resTitle
                        ? _c(
                            "div",
                            {
                              staticClass: "ds-home-tab-panel-head-title-text",
                            },
                            [_vm._v(" " + _vm._s(_vm.resTitle) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "ds-home-tab-panel-head-scroll" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTabs,
                        expression: "showTabs",
                      },
                    ],
                    staticClass: "ds-home-tab-panel-head-items",
                  },
                  [
                    _vm._l(_vm.userTabs, function (tab) {
                      return [
                        !tab.hide
                          ? _c(
                              "div",
                              {
                                key: tab.name,
                                ref: tab.name,
                                refInFor: true,
                                staticClass: "ds-home-tab-panel-head-item",
                                attrs: { active: _vm.tabActive == tab.name },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickTab($event, tab)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(tab.title) + " "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: tab.badge > 0,
                                        expression: "tab.badge > 0",
                                      },
                                    ],
                                    staticClass:
                                      "ds-home-tab-panel-head-item-badge",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.tabActive == tab.name,
                                            expression: "tabActive == tab.name",
                                          },
                                        ],
                                      },
                                      [_vm._v(_vm._s(tab.badge))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTabs,
                      expression: "showTabs",
                    },
                  ],
                  staticClass: "ds-home-tab-panel-head-indicator",
                },
                [
                  _c("DsfIcon", {
                    staticClass:
                      "ds-home-tab-panel-head-indicator-icon ds-home-tab-panel-head-indicator-left",
                    attrs: { name: "left" },
                    on: {
                      click: function ($event) {
                        return _vm.moveScroll("left")
                      },
                    },
                  }),
                  _c("DsfIcon", {
                    staticClass:
                      "ds-home-tab-panel-head-indicator-icon ds-home-tab-panel-head-indicator-right",
                    attrs: { name: "right" },
                    on: {
                      click: function ($event) {
                        return _vm.moveScroll("right")
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._t("header-right", null, { active: _vm.tabActive }),
              _vm._t("add", function () {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.getAdd && _vm.getAdd.url && _vm.addShow,
                          expression: "getAdd && getAdd.url && addShow",
                        },
                      ],
                      staticClass:
                        "ds-home-tab-panel-head-icon ds-home-tab-panel-head-more",
                      on: {
                        click: function ($event) {
                          return _vm.panelIconBtnClick("add")
                        },
                      },
                    },
                    [
                      _vm.addText ? [_vm._v(_vm._s(_vm.addText))] : _vm._e(),
                      _vm.addIcon
                        ? _c("DsfIcon", { attrs: { name: _vm.addIcon } })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
              _vm._t("more", function () {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.getMore && _vm.getMore.url && _vm.moreShow,
                          expression: "getMore && getMore.url && moreShow",
                        },
                      ],
                      staticClass:
                        "ds-home-tab-panel-head-icon ds-home-tab-panel-head-more",
                      on: {
                        click: function ($event) {
                          return _vm.panelIconBtnClick("more")
                        },
                      },
                    },
                    [
                      _vm.moreText ? [_vm._v(_vm._s(_vm.moreText))] : _vm._e(),
                      _vm.moreIcon
                        ? _c("DsfIcon", { attrs: { name: _vm.moreIcon } })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
              _vm._t("config", function () {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.settingShow,
                          expression: "settingShow",
                        },
                      ],
                      staticClass:
                        "ds-home-tab-panel-head-icon ds-home-tab-panel-head-more",
                      on: {
                        click: function ($event) {
                          return _vm.panelIconBtnClick("setting")
                        },
                      },
                    },
                    [
                      _vm.settingText
                        ? [_vm._v(_vm._s(_vm.settingText))]
                        : _vm._e(),
                      _vm.settingIcon
                        ? _c("DsfIcon", { attrs: { name: _vm.settingIcon } })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
          [
            _c("div", { staticClass: "wrap" }, [
              _vm.visibleSlots && _vm.tabActive == "all" && _vm.allSlots
                ? _c(
                    "div",
                    { staticClass: "tabs" },
                    _vm._l(_vm.allSlots, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.index,
                          staticClass: "tabs-item",
                          class: [_vm.status == index ? "active" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.tabScreen(item, index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }),
                    0
                  )
                : _vm.visibleSlots &&
                  _vm.tabActive == "workrecord" &&
                  _vm.workrecordSlots
                ? _c(
                    "div",
                    { staticClass: "tabs" },
                    _vm._l(_vm.workrecordSlots, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.index,
                          staticClass: "tabs-item",
                          class: [_vm.status == index ? "active" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.tabScreen(item, index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    }),
                    0
                  )
                : _vm.visibleSlots && _vm.tabActive == "read" && _vm.readSlots
                ? _c(
                    "div",
                    { staticClass: "tabs" },
                    _vm._l(_vm.readSlots, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.index,
                          staticClass: "tabs-item",
                          class: [_vm.status == index ? "active" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.tabScreen(item, index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }),
                    0
                  )
                : _vm.visibleSlots &&
                  _vm.tabActive == "announcement" &&
                  _vm.announcementSlots
                ? _c(
                    "div",
                    { staticClass: "tabs" },
                    _vm._l(_vm.announcementSlots, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.index,
                          staticClass: "tabs-item",
                          class: [_vm.status == index ? "active" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.tabScreen(item, index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.status == 0 && _vm.tabActive == "announcement"
                ? _c(
                    "div",
                    {
                      staticClass: "yijianyidu",
                      on: { click: _vm.beenReadEvent },
                    },
                    [_vm._v("全部标记已读")]
                  )
                : _vm._e(),
            ]),
          ],
          _vm._l(_vm.userTabs, function (slot, index) {
            return [
              _vm.singleSlot && !index
                ? [
                    !slot.hide
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.singleSlot || _vm.tabActive == slot.name,
                                expression:
                                  "singleSlot || tabActive == slot.name",
                              },
                            ],
                            key: slot.name,
                            staticClass: "ds-home-tab-panel-main",
                            style: _vm.mainStyle,
                            attrs: { "slot-name": slot.name },
                          },
                          [
                            _vm.isDesign
                              ? _vm._t(slot.name)
                              : _c(
                                  "DsfNcVirtualScroll",
                                  [_vm._t(slot.name)],
                                  2
                                ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                : !_vm.singleSlot
                ? [
                    !slot.hide
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tabActive == slot.name,
                                expression: "tabActive == slot.name",
                              },
                            ],
                            key: slot.name,
                            staticClass: "ds-home-tab-panel-main",
                            style: _vm.mainStyle,
                            attrs: { "slot-name": slot.name },
                          },
                          [
                            _vm.isDesign
                              ? _vm._t(slot.name)
                              : _c(
                                  "DsfNcVirtualScroll",
                                  [_vm._t(slot.name)],
                                  2
                                ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }