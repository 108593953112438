var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_nc_bcertificate", class: _vm.getCss },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "800px",
            "close-on-press-escape": false,
            "show-close": false,
            "append-to-body": true,
            "custom-class": "nc_pc_certificate_dialog",
            visible: _vm.show,
          },
          on: {
            close: _vm.onClose,
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("结业证书"),
          ]),
          _c("div", { staticClass: "img-wrap" }, [
            _c("img", { attrs: { src: _vm.imgUrl, alt: "" } }),
          ]),
          _c("DsfButtonBar", {
            attrs: { buttons: _vm.buttons, "max-number": _vm.maxNumber },
            on: { close: _vm.onClose, save: _vm.downloadImg },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }