var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-nc-shopping-car ds-nc-shopping-order" }, [
    _c("div", { staticClass: "shopping-head" }, [
      _c("div", { staticClass: "shopping-head-inner" }, [
        _c("h3", { staticClass: "name" }, [_vm._v(_vm._s(_vm.orderText))]),
        !_vm.readonly
          ? _c("h4", { staticClass: "sub-text" }, [
              _vm._v(
                "商品已提交至【我的订单】，状态为待付款，若想重新加购商品，请于商品详情处重新加购商品。"
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "ds-nc-shopping-container order-posi order-padding-bottom",
        style: { minHeight: _vm.minHeight + "px" },
      },
      [
        _c("shoppingOrderItem", {
          attrs: {
            list: _vm.orderList,
            shoppingCarUrl: _vm.shoppingCarUrl,
            userCoins: _vm.coins,
            readonly: _vm.readonly,
          },
          on: { toCheckOut: _vm.toCheckOut },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }