var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-nc-shop-list-detail ds-control" },
    [
      _vm.isDesign
        ? [
            _c("div", { staticClass: "header", class: _vm.showBgStyle }, [
              _c("div", { staticClass: "header-inner" }, [
                _c(
                  "div",
                  { staticClass: "header-left-img" },
                  [_c("dsf-image")],
                  1
                ),
                _c("div", { staticClass: "header-right-desc" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("供给侧结构性改革网络布局"),
                  ]),
                  _c("div", { staticClass: "has-mj-icon" }),
                  _vm._m(0),
                  _c("ul", { staticClass: "price" }, [
                    _c(
                      "li",
                      [
                        _c("span", { staticClass: "prefix" }, [
                          _vm._v("组织价"),
                        ]),
                        _c("dsf-icon"),
                        _c("b", { staticClass: "num" }, [_vm._v("850")]),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("span", { staticClass: "prefix" }, [
                          _vm._v("个人价"),
                        ]),
                        _c("dsf-icon"),
                        _c("b", { staticClass: "num" }, [_vm._v("950")]),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("span", { staticClass: "prefix" }, [_vm._v("赠")]),
                        _c("dsf-icon"),
                        _c("b", { staticClass: "num" }, [_vm._v("850")]),
                      ],
                      1
                    ),
                  ]),
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "has-bg",
                          style: { width: _vm.btnWidth },
                        },
                        [_vm._v("发起拼单")]
                      ),
                      _c("button", { style: { width: _vm.btnWidth } }, [
                        _vm._v("单独购买"),
                      ]),
                      _vm.shopButtons.length
                        ? _vm._l(_vm.shopButtons, function (it) {
                            return _c(
                              "button",
                              {
                                key: it.text,
                                style: { width: _vm.btnWidth },
                                on: {
                                  click: function ($event) {
                                    return _vm.toEmit(it)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(it.text) + " ")]
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "group-buy" }, [
                _vm._m(2),
                _c("ul", { staticClass: "groups" }, [
                  _c(
                    "li",
                    [
                      _c("dsf-image", { staticClass: "avatar" }),
                      _c("span", [_vm._v("双杨")]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("dsf-image", { staticClass: "avatar" }),
                      _c("span", [_vm._v("双杨")]),
                    ],
                    1
                  ),
                  _c("li", [_c("dsf-image", { staticClass: "avatar" })], 1),
                ]),
                _vm._m(3),
                _c("button", { staticClass: "to-buy" }, [_vm._v("去拼单")]),
              ]),
              _c("div", { staticClass: "detail-content" }, [
                _vm._m(4),
                _c("div", { staticClass: "right-recommend" }, [
                  _c("h3", { staticClass: "title-name" }, [_vm._v("相关推荐")]),
                  _c(
                    "div",
                    {
                      staticClass: "con",
                      class: { insert: _vm.isDesign },
                      attrs: { "slot-name": "recommend" },
                    },
                    [_vm._t("recommend")],
                    2
                  ),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.it
        ? [
            _c("div", { staticClass: "header", class: _vm.showBgStyle }, [
              _c("div", { staticClass: "header-inner" }, [
                _c(
                  "div",
                  { staticClass: "header-left-img" },
                  [
                    _c("dsf-image", {
                      attrs: {
                        errorSrc: _vm.defaultSrc,
                        src: _vm._f("imgFormat")(_vm.it.cover),
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "header-right-desc" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.it.name)),
                  ]),
                  _vm.type === "special"
                    ? _c("div", { staticClass: "special-item" }, [
                        _c("span", [
                          _vm._v(
                            "必修: " + _vm._s(_vm.it.needPeriod || 0) + "学时"
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            "选修: " + _vm._s(_vm.it.noNeedPeriod || 0) + "学时"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.type === "live" || _vm.type === "channel"
                    ? _c("p", { staticClass: "dept no-after" }, [
                        _c("span", { staticClass: "resource" }, [
                          _vm._v("资源数：" + _vm._s(_vm.it.subNum || 0)),
                        ]),
                        _c("span", { staticClass: "scale" }, [
                          _vm._v("学时：" + _vm._s(_vm.it.indentNum || 0)),
                        ]),
                        _c("span", { staticClass: "pad-right" }, [
                          _vm._v("来源：" + _vm._s(_vm.it.source || "-")),
                        ]),
                        _c("span", { staticClass: "pad-right" }, [
                          _vm._v("销量：" + _vm._s(_vm.it.indentNum || 0)),
                        ]),
                        _c("span", { staticClass: "pad-right" }, [
                          _vm._v("库存：" + _vm._s(_vm.it.numLimit || 0)),
                        ]),
                      ])
                    : _c("p", { staticClass: "dept" }, [
                        _c("span", { staticClass: "resource" }, [
                          _vm._v("来源：" + _vm._s(_vm.it.source || "-")),
                        ]),
                        _c("span", { staticClass: "scale" }, [
                          _vm._v("销量：" + _vm._s(_vm.it.indentNum || 0)),
                        ]),
                        _c("span", [
                          _vm._v("库存：" + _vm._s(_vm.it.numLimit || 0)),
                        ]),
                      ]),
                  _c("ul", { staticClass: "price" }, [
                    _c("li", [
                      _c("span", { staticClass: "prefix" }, [_vm._v("组织价")]),
                      _c(
                        "span",
                        { staticClass: "num-wrap" },
                        [
                          _c("dsf-icon", { attrs: { name: "jinbi" } }),
                          _c("b", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.it.unitPrice || "免费")),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "prefix" }, [_vm._v("个人价")]),
                      _c(
                        "span",
                        { staticClass: "num-wrap" },
                        [
                          _c("dsf-icon", { attrs: { name: "jinbi" } }),
                          _c("b", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.it.userPrice || "免费")),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm.it.giftCoupon
                      ? _c("li", [
                          _c("span", { staticClass: "prefix" }, [_vm._v("赠")]),
                          _c(
                            "span",
                            { staticClass: "num-wrap" },
                            [
                              _c("dsf-icon", { attrs: { name: "quan" } }),
                              _c("b", { staticClass: "num" }, [
                                _vm._v(_vm._s(_vm.it.giftCoupon || 0)),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.it.activitySuffix
                    ? _c("p", { staticClass: "time-end" }, [
                        _c("i", { staticClass: "has-group" }, [
                          _vm._v("3人团"),
                        ]),
                        _vm._v(" 活动倒计时：02天 20时27分 "),
                      ])
                    : _vm._e(),
                  !_vm.readonly
                    ? _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "button",
                            {
                              style: { width: _vm.btnWidth },
                              on: {
                                click: function ($event) {
                                  return _vm.handleItem(2)
                                },
                              },
                            },
                            [_vm._v("加购物车")]
                          ),
                          _vm.shopButtons.length
                            ? _vm._l(_vm.shopButtons, function (it) {
                                return _c(
                                  "button",
                                  {
                                    key: it.text,
                                    style: { width: _vm.btnWidth },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toEmit(it)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(it.text) + " ")]
                                )
                              })
                            : _vm._e(),
                          _vm.it.viewFile
                            ? _c(
                                "button",
                                {
                                  style: { width: _vm.btnWidth },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleItem(3)
                                    },
                                  },
                                },
                                [_vm._v("试听")]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "detail-content" }, [
                _c("div", { staticClass: "left-intro" }, [
                  _c("h3", { staticClass: "title-name" }, [_vm._v("简介")]),
                  _vm.it
                    ? _c("p", {
                        staticClass: "intro con",
                        domProps: { innerHTML: _vm._s(_vm.it.content || "") },
                      })
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "right-recommend" }, [
                  _c("h3", { staticClass: "title-name" }, [_vm._v("相关推荐")]),
                  _c(
                    "div",
                    {
                      staticClass: "con",
                      class: { insert: _vm.isDesign },
                      attrs: { "slot-name": "recommend" },
                    },
                    [_vm._t("recommend")],
                    2
                  ),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
      !_vm.readonly
        ? _c(
            "div",
            {
              staticClass: "shoppingcars-fixed",
              style: { right: _vm.fixedStyle + "px" },
              on: { click: _vm.toShoppingCar },
            },
            [
              _c("i", { staticClass: "icon-detail-shopping" }),
              _vm.carCount
                ? _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(_vm.carCount)),
                  ])
                : _vm._e(),
              _c("span", { staticClass: "my-car" }, [_vm._v("我的购物车")]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "dept" }, [
      _c("span", { staticClass: "resource" }, [_vm._v("来源：网络学院-前端")]),
      _c("span", { staticClass: "scale" }, [_vm._v("销量：25")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "time-end" }, [
      _c("i", { staticClass: "has-group" }, [_vm._v("3人团")]),
      _vm._v(" 活动倒计时：02天 20时27分 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menmber-num menmber-bg" }, [
      _c("p", [_vm._v("3人正在拼单，可直接参与")]),
      _c("p", [_vm._v("（限组织购买）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menmber-num remind-text" }, [
      _c("p", { staticClass: "diff" }, [_vm._v("还差一人拼成")]),
      _c("p", { staticClass: "time" }, [_vm._v("剩余时间23:20:10")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-intro" }, [
      _c("h3", { staticClass: "title-name" }, [_vm._v("简介")]),
      _c("p", { staticClass: "intro con" }, [_vm._v("213")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }