<template>
  <div class="dsf_cardItemByNote" v-if="visible">
    <div class="content">
      <div class="item-wrap" v-if="isDesign">
        <div class="img-box pointer" :style="{ width: imgWidth, height: imgHeight, margin: imgMargin }">
          <el-image :src="src | imgFormat" fit="cover"></el-image>
        </div>
        <div class="info" :style="{ width: `calc(96% - ${imgWidth})` }">
          <p class="title ell">笔记名称笔记名称笔记名称</p>
          <p class="from ell2">
            来源：
            <span>数字经济时代云端一体“芯”机遇第四讲：把握机遇，携手创“芯”，共建未来</span>
          </p>
          <div class="time">
            <span class="video-time">
              <dsf-icon name="shijian2"></dsf-icon>
              00:01:38
            </span>
            <span class="video-date">2021-01-22 13:22:08</span>
          </div>
        </div>
      </div>
      <div class="item-wrap" v-else @click="_clickBefore(goDetail)">
        <div class="img-box pointer" :style="{ width: imgWidth, height: imgHeight, margin: imgMargin }">
          <!-- <el-image :src="data['cover'] | imgFormat" fit="cover"></el-image> -->
          <!-- <dsf-image :src="data.cover | imgFormat" /> -->
          <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
        </div>
        <div class="info" :style="{ width: `calc(96% - ${imgWidth})` }">
          <p class="title ell">{{ data["title"] || "" }}</p>
          <p class="from ell2">
            来源：
            <span>{{ data["from"] || "" }}</span>
          </p>
          <div class="time">
            <span class="video-time">
              <dsf-icon name="shijian2"></dsf-icon>
              {{ data["videoTime"] || "" }}
            </span>
            <span class="video-date">{{ data["time"] || 0 }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCardItemByNote",
  ctrlCaption: "笔记卡片",
  mixins: [$mixins.card],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    detailCourseUrl: {
      type: String,
      default: "",
    },
    detailLiveUrl: {
      type: String,
      default: "",
    },
    imgWidth: {
      type: String,
      default: "270px",
    },
    imgHeight: {
      type: String,
      default: "150px",
    },
    imgMargin: {
      type: String,
      default: "0 20px 0 0",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "title", to: "title", type: "text", desc: "笔记名称" },
          { from: "from", to: "from", type: "text", desc: "来源" },
          { from: "videoTime", to: "videoTime", type: "text", desc: "视频时间" },
          { from: "time", to: "time", type: "text|number", desc: "时间" },
          { from: "type", to: "type", type: "text", desc: "笔记类型" },
          { from: "nc_note_info_id", to: "id", type: "text", desc: "id" },
        ];
      },
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  components: {},
  methods: {
    goDetail() {
      if (this.$listeners["click"]) {
        this.$dispatch("click", this.data);
      } else {
        if (this.data["type"] === "course") {
          if (this.detailCourseUrl) {
            let url = this.$replace(this.detailCourseUrl, this.data);
            url && dsf.layer.openWindow(url);
          }
        } else if (this.data["type"] === "live") {
          if (this.detailLiveUrl) {
            let url = this.$replace(this.detailLiveUrl, this.data);
            url && dsf.layer.openWindow(url);
          }
        }
      }
    },
  },
});
</script>
