<template>
  <div class="dsf_nc_title_two" :style="{ padding: controlStyle }">
    <div class="title-inner">
      <div class="title">
        <span class="font">{{ title }}</span>
        <i class="line"></i>
      </div>
      <div class="title-more" v-if="showMore">
        <span slot="more" @click="_clickBefore(toUrl)">{{ moreFont }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcTitleTwo",
  ctrlCaption: "门户标题",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "推荐课程/专题",
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    moreFont: {
      type: String,
      default: "更多",
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    padding: {
      type: Array,
      default: () => [10, 10, 10, 10],
    },
  },
  data() {
    return {};
  },
  created() {
    console.log(this.padding);
  },
  computed: {
    controlStyle() {
      let { padding, isDesign } = this;
      let style = padding.join("px ") + "px";
      if (isDesign) {
        style = "10px 10px 10px 10px";
      }
      return style;
    },
  },
  methods: {
    toUrl() {
      let { url, state } = this.moreUrl;
      this.$open({ url, state });
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.$checkLogin();
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
  },
});
</script>
