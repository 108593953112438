var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_period_list", style: _vm.controlStyle }, [
        _c(
          "ul",
          { staticClass: "peridod_list_wrap" },
          _vm._l(_vm.lists, function (item, index) {
            return _c(
              "li",
              { key: index, staticClass: "lists_items", style: _vm.itemsStyle },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.unitName || "")),
                ]),
                _c("div", { staticClass: "xx" }, [
                  _vm._v(" 选修需完成 "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.electivesPeriod,
                        expression: "item.electivesPeriod",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: item.electivesPeriod,
                      onkeyup:
                        "value=value.replace(/[^\\d{1,}\\.\\d{1,}|\\d{1,}]/g,'')",
                    },
                    domProps: { value: item.electivesPeriod },
                    on: {
                      change: function ($event) {
                        return _vm.eletivesChange(item.electivesPeriod, item)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "electivesPeriod", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" / " + _vm._s(item.elePeriods || 0) + " 学时 "),
                ]),
                _c("div", { staticClass: "bx" }, [
                  _vm._v(" 必修需完成 "),
                  _vm._v(
                    " " +
                      _vm._s(item.compulsoryPeriod || 0) +
                      " / " +
                      _vm._s(item.comPeriods || 0) +
                      " 学时 "
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }