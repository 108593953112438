var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_course", style: { width: _vm.itemWidth } },
    [
      _c("dsf-nc-title", {
        attrs: {
          moreUrl: _vm.moreUrl,
          padding: _vm.itemPadding,
          title: _vm.title,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }