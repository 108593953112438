var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "ds-upload",
      attrs: {
        width: "800px",
        title: "图片裁剪",
        "append-to-body": true,
        visible: _vm.editImg,
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.editImg = $event
        },
      },
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "450px" } },
        [
          _c(
            "el-main",
            { staticStyle: { padding: "0" } },
            [
              _c(
                "vue-picture-cut",
                {
                  ref: "pictureCut",
                  attrs: {
                    src: _vm.src,
                    "msk-option": _vm.mskRatio,
                    "menu-thickness": 0,
                  },
                },
                [
                  _c("vue-picture-cut-mask", {
                    attrs: {
                      width: _vm.mskRatio.width,
                      height: _vm.mskRatio.height,
                      "is-round": false,
                      resize: _vm.resize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-aside",
            { staticClass: "edit-img", staticStyle: { width: "130px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.rotate(-15)
                    },
                  },
                },
                [_vm._v("顺时针转")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.rotate(15)
                    },
                  },
                },
                [_vm._v("逆时针转")]
              ),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.scaleH } },
                [_vm._v("水平镜像")]
              ),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.scaleV } },
                [_vm._v("垂直镜像")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.zoom(0.96)
                    },
                  },
                },
                [_vm._v("缩小")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.zoom(1.04)
                    },
                  },
                },
                [_vm._v("放大")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTipsText,
                  expression: "showTipsText",
                },
              ],
              staticStyle: {
                position: "absolute",
                "font-size": "26px",
                left: "30%",
                color: "red",
              },
            },
            [_vm._v(_vm._s(_vm.tipsText))]
          ),
          _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重 置")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFileImg } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }