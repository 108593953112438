<template>
  <div class="dsf_nc_saas saas_setting_footer_model1">
    <div class="subtitle margint20 marginb20">效果预览</div>
    <footerOne itemWidth="100%" margin="9px 0 50px 0" :data="data" />
    <div class="setting">
      <div>
        <div>
          <div class="subtitle margint20 marginb20">基本信息配置</div>
          <div class="line">
            <label>座机</label>
            <div>
              <el-input type="text" v-model="data.companyPhone" placeholder="请输入座机"></el-input>
            </div>
          </div>
          <div class="line">
            <label>公司地址</label>
            <div>
              <el-input type="text" v-model="data.addressOne" placeholder="请输入公司地址"></el-input>
            </div>
          </div>
        </div>
        <div>
          <div class="subtitle margint20 marginb20">数据配置</div>
          <div class="line" v-for="(source, i) in sourceList" :key="i">
            <label>{{ source.name }}</label>
            <div>
              <el-select type="text" v-model="data.dataSource[i]" placeholder="请选择显示数据">
                <el-option v-for="item in source.selection" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="subtitle margint20 marginb20">底部信息配置</div>
        <div class="line">
          <label>备案号</label>
          <div>
            <el-input type="text" v-model="data.internetNumOne" placeholder="请输入备案号"></el-input>
          </div>
        </div>
        <div class="line">
          <label>公安备案图标</label>
          <div class="upload">
            <el-upload
              accept="image/jpg,image/jpeg,image/png"
              class="avatar-uploader"
              action="/file/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <DsfImage v-if="data.policeInternetImgOne" :src="JSON.parse(data.policeInternetImgOne || '{}').relativePath" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-upload accept="image/jpg,image/jpeg,image/png" action="/file/upload" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <dsf-button size="small">上传</dsf-button>
            </el-upload>
            <dsf-button size="small" type="plain" @click="clearIcon">删除</dsf-button>
            <dsf-nc-crop-image
              :show-tips-text="true"
              :resize="false"
              :edit-img="editImg"
              :edit-file-img="editFileImg"
              :aspect-ratio="aspectRatio"
              :request-url="setRequestUrl"
              @saveFile="editFile"
              @handleClose="handleClose"
            ></dsf-nc-crop-image>
          </div>
        </div>
        <div class="line">
          <label>公安备案号</label>
          <div>
            <el-input type="text" v-model="data.policeInternetNumOne" placeholder="请输入公安备案号"></el-input>
          </div>
        </div>
        <div class="line">
          <label>等级备案号</label>
          <div>
            <el-input type="text" v-model="data.levelInternetNumOne" placeholder="请输入等级备案号"></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <dsf-button type="plain" @click="backPage">取消</dsf-button>
      <dsf-button type="primary" @click="saveModel">保存</dsf-button>
    </div>
  </div>
</template>
<script>
import footerOne from "../../base/saas/footerOne.vue";
export default dsf.component({
  name: "DsfNcSaasSettingFooterModel1",
  ctrlCaption: "页尾模板设置1",
  mixins: [$mixins.control],
  props: {
    bindURL: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { footerOne },
  data() {
    return {
      editImg: false,
      editFileImg: "",
      aspectRatio: "20/20",
      radioValue: null,
      sourceList: [
        {
          name: "数据一",
          selection: [
            { label: "资源总量", value: "resourceNum" },
            { label: "今日点击访问量", value: "todayView" },
            { label: "历史点击访问量", value: "totalView" },
          ],
        },
        {
          name: "数据二",
          selection: [
            { label: "资源总量", value: "resourceNum" },
            { label: "今日点击访问量", value: "todayView" },
            { label: "历史点击访问量", value: "totalView" },
          ],
        },
        {
          name: "数据三",
          selection: [
            { label: "资源总量", value: "resourceNum" },
            { label: "今日点击访问量", value: "todayView" },
            { label: "历史点击访问量", value: "totalView" },
          ],
        },
      ],
    };
  },
  computed: {
    setRequestUrl() {
      return dsf.url.getWebPath("file/upload/");
    },
  },
  methods: {
    beforeAvatarUpload() {},
    handleAvatarSuccess(res) {
      this.editFileImg = res.data[0];
      this.editImg = true;
    },
    editFile(files) {
      this.editImg = false;
      this.data.policeInternetImgOne = JSON.stringify(files);
    },
    handleClose() {
      this.editImg = false;
    },
    clearIcon() {
      this.data.policeInternetImgOne = "";
    },
    saveModel() {
      this.$emit("saveModel", { ...this.data, dataSource: JSON.stringify(this.data.dataSource) });
    },
    backPage() {
      this.$emit("back");
    },
  },
});
</script>
