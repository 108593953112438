var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-change-phone" }, [
    _c("section", [
      _c("div", { staticClass: "ds-change-phone-box" }, [
        _c("p", [_vm._v("更换手机号后，下次登录可以使用新手机号登录。")]),
        _c("p", [_vm._v("当前手机号：" + _vm._s(_vm.userInfo.phone))]),
        _c("div", { staticClass: "tips" }),
        _c("label", { staticClass: "input" }, [
          _c("i", { staticClass: "beforeIcon iconfont icon-lianxidianhua" }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.phoneNumber,
                expression: "phoneNumber",
                modifiers: { trim: true },
              },
            ],
            attrs: {
              maxlength: "11",
              type: "text",
              placeholder: "请输入手机号码",
            },
            domProps: { value: _vm.phoneNumber },
            on: {
              blur: [
                _vm.blurInput,
                function ($event) {
                  return _vm.$forceUpdate()
                },
              ],
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phoneNumber = $event.target.value.trim()
              },
            },
          }),
        ]),
        _c("div", { staticClass: "input" }, [
          _c("i", { staticClass: "beforeIcon iconfont icon-yanzhengma1" }),
          _c(
            "div",
            {
              staticClass: "input-bt",
              class: [_vm.codeBtType],
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.getCode.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.codeBtType != ""
                      ? _vm.countdownTime + "s"
                      : "获取验证码"
                  ) +
                  " "
              ),
            ]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.phoneCode,
                expression: "phoneCode",
                modifiers: { trim: true },
              },
            ],
            attrs: {
              maxlength: "12",
              type: "text",
              placeholder: "请输入验证码",
            },
            domProps: { value: _vm.phoneCode },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phoneCode = $event.target.value.trim()
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm.codeAuthResult && _vm.phoneCode
          ? _c("div", { staticClass: "error-tips" }, [
              _c("i", { staticClass: "iconfont icon-tixingshixin" }),
              _vm._v(" " + _vm._s(_vm.codeAuthResult) + " "),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "btns" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: {
                  disabled: !_vm.isSendCode,
                  loading: _vm.isWaiting,
                  block: "",
                },
                on: { click: _vm.nextStep },
              },
              [_vm._v("确 认")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }