var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf_nc_zxqy_footer",
      style: { width: _vm.itemWidth, margin: _vm.margin },
    },
    [
      _c("div", { staticClass: "nc_footer_inner" }, [
        _c("div", { staticClass: "one_wrap" }, [
          _c(
            "div",
            { staticClass: "img" },
            [_c("dsf-nc-image", { attrs: { src: _vm.sydwImg } })],
            1
          ),
          _c(
            "div",
            { staticClass: "img" },
            [_c("dsf-nc-image", { attrs: { src: _vm.zfwzImg } })],
            1
          ),
        ]),
        _c("div", { staticClass: "two_wrap" }, [
          _c("div", { staticClass: "address" }, [_vm._v(_vm._s(_vm.address))]),
          _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.email))]),
          _c("div", { staticClass: "copy" }, [_vm._v(_vm._s(_vm.copy))]),
          _c("div", { staticClass: "records" }, [_vm._v(_vm._s(_vm.records))]),
        ]),
        _c("div", { staticClass: "three_wrap" }, [
          _c("div", { staticClass: "servephone" }, [
            _c("p", [_vm._v(_vm._s(_vm.servephoneText))]),
            _c("p", [_vm._v(_vm._s(_vm.servephoneNo1))]),
            _c("p", [_vm._v(_vm._s(_vm.servephoneNo2))]),
          ]),
          _c("div", { staticClass: "card_serve_phone" }, [
            _c("p", [_vm._v(_vm._s(_vm.cardServephoneTxt))]),
            _c("p", [_vm._v(_vm._s(_vm.cardServephoneNo1))]),
          ]),
        ]),
        _c("div", { staticClass: "four_wrap" }, [
          _c(
            "div",
            { staticClass: "app qr_box" },
            [
              _c("span", [_vm._v(_vm._s(_vm.appValue[0] || ""))]),
              _c("dsf-nc-image", { attrs: { src: _vm.appValue[1] } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "wechat qr_box" },
            [
              _c("span", [_vm._v(_vm._s(_vm.wechatValue[0] || ""))]),
              _c("dsf-nc-image", { attrs: { src: _vm.wechatValue[1] } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "TikTok qr_box" },
            [
              _c("span", [_vm._v(_vm._s(_vm.tiktokValue[0] || ""))]),
              _c("dsf-nc-image", { attrs: { src: _vm.tiktokValue[1] } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "video qr_box" },
            [
              _c("span", [_vm._v(_vm._s(_vm.videoValue[0] || ""))]),
              _c("dsf-nc-image", { attrs: { src: _vm.videoValue[1] } }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }