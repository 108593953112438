<template>
  <DsfSection
    mainWidth="1200px"
    :margin="[0, 0, 0, 0]"
    :padding="[10, 10, 10, 10]"
    :backgroundConfig="{
      color: '#fff',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
    :foregroundConfig="{
      color: 'rgba(255, 255, 255, 0)',
      image: '',
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    }"
  >
    <!--nctitle38-->
    <DsfNcTitle
      v-if="showTitle"
      :showMore="showMore"
      :title="title"
      caption="DsfNcTitle"
      :showBottomLine="true"
      titleSize="big"
      :titleBold="false"
      :clickConfig="clickConfigTitle"
      padding="80px 0 60px 0"
    ></DsfNcTitle>
    <!--platformcardbox-->
    <DsfPlatformCardBox :columnNumber="2" :columnWidth="cardWidth" marginTop="15px" rowKey="_id" url="/inc/nc/Meta/tree/dbSource/id/?rmId=cd97915a3b3d4d8baa07a139a6768bbf" :checkbox="false">
      <template v-slot:default="scope">
        <!--nccarditembywonderfullive-->
        <DsfNcCardItemByWonderfulLive :local="scope.row" :clickConfig="clickConfig" :cardWidth="cardWidth" :detailUrl="detailUrl" :keysMap="keysMap"></DsfNcCardItemByWonderfulLive>
      </template>
    </DsfPlatformCardBox>
  </DsfSection>
</template>

<script>
export default dsf.component({
  name: "DsfNcSaasHomeJingcaizhibo",
  ctrlCaption: "精彩直播",
  mixins: [$mixins.control],
  props: {
    sourceUrl: {
      type: String,
      default: "/inc/meta/tree/dbSource/id/?rmId=cd97915a3b3d4d8baa07a139a6768bbf",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      clickConfigTitle: {
        url: "/ncIndex.html#/pc/nc/pagelive/wonderfulLive",
        state: 4,
        title: "对话框标题",
        width: "1000px",
        height: "60vh",
      },
      clickConfig: {
        url: "#/pc/nc/pagelive/liveDetail?id=",
        state: 2,
      },
      keysMap: [
        {
          from: "cover",
          to: "cover",
          type: "text",
          desc: "封面",
        },
        {
          from: "state_text",
          to: "liveTxt",
          type: "text",
          desc: "直播文字",
        },
        {
          from: "state_value",
          to: "type",
          type: "text|number",
          desc: "直播状态",
        },
        {
          from: "subscribe",
          to: "subscribe",
          type: "text",
          desc: "是否预约",
        },
        {
          from: "time_sdate",
          to: "time",
          type: "text|number",
          desc: "直播时间",
        },
        {
          from: "title",
          to: "title",
          type: "text",
          desc: "标题",
        },
        {
          from: "speaker",
          to: "teacher",
          type: "text",
          desc: "授课教师",
        },
        {
          from: "job",
          to: "job",
          type: "text",
          desc: "职位",
        },
        {
          from: "nc_live_info_id",
          to: "id",
          type: "text",
          desc: "id",
        },
        {
          from: "login_limit_text",
          to: "login_limit_text",
          type: "text",
          desc: "登录校验文字",
        },
        {
          from: "login_limit_value",
          to: "login_limit_value",
          type: "text",
          desc: "登录校验值",
        },
      ],
      detailUrl: "/page.html#/pc/nc/pagelive/liveDetail?id=",
      cardWidth: "580px",
    };
  },
  computed: {
    title() {
      return this.data["bindAttr"]["title"];
    },
    showTitle() {
      return this.data["bindAttr"]["showTitle"] == 1 ? true : false;
    },
    showMore() {
      return this.data["bindAttr"]["showMore"] == 1 ? true : false;
    },
  },
  created() {},
  mounted() {},
  methods: {},
});
</script>
