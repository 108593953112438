<template>
  <div :style="controlStyle" class="ds-virtual-list ds-control ds-no-padding">
    <div
      class="ds-virtual-list-warp"
      :is-design="isDesign"
      :style="panelStyle"
      :pointer="pointer"
      v-bind="{
        hoverBright: styleAttrs.hoverBright,
        dividerLine: styleAttrs.dividerLine,
      }"
    >
      <template v-if="isDesign">
        <div class="ds-virtual-list-design" slot-name="list-row">
          <slot name="list-row" v-bind="{ row: {}, $index: 0, $hash: 1 }"></slot>
        </div>
      </template>
      <template v-else>
        <dsf-empty-data v-if="loaded && (!fliterList || !fliterList.length)" :text="tabType"></dsf-empty-data>
        <template v-else>
          <template v-for="(row, index) in fliterList">
            <!-- v-if="status == 0 ? row.status == 1 : true" -->
            <div
              :key="index"
              class="ds-virtual-list-row"
              :style="{
                padding: rowPadding.join('px ') + 'px',
                margin: rowMargin.join('px ') + 'px',
              }"
              @click="rowClick({ row, $index: index, $hash: row.$hash })"
            >
              <slot name="list-row" v-bind="{ row, $index: index, $hash: row.$hash }"></slot>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcVirtualList",
  ctrlCaption: "网络学院标签面板",
  mixins: [$mixins.control],
  design: {
    isMask: false,
  },
  props: {
    tabActive: {
      type: Object,
    },
    isBeenRead: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 0,
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "list-row",
            controls: [],
            scope: "scope",
            repeat: true,
          },
        ];
      },
    },
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "auto",
    },
    // 风格
    styleType: {
      type: String,
      default: "one",
    },
    // 列表数据
    list: {
      type: Array,
      default: () => [],
    },
    // url
    url: {
      type: String,
      default: "",
    },
    urlQuery: {
      type: Object,
      default: () => {},
    },
    padding: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
    margin: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
    rowPadding: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
    rowMargin: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
    styleAttrs: {
      type: Object,
      default: () => ({
        hoverBright: false,
        dividerLine: false,
      }),
    },
    max: {
      type: Number,
      default: null,
    },
    pointer: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    fliterList() {
      let { listData, max, status } = this;
      let Array = [];
      // if (!max) return listData || [];
      if (status == 0) {
        listData.forEach(item => {
          if (item.status == 0 || (item.status == "1" && item.type == "0")) {
            Array.push(item);
          }
        });
        return Array;
      }
      // if (status == 1) return listData;
      return listData;
      // return listData.slice(0, max);
    },
    controlStyle() {
      let { width, margin, height, isDesign, listData } = this;
      let style = {
        width,
        height,
        padding: margin.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "10px 10px 10px 10px";
      }
      if (height == "auto" && (!listData || !listData.length)) {
        style.height = "100%";
      }
      return style;
    },
    panelStyle() {
      let { height, padding, listData } = this;
      let style = {
        height,
        padding: padding.join("px ") + "px",
      };
      if (height == "auto" && (!listData || !listData.length)) {
        style.height = "100%";
      }
      return style;
    },
    tabType() {
      if (this.url.split("type=")[1].split("&")[0] == "workrecord") {
        return "暂无待办";
      } else if (this.url.split("type=")[1].split("&")[0] == "announcement") {
        return "暂无新消息";
      }
      return "";
    },
  },

  data() {
    return {
      loaded: false,
      listData: [],
    };
  },

  watch: {
    isBeenRead: {
      immediate: true,
      handler(v) {
        let that = this;
        if (this.url.indexOf("announcement") != -1) {
          let _idArr = [];
          if (v) {
            this.listData.forEach(item => {
              _idArr.push(item._id);
            });
          }

          if (_idArr.length > 0) {
            dsf.layer
              .confirm("确定全部标记为已读吗?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(function () {
                //确认，执行逻辑
                let header = {
                  "Content-Type": "application/json;charset=utf-8",
                };
                that.$http
                  .post("/nc/index/notice/markReadAllByIds", JSON.stringify(_idArr), { headers: header })
                  .done(function (res) {
                    if (res.success) {
                      dsf.layer.message("操作成功", true);
                      that.reloadData();
                    } else {
                      dsf.layer.message(res.message || "请求异常", false);
                    }
                  })
                  .error(function (err) {
                    //错误
                    dsf.layer.message((err && err.message) || "请求异常", false);
                  })
                  .always(function () {
                    //不管成功失败都会执行（请求，比如404，200）
                  });
              })
              .catch(function () {
                //取消
              });
          }
        }
      },
    },
    listData: {
      immediate: true,
      handler(v = []) {
        _.each(v, it => {
          !("$hash" in it) && this.$set(it, "$hash", dsf.uuid());
        });
        this.$emit("list-change", v);
      },
    },
    list: {
      immediate: true,
      handler(v = []) {
        this.listData = v;
      },
    },
    url: {
      immediate: true,
      handler() {
        this.getDataUrl();
      },
    },
    tabActive: {
      immediate: true,
      handler(n) {
        if (n && n["name"] == "announcement") {
          console.log(n);
        }
        console.log(this.fliterList);
      },
    },
    urlQuery() {
      this.getDataUrl();
    },
    fliterList: {
      immediate: true,
      deep: true,
      handler(n) {
        // console.log(this.tabActive);
        // if (this.url.split("type=")[1].split("&")[0] == "workrecord") {
        //   console.log(n.length);
        //   this.$emit("count", n.length);
        // } else if (this.url.split("type=")[1].split("&")[0] == "announcement") {
        //   this.$emit("count", n.length);
        //   console.log(n.length);
        // }
      },
    },
  },

  created() {},

  methods: {
    getDataUrl() {
      let { url } = this;

      if (this.isDesign || !url) return;
      if (this.getDataUrlSetTimeout) clearTimeout(this.getDataUrlSetTimeout);
      this.getDataUrlSetTimeout = setTimeout(() => {
        let param = {
          ...(this.urlQuery || {}),
        };

        this.$http
          .get(url, param)
          .then(({ data }) => {
            if (data && data.success) {
              data.data.forEach(item => {
                item.title = item.title.split("]")[1];
              });
              this.listData = data.data || [];
            } else {
              dsf.layer.message(data.message, false);
            }
          })
          .catch(err => {
            dsf.layer.message("未知错误", false);
          })
          .finally(() => {
            this.loaded = true;
          });
      }, 400);
    },
    reloadData() {
      this.getDataUrl();
      _.each(this.$children, child => {
        child?.reloadData && child.reloadData();
      });
    },
    rowClick(args) {
      this.$dispatch("row-click", args);
    },
  },
});
</script>
