var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf-register-model-two-pc-control",
      style: _vm.controlStyle,
    },
    [
      _c(
        "div",
        { staticClass: "close-btn" },
        [
          _c("dsf-icon", {
            attrs: { name: "guanbi2" },
            on: {
              click: function ($event) {
                return _vm.$root.close()
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "register_form" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("注册账号")]),
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form-box",
                attrs: { model: _vm.form, "label-width": "67px" },
              },
              [
                _c("DsfNcPcRegisterFormation", {
                  attrs: {
                    columnsNum: 3,
                    fromValues: _vm.fromValues,
                    labelWidth: "67px",
                  },
                  on: { onSubmit: _vm.updateFormValue },
                }),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.checkMsgCode,
                        expression: "checkMsgCode",
                      },
                    ],
                    attrs: { label: "验证码" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "code-form" },
                      [
                        _c("el-input", {
                          staticClass: "code-input",
                          attrs: { placeholder: "验证码" },
                          model: {
                            value: _vm.form.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "code", $$v)
                            },
                            expression: "form.code",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "form-getCode",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.getCodeFun.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.codeBtType != ""
                                  ? _vm.countdownTime + "s"
                                  : "获取验证码"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { staticClass: "requiredFrom", attrs: { label: "密码" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.isShowPwd ? "text" : "password",
                          placeholder: "密码",
                        },
                        model: {
                          value: _vm.form.pwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pwd", $$v)
                          },
                          expression: "form.pwd",
                        },
                      },
                      [
                        _c("dsf-icon", {
                          staticClass: "pointer",
                          attrs: {
                            slot: "suffix",
                            name: _vm.isShowPwd ? "yanjing1" : "biyan",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isShowPwd = !_vm.isShowPwd
                            },
                          },
                          slot: "suffix",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "messageTips" }, [
                  _vm._v(_vm._s(_vm.messageTips)),
                ]),
                _c(
                  "div",
                  { staticClass: "clause" },
                  [
                    _c("el-radio", {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.clause,
                        callback: function ($$v) {
                          _vm.clause = $$v
                        },
                        expression: "clause",
                      },
                    }),
                    _vm._v(" 我已阅读并同意 "),
                    _c(
                      "span",
                      {
                        staticClass: "span-color pointer",
                        on: { click: _vm.handleClause },
                      },
                      [_vm._v("《注册条款》")]
                    ),
                    _vm._v(" 和 "),
                    _c(
                      "span",
                      {
                        staticClass: "span-color pointer",
                        on: { click: _vm.handlePolicy },
                      },
                      [_vm._v("《隐私政策》")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "btn", attrs: { "label-width": "0" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "submit-btn",
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v("注册")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "hint" }, [
              _vm._v(" 已有账号？ "),
              _c(
                "span",
                { staticClass: "pointer", on: { click: _vm.goLogin } },
                [_vm._v("去登录")]
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }