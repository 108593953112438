<template>
  <!-- showFace1 -->
  <el-dialog class="face" title="提示" :visible.sync="showFace" :show-close="false" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true">
    <template>
      <div class="faceQrcode">
        <img :src="socketImg" alt="" />
      </div>
      <div class="desc">扫一扫人脸识别</div>
    </template>
  </el-dialog>
</template>

<script>
export default dsf.component({
  name: "DsfNcFace",
  ctrlCaption: "人脸识别弹窗",
  mixins: [$mixins.control],
  props: {
    specialId: {
      type: String,
      // default: "d7672eff82d14b1ead23aab461ce3b3e",
      default: "",
    },
    isShow: {
      type: Boolean,
      default: false,
    }
  },
  computed: {},
  components: {},
  data() {
    return {
      socketImg:"",
      showFace: false,
      time: 10, //分钟数
      seconds: "",
      flag: true,
    };
  },
  created() {
    if (localStorage.getItem("verifyTime")) {
      this.time = Number(localStorage.getItem("verifyTime"));
    }
    if (localStorage.getItem("isShowFace")) {
      this.isShow = JSON.parse(localStorage.getItem("isShowFace")) == 1 ? true : false;
    }
  },
  mounted() {
    if (this.specialId && this.isShow) this.faceTimeOut();
    // this.showFace = true;
  },
  methods: {
    faceTimeOut() {
      setTimeout(() => {
        this.ws();
      }, this.time * 60000);
    },
    faceInteval() {
      if (localStorage.getItem("socketUrl") && dsf.getCookie("userId")) {
        this.seconds = this.time * 60;
        this.timer = setInterval(() => {
          this.seconds = this.seconds - 1;
          console.log(this.seconds);
          if (this.seconds == 0) {
            this.ws();
            this.seconds = this.time * 60;
          }
        }, 1000);
      }
    },
    ws() {
      if (localStorage.getItem("socketUrl") && dsf.getCookie("userId") && this.flag) {
        let ws = new WebSocket(`${localStorage.getItem("socketUrl")}/${dsf.getCookie("userId")}`);
        ws.onopen = () => {
          //当WebSocket创建成功时，触发onopen事件
          // localStorage.setItem("isFace", JSON.stringify(true));
          console.log("通道链接成功了");
          this.showFace = true;
          dsf.http.get("/face/getQrCode", {}, $$webRoot.nc)
          .done(res => {
            this.socketImg = res.data
          })
          this.$emit("open");
          this.flag = false;
        };
        ws.onmessage = e => {
          // 接受到服务器传来的消息
          this.flag = true;
          let data = JSON.parse(e.data);
          // data["_header"]["isPass"] = true;
          ws.close();
          if (data["_header"]["isPass"]) {
            // 人脸识别通过，关闭弹窗
            this.$emit("shure");
            this.showFace = false;
            // clearInterval(this.timer);
            // this.faceInteval();
          } else {
            dsf.layer.message("人脸识别失败！");
            localStorage.setItem("isFace", JSON.stringify(false));
          }
        };
        ws.onclose = e => {
          //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
          console.log("连接关闭");
          if (this.timer) clearInterval(this.timer);
          // this.faceInteval();
          // dsf.layer.message("服务器异常！", false);
        };
      }
    },
  },
  beforeDestroy() {},
});
</script>
