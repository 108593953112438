<template>
  <div class="dsf_nc_title" :style="{ padding: padding }">
    <div class="title-inner">
      <div class="title-wrap">
        <h3 class="title" :class="{ 'has-bottom-line': showBottomLine }" :style="showTitleSize">
          {{ title }}
        </h3>
        <div class="title-more" v-if="showMore">
          <span slot="more" @click="_clickBefore(toUrl)">更多>></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcTitle",
  ctrlCaption: "门户标题",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "标题",
    },
    clickConfig: {
      type: Object,
      default: null,
    },
    showBottomLine: {
      type: Boolean,
      default: true,
    },
    titleSize: {
      type: String,
      default: "big",
    },
    titleBold: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "50px 0 10px 0",
    },
    showMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    showTitleSize() {
      const config = {
        big: "font-size:32px",
        middle: "font-size:28px",
        small: "font-size:24px",
      };

      return `${config[this.titleSize]};font-weight:${this.titleBold ? 600 : 500}`;
    },
  },
  methods: {
    toUrl() {
      // this.moreUrl && this.$router.push(this.moreUrl);
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.$checkLogin();
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
  },
});
</script>
