var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "dsf_teach_assistant_billboard" }, [
        _vm._m(0),
        _c(
          "ul",
          { staticClass: "class_situation_wrap" },
          _vm._l(_vm.classSituationData, function (item, index) {
            return _c(
              "li",
              { key: index, staticClass: "class_situation_items" },
              [
                _c(
                  "div",
                  { staticClass: "img_box" },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "96px", height: "96px" },
                        attrs: { src: item.imgIcon },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.dsf.config.setting_error_img,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "situation_right" }, [
                  _c("div", { staticClass: "count" }, [
                    _vm._v(_vm._s(item.count)),
                  ]),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._m(1),
        _c(
          "ul",
          { staticClass: "learning_progress_wrap" },
          _vm._l(_vm.learningProgressData, function (item, index) {
            return _c(
              "li",
              {
                key: index + "progress",
                staticClass: "learning_progress_items",
              },
              [
                _c("div", { staticClass: "learning_progress_items_inner" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.text)),
                  ]),
                  _c("div", { staticClass: "progress" }, [
                    _vm._v(_vm._s(item.progress)),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._m(2),
        _c(
          "div",
          { staticClass: "online_situation", attrs: { id: "onlineSituation" } },
          [
            _c("div", {
              ref: "onlineSituationEchats",
              staticClass: "online_situation_inner",
              style: { width: "100%", height: "280px" },
              attrs: { id: "onlineSituationEchats" },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teach_assistant_title" }, [
      _c("span", { staticClass: "font" }, [_vm._v("班级概况")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teach_assistant_title" }, [
      _c("span", { staticClass: "font" }, [_vm._v("学习进度")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teach_assistant_title" }, [
      _c("span", { staticClass: "font" }, [_vm._v("在线情况")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }