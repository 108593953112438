var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c(
        "div",
        {
          staticClass: "dsf_nc_footer_three",
          style: { width: _vm.itemWidth, margin: _vm.margin },
        },
        [
          _c("div", { staticClass: "nc-footer-inner" }, [
            _c("div", { staticClass: "footer-top" }, [
              _c(
                "div",
                { staticClass: "wrap" },
                _vm._l(_vm.data.footUrls, function (nav, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: nav.name,
                          expression: "nav.name",
                        },
                      ],
                      key: index,
                      staticClass: "regards",
                      on: {
                        click: function ($event) {
                          return _vm.toPage(nav.url)
                        },
                      },
                    },
                    [_vm._v(_vm._s(nav.name))]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "footer-bottom" }, [
              _c("div", { staticClass: "internet_provider" }, [
                _vm._v("沪ICP备18014503号-5"),
              ]),
              _c("div", { staticClass: "internet_provider" }, [
                _vm.data.policeInternetImgTwo
                  ? _c("img", {
                      staticClass: "police-icon-new",
                      attrs: {
                        src: JSON.parse(_vm.data.policeInternetImgTwo)
                          .relativePath,
                      },
                    })
                  : _c("i", { staticClass: "polic-icon" }),
                _c("span", [_vm._v("沪公网安备 31010602006053")]),
              ]),
              _c("div", { staticClass: "internet_provider" }, [
                _vm._v("等级备案号：21010643029-21001"),
              ]),
            ]),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "dsf_nc_footer_three",
          style: { width: _vm.itemWidth, margin: _vm.margin },
        },
        [
          _c("div", { staticClass: "nc-footer-inner" }, [
            _c("div", { staticClass: "footer-top" }, [
              _c(
                "div",
                { staticClass: "wrap" },
                _vm._l(_vm.data.footUrls, function (nav, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: nav.name,
                          expression: "nav.name",
                        },
                      ],
                      key: index,
                      staticClass: "regards",
                      on: {
                        click: function ($event) {
                          return _vm.toPage(nav.url)
                        },
                      },
                    },
                    [_vm._v(_vm._s(nav.name))]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "footer-bottom" }, [
              _vm.data.internetNumTwo
                ? _c(
                    "div",
                    {
                      staticClass: "internet_provider",
                      on: {
                        click: function ($event) {
                          return _vm.toPage(_vm.copyNumberSourceUrl)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.data.internetNumTwo))]
                  )
                : _vm._e(),
              _vm.data.policeInternetNumTwo
                ? _c(
                    "div",
                    {
                      staticClass: "internet_provider",
                      on: {
                        click: function ($event) {
                          return _vm.toPage(_vm.policeNumberSourceUrl)
                        },
                      },
                    },
                    [
                      _vm.data.policeInternetImgTwo
                        ? _c("img", {
                            staticClass: "police-icon-new",
                            attrs: {
                              src: JSON.parse(_vm.data.policeInternetImgTwo)
                                .relativePath,
                            },
                          })
                        : _c("i", { staticClass: "polic-icon" }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.data.policeInternetNumTwo)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.data.levelInternetNumTwo
                ? _c(
                    "div",
                    {
                      staticClass: "internet_provider",
                      on: {
                        click: function ($event) {
                          return _vm.toPage(_vm.levNumberSourceUrl)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "等级备案号：" + _vm._s(_vm.data.levelInternetNumTwo)
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }