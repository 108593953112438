<!-- PC端保利威视直播播放器 -->
<template>
  <div class="dsfNcLivePlayer" :style="{ padding: playerPadding, marign: playerMargin }" :class="{ isDesign: isDesign }">
    <div v-if="isDesign" class="dsfNcLivePlayer-isDesign">
      <dsf-icon name="icon_video"></dsf-icon>
      当前暂无直播
    </div>
    <div class="playerWarp" :class="{ fullScreen: isFullScreen }" :style="{ width: playerWarpWidth, height: playerWarpHeight }" v-else>
      <div id="liveVideo" :class="{ isSwitch: isSwitch, fullScreen: isFullScreen, infont: infont }" :style="{ height: liveVideoHeight, width: liveVideoWidth }"></div>
      <div id="pptVideo" v-if="showPptVideo" :style="{ width: pptVideoWidth, height: pptVideoHeight }" :class="{ isSwitch: isSwitch, fullScreen: isFullScreen, infont: !infont }"></div>
      <div id="chatwrap" :style="{ top: chatWarpTop, height: chatHeight }"></div>
      <div class="ppt-control-use" :style="{ width: controlWidth }" v-if="showControl"></div>
      <!--  -->
      <span class="ppt-apply is-apply" :style="{ right: parseInt(this.charWidth) - 110 + 'px' }" v-if="onLine" :class="{ 'apply-cancel': onLineing }" id="applyContact" @click="handleOnline">
        {{ lineText }}
      </span>
      <div id="local" :style="{ height: localHeight, width: localWidth, top: localTop }"></div>
      <div id="local2" :style="{ height: localHeight, width: localWidth, top: localTop }"></div>
    </div>

    <div class="player-signed-in" v-if="showSigned">
      <div class="signed-head">签到</div>
      <div class="signed-content">
        <div class="player-signed">
          <div>
            <span class="signed-time">{{ Signtime }}</span>
            秒
          </div>
          <li class="sign-message">{{ signMessage }}</li>

          <span class="btn-signed" @click="sign">签到</span>
        </div>
      </div>
    </div>

    <div id="isvideo-box" v-if="mutedPlay">
      是否允许浏览器播放声音?
      <span class="ppt-control-volume-cancel isvideo" @click="handleMuted">播放声音</span>
      <span class="ppt-control-volume-close isvideoclose" @click="mutedPlay = false">忽略</span>
    </div>
  </div>
</template>

<script>
/**
 * 设置播放器参数
 * @param autoplay 自动播放
 * @param audioMode 讲师画面以音频模式播放(只在移动端生效), 默认false
 * @param controller 是否显示控制栏
 * @param pptNav 是否显示ppt控制控件
 * @param barrage 弹幕 是否开启弹幕 true false
 * @param defaultBarrageStatus: 播放器加载时弹幕转台是否显示开启，默认 true
 * @param switchPlayer  切换按钮
 * @param fixedController  固定显示控制栏，设置后控制栏不跟随鼠标离开隐藏, 默认false，该设置只在桌面端生效
 * @param controllerPosition  固定位置 'ppt'|'player'
 * @param pptPlaceholder?: boolean：是否在直播模式并且当前暂无直播时在ppt区域显示占位图，默认false
 */
/**
 * 设置聊天室参数
 * @param enableFlower 送花功能 开启true 关闭false
 * @param enableLike 点赞功能 开启true 关闭false
 * @param customChatColor 消息颜色 '#2495c7'
 * @param enableRewardAnimation  打赏动画效果 true false
 * @param enableUpdatePageviews  热度更新 true false
 * @param tabData  菜单栏 true false
 */
export default dsf.component({
  name: "DsfNcLivePlayer",
  ctrlCaption: "PC端保利威视直播播放器",
  mixins: [$mixins.control],
  props: {
    lang: {
      type: String,
      default: "",
    },
    liveId: {
      type: String,
      default: "",
    },
    liveInfo: {
      type: Object,
      default: () => {
        return;
      },
    },
    channelId: {
      type: String,
      default: "",
    },
    isDesign: {
      type: Boolean,
      default: false,
      des: "设计器中",
    },
    isBackPlay: {
      type: Boolean,
      default: false,
      des: "是否回放",
    },
    sourceUrl: {
      type: String,
      default: "nc/live/info",
    },
    playerWidth: {
      type: String,
      default: "1000px",
    },
    playerHeight: {
      type: String,
      default: "800px",
    },
    charWidth: {
      type: String,
      default: "300px",
      des: "聊天室宽度",
    },
    playerPadding: {
      type: String,
      default: "",
    },
    playerMargin: {
      type: String,
      default: "",
    },
    PolyvParams: {
      type: Object,
      //播放器参数
      default: () => {
        return {
          autoplay: true,
          audioMode: false,
          controller: true,
          pptNav: true,
          barrage: true,
          defaultBarrageStatus: true,
          switchPlayer: true,
          fixedController: true,
          controllerPosition: "ppt",
          pptPlaceholder: true,
        };
      },
    },
    charRoomParams: {
      type: Object,
      //聊天室参数
      default: () => {
        return {
          enableFlower: false,
          enableLike: true,
          customChatColor: "#2495c7",
          enableRewardAnimation: true,
          enableUpdatePageviews: true,
          tabData: [],
        };
      },
    },
    ckplayerWarmUp: {
      type: Boolean,
      default: false,
    },
    enableAsk: {
      type: Boolean,
      default: false,
    },
    userPic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      barrageStatus: true,
      showPptVideo: false,
      liveSdk: null,
      chatroom: null,
      rtc: null,
      showSigned: false,
      Signtime: 0,
      signMessage: "",
      timer2: null,
      checkinId: "",
      playerWarpWidth: 0, //盒子宽度
      playerWarpHeight: 0, //盒子高度
      chatHeight: 0, //聊天室高度
      pptVideoWidth: 0, //顶部视频宽度
      pptVideoHeight: 0, //顶部视频高度
      isSwitch: false, //是否切换
      liveVideoHeight: "", //播放器高度
      liveVideoWidth: ``, //播放器宽度
      controlWidth: ``, //控制器宽度
      chatWarpTop: 0, //聊天室距离顶部
      localWidth: 0, //连麦宽度
      localHeight: 0, //连麦高度
      localTop: 0,
      switchFlag: false,
      mutedPlay: false, //声音提示
      isFullScreen: false,
      playerObj: {
        width: 0,
        height: 0,
      },
      infont: false, //判断是谁在前边
      showControl: true, //显示控制栏
      onLine: false, //显示连线
      onLineing: false, //连线中
      lineText: "申请连线", //连线
      videoType: null,
      OnOffControl: {}, //开关控制器
      isLOGIN: true,
      ckPlayerMain: "",
    };
  },

  components: {},

  computed: {},

  created() {
    this.liveId = this.queryString["id"] || ""; //三分
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://player.polyv.net/livesdk/polyv-live.min.js";
    let script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://player.polyv.net/jssdk/polyv-chatroom.min.js";
    let script3 = document.createElement("script");
    script3.type = "text/javascript";
    // script3.src = "https://player.polyv.net/resp/rtc-sdk/latest/polyv-rtc.min.js";
    // script3.src = "https://player.polyv.net/resp/rtc-sdk/latest/polyv-rtc.min.js";
    let css = document.createElement("link");
    css.rel = "stylesheet";
    css.href = "https://player.polyv.net/jssdk/polyv-chatroom.min.css";
    document.getElementsByTagName("head")[0].appendChild(script);
    document.getElementsByTagName("head")[0].appendChild(script2);
    document.getElementsByTagName("head")[0].appendChild(script3);
    document.getElementsByTagName("head")[0].appendChild(css);

    // console.log(this.isDesign);
    // if (!this.isDesign) {
    //   this.isDesign = true;
    // }
  },
  mounted() {
    // 此参数可控制是否请求直播数据
    if (this.channelId) {
      this.getLiveConfig(this.channelId);
      // 查看是否回放
      this.isBackPlay = this.liveInfo.state.value == 3 || this.liveInfo.state.value == 2 ? true : false;
    } else {
      this.getLiveDetail();
    }
  },
  beforeDestroy() {
    if (this.chatroom) {
      this.chatroom.destroy();
    }
    if (this.liveSdk) {
      this.liveSdk.destroy();
    }
  },
  methods: {
    // 初始化高度
    initHeight(isPPT) {
      //获取显示宽度
      //
      // let windowWidth = window.innerWidth,
      //   windowHeight = window.innerHeight;
      let windowWidth = $(".dsfNcLivePlayer").width(),
        windowHeight = $(".dsfNcLivePlayer").height();
      this.playerObj = {
        width: parseInt(this.playerWidth),
        height: parseInt(this.playerHeight),
      };
      if (this.playerWidth.includes("%")) {
        this.playerObj.width = windowWidth * (parseInt(this.playerWidth) / 100);
      } else {
        this.playerObj.width = parseInt(this.playerWidth);
      }
      if (this.playerHeight.includes("%")) {
        this.playerObj.height = windowHeight * (parseInt(this.playerHeight) / 100);
      } else {
        this.playerObj.height = parseInt(this.playerHeight);
      }
      //设置总体宽度
      this.playerWarpWidth = this.playerObj.width + "px";
      this.playerWarpHeight = this.playerObj.height + "px";
      //设置聊天室高度
      this.chatHeight = this.playerWarpHeight;
      //设置连麦
      this.localWidth = this.charWidth;
      this.localHeight = this.playerObj.height - 38 + "px";

      if (isPPT) {
        this.pptVideoWidth = this.charWidth;
        this.pptVideoHeight = (parseInt(this.charWidth) * 9) / 16 + "px";
        this.chatHeight = parseInt(this.playerWarpHeight) - parseInt(this.pptVideoHeight) + "px";
        this.chatWarpTop = this.pptVideoHeight;
        // this.localHeight = parseInt(this.chatHeight) - 38 + "px";
        this.localHeight = parseInt(this.chatHeight) - 318 + "px";
        console.log(this.localHeight);
      }
      this.localTop = parseInt(this.chatWarpTop) + 38 + "px";
      //设置播放器
      this.liveVideoWidth = this.playerObj.width - parseInt(this.charWidth) + "px";
      this.liveVideoHeight = parseInt(this.playerWarpHeight) - 48 + "px";
      if (!isPPT) {
        this.liveVideoHeight = this.playerWarpHeight;
      }
      //设置控制条
      this.controlWidth = this.liveVideoWidth;
      // 动态添加css标签 设置连麦的高度
      let style = document.createElement("style");
      style.type = "text/css";
      let macHeigth = (parseInt(this.charWidth) * 9) / 16 + "px";
      style.innerText = `#local .pv-rtc-player{height:${macHeigth} !important} #local2 .pv-rtc-player{height:${macHeigth} !important}`;
      document.getElementsByTagName("head")[0].appendChild(style);
    },
    //获取直播信息
    getLiveDetail() {
      let params = {
        id: this.liveId,
      };
      this.$http
        .get(this.sourceUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.liveInfo = res.data;
            this.getLiveConfig(res.data.ncLiveInfo.channelValue);
            this.channelId = res.data.ncLiveInfo.channelValue;
            // 查看是否回放
            this.isBackPlay = res.data.state.value == 3 || res.data.state.value == 2 ? true : false;
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {});
    },
    //获取频道信息
    getLiveConfig(channelId) {
      let params = {
        channelId: channelId,
      };
      this.$http
        .get("nc/live/liveConfig", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.getChatToken(res.data);
            if (res.data.scene === "ppt") {
              this.initHeight(true);
            } else {
              this.initHeight();
            }
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {});
    },
    getChatToken(data) {
      let t = "visitor_" + Date.parse(new Date());
      this.$http
        .get("nc/live/getLiveSign", { channelId: data.channelId, userId: data.user.userId || t, appId: data.appId, role: "viewer" }, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.liveToken(res.data, data);
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {});
    },
    async liveToken(sign, user) {
      let t = "visitor_" + Date.parse(new Date());
      $.post(
        "https://api.polyv.net/live/v3/channel/common/get-chat-token",
        {
          appId: user.appId || "", //string	从API设置中获取，在直播系统登记的appId
          timestamp: sign.timestamp || "", //long	13位当前时间戳
          sign: sign.sign || "", //是	string	签名值
          channelId: sign.channelId || "", //是	int	频道Id
          userId: user.user.userId || t, //是	String	观看者用户ID
          role: "viewer", //是	String	角色，值有：teacher admin guest assistant viewer等
          origin: user.origin,
        },
        resP => {
          if (resP.code == 200) {
            let _resP = resP.data;
            let chatroom = new PolyvChatRoom({
              roomId: user.channelId || "268682",
              userId: user.user.userId || "153075602311",
              nick: user.user.userName || "游客" + new Date().getTime(),
              pic: (this.userPic && this.getPic(user.user.pic)) || "https://livestatic.videocc.net/assets/wimages/missing_face.png",
              token: _resP.token,
              mediaChannelKey: _resP.mediaChannelKey,
              version: "2.0",
              container: "#chatwrap",
              width: parseInt(this.charWidth),
              height: this.chatHeight,
              userType: user.user.userType || "slice",
              enableFlower: this.charRoomParams.enableFlower, //送花
              enableLike: this.charRoomParams.enableLike, //点赞
              enableRewardAnimation: this.charRoomParams.enableRewardAnimation, //打赏动画效果
              enableUpdatePageviews: this.charRoomParams.enableUpdatePageviews, //热度更新
              tabData: this.charRoomParams.tabData.length ? this.charRoomParams.tabData : null,
              enableAsk: this.enableAsk,
              roomMessage: data2 => {
                // TODO
                // data为聊天室socket消息，当有聊天室消息时会触发此方法
                // console.log(data, "1231231");
                switch (data2.EVENT) {
                  case "CLOSEROOM": {
                    //关闭聊天室
                    $(".polyv-input-ele").attr("disabled", true);
                    break;
                  }
                  case "openRoom": {
                    //开启聊天室
                    $(".polyv-input-ele").removeAttr("disabled");
                    break;
                  }
                  case "SIGN_IN": {
                    //签到
                    this.showSigned = true;
                    this.Signtime = data2.data.limitTime;
                    this.signMessage = data2.data.message;
                    this.checkinId = data2.data.checkinId;
                    this.timer2 = setInterval(() => {
                      this.Signtime -= 1;
                      if (this.Signtime <= 0) {
                        clearInterval(this.timer2);
                      }
                    }, 1000);

                    break;
                  }
                  //
                  case "STOP_SIGN_IN": {
                    this.showSigned = false;
                    //结束签到
                    clearInterval(this.timer2);
                    break;
                  }
                  case "CLOSE_DANMU": {
                    //关闭弹幕
                    console.log(3);
                    break;
                  }
                  case "SPEAK": {
                    // 进入队列时，自己SPEAK消息不触发
                    if (data2.user.userId !== user.user.userId) this.sendText(data2.content);
                    break;
                  }
                  case "sendMessage": {
                    this.sendText(data2.content, this.charRoomParams.customChatColor);
                    break;
                  }
                }
                // if (data.content) {
                //   sendText(data.content, );
                // }
              },
            });
            this.chatroom = chatroom;
            //未登录不让发言
            if (!dsf.getToken()) {
              $(".polyv-input-ele").attr("disabled", true);
            }
            this.renderPolyvPlayer(user);
            //连麦功能
            this.createRTCWebSDK(user);
          }
        }
      );
      //     }
      //   }
      // );
    },
    //构建播放器
    renderPolyvPlayer(data) {
      //以下是js
      let liveSdk = new PolyvLiveSdk({
        channelId: data.channelId, //频道信息
        sign: data.sign, // 频道验证签名
        timestamp: data.timestamp, // 毫秒级时间戳
        appId: data.appId, // polyv 后台的appId
        socket: this.chatroom.chat.socket,
        user: {
          userId: data.user.userId || "",
          userName: data.user.userName || "游客" + new Date().getTime(),
          pic: (this.userPic && this.getPic(data.user.pic)) || "https://livestatic.videocc.net/assets/wimages/missing_face.png",
          userType: data.user.userType || "slice",
        },
      });
      let pptEl;
      let el;
      let type = "auto";
      if (data.scene == "ppt") {
        pptEl = "#liveVideo";
        el = "#pptVideo";
        this.showPptVideo = true;
      } else {
        pptEl = "#liveVideo";
        el = "#liveVideo";
        this.showPptVideo = false;
      }
      let config = {
        pptEl: pptEl,
        el: el,
        type: type,
        autoplay: this.PolyvParams.autoplay,
        audioMode: this.PolyvParams.audioMode,
        width: "100%",
        height: "100%",
        pptWidth: "100%",
        pptHeight: "100%",
        controllerPosition: this.PolyvParams.controllerPosition || "ppt",
        controllerEl: $(".ppt-control-use")[0],
        pptPlaceholder: this.PolyvParams.pptPlaceholder,
        pptNavBottom: "60px",
        controller: this.PolyvParams.controller,
        pptNav: this.PolyvParams.pptNav,
        barrage: this.PolyvParams.barrage, //弹幕
        defaultBarrageStatus: this.PolyvParams.defaultBarrageStatus, //弹幕
        switchPlayer: this.PolyvParams.switchPlayer,
        fixedController: this.PolyvParams.fixedController,
        fullscreenEl: document.getElementById("liveVideo"), //全屏元素
        rtc: true, // 在非无延迟的频道里面设置后可进行连麦，sdk会加载连麦sdk并返回实例
      };
      let playBackVideos = JSON.parse(this.liveInfo.ncLiveInfo.playBackVideos || "[]");
      if (this.isBackPlay && playBackVideos.length) {
        // 回放模式需要fileId、url、sessionId
        // fileId: undefined, //回放时需要
        // url: undefined, //回放时需要
        // sessionId: undefined, //回放时需要
        config.fileId = playBackVideos[0].fileId || undefined;
        config.url = playBackVideos[0].url || undefined;
        config.sessionId = playBackVideos[0].channelSessionId || undefined;
        config.type = "record";
        config.pptType = "record";
      }
      this.liveSdk = liveSdk;
      // 监听频道信息并初始化播放器
      liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
        liveSdk.setupPlayer(config);
        if (data.warmUpEnabled == "Y" && this.ckplayerWarmUp == true) {
          this.hasWarmUp = true;
          this.switchPlayer(false, 1); //直播屏在右
        }
        liveSdk.player.on("switchMainScreen", main => {
          // console.log("切换主讲位置，当前主屏为", main); // 'player'|'ppt'
          this.ckPlayerMain = main;
          this.switchPlayer(main == "ppt" ? true : false, 1);
          // this.switchPlayer(1);
        });
        liveSdk.player.on("switchPlayer", main => {
          // console.log("switchPlayer");
          this.switchPlayer();
        });
        //检测播放声音
        liveSdk.player.on("mutedAutoplay", () => {
          // console.log("静音");
          if (this.isLOGIN) {
            this.mutedAutoplay();
          }
        });
        //弹幕
        liveSdk.player.on("barrageStatusChange", line => {
          this.barrageStatus = line;
        });
        //全屏事件
        liveSdk.player.on("fullscreenChange", (state, dom) => {
          if (state) {
            this.isFullScreen = true;
          } else {
            this.isFullScreen = false;
          }
        });
        //连麦实例初始化完成，可以进行连麦相关代码调用，无延迟，普通直播并支持连麦的情况下会回调
        liveSdk.player.on("rtcInitialized", rtc => {
          console.log("连麦实例初始化完成", rtc);
          this.rtc = rtc;
          this.initRTC(rtc);
        });
      });
      // 踢出直播间
      liveSdk.on(PolyvLiveSdk.EVENTS.BAN_USER_ROOM, (event, state) => {
        $(".pv-ppt-control-volume").hide();
        this.liveSdk.player.setVolume(0);
        this.mutedPlay = false;
        this.isLOGIN = false;
        // console.log(this.$alert);
        dsf.layer.pc.message("您已被踢出直播间", false);
        this.isLOGIN = false;
        setTimeout(() => {
          window.close();
          dsf.layer.pc.closeWindow();
        }, 500);
      });
      liveSdk.on(PolyvLiveSdk.EVENTS.LOGIN_REFUSE, (event, data) => {
        if (data.data && data.data.referField) {
          dsf.layer.pc.message("您已被踢出直播间", false);
          this.isLOGIN = false;
          this.mutedPlay = false;
          setTimeout(() => {
            $(".pv-ppt-control-volume").hide();
            this.$nextTick(() => {
              this.liveSdk.player.setVolume(0);
            });
          }, 500);
        }
      });
      //检查流变化
      liveSdk.on(PolyvLiveSdk.EVENTS.STREAM_UPDATE, (event, state) => {
        if (state === "live") {
          if (this.ckplayerWarmUp && this.hasWarmUp) {
            this.switchPlayer(this.ckPlayerMain != "player", 1);
          }
          liveSdk.reloadPlayer();
        } else if (state === "end") {
          if (this.ckplayerWarmUp && this.hasWarmUp) {
            this.switchPlayer(false, 1);
          }
          this.$emit("liveEnd");
        }
      });
      // liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
      //   console.log("频道菜单信息:", data.channelMenus);
      // });
    },
    //切换
    switchPlayer(val, is) {
      let positionPPT = false;
      if (is) {
        if (val) {
          positionPPT = true;
          this.isSwitch = false;
          if (!this.isSwitch) {
            positionPPT = true;
            this.isSwitch = false;
          }
        } else {
          positionPPT = false;
          this.isSwitch = true;
        }
      } else {
        if (this.isSwitch) {
          positionPPT = true;
          this.isSwitch = false;
        } else {
          positionPPT = false;
          this.isSwitch = true;
        }
      }
      if (positionPPT) {
        this.infont = true;
        this.liveVideoWidth = this.playerObj.width - parseInt(this.charWidth) + "px";
        this.liveVideoHeight = parseInt(this.playerWarpHeight) - 48 + "px";
        this.pptVideoWidth = this.charWidth;
        this.pptVideoHeight = (parseInt(this.charWidth) * 9) / 16 + "px";
      } else {
        this.infont = false;
        this.liveVideoWidth = this.charWidth;
        this.liveVideoHeight = (parseInt(this.charWidth) * 9) / 16 + "px";
        this.pptVideoWidth = this.playerObj.width - parseInt(this.charWidth) + "px";
        this.pptVideoHeight = parseInt(this.playerWarpHeight) - 48 + "px";
      }
      // 设置全屏元素
      if (this.isSwitch) {
        this.liveSdk.player.setFullscreenEl(document.getElementById("pptVideo"));
      } else {
        this.liveSdk.player.setFullscreenEl(document.getElementById("liveVideo"));
      }
      setTimeout(() => {
        this.liveSdk.player.resize();
      });
    },
    //没有声音提示
    mutedAutoplay() {
      this.mutedPlay = true;
    },
    handleMuted() {
      this.liveSdk.player.setVolume(0.5);
      this.mutedPlay = false;
    },
    //签到
    sign() {
      this.liveSdk.toSign(this.checkinId, res => {
        let code = JSON.parse(res).code || null;
        if (code === 200) {
          dsf.layer.pc.message("签到成功", true);
          this.showSigned = false;
          clearInterval(this.timer2);
        }
      });
    },
    //连麦功能
    createRTCWebSDK(data) {
      if (!data) {
        return;
      }
      var rtcConfig = {
        roomId: data.channelId,
        socket: this.chatroom.chat.socket,
        userId: data.user.userId || "153075602311",
        nick: data.user.userName || "游客" + new Date().getTime(),
        pic: (this.userPic && this.getPic(data.user.pic)) || "https://livestatic.videocc.net/assets/wimages/missing_face.png",
      };
      // let rtc = new PolyvRTCWebSDK(rtcConfig);
      // this.liveSdk.rtc = true;
      let rtc = this.liveSdk;
      // if (data.scene == "ppt") {
      //   $("#applyContact").addClass("isPPTonline");
      // }
      // // else {
      // // 设置模式
      // // }
      console.log(this.liveSdk.playerConfig);
      this.rtc = rtc;
      rtc.on("OPEN_MICROPHONE", () => {
        //讲师开启连麦
        console.log("OPEN_MICROPHONE", "讲师开启连麦");
        this.lineText = "申请连线";
        this.onLine = true;
      });

      rtc.on("JOIN_CHANNEL_TIMEOUT", evt => {
        console.log(evt);
        this.lineText = "申请连线";
        console.log("申请连线了");
        this.onLineing = false;
        // alert("申请连麦超时，请重新申请");
        dsf.layer.pc.message("申请连麦超时，请重新申请", false);
      });
      rtc.on("CLOSE_MICROPHONE", () => {
        console.log("CLOSE_MICROPHONE");
        //讲师关闭连麦
        this.lineText = "申请连线";
        this.onLine = false;
        this.onLineing = false;
        //删除dom
        this.removeDome();
        $(".tab-chat").show();
        $("#local").hide();
        $("#local2").hide();
      });
      rtc.on("ALLOW_MICROPHONE", evt => {
        //连麦通过
        console.log(`开始加入连麦，频道为 ${evt}`);
        // this.onLine = false;
        this.lineText = "取消连麦";
      });
      rtc.on("INIT_LOCAL_STREAM_READY", evt => {
        console.log("准备推流，通话类型为" + evt.type);
        this.videoType = evt.type;
        //设置静音
        this.liveSdk.player.setVolume(0);
        //隐藏音频控制按钮
        $(".pviconfont.pv-icon-volumenone,.pv-ppt-control-volume").hide();
        //开始推流
        if (evt.type === "audio") {
          evt.init({
            element: document.getElementById("local"),
            control: true,
            profile: "480p",
          });
          $("#local").show();
        } else {
          evt.init({
            element: document.getElementById("local2"),
            control: true,
            profile: "480p",
          });
          $("#local2").show();
        }
        var html2 = '<li style="width:25%" data-index="3"  class="polyv-crn-active" data-type="contact"><span data-lang="contact">连麦</span></li>';
        var li = $(".polyv-cr-navbar li");
        for (var i = 0; i < li.length; i++) {
          $(li[i]).css({ width: "25%" }).removeClass("polyv-crn-active");
        }
        $(".polyv-cr-navbar").prepend(html2);
        var _li = $(".polyv-cr-navbar li");

        $(".tab-chat").hide();
        $(".polyv-cr-navbar li").on("click", function () {
          var index = $(this).data("index");
          for (var i = 0; i < _li.length; i++) {
            $(_li[i]).removeClass("polyv-crn-active");
          }
          $(this).addClass("polyv-crn-active");
          if (index == 3) {
            $(".pv-rtc-player").show();
          } else {
            $(".pv-rtc-player").hide();
          }
          if (index == 0) {
            $(".tab-chat").show();
          }
        });
      });
      rtc.on("CLIENT_BANNED", () => {
        console.log("被讲师挂断连麦");
        //设置音量
        this.liveSdk.player.setVolume(0.5);
        $("#local").hide();
        $("#local2").hide();
        //显示音量控制
        $(".pviconfont.pv-icon-volumenone,.pv-ppt-control-volume").show();
        this.lineText = "申请连线";
        this.onLine = true;
        this.onLineing = false;
        //删除dom
        this.removeDome();
      });
      // 主动挂断视频通话
      rtc.on("STOP", evt => {
        console.log("主动挂断通话");
        this.lineText = "申请连线";
        this.onLine = true;
        this.onLineing = false;
        //删除dom
        this.removeDome();
        //设置音量
        this.liveSdk.player.setVolume(0.5);
        //显示音量控制
        $(".pviconfont.pv-icon-volumenone,.pv-ppt-control-volume").show();
        $("#local").hide();
        $("#local2").hide();
      });

      // rtc.on("LOCAL_MUTE_VIDEO", function(evt)  {
      //   console.log("关闭视频轨道");
      //   $(".ppt-apply").removeClass("apply-cancel").html("申请连线").show();

      //
      //   $(".polyv-cr-navbar li").eq(0).remove();
      //   var __li = $(".polyv-cr-navbar li");
      //   for (var i = 0; i < __li.length; i++) {
      //     $(__li[i]).css({ width: "33.33%" });
      //   }
      //   $(".polyv-cr-navbar li").eq(0).addClass("polyv-crn-active");
      //   $(".tab-chat").show();
      // });
      //用于挂断连麦，退出连麦频道，停止推流
      if (rtc.leaveChannel) {
        rtc.leaveChannel(() => {
          console.log("退出连麦频道，停止推流");
          this.lineText = "申请连线";
          // $(".ppt-apply").removeClass("apply-cancel").html("申请连线");
          //删除dom
          // removeDome();
        });
      }
      //加入订阅
      rtc.on("USER_STREAM_ADDED", evt => {
        // console.log(`是否为讲师：${evt.teacher}`);
        // console.log(`流Id：${evt.streamId}`);
        // console.log(`用户昵称：${evt.nick}`);
        // console.log(`用户头像：${evt.pic}`);
        console.log("USER_STREAM_ADDED加入订阅");
        // console.log(evt);
        if (this.videoType === "video") {
          $("#local").hide();
          $("#local2").show();
          this.videoLine(evt);
        } else {
          $("#local").show();
          $("#local2").hide();
          // var PIC = evt.pic ? evt.pic : "https://liveimages.videocc.net/defaultImg/avatar/viewer.png";
          this.audioLine(evt);
        }
      });
      rtc.on("USER_STREAM_SUBSCRIBED", evt => {
        cons.log("订阅成功");
        console.log(`是否为讲师：${evt.teacher}`);
        console.log(`流Id：${evt.streamId}`);
        console.log(`用户昵称：${evt.nick}`);
        console.log(`用户头像：${evt.pic}`);
      });
    },
    initRTC(rtc) {
      if (!rtc) return false;
      console.log(rtc);
      rtc.on("OPEN_MICROPHONE", () => {
        //讲师开启连麦
        console.log("OPEN_MICROPHONE", "讲师开启连麦");
        this.lineText = "申请连线";
        this.onLine = true;
      });

      rtc.on("JOIN_CHANNEL_TIMEOUT", evt => {
        console.log(evt);
        this.lineText = "申请连线";
        console.log("申请连线了");
        this.onLineing = false;
        // alert("申请连麦超时，请重新申请");
        dsf.layer.pc.message("申请连麦超时，请重新申请", false);
      });
      rtc.on("CLOSE_MICROPHONE", () => {
        console.log("CLOSE_MICROPHONE");
        //讲师关闭连麦
        this.lineText = "申请连线";
        this.onLine = false;
        this.onLineing = false;
        //删除dom
        this.removeDome();
        $(".tab-chat").show();
        $("#local").hide();
        $("#local2").hide();
      });
      rtc.on("ALLOW_MICROPHONE", evt => {
        //连麦通过
        console.log(`开始加入连麦，频道为 ${evt}`);
        // this.onLine = false;
        this.lineText = "取消连麦";
      });
      rtc.on("INIT_LOCAL_STREAM_READY", evt => {
        console.log("准备推流，通话类型为" + evt.type);
        this.videoType = evt.type;
        //设置静音
        this.liveSdk.player.setVolume(0);
        //隐藏音频控制按钮
        $(".pviconfont.pv-icon-volumenone,.pv-ppt-control-volume").hide();
        //开始推流
        if (evt.type === "audio") {
          evt.init({
            element: document.getElementById("local"),
            control: true,
            profile: "480p",
          });
          $("#local").show();
        } else {
          evt.init({
            element: document.getElementById("local2"),
            control: true,
            profile: "480p",
          });
          $("#local2").show();
        }
        var html2 = '<li style="width:25%" data-index="3"  class="polyv-crn-active" data-type="contact"><span data-lang="contact">连麦</span></li>';
        var li = $(".polyv-cr-navbar li");
        for (var i = 0; i < li.length; i++) {
          $(li[i]).css({ width: "25%" }).removeClass("polyv-crn-active");
        }
        $(".polyv-cr-navbar").prepend(html2);
        var _li = $(".polyv-cr-navbar li");

        $(".tab-chat").hide();
        $(".polyv-cr-navbar li").on("click", function () {
          var index = $(this).data("index");
          for (var i = 0; i < _li.length; i++) {
            $(_li[i]).removeClass("polyv-crn-active");
          }
          $(this).addClass("polyv-crn-active");
          if ($(".pv-rtc-player").length > 1) {
            // $("#local").remove($(".pv-rtc-player")[0]);
            console.log();
            document.getElementById("local").removeChild($(".pv-rtc-player")[0]);
          }
          if (index == 3) {
            // if ($(".pv-rtc-player")[1]) {
            //   $(".pv-rtc-player")[1].show();
            // } else {
            //   $(".pv-rtc-player").show();
            // }
            $(".pv-rtc-player").show();
          } else {
            // if ($(".pv-rtc-player")[1]) {
            //   $(".pv-rtc-player")[1].hide();
            // } else {
            //   $(".pv-rtc-player").hide();
            // }
            $(".pv-rtc-player").hide();
          }
          if (index == 0) {
            $(".tab-chat").show();
          }
        });
      });
      rtc.on("CLIENT_BANNED", () => {
        console.log("被讲师挂断连麦");
        //设置音量
        this.liveSdk.player.setVolume(0.5);
        $("#local").hide();
        $("#local2").hide();
        //显示音量控制
        $(".pviconfont.pv-icon-volumenone,.pv-ppt-control-volume").show();
        this.lineText = "申请连线";
        this.onLine = true;
        this.onLineing = false;
        //删除dom
        this.removeDome();
      });
      // 主动挂断视频通话
      rtc.on("STOP", evt => {
        console.log("主动挂断通话");
        this.lineText = "申请连线";
        this.onLine = true;
        this.onLineing = false;
        //删除dom
        this.removeDome();
        //设置音量
        this.liveSdk.player.setVolume(0.5);
        //显示音量控制
        $(".pviconfont.pv-icon-volumenone,.pv-ppt-control-volume").show();
        $("#local").hide();
        $("#local2").hide();
      });

      // rtc.on("LOCAL_MUTE_VIDEO", function(evt)  {
      //   console.log("关闭视频轨道");
      //   $(".ppt-apply").removeClass("apply-cancel").html("申请连线").show();

      //
      //   $(".polyv-cr-navbar li").eq(0).remove();
      //   var __li = $(".polyv-cr-navbar li");
      //   for (var i = 0; i < __li.length; i++) {
      //     $(__li[i]).css({ width: "33.33%" });
      //   }
      //   $(".polyv-cr-navbar li").eq(0).addClass("polyv-crn-active");
      //   $(".tab-chat").show();
      // });
      //用于挂断连麦，退出连麦频道，停止推流
      if (rtc.leaveChannel) {
        rtc.leaveChannel(() => {
          console.log("退出连麦频道，停止推流");
          this.lineText = "申请连线";
          // $(".ppt-apply").removeClass("apply-cancel").html("申请连线");
          //删除dom
          // removeDome();
        });
      }
      //加入订阅
      rtc.on("USER_STREAM_ADDED", evt => {
        // console.log(`是否为讲师：${evt.teacher}`);
        // console.log(`流Id：${evt.streamId}`);
        // console.log(`用户昵称：${evt.nick}`);
        // console.log(`用户头像：${evt.pic}`);
        console.log("USER_STREAM_ADDED加入订阅");
        // console.log(evt);
        if (this.videoType === "video") {
          $("#local").hide();
          $("#local2").show();
          this.videoLine(evt);
        } else {
          $("#local").show();
          $("#local2").hide();
          // var PIC = evt.pic ? evt.pic : "https://liveimages.videocc.net/defaultImg/avatar/viewer.png";
          this.audioLine(evt);
        }
      });
      rtc.on("USER_STREAM_SUBSCRIBED", evt => {
        cons.log("订阅成功");
        console.log(`是否为讲师：${evt.teacher}`);
        console.log(`流Id：${evt.streamId}`);
        console.log(`用户昵称：${evt.nick}`);
        console.log(`用户头像：${evt.pic}`);
      });
    },
    handleOnline() {
      if (this.lineText === "申请连线") {
        this.lineText = "取消申请";
        this.rtc.joinChannel(() => {
          console.log("申请成功");
          this.lineText = "取消申请";
          this.onLineing = true;
        });
        // this.rtc.on("OPEN_MICROPHONE", evt => {
        //   // 也可以通过页面点击申请
        //   console.log("申请连麦成功");
        //   this.liveSdk.joinChannel(() => {
        //     console.log("申请成功");
        //     this.lineText = "取消申请";
        //     this.onLineing = true;
        //   });
        // });
      } else {
        //取消申请
        console.log(this.rtc.cancelJoinChannel);
        this.rtc.cancelJoinChannel(() => {
          console.log("取消申请");
          this.lineText = "申请连线";
          this.onLineing = false;
        });
      }
    },
    // 视频连线
    videoLine(evt) {
      // $(".playerWarp").addClass("videoLine");
      // $(".playerWarp").removeClass("audioLine");
      if (evt.teacher) {
        evt.subscribe(
          {
            element: document.getElementById("local2"),
          },
          function (err) {
            console.log("订阅失败");
          }
        );
      } else {
        evt.subscribe({
          element: document.getElementById("local2"),
          control: true,
        });
      }
    },
    //语音连麦
    audioLine(evt) {
      // $(".playerWarp").addClass("audioLine");
      // $(".playerWarp").removeClass("videoLine");
      console.log("928", evt);
      if (evt.teacher) {
        evt.subscribe(
          {
            element: document.getElementById("local"),
          },
          function (err) {
            console.log("订阅失败");
          }
        );
      } else {
        evt.subscribe({
          element: document.getElementById("local"),
          control: true,
        });
      }
    },
    removeDome() {
      let html = $(".polyv-cr-navbar li").eq(0).html();
      if (html.indexOf("聊天") != -1 || html.indexOf("在线") != -1 || html.indexOf("提问") != -1) {
        return;
      }
      //删除dom
      $(".polyv-cr-navbar li").eq(0).remove();
      var __li = $(".polyv-cr-navbar li");
      for (var i = 0; i < __li.length; i++) {
        $(__li[i]).css({ width: "33.33%" });
      }
      $(".polyv-cr-navbar li").eq(0).addClass("polyv-crn-active");
      $(".tab-chat").show();
      $("#local,#local2").html("");
    },
    //获取头像函数
    getPic(val) {
      // return null;
      if (!val) return null;
      try {
        if (val.length > 2) {
          let re = dsf.url.getWebPath(JSON.parse(val)[0].relativePath);
          let url = location.origin + re;
          if (re.startsWith("http://") || re.startsWith("https://")) {
            url = re;
          }
          return url;
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    sendText(text, color) {
      if (this.barrageStatus) {
        this.liveSdk.player.sendBarrage(text, color);
      }
    },
  },
});
</script>
