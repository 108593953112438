var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds_channel_detail_top" }, [
        _c("div", { staticClass: "ds_channel_detail_wrap" }, [
          _c("div", { staticClass: "channel-detail-inner" }, [
            _c(
              "div",
              { staticClass: "cover" },
              [
                _c("el-image", {
                  attrs: {
                    src:
                      "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                  },
                }),
              ],
              1
            ),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "channel-button" },
              [_c("dsf-icon", { attrs: { name: "iconfontzhizuobiaozhun13" } })],
              1
            ),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "ds_channel_detail_top" }, [
        _vm.it
          ? _c("div", { staticClass: "ds_channel_detail_wrap" }, [
              _c("div", { staticClass: "channel-detail-inner" }, [
                _c(
                  "div",
                  { staticClass: "cover" },
                  [
                    _c("dsf-image", {
                      attrs: { src: _vm._f("imgFormat")(_vm.it.cover) },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "desc" }, [
                  _c("h3", { staticClass: "desc-name" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.it.title)),
                    ]),
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("span", [_vm._v("发布: " + _vm._s(_vm.it.time))]),
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("span", [
                      _vm._v(
                        "资源数: " + _vm._s(_vm.it.courseCount || 0) + "门"
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "info info-bottom" }, [
                    _c("span", [
                      _vm._v(
                        "浏览: " +
                          _vm._s(_vm.it.hits || 0) +
                          "次  |  订阅数: " +
                          _vm._s(_vm.it.subscription || 0)
                      ),
                    ]),
                  ]),
                  _vm.it.labelText
                    ? _c(
                        "div",
                        { staticClass: "info info-bottom" },
                        _vm._l(_vm.it.labelText.split("^"), function (item) {
                          return _c(
                            "span",
                            { key: item, staticClass: "tags" },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "channel-button" }, [
                  _c(
                    "button",
                    {
                      staticClass: "subcrible",
                      on: {
                        click: function ($event) {
                          return _vm.toSubscrible(_vm.it, "mySubscribed")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.showSubText))]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("h3", { staticClass: "desc-name" }, [
        _c("span", { staticClass: "name" }, [_vm._v("2")]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("span", [_vm._v("发布: 2020-10-21")]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("span", [_vm._v("资源数：共 30门")]),
      ]),
      _c("div", { staticClass: "info info-bottom" }, [
        _c("span", [_vm._v("浏览 12221次")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }