<template>
  <div class="ds_recommendNewsListModelTwo" :style="controlStyle">
    <div class="module_header">
      <h6 style="">相关推荐</h6>
    </div>
    <div class="content" v-if="news.length">
      <div class="ds_recommendNews-row ell" v-for="(item, index) in news" :key="index" @click="_clickBefore(goDetail, item)" :style="rowStyle">
        <p class="number">
          <!-- {{ index + 1 }} -->
        </p>
        <p class="title ell" :title="item[keyField.title]">{{ item[keyField.title] }}</p>
      </div>
    </div>
    <div v-else class="noDate">暂无数据</div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcRecommendNewsListModelTwo",
  ctrlCaption: "推荐新闻列表模式二",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    margin: {
      type: Array,
      default() {
        return [];
      },
    },
    padding: {
      type: Array,
      default() {
        return [15, 15, 15, 15];
      },
    },
    rowPadding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    rowMargin: {
      type: Array,
      default() {
        return [0, 0, 22, 0];
      },
    },
    detailUrl: {
      type: String,
      default: "",
    },
    reqUrl: {
      type: String,
      default: "cms/articles/queryArticlesByCatalogCode?pageNumber=1&pageSize=15&catalogCode=002",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [{ from: "title", to: "title", type: "text", desc: "新闻标题" }];
      },
    },
  },
  computed: {
    controlStyle() {
      let { margin, width, height, padding, isDesign, visible } = this;
      let style = {
        margin: margin.join("px ") + "px",
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    rowStyle() {
      let { rowPadding, rowMargin } = this;
      let style = {
        margin: rowMargin.join("px ") + "px",
        padding: rowPadding.join("px ") + "px",
      };
      return style;
    },
  },
  data() {
    return {
      news: [],
      keyField: {},
      id: "",
    };
  },
  mounted() {
    this.id = this.queryString["id"] || "";
    this.keysMap.map(item => {
      this.keyField[item.to] = item.from;
    });
    if (!this.isDesign) {
      this.initData();
    } else {
      this.news = [
        {
          title: "阿里研究院副院长安筱鹏：一文讲透数字化的8个关键问题",
          id: "11",
        },
        {
          title: "防返乡潮疫情风险，河北邢台巨鹿县全县启用钉钉返乡申报",
          id: "22",
        },
        {
          title: "阿里研究院副院长安筱鹏：一文讲透数字化的8个关键问题",
          id: "11",
        },
      ];
    }
  },
  methods: {
    initData() {
      if (!this.reqUrl) return;
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.reqUrl, {}, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.news = data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        if (this.$checkLogin) {
          this.$checkLogin().then(res => {
            if (res) next(...params);
          });
        } else {
          next(...params);
        }
      } catch (err) {
        console.log(err);
      }
    },
    goDetail(item) {
      if (this.$listeners["click"]) {
        this.$dispatch("click", item);
      } else {
        // if (this.detailUrl) {
        //   let url = this.$replace(this.detailUrl, item);
        //   url && dsf.layer.openWindow(url);
        // }
        if (item.type == 3) {
          item.externalLink && window.open(item.externalLink);
        } else {
          if (this.detailUrl) {
            let url = this.$replace(this.detailUrl, item);
            url && dsf.layer.openWindow(url);
          }
        }
      }
      //获取积分
      // dsf.http
      //   .get("nc/cms/articles/queryArticlesById", { id: item.id }, $$webRoot.nc)
      //   .done(res => {
      //     if (!res.success) {
      //       dsf.layer.message(res.message || "积分异常", false);
      //     }
      //   })
      //   .error(err => {
      //     dsf.layer.message(res.message || "积分异常", false);
      //   })
      //   .always(res => {});
    },
  },
});
</script>
